import { Card, Intent } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { Application } from "../../../types/Application";
import { SignatureHolderReadDTO } from "../../../types/Application/Signature";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import Form from "../../Forms";
import { signatureFormSchema } from "../../Forms/ApplicationForm/Signature";
import { blankFormSchema } from "../../Forms/ApplicationForm/Signatures";
import { Loading } from "../../Loading/Loading";
import { AppToaster } from "../../Toast/Toast";
import { useSignature } from "../../Signatures/useSignature";
import { SignaturesValidationPanel } from "../../Signatures/SignaturesValidationPanel";

export interface SignaturesCardProps {
  application?: Application;
  canEdit?: boolean;
  submitForm: (signatures: SignatureHolderReadDTO[]) => Promise<void>;
}

export const SignaturesCard: FC<SignaturesCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const {
    ready,
    signatures,
    signaturesValidationDetails,
    setSignatureValues,
    fetchSignatures,
  } = useSignature(application?.id);

  useEffect(() => {
    (async () => {
      if (application) {
        await fetchSignatures();
      }
    })();
  }, [application?.signatures]);

  return (
    <>
      <Card className="signatures-card">
        <>
          {ready && (
            <>
              <SignaturesValidationPanel
                signaturesValidationDetails={signaturesValidationDetails}
              />
              <Form<SignatureHolderReadDTO[]>
                formId="signatures-form"
                value={{} as SignatureHolderReadDTO[]}
                shouldBlockNavigation={true}
                disableAllFields={!canEdit}
                formSchemaBuilder={blankFormSchema}
                onFormSubmit={async () => {
                  setIsSaving(true);
                  await submitForm(signatures)
                    .then(() => {
                      AppToaster.show({
                        message: (
                          <div>
                            <h3>Success</h3>Application Saved
                          </div>
                        ),
                        intent: Intent.SUCCESS,
                      });
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }}
              />
              {signatures?.map((signature, idx) => {
                return (
                  <div key={idx} className="signature-card">
                    <Form<SignatureHolderReadDTO>
                      formId={`signature-${idx}-form`}
                      value={signature}
                      formSchemaBuilder={signatureFormSchema}
                      disableAllFields={!canEdit}
                      onFormSubmit={async (incomingSignature) => {
                        const newSignatures = signatures.map((ogSignature) =>
                          ogSignature.signatureHolderName ===
                          signature.signatureHolderName
                            ? { ...signature, ...incomingSignature }
                            : ogSignature
                        );

                        setSignatureValues(newSignatures);
                      }}
                      isFormRequired={signature.isRequired}
                    />
                  </div>
                );
              })}
            </>
          )}
          {!ready && <Loading />}
        </>
      </Card>
      <div className="application-card-footer">
        <FormSubmitButton
          disabled={!canEdit}
          buttonId="signatures-save-button"
          isSaving={isSaving}
          formId="signatures-form"
          text="Save"
        />
      </div>
    </>
  );
};
