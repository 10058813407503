import React, { useEffect, useState } from "react";
import {
  FIARequest,
  FIARequestResendDTO,
  FIARequestUpdateDTO,
} from "./configurations/types";
import { ExistingInstitution } from "../../types/Institution";
import { institutionApiClient } from "../../lib/apiClients/institution/institutionApiClient";
import { fiaRequestStore } from "./stores/FIARequestStore";
import {
  blankFIARequestDetails,
  blankNewFIARequest,
} from "../Forms/FIARequestForm/FIARequestForm";
import { fiaRequestApiClient } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { errorStore, genericErrorMessage } from "../../stores/ErrorStore";
import { getRightArticleSyntax_aWord } from "../../utils/miscellaneous";
import { userStore } from "../../stores/UserStore";
import { utils } from "@ucl/library";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { AppToaster } from "../Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { setIsUpdatedFIAPending } from "./FIARequestFlyout";
import { fiaRequestConnectivityHub } from "../../lib/hub/fiaRequestConnectivityHub";

export const useFIARequestFlyout = (
  fiaRequestId: string | undefined,
  onClose: (() => void) | undefined
) => {
  const [fiaRequest, setFIARequest] = useState<FIARequest>();
  const [reloadKey, setReloadKey] = React.useState<number>(0);

  const [localFIARequestId, setlocalFIARequestId] = useState<
    string | undefined
  >();

  const [institution, setInstitution] = useState<ExistingInstitution>();
  const [isActionExecuting, setIsActionExecuting] = useState<boolean>(false);

  const handleClose = () => {
    if (isActionExecuting) {
      return;
    }

    onClose && onClose();
  };

  const reload = () => {
    setReloadKey((prev) => prev + 1);
  };

  const setFIARequestFromFIACreateApproveOrSend = (fiaRequest: FIARequest) => {
    setFIARequest({ ...fiaRequest });
    setlocalFIARequestId(fiaRequest.id);
    reload();
  };

  React.useEffect(() => {
    const newValue = fiaRequestId !== "new" ? fiaRequestId : undefined;
    if (newValue !== localFIARequestId) {
      setlocalFIARequestId(newValue);
      dialogsViewerStore.setIsFIARequestResendDialogOpen(false);
      dialogsViewerStore.setIsFIARequestCancelDialogOpen(false);
      dialogsViewerStore.setIsConfirmDialogOpen(false);
      reload();
    }
  }, [fiaRequestId]);

  React.useEffect(() => {
    if (fiaRequest && fiaRequest.institutionId) {
      institutionApiClient
        .getInstitutionDetail(fiaRequest.institutionId)
        .then((item) => {
          setInstitution(item);
        });
    }
    return () => {
      setInstitution(undefined);
    };
  }, [fiaRequest?.institutionId]);

  useEffect(() => {
    let refreshInterval: NodeJS.Timeout;
    if (!!fiaRequestId) {
      fiaRequestConnectivityHub.subscribe(fiaRequestId);
      refreshInterval = setInterval(() => {
        fiaRequestConnectivityHub.subscribe(fiaRequestId);
      }, 30000);
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, [fiaRequestId]);

  return {
    setFIARequestFromFIACreateApproveOrSend,
    handleClose,
    reload,
    reloadKey,
    localFIARequestId,
    institution,
    setIsActionExecuting,
    fiaRequest,
    setFIARequest,
  };
};

export const useFIARequestCreate = (applicationId: string) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fiaRequest, setFIARequest] = useState<FIARequest>(blankNewFIARequest);

  const [ready, setReady] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    (async () => {
      // fetch
      const newFIARequest = await fiaRequestApiClient
        .newRequest(applicationId)
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        });

      // set
      newFIARequest &&
        setFIARequest({ ...blankNewFIARequest, ...newFIARequest });
      setReady(true);
    })();
  }, []);

  // TODO: Add this back in when we have async autocomplete working again
  // useEffect(() => {
  //   if (fiaRequest.checklistItemId) {
  //     checklistApiClient
  //       .getById(fiaRequest.checklistItemId)
  //       .then((checklistItem) => {
  //         if (!!checklistItem.institutionId) {
  //           setFIARequest({
  //             ...fiaRequest,
  //             institutionId: checklistItem.institutionId,
  //           });
  //         }
  //       });
  //   }
  // }, [fiaRequest.checklistItemId]);

  return {
    isSaving,
    setIsSaving,
    ready,
    errors,
    setErrors,
    fiaRequest,
    setFIARequest,
  };
};

export const useFIARequestUpdate = (
  applicationId: string,
  fiaRequestId: string,
  institution: ExistingInstitution | undefined,
  setFIARequestOnApproveOrSend: (fiaRequest: FIARequest) => void,
  onRequestLoaded?: (fiaRequest?: FIARequest) => void,
  onActionExecuting?: (isExecuting: boolean) => void
) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fiaRequest, setFIARequest] = useState<FIARequestUpdateDTO>(
    fiaRequestStore.fiaRequest as FIARequestUpdateDTO
  );
  const [ready, setReady] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});
  const [documentErrors, setDocumentErrors] = useState<string[]>([]);

  // actions
  const [isResendLoading, setIsResendLoading] = useState<boolean>(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);
  const [isMarkAsReceivedLoading, setIsMarkAsReceivedLoading] =
    useState<boolean>(false);
  const [isMarkAsNotReceivedLoading, setIsMarkAsNotReceivedLoading] =
    useState<boolean>(false);
  const [isActionExecuting, setIsActionExecuting] = useState<boolean>(false);
  const [isViewSentFIALoading, setIsViewSentFIALoading] =
    useState<boolean>(false);
  const [canSendFIARequest, setCanSendFIARequest] = useState<boolean>(false);

  const newFIAResendDTO: FIARequestResendDTO = {
    followUpReason: undefined,
  };
  const [fiaRequestResendDTO, setFIARequestResendDTO] =
    useState<FIARequestResendDTO>(newFIAResendDTO);

  const validateFIARequestRequirements = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequest: FIARequest
  ) => {
    const fiaRequestApproveSendDTO = blankFIARequestDetails;
    fiaRequest && utils.clone.mapTo(fiaRequest, fiaRequestApproveSendDTO);

    await fiaRequestApiClient
      .validateFIARequestRequirements(
        applicationId,
        fiaRequestId,
        fiaRequestApproveSendDTO
      )
      .then((failures) => {
        setDocumentErrors(
          failures?.length > 0 ? failures.map((x) => x.errorMessage || "") : []
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrors(error.response.data.additionalInfo);
        }
      });
  };

  useEffect(() => {
    const actionExecuting =
      isPreviewLoading ||
      isViewSentFIALoading ||
      isCancelLoading ||
      isSaving ||
      isResendLoading ||
      isMarkAsReceivedLoading ||
      isMarkAsNotReceivedLoading;
    setIsActionExecuting(actionExecuting);
    onActionExecuting && onActionExecuting(actionExecuting);
  }, [
    isPreviewLoading,
    isViewSentFIALoading,
    isCancelLoading,
    isSaving,
    isResendLoading,
    isMarkAsReceivedLoading,
    isMarkAsNotReceivedLoading,
  ]);

  useEffect(() => {
    (async () => {
      // fetch
      const fetchedFIARequest = await fiaRequestApiClient
        .getRequest(applicationId, fiaRequestId)
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        });

      // set
      fetchedFIARequest && setFIARequest(fetchedFIARequest);
      fetchedFIARequest &&
        onRequestLoaded &&
        onRequestLoaded(fetchedFIARequest);
      fetchedFIARequest &&
        (await validateFIARequestRequirements(
          applicationId,
          fiaRequestId,
          fetchedFIARequest
        ));

      setReady(true);
    })();
  }, []);

  useEffect(() => {
    setCanSendFIARequest(
      !!institution &&
        (institution.isSpecialized
          ? !!userStore.user?.canSendSpecializedFIA
          : !!userStore.user?.canSubmitFIARequest)
    );
  }, [institution]);

  const fiaRequestSubmissionDialog = () => {
    return (
      <>
        By submitting this FIA Request, you attest that you have reviewed it.
        Clicking <b>Yes</b> will send{" "}
        {getRightArticleSyntax_aWord(
          institution?.sendingContactMethod_AsString
        )}{" "}
        <b>{institution?.sendingContactMethod_AsString}</b> to{" "}
        <b> {institution?.name}</b> and document the date and time of your
        attestation.
      </>
    );
  };

  const fiaRequestMailSubmissionDialog = () => {
    return (
      <>
        By submitting this FIA Request, you attest that you have reviewed it.
        Clicking <b>Yes </b>
        will store the date and time of your attestation.
      </>
    );
  };

  const catchPreviewFIARequestErrors = (
    promise: Promise<BlobPart> | undefined,
    setErrors: (data: any) => void
  ) => {
    promise?.catch((error) => {
      if (error?.response?.status == 400) {
        setErrors(error.response.data.additionalInfo);
      }
    });
  };

  const executeApproveAndSend = async (
    updatedFIARequest: FIARequestUpdateDTO,
    isBypass: boolean
  ): Promise<{ isSuccess: boolean; error: any }> => {
    const result = { isSuccess: true, error: undefined };

    if (fiaRequest.canApproveOnly) {
      await fiaRequestStore
        .approve(applicationId, fiaRequestId, updatedFIARequest, isBypass)
        .then(() => {
          if (fiaRequestStore.fiaRequest) {
            setFIARequestOnApproveOrSend(fiaRequestStore.fiaRequest);

            setIsUpdatedFIAPending(fiaRequestStore.fiaRequest);
          }
        })
        .catch((error) => {
          result.isSuccess = false;
          result.error = error;

          if (error?.response?.status == 400) {
            setErrors(error.response.data.additionalInfo);

            AppToaster.show({
              message: <div>The FIA Request is missing required data.</div>,
              intent: Intent.WARNING,
            });
          } else {
            AppToaster.show({
              message: <div>{genericErrorMessage}</div>,
              intent: Intent.DANGER,
            });
          }
        });
    } else {
      await fiaRequestStore
        .approveAndSend(
          applicationId,
          fiaRequestId,
          updatedFIARequest,
          isBypass
        )
        .then(() => {
          if (fiaRequestStore.fiaRequest) {
            setFIARequestOnApproveOrSend(fiaRequestStore.fiaRequest);
            setIsUpdatedFIAPending(fiaRequestStore.fiaRequest);
          }
        })
        .catch((error) => {
          result.isSuccess = false;
          result.error = error;

          if (error?.response?.status == 400) {
            setErrors(error.response.data.additionalInfo);

            AppToaster.show({
              message: <div>The FIA Request is missing required data.</div>,
              intent: Intent.WARNING,
            });
          } else {
            AppToaster.show({
              message: <div>{genericErrorMessage}</div>,
              intent: Intent.DANGER,
            });
          }
        });
    }

    return result;
  };

  const openResendDialog = (onSuccessfulResend: () => void) => {
    setIsResendLoading(true);

    if (documentErrors?.length > 0) {
      if (userStore.user?.canBypassFIARequestApproveAndSend) {
        dialogsViewerStore.confirm({
          content: (
            <div>
              The FIA Request is missing Required Documents:
              <ul>
                {documentErrors.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
              <br />
              Are you sure you want to resend?
            </div>
          ),
          rightSideDisplay: true,
          onClose: () => {
            setIsResendLoading(false);
          },
          onConfirm: () => {
            return new Promise((resolve) => {
              dialogsViewerStore.setIsFIARequestResendDialogOpen(true, {
                applicationId: applicationId,
                fiaRequestId: fiaRequest.id,
                institution: institution,
                setIsResendLoading: setIsResendLoading,
                isBypass: true,
                // TODO: Right side display
                onSuccessfulResend: () => {
                  if (fiaRequestStore.fiaRequest) {
                    setFIARequestOnApproveOrSend(fiaRequestStore.fiaRequest);
                    onSuccessfulResend();
                  }
                },
              });
              // Resolve the promise immediately
              resolve({ isSuccess: true, error: undefined });
            });
          },
        });
      } else {
        setIsResendLoading(false);
        AppToaster.show({
          message: (
            <div>
              The FIA Request is missing Required Documents:
              <ul>
                {documentErrors.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            </div>
          ),
          intent: Intent.WARNING,
        });
      }
    } else {
      dialogsViewerStore.setIsFIARequestResendDialogOpen(true, {
        applicationId: applicationId,
        fiaRequestId: fiaRequest.id,
        institution: institution,
        setIsResendLoading: setIsResendLoading,
        isBypass: false,
        // TODO: Right side display
        onSuccessfulResend: () => {
          if (fiaRequestStore.fiaRequest) {
            setFIARequestOnApproveOrSend(fiaRequestStore.fiaRequest);
            onSuccessfulResend();
          }
        },
      });
    }
  };

  const approveAndSend = async (updatedFIARequest: FIARequestUpdateDTO) => {
    // Approve and Send
    setIsSaving(true);

    if (documentErrors?.length > 0) {
      if (userStore.user?.canBypassFIARequestApproveAndSend) {
        dialogsViewerStore.confirm({
          content: (
            <div>
              The FIA Request is missing Required Documents:
              <ul>
                {documentErrors.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
              <br />
              Are you sure you want to send?
            </div>
          ),
          rightSideDisplay: true,
          onClose: () => {
            setIsSaving(false);
          },
          onConfirm: async () => {
            dialogsViewerStore.confirm({
              content: updatedFIARequest.isSendingMethodMail
                ? fiaRequestMailSubmissionDialog()
                : fiaRequestSubmissionDialog(),

              rightSideDisplay: true,
              onClose: () => {
                setIsSaving(false);
              },
              onConfirm: async () => {
                const result = await executeApproveAndSend(
                  updatedFIARequest,
                  true
                );
                return result;
              },
            });
            return { isSuccess: false, error: undefined };
          },
        });
      } else {
        setIsSaving(false);
        AppToaster.show({
          message: (
            <div>
              The FIA Request is missing Required Documents:
              <ul>
                {documentErrors.map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            </div>
          ),
          intent: Intent.WARNING,
        });
      }
    } else {
      dialogsViewerStore.confirm({
        content: updatedFIARequest.isSendingMethodMail
          ? fiaRequestMailSubmissionDialog()
          : fiaRequestSubmissionDialog(),

        rightSideDisplay: true,
        onClose: () => {
          setIsSaving(false);
        },
        onConfirm: async () => {
          const result = await executeApproveAndSend(updatedFIARequest, false);
          return result;
        },
      });
    }
  };

  return {
    isSaving,
    setIsSaving,
    ready,
    fiaRequestMailSubmissionDialog,
    fiaRequestSubmissionDialog,
    catchPreviewFIARequestErrors,
    isActionExecuting,
    setIsActionExecuting,
    fiaRequest,
    setFIARequest,
    errors,
    documentErrors,
    setErrors,
    isResendLoading,
    setIsResendLoading,
    isPreviewLoading,
    setIsPreviewLoading,
    isViewSentFIALoading,
    setIsViewSentFIALoading,
    isCancelLoading,
    setIsCancelLoading,
    isMarkAsReceivedLoading,
    setIsMarkAsReceivedLoading,
    canSendFIARequest,
    isMarkAsNotReceivedLoading,
    setIsMarkAsNotReceivedLoading,
    validateFIARequestRequirements,
    approveAndSend,
    fiaRequestResendDTO,
    setFIARequestResendDTO,
    openResendDialog,
  };
};
