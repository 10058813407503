import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { userApiClient } from "../../lib/apiClients/user/userApiClient";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { newFormUser } from "../Forms/UserForm/UserForm";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { User, UserRoles } from "../../types/User";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { UserDetails } from "./UserDetails";
import { AppToaster } from "../Toast/Toast";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { handleFacilitiesMultiselectChange } from "./User/utils";
import { corporateApiClient } from "../../lib/apiClients/corporate/corporateApiClient";

export interface UserCreationDialogProps extends BaseDialogProps {}

export const UserCreationDialog: React.FC<UserCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const defaultFormValues: Partial<User> = {
    role: dialogsViewerStore.userCreationDialogOpenOptions?.role || undefined,
    isExternal:
      dialogsViewerStore.userCreationDialogOpenOptions?.isExternal || false,
    corporateId:
      dialogsViewerStore.userCreationDialogOpenOptions?.corporateId || "",
    facilityIds: dialogsViewerStore.userCreationDialogOpenOptions?.facilityId
      ? [dialogsViewerStore.userCreationDialogOpenOptions.facilityId]
      : [],
  };

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveAndSending, setIsSaveAndSending] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<User>({
    ...newFormUser,
    ...defaultFormValues,
  } as User);
  const [ready] = useState<boolean>(true);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    handleFacilitiesMultiselectChange(newUser, setNewUser);
  }, [newUser.facilityIds.length]);

  useEffect(() => {
    if (!!newUser.corporateId && newUser.role === UserRoles.CorporateUser) {
      getCorporateDetailAndAssignCorporateValuesToUser(newUser.corporateId);
    }
  }, [newUser.corporateId]);

  const getCorporateDetailAndAssignCorporateValuesToUser = async (
    corporateId: string
  ) => {
    if (!!corporateId) {
      const corporate = await corporateApiClient.getCorporateDetail(
        corporateId
      );

      setNewUser((prevUser) => ({
        ...prevUser,
        phoneNumber: corporate.companyPhone,
        address: corporate.companyAddress,
      }));
    }
  };

  const setSavingState = (
    shouldSendInviteEmail: boolean,
    newState: boolean
  ) => {
    if (shouldSendInviteEmail) {
      setIsSaveAndSending(newState);
    } else {
      setIsSaving(newState);
    }
  };

  //Is External = FacilityUser || CorporateUser
  const shouldShowInviteButton = newUser.isExternal;

  const formId = "create-user-form";
  return (
    <>
      {ready && (
        <Dialog
          portalClassName="mdr-dialog user-creation-dialog"
          isOpen={isOpen}
          hasBackdrop={false}
          isCloseButtonShown={true}
          title={"Create User"}
          onClose={closeDialog}
        >
          <div className={Classes.DIALOG_BODY}>
            <BaseForm<User>
              formId={formId}
              value={newUser}
              onErrors={(errors) => setErrors(errors)}
              formValueState={{
                formValue: newUser,
                setFormValue: setNewUser,
              }}
              onFormSubmit={async (user) => {
                const clickedButtonId = document.activeElement?.id;
                const shouldSendInviteEmail =
                  clickedButtonId === "user-save-and-send-invite-button";

                setSavingState(shouldSendInviteEmail, true);

                const result = { isSuccess: true, error: undefined };

                await userApiClient
                  .createUser(user, shouldSendInviteEmail)
                  .then(() => {
                    gridStore.refresh();
                    dialogsViewerStore.userCreationDialogOpenOptions
                      ?.onSubmit &&
                      dialogsViewerStore.userCreationDialogOpenOptions.onSubmit();
                    submitDialog();
                    //Reset Form On Create
                    setNewUser(newFormUser as User);
                  })
                  .catch((error) => {
                    result.isSuccess = false;
                    result.error = error;

                    if (error?.response?.status == 400) {
                      setErrors(error.response.data.additionalInfo);
                    }

                    AppToaster.show({
                      message: <div>{genericErrorMessage}</div>,
                      intent: Intent.DANGER,
                    });
                  })
                  .finally(() => {
                    setSavingState(shouldSendInviteEmail, false);
                  });
              }}
              computedStateOption={{
                fields: ["role"],
                getComputedState: userApiClient.getComputedState,
              }}
            >
              <UserDetails
                user={newUser}
                errors={errors}
                setUserForm={setNewUser}
                formId={formId}
              />
            </BaseForm>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <Button
              minimal
              intent={Intent.DANGER}
              text="Cancel"
              onClick={() => {
                setNewUser(newFormUser as User);
                closeDialog();
              }}
            />
            <FormSubmitButton
              buttonId="user-save-button"
              isSaving={isSaving}
              formId={formId}
              text="Save"
            />
            {!!shouldShowInviteButton && (
              <FormSubmitButton
                buttonId="user-save-and-send-invite-button"
                isSaving={isSaveAndSending}
                formId={formId}
                text="Save &amp; Send Invite"
              />
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};
