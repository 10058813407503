import { NonIdealState } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  GridComponent,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { set } from "lodash";
import React, { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { ChecklistItem } from "../../../../types/Checklist";
import { PdfDocuments } from "../../../Document/PdfDocuments";
import { WithTooltip } from "../../../Tooltip/Tooltip";
import "../../../Checklist/styles.scss";

export interface ExternalUserSummaryChecklistGridProps {
  checklistItems: ChecklistItem[];
}

const ExternalUserSummaryChecklistGridComponent: FC<
  ExternalUserSummaryChecklistGridProps
> = ({ checklistItems }) => {
  const [localChecklistItems] = React.useState(checklistItems);
  const gridRef = React.useRef<GridComponent | null>(null);

  React.useEffect(() => {
    set(gridRef, "current.dataSource", checklistItems);

    //Databound
    if (gridRef.current && checklistItems.length > 0) {
      gridRef.current.dataSource = checklistItems;
      gridRef.current.dataBound = () => {
        gridRef.current?.detailExpandAll();
      };
    }
  }, [checklistItems]);

  const updateGridRow = (
    index: number | string,
    updatedChecklistItem: ChecklistItem
  ) => {
    if (gridRef.current) {
      gridRef.current.setRowData(index, updatedChecklistItem);
    }
  };

  const findGridChecklist = (checklistId: string) => {
    return gridRef.current
      ?.getRowsObject()
      .find(
        (rowObject) => checklistId === (rowObject.data as ChecklistItem)?.id
      )?.data as ChecklistItem;
  };

  return (
    <>
      {localChecklistItems?.length <= 0 && (
        <NonIdealState title="No Information Available" />
      )}
      {localChecklistItems?.length > 0 && (
        <>
          <GridComponent
            id="checklist-grid"
            ref={gridRef}
            allowSorting={true}
            allowFiltering={true}
            filterSettings={{
              type: "Excel",
            }}
            detailTemplate={(checklistItem: ChecklistItem) => {
              return (
                <>
                  <PdfDocuments
                    checklistItem={checklistItem}
                    disableEditDoc={true}
                    disableRenameDoc={true}
                    disablePreviewDoc={true}
                    disableDeleteDoc={true}
                    disableVerifyDoc={true}
                    setUIChecklistItemFileCount={(_, action, updatedCount) => {
                      const ogChecklistItem = findGridChecklist(
                        checklistItem.id
                      );
                      let count = ogChecklistItem.count ?? 0;

                      if (action === "minus") {
                        count -= updatedCount;
                      } else if (action === "plus") {
                        count += updatedCount;
                      }

                      //Set Status to Needs Review On Doc Upload.
                      updateGridRow(ogChecklistItem.id, {
                        ...ogChecklistItem,
                        count,
                      });
                    }}
                  />
                </>
              );
            }}
          >
            <Inject services={[DetailRow]} />
            <ColumnsDirective>
              <ColumnDirective
                field="id"
                isPrimaryKey={true}
                visible={false}
                width="100"
              />
              <ColumnDirective
                headerText="Name"
                field="displayName"
                width="100"
                template={(checklistItem: ChecklistItem) => {
                  return (
                    <WithTooltip
                      shouldShowTooltip={true}
                      content={checklistItem.displayName || ""}
                    >
                      <td className={"checklist-grid-text"}>
                        {checklistItem.displayName}
                      </td>
                    </WithTooltip>
                  );
                }}
              />
              <ColumnDirective
                headerText="Files"
                field="count"
                type="number"
                width="30"
              />
            </ColumnsDirective>
          </GridComponent>
        </>
      )}
    </>
  );
};

export const ExternalUserSummaryChecklistGrid = withAITracking(
  reactPlugin,
  ExternalUserSummaryChecklistGridComponent,
  "ExternalUserSummaryChecklistGrid",
  "AI-flex-container"
);
