import baseApiClient from "../apiClients/baseApiClient";
import { FeatureToggleSet } from "./FeatureToggles";

class FeatureToggleApiClient {
  getFeatureToggleSet = async () => {
    return baseApiClient.get<FeatureToggleSet>("feature-toggle/toggle");
  };
}

export const featureToggleApiClient = new FeatureToggleApiClient();
