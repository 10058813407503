import { ApplicationStatuses } from "../../../types/Application";
import { ContactMethods, InstitutionTypes } from "../../../types/Institution";

export type FIARequest =
  | FIARequestNewDTO
  | FIARequestUpdateDTO
  | FIARequestCreateDTO
  | FIARequestApproveSendDTO;

export interface FIARequestValidationsDTO {
  canBeUpdated?: boolean;
  canUpdateDocuments?: boolean;
  canBeCanceled?: boolean;
  canBeApprovedAndSent?: boolean;
  canBeResent?: boolean;
  canBeMarkAsReceived?: boolean;
  canBeMarkAsNotReceived?: boolean;
  shouldDownloadInsteadOfPreview?: boolean;
  isSendingMethodMail?: boolean;
  canApproveOnly?: boolean;
  hasInvalidFaxInformation?: boolean;
  hasInvalidEmailInformation?: boolean;
  isPending?: boolean;
}

export interface FIARequestCancelDTO {
  cancelReasons_AsList?: number[];
}

export interface FIARequestNewDTO
  extends FIARequestValidationsDTO,
    FIARequestDetails {}

export interface FIARequestCreateDTO extends FIARequestDetails {}

export interface FIARequestApproveSendDTO extends FIARequestDetails {
  hasRequestDocuments?: boolean;
}

export interface FIARequestResendDTO {
  followUpReason?: number;
  followUpSecondaryReason?: number;
}

export interface FIARequestUpdateDTO
  extends FIARequestValidationsDTO,
    FIARequestInfo,
    FIARequestDetails {
  id?: string;
  requestStatus?: FIARequestStatusTypes;
  requestStatus_AsString?: string;
  institutionName?: string;
  updatedAt?: string;
  cancelReasons_AsList?: number[];
  lastSentFIARequestDocumentId?: string;
}

export interface FIARequestInfo {
  requestId?: string;
  requestNumber_AsString?: string;
  submittedDate?: string;
  approvedDate?: string;
  createdDate?: string;
  receivedDate?: string;
  canceledDate?: string;
  estimatedReturnDate?: string;
  requestStatus_AsString?: string;
  followUpReason_AsString?: string;
  followUpSecondaryReason_AsString?: string;
}

export interface FIARequestDetails extends FIARequestContacts {
  id?: string;
  isResidentIncluded?: boolean;
  isSpouseIncluded?: boolean;
  checklistItemId?: string;
  institutionId?: string;
  institutionContactMethod_AsString?: string;
  institutionDisplayNotes?: string;
  institutionAccountNumbers_AsList?: string[];
  statementDateRangeStart?: string;
  statementDates_AsList?: string[];
  statementCycleEndDay?: number;
  statementFrequency?: number;
  annotation?: string;
  statementDateType?: number;
  hasInvalidFaxInformation?: boolean;
  hasInvalidEmailInformation?: boolean;
}

export interface FIARequestContacts {
  residentFullName?: string;
  residentDateOfBirth?: string;
  residentSSN?: string;
  spouseFullName?: string;
  spouseDateOfBirth?: string;
  spouseSSN?: string;
}

export interface FIARequestODataReadDTO {
  id: string;
  applicationId: string;
  institutionId: string;
  institutionName: string;
  institutionType: InstitutionTypes;
  institutionType_AsString: string;
  institutionSendingContactMethod: ContactMethods;
  institutionSendingContactMethod_AsString: string;
  institutionAccountNumbers: string;
  requestNumber: string;
  requestNumber_AsString: string;
  fileNumber: string;
  residentFullName: string;
  spouseFullName: string;
  applicationDueDate_AsDateOnly: Date;
  applicationStatus: ApplicationStatuses;
  applicationStatus_AsString: string;
  requestStatus: FIARequestStatusTypes;
  requestStatus_AsString: string;
  receivedStatus: FIARequestReceivedStatusTypes;
  receivedStatus_AsString: string;
  lastSubmittedAt: Date;
  estimatedReturnDate: Date;
  markedAsReceivedAt: Date;
  canceledAt: Date;
  dueIn: number;
}

export interface FIARequestMetricReadDTO {
  fiaRequestCount: string;
  attentionFraction: string;
  attentionPercentage: string;
  averageAttentionAge: string;
}

export enum FIARequestStatusTypes {
  Open = 1,
  Submitted = 2,
  Error = 3,
  Complete = 4,
  Canceled = 5,
}

export enum FIARequestReceivedStatusTypes {
  Outstanding = 1,
  ReceivedOnTime = 2,
  ReceivedPastDue = 3,
}

export enum FinancialAccountTypes {
  InstitutionChecking = 7,
  InstitutionSavings = 8,
}

export enum StatementDateTypes {
  StartMonth = 1,
  SpecificMonth = 2,
}

export enum FIARequestFollowUpActionTypes {
  MarkComplete = 1,
  Resend = 2,
}

export enum FIARequestFollowUpReasonTypes {
  MonthPassedNeedUpdatedStatement = 1,
  NoResponseReceived = 2,
  IncompleteResponseReceivedOnPreviousRequest = 3,
  PreviousRequestHasBeenCorrected = 4,
}
