import { BaseDialogProps } from "./types/types";
import React from "react";
import { DocumentPreview } from "../Document/DocumentPreview";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

export interface DocumentViewerDialogProps extends BaseDialogProps {
  getDocument?: Promise<BlobPart>;
}

export const DocumentViewerDialog: React.FC<DocumentViewerDialogProps> = ({
  getDocument,
  isOpen,
  closeDialog,
}) => {
  return (
    // Dev Note: Syncfusion Dialog needs to be wrapped in a parent or else it breaks routing.
    // TODO: build out a re-usable portal that we can mount all dialogs to.
    <div id="document-viewer-dialog-target">
      <DialogComponent
        header={"Document Viewer"}
        className="document-viewer-dialog"
        width="500px"
        position={{ X: "left", Y: "top" }}
        resizeHandles={["SouthEast"]}
        visible={isOpen}
        close={closeDialog}
        showCloseIcon
        enableResize
        allowDragging
      >
        <DocumentPreview getDocument={getDocument} />
      </DialogComponent>
    </div>
  );
};
