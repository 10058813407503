import { get } from "lodash";
import { useState } from "react";
import { schemaStore } from "../../../stores/SchemaStore";
import { NewChecklistItem } from "../../../types/Checklist";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { getInstitutionAsyncAutocompleteFieldProps } from "../types";
import { FormSchemaBuilder } from "../types";
import {
  Vehicle,
  ownedEntityDocumentTypes,
} from "../../../types/ChecklistDocumentType";
import { applicationStore } from "../../../stores/ApplicationStore";
import { usStateOptions } from "../../../constants/usStateOptions";

export const checklistSplitItemCreationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "checklistItem";
  const checklistItem = form as NewChecklistItem;
  const [showInstitution, setShowInstitution] = useState<boolean>(false);

  const documentType = schemaStore.getFieldSchemaByName(
    "checklistItem",
    "documentType"
  );

  const filterChecklistDocumentTypes = (docTypeOptions: Option[]): Option[] => {
    const isApplicationStateNC =
      applicationStore.application?.facility?.facilityAddress.state.toString() ===
      usStateOptions.find((state) => state.label === "NC")?.value;

    const docTypesToFilter = isApplicationStateNC
      ? ownedEntityDocumentTypes.filter(
          (docType) =>
            docType !== Vehicle.AutomobileRegistrationTitleValueVerification
        )
      : ownedEntityDocumentTypes;

    return docTypeOptions.filter(
      (entity: Option) => !docTypesToFilter.includes(entity.value)
    );
  };

  const documentTypes = false
    ? (documentType.optionValues as Option[])
    : filterChecklistDocumentTypes(documentType.optionValues as Option[]);

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "documentType"),
      optionValues: [...documentTypes],
      value: checklistItem.documentType,
      onSubmit: (documentType: Option) => {
        onValueChange({ documentType: documentType.value });
      },
      errorMessages: get(errors, "documentType"),
      isRequired: true,
      usState: applicationStore.application?.facility?.facilityAddress.state,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "documentCategory"),
      value: checklistItem.documentCategory,
      onSubmit: (documentCategory: Option) => {
        onValueChange({ documentCategory: documentCategory.value });
      },
      width: "45%",
      errorMessages: get(errors, "documentCategory"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "responsiblePartyType"),
      value: checklistItem.responsiblePartyType,
      onSubmit: (responsiblePartyType: Option) => {
        responsiblePartyType.label === "Institution"
          ? setShowInstitution(true)
          : setShowInstitution(false);
        onValueChange({ responsiblePartyType: responsiblePartyType.value });
      },
      width: "45%",
      errorMessages: get(errors, "responsiblePartyType"),
      isRequired: true,
    } as AllFields,
    {
      width: "45%",
      isRequired: false,
      hidden: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "institutionId"),
      ...getInstitutionAsyncAutocompleteFieldProps(),
      value: checklistItem.institutionId,
      onSubmit: (options: { id_AsString: string }) => {
        checklistItem.institutionId = options?.id_AsString;
      },
      groupByActiveStatus: true,
      width: "45%",
      errorMessages: get(errors, "institutionId"),
      isRequired: false,
      hidden: !showInstitution,
    } as unknown as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "shortDescription"),
      onSubmit: (shortDescription) => {
        onValueChange({ shortDescription });
      },
      errorMessages: get(errors, "shortDescription"),
      value: checklistItem.shortDescription,
      maxLength: 256,
    } as AllFields,
  ];
};
