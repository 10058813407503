import { FC, useState } from "react";
import { Comment, CommentFilters } from "../../../../types/Comment";
import RelativityCard from "../../../Card/RelativityCard";
import "../../styles.scss";
import { Intent, Button, Icon } from "@blueprintjs/core";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { BaseField } from "../../../Forms/BaseField/BaseField";
import {
  commentFilteringFormSchema,
  defaultCommentFilters,
} from "../../../Forms/CommentForm/CommentFilteringForm";
import { IconNames } from "@blueprintjs/icons";

export interface CommentsFiltersProps {
  comments: Comment[];
  shouldFetchRelatedComments: boolean;
  commentFilters: CommentFilters;
  setCommentFilters: (commentFilters: CommentFilters) => void;
  loadComments: (
    shouldFetchRelatedComments: boolean,
    filters: CommentFilters | undefined
  ) => void;
}

export const CommentsFilters: FC<CommentsFiltersProps> = ({
  comments,
  shouldFetchRelatedComments,
  commentFilters,
  setCommentFilters,
  loadComments,
}) => {
  const [fieldKey, setFieldKey] = useState<boolean>(false);

  const clearFilters = () => {
    setCommentFilters(defaultCommentFilters);
    loadComments(shouldFetchRelatedComments, defaultCommentFilters);
    setFieldKey(!fieldKey);
  };

  return (
    <>
      <RelativityCard className="comments-filters" columns={1}>
        <RelativitySubCard columns={6}>
          <>
            <BaseField
              {...commentFilteringFormSchema.applicationIds(
                commentFilters,
                setCommentFilters,
                comments,
                fieldKey,
                shouldFetchRelatedComments,
                loadComments
              )}
            />
            <BaseField
              {...commentFilteringFormSchema.levels(
                commentFilters,
                setCommentFilters,
                comments,
                fieldKey,
                shouldFetchRelatedComments,
                loadComments
              )}
            />
            <BaseField
              {...commentFilteringFormSchema.sources(
                commentFilters,
                setCommentFilters,
                comments,
                fieldKey,
                shouldFetchRelatedComments,
                loadComments
              )}
            />
            <BaseField
              {...commentFilteringFormSchema.createdBy(
                commentFilters,
                setCommentFilters,
                comments,
                fieldKey,
                shouldFetchRelatedComments,
                loadComments
              )}
            />
            <BaseField
              {...commentFilteringFormSchema.createdAt(
                commentFilters,
                setCommentFilters,
                shouldFetchRelatedComments,
                loadComments
              )}
            />
          </>
        </RelativitySubCard>
        <div className="comments-filters__actions">
          <div className="comments-sort">
            <BaseField
              {...commentFilteringFormSchema.sortBy(
                commentFilters,
                setCommentFilters,
                fieldKey,
                shouldFetchRelatedComments,
                loadComments
              )}
            />
            <Icon
              className="comments-sort__icon"
              icon={
                commentFilters.sortAscending
                  ? IconNames.SortAlphabetical
                  : IconNames.SortAlphabeticalDesc
              }
              onClick={() => {
                if (!commentFilters.sortBy) {
                  return;
                }
                const commentFiltersToSet = {
                  ...commentFilters,
                  sortAscending: !commentFilters.sortAscending,
                };
                setCommentFilters(commentFiltersToSet);
                loadComments(shouldFetchRelatedComments, commentFiltersToSet);
              }}
            />
          </div>
          <Button
            className="comments-filters__actions__clear"
            intent={Intent.NONE}
            text="Clear Filters"
            onClick={clearFilters}
            icon={IconNames.FILTER_REMOVE}
          />
        </div>
      </RelativityCard>
    </>
  );
};
