import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { IconNames } from "@blueprintjs/icons";
import { useState, useEffect } from "react";
import "../../Grid/Views/styles.scss";
import { MatchupGridV2CannedViewPickerTypes } from "./types";

export interface MatchupGridV2CannedViewPickerProps {
  setGridCannedView: Function;
}

export const MatchupGridV2CannedViewPicker: React.FC<MatchupGridV2CannedViewPickerProps> =
  observer((props) => {
    const [cannedView, setCannedView] =
      useState<MatchupGridV2CannedViewPickerTypes>("Active");

    useEffect(() => {
      props.setGridCannedView(cannedView);

      return () => {
        props.setGridCannedView("Active");
      };
    }, [cannedView, setCannedView]);

    return (
      <div className="canned-view-picker-popover">
        <Popover2
          placement="bottom-end"
          content={
            <Menu>
              <MenuItem
                text="Active"
                onClick={() => {
                  setCannedView("Active");
                }}
              />
              <MenuItem
                text="Closed"
                onClick={() => {
                  setCannedView("Closed");
                }}
              />
              <MenuItem
                text="All"
                onClick={() => {
                  setCannedView("All");
                }}
              />
            </Menu>
          }
        >
          <div className="canned-view-picker-text">
            <div>{`${cannedView}`}</div>
            <div>
              <Icon iconSize={15} icon={IconNames.CHEVRON_DOWN} />
            </div>
          </div>
        </Popover2>
      </div>
    );
  });
