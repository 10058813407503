import {
  AsyncSettingsModel,
  RemovingEventArgs,
  UploaderModel,
  UploadingEventArgs,
} from "@syncfusion/ej2-react-inputs";
import { get } from "lodash";
import { Option } from "../../../components/Fields/types/fieldTypes";
import variables from "../../../config/variables";
import { getAccessToken } from "../../../lib/apiClients/baseApiClient";
import { schemaStore } from "../../../stores/SchemaStore";
import { Document, NewDocument } from "../../../types/Document";
import { buildFileDocumentCustomFormData } from "../../../types/FileDocument";
import {
  Template,
  TemplateData,
  templatesFolderName,
} from "../../../types/Template";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newFormTemplate: Template = {
  templateName: "",
  templateType: 0,
  templateStates_AsList: [],
  sendOrder: 0,
  originalFileName: "",
  fileName: "",
  documentId: undefined,
};

const modelName = "template";

const templateFormSchemaFields = {
  templateName: (
    template: TemplateData,
    onValueChange: (field: {}) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "templateName"),
      errorMessages: get(errors, "templateName"),
      value: template.templateName,
      onSubmit: (templateName) => {
        onValueChange({ templateName });
      },
      isRequired: true,
    } as AllFields;
  },
  templateType: (
    template: TemplateData,
    onValueChange: (field: {}) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "templateType"),
      errorMessages: get(errors, "templateType"),
      value: template.templateType,
      onSubmit: (value) => {
        const templateType = value as Option;
        onValueChange({ templateType: templateType.value });
      },
      width: "61%",
      isRequired: true,
    } as AllFields;
  },
  sendOrder: (
    template: TemplateData,
    onValueChange: (field: {}) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "sendOrder"),
      errorMessages: get(errors, "sendOrder"),
      value: template.sendOrder,
      onSubmit: (sendOrder) => {
        onValueChange({ sendOrder });
      },
      isRequired: true,
      width: "38%",
    } as AllFields;
  },
  templateState: (
    template: TemplateData,
    onValueChange: (field: {}) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "templateStates_AsList"),
      errorMessages: get(errors, "templateStates_AsList"),
      value: template.templateStates_AsList,
      onSubmit: (templateStates_AsList: number[]) => {
        onValueChange({ templateStates_AsList: templateStates_AsList });
      },
      width: "61%",
      isRequired: true,
    } as AllFields;
  },
  documentType: (
    template: TemplateData,
    onValueChange: (field: {}) => void,
    errors?: { [key: string]: string[] }
  ) => {
    // Update Written Auth Type if no states are selected
    if (template.templateStates_AsList?.length === 0) {
      template.documentType = undefined;
    }

    return {
      ...schemaStore.getFieldSchemaByName(modelName, "documentType"),
      errorMessages: get(errors, "documentType"),
      value: template.documentType,
      onSubmit: (value) => {
        const documentType = value as Option;
        onValueChange({ documentType: documentType.value });
      },
      width: "38%",
      disabled: template.templateStates_AsList?.length === 0,
    } as AllFields;
  },
  documentUploader: (
    template: Template,
    onValueChange: (field: {}) => void,
    errors?: { [key: string]: string[] }
  ) => {
    const onUploadingFile = (args: UploadingEventArgs) => {
      // Set Custom Data
      const doc: NewDocument = {
        originalFileName: args.fileData.name,
        folderName: templatesFolderName,
        entityType: 2,
        isChunked: true,
      };

      args.customFormData = buildFileDocumentCustomFormData(doc);

      onValueChange({
        documentId: undefined,
        fileName: undefined,
        originalFileName: undefined,
      });
    };

    const onChunkUploadingFile = (
      args: UploadingEventArgs,
      document: Document
    ) => {
      // Set Custom Data
      const doc: Partial<Document> = {
        id: document.id,
        fileName: document.fileName,
        originalFileName: args.fileData.name,
        folderName: templatesFolderName,
        entityType: 2,
        isChunked: true,
      };

      args.customFormData = buildFileDocumentCustomFormData(doc);

      onValueChange({
        documentId: undefined,
        fileName: undefined,
        originalFileName: undefined,
      });
    };

    const onRemovingFile = (args: RemovingEventArgs) => {
      const doc: Partial<Document> = {
        id: template.documentId,
        folderName: templatesFolderName,
        isChunked: true,
      };

      // Set Custom Data
      args.customFormData = buildFileDocumentCustomFormData(doc);

      onValueChange({
        documentId: undefined,
        fileName: undefined,
        originalFileName: undefined,
      });
    };

    return {
      errorMessages: get(errors, "fileName"),
      type: "File",
      label: `Template Document ${
        template.documentId ? ": " + template.originalFileName : ""
      } `,
      disabled: false,
      onSubmit: (doc: Document) => {
        onValueChange({
          documentId: doc.id,
          fileName: doc.fileName,
          originalFileName: doc.originalFileName,
        });
      },
      getAccessToken: getAccessToken,
      onUploading: onUploadingFile,
      onChunkUploading: onChunkUploadingFile,
      onRemoving: onRemovingFile,
      uploaderConfig: {
        multiple: false,
        allowedExtensions: ".pdf",
        asyncSettings: {
          chunkSize: 102400,
          removeUrl: `${variables.apiBaseUrl}api/document/remove`,
          saveUrl: `${variables.apiBaseUrl}api/document/upload`,
        } as AsyncSettingsModel,
      } as UploaderModel,
    } as AllFields;
  },
};

export const createTemplateFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const createTemplate = form as Template;

  return [
    templateFormSchemaFields.templateName(
      createTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.templateType(
      createTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.sendOrder(createTemplate, onValueChange, errors),
    templateFormSchemaFields.templateState(
      createTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.documentType(
      createTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.documentUploader(
      createTemplate,
      onValueChange,
      errors
    ),
  ];
};

export const updateTemplateFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const updateTemplate = form as Template;
  return [
    templateFormSchemaFields.templateName(
      updateTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.templateType(
      updateTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.sendOrder(updateTemplate, onValueChange, errors),
    templateFormSchemaFields.templateState(
      updateTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.documentType(
      updateTemplate,
      onValueChange,
      errors
    ),
    templateFormSchemaFields.documentUploader(
      updateTemplate,
      onValueChange,
      errors
    ),
  ];
};
