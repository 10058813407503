export enum ExceptionType {
  DocumentCorruptException = "document-corrupt-exception",
}

export interface ExceptionDetails {
  additionalInfo: string;
  detail: string;
  instance: string;
  status: number;
  title: string;
  type: string;
}

export interface ValidationFailure {
  propertyName?: string;
  errorMessage?: string;
}
