import variables from "../../config/variables";
import { loginRequest, msalInstance } from "../msal/msal";
import { ApiClient } from "@ucl/library";

export interface DecisionPayload {
  method: string;
  url: string;
  payload: {};
}

export const getAccessToken = async () => {
  try {
    const token = await msalInstance.acquireTokenSilent(loginRequest);
    return token.accessToken;
  } catch (e) {
    msalInstance.loginRedirect(loginRequest);
  }
};

export const getAuthRequestHeader = async (): Promise<
  Record<string, string>
> => {
  const accessToken = await getAccessToken();

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export default new ApiClient(`${variables.apiBaseUrl}api`, async (config) => {
  const authRequestHeader = await getAuthRequestHeader();
  if (authRequestHeader) {
    (config.headers as { Authorization: string }).Authorization =
      authRequestHeader.Authorization;
  }
  return config;
});
