import { H3 } from "@blueprintjs/core";
import { SavingSpinner } from "../../Loading/Spinners/SavingSpinner";
import { SectionHeaderProps } from "../types/fieldTypes";
import "./styles.scss";

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  label,
  hidden,
  inAction,
}) => {
  if (hidden) {
    return null;
  }
  return (
    <div className="section-header-container">
      <H3 className="section-header">{label}</H3>
      {!!inAction && <SavingSpinner />}
    </div>
  );
};
