import { Dispatch, SetStateAction } from "react";
import "./styles.scss";
import { applicationStore } from "../../stores/ApplicationStore";
import { medicalCoverageOwnedEntityStore } from "../../stores/OwnedEntityStores/MedicalCoverageOwnedEntityStore";
import { incomeOwnedEntityStore } from "../../stores/OwnedEntityStores/IncomeOwnedEntityStore";
import { BillCard, newBillId } from "./Cards/BillCard";
import { billOwnedEntityStore } from "../../stores/OwnedEntityStores/BillOwnedEntityStore";
import { Asset, NewAsset } from "../../types/Application/Asset";
import { Bill, NewBill } from "../../types/Application/Bill";
import { newBill } from "../Forms/ApplicationForm/Bill";
import { newIncome } from "../Forms/ApplicationForm/Income";
import { Income, NewIncome } from "../../types/Application/Income";
import { newMedicalCoverage } from "../Forms/ApplicationForm/MedicalCoverage";
import {
  MedicalCoverage,
  NewMedicalCoverage,
} from "../../types/Application/MedicalCoverage";
import { newAsset } from "../Forms/ApplicationForm/Assets";
import { AssetsFormCard, newAssetId } from "./Cards/Asset/AssetsFormCard";
import { IncomesFormCard, newIncomeId } from "./Cards/Income/IncomesFormCard";
import {
  MedicalCoveragesFormCard,
  newMedicalCoverageId,
} from "./Cards/MedicalCoverage/MedicalCoveragesFormCard";
import {
  OwnedEntityType,
  assetTypeString,
  billTypeString,
  incomeTypeString,
  medicalCoverageTypeString,
  useOwnedEntityComponent,
} from "./UseOwnedEntityComponent";

export interface OwnedEntityComponentProps {
  onSave?: () => void;
  onCancel?: () => void;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  ownedEntityTypeString: string;
  setOwnedEntityTypeString: (ownedEntityType: string) => void;
  localAssetTypeSchema?: any;
  existingOwnedEntity?: OwnedEntityType;
  showCancelButton: boolean;
  updateAsset?: () => Promise<void>;
  refreshOwnedEntity?: () => void;
}

export const OwnedEntityComponent: React.FC<OwnedEntityComponentProps> = (
  props
) => {
  const { setOwnedEntityToDisplay } = useOwnedEntityComponent();

  return (
    <div>
      {props.ownedEntityTypeString === medicalCoverageTypeString && (
        <MedicalCoveragesFormCard
          medicalCoverages={setOwnedEntityToDisplay<
            MedicalCoverage,
            NewMedicalCoverage
          >(
            props.existingOwnedEntity,
            newMedicalCoverage,
            newMedicalCoverageId
          )}
          canEdit={!!applicationStore.applicationCanBeEdited}
          createAndUpdateMedicalCoverages={async () => {
            await medicalCoverageOwnedEntityStore.saveLocalMedicalCoverages();
            props.onSave && props.onSave();
          }}
          showCancelButton={props.showCancelButton}
          onCancel={props.onCancel}
          showAddButton={false}
        />
      )}
      {props.ownedEntityTypeString === incomeTypeString && (
        <IncomesFormCard
          incomes={setOwnedEntityToDisplay<Income, NewIncome>(
            props.existingOwnedEntity,
            newIncome,
            newIncomeId
          )}
          createAndUpdateIncomes={async () => {
            await incomeOwnedEntityStore.saveLocalIncomes();
            props.refreshOwnedEntity?.();
            props.onSave && props.onSave();
          }}
          formIsSaving={props.isSaving}
          setFormIsSaving={props.setIsSaving}
          shouldDisableForm={props.isSaving}
          setShouldDisableForm={props.setIsSaving}
          showCancelButton={props.showCancelButton}
          onCancel={props.onCancel}
          showAddButton={false}
        />
      )}

      {props.ownedEntityTypeString === assetTypeString &&
        props.localAssetTypeSchema && (
          <AssetsFormCard
            assets={setOwnedEntityToDisplay<Asset, NewAsset>(
              props.existingOwnedEntity,
              newAsset,
              newAssetId
            )}
            assetType={props.localAssetTypeSchema.type}
            title={props.localAssetTypeSchema.title}
            assetFormSchema={props.localAssetTypeSchema.asset}
            shouldSaveEntityToLocalStore={true}
            createAsset={async (asset: NewAsset) => {
              await applicationStore.createApplicationOwnedEntity({
                page: "asset",
                ownedEntity: asset,
              });
              props.refreshOwnedEntity?.();
              props.onSave && props.onSave();
            }}
            updateAsset={props.updateAsset}
            shouldDisableForm={props.isSaving}
            setShouldDisableForm={props.setIsSaving}
            formIsSaving={props.isSaving}
            setFormIsSaving={props.setIsSaving}
            showCancelButton={props.showCancelButton}
            onCancel={props.onCancel}
            showAddButton={false}
          />
        )}

      {props.ownedEntityTypeString === billTypeString && (
        <BillCard
          bills={setOwnedEntityToDisplay<Bill, NewBill>(
            props.existingOwnedEntity,
            newBill,
            newBillId
          )}
          createAndUpdateBills={async () => {
            await billOwnedEntityStore.saveLocalBills();
            props.onSave && props.onSave();
          }}
          formIsSaving={props.isSaving}
          setFormIsSaving={props.setIsSaving}
          shouldDisableForm={props.isSaving}
          setShouldDisableForm={props.setIsSaving}
          showCancelButton={props.showCancelButton}
          onCancel={props.onCancel}
          showAddButton={false}
        />
      )}
    </div>
  );
};
