import {
  AllFields,
  SectionHeaderProps,
  BaseFieldProps,
} from "../../Fields/types/fieldTypes";
import { buildField } from "../../Fields";
import { FormDisabledContext } from "../BaseForm/BaseForm";
import React from "react";

export type BaseFieldComponentProps = (AllFields | SectionHeaderProps) & {
  idx?: number;
  handleOnBlur?: any;
};
export const BaseField: React.FC<BaseFieldComponentProps> = (props) => {
  const formIsDisabled = React.useContext(FormDisabledContext);

  return buildField(
    {
      ...props,
      ...{
        disabled: formIsDisabled || (props as BaseFieldProps).disabled,
      },
    },
    props.idx || 0,
    props.handleOnBlur
  );
};
