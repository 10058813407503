import { IconNames } from "@blueprintjs/icons";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { Document } from "../../types/Document";
import {
  DocumentAction,
  DocumentActionRender,
  DocumentActionTypes,
} from "./useDocumentPagesViewer";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { downloadFile } from "./PdfDocuments";
import { AppToaster } from "../Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { applicationStore } from "../../stores/ApplicationStore";

export const useDocument = () => {
  /** This function is used to get the document from the cache or from the server */
  const getDocument = (documentId: string): Promise<BlobPart> => {
    let getDoc: Promise<BlobPart> | undefined = undefined;
    getDoc = documentApiClient.downloadDocument(documentId);

    return getDoc;
  };

  const fetchDocumentsByIds = async (documentIds: string[]) => {
    return documentApiClient.fetchDocumentsByIds(documentIds);
  };

  const getStampedDocument = (documentId: string): Promise<BlobPart> => {
    return documentApiClient.getStampedDocument(
      documentId,
      applicationStore.application?.id ?? ""
    );
  };

  const getDocumentPage = (
    documentId: string,
    pageId: number
  ): Promise<BlobPart> => {
    let getDoc: Promise<BlobPart> | undefined = undefined;
    getDoc = documentApiClient.downloadDocumentPage(documentId, pageId);

    return getDoc;
  };

  /** This function is used to download the document from the server */
  const downloadDocument = (documentId: string): Promise<BlobPart> => {
    return documentApiClient.downloadDocument(documentId);
  };

  /** This function is used to get the blob url from the blob part */
  const getDocumentBlobUrl = (blobPart: BlobPart) => {
    return getBlobUrl(blobPart, "application/pdf");
  };

  const getDocumentPageBlobUrl = (blobPart: BlobPart) => {
    return getBlobUrl(blobPart, "image/png");
  };

  const getDocumentImageBlobUrl = (blobPart: BlobPart) => {
    return getBlobUrl(blobPart, "image/png");
  };

  const getBlobUrl = (blobPart: BlobPart, blobType: string) => {
    const blobFile = new File([new Blob([blobPart])], "Preview", {
      type: blobType,
    });

    const blobUrl = URL.createObjectURL(blobFile);
    return blobUrl;
  };

  const getBase64Url = async (base64Data: string) => {
    // Fetch
    const base64Response = await fetch(base64Data);

    // Get Url
    const blob = await base64Response.blob();
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  };

  const documentActionTypes = (
    props: {
      disableEditDoc?: boolean;
      disableRenameDoc?: boolean;
      disablePreviewDoc?: boolean;
      disableOpenNewTabDoc?: boolean;
      disableOpenNewTabStampedDoc?: boolean;
      disableDownloadDoc?: boolean;
    }
    // onConfirm?: () => void
  ): Array<
    | DocumentActionTypes
    | DocumentAction<Document>
    | DocumentActionRender<Document>
  > => {
    const documentActions: Array<
      | DocumentActionTypes
      | DocumentAction<Document>
      | DocumentActionRender<Document>
    > = [];

    if (!props.disablePreviewDoc) {
      documentActions.push({
        name: "preview",
        minimal: true,
        icon: IconNames.EyeOpen,
        onActionClick: (doc: Document) => {
          dialogsViewerStore.openDocumentViewerDialog({
            key: doc.id,
            getDocument: documentApiClient.downloadDocument(doc.id),
          });
        },
      } as DocumentAction<Document>);
    }

    if (!props.disableOpenNewTabDoc) {
      documentActions.push({
        name: "openInNewTab",
        minimal: true,
        icon: IconNames.DOCUMENT_OPEN,
        onActionClick: (doc: Document) => {
          openDocumentNewTab(doc, false);
        },
      } as DocumentAction<Document>);
    }

    if (!props.disableOpenNewTabStampedDoc) {
      documentActions.push({
        name: "openInNewTabStamped",
        className: "open-document-stamped-button",
        text: "w/#",
        minimal: true,
        icon: IconNames.DOCUMENT_OPEN,
        onActionClick: (doc: Document) => {
          openDocumentNewTab(doc, true);
        },
      } as DocumentAction<Document>);
    }

    if (!props.disableDownloadDoc) {
      documentActions.push({
        name: "download",
        minimal: true,
        icon: IconNames.Download,
        onActionClick: async (doc: Document) => {
          try {
            const response = (await documentApiClient.downloadDocument(
              doc.id
            )) as BlobPart;
            downloadFile(response, doc.originalFileName);
          } catch {
            AppToaster.show({
              message: (
                <div>
                  Something went wrong while processing the file. <br />
                  Please upload again or contact support.
                </div>
              ),
              intent: Intent.DANGER,
            });
          }
        },
      } as DocumentAction<Document>);
    }

    return documentActions;
  };

  const openDocumentNewTab = async (document: Document, stamped: boolean) => {
    // Open document in new tab
    // We must open the new tab synchronously so that the
    // browser doesn't flag it as a suspicious popup
    // source: https://www.yaplex.com/blog/avoid-browser-pop-up-blockers/
    const appWindow = window.open("", "_blank") as Window;

    try {
      if (!stamped) {
        // Get document from cache or api
        const documentBlobPart = await getDocument(document.id);
        // Open
        appWindow.location = getDocumentBlobUrl(documentBlobPart);
      } else {
        const documentBlobPart = await getStampedDocument(document.id);
        appWindow.location = getDocumentBlobUrl(documentBlobPart);
      }
    } catch {
      appWindow.close();

      AppToaster.show({
        message: (
          <div>
            Something went wrong while processing the file. <br />
            Please upload again or contact support.
          </div>
        ),
        intent: Intent.DANGER,
      });
    }
  };

  return {
    fetchDocumentsByIds,
    getDocument,
    getStampedDocument,
    getDocumentPage,
    downloadDocument,
    getDocumentBlobUrl,
    getDocumentPageBlobUrl,
    getDocumentImageBlobUrl,
    getBase64Url,
    documentActionTypes,
    openDocumentNewTab,
  };
};
