import { Card } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC, useState } from "react";
import { applicationStore } from "../../../stores/ApplicationStore";
import { billOwnedEntityStore } from "../../../stores/OwnedEntityStores/BillOwnedEntityStore";
import { Application } from "../../../types/Application";
import { BillInformation } from "../../../types/Application/BillInformation";
import Form from "../../Forms";
import {
  billInformationFormSchema,
  newBillInformation,
} from "../../Forms/ApplicationForm/BillInformation";
import { showSuccessfulSaveAppToaster } from "../../Toast/Toast";
import { BillCard } from "./BillCard";

export interface BillInformationCardProps {
  application?: Application;
  canEdit?: boolean;
  submitForm: (application: BillInformation) => Promise<void>;
}

export const BillInformationCard: FC<BillInformationCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const shouldShowChildren =
    application?.doesResidentHaveBills ||
    newBillInformation.doesResidentHaveBills ||
    application?.doesSpouseHaveBills ||
    newBillInformation.doesSpouseHaveBills;

  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  return (
    <Card className="bill-information-card">
      <Observer>
        {() => (
          <Form<BillInformation>
            formId="bill-information-form"
            shouldBlockNavigation={true}
            disableAllFields={shouldDisableForm || !canEdit}
            value={application || newBillInformation}
            formSchemaBuilder={billInformationFormSchema}
            onFormSubmit={async (billInfo) => {
              setShouldDisableForm(true);
              setFormIsSaving(true);
              await submitForm(billInfo).then(() => {
                showSuccessfulSaveAppToaster("Bill Information");
              });
              setShouldDisableForm(false);
              setFormIsSaving(false);
            }}
          />
        )}
      </Observer>
      {shouldShowChildren && (
        <BillCard
          bills={application?.bills}
          createAndUpdateBills={async () =>
            await billOwnedEntityStore.saveLocalBills()
          }
          deleteBill={async (billForm) => {
            billOwnedEntityStore.removeLocalBill(billForm);
            await applicationStore.deleteApplicationOwnedEntity(
              billForm.id,
              "bill"
            );
          }}
          formIsSaving={formIsSaving}
          setFormIsSaving={setFormIsSaving}
          shouldDisableForm={shouldDisableForm || !canEdit}
          setShouldDisableForm={setShouldDisableForm}
          showAddButton={true}
          showCancelButton={false}
        />
      )}
    </Card>
  );
};
