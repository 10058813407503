import GridColumn from "../../../Grid/Grid/types/GridColumn";

export const facilityUsersGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "corporateCode",
    headerText: "Corp Code",
    type: "string",
  }),
  new GridColumn({
    field: "facilityName",
    headerText: "Facility",
    type: "string",
    width: 250,
  }),
  new GridColumn({
    field: "firstName",
    headerText: "First Name",
    type: "string",
  }),
  new GridColumn({
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "title",
    headerText: "Title",
    type: "string",
  }),

  new GridColumn({
    field: "username",
    headerText: "Username",
    type: "string",
  }),
  {
    field: "userInviteEmailSentDate_AsDateOnly",
    headerText: "Invite Date Sent",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    allowSearching: false,
    customAttributes: {
      id: "facility-users-is-active-header",
    },
  }),
];
