import { FormGroup } from "@blueprintjs/core";
import {
  CustomFieldProps,
  CustomRendererBuilderProps,
} from "../types/fieldTypes";

export const CustomField: React.FC<CustomFieldProps> = ({
  renderer,
  form,
  hidden,
  errorMessages,
  ...rendererProps
}) => {
  if (hidden) {
    return null;
  }

  const customRendererBuilderProps = {
    form: form,
    rendererProps: rendererProps,
  } as CustomRendererBuilderProps;
  return (
    <FormGroup className="base-field">
      {renderer(customRendererBuilderProps)}
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
