import { Button, Intent, Tag } from "@blueprintjs/core";
import { FC, useState } from "react";
import RelativityCard from "../../../Card/RelativityCard";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import { BaseField } from "../../../Forms/BaseField/BaseField";
import { applicationSummaryOutstandingCount } from "../../../Forms/ApplicationSummary/FileInformationFormSchema";
import { IconNames } from "@blueprintjs/icons";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { userStore } from "../../../../stores/UserStore";

export interface OutstandingDocumentsCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
}

export const OutstandingDocumentsCard: FC<OutstandingDocumentsCardProps> = ({
  applicationSummary,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);
  const [localApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(applicationSummary);
  return (
    <RelativityCard
      className="summary-info"
      headerContent={
        <div className="outstanding-card__header">
          <Button
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            Outstanding Documents
          </Button>
          <div className="application-form-tab">
            {(localApplicationSummary.outstandingDocumentsCount || 0) > 0 && (
              <Tag large={true} round={true}>
                <BaseField
                  {...applicationSummaryOutstandingCount.outstandingDocument(
                    localApplicationSummary
                  )}
                />
              </Tag>
            )}
          </div>
          {userStore.user?.isExternal && (
            <div className="outstanding-card__header__right">
              *List is updated as documents are received. Please allow time for
              review and verification.
            </div>
          )}
        </div>
      }
      hideBody={!isViewing}
    >
      <div className="summary-info__section summary-info__section__outstanding-documents">
        <RelativitySubCard columns={1}>
          <div className={`outstanding-documents-container`}>
            {localApplicationSummary.checklistItems
              ?.filter((x) => !x.shouldDisplayOnExternalUserSummaryChecklist)
              .map((checklistItem, index) => {
                return (
                  <div key={index} className="outstanding-documents-card">
                    <Button
                      className="summary-button-group__buttons__default"
                      minimal
                      icon={IconNames.Document}
                      intent={Intent.NONE}
                      text={checklistItem.displayName}
                    />
                    <p className="short-description">
                      {checklistItem.shortDescription}
                    </p>
                  </div>
                );
              })}
          </div>
        </RelativitySubCard>
      </div>
    </RelativityCard>
  );
};
