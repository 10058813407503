import { UserRoles } from "../../../types/User";
import GridClickableDataColumn from "../../Grid/Grid/types/GridClickableDataColumn";
import GridColumn from "../../Grid/Grid/types/GridColumn";

export const gridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "id",
    headerText: "Facility ID",
    type: "string",
  }),
  new GridColumn({
    field: "facilityName",
    headerText: "Facility Name",
    type: "string",
  }),
  new GridColumn({
    field: "corporateName",
    headerText: "Corporate Name",
    type: "string",
  }),
  new GridColumn({
    field: "corporateCode",
    headerText: "Corporate Code",
    type: "string",
  }),
  new GridColumn({
    field: "area_AsString",
    headerText: "Region",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddress_County",
    headerText: "County",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddress_Street",
    headerText: "Street Address",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddress_City",
    headerText: "City",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddress_State_AsString",
    headerText: "State",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddress_Zip",
    headerText: "Zip",
    type: "string",
  }),
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "facility-is-active-header",
    },
  }),
  new GridClickableDataColumn({
    field: "userCount",
    headerText: "Users",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "facility-user-count",
    },
    dialogType: "facility-users-grid",
  }),
  new GridClickableDataColumn({
    field: "applicationCount",
    headerText: "Applications",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "facility-application-count",
    },
    dialogType: "facility-applications-grid",
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new GridColumn({
    field: "fieldRepFullName",
    headerText: "Field Rep",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "corporateName",
  "corporateCode",
  "facilityName",
  "area_AsString",
  "facilityAddress_County",
  "facilityAddress_Street",
  "facilityAddress_City",
  "facilityAddress_State_AsString",
  "facilityAddress_Zip",
  "isActive",
  "userCount",
  "applicationCount",
  "updatedBy_AsName",
  "updatedAt_AsDateOnly",
  "createdBy_AsName",
  "createdAt_AsDateOnly",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
