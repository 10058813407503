import { Button, Intent, Tag } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { Application, Resident } from "../../../../types/Application";
import { SaveButton } from "../../../Buttons/SaveButton";
import { UndoButton } from "../../../Buttons/UndoButton";
import RelativityCard from "../../../Card/RelativityCard";
import Form from "../../../Forms";
import { residentSecondaryInformationFormSchema } from "../../../Forms/ApplicationPage/ResidentSecondaryInformationForm";
import { AppToaster } from "../../../Toast/Toast";

export interface ResidentSecondaryInformationCardProps {
  application?: Application;
  shouldBlockNavigation?: boolean;
  submitForm: (resident: Resident) => Promise<void>;
  canEdit: boolean;
  missingFieldCount?: number;
  formKey: string;
}

export const ResidentSecondaryInformationCard: FC<
  ResidentSecondaryInformationCardProps
> = ({
  application,
  shouldBlockNavigation,
  submitForm,
  missingFieldCount,
  canEdit,
  formKey,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);

  const [activeFormValue, setActiveFormValue] = useState<Application>(
    application as Application
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  return (
    <RelativityCard
      headerContent={
        <>
          <Button
            className="resident-panel__section__view-toggle-button"
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            <div className="application-form-tab">
              Secondary Information
              {(missingFieldCount || 0) > 0 && (
                <Tag large={true} round={true}>
                  {missingFieldCount}
                </Tag>
              )}
            </div>
          </Button>
          {canEdit && (
            <>
              <>
                <SaveButton
                  formId={`resident-secondary-information-form-${formKey}`}
                  isSaving={isSaving}
                />
                <UndoButton
                  isSaving={isSaving}
                  onClick={async () => {
                    setShouldResetForm(true);
                    await handleReset();
                    setActiveFormValue(
                      applicationStore.application as Application
                    );
                    setShouldResetForm(false);
                  }}
                />
              </>
            </>
          )}
        </>
      }
      hideBody={!isViewing}
    >
      <Form<Application>
        formId={`resident-secondary-information-form-${formKey}`}
        shouldBlockNavigation={shouldBlockNavigation}
        value={activeFormValue}
        formSchemaBuilder={residentSecondaryInformationFormSchema}
        disableAllFields={!!isSaving || !canEdit}
        shouldResetForm={shouldResetForm}
        onFormSubmit={async (resident) => {
          setIsSaving(true);

          await submitForm(resident)
            .then(() => {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Resident Secondary Information Saved
                  </div>
                ),
                intent: Intent.SUCCESS,
              });
            })
            .finally(() => {
              setIsSaving(false);
            });
        }}
      />
    </RelativityCard>
  );
};
