import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { applicationStore } from "../../stores/ApplicationStore";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { applyToStateFormSchema } from "../Forms/ApplicationForm/ApplyToState";
import { AppToaster } from "../Toast/Toast";
import { BaseDialogProps } from "./types/types";

export interface ApplyToStateDialogProps extends BaseDialogProps {}

export const ApplyToStateDialog: React.FC<ApplyToStateDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [comment] = useState<string>("");

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Apply to State Comment"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Form<{ comment: string }>
          formId="apply-to-state-form"
          value={{ comment }}
          formSchemaBuilder={applyToStateFormSchema}
          onFormSubmit={async (formComment) => {
            try {
              setIsSaving(true);
              await applicationStore.submitApplicationToState(
                formComment.comment
              );
              closeDialog();
              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Submission to the state is in progress. This
                    may take up to 2 hours
                  </div>
                ),
                intent: Intent.SUCCESS,
              });
            } finally {
              setIsSaving(false);
            }
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="apply-to-state-button"
          isSaving={isSaving}
          formId="apply-to-state-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
