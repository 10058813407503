import { get } from "lodash";
import { schemaStore } from "../../../../stores/SchemaStore";
import { Asset, AssetInformation } from "../../../../types/Application/Asset";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../../types";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { ApplicationStatuses } from "../../../../types/Application";
import { userStore } from "../../../../stores/UserStore";
import { getAssetOwnerOptions } from "../../../../types/Application/AssetHelpers";

export const vehicleApplicationFormSchema: FormSchemaBuilder = (
  _onValueChange,
  form,
  _errors,
  _formId,
  onFormSubmit
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as AssetInformation;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasAssetVehicle"),
      value: application.hasAssetVehicle,
      onSubmit: (hasAssetVehicle) => {
        if (onFormSubmit) {
          onFormSubmit({ ...application, hasAssetVehicle });
        }
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "spouseHasAssetVehicle"),
      value: application.spouseHasAssetVehicle,
      onSubmit: (spouseHasAssetVehicle) => {
        if (onFormSubmit) {
          onFormSubmit({ ...application, spouseHasAssetVehicle });
        }
      },
      hidden: !applicationStore.application?.isMarried,
      isRequired: true,
    } as AllFields,
  ];
};

export const vehicleFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "asset";
  const asset = form as Asset;

  const canEdit = !!applicationStore.applicationCanBeEdited;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "owner"),
      value: asset.owner,
      onSubmit: (owner: Option) => {
        onValueChange({ owner: owner.value });
      },
      isRequired: true,
      errorMessages: get(errors, "owner"),
      width: "48.25%",
      optionValues: getAssetOwnerOptions(),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "subType"),
      optionValues: schemaStore.getMappingsByName("vehicle"),
      value: asset.subType,
      onSubmit: (subType: Option) => {
        onValueChange({ subType: subType.value });
      },
      isRequired: true,
      errorMessages: get(errors, "subType"),
      width: "48.25%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "vehicleYear"),
      value: asset.vehicleYear,
      onSubmit: (vehicleYear) => {
        onValueChange({ vehicleYear });
      },
      isRequired: true,
      errorMessages: get(errors, "vehicleYear"),
      width: "22.5%",
      maxValue: new Date().getFullYear() + 1,
      minValue: new Date().getFullYear() - 100,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "vehicleMake"),
      value: asset.vehicleMake,
      onSubmit: (vehicleMake) => {
        onValueChange({ vehicleMake });
      },
      isRequired: true,
      errorMessages: get(errors, "vehicleMake"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "vehicleModel"),
      value: asset.vehicleModel,
      onSubmit: (vehicleModel) => {
        onValueChange({ vehicleModel });
      },
      isRequired: true,
      errorMessages: get(errors, "vehicleModel"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isVehicleRegistered"),
      value: asset.isVehicleRegistered,
      onSubmit: (isVehicleRegistered) => {
        onValueChange({ isVehicleRegistered });
      },
      isRequired: true,
      errorMessages: get(errors, "isVehicleRegistered"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "value"),
      label: "Value",
      value: asset.value,
      onSubmit: (value) => {
        onValueChange({ value });
      },
      isRequired: true,
      errorMessages: get(errors, "value"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "asOfDate"),
      value: asset.asOfDate,
      onSubmit: (asOfDate) => {
        onValueChange({ asOfDate });
      },
      isRequired: true,
      errorMessages: get(errors, "asOfDate"),
      width: "22.5%",
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isExempt"),
      value: asset.isExempt,
      onSubmit: (isExempt) => {
        onValueChange({ isExempt });
      },
      disabled: !canEdit || !userStore.user?.canEditAssetExempt,
      isRequired:
        applicationStore.application?.status !=
        ApplicationStatuses.interviewing,
      errorMessages: get(errors, "isExempt"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isVerified"),
      value: asset.isVerified,
      onSubmit: (isVerified) => {
        onValueChange({ isVerified });
      },
      disabled: !canEdit || !userStore.user?.canEditAssetVerified,
      hidden:
        applicationStore.application?.status ==
        ApplicationStatuses.interviewing,
      isRequired:
        applicationStore.application?.status !=
        ApplicationStatuses.interviewing,
      errorMessages: get(errors, "isVerified"),
      width: "22.5%",
    } as AllFields,
  ];
};
