import { UserRoles } from "../../../../types/User";
import GridColumn from "../types/GridColumn";
import GridClickableDataColumn from "../types/GridClickableDataColumn";

export const gridColumnsConfiguration: GridColumn[] = [
  {
    field: "role_AsString",
    headerText: "Role",
    type: "string",
  } as GridColumn,
  {
    field: "firstName",
    headerText: "First Name",
    type: "string",
  } as GridColumn,
  {
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  } as GridColumn,
  {
    field: "username",
    headerText: "Username",
    type: "string",
  } as GridColumn,
  {
    field: "title",
    headerText: "Title",
    type: "string",
  } as GridColumn,
  {
    field: "email",
    headerText: "Email",
    type: "string",
  } as GridColumn,
  {
    field: "userInviteEmailSentDate_AsDateOnly",
    headerText: "Invite Date Sent",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "lastLoginDate_AsDateOnly",
    headerText: "Last Login Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "regions_AsString",
    headerText: "Regions",
    type: "string",
    width: 215,
  } as GridColumn,
  {
    field: "corporates_AsString",
    headerText: "Corporates",
    type: "string",
  } as GridColumn,
  {
    field: "facilities_AsString",
    headerText: "Facilities",
    type: "string",
    width: 215,
  } as GridColumn,
  new GridClickableDataColumn({
    field: "applicationCount",
    headerText: "Applications",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "user-application-count",
    },
    dialogType: "user-applications-grid",
  }),
  {
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
  } as GridColumn,
  {
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  } as GridColumn,
  {
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  } as GridColumn,
  {
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  {
    field: "id",
    headerText: "User Id",
    type: "string",
    visible: true,
  } as GridColumn,
];

export const gridRoleColumnsConfigurationDefaultFields = [
  "role_AsString",
  "firstName",
  "lastName",
  "username",
  "title",
  "email",
  "userInviteEmailSentDate_AsDateOnly",
  "regions_AsString",
  "corporates_AsString",
  "facilities_AsString",
  "applicationCount",
  "isActive",
  "updatedBy_AsName",
  "updatedAt_AsDateOnly",
  "createdBy_AsName",
  "createdAt_AsDateOnly",
];

export const externalGridColumnsConfigurationDefaultFields = [
  "role_AsString",
  "firstName",
  "lastName",
  "username",
  "title",
  "email",
  "userInviteEmailSentDate_AsDateOnly",
  "corporates_AsString",
  "facilities_AsString",
  "isActive",
  "updatedBy_AsName",
  "updatedAt_AsDateOnly",
  "createdBy_AsName",
  "createdAt_AsDateOnly",
];

export const internalGridColumnsConfigurationDefaultFields = [
  "role_AsString",
  "firstName",
  "lastName",
  "email",
  "regions_AsString",
  "applicationCount",
  "isActive",
  "updatedBy_AsName",
  "updatedAt_AsDateOnly",
  "createdBy_AsName",
  "createdAt_AsDateOnly",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};

export const getGridColumnsConfigurationByRoleExternal = (
  _role?: UserRoles
) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        externalGridColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          externalGridColumnsConfigurationDefaultFields.indexOf(a.field) -
          externalGridColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};

export const getGridColumnsConfigurationByRoleInternal = (
  _role?: UserRoles
) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        internalGridColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          internalGridColumnsConfigurationDefaultFields.indexOf(a.field) -
          internalGridColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
