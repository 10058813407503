import { Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { User } from "../../types/User";
import { WithTooltip } from "../Tooltip/Tooltip";
import "./styles.scss";

export interface UserIconProps {
  user?: User;
  shouldShowTooltip?: boolean;
  initials?: string;
}

export const UserIcon: React.FC<UserIconProps> = observer(
  ({ user, shouldShowTooltip, initials }) => {
    return (
      <div>
        {!!user && (
          <WithTooltip
            content={<>{`${user.fullName} is currently viewing this page.`}</>}
            shouldShowTooltip={true}
            className={Classes.FOCUS_DISABLED}
          >
            <div className="user-icon">{`${user.firstName[0].toLocaleUpperCase()}${user.lastName[0].toLocaleUpperCase()}`}</div>
          </WithTooltip>
        )}
        {!!initials && (
          <WithTooltip
            content={<>{`${initials} is currently viewing this page.`}</>}
            shouldShowTooltip={shouldShowTooltip || false}
            className={Classes.FOCUS_DISABLED}
          >
            <div className="user-icon">{`${initials.toLocaleUpperCase()}`}</div>
          </WithTooltip>
        )}
      </div>
    );
  }
);
