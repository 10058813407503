import { Button, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import RelativityCard from "../../../Card/RelativityCard";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import { CommentCard } from "../../../Dialogs/CommentsDialog/CommentCard";
import { userStore } from "../../../../stores/UserStore";
import { AddButton } from "../../../Buttons/AddButton";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";

export interface TopPinnedCommentsCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
  //show last 3 pinned or last 3 public
  isPinnedComments: boolean;
  fetchApplicationSummary?: () => Promise<void>;
  setReady?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TopPinnedCommentsCard: FC<TopPinnedCommentsCardProps> = ({
  applicationSummary,
  isPinnedComments,
  fetchApplicationSummary,
  setReady,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);
  const [viewAllComments, setViewAllComments] = useState(false);

  const [localApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(applicationSummary);

  const pinnedCommentSort = (a: any, b: any) => {
    return (
      // first sort pinned to top
      Number(b.isPinned) - Number(a.isPinned) ||
      // order by pinned date (first pinned date to most recent)
      +new Date(a.pinnedDate) - +new Date(b.pinnedDate)
    );
  };

  const publicCommentSort = (a: any, b: any) => {
    // order by most recent comment to oldest
    return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
  };

  const sliceValue = (viewAllComments: boolean, isPinnedComments: boolean) => {
    if (isPinnedComments) {
      return 2;
    } else {
      return viewAllComments ? undefined : 3;
    }
  };

  return (
    <RelativityCard
      headerContent={
        <div className="top-comments-card__header">
          <Button
            className="resident-panel__section__view-toggle-button toggle-comments"
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            {!isPinnedComments ? "Last 3 Comments" : "Pinned Comments"}
          </Button>
          {userStore.user?.isExternal && isViewing && !isPinnedComments && (
            <div className="external-user-comment-buttons">
              <Button
                className="resident-panel__section__view-toggle-button view-all-comments"
                minimal
                intent={Intent.PRIMARY}
                onClick={() => setViewAllComments((prev) => !prev)}
              >
                {viewAllComments ? "View Last 3 Comments" : "View All Comments"}
              </Button>
              <AddButton
                className="resident-panel__section__view-toggle-button add-comment"
                text="Add Comment"
                id="external-summary-add-comment"
                onClick={() => {
                  dialogsViewerStore.setIsCommentCreationDialogOpen(true, {
                    fromExternalUserSummary: true,
                    applicationId: applicationSummary.id,
                    applicationFileNumber: applicationSummary.fileNumber,
                    onSubmit: () => {
                      if (!!setReady && !!fetchApplicationSummary) {
                        setReady(false);
                        fetchApplicationSummary();
                      }
                    },
                  });
                }}
              />
            </div>
          )}
        </div>
      }
      hideBody={!isViewing}
    >
      <div className="comment-container">
        <>
          {localApplicationSummary.comments &&
            localApplicationSummary.comments
              .filter((x) => (isPinnedComments ? x.isPinned : x))
              .sort((a, b) =>
                isPinnedComments
                  ? pinnedCommentSort(a, b)
                  : publicCommentSort(a, b)
              )
              .map((comment, index) => {
                return (
                  <CommentCard
                    key={index}
                    comment={comment}
                    isComponentEnabled={false}
                  />
                );
              })
              .slice(0, sliceValue(viewAllComments, isPinnedComments))}
        </>
      </div>
    </RelativityCard>
  );
};
