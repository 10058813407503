import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC } from "react";

export interface SaveButtonProps {
  formId: string;
  isSaving?: boolean;
  text?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  hidden?: boolean;
}

export const SaveButton: FC<SaveButtonProps> = ({
  formId,
  isSaving,
  text = "",
  disabled = false,
  onClick,
  hidden,
}) => {
  return (
    <Button
      form={formId}
      type="submit"
      disabled={disabled}
      minimal
      intent={Intent.PRIMARY}
      onClick={onClick}
      rightIcon={IconNames.FLOPPY_DISK}
      loading={isSaving}
      text={text}
      hidden={hidden}
    />
  );
};
