import { Button, Intent, Tag } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { Application, Resident } from "../../../../types/Application";
import { SaveButton } from "../../../Buttons/SaveButton";
import { UndoButton } from "../../../Buttons/UndoButton";
import RelativityCard from "../../../Card/RelativityCard";
import Form from "../../../Forms";
import { residentPrimaryInformationFormSchema } from "../../../Forms/ApplicationPage/ResidentPrimaryInformationForm";
import { AppToaster } from "../../../Toast/Toast";

export interface ResidentPrimaryInformationCardProps {
  application?: Application;
  shouldBlockNavigation?: boolean;
  submitForm: (resident: Resident) => Promise<void>;
  canEdit: boolean;
  missingFieldCount?: number;
  formKey: string;
}

export const ResidentPrimaryInformationCard: FC<
  ResidentPrimaryInformationCardProps
> = ({
  application,
  shouldBlockNavigation,
  missingFieldCount,
  submitForm,
  canEdit,
  formKey,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);

  const [activeFormValue, setActiveFormValue] = useState<Application>(
    application as Application
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  return (
    <RelativityCard
      headerContent={
        <>
          <Button
            className="resident-panel__section__view-toggle-button"
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            <div className="application-form-tab">
              Primary Information
              {(missingFieldCount || 0) > 0 && (
                <Tag large={true} round={true}>
                  {missingFieldCount}
                </Tag>
              )}
            </div>
          </Button>
          {canEdit && (
            <>
              <SaveButton
                formId={`resident-primary-information-form-${formKey}`}
                isSaving={isSaving}
              />
              <UndoButton
                isSaving={isSaving}
                onClick={async () => {
                  setShouldResetForm(true);
                  await handleReset();
                  setShouldResetForm(false);
                }}
              />
            </>
          )}
        </>
      }
      hideBody={!isViewing}
    >
      <Form<Application>
        formId={`resident-primary-information-form-${formKey}`}
        shouldBlockNavigation={shouldBlockNavigation}
        value={activeFormValue}
        shouldResetForm={shouldResetForm}
        formSchemaBuilder={residentPrimaryInformationFormSchema}
        disableAllFields={!!isSaving || !canEdit}
        onFormSubmit={async (resident) => {
          setIsSaving(true);

          await submitForm(resident)
            .then(() => {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Resident Primary Information Saved
                  </div>
                ),
                intent: Intent.SUCCESS,
              });
            })
            .finally(() => {
              setIsSaving(false);
            });
        }}
      />
    </RelativityCard>
  );
};
