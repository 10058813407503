import { Button, Icon, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { Observer } from "mobx-react";
import {
  FilterDetails,
  gridFiltersModuleStore,
  SortDetails,
} from "./stores/GridFiltersModuleStore";
import { gridStore } from "../Grid/stores/GridStore";
import "./styles.scss";
import { isNil } from "lodash";

export interface GridFiltersModuleProps {}
export const GridFiltersModule: React.FC<GridFiltersModuleProps> = () => {
  return (
    <Observer>
      {() => {
        let sortDetails: SortDetails[] | undefined;
        let filterDetails: FilterDetails[] | undefined;
        let filtersCount = undefined;

        if (!!gridStore.filterSettings) {
          sortDetails = gridFiltersModuleStore.getSortDetails(
            gridStore.filterSettings.rawColumnSettings,
            gridStore.filterSettings.rawSortSettings
          );
          filterDetails = gridFiltersModuleStore.getFilterDetails(
            gridStore.filterSettings?.rawFilterSettings,
            gridStore.filterSettings?.rawColumnSettings
          );
          filtersCount =
            (sortDetails?.length ?? 0) + (filterDetails?.length ?? 0);
        }

        return (
          <div className="mdr-grid-filters-module">
            {!isNil(filtersCount) && filtersCount > 0 && (
              <div className="grid-filters">
                <Popover2
                  interactionKind="hover"
                  placement="left"
                  minimal={true}
                  portalClassName="mdr-grid-filters-module"
                  popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                  content={
                    <div className="grid-filters-details">
                      <ul className="grid-filters-details-list">
                        {sortDetails?.map((sort) => (
                          <li key={`sort-${sort.field}`}>
                            <Tag
                              fill={false}
                              onRemove={() => gridStore.removeSort(sort.field)}
                            >
                              {sort.details}
                              <Icon
                                iconSize={10}
                                icon={
                                  sort.direction === "Ascending"
                                    ? IconNames.ARROW_UP
                                    : IconNames.ARROW_DOWN
                                }
                              />
                            </Tag>
                          </li>
                        ))}
                        {filterDetails?.map((filter) => (
                          <li key={`filter-${filter.field}`}>
                            <Tag
                              fill={false}
                              onRemove={() =>
                                gridStore.removeFilter(filter.field)
                              }
                            >
                              {filter.details}
                            </Tag>
                          </li>
                        ))}
                      </ul>
                      <Button
                        className="grid-filters-details-clear"
                        minimal={true}
                        text="Clear All"
                        onClick={() => gridStore.clearFilters()}
                      />
                    </div>
                  }
                >
                  <div className="grid-filters-count">
                    <Button
                      className="grid-filters-count-target"
                      title="Clear All"
                    >
                      <Icon
                        className="grid-filters-count-icon"
                        icon={IconNames.FILTER_LIST}
                      />
                      <span className="grid-filters-count-text">
                        {`${filtersCount} Filter(s)`}
                      </span>
                      <Icon
                        className="grid-filters-count-clear"
                        icon={IconNames.CROSS}
                        onClick={() => gridStore.clearFilters()}
                      />
                    </Button>
                  </div>
                </Popover2>
              </div>
            )}
          </div>
        );
      }}
    </Observer>
  );
};
