import { Ajax } from "@syncfusion/ej2-base";
import {
  ODataV4Adaptor,
  DataManager,
  CrudOptions,
  DataOptions,
  DataResult,
  Query,
} from "@syncfusion/ej2-data";
import { ODataBeforeSendArgs } from "../Grid/Grid/types/ODataBeforeSendArgs";
import { AppointmentOutlookEvents } from "../../types/Scheduling";
import { getUTCDate } from "../../utils/date";
import { getFacilityBlockStartEndTimes } from "./utils/scheduleUtils";

export default class SchedulerODataV4Adaptor extends ODataV4Adaptor {
  private onBeforeSend?: (
    beforeSendArgs: ODataBeforeSendArgs
  ) => [string, string | undefined];

  private includeOutlookEvents?: boolean;
  private outlookEvents?: AppointmentOutlookEvents[];
  private isFacilitySchedule?: boolean;
  constructor(
    beforeSendFn?: (
      beforeSendArgs: ODataBeforeSendArgs
    ) => [string, string | undefined],
    includeOutlookEvents?: boolean,
    outlookEvents?: AppointmentOutlookEvents[],
    isFacilitySchedule?: boolean
  ) {
    super();

    this.onBeforeSend = beforeSendFn;
    this.processResponse = this.processResponse.bind(this);
    this.includeOutlookEvents = includeOutlookEvents;
    this.outlookEvents = outlookEvents ?? [];
    this.isFacilitySchedule = isFacilitySchedule;
  }

  processResponse(
    data: DataResult,
    ds?: DataOptions | undefined,
    query?: Query | undefined,
    xhr?: XMLHttpRequest | undefined,
    request?: Ajax | undefined,
    changes?: CrudOptions | undefined
  ): Object {
    if (!!this.includeOutlookEvents) {
      // Dev Notes: Include the outlook events in the scheduler data source.
      // Merge the outlook events into the data source
      data.value = (data.value as any[])?.concat(this.outlookEvents);
    }

    if (!!this.isFacilitySchedule) {
      data.value = (data.value as any[]).map((x) => {
        const { startTime, endTime } = getFacilityBlockStartEndTimes(
          getUTCDate(x.startDate),
          x.availableTimeSlot_AsString
        );

        return {
          ...x,
          startTime,
          endTime,
        };
      });
    }
    const processResult = super.processResponse.apply(this, [
      data,
      ds,
      query,
      xhr,
      request,
      changes,
    ]);

    return processResult;
  }
  beforeSend = async (
    dataManager: DataManager,
    request: XMLHttpRequest,
    ajax: Ajax
  ) => {
    // Dev Notes. Asynchronous calls cannot be handled at this level since beforeSend is synchronous.
    // Fetch Query
    const url = new URL(ajax.url);

    // Remove default Schedule query params
    url.searchParams.delete("StartDate");
    url.searchParams.delete("EndDate");

    // Set Query String
    let queryString = url.search;

    // Cleanups
    //--Replaces any undefined keyword for null to supprto api types
    queryString = queryString.replace(/undefined/g, "null");

    let accessToken = undefined;

    // Send onBeforeSend event
    if (!!this.onBeforeSend) {
      const [qry, token] = this.onBeforeSend({
        dataManager,
        request,
        ajax,
        queryString,
      } as ODataBeforeSendArgs);

      queryString = qry;
      accessToken = token;
    }

    // Send request on Body
    // Note that is important that the body still sends the ? on the beginning for full support of queriers, including top.
    // Set Query String
    ajax.data = queryString;

    // Open as POST Request
    request.open("POST", String(`${url.origin}${url.pathname}$query`));
    // Set Access Token
    if (!!accessToken) {
      request.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    }
  };
}
