import { EnumSingleSelectFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  FIARequest,
  FIARequestFollowUpActionTypes,
  FIARequestValidationsDTO,
} from "../../FIARequests/configurations/types";

export const useFiaRequestVerify = () => {
  const getFiaRequestFollowUpReasonOptions = () => {
    const schemaOptions = schemaStore.getFieldSchemaByName(
      "fiaRequest",
      "followUpReason"
    )?.optionValues;

    return schemaOptions
      ? schemaOptions.map((option) => ({
          label: option.label,
          value: option.value as EnumSingleSelectFieldValue,
        }))
      : [];
  };

  const getFiaRequestFollowUpSecondaryReasonOptions = () => {
    const schemaOptions = schemaStore.getFieldSchemaByName(
      "fiaRequest",
      "followUpSecondaryReason"
    )?.optionValues;

    return schemaOptions
      ? schemaOptions.map((option) => ({
          label: option.label,
          value: option.value as EnumSingleSelectFieldValue,
        }))
      : [];
  };

  const getFIAFollowUpActions = (fiaRequests: FIARequest[]) => {
    const followUpActions = [
      {
        label: "Mark FIA Requests Complete",
        value: FIARequestFollowUpActionTypes.MarkComplete,
      },
    ];
    if (
      !fiaRequests.every(
        (fiaRequest) =>
          (fiaRequest as FIARequestValidationsDTO).isSendingMethodMail
      )
    ) {
      followUpActions.push({
        label: "Resend FIA Requests",
        value: FIARequestFollowUpActionTypes.Resend,
      });
    }

    return followUpActions;
  };

  return {
    getFiaRequestFollowUpReasonOptions,
    getFiaRequestFollowUpSecondaryReasonOptions,
    getFIAFollowUpActions,
  };
};
