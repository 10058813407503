import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  ApplicationContact,
  ExistingApplicationContact,
} from "../../../types/Application/ApplicationContact";
import {
  AllFields,
  NameFieldValue,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder, validGovernmentIdUtils } from "../types";
import { ContactTypes } from "../../../types/Application/SpouseContact";
import { userStore } from "../../../stores/UserStore";
import { getScheduleMappingClassName } from "../../../utils/miscellaneous";

export const newApplicationContact: ApplicationContact = {
  scheduleMappingValues: [],
  firstName: "",
  lastName: "",
  contactType: 0,
  relationshipType: 0,
  address: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
  county: "",
  email: "",
  phoneNumber: "",
  additionalPhoneNumber: "",
  preferredContactMethod: 0,
  isIdAvailable: undefined,
  isCredibleWitnessAvailable: undefined,
  idNumber: "",
  notes: "",
  canEditContactPersonalInformation: true,
};

export const applicationContactFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "contact";
  const applicationContact = form as ExistingApplicationContact;
  const validGovernmentId =
    validGovernmentIdUtils.resolveContactValidGovernmentId(
      applicationContact,
      applicationContact.contactType,
      false
    );

  return [
    {
      type: "Name",
      label: "Name",
      className: getScheduleMappingClassName(
        applicationContact.scheduleMappingValues,
        "name"
      ),
      onSubmit: (name) => {
        onValueChange({ ...(name as NameFieldValue) });
      },
      value: {
        firstName: applicationContact.firstName,
        lastName: applicationContact.lastName,
      },
      isRequired: true,
      disabled: !applicationContact.canEditContactPersonalInformation,
    },
    {
      ...schemaStore.getFieldSchemaByName(modelName, "relationshipType"),
      className: getScheduleMappingClassName(
        applicationContact.scheduleMappingValues,
        "relationshipType"
      ),
      value: applicationContact.relationshipType,
      width: "49.5%",
      onSubmit: (relationshipType: Option) => {
        onValueChange({ relationshipType: relationshipType.value });
      },
      isRequired: true,
      errorMessages: get(errors, "relationshipType"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "contactType"),
      value: applicationContact.contactType,
      width: "49.5%",
      onSubmit: (contactType: Option) => {
        onValueChange({ contactType: contactType.value });
      },
      isRequired: true,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      errorMessages: get(errors, "contactType"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "address"),
      onSubmit: (address) => {
        onValueChange({ address });
      },
      value: applicationContact.address,
      errorMessages: [
        ...(get(errors, "address.Street") || []),
        ...(get(errors, "address.City") || []),
        ...(get(errors, "address.State") || []),
        ...(get(errors, "address.Zip") || []),
        ...(get(errors, "address.County") || []),
      ],
      disabled: !applicationContact.canEditContactPersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "email"),
      className: getScheduleMappingClassName(
        applicationContact.scheduleMappingValues,
        "email"
      ),
      onSubmit: (email) => {
        onValueChange({ email });
      },
      errorMessages: get(errors, "email"),
      formId: formId,
      value: applicationContact.email,
      isRequired: applicationContact.preferredContactMethod === 3,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneNumber"),
      className: getScheduleMappingClassName(
        applicationContact.scheduleMappingValues,
        "phoneNumber"
      ),
      onSubmit: (phoneNumber) => {
        onValueChange({ phoneNumber });
      },
      value: applicationContact.phoneNumber,
      isRequired: applicationContact.preferredContactMethod === 1,
      width: "49%",
      errorMessages: get(errors, "phoneNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "additionalPhoneNumber"),
      onSubmit: (additionalPhoneNumber) => {
        onValueChange({ additionalPhoneNumber });
      },
      value: applicationContact.additionalPhoneNumber,
      isRequired: applicationContact.preferredContactMethod === 2,
      width: "49%",
      errorMessages: get(errors, "additionalPhoneNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "preferredContactMethod"),
      className: getScheduleMappingClassName(
        applicationContact.scheduleMappingValues,
        "preferredContactMethod"
      ),
      value: applicationContact.preferredContactMethod,
      onSubmit: (preferredContactMethod: Option) => {
        onValueChange({ preferredContactMethod: preferredContactMethod.value });
      },
      errorMessages: get(errors, "preferredContactMethod"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isIdAvailable"),
      value: validGovernmentId.isIdAvailable,
      width: "33%",
      onSubmit: (isIdAvailable) => {
        onValueChange({ isIdAvailable });
      },
      hidden: !validGovernmentId.showIsIdAvailable,
      isRequired: true,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      errorMessages: get(errors, "isIdAvailable"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isCredibleWitnessAvailable"
      ),
      value: validGovernmentId.isCredibleWitnessAvailable,
      width: "33%",
      onSubmit: (isCredibleWitnessAvailable) => {
        onValueChange({ isCredibleWitnessAvailable });
      },
      hidden: !validGovernmentId.showIsCredibleWitnessAvailable,
      isRequired: true,
      errorMessages: get(errors, "isCredibleWitnessAvailable"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "idNumber"),
      hidden: !validGovernmentId.showIdNumber,
      isRequired: true,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      value: validGovernmentId.idNumber,
      width: "33%",
      onSubmit: (idNumber) => {
        onValueChange({ idNumber });
      },
      errorMessages: get(errors, "idNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isPersonallyKnownToNotary"
      ),
      hidden: applicationContact.contactType !== ContactTypes.Witness,
      isRequired: false,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      value: applicationContact.isPersonallyKnownToNotary,
      width: "33%",
      onSubmit: (isPersonallyKnownToNotary) => {
        onValueChange({ isPersonallyKnownToNotary });
      },
      errorMessages: get(errors, "isPersonallyKnownToNotary"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "notes"),
      onSubmit: (notes) => {
        onValueChange({ notes });
      },
      errorMessages: get(errors, "notes"),
      value: applicationContact.notes,
    } as AllFields,
  ];
};
