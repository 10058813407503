import { Intent } from "@blueprintjs/core";
import { helpCenterTopicApiClient } from "../../lib/apiClients/helpCenterTopic/helpCenterTopicApiClient";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { AppToaster } from "../Toast/Toast";
import {
  HelpCenterTopic,
  HelpCenterTopicCreateDTO,
  HelpCenterTopicUpdateDTO,
  TopicSectionTypes,
} from "./types";

export const useHelpCenterTopic = () => {
  const getHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    id: string
  ): Promise<HelpCenterTopic | undefined> => {
    const topic = await helpCenterTopicApiClient.getHelpCenterTopic(
      sectionType,
      id
    );

    if (!topic) {
      const isErrorToastShown = AppToaster.getToasts().some(
        (toast) => toast.key === "topic-get-error"
      );

      if (!isErrorToastShown) {
        AppToaster.show(
          {
            message: <div>{genericErrorMessage}</div>,
            intent: Intent.DANGER,
          },
          "topic-get-error"
        );
      }

      return undefined;
    }

    return topic;
  };

  const createHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    newTopic: HelpCenterTopicCreateDTO
  ): Promise<HelpCenterTopic | undefined> => {
    const topic = await helpCenterTopicApiClient.createHelpCenterTopic(
      sectionType,
      newTopic
    );

    if (!topic) {
      return undefined;
    }

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Topic was Created
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    return topic;
  };

  const updateHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    id: string,
    modifiedTopic: HelpCenterTopicUpdateDTO
  ): Promise<HelpCenterTopic | undefined> => {
    const topic = await helpCenterTopicApiClient.updateHelpCenterTopic(
      sectionType,
      id,
      modifiedTopic
    );

    if (!topic) {
      return undefined;
    }

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Topic was Updated
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    return topic;
  };

  const deleteHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    id: string
  ): Promise<boolean> => {
    let success = true;

    await helpCenterTopicApiClient
      .deleteHelpCenterTopic(sectionType, id)
      .catch(() => {
        success = false;
        AppToaster.show(
          {
            message: <div>{genericErrorMessage}</div>,
            intent: Intent.DANGER,
          },
          "topic-get-error"
        );
      });

    if (success) {
      AppToaster.show({
        message: (
          <div>
            <h3>Success</h3>Topic was Deleted
          </div>
        ),
        intent: Intent.SUCCESS,
      });
    }

    return success;
  };

  const getBlankTopic = (
    sectionType: TopicSectionTypes,
    usState?: number
  ): HelpCenterTopic => {
    return {
      title: "",
      content: "",
      externalLink: "",
      usState: usState,
      sectionType: sectionType,
      isFeedAllowed: true,
    } as HelpCenterTopic;
  };

  return {
    getBlankTopic,
    getHelpCenterTopic,
    createHelpCenterTopic,
    updateHelpCenterTopic,
    deleteHelpCenterTopic,
  };
};
