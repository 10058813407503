import { makeObservable, observable } from "mobx";
import React from "react";

export const FIAErrorMessage = (
  <p>
    Unable to preview/download since institution is missing information. Please
    see specific errors called out on FIA Request and contact DVT to resolve.
  </p>
);

export const genericErrorMessage = (
  <p>
    Sorry, something went wrong. If you continue to see this message, please
    contact{" "}
    <a href="mailto:Support@medicaiddoneright.com">
      Support@medicaiddoneright.com
    </a>
    . Thank you!
  </p>
);

export class ErrorStore {
  public errorMessage?: React.ReactNode;
  constructor() {
    makeObservable(this, {
      errorMessage: observable,
    });
  }

  setErrorMessage(errorMessage: React.ReactNode) {
    this.errorMessage = errorMessage;
  }
}

export const errorStore = new ErrorStore();
