import { Button, Intent } from "@blueprintjs/core";
import Footer from "../Footer/Footer";
import classNames from "classnames";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import Form from "../Forms";
import { useActionExecuting } from "../ActtionExecuting/useActionExecuting";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import RelativityCard from "../Card/RelativityCard";
import { updateReportFormSchema } from "../Forms/ReportForm/ReportForm";
import "./styles.scss";
import "../Dialogs/styles.scss";
import { Report, ReportUpdateDTO } from "./types";
import { useReport } from "./useReport";
import { Loading } from "../Loading/Loading";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";

export interface ReportUpdateProps {
  id: string;
  onAction?: (refresh: boolean) => void;
}

export const ReportUpdate: React.FC<ReportUpdateProps> = (props) => {
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [report, setReport] = useState<Report>();

  const { getReport, updateReport, deleteReport } = useReport();
  const { isActionExecuting } = useActionExecuting([
    isLoadingReport,
    isSaving,
    isDeleting,
  ]);

  useEffect(() => {
    loadReport();
  }, []);

  const actionHandler = (refresh: boolean) => {
    props.onAction && props.onAction(refresh);
  };

  const deleteReports = async () => {
    dialogsViewerStore.setIsConfirmDialogOpen(true, {
      content: "Are you sure you want delete this Report?",
      onConfirm: async () => {
        setIsDeleting(true);

        const success = await deleteReport(props.id).finally(() => {
          setIsDeleting(false);
        });

        if (!success) {
          return { isSuccess: false, error: "Failed to delete Report" };
        }

        // Call Action
        actionHandler(true);
        return { isSuccess: true, error: null };
      },
      onClose: () => {
        setIsDeleting(false);
      },
    });
  };

  const loadReport = async () => {
    setIsLoadingReport(true);

    const loadedReport = await getReport(props.id);

    if (!loadedReport) {
      props.onAction && props.onAction(false);
      return;
    }

    setReport(loadedReport);
    setIsLoadingReport(false);
  };

  const update = async (reportUpdateDTO: ReportUpdateDTO) => {
    setIsSaving(true);

    const updatedReport = await updateReport(props.id, reportUpdateDTO).finally(
      () => {
        setIsSaving(false);
      }
    );

    if (!updatedReport) {
      return;
    }

    setReport(updatedReport);

    // Call Action
    actionHandler(true);
  };

  return (
    <section className="report-update">
      {isLoadingReport && <Loading />}
      {!isLoadingReport && (
        <>
          <RelativityCard>
            <Observer>
              {() => (
                <div className="report-update-form-container">
                  <Form<ReportUpdateDTO>
                    formId="report-update-form"
                    disableAllFields={isActionExecuting}
                    value={report}
                    formSchemaBuilder={updateReportFormSchema}
                    onFormSubmit={async (reportUpdateDTO) => {
                      await update(reportUpdateDTO);
                    }}
                  />
                </div>
              )}
            </Observer>
          </RelativityCard>
          <Footer className={classNames("report-update-actions-container")}>
            <Button
              className="report-update-action-delete"
              icon="trash"
              intent={Intent.DANGER}
              disabled={isActionExecuting}
              onClick={deleteReports}
            />
            <Button
              minimal
              intent={Intent.DANGER}
              disabled={isActionExecuting}
              text="Cancel"
              onClick={() => actionHandler(false)}
            />
            <FormSubmitButton
              buttonId="report-update-form-save-action"
              disabled={isActionExecuting}
              isSaving={isSaving}
              formId="report-update-form"
              text="Save"
            />
          </Footer>
        </>
      )}
    </section>
  );
};
