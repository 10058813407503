import { ExistingResource } from "./ExistingResource";

export interface NewChecklistItem {
  applicationId: string;
  displayName: string;
  status: number;
  status_AsString?: string;
  documentCategory?: number;
  documentCategory_AsString: string;
  documentType?: number;
  documentType_AsString: string;
  responsiblePartyType?: number;
  responsiblePartyType_AsString: string;
  collectionSLAInDays?: number;
  reviewSLAInDays?: number;
  count?: number;
  shouldRequireInstitution: boolean;
  shouldDisplayOnExternalUserSummaryChecklist: boolean;
  institutionId?: string;
  shortDescription?: string;
  shouldDisplayOnFiaRequest: boolean;
  entityType: string;
  entityId: string;
  useStatementsReceived: boolean;
  statementsReceived_AsList: string[];
}

export type ChecklistItem = ExistingResource<NewChecklistItem>;

export enum ChecklistItemDocTypes {
  DriversLicensePhotoID = 1,
  POADocument = 20,
  GuardianshipConservatorDocument = 21,
  DVTUploads = 156,
  RepUploads = 219,
  SpousePOADocument = 10_001,
}

export enum ChecklistItemDocCategories {
  Assets = 1,
  Expenses = 2,
  Identification = 3,
  Income = 4,
  Legal = 5,
  Medical = 6,
  Other = 7,
  StateCorrespondence = 8,
  LevelOfCare = 9,
}

export enum ChecklistCardView {
  Items = "Checklist Items",
  Documents = "Checklist Documents",
}

export enum ChecklistItemStatuses {
  Open = 1,
  Closed = 2,
  NotApplicable = 3,
  NeedsReview = 4,
}

export enum ChecklistItemResponsiblePartyTypes {
  POA = 4,
  Guardian = 5,
}
