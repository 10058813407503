import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { invoiceApiClient } from "../../lib/apiClients/invoice/invoiceApiClient";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { buildField } from "../Fields";
import { AllFields } from "../Fields/types/fieldTypes";
import { AppToaster } from "../Toast/Toast";
import { BaseDialogProps } from "./types/types";
import { get } from "lodash";
import { dateBuilderAddYearsToTodaysDate } from "../../utils/date";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";

export interface InvoiceGenerationAttestationProps extends BaseDialogProps {}

export const InvoiceGenerationAttestation: React.FC<
  InvoiceGenerationAttestationProps
> = ({ isOpen, closeDialog }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localDateRange, setLocalDateRange] =
    useState<[Date | null, Date | null]>();

  const [lastInvoiceNumber, setLastInvoiceNumber] = useState<number>(10000);
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState<number>(
    lastInvoiceNumber + 1
  );
  const [localServiceDate, setLocalServiceDate] = useState<string>("");
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    invoiceApiClient.getLastInvoiceNumber().then((res) => {
      setLastInvoiceNumber(res === 0 ? 10000 : res);
      setLocalInvoiceNumber(res === 0 ? 10001 : res + 1);
    });

    return () => {
      setErrors({});
    };
  }, [dialogsViewerStore.isInvoiceGenerationDialogOpen]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      className="invoice-generation"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Run Billing"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <form
          id="generate-invoice-form"
          onSubmit={async (e) => {
            e.preventDefault();
            if (
              localDateRange &&
              localDateRange[0] &&
              localDateRange[1] &&
              localInvoiceNumber &&
              localServiceDate
            ) {
              setIsSaving(true);
              try {
                await invoiceApiClient
                  .createInvoices(
                    localDateRange,
                    localInvoiceNumber,
                    localServiceDate
                  )
                  .then(() => {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Invoice Generated
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                  })
                  .then(closeDialog);

                setIsSaving(false);
              } catch (error: any) {
                if (error?.response?.status == 400) {
                  setErrors(error.response.data.additionalInfo);
                }

                setIsSaving(false);
              }
            }
          }}
        >
          {buildField(
            {
              type: "DateRange",
              label:
                "By proceeding, you will be creating the invoices for the billing period below. This action is irreversible, continue?",
              value: localDateRange,
              onSubmit: (dateRange: [Date | null, Date | null]) => {
                setLocalDateRange(dateRange);
              },
            } as AllFields,
            0
          )}
          {buildField(
            {
              fieldKey: lastInvoiceNumber.toString(),
              type: "Number",
              label: `What Invoice Number do you want to start with? Your last invoice number was #${lastInvoiceNumber}.`,
              value: localInvoiceNumber,
              onSubmit: (invoiceNumber: number) => {
                setLocalInvoiceNumber(invoiceNumber);
              },
            } as AllFields,
            0
          )}
          {buildField(
            {
              type: "Datetime",
              label: "Service Date",
              value: localServiceDate,
              minDate: new Date(dateBuilderAddYearsToTodaysDate(-10)),
              maxDate: new Date(dateBuilderAddYearsToTodaysDate(10)),
              onSubmit: (localServiceDate: string) => {
                setLocalServiceDate(localServiceDate);
              },
            } as AllFields,
            0
          )}
        </form>
        {errors && (
          <>
            {get(errors, "invoiceNumber")?.map(
              (errorMessage: string, idx: number) => (
                <p key={idx} className="error-message">
                  {errorMessage}
                </p>
              )
            )}
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="generate-invoice-button"
          isSaving={isSaving}
          formId="generate-invoice-form"
          text="Submit"
        />
      </div>
    </Dialog>
  );
};
