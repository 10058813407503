import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState, useEffect } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";
import { ExistingMonitor } from "../../types/Monitor";
import { endMonitorFormSchema } from "../Forms/MonitorForm/EndMonitorForm";
import { applicationStore } from "../../stores/ApplicationStore";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { Observer } from "mobx-react";

export interface EndMonitorDialogProps extends BaseDialogProps {}

export const EndMonitorDialog: React.FC<EndMonitorDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [existingMonitor, setExistingMonitor] =
    useState<ExistingMonitor | null>(null);

  useEffect(() => {
    if (!!dialogsViewerStore.isEndMonitorDialogOpen) {
      if (applicationStore.application?.monitor) {
        setExistingMonitor(applicationStore.application.monitor);
      }
    }

    return () => {
      setExistingMonitor(null);
    };
  }, [dialogsViewerStore.isEndMonitorDialogOpen]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"End Monitor"}
      onClose={closeDialog}
      className={"end-monitor"}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            existingMonitor && (
              <>
                <Form
                  formId="end-monitor-form"
                  value={existingMonitor}
                  formSchemaBuilder={endMonitorFormSchema}
                  onFormSubmit={async (existingMonitor) => {
                    setIsSaving(true);
                    try {
                      await applicationStore
                        .deleteApplicationMonitorWithEndDate(existingMonitor)
                        .then(() => {
                          gridStore.refresh();
                          submitDialog();
                        });
                    } finally {
                      setIsSaving(false);
                    }
                  }}
                />
                {!!existingMonitor.endDate && (
                  <div className="end-monitor-information-text">
                    *End Date is already scheduled, setting the End Date here
                    will end the monitor. If you would like to update the
                    scheduled End Date, please use the Update Monitor dialog
                    instead.
                  </div>
                )}
              </>
            )
          }
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="end-monitor-button"
          isSaving={isSaving}
          formId="end-monitor-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
