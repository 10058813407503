import { dialogStore } from "@ucl/library";
import FacilityBlockConfigurationForm from "../Forms/FacilityBlockConfigurationForm/FacilityBlockConfigurationForm";
import "./styles.scss";

const dialogIdBase = "block-schedule-dialog";

export const openFacilityBlockScheduleDialog = (
  facilityId: string,
  fieldRepId: string,
  fieldRepFullName: string
) =>
  dialogStore.openDialog({
    id: dialogIdBase,
    className: dialogIdBase,
    header: `Facility Block Schedule Configuration - ${fieldRepFullName}`,
    allowDragging: false,
    content: () => (
      <FacilityBlockConfigurationForm
        facilityId={facilityId}
        fieldRepId={fieldRepId}
      />
    ),
  });

export const closeFacilityBlockScheduleDialog = () =>
  dialogStore.closeDialog(dialogIdBase);
