import { Tag } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import React from "react";
import { fiaRequestApiClient } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { userStore } from "../../stores/UserStore";
import { fiaRequestStore } from "./stores/FIARequestStore";

export interface FIARequestTabProps {
  applicationId?: string;
}

export const FIARequestTab: React.FC<FIARequestTabProps> = (props) => {
  const [outstandingFIARequestsCount, setOutstandingFIARequestsCount] =
    React.useState<number>();

  React.useEffect(() => {
    if (!!props.applicationId) {
      refresh();
    }
  }, []);

  React.useEffect(() => {
    if (fiaRequestStore.fiaRequest) {
      refresh();
    }
  }, [fiaRequestStore.fiaRequest]);

  const refresh = async () => {
    if (!!props.applicationId) {
      const outstanding =
        await fiaRequestApiClient.getOutstandingFIARequestsCount(
          props.applicationId
        );

      setOutstandingFIARequestsCount(outstanding);
    }
  };

  return (
    <Observer>
      {() => {
        if (!userStore.user?.canViewFIARequest) {
          return null;
        }

        return (
          <div className="application-form-tab">
            FIA Requests
            {(outstandingFIARequestsCount || 0) > 0 && (
              <Tag round={true}>{outstandingFIARequestsCount}</Tag>
            )}
          </div>
        );
      }}
    </Observer>
  );
};
