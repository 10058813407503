import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { userStore } from "../../../../stores/UserStore";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { BaseDialogProps } from "../../types/types";
import { CorporateApplicationsGrid } from "./CorporateApplicationsGrid";

export interface CorporateApplicationsGridDialogProps extends BaseDialogProps {}

export const CorporateApplicationsGridDialog: React.FC<
  CorporateApplicationsGridDialogProps
> = ({ isOpen, closeDialog }) => {
  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      isCloseButtonShown={true}
      title={"Corporate Applications"}
      onClose={closeDialog}
      className="corporate-applications"
      portalClassName="mdr-dialog corporate-applications-dialog fullscreen-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        {dialogsViewerStore.corporateApplicationsGridDialogOpenOptions
          ?.corporateId && (
          <CorporateApplicationsGrid
            canExportGridToExcel={
              !!userStore.user?.canExportCorporateBoardToExcel
            }
            canInvokeGridRecord={!!userStore.user?.canEditCorporate}
            corporateId={
              dialogsViewerStore.corporateApplicationsGridDialogOpenOptions
                .corporateId
            }
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          minimal
          intent={Intent.DANGER}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
