import baseApiClient from "../baseApiClient";
import { Comment, CommentFilters, NewComment } from "../../../types/Comment";

class CommentsApiClient {
  getApplicationComments = async (
    applicationId: string,
    filters?: CommentFilters
  ) => {
    return baseApiClient.post<Comment[]>(
      `application/${applicationId}/comments`,
      filters
    );
  };

  getRelatedApplicationComments = async (
    applicationId: string,
    filters?: CommentFilters
  ) => {
    return baseApiClient.post<Comment[]>(
      `application/${applicationId}/related-comments`,
      filters
    );
  };

  getSourceComments = async (sourceId: string) => {
    return baseApiClient.get<Comment[]>(`comment/source/${sourceId}`);
  };

  createComment = async (comment: Partial<NewComment>) => {
    return baseApiClient.post<Comment>(`comment`, comment);
  };

  updateComment = async (comment: Partial<Comment>) => {
    return baseApiClient.put<Comment>(`comment/${comment.id}`, comment);
  };

  pinComment = async (comment: Partial<Comment>) => {
    return baseApiClient.put<Comment>(`comment/pin`, comment);
  };

  deleteComment = async (commentId: string) => {
    return baseApiClient.delete(`comment/${commentId}`);
  };
}

export const commentsApiClient = new CommentsApiClient();
