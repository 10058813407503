import { EnumSingleSelectField } from "@ucl/library";
import { ColumnModel } from "@syncfusion/ej2-react-grids";
import GridColumn from "./GridColumn";
import "../styles.scss";

interface GridDropdownColumnOptions extends ColumnModel {
  fieldValueParser: (value: string) => any;
  optionValues: { label: string; value: any }[];
  onSubmit: (id: string, value: unknown) => Promise<void>;
}

export default class GridDropdownColumn extends GridColumn {
  constructor(opts: GridDropdownColumnOptions) {
    const { fieldValueParser, optionValues, onSubmit, ...superOpts } = opts;

    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const modelValue = opts.field ? model[opts.field] ?? undefined : "";
        const parsedModelValue = fieldValueParser(modelValue);

        return (
          <div
            className="grid-dropdown-column"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <EnumSingleSelectField
              type="EnumSingleSelect"
              className="grid-dropdown-column__field"
              label=""
              placeholder=""
              value={parsedModelValue}
              disabled={false}
              isRequired={false}
              optionValues={optionValues}
              onSubmit={(value) => onSubmit(model.id, value)}
            />
          </div>
        );
      }) as any,
    });
  }
}
