import {
  EnumSingleSelectFieldV2Props,
  PhoneNumberFieldProps,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { AppointmentContactFormModel } from "./AppointmentContactFormModel";
import { Dispatch, SetStateAction } from "react";
import { get } from "lodash";

const modelName = "AppointmentContact";
const getFieldKey = (key: string) => `${modelName}__${key}`;

export const getAppointmentContactFormSchema = (
  factory: SchemaFactoryV2,
  errors: { [key: string]: string[] },
  form: AppointmentContactFormModel,
  setForm: Dispatch<SetStateAction<AppointmentContactFormModel>>,
  isRequired: boolean
) => {
  return {
    FirstName: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("FirstName")),
      value: form.firstName,
      isRequired,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, firstName: value };
        }),
      errorMessages: get(errors, "firstName"),
    } as TextFieldProps,
    LastName: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("LastName")),
      value: form.lastName,
      isRequired,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, lastName: value };
        }),
      errorMessages: get(errors, "lastName"),
    } as TextFieldProps,
    PhoneNumber: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("PhoneNumber")),
      value: form.phoneNumber,
      isRequired: isRequired && !form.email,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, phoneNumber: value };
        }),
      errorMessages: get(errors, "phoneNumber"),
    } as PhoneNumberFieldProps,
    Email: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("Email")),
      value: form.email,
      isRequired: isRequired && !form.phoneNumber,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, email: value };
        }),
      errorMessages: get(errors, "email"),
    } as TextFieldProps,
    PreferredContactMethod: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("PreferredContactMethod")
      ),
      value: form.preferredContactMethod,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, preferredContactMethod: value };
        }),
    } as EnumSingleSelectFieldV2Props,
    RelationshipToResident: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("RelationshipToResident")
      ),
      isRequired,
      value: form.relationshipToResident,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, relationshipToResident: value };
        }),
      errorMessages: get(errors, "relationshipToResident"),
    } as EnumSingleSelectFieldV2Props,
  };
};
