import { Classes, Dialog } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import React from "react";
import { Observer } from "mobx-react";
import { DocumentEditor } from "../Document/DocumentEditor";

export interface DocumentEditorDialogProps extends BaseDialogProps {
  documentId?: string;
}

export const DocumentEditorDialog: React.FC<DocumentEditorDialogProps> = ({
  documentId,
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  return (
    <Dialog
      portalClassName="mdr-dialog document-editor-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Document Editor"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <section className="document-editor-dialog-container">
          <Observer>
            {() => (
              <DocumentEditor
                documentId={documentId as string}
                onCancel={closeDialog}
                onUpdate={submitDialog}
              />
            )}
          </Observer>
        </section>
      </div>
    </Dialog>
  );
};
