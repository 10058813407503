import { useEffect, useState } from "react";
import { matchupApiClient } from "../../lib/apiClients/matchup/matchupApiClient";
import { Lead } from "../../types/Lead";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../Toast/Toast";
import { MatchupFlyoutProps } from "./MatchupFlyout";

export const useMatchupFlyout = (props: MatchupFlyoutProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [lead, setLead] = useState<Lead | null>(null);

  useEffect(() => {
    if (props.matchupId) {
      matchupApiClient.getLeadById(props.matchupId).then((lead) => {
        setLead(lead);
      });
    }

    return () => {
      setLead(null);
    };
  }, []);

  const saveLead = async (lead: Lead) => {
    setIsSaving(true);
    await matchupApiClient
      .updateLead(lead.id, lead)
      .then(() => {
        setLead(lead);
        gridStore.refresh();
        //submitDialog();
      })
      .finally(() => {
        setIsSaving(false);
        props.onClose && props.onClose();
      });
  };

  const saveAndConvertLead = async (
    lead: Lead,
    shouldValidateSSNOnOpenApplication = true
  ) => {
    setIsConverting(true);
    await matchupApiClient
      .updateLead(lead.id, lead)
      .then(async () => {
        await matchupApiClient
          .convertToApplication(
            lead.id,
            lead,
            shouldValidateSSNOnOpenApplication
          )
          .then((res: any) => {
            let redirectTo: string | undefined = undefined;
            if (res.applicationStatus_AsString === "Interviewing") {
              redirectTo = `/application-form/${res.applicationId}/primary-information`;
            } else {
              redirectTo = `/application-page/${res.applicationId}`;
            }
            window.open(redirectTo, "_blank");
          })
          .then(() => {
            gridStore.refresh();
            //submitDialog();
          });

        setLead(lead);
        props.onClose && props.onClose();
      })
      .catch((error: any) => {
        if (error.response?.status === 400) {
          const specificErrorMessage =
            "Social Security Number exists on an open application.";
          const ssnErrors =
            error.response?.data?.additionalInfo?.socialSecurityNumber;

          if (
            Array.isArray(ssnErrors) &&
            ssnErrors.includes(specificErrorMessage)
          ) {
            dialogsViewerStore.setIsConfirmDialogOpen(true, {
              content:
                "There is an open file with a matching SSN, are you sure you want to proceed with conversion?",
              onConfirm: async () => {
                saveAndConvertLead(lead, false);
                return { isSuccess: true, error: null };
              },
            });
          } else {
            AppToaster.show({
              message: (
                <div>
                  Please fill out all required fields before converting to an
                  Application.
                </div>
              ),
              intent: Intent.DANGER,
            });
          }
        }
      })
      .finally(() => {
        setIsConverting(false);
      });
  };

  return { lead, isSaving, isConverting, saveLead, saveAndConvertLead };
};
