import * as React from "react";

import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { UserGrid } from "../components/UserGrid/UserGrid";
import { CorporateGrid } from "../components/CorporateGrid/CorporateGrid";
import { FacilityGrid } from "../components/FacilityGrid/FacilityGrid";
import { InstitutionGrid } from "../components/InstitutionGrid/InstitutionGrid";
import { NotaryGrid } from "../components/NotaryGrid/NotaryGrid";
import { useNavigate, useParams } from "react-router";
import { BillingGrid } from "../components/Billing/BillingGrid/BillingGrid";
import { InvoiceGrid } from "../components/Invoice/InvoiceGrid";
import { Observer } from "mobx-react";
import { userStore } from "../stores/UserStore";

export interface AdminTabsProps {}

export const AdminTabs: React.FC<AdminTabsProps> = ({}) => {
  const { tabId } = useParams<{ tabId: string }>();
  const navigate = useNavigate();

  return (
    <Observer>
      {() => (
        <Tabs
          large
          animate
          id="admin-tabs"
          renderActiveTabPanelOnly
          className={Classes.FOCUS_DISABLED}
          defaultSelectedTabId={tabId}
          onChange={(tabId) => navigate(`/admin/${tabId}`)}
        >
          {userStore.user?.canViewUserBoard && (
            <Tab
              id="users"
              title="Users"
              panel={
                <UserGrid
                  canConfigureBoard={userStore.user?.canConfigureUserBoard}
                  canExportGridToExcel={
                    userStore.user?.canExportUserBoardToExcel
                  }
                  canCreateNewRecord={userStore.user?.canCreateUser}
                  canInvokeGridRecord={userStore.user?.canEditUser}
                />
              }
            />
          )}
          {userStore.user?.canViewCorporateBoard && (
            <Tab
              id="corporates"
              title="Corporates"
              panel={
                <CorporateGrid
                  canConfigureBoard={userStore.user?.canConfigureCorporateBoard}
                  canExportGridToExcel={
                    userStore.user?.canExportCorporateBoardToExcel
                  }
                  canCreateNewRecord={userStore.user?.canCreateCorporate}
                  canInvokeGridRecord={userStore.user?.canEditCorporate}
                />
              }
            />
          )}
          {userStore.user?.canViewFacilityBoard && (
            <Tab
              id="facilities"
              title="Facilities"
              panel={
                <FacilityGrid
                  canConfigureBoard={userStore.user?.canConfigureFacilityBoard}
                  canCreateNewRecord={userStore.user?.canCreateFacility}
                  canExportGridToExcel={
                    userStore.user?.canExportFacilityToExcel
                  }
                  canInvokeGridRecord={userStore.user?.canEditFacility}
                />
              }
            />
          )}
          {userStore.user?.canViewInstitutionBoard && (
            <Tab
              id="fias"
              title="Institutions"
              panel={
                <InstitutionGrid
                  canConfigureBoard={
                    userStore.user?.canConfigureInstitutionBoard
                  }
                  canCreateNewRecord={userStore.user?.canCreateInstitution}
                  canExportGridToExcel={
                    userStore.user?.canExportInstitutionToExcel
                  }
                  canInvokeGridRecord={userStore.user?.canEditInsitution}
                />
              }
            />
          )}
          {userStore.user?.canViewNotaryBoard && (
            <Tab
              id="notary"
              title="Notary"
              panel={
                <NotaryGrid
                  canConfigureBoard={!!userStore.user?.canConfigureNotaryBoard}
                  canCreateNewRecord={!!userStore.user?.canCreateNotary}
                  canExportGridToExcel={
                    !!userStore.user?.canExportNotaryGridToExcel
                  }
                  canInvokeGridRecord={!!userStore.user?.canEditNotary}
                />
              }
            />
          )}

          {userStore.user?.canViewBillingBoard && (
            <Tab
              id="billing"
              title="Billing"
              panel={
                <BillingGrid
                  canConfigureBoard={userStore.user?.canConfigureBillingBoard}
                  canCreateNewRecord={userStore.user?.canCreateBill}
                  canExportGridToExcel={userStore.user?.canExportBillingToExcel}
                  canGenerateInvoice={userStore.user?.canGenerateInvoice}
                  canInvokeGridRecord={userStore.user?.canEditBill}
                />
              }
            />
          )}
          {userStore.user?.canViewInvoiceBoard && (
            <Tab
              id="invoice"
              title="Invoice"
              panel={
                <InvoiceGrid
                  canExportGridToExcel={userStore.user?.canExportBillingToExcel}
                />
              }
            />
          )}
        </Tabs>
      )}
    </Observer>
  );
};
