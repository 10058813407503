import { get } from "lodash";

export interface FileDocument {
  fileName: string;
  folderName?: string;
  isChunked?: boolean;
}

export const buildFileDocumentCustomFormData = (
  doc: object
): {
  [key: string]: Object;
}[] => {
  return Object.keys(doc).map((key) => ({ [key]: get(doc, key) })) as {
    [key: string]: Object;
  }[];
};
