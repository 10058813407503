import _ from "lodash";

export const deep = <T>(value: T): T => {
  if (typeof value !== "object" || value === null) {
    return value;
  }
  if (Array.isArray(value)) {
    return deepArray(value) as T;
  }
  return deepObject(value);
};

export const deepObject = <T>(source: T) => {
  const result = {} as T;
  Object.keys(source as unknown[]).forEach((key) => {
    const value = source[key as keyof T];
    result[key as keyof T] = deep(value);
  }, {});
  return result as T;
};

export const deepArray = <T extends unknown[]>(collection: T): unknown => {
  return collection.map((value) => {
    return deep(value);
  });
};

/**
 * Will transfer just existing properties on destiny. Will not add more.
 * @param source
 * @param destiny
 */
export const mapTo = (source: object, destiny: object) => {
  for (const key in destiny) {
    if (key in source) {
      _.set(destiny, key, _.get(source, key));
    }
  }
};
