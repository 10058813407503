import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import { PdfDocumentEntity } from "../Document/PdfDocumentUploader";
import React from "react";
import { OrderedDocumentCoverLetterCreateDTO } from "../OrderedDocumentsGrid/configurations/types";
import { CoverLetterPicker } from "../TemplatePicker/CoverLetterPicker";

export interface CoverLetterPickerDialogProps extends BaseDialogProps {
  submitDialog: (
    orderedDocumentCoverLetterCreate?: OrderedDocumentCoverLetterCreateDTO
  ) => void;
  pdfDocumentEntity?: PdfDocumentEntity;
}

export const CoverLetterPickerDialog: React.FC<
  CoverLetterPickerDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [documentPicked, setDocumentPicked] =
    React.useState<OrderedDocumentCoverLetterCreateDTO>();

  return (
    <Dialog
      portalClassName="mdr-dialog cover-letter-picker-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Add Cover Letter"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <section className="cover-letter-picker-dialog-container">
          <CoverLetterPicker
            onDocumentPicked={(documentType) => {
              let orderedDocumentCoverLetterCreateDTO = undefined;
              if (!!documentType) {
                orderedDocumentCoverLetterCreateDTO = {
                  documentType: Number(documentType),
                };
              }
              setDocumentPicked(orderedDocumentCoverLetterCreateDTO);
            }}
          />
        </section>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <Button
          className="bp4-button bp4-intent-success"
          text="Save"
          disabled={!documentPicked}
          onClick={() => submitDialog(documentPicked)}
        />
      </div>
    </Dialog>
  );
};
