import { action, makeObservable, observable } from "mobx";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
  ChangeEventArgs,
  SelectEventArgs,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { expressionsStore } from "./ExpressionsStore";
import { templateApiClient } from "../../../lib/apiClients/template/templateApiClient";
import { Option } from "../../Fields/types/fieldTypes";
import { ODataStore } from "../../../stores/ODataStore";

export interface PreviewApplicationReadDTO {
  id_AsString?: string;
  fileNumber?: string;
  firstName?: string;
  lastName?: string;
  productType_AsString?: string;
}

export class PreviewStore extends ODataStore {
  // public
  public autoCompleteInstance?: AutoCompleteComponent;
  public applicationId?: string;
  public applicationData?: PreviewApplicationReadDTO;

  // Events

  showSearchPopup = () => {
    if (!!this.autoCompleteInstance) {
      this.autoCompleteInstance.showPopup();
    }
  };

  handleSearchSelect = async (args: SelectEventArgs) => {
    if (!!this.autoCompleteInstance) {
      const data = args?.itemData as Option;

      if (data?.value) {
        await this.setApplicationId(data.value);
      }
    }
  };

  handleChange = async (args: ChangeEventArgs) => {
    if (!!this.autoCompleteInstance) {
      if (!args.value) {
        await this.setApplicationId(undefined);
      }
    }
  };

  setApplicationId = async (applicationId?: string) => {
    this.applicationId = applicationId;
    let templateFieldsData = {};
    if (applicationId) {
      templateFieldsData = await this.getTemplateFieldsData(applicationId);
      this.setApplicationData(templateFieldsData);
    }
    await expressionsStore.previewAnnotations(templateFieldsData);
  };

  setApplicationData = (data: PreviewApplicationReadDTO) => {
    this.applicationData = data;
  };

  getTemplateFieldsData = async (applicationId: string) => {
    if (!applicationId) {
      return {};
    }

    const data = await templateApiClient.getTemplateFieldsData(applicationId);
    return data;
  };

  private defaultAutoCompleteSettings: AutoCompleteModel &
    DefaultHtmlAttributes = {
    fields: { text: "label", value: "value" },
    itemTemplate:
      "<table><tr><td>${fileNumber} - ${firstName} ${lastName}</td></tr><table>",
    autofill: true,
    showClearButton: false,
    highlight: true,
    placeholder: "Enter File # or Resident Name",
    select: this.handleSearchSelect,
    focus: this.showSearchPopup,
    onClick: this.showSearchPopup,
    change: this.handleChange,
  };

  constructor() {
    super();

    makeObservable(this, {
      autoCompleteInstance: observable,
      applicationData: observable,
      setApplicationData: action,
    });
  }

  public setup = async (args: {
    odataUrl: string;
    getAccessToken: () => Promise<string | undefined>;
  }) => {
    await this.init(args.getAccessToken);

    // Setup Properties
    const autoCompleteSettings = {
      ...this.defaultAutoCompleteSettings,
    } as AutoCompleteModel & DefaultHtmlAttributes;

    const dm = this.setupODataSource(args.odataUrl);
    autoCompleteSettings.dataSource = dm;

    // Dev Notes. This retriggers the ajax, select, and preview. Analyze later if we want to avoid it.
    autoCompleteSettings.value = this.applicationId;

    const query = new Query()
      .select(["label", "value", "fileNumber", "firstName", "lastName"])
      .sortBy("firstName")
      .take(500);

    autoCompleteSettings.query = query;

    //Filtering across multiple columns
    const onFiltering = async (args: FilteringEventArgs, dataSource = dm) => {
      if (!this.autoCompleteInstance) {
        return;
      }

      //set Query
      const predicate = new Predicate("fileNumber", "contains", args.text, true)
        .or(new Predicate("firstName", "contains", args.text, true))
        .or(new Predicate("lastName", "contains", args.text, true));

      const query = this.autoCompleteInstance.query.clone();
      query
        .select(["label", "value", "fileNumber", "firstName", "lastName"])
        .where(predicate)
        .take(500);

      args.updateData(dataSource, query);
    };
    autoCompleteSettings.filtering = onFiltering;

    autoCompleteSettings.actionFailure = this.baseActionFailureEvent(() => {
      this.autoCompleteInstance?.hidePopup();
      setTimeout(() => {
        this.autoCompleteInstance?.showPopup();
      }, 100);
    });
    autoCompleteSettings.actionComplete = this.baseActionCompleteEvent();

    return autoCompleteSettings;
  };

  public initialize = (newAutoCompleteInstance: AutoCompleteComponent) => {
    this.setInstance(newAutoCompleteInstance);
  };

  public reset = () => {
    this.setInstance(undefined);
  };

  public setInstance = (scopeAutoCompleteInstance?: AutoCompleteComponent) => {
    this.autoCompleteInstance = scopeAutoCompleteInstance;

    // Additional Settings
    if (scopeAutoCompleteInstance) {
      const element = scopeAutoCompleteInstance.element as HTMLInputElement;
      if (element) {
        element.maxLength = 38;
      }
    }
  };
}

export const previewStore = new PreviewStore();
