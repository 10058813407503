import _ from "lodash";
import { action, makeObservable, observable, reaction } from "mobx";
import {
  FIARequest,
  FIARequestCancelDTO,
  FIARequestNewDTO,
  FIARequestUpdateDTO,
  FIARequestDetails,
  FIARequestResendDTO,
} from "../configurations/types";
import { fiaRequestApiClient } from "../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { mapTo } from "../../../utils/clone";
import { blankFIARequestDetails } from "../../Forms/FIARequestForm/FIARequestForm";
import { applicationStore } from "../../../stores/ApplicationStore";
export class FIARequestStore {
  /**
   * FIA Request Currently Loaded
   */
  public fiaRequest?: FIARequest;

  constructor() {
    makeObservable(this, {
      fiaRequest: observable,
      setFIARequest: action,
    });

    reaction(
      () => (this.fiaRequest as FIARequestUpdateDTO)?.requestStatus,
      () => {
        applicationStore.getPageMissingFieldCount();
      }
    );
  }

  setFIARequest = (request: FIARequest) => {
    this.fiaRequest = request;
  };

  public create = async (
    applicationId: string,
    fiaRequestNewDTO: FIARequestNewDTO
  ) => {
    const fiaRequestCreateDTO = blankFIARequestDetails;
    mapTo(fiaRequestNewDTO, fiaRequestCreateDTO);

    const fiaRequestReadDTO = await fiaRequestApiClient.createRequest(
      applicationId,
      fiaRequestCreateDTO
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public cancel = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequestCancelDTO: FIARequestCancelDTO
  ) => {
    const fiaRequestReadDTO = await fiaRequestApiClient.cancelRequest(
      applicationId,
      fiaRequestId,
      fiaRequestCancelDTO
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public approveAndSend = async (
    applicationId: string,
    fiaRequestId: string,
    updatedFIARequest: FIARequestUpdateDTO,
    isBypass: boolean
  ) => {
    const fiaRequestApproveSendDTO = blankFIARequestDetails;
    mapTo(updatedFIARequest, fiaRequestApproveSendDTO);

    const fiaRequestReadDTO = await fiaRequestApiClient.approveAndSendRequest(
      applicationId,
      fiaRequestId,
      fiaRequestApproveSendDTO,
      isBypass
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public approve = async (
    applicationId: string,
    fiaRequestId: string,
    updatedFIARequest: FIARequestUpdateDTO,
    isBypass: boolean
  ) => {
    const fiaRequestApproveSendDTO = blankFIARequestDetails;
    mapTo(updatedFIARequest, fiaRequestApproveSendDTO);

    const fiaRequestReadDTO = await fiaRequestApiClient.approveRequest(
      applicationId,
      fiaRequestId,
      fiaRequestApproveSendDTO,
      isBypass
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public update = async (
    applicationId: string,
    fiaRequestId: string,
    updatedFIARequest: FIARequestDetails
  ) => {
    const fiaRequestReadDTO = await fiaRequestApiClient.updateFIARequestDetails(
      applicationId,
      fiaRequestId,
      updatedFIARequest
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public resend = async (
    applicationId: string,

    fiaRequestId: string,
    isBypass: boolean,
    fiaRequestResendDTO: FIARequestResendDTO
  ) => {
    const fiaRequestReadDTO = await fiaRequestApiClient.resendRequest(
      applicationId,
      fiaRequestId,
      isBypass,
      fiaRequestResendDTO
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public markAsReceived = async (
    applicationId: string,
    fiaRequestId: string
  ) => {
    const fiaRequestReadDTO = await fiaRequestApiClient.markAsReceivedRequest(
      applicationId,
      fiaRequestId
    );

    this.setFIARequest(fiaRequestReadDTO);
  };

  public markAsNotReceived = async (
    applicationId: string,
    fiaRequestId: string
  ) => {
    const fiaRequestReadDTO =
      await fiaRequestApiClient.markAsNotReceivedRequest(
        applicationId,
        fiaRequestId
      );

    this.setFIARequest(fiaRequestReadDTO);
  };
}

export const fiaRequestStore = new FIARequestStore();
