import {
  Classes,
  Icon,
  Intent,
  Spinner,
  SpinnerSize,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import classNames from "classnames";
import { Observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { reactPlugin } from "../../AppInsights";
import {
  applicationPageCards,
  applicationPagePrimaryCards,
} from "../../components/Application/Cards";
import { EligibilitySummary } from "../../components/Application/EligibilitySummary";
import { ResidentPanel } from "../../components/Application/Page/ResidentPanel";
import { FIARequestTab } from "../../components/FIARequests/FIARequestTab";
import { Header } from "../../components/Header/Header";
import { PageSidebar } from "../../components/PageSidebar/PageSidebar";
import { applicationStore } from "../../stores/ApplicationStore";
import { applicationViewerStore } from "../../stores/ApplicationViewerStore";
import { userStore } from "../../stores/UserStore";
import {
  Application,
  ApplicationFormPage,
  ApplicationPages,
} from "../../types/Application";
import "../styles.scss";
import { ApplicationFormTab } from "./ApplicationFormTab";
import { SplitterComponent } from "@syncfusion/ej2-react-layouts";
import "./splitter.scss";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../components/UseTitle/useTitle";
import { FinancialSummary } from "../../components/Application/FinancialSummary";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface ApplicationPageParams {
  id: string;

  //Strongly Typed Page Route As String
  selectedPageTab?: ApplicationFormPage | ApplicationPages;
}

export const primaryTabs: {
  id: ApplicationPages;
  value: string | ((app?: Application) => React.ReactNode);
}[] = [
  {
    id: "primary-information",
    value: () => (
      <ApplicationFormTab
        name="File Information"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "primary-information"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "date-information",
    value: () => (
      <ApplicationFormTab
        name="Date Information"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "date-information"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "state-information",
    value: () => (
      <ApplicationFormTab
        name="State Information"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "state-information"
          )?.missingFieldCount
        }
      />
    ),
  },
];

export const medicalCoveragePage: {
  id: ApplicationFormPage;
  value: string | ((app?: Application) => React.ReactNode);
} = {
  id: "medical-coverage",

  value: () => (
    <ApplicationFormTab
      name="Medical Coverage"
      missingFieldCount={
        applicationStore.pageMissingFieldCount.find(
          (page) => page.pageName === "medical-coverage"
        )?.missingFieldCount
      }
    />
  ),
};

export const spouseInformationPage: {
  id: ApplicationFormPage;
  value: string | ((app?: Application) => React.ReactNode);
} = {
  id: "spouse-information",
  value: () => (
    <ApplicationFormTab
      name="Spouse"
      missingFieldCount={
        applicationStore.pageMissingFieldCount.find(
          (page) => page.pageName === "spouse-information"
        )?.missingFieldCount
      }
    />
  ),
};
export const firstPages: {
  id: ApplicationFormPage;
  value: string | ((app?: Application) => React.ReactNode);
}[] = [
  {
    id: "comments",
    value: "Comments",
  },
  {
    id: "contact",
    value: () => (
      <ApplicationFormTab
        name="Contacts"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "contact"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "medical-information",
    value: () => (
      <ApplicationFormTab
        name="Medical Info"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "medical-information"
          )?.missingFieldCount
        }
      />
    ),
  },
];

export const lastPages: {
  id: ApplicationFormPage;
  value: string | ((app?: Application) => React.ReactNode);
}[] = [
  {
    id: "income-information",
    value: () => (
      <ApplicationFormTab
        name="Income"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "income-information"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "asset",
    value: () => (
      <ApplicationFormTab
        name="Assets"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "asset"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "bill-information",
    value: () => (
      <ApplicationFormTab
        name="Bills"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "bill-information"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "legal-information",
    value: () => (
      <ApplicationFormTab
        name="Legal"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "legal-information"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "signatures",
    value: () => (
      <ApplicationFormTab
        name="Signatures"
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "signatures"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "checklist",
    value: () => (
      <ApplicationFormTab
        name="Checklist"
        shouldHide={!userStore.user?.canViewChecklist}
        missingFieldCount={
          applicationStore.pageMissingFieldCount.find(
            (page) => page.pageName === "checklist"
          )?.missingFieldCount
        }
      />
    ),
  },
  {
    id: "fia-requests",
    value: (app?: Application) => <FIARequestTab applicationId={app?.id} />,
  },
  {
    id: "audit-log",
    value: "Audit Log",
  },
];

const getPages = ({
  hasMedicalCoverage,
  hasMedicarePartA,
  hasMedicarePartB,
  medicalCoverages,
  isMarried,
}: Application) => [
  ...firstPages,
  ...(hasMedicalCoverage ||
  hasMedicarePartA ||
  hasMedicarePartB ||
  medicalCoverages.length > 0
    ? [medicalCoveragePage]
    : []),
  ...(isMarried ? [spouseInformationPage] : []),
  ...lastPages,
];

const ApplicationPageComponent: React.FC<ApplicationPageParams> = (props) => {
  const splitterRef = useRef<SplitterComponent>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setTitle } = useTitle("Medicaid Done Right");

  const [selectedTab, setSelectedTab] = useState<
    ApplicationPages | ApplicationFormPage
  >(props.selectedPageTab || "comments");

  useEffect(() => {
    const panelIndex = 0;

    if (isExpanded) {
      splitterRef.current?.collapse(panelIndex);
    } else {
      splitterRef.current?.expand(panelIndex);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (userStore.user?.canViewApplicationDetail) {
      applicationStore.getApplicationById(props.id).finally(() => {
        setIsReady(true);
      });
    } else {
      navigate("/");
    }

    return () => {
      applicationStore.unsetApplication();
      setIsReady(false);
    };
  }, [props.id]);

  useEffect(() => {
    if (props.selectedPageTab) {
      setSelectedTab(props.selectedPageTab);
    }
  }, [props.selectedPageTab]);

  function applicationPageHeader() {
    return (
      <section className="application-page-header">
        <Tabs
          className={`application-page__forms ${Classes.FOCUS_DISABLED}`}
          large={true}
          renderActiveTabPanelOnly={true}
        >
          {primaryTabs.map((tab) => (
            <Tab
              className="header-tab"
              key={tab.id}
              id={tab.id}
              title={
                typeof tab.value === "string" || tab.value instanceof String
                  ? tab.value
                  : tab.value(applicationStore.application)
              }
              panel={
                <div className="application-page-card">
                  {applicationPageCards[tab.id](applicationStore.application)}
                </div>
              }
            />
          ))}
        </Tabs>
        {!applicationStore.application?.isRecertProductType ||
        (applicationStore.application.isRecertProductType &&
          featureToggleStore.featureToggles?.EnableRecertAMD) ? (
          <EligibilitySummary
            application={applicationStore.application}
            eligibilitySummary={applicationStore.eligibilitySummary}
          />
        ) : (
          <FinancialSummary application={applicationStore.application} />
        )}
      </section>
    );
  }

  function applicationPageBody() {
    return (
      <Tabs
        large={true}
        renderActiveTabPanelOnly={true}
        className={`application-page__forms__primary ${Classes.FOCUS_DISABLED}`}
        selectedTabId={selectedTab}
        onChange={(tabId) => {
          const selectedTabId = tabId.toString();
          setSelectedTab(
            selectedTabId as ApplicationPages | ApplicationFormPage
          );

          //DEV NOTE- We will handle the tab change with the state
          // while replacing url just for url reference (ex. copy/paste)
          // This urls are now registered through the routing system
          // for direct access
          window.history.replaceState(
            "tab-redirect",
            "",
            `${`/application-page/${props.id}/${selectedTabId}`}`
          );
        }}
      >
        <Icon
          icon={isExpanded ? "minimize" : "maximize"}
          intent="primary"
          onClick={() => setIsExpanded((prev) => !prev)}
        />
        {applicationStore.application &&
          getPages(applicationStore.application).map((tab) => (
            <Tab
              key={tab.id}
              id={tab.id}
              title={
                typeof tab.value === "string" || tab.value instanceof String
                  ? tab.value
                  : tab.value(applicationStore.application)
              }
              panel={
                <div className="application-card">
                  {applicationPagePrimaryCards[tab.id](
                    applicationStore.application
                  )}
                </div>
              }
            />
          ))}
      </Tabs>
    );
  }

  return (
    <div className="page application-page">
      <Observer>
        {() => (
          <>
            {setTitle(
              applicationStore.application
                ? `${applicationStore.application?.firstName} ${applicationStore.application?.lastName}`
                : "Medicaid Done Right"
            )}

            <Header
              applicationPageHeaderProps={{
                application: applicationStore.application,
                activeUsers: applicationViewerStore.activeUsers,
              }}
              breadCrumbs={[
                { href: "/", children: <Link to="/">Board</Link> },
                {
                  text: applicationStore.application?.fileNumber ? (
                    `${applicationStore.application?.firstName} ${applicationStore.application?.lastName} - ${applicationStore.application?.fileNumber}`
                  ) : (
                    <SkeletonComponent
                      className="loading-skeleton"
                      type="rectangle"
                      width={150}
                      height={15}
                    />
                  ),
                },
              ]}
            />
            <div
              className={classNames({
                content: !!isReady,
                loading_content: !isReady,
              })}
            >
              <PageSidebar activePage="home" />

              {!isReady && (
                <div className="application-page-loading-spinner">
                  <Spinner size={SpinnerSize.LARGE} intent={Intent.PRIMARY} />
                </div>
              )}
              {applicationStore.application && isReady && (
                <>
                  <ResidentPanel application={applicationStore.application} />
                  <div className="application-page-main-content">
                    <SplitterComponent
                      paneSettings={[
                        {
                          collapsible: true,
                          resizable: false,
                          size: "280px",
                          cssClass:
                            "application-page-main-contact__header-pane",
                        },
                        {
                          resizable: false,
                          cssClass: "application-page-main-contact__body-pane",
                        },
                      ]}
                      orientation="Vertical"
                      separatorSize={0}
                      ref={splitterRef}
                    >
                      <div>{applicationPageHeader()}</div>

                      <div>{applicationPageBody()}</div>
                    </SplitterComponent>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </Observer>
    </div>
  );
};

export const ApplicationPage = withAITracking(
  reactPlugin,
  ApplicationPageComponent,
  "ApplicationPage",
  "AI-flex-container"
);
