import { Button, Intent } from "@blueprintjs/core";
import Footer from "../Footer/Footer";
import classNames from "classnames";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import Form from "../Forms";
import { useActionExecuting } from "../ActtionExecuting/useActionExecuting";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import RelativityCard from "../Card/RelativityCard";
import { createHelpCenterTopicFormSchema } from "../Forms/HelpCenterTopicForm/HelpCenterTopicForm";
import "./styles.scss";
import {
  HelpCenterTopic,
  HelpCenterTopicCreateDTO,
  TopicSectionTypes,
} from "./types";
import { useHelpCenterTopic } from "./useHelpCenterTopic";
import { Loading } from "../Loading/Loading";

export interface HelpCenterTopicCreateProps {
  sectionType: TopicSectionTypes;
  usState?: number;
  onAction?: (refresh: boolean) => void;
}

export const HelpCenterTopicCreate: React.FC<HelpCenterTopicCreateProps> = (
  props
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [blankTopic, setBlankTopic] = useState<HelpCenterTopic>();
  const { getBlankTopic, createHelpCenterTopic } = useHelpCenterTopic();
  const { isActionExecuting } = useActionExecuting([isSaving]);

  useEffect(() => {
    setBlankTopic(getBlankTopic(props.sectionType, props.usState));
  }, []);

  const actionHandler = (refresh: boolean) => {
    props.onAction && props.onAction(refresh);
  };

  const createTopic = async (
    helpCenterTopicCreateDTO: HelpCenterTopicCreateDTO
  ) => {
    setIsSaving(true);

    const createdTopic = await createHelpCenterTopic(
      props.sectionType,
      helpCenterTopicCreateDTO
    ).finally(() => {
      setIsSaving(false);
    });

    if (!createdTopic) {
      return;
    }

    // Call Action
    actionHandler(true);
  };

  return (
    <section className="helpcenter-topic-create">
      {!blankTopic && <Loading />}
      {blankTopic && (
        <>
          <RelativityCard>
            <Observer>
              {() => (
                <div className="helpcenter-topic-create-form-container">
                  <Form<HelpCenterTopicCreateDTO>
                    formId="helpcenter-topic-create-form"
                    disableAllFields={isActionExecuting}
                    value={blankTopic}
                    formSchemaBuilder={createHelpCenterTopicFormSchema}
                    onFormSubmit={async (helpCenterTopicCreateDTO) => {
                      await createTopic(helpCenterTopicCreateDTO);
                    }}
                  />
                </div>
              )}
            </Observer>
          </RelativityCard>
          <Footer
            className={classNames("helpcenter-topic-create-actions-container")}
          >
            <Button
              minimal
              intent={Intent.DANGER}
              disabled={isActionExecuting}
              text="Cancel"
              onClick={() => actionHandler(false)}
            />
            <FormSubmitButton
              buttonId="helpcenter-topic-create-form-save-action"
              disabled={isActionExecuting}
              isSaving={isSaving}
              formId="helpcenter-topic-create-form"
              text="Create"
            />
          </Footer>
        </>
      )}
    </section>
  );
};
