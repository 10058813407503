import { Dialog } from "@blueprintjs/core";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";
import { HelpCenterTopicDetails } from "../HelpCenter/HelpCenterTopicDetails";
import { TopicSectionTypes, topicSectionTypeConfig } from "../HelpCenter/types";

export interface HelpCenterTopicDialogProps extends BaseDialogProps {
  sectionType?: TopicSectionTypes;
  usState?: number;
  id?: string;
  onClose?: (refresh: boolean) => void;
}

export const HelpCenterTopicDialog: React.FC<HelpCenterTopicDialogProps> = (
  props
) => {
  if (!props.sectionType) {
    return null;
  }

  const closeDialogHandler = (refresh: boolean) => {
    props.onClose && props.onClose(refresh);
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      hasBackdrop={false}
      portalClassName="mdr-dialog helpcenter-topic-dialog-container fullscreen-dialog"
      isCloseButtonShown={true}
      title={`${props.id ? "Update" : "Create"} ${
        topicSectionTypeConfig[props.sectionType].singularTitle
      }`}
      canOutsideClickClose={false}
      onClose={() => closeDialogHandler(false)}
    >
      <div className="helpcenter-topic-dialog">
        <HelpCenterTopicDetails
          id={props.id}
          sectionType={props.sectionType}
          usState={props.usState}
          onClose={closeDialogHandler}
        />
      </div>
      <div className="relativity-dialog-footer-block"></div>
    </Dialog>
  );
};
