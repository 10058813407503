import { action, makeObservable, observable } from "mobx";
import { Bill } from "../../types/Application/Bill";
import { OwnedEntityViewerStore } from "./OwnedEntityViewerStore";

export interface BillStore {
  addLocalBill: (billToAdd: Bill) => void;
  removeLocalBill: (billToRemove: Bill) => void;
  saveLocalBills: () => void;
  localBills?: Bill[];
}

export class BillOwnedEntityStore
  extends OwnedEntityViewerStore
  implements BillStore
{
  public localBills?: Bill[] = [];

  constructor() {
    super();
    makeObservable(this, {
      localBills: observable,
      addLocalBill: action,
      removeLocalBill: action,
      saveLocalBills: action,
    });
  }

  public removeLocalBill(billToRemove: Bill) {
    if (!this.localBills) {
      return;
    }
    this.localBills = this.localBills.filter((x) => x.id !== billToRemove.id);
  }

  public addLocalBill(billToAdd: Bill) {
    if (!this.localBills) {
      this.localBills = [];
    }

    const index = this.localBills.findIndex((bill) => bill.id === billToAdd.id);
    if (index === -1) {
      // Bill not found, add the new bill
      this.localBills = [...this.localBills, billToAdd];
    } else {
      // Bill found, update the existing bill
      this.localBills = this.localBills.map((bill, i) =>
        i === index ? billToAdd : bill
      );
    }
  }

  public saveLocalBills = async () => {
    const promisesToAwait = [];

    if (!!this.localBills && this.localBills.length > 0) {
      for (let i = 0; i < this.localBills.length; i++) {
        const bill = this.localBills[i];
        if (bill.id === "new-bill") {
          promisesToAwait.push(
            this.createAndAwaitApplicationOwnedEntity({
              page: "bill",
              ownedEntity: bill,
            })
          );
        } else {
          promisesToAwait.push(
            this.updateAndAwaitApplicationOwnedEntity({
              page: "bill",
              ownedEntity: bill,
              ownedEntityId: bill.id,
            })
          );
        }
      }
      await this.awaitAndValidateOwnedEntityPromises(promisesToAwait, "Bill");
      this.localBills = [];
    }
  };
}

export const billOwnedEntityStore = new BillOwnedEntityStore();
