import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  CommentLevel,
  CommentSource,
  NewComment,
} from "../../../types/Comment";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import { FinancialEligibilityConversation } from "../../../types/Application/FinancialEligibilityConversation";

export const newFormShortComment: Partial<NewComment> = {
  message: undefined,
  source: CommentSource.Document,
  level: CommentLevel.Internal,
  isPinned: false,
};

export const financialEligibilityConversationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as FinancialEligibilityConversation;

  return [
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "financialEligibilityConversationConfirmation"
      ),
      value: application.financialEligibilityConversationConfirmation,
      isRequired: true,
      onSubmit: (financialEligibilityConversationConfirmation: boolean) => {
        onValueChange({
          financialEligibilityConversationConfirmation:
            financialEligibilityConversationConfirmation,
        });
      },
      errorMessages: get(
        errors,
        "financialEligibilityConversationConfirmation"
      ),
    } as AllFields,
  ];
};
