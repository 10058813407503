import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { Application } from "../../../types/Application";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder, validGovernmentIdUtils } from "../types";
import { userStore } from "../../../stores/UserStore";

export const residentSecondaryInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as Application;
  const validGovernmentId =
    validGovernmentIdUtils.resolveResidentValidGovernmentId(application);

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "gender"),
      value: application.gender,
      onSubmit: (gender: Option) => {
        onValueChange({ gender: gender.value });
      },
      isRequired: true,
      errorMessages: get(errors, "gender"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "preferredLanguages"),
      value: application.preferredLanguages,
      onSubmit: (preferredLanguages: Option) => {
        onValueChange({ preferredLanguages: preferredLanguages.value });
      },
      isRequired: true,
      errorMessages: get(errors, "preferredLanguages"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "maritalStatus"),
      value: application.maritalStatus,
      onSubmit: (maritalStatus: Option) => {
        onValueChange({ maritalStatus: maritalStatus.value });
      },
      isRequired: true,
      errorMessages: get(errors, "maritalStatus"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "race"),
      value: application.race,
      onSubmit: (race: Option) => {
        onValueChange({ race: race.value });
      },
      isRequired: true,
      errorMessages: get(errors, "race"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "ethnicity"),
      value: application.ethnicity,
      onSubmit: (ethnicity: Option) => {
        onValueChange({ ethnicity: ethnicity.value });
      },
      isRequired: true,
      errorMessages: get(errors, "ethnicity"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isUsCitizen"),
      value: application.isUsCitizen,
      onSubmit: (isUsCitizen) => {
        onValueChange({ isUsCitizen });
      },
      isRequired: true,
      errorMessages: get(errors, "isUsCitizen"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isLawfulPermanentResident"
      ),
      value: application.isLawfulPermanentResident,
      hidden: !(application.isUsCitizen === false),
      onSubmit: (isLawfulPermanentResident) => {
        onValueChange({ isLawfulPermanentResident });
      },
      errorMessages: get(errors, "isLawfulPermanentResident"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasGreenCard"),
      value: application.hasGreenCard,
      hidden: !(application.isUsCitizen === false),
      onSubmit: (hasGreenCard) => {
        onValueChange({ hasGreenCard });
      },
      errorMessages: get(errors, "hasGreenCard"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "durationInUSA"),
      value: application.durationInUSA,
      hidden: !(application.isUsCitizen === false),
      onSubmit: (durationInUSA) => {
        onValueChange({ durationInUSA });
      },
      errorMessages: get(errors, "durationInUSA"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isMilitary"),
      value: application.isMilitary,
      onSubmit: (isMilitary) => {
        onValueChange({ isMilitary });
      },
      isRequired: true,
      errorMessages: get(errors, "isMilitary"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isVeteran"),
      value: application.isVeteran,
      onSubmit: (isVeteran) => {
        onValueChange({ isVeteran });
      },
      isRequired: true,
      errorMessages: get(errors, "isVeteran"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isIdAvailable"),
      value: validGovernmentId.isIdAvailable,
      onSubmit: (isIdAvailable) => {
        onValueChange({ isIdAvailable });
      },
      hidden: !validGovernmentId.showIsIdAvailable,
      isRequired: true,
      errorMessages: get(errors, "isIdAvailable"),
      disabled: !userStore.user?.canEditResidentIDAvailable,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isCredibleWitnessAvailable"
      ),
      value: validGovernmentId.isCredibleWitnessAvailable,
      onSubmit: (isCredibleWitnessAvailable) => {
        onValueChange({ isCredibleWitnessAvailable });
      },
      hidden: !validGovernmentId.showIsCredibleWitnessAvailable,
      isRequired: true,
      errorMessages: get(errors, "isCredibleWitnessAvailable"),
      disabled: !userStore.user?.canEditResidentIDNumber,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "idNumber"),
      hidden: !validGovernmentId.showIdNumber,
      isRequired: true,
      value: validGovernmentId.idNumber,
      onSubmit: (idNumber) => {
        onValueChange({ idNumber });
      },
      errorMessages: get(errors, "idNumber"),
      disabled: !userStore.user?.canEditResidentIDNumber,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "countryOfBirth"),
      value: application.countryOfBirth,
      onSubmit: (countryOfBirth: Option) => {
        onValueChange({ countryOfBirth: countryOfBirth.value });
      },
      isRequired: true,
      errorMessages: get(errors, "countryOfBirth"),
      //Orders USA to top of collection
      reorderOptions: [{ newIndex: 0, value: 187 }],
    } as unknown as AllFields,
  ];
};
