import { Classes } from "@blueprintjs/core";
import { Tooltip2, Tooltip2Props } from "@blueprintjs/popover2";

export interface TooltipProps extends Tooltip2Props {
  shouldShowTooltip: boolean;
}

export const WithTooltip: React.FC<TooltipProps> = ({
  shouldShowTooltip,
  children,
  ...otherProps
}) => {
  if (shouldShowTooltip) {
    return (
      <Tooltip2
        className="tooltip-popover"
        {...otherProps}
        // interactionKind={"hover"}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        portalClassName={"tooltip-popover"}
      >
        {children}
      </Tooltip2>
    );
  } else {
    return <>{children}</>;
  }
};
