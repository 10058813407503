import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { userStore } from "../../../stores/UserStore";
import { SecondaryInformation } from "../../../types/Application/SecondaryInformation";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder, validGovernmentIdUtils } from "../types";
import { Application } from "../../../types/Application";
import { getScheduleMappingClassName } from "../../../utils/miscellaneous";

export const newSecondaryInformation: SecondaryInformation = {
  gender: undefined,
  maritalStatus: undefined,
  isUsCitizen: undefined,
  preferredLanguages: undefined,
  isMilitary: undefined,
  isVeteran: undefined,
  isIdAvailable: undefined,
  idNumber: undefined,
  countryOfBirth: undefined,
  race: undefined,
  ethnicity: undefined,
};

export const secondaryInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const secondaryInformation = form as SecondaryInformation;
  const application = form as Application;
  const validGovernmentId =
    validGovernmentIdUtils.resolveResidentValidGovernmentId(
      secondaryInformation
    );

  return [
    {
      type: "Section",
      label: "Secondary Information",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "gender"),
      value: secondaryInformation.gender,
      onSubmit: (gender: Option) => {
        onValueChange({ gender: gender.value });
      },
      isRequired: true,
      errorMessages: get(errors, "gender"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "maritalStatus"),
      className: getScheduleMappingClassName(
        application.scheduleMappingValues,
        "maritalStatus"
      ),
      value: secondaryInformation.maritalStatus,
      onSubmit: (maritalStatus: Option) => {
        onValueChange({ maritalStatus: maritalStatus.value });
      },
      isRequired: true,
      errorMessages: get(errors, "maritalStatus"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "preferredLanguages"),
      value: secondaryInformation.preferredLanguages,
      onSubmit: (preferredLanguages: Option) => {
        onValueChange({ preferredLanguages: preferredLanguages.value });
      },
      isRequired: true,
      errorMessages: get(errors, "preferredLanguages"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "countryOfBirth"),
      value: secondaryInformation.countryOfBirth,
      onSubmit: (countryOfBirth: Option) => {
        onValueChange({ countryOfBirth: countryOfBirth.value });
      },
      isRequired: true,
      errorMessages: get(errors, "countryOfBirth"),
      //Orders USA to top of collection
      reorderOptions: [{ newIndex: 0, value: 187 }],
    } as unknown as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "race"),
      value: secondaryInformation.race,
      onSubmit: (race: Option) => {
        onValueChange({ race: race.value });
      },
      isRequired: true,
      errorMessages: get(errors, "race"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "ethnicity"),
      value: secondaryInformation.ethnicity,
      onSubmit: (ethnicity: Option) => {
        onValueChange({ ethnicity: ethnicity.value });
      },
      isRequired: true,
      errorMessages: get(errors, "ethnicity"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isUsCitizen"),
      value: secondaryInformation.isUsCitizen,
      onSubmit: (isUsCitizen) => {
        onValueChange({ isUsCitizen });
      },
      isRequired: true,
      errorMessages: get(errors, "isUsCitizen"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isLawfulPermanentResident"
      ),
      value: secondaryInformation.isLawfulPermanentResident,
      hidden: !(secondaryInformation.isUsCitizen === false),
      onSubmit: (isLawfulPermanentResident) => {
        onValueChange({ isLawfulPermanentResident });
      },
      width: "33.3%",
      errorMessages: get(errors, "isLawfulPermanentResident"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasGreenCard"),
      value: secondaryInformation.hasGreenCard,
      hidden: !(secondaryInformation.isUsCitizen === false),
      onSubmit: (hasGreenCard) => {
        onValueChange({ hasGreenCard });
      },
      width: "33.3%",
      errorMessages: get(errors, "hasGreenCard"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "durationInUSA"),
      value: secondaryInformation.durationInUSA,
      hidden: !(secondaryInformation.isUsCitizen === false),
      onSubmit: (durationInUSA) => {
        onValueChange({ durationInUSA });
      },
      width: "33.3%",
      errorMessages: get(errors, "durationInUSA"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isMilitary"),
      value: secondaryInformation.isMilitary,
      onSubmit: (isMilitary) => {
        onValueChange({ isMilitary });
      },
      isRequired: true,
      errorMessages: get(errors, "isMilitary"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isVeteran"),
      value: secondaryInformation.isVeteran,
      onSubmit: (isVeteran) => {
        onValueChange({ isVeteran });
      },
      isRequired: true,
      errorMessages: get(errors, "isVeteran"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isIdAvailable"),
      value: validGovernmentId.isIdAvailable,
      onSubmit: (isIdAvailable) => {
        onValueChange({ isIdAvailable });
      },
      hidden: !validGovernmentId.showIsIdAvailable,
      isRequired: true,
      errorMessages: get(errors, "isIdAvailable"),
      disabled: !userStore.user?.canEditResidentIDAvailable,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isCredibleWitnessAvailable"
      ),
      value: validGovernmentId.isCredibleWitnessAvailable,
      onSubmit: (isCredibleWitnessAvailable) => {
        onValueChange({ isCredibleWitnessAvailable });
      },
      hidden: !validGovernmentId.showIsCredibleWitnessAvailable,
      isRequired: true,
      errorMessages: get(errors, "isCredibleWitnessAvailable"),
      disabled: !userStore.user?.canEditResidentIDNumber,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "idNumber"),
      hidden: !validGovernmentId.showIdNumber,
      isRequired: true,
      value: validGovernmentId.idNumber,
      onSubmit: (idNumber) => {
        onValueChange({ idNumber });
      },
      errorMessages: get(errors, "idNumber"),
      disabled: !userStore.user?.canEditResidentIDNumber,
    } as AllFields,
  ];
};
