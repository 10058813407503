import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { useState } from "react";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { FIARequestResendDTO } from "../FIARequests/configurations/types";
import { BaseField } from "../Forms/BaseField/BaseField";
import { fiaRequestFormSchema } from "../Forms/FIARequestForm/FIARequestForm";
import { fiaRequestStore } from "../FIARequests/stores/FIARequestStore";
import { AppToaster } from "../Toast/Toast";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { ExistingInstitution } from "../../types/Institution";
import { getRightArticleSyntax_aWord } from "../../utils/miscellaneous";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface FIARequestResendDialog extends BaseDialogProps {
  applicationId?: string;
  fiaRequestId?: string;
  institution?: ExistingInstitution;
  setIsResendLoading?: (isResending: boolean) => void;
  isBypass?: boolean;
  onSuccessfulResend?: () => void;
}
export const newFIARequestResendDTO: FIARequestResendDTO = {
  followUpReason: undefined,
  followUpSecondaryReason: undefined,
};
export const FIARequestResendDialog: React.FC<FIARequestResendDialog> = ({
  isOpen,
  closeDialog,
  submitDialog,
  applicationId,
  fiaRequestId,
  institution,
  setIsResendLoading,
  isBypass,
  onSuccessfulResend,
}) => {
  const formId = "fia-request-resend-form";

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [fiaRequestResendDTO, setFIARequestResendDTO] =
    useState<FIARequestResendDTO>(newFIARequestResendDTO);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  return (
    <>
      <Dialog
        className="mdr-dialog-right"
        portalClassName="mdr-dialog"
        isOpen={isOpen}
        hasBackdrop={false}
        isCloseButtonShown={true}
        title={"Resend FIA Request"}
        onClose={() => {
          setIsResendLoading && setIsResendLoading(false);
          closeDialog();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <BaseForm<FIARequestResendDTO>
            formId={formId}
            value={fiaRequestResendDTO}
            formValueState={{
              formValue: fiaRequestResendDTO,
              setFormValue: setFIARequestResendDTO,
            }}
            onErrors={(errors) => setErrors(errors)}
            onFormSubmit={async (fiaRequestResendDTO) => {
              setIsSaving(true);
              const result = { isSuccess: true, error: undefined };
              applicationId &&
                fiaRequestId &&
                (await fiaRequestStore
                  .resend(
                    applicationId,
                    fiaRequestId,
                    !!isBypass,
                    fiaRequestResendDTO
                  )
                  .then(() => {
                    setIsResendLoading && setIsResendLoading(false);
                    onSuccessfulResend && onSuccessfulResend();
                  })
                  .catch((error) => {
                    result.isSuccess = false;
                    result.error = error;

                    if (error?.response?.status == 400) {
                      setErrors(error.response.data.additionalInfo);
                    }

                    AppToaster.show({
                      message: <div>{genericErrorMessage}</div>,
                      intent: Intent.DANGER,
                    });
                  })
                  .finally(() => {
                    setIsSaving(false);
                    if (result.isSuccess) {
                      closeDialog();
                      submitDialog();
                    }
                  }));
            }}
          >
            <div className="resend-fia-field-container">
              <div>
                By submitting this FIA Request, you attest that you have
                reviewed it. Clicking <b>Yes</b> will send{" "}
                {getRightArticleSyntax_aWord(
                  institution?.sendingContactMethod_AsString
                )}{" "}
                <b>{institution?.sendingContactMethod_AsString}</b> to{" "}
                <b> {institution?.name}</b> and document the date and time of
                your attestation.
              </div>
              {featureToggleStore.featureToggles?.EnableFiaFollowUpReason && (
                <>
                  <div className="resend-fia-fields">
                    <BaseField
                      {...fiaRequestFormSchema.followUpReason(
                        fiaRequestResendDTO,
                        setFIARequestResendDTO,
                        errors
                      )}
                    />
                  </div>
                  {fiaRequestResendDTO.followUpReason === 4 && (
                    <div className="resend-fia-fields">
                      <BaseField
                        {...fiaRequestFormSchema.followUpSecondaryReason(
                          fiaRequestResendDTO,
                          setFIARequestResendDTO,
                          errors
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </BaseForm>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={() => {
              setIsResendLoading && setIsResendLoading(false);
              closeDialog();
            }}
          />
          <FormSubmitButton
            buttonId="fia-resend-save-button"
            isSaving={isSaving}
            formId={formId}
            text="Save"
            disabled={
              !fiaRequestResendDTO.followUpReason &&
              featureToggleStore.featureToggles?.EnableFiaFollowUpReason
            }
          />
        </div>
      </Dialog>
    </>
  );
};
