import { makeObservable, observable } from "mobx";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
  SelectEventArgs,
  FilteringEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GlobalApplicationSearchOptions } from "../../Fields/types/fieldTypes";
import { ODataStore } from "../../../stores/ODataStore";

export interface PreviewApplicationReadDTO {
  id_AsString?: string;
  fileNumber?: string;
  firstName?: string;
  lastName?: string;
  productType_AsString?: string;
}

export class ApplicationSearchToolbarStore extends ODataStore {
  // public
  public autoCompleteInstance?: AutoCompleteComponent;
  public applicationId?: string;

  // Events
  onRecordClick = (selectedOption: GlobalApplicationSearchOptions) => {
    let redirectTo: string | undefined = undefined;

    if (selectedOption.status_AsString !== "Interviewing") {
      redirectTo = `/application-page/${selectedOption.value}`;
    } else {
      redirectTo = `/application-form/${selectedOption.value}/primary-information`;
    }

    window.open(redirectTo, "_blank");
  };

  showSearchPopup = () => {
    if (!!this.autoCompleteInstance) {
      this.autoCompleteInstance.showPopup();
    }
  };

  handleSearchSelect = async (args: SelectEventArgs) => {
    if (!!this.autoCompleteInstance) {
      const data = args?.itemData as GlobalApplicationSearchOptions;

      if (data?.value) {
        this.onRecordClick(data);
      }
    }
  };

  private defaultAutoCompleteSettings: AutoCompleteModel &
    DefaultHtmlAttributes = {
    fields: { text: "label", value: "value" },
    itemTemplate:
      "<table><tr><td>${fileNumber} - ${fullName} - ${facility_State} - ${internalStatus}</td></tr><table>",
    autofill: true,
    showClearButton: false,
    highlight: true,
    placeholder: "Enter File #, Resident Name, or SSN to Search",
    select: this.handleSearchSelect,
    focus: this.showSearchPopup,
    onClick: this.showSearchPopup,
  };

  constructor() {
    super();

    makeObservable(this, {
      autoCompleteInstance: observable,
    });
  }

  public setup = async (args: {
    odataUrl: string;
    getAccessToken: () => Promise<string | undefined>;
  }) => {
    await this.init(args.getAccessToken);

    // Setup Properties
    const autoCompleteSettings = {
      ...this.defaultAutoCompleteSettings,
    } as AutoCompleteModel & DefaultHtmlAttributes;

    const dm = this.setupODataSource(args.odataUrl);
    autoCompleteSettings.dataSource = dm;

    // Dev Notes. This retriggers the ajax, select, and preview. Analyze later if we want to avoid it.
    autoCompleteSettings.value = this.applicationId;

    const query = new Query()
      .select([
        "label",
        "value",
        "fileNumber",
        "firstName",
        "lastName",
        "fullName",
        "socialSecurityNumber",
        "applicationContacts_FullNames_AsCSV",
        "stateAppNumber",
        "caseNumber",
        "internalStatus",
        "facility_State",
        "medicaidIdNumber",
      ])
      .sortBy("firstName")
      .take(500);

    autoCompleteSettings.query = query;

    //Filtering across multiple columns
    const onFiltering = async (args: FilteringEventArgs, dataSource = dm) => {
      if (!this.autoCompleteInstance) {
        return;
      }

      //set Query
      const predicate = new Predicate("fileNumber", "contains", args.text, true)
        .or(new Predicate("firstName", "contains", args.text, true))
        .or(new Predicate("lastName", "contains", args.text, true))
        .or(new Predicate("fullName", "contains", args.text, true))
        .or(
          new Predicate(
            "applicationContacts_FullNames_AsCSV",
            "contains",
            args.text,
            true
          )
        )
        .or(new Predicate("socialSecurityNumber", "contains", args.text, true))
        .or(new Predicate("stateAppNumber", "contains", args.text, true))
        .or(new Predicate("caseNumber", "contains", args.text, true))
        .or(new Predicate("medicaidIdNumber", "contains", args.text, true));

      const query = this.autoCompleteInstance.query.clone();
      query
        .select([
          "label",
          "value",
          "fileNumber",
          "firstName",
          "lastName",
          "fullName",
          "socialSecurityNumber",
          "applicationContacts_FullNames_AsCSV",
          "stateAppNumber",
          "caseNumber",
        ])
        .where(predicate)
        .take(500);
      query.search(args.text);
      args.updateData(dataSource, query);
    };
    autoCompleteSettings.filtering = onFiltering;

    autoCompleteSettings.actionFailure = this.baseActionFailureEvent(() => {
      this.autoCompleteInstance?.hidePopup();
      setTimeout(() => {
        this.autoCompleteInstance?.showPopup();
      }, 100);
    });
    autoCompleteSettings.actionComplete = this.baseActionCompleteEvent();

    return autoCompleteSettings;
  };

  public initialize = (newAutoCompleteInstance: AutoCompleteComponent) => {
    this.setInstance(newAutoCompleteInstance);
  };

  public reset = () => {
    this.setInstance(undefined);
  };

  public setInstance = (scopeAutoCompleteInstance?: AutoCompleteComponent) => {
    this.autoCompleteInstance = scopeAutoCompleteInstance;

    // Additional Settings
    if (scopeAutoCompleteInstance) {
      const element = scopeAutoCompleteInstance.element as HTMLInputElement;
      if (element) {
        element.maxLength = 38;
      }
    }
  };
}

export const applicationSearchToolbarStore =
  new ApplicationSearchToolbarStore();
