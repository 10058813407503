import { Button, Intent, Tag } from "@blueprintjs/core";
import { FC, useState } from "react";
import RelativityCard from "../../../Card/RelativityCard";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import { BaseField } from "../../../Forms/BaseField/BaseField";
import { applicationSummaryOutstandingCount } from "../../../Forms/ApplicationSummary/FileInformationFormSchema";
import { IconNames } from "@blueprintjs/icons";
import { userStore } from "../../../../stores/UserStore";

export interface OutstandingFIARequestsCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
}

export const OutstandingFIARequestsCard: FC<
  OutstandingFIARequestsCardProps
> = ({ applicationSummary }) => {
  const [isViewing, setIsViewing] = useState<boolean>(
    userStore.user?.isExternal ? false : true
  );

  const [localApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(applicationSummary);
  return (
    <RelativityCard
      className="summary-info"
      headerContent={
        <div className="outstanding-card__header">
          <Button
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            {userStore.user?.isExternal
              ? "MDR Ordered"
              : "Oustanding FIA Requests"}
          </Button>
          <div className="application-form-tab">
            {(localApplicationSummary.outstandingFIARequestsCount || 0) > 0 && (
              <Tag large={true} round={true}>
                <BaseField
                  {...applicationSummaryOutstandingCount.outstandingFIARequests(
                    localApplicationSummary
                  )}
                ></BaseField>
              </Tag>
            )}
          </div>
        </div>
      }
      hideBody={!isViewing}
    >
      <div className="summary-info__section">
        {localApplicationSummary.fiaRequests?.map((fiaRequest, index) => {
          fiaRequest;
          return (
            <Button
              className="summary-button-group__buttons__default"
              key={index}
              icon={IconNames.Office}
              minimal
              intent={Intent.NONE}
              text={fiaRequest.institutionName}
            />
          );
        })}
      </div>
    </RelativityCard>
  );
};
