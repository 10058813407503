import {
  ExcelExport,
  Filter,
  GridModel,
  GridComponent,
  Group,
  InfiniteScroll,
  Inject,
  Page,
  Reorder,
  Resize,
  Search,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { Predicate } from "@syncfusion/ej2-data";
import { Button, Intent } from "@blueprintjs/core";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Observer } from "mobx-react";
import { autorun } from "mobx";
import React from "react";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import "./styles.scss";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { Discount } from "../../types/Discount";
import { discountGridStore } from "./store/DiscountGridStore";
import { BaseGridModel } from "../Grid/Grid/types/BaseGridModel";
import { GridPermissions } from "../Grid/types/GridPermissions";
import { reactPlugin } from "../../AppInsights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { discountGridColumnsConfiguration } from "./configurations/gridColumnsConfiguration";

export interface DiscountGridProps<T extends object>
  extends Partial<GridPermissions> {
  corporateId: string;
  gridConfiguration?: Readonly<BaseGridModel<T>>;
}

const DiscountGridComponent = <T extends object>(
  props: DiscountGridProps<T>
) => {
  const gridRef = React.useRef<GridComponent | null>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [gridSettings, setGridSettings] = React.useState<
    GridModel & DefaultHtmlAttributes
  >();
  const [ready, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    autorun(async () => {
      discountGridStore.refresh();
    });
  }, []);

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      discountGridStore.reset();
    };
  }, []);

  // Initialize
  React.useEffect(() => {
    if (!!gridRef.current && !initialized) {
      discountGridStore.initialize(gridRef.current);
      setInitialized(true);
    }
  });

  // Setup Method
  const setup = async () => {
    const gridModel = await discountGridStore.setup({
      getAccessToken: getAccessToken,
      canExportGridToExcel: !!props.canExportGridToExcel,
      canInvokeGridRecord: !!props.canInvokeGridRecord,
      gridConfiguration: props.gridConfiguration,
      gridColumnsConfiguration: discountGridColumnsConfiguration,
      dataSource: "discountodata",
      predicate: new Predicate("corporateId", "equal", props.corporateId),
    });

    setGridSettings(gridModel);
    setReady(true);
  };

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-right">
          {props.canCreateNewRecord && (
            <Button
              className="create-discount-button"
              intent={Intent.PRIMARY}
              text="Create New Discount"
              onClick={() => {
                dialogsViewerStore.setIsDiscountCreationDialogOpen(true, {
                  corporateId: props.corporateId,
                });
              }}
            />
          )}
        </ul>
      </section>
      <section className="board-grid-container">
        <div className="grid-container">
          <Observer>
            {() => (
              <>
                {ready && (
                  <GridComponent
                    ref={gridRef}
                    {...gridSettings}
                    className="grid"
                    recordClick={(e: { rowData: Discount }) => {
                      dialogsViewerStore.setIsDiscountUpdateDeleteDialogOpen(
                        true,
                        {
                          discount: e.rowData,
                        }
                      );
                    }}
                  >
                    <Inject
                      services={[
                        Page,
                        Sort,
                        Filter,
                        Group,
                        Toolbar,
                        Reorder,
                        Resize,
                        InfiniteScroll,
                        Search,
                        ExcelExport,
                      ]}
                    />
                  </GridComponent>
                )}
                {!ready && <></>}
              </>
            )}
          </Observer>
        </div>
      </section>
    </div>
  );
};

export const DiscountGrid = withAITracking(
  reactPlugin,
  DiscountGridComponent,
  "DiscountGrid",
  "AI-flex-container"
);
