import { Button } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { ViewSelectionDropdownProps } from "../interfaces/ViewSelectionDropdownProps";
import { ViewSelectionMenu } from "./ViewSelectionMenu";

export const ViewSelectionDropdown: React.FC<ViewSelectionDropdownProps> = ({
  menuItems,
  viewTitle,
}) => {
  return (
    <Popover2
      content={<ViewSelectionMenu menuItems={menuItems} />}
      placement="bottom"
    >
      <Button
        alignText="center"
        icon="menu"
        rightIcon="caret-down"
        text={viewTitle}
      />
    </Popover2>
  );
};
