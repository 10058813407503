import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { notaryApiClient } from "../../lib/apiClients/notary/notaryApiClient";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import {
  newFormNotary,
  notaryFormSchema,
} from "../Forms/NotaryForm/NotaryForm";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { NewNotary } from "../../types/Notary";

export interface NotaryCreationDialogProps extends BaseDialogProps {}

export const NotaryCreationDialog: React.FC<NotaryCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Notary"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<NewNotary>
              formId="create-notary-form"
              value={newFormNotary}
              formSchemaBuilder={notaryFormSchema}
              onFormSubmit={async (notary) => {
                setIsSaving(true);

                await notaryApiClient
                  .createNotary(notary)
                  .then(() => {
                    gridStore.refresh();
                    submitDialog();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="notary-save-button"
          isSaving={isSaving}
          formId="create-notary-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
