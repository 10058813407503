import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import {
  createTemplateFormSchema,
  newFormTemplate,
} from "../Forms/TemplateForm/TemplateForm";
import { Template } from "../../types/Template";
import { templateApiClient } from "../../lib/apiClients/template/templateApiClient";
import { useNavigate } from "react-router";
import React from "react";

export interface TemplateCreationDialogProps extends BaseDialogProps {}

export const TemplateCreationDialog: React.FC<TemplateCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  return (
    <Dialog
      portalClassName="mdr-dialog template-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Template"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<Template>
              formId="create-template-form"
              value={newFormTemplate}
              formSchemaBuilder={createTemplateFormSchema}
              onFormSubmit={async (template) => {
                setIsSaving(true);

                await templateApiClient
                  .createTemplate(template)
                  .then((template) => {
                    gridStore.refresh();
                    navigate(`/templates/${template.id}`);
                    submitDialog();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="template-save-button"
          isSaving={isSaving}
          formId="create-template-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
