import {
  assetTypeString,
  billTypeString,
  getAssetTypeSchema,
  incomeTypeString,
  medicalCoverageTypeString,
  otherTypeString,
} from "../../Application/UseOwnedEntityComponent";
import { AllFields } from "../../Fields/types/fieldTypes";
import { Option } from "../../Fields/types/fieldTypes";
import assetSchemas from "../ApplicationForm/Assets";

const ownedEntityTypeOptions: Option[] = [
  {
    label: "Medical Coverage",
    value: medicalCoverageTypeString,
  },
  { label: "Income", value: incomeTypeString },
  { label: "Asset", value: assetTypeString },
  { label: "Bill", value: billTypeString },
  { label: "Other", value: otherTypeString },
];

const assetTypes: Option[] = assetSchemas.map((schema: any) => ({
  label: schema.title,
  value: schema.type.toString(),
}));

export const addOwnedEntityFormSchema = {
  ownedEntityType: (setOwnedEntityType: (ownedEntityType: string) => void) => {
    return {
      type: "Select",
      label: "Type",
      value: 0,

      isRequired: true,
      optionValues: ownedEntityTypeOptions,
      onSubmit: (ownedEntityType: Option) => {
        setOwnedEntityType(ownedEntityType.value);
      },
    } as AllFields;
  },
  ownedEntityAssetType: (
    localOwnedEntityType: string,
    setAssetTypeSchema: (value: any) => void
  ) => {
    return {
      type: "Select",
      label: "Asset Subtype",
      value: 0,

      isRequired: true,
      hidden: localOwnedEntityType !== assetTypeString,
      optionValues: assetTypes,
      onSubmit: (assetSubtype: Option) => {
        const schema = getAssetTypeSchema(assetSubtype.value);
        setAssetTypeSchema(schema);
      },
    } as AllFields;
  },
};
