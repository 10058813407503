import { schemaStore } from "../../../stores/SchemaStore";
import { BillInformation } from "../../../types/Application/BillInformation";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import { applicationStore } from "../../../stores/ApplicationStore";

export const newBillInformation: BillInformation = {
  doesResidentHaveBills: undefined,
};

export const billInformationFormSchema: FormSchemaBuilder = (
  _onValueChange,
  form,
  _errors,
  _formId,
  onFormSubmit,
  _disableAllFields,
  hideSectionHeader
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const billInformation = form as BillInformation;

  return [
    {
      type: "Section",
      label: "Bill Information",
      hidden: !!hideSectionHeader,
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "doesResidentHaveBills"),
      value: billInformation.doesResidentHaveBills,
      onSubmit: (doesResidentHaveBills) => {
        if (onFormSubmit) {
          onFormSubmit({ ...billInformation, doesResidentHaveBills });
        }
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "doesSpouseHaveBills"),
      value: billInformation.doesSpouseHaveBills,
      onSubmit: (doesSpouseHaveBills) => {
        if (onFormSubmit) {
          onFormSubmit({ ...billInformation, doesSpouseHaveBills });
        }
      },
      hidden: !applicationStore.application?.isMarried,
      isRequired: true,
    } as AllFields,
  ];
};
