import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { IconNames } from "@blueprintjs/icons";
import { useState, useEffect } from "react";
import "../Grid/Views/styles.scss";
import { userStore } from "../../stores/UserStore";
import { UserRoles } from "../../types/User";

export interface UserCannedViewPickerProps {
  setGridCannedView: Function;
}

export const UserCannedViewPicker: React.FC<UserCannedViewPickerProps> =
  observer((props) => {
    const [cannedView, setCannedView] = useState<string>(
      userStore.user?.role !== UserRoles.FieldRepresentativeManager
        ? "External"
        : "Internal"
    );

    useEffect(() => {
      props.setGridCannedView(cannedView);

      return () => {
        props.setGridCannedView("External");
      };
    }, [cannedView, setCannedView]);

    return (
      <div className="canned-view-picker-popover">
        <Popover2
          placement="bottom-end"
          content={
            <Menu>
              {userStore.user?.role !==
                UserRoles.FieldRepresentativeManager && (
                <MenuItem
                  text="External Users"
                  className="external"
                  onClick={() => {
                    setCannedView("External");
                  }}
                />
              )}
              <MenuItem
                text="Internal Users"
                className="internal"
                onClick={() => {
                  setCannedView("Internal");
                }}
              />
            </Menu>
          }
        >
          <div className="canned-view-picker-text">
            <div>{`${cannedView} Users`}</div>
            <div>
              <Icon iconSize={15} icon={IconNames.CHEVRON_DOWN} />
            </div>
          </div>
        </Popover2>
      </div>
    );
  });
