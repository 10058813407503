import GridColumn from "../../../Grid/Grid/types/GridColumn";

export const corporateUsersGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "facilities_AsString",
    headerText: "Facilities",
    type: "string",
  }),
  new GridColumn({
    field: "firstName",
    headerText: "First Name",
    type: "string",
  }),
  new GridColumn({
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "title",
    headerText: "Title",
    type: "string",
  }),
  new GridColumn({
    field: "userName",
    headerText: "User Name",
    type: "string",
  }),
  new GridColumn({
    field: "role_AsString",
    headerText: "Role",
    type: "string",
  }),
  {
    field: "userInviteEmailSentDate_AsDateOnly",
    headerText: "Invite Date Sent",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  } as GridColumn,
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "user-is-active-header",
    },
  }),
];
