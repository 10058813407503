import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { IncomeInformation } from "../../../types/Application/IncomeInformation";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import { applicationStore } from "../../../stores/ApplicationStore";

export const newIncomeInformation: IncomeInformation = {
  //Social Security
  doesResidentHaveIncomeFromSocialSecurity: undefined,
  socialSecurityAmount: undefined,
  socialSecurityStartDate: undefined,
  socialSecurityVerified: undefined,
  //Spouse Social Security
  doesSpouseHaveIncomeFromSocialSecurity: undefined,
  spouseSocialSecurityAmount: undefined,
  spouseSocialSecurityStartDate: undefined,
  spouseSocialSecurityVerified: undefined,
  //SSI
  doesResidentHaveIncomeFromSSI: undefined,
  didResidentGetSSIInMonthBeforeSocialSecurityBenefits: undefined,
  isResidentRequiredToFileForWidowerBenefits: undefined,
  doesResidentGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability:
    undefined,
  hasResidentReceivedSSIAndSocialSecurityBenefitsConcurrently: undefined,
  isResidentEntitledToSocialSecurityWidowerBenefits: undefined,
  isResidentReceivingSocialSecurityBenefitsUnderParentsCoverage: undefined,
  hasResidentReceivedSSIBenefitsPriorToAge60: undefined,
  ssiAmount: undefined,
  ssiStartDate: undefined,
  ssiVerified: undefined,
  //Spouse SSI
  doesSpouseHaveIncomeFromSSI: undefined,
  didSpouseGetSSIInMonthBeforeSocialSecurityBenefits: undefined,
  isSpouseRequiredToFileForWidowerBenefits: undefined,
  doesSpouseGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability:
    undefined,
  hasSpouseReceivedSSIAndSocialSecurityBenefitsConcurrently: undefined,
  isSpouseEntitledToSocialSecurityWidowerBenefits: undefined,
  hasSpouseReceivedSSIBenefitsPriorToAge60: undefined,
  spouseSSIAmount: undefined,
  spouseSSIStartDate: undefined,
  spouseSSIVerified: undefined,
  // VA Benefits
  doesResidentHaveIncomeFromVABenefits: undefined,
  vaBenefitsAmount: undefined,
  vaBenefitsStartDate: undefined,
  vaBenefitsVerified: undefined,
  //Spouse VA Benefits
  doesSpouseHaveIncomeFromVABenefits: undefined,
  spouseVABenefitsAmount: undefined,
  spouseVABenefitsStartDate: undefined,
  spouseVABenefitsVerified: undefined,
};

export const incomeInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  _formId,
  onFormSubmit,
  _disableAllFields,
  hideSectionHeader
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const incomeInformation = form as IncomeInformation;

  return [
    {
      type: "Section",
      label: "Income Information",
      hidden: !!hideSectionHeader,
    } as SectionHeaderProps,
    //Social Security
    {
      type: "Section",
      label: "Social Security",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentHaveIncomeFromSocialSecurity"
      ),
      value: incomeInformation.doesResidentHaveIncomeFromSocialSecurity,
      onSubmit: (doesResidentHaveIncomeFromSocialSecurity) => {
        onValueChange({ doesResidentHaveIncomeFromSocialSecurity });
      },
      errorMessages: get(errors, "doesResidentHaveIncomeFromSocialSecurity"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "socialSecurityAmount"),
      onSubmit: (socialSecurityAmount) => {
        onValueChange({ socialSecurityAmount });
      },
      value: incomeInformation.socialSecurityAmount,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSocialSecurity,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "socialSecurityAmount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "socialSecurityStartDate"),
      value: incomeInformation.socialSecurityStartDate,
      onSubmit: (socialSecurityStartDate) => {
        onValueChange({ socialSecurityStartDate });
      },
      hidden: !incomeInformation.doesResidentHaveIncomeFromSocialSecurity,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "socialSecurityStartDate"),
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "socialSecurityVerified"),
      onSubmit: (socialSecurityVerified) => {
        onValueChange({ socialSecurityVerified });
      },
      value: incomeInformation.socialSecurityVerified,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSocialSecurity,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "socialSecurityVerified"),
    } as AllFields,
    //Spouse Social Security
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesSpouseHaveIncomeFromSocialSecurity"
      ),
      hidden: !applicationStore.application?.isMarried,
      value: incomeInformation.doesSpouseHaveIncomeFromSocialSecurity,
      onSubmit: (doesSpouseHaveIncomeFromSocialSecurity) => {
        onValueChange({ doesSpouseHaveIncomeFromSocialSecurity });
      },
      isRequired: true,
      errorMessages: get(errors, "doesSpouseHaveIncomeFromSocialSecurity"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseSocialSecurityAmount"
      ),
      onSubmit: (spouseSocialSecurityAmount) => {
        onValueChange({ spouseSocialSecurityAmount });
      },
      value: incomeInformation.spouseSocialSecurityAmount,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSocialSecurity,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseSocialSecurityAmount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseSocialSecurityStartDate"
      ),
      value: incomeInformation.spouseSocialSecurityStartDate,
      onSubmit: (spouseSocialSecurityStartDate) => {
        onValueChange({ spouseSocialSecurityStartDate });
      },
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSocialSecurity,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseSocialSecurityStartDate"),
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseSocialSecurityVerified"
      ),
      onSubmit: (spouseSocialSecurityVerified) => {
        onValueChange({ spouseSocialSecurityVerified });
      },
      value: incomeInformation.spouseSocialSecurityVerified,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSocialSecurity,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseSocialSecurityVerified"),
    } as AllFields,
    //SSI
    {
      type: "Section",
      label: "SSI",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentHaveIncomeFromSSI"
      ),
      onSubmit: (doesResidentHaveIncomeFromSSI) => {
        onValueChange({ doesResidentHaveIncomeFromSSI });
      },
      value: incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(errors, "doesResidentHaveIncomeFromSSI"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "didResidentGetSSIInMonthBeforeSocialSecurityBenefits"
      ),
      value:
        incomeInformation.didResidentGetSSIInMonthBeforeSocialSecurityBenefits,
      onSubmit: (didResidentGetSSIInMonthBeforeSocialSecurityBenefits) => {
        onValueChange({ didResidentGetSSIInMonthBeforeSocialSecurityBenefits });
      },
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "didResidentGetSSIInMonthBeforeSocialSecurityBenefits"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isResidentRequiredToFileForWidowerBenefits"
      ),
      onSubmit: (isResidentRequiredToFileForWidowerBenefits) => {
        onValueChange({ isResidentRequiredToFileForWidowerBenefits });
      },
      value: incomeInformation.isResidentRequiredToFileForWidowerBenefits,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(errors, "isResidentRequiredToFileForWidowerBenefits"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability"
      ),
      onSubmit: (
        doesResidentGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability
      ) => {
        onValueChange({
          doesResidentGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability,
        });
      },
      value:
        incomeInformation.doesResidentGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "doesResidentGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasResidentReceivedSSIAndSocialSecurityBenefitsConcurrently"
      ),
      onSubmit: (
        hasResidentReceivedSSIAndSocialSecurityBenefitsConcurrently
      ) => {
        onValueChange({
          hasResidentReceivedSSIAndSocialSecurityBenefitsConcurrently,
        });
      },
      value:
        incomeInformation.hasResidentReceivedSSIAndSocialSecurityBenefitsConcurrently,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "hasResidentReceivedSSIAndSocialSecurityBenefitsConcurrently"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isResidentEntitledToSocialSecurityWidowerBenefits"
      ),
      onSubmit: (isResidentEntitledToSocialSecurityWidowerBenefits) => {
        onValueChange({
          isResidentEntitledToSocialSecurityWidowerBenefits,
        });
      },
      value:
        incomeInformation.isResidentEntitledToSocialSecurityWidowerBenefits,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "isResidentEntitledToSocialSecurityWidowerBenefits"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isResidentReceivingSocialSecurityBenefitsUnderParentsCoverage"
      ),
      onSubmit: (
        isResidentReceivingSocialSecurityBenefitsUnderParentsCoverage
      ) => {
        onValueChange({
          isResidentReceivingSocialSecurityBenefitsUnderParentsCoverage,
        });
      },
      value:
        incomeInformation.isResidentReceivingSocialSecurityBenefitsUnderParentsCoverage,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "isResidentReceivingSocialSecurityBenefitsUnderParentsCoverage"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasResidentReceivedSSIBenefitsPriorToAge60"
      ),
      onSubmit: (hasResidentReceivedSSIBenefitsPriorToAge60) => {
        onValueChange({
          hasResidentReceivedSSIBenefitsPriorToAge60,
        });
      },
      value: incomeInformation.hasResidentReceivedSSIBenefitsPriorToAge60,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(errors, "hasResidentReceivedSSIBenefitsPriorToAge60"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "ssiAmount"),
      onSubmit: (ssiAmount) => {
        onValueChange({ ssiAmount });
      },
      value: incomeInformation.ssiAmount,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "ssiAmount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "ssiStartDate"),
      onSubmit: (ssiStartDate) => {
        onValueChange({ ssiStartDate });
      },
      value: incomeInformation.ssiStartDate,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "ssiStartDate"),
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "ssiVerified"),
      onSubmit: (ssiVerified) => {
        onValueChange({ ssiVerified });
      },
      value: incomeInformation.ssiVerified,
      hidden: !incomeInformation.doesResidentHaveIncomeFromSSI,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "ssiVerified"),
    } as AllFields,
    //Spouse SSI
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesSpouseHaveIncomeFromSSI"
      ),
      onSubmit: (doesSpouseHaveIncomeFromSSI) => {
        onValueChange({ doesSpouseHaveIncomeFromSSI });
      },
      value: incomeInformation.doesSpouseHaveIncomeFromSSI,
      hidden: !applicationStore.application?.isMarried,
      isRequired: true,
      errorMessages: get(errors, "doesSpouseHaveIncomeFromSSI"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "didSpouseGetSSIInMonthBeforeSocialSecurityBenefits"
      ),
      value:
        incomeInformation.didSpouseGetSSIInMonthBeforeSocialSecurityBenefits,
      onSubmit: (didSpouseGetSSIInMonthBeforeSocialSecurityBenefits) => {
        onValueChange({ didSpouseGetSSIInMonthBeforeSocialSecurityBenefits });
      },
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "didSpouseGetSSIInMonthBeforeSocialSecurityBenefits"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isSpouseRequiredToFileForWidowerBenefits"
      ),
      onSubmit: (isSpouseRequiredToFileForWidowerBenefits) => {
        onValueChange({ isSpouseRequiredToFileForWidowerBenefits });
      },
      value: incomeInformation.isSpouseRequiredToFileForWidowerBenefits,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(errors, "isSpouseRequiredToFileForWidowerBenefits"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesSpouseGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability"
      ),
      onSubmit: (
        doesSpouseGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability
      ) => {
        onValueChange({
          doesSpouseGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability,
        });
      },
      value:
        incomeInformation.doesSpouseGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "doesSpouseGetSocialSecurityBenefitsFromDefinitionChangeOfChildhoodDisability"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasSpouseReceivedSSIAndSocialSecurityBenefitsConcurrently"
      ),
      onSubmit: (hasSpouseReceivedSSIAndSocialSecurityBenefitsConcurrently) => {
        onValueChange({
          hasSpouseReceivedSSIAndSocialSecurityBenefitsConcurrently,
        });
      },
      value:
        incomeInformation.hasSpouseReceivedSSIAndSocialSecurityBenefitsConcurrently,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "hasSpouseReceivedSSIAndSocialSecurityBenefitsConcurrently"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isSpouseEntitledToSocialSecurityWidowerBenefits"
      ),
      onSubmit: (isSpouseEntitledToSocialSecurityWidowerBenefits) => {
        onValueChange({
          isSpouseEntitledToSocialSecurityWidowerBenefits,
        });
      },
      value: incomeInformation.isSpouseEntitledToSocialSecurityWidowerBenefits,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "isSpouseEntitledToSocialSecurityWidowerBenefits"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isSpouseReceivingSocialSecurityBenefitsUnderParentsCoverage"
      ),
      onSubmit: (
        isSpouseReceivingSocialSecurityBenefitsUnderParentsCoverage
      ) => {
        onValueChange({
          isSpouseReceivingSocialSecurityBenefitsUnderParentsCoverage,
        });
      },
      value:
        incomeInformation.isSpouseReceivingSocialSecurityBenefitsUnderParentsCoverage,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(
        errors,
        "isSpouseReceivingSocialSecurityBenefitsUnderParentsCoverage"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasSpouseReceivedSSIBenefitsPriorToAge60"
      ),
      onSubmit: (hasSpouseReceivedSSIBenefitsPriorToAge60) => {
        onValueChange({
          hasSpouseReceivedSSIBenefitsPriorToAge60,
        });
      },
      value: incomeInformation.hasSpouseReceivedSSIBenefitsPriorToAge60,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      isRequired: true,
      errorMessages: get(errors, "hasSpouseReceivedSSIBenefitsPriorToAge60"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "spouseSSIAmount"),
      onSubmit: (spouseSSIAmount) => {
        onValueChange({ spouseSSIAmount });
      },
      value: incomeInformation.spouseSSIAmount,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseSSIAmount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "spouseSSIStartDate"),
      onSubmit: (spouseSSIStartDate) => {
        onValueChange({ spouseSSIStartDate });
      },
      value: incomeInformation.spouseSSIStartDate,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseSSIStartDate"),
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "spouseSSIVerified"),
      onSubmit: (spouseSSIVerified) => {
        onValueChange({ spouseSSIVerified });
      },
      value: incomeInformation.spouseSSIVerified,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromSSI,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseSSIVerified"),
    } as AllFields,
    // VA Benefits
    {
      type: "Section",
      label: "VA Benefits",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentHaveIncomeFromVABenefits"
      ),
      onSubmit: (doesResidentHaveIncomeFromVABenefits) => {
        onValueChange({ doesResidentHaveIncomeFromVABenefits });
      },
      value: incomeInformation.doesResidentHaveIncomeFromVABenefits,
      isRequired: true,
      errorMessages: get(errors, "doesResidentHaveIncomeFromVABenefits"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "vaBenefitsAmount"),
      onSubmit: (vaBenefitsAmount) => {
        onValueChange({ vaBenefitsAmount });
      },
      value: incomeInformation.vaBenefitsAmount,
      hidden: !incomeInformation.doesResidentHaveIncomeFromVABenefits,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "vaBenefitsAmount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "vaBenefitsStartDate"),
      onSubmit: (vaBenefitsStartDate) => {
        onValueChange({ vaBenefitsStartDate });
      },
      value: incomeInformation.vaBenefitsStartDate,
      hidden: !incomeInformation.doesResidentHaveIncomeFromVABenefits,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "vaBenefitsStartDate"),
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "vaBenefitsVerified"),
      onSubmit: (vaBenefitsVerified) => {
        onValueChange({ vaBenefitsVerified });
      },
      value: incomeInformation.vaBenefitsVerified,
      hidden: !incomeInformation.doesResidentHaveIncomeFromVABenefits,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "vaBenefitsVerified"),
    } as AllFields,
    //Spouse VA Benefits
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesSpouseHaveIncomeFromVABenefits"
      ),
      onSubmit: (doesSpouseHaveIncomeFromVABenefits) => {
        onValueChange({ doesSpouseHaveIncomeFromVABenefits });
      },
      value: incomeInformation.doesSpouseHaveIncomeFromVABenefits,
      isRequired: true,
      hidden: !applicationStore.application?.isMarried,
      errorMessages: get(errors, "doesSpouseHaveIncomeFromVABenefits"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "spouseVABenefitsAmount"),
      onSubmit: (spouseVABenefitsAmount) => {
        onValueChange({ spouseVABenefitsAmount });
      },
      value: incomeInformation.spouseVABenefitsAmount,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromVABenefits,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseVABenefitsAmount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseVABenefitsStartDate"
      ),
      onSubmit: (spouseVABenefitsStartDate) => {
        onValueChange({ spouseVABenefitsStartDate });
      },
      value: incomeInformation.spouseVABenefitsStartDate,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromVABenefits,
      width: "30%",
      isRequired: true,
      maxDate: new Date(Date.now()),
      errorMessages: get(errors, "spouseVABenefitsStartDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseVABenefitsVerified"
      ),
      onSubmit: (spouseVABenefitsVerified) => {
        onValueChange({ spouseVABenefitsVerified });
      },
      value: incomeInformation.spouseVABenefitsVerified,
      hidden: !incomeInformation.doesSpouseHaveIncomeFromVABenefits,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "spouseVABenefitsVerified"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesResidentHaveAdditionalIncome"
      ),
      value: incomeInformation.doesResidentHaveAdditionalIncome,
      onSubmit: (doesResidentHaveAdditionalIncome) => {
        if (onFormSubmit) {
          onFormSubmit({
            ...incomeInformation,
            doesResidentHaveAdditionalIncome,
          });
        }
      },
      isRequired: true,
      errorMessages: get(errors, "doesResidentHaveAdditionalIncome"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "doesSpouseHaveAdditionalIncome"
      ),
      value: incomeInformation.doesSpouseHaveAdditionalIncome,
      onSubmit: (doesSpouseHaveAdditionalIncome) => {
        if (onFormSubmit) {
          onFormSubmit({
            ...incomeInformation,
            doesSpouseHaveAdditionalIncome,
          });
        }
      },
      hidden: !applicationStore.application?.isMarried,
      isRequired: true,
      errorMessages: get(errors, "doesSpouseHaveAdditionalIncome"),
    } as AllFields,
  ];
};
