import {
  AutoCompleteComponent,
  AutoCompleteModel,
} from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import { templatePickerStore } from "./stores/TemplatePickerStore";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import variables from "../../config/variables";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { TemplateODataReadDTO } from "../TemplateGrid/configurations/types";
import "./styles.scss";

export interface TemplatePickerProps {
  onTemplatePicked: (templateODataReadDTO?: TemplateODataReadDTO) => void;
  odataExcludeIds?: string[];
}
export const TemplatePicker: React.FC<TemplatePickerProps> = (props) => {
  const autoCompleteRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);
  const [autoCompleteSettings, setAutoCompleteSettings] = React.useState<
    AutoCompleteModel & DefaultHtmlAttributes
  >();

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      templatePickerStore.reset();
    };
  }, []);

  const setup = async () => {
    const autoCompleteModel = await templatePickerStore.setup({
      odataUrl: `${variables.apiBaseUrl}odata/templateodatainternal`,
      getAccessToken,
      onTemplatePicked: props.onTemplatePicked,
      odataExcludeIds: props.odataExcludeIds,
    });

    setAutoCompleteSettings(autoCompleteModel);
    setReady(true);
  };

  React.useEffect(() => {
    if (autoCompleteRef.current) {
      templatePickerStore.initialize(autoCompleteRef.current);
    }
  }, [ready, autoCompleteRef]);

  return (
    <div className="template-picker-toolbar">
      {ready && (
        <div className="template-picker-input-container">
          <AutoCompleteComponent
            ref={autoCompleteRef}
            id="template-picker-search-list"
            className="template-picker-input"
            {...autoCompleteSettings}
          />
          <Icon icon={IconNames.Search} size={14} />
        </div>
      )}
    </div>
  );
};
