import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { useState } from "react";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { FIARequestCancelDTO } from "../FIARequests/configurations/types";
import { BaseField } from "../Forms/BaseField/BaseField";
import { fiaRequestFormSchema } from "../Forms/FIARequestForm/FIARequestForm";
import { fiaRequestStore } from "../FIARequests/stores/FIARequestStore";

export interface FIARequestCancelDialogProps extends BaseDialogProps {
  applicationId?: string;
  fiaRequestId?: string;
  setIsCancelLoading?: (isCanceling: boolean) => void;
  readOnly?: boolean;
  cancelReasons?: number[];
  onSuccessfulCancel?: () => void;
}

export const FIARequestCancelDialog: React.FC<FIARequestCancelDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
  applicationId,
  fiaRequestId,
  setIsCancelLoading,
  readOnly,
  cancelReasons,
  onSuccessfulCancel,
}) => {
  const newFIACancelRequest: FIARequestCancelDTO = {
    cancelReasons_AsList: cancelReasons,
  };
  const formId = "fia-request-cancel-form";

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localCancelReasons, setLocalCancelReasons] =
    useState<FIARequestCancelDTO>(newFIACancelRequest);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  return (
    <>
      <Dialog
        portalClassName="mdr-dialog"
        className="mdr-dialog-right"
        isOpen={isOpen}
        hasBackdrop={false}
        isCloseButtonShown={true}
        title={"Cancel FIA Request"}
        onClose={() => {
          if (setIsCancelLoading) {
            setIsCancelLoading(false);
          }
          closeDialog();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <BaseForm<FIARequestCancelDTO>
            formId={formId}
            value={localCancelReasons}
            isDisabled={readOnly}
            formValueState={{
              formValue: localCancelReasons,
              setFormValue: setLocalCancelReasons,
            }}
            onErrors={(errors) => setErrors(errors)}
            onFormSubmit={async (fiaCancelRequest) => {
              setIsSaving(true);

              await fiaRequestStore
                .cancel(
                  applicationId || "",
                  fiaRequestId || "",
                  fiaCancelRequest
                )
                .then(() => {
                  if (setIsCancelLoading) {
                    setIsCancelLoading(false);
                  }
                  onSuccessfulCancel && onSuccessfulCancel();
                  closeDialog();
                  submitDialog();
                })
                .finally(() => {
                  setIsSaving(false);
                });
            }}
          >
            <div className="cancel-fia-field-container">
              {!readOnly && (
                <div>
                  Are you sure you want to <b>Cancel</b> this request?
                </div>
              )}
              <div className="cancel-fia-fields">
                <BaseField
                  {...fiaRequestFormSchema.cancelReason(
                    localCancelReasons,
                    setLocalCancelReasons,
                    errors,
                    readOnly
                  )}
                />
              </div>
            </div>
          </BaseForm>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={() => {
              if (setIsCancelLoading) {
                setIsCancelLoading(false);
              }
              closeDialog();
            }}
          />
          {!readOnly && (
            <FormSubmitButton
              buttonId="fia-cancel-save-button"
              isSaving={isSaving}
              formId={formId}
              text="Save"
            />
          )}
        </div>
      </Dialog>
    </>
  );
};
