import {
  ExcelExport,
  Filter,
  GridModel,
  GridComponent,
  Group,
  InfiniteScroll,
  Inject,
  Page,
  Reorder,
  Resize,
  Search,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { Predicate } from "@syncfusion/ej2-data";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Observer } from "mobx-react";
import { autorun } from "mobx";
import React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { GridPermissions } from "../../../Grid/types/GridPermissions";
import { BaseGridModel } from "../../../Grid/Grid/types/BaseGridModel";
import { getAccessToken } from "../../../../lib/apiClients/baseApiClient";
import { reactPlugin } from "../../../../AppInsights";
import { userApplicationsGridStore } from "./UserApplicationsGridStore";
import { userApplicationsGridColumnsConfiguration } from "./userApplicationsGridColumnConfiguration";
import { openApplicationFormOrPageFromRowData } from "../CorporateApplications/utils";
import {
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";

export interface UserApplicationsGridProps<T extends object>
  extends Partial<GridPermissions> {
  gridConfiguration?: Readonly<BaseGridModel<T>>;
  userId: string;
  userRole: string;
}

const UserApplicationsGridComponent = <T extends object>(
  props: UserApplicationsGridProps<T>
) => {
  const gridRef = React.useRef<GridComponent | null>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [gridSettings, setGridSettings] = React.useState<
    GridModel & DefaultHtmlAttributes
  >();
  const [ready, setReady] = React.useState<boolean>(false);
  const [tooltipText, setTooltipText] = React.useState<string>();

  React.useEffect(() => {
    autorun(async () => {
      userApplicationsGridStore.refresh();
    });
  }, []);

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      userApplicationsGridStore.reset();
    };
  }, []);

  // Initialize
  React.useEffect(() => {
    if (!!gridRef.current && !initialized) {
      userApplicationsGridStore.initialize(gridRef.current);
      setInitialized(true);
    }
  });

  const setPredicateField = (userRole: string) => {
    if (
      userRole === "FieldRepresentative" ||
      userRole === "FieldRepresentativeManager"
    ) {
      return "fieldRepId";
    } else {
      return "processorId";
    }
  };

  // Setup Method
  const setup = async () => {
    const gridModel = await userApplicationsGridStore.setup({
      getAccessToken: getAccessToken,
      canExportGridToExcel: !!props.canExportGridToExcel,
      canInvokeGridRecord: !!props.canInvokeGridRecord,
      gridConfiguration: props.gridConfiguration,
      dataSource: "userapplicationsodata",
      gridColumnsConfiguration: userApplicationsGridColumnsConfiguration,
      predicate: new Predicate(
        setPredicateField(props.userRole),
        "equal",
        props.userId
      ),
    });
    setGridSettings(gridModel);
    setReady(true);
  };

  // Set Tooltip on Column hover
  const beforeRender = (args: TooltipEventArgs) => {
    // Only show a tooltip for long character-length column values.
    if (!args.target.innerText || args.target.innerText.length < 25) {
      args.cancel = true;
    }

    setTooltipText(args.target.innerText);
  };

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-right"></ul>
      </section>
      <section className="board-grid-container">
        <div className="grid-container">
          <Observer>
            {() => (
              <>
                {ready && (
                  <TooltipComponent
                    id="Tooltip"
                    target=".e-rowcell"
                    beforeRender={beforeRender}
                    content={tooltipText}
                  >
                    <GridComponent
                      ref={gridRef}
                      {...gridSettings}
                      className="grid"
                      recordClick={({ rowData }) => {
                        openApplicationFormOrPageFromRowData(
                          rowData,
                          props.canInvokeGridRecord
                        );
                      }}
                    >
                      <Inject
                        services={[
                          Page,
                          Sort,
                          Filter,
                          Group,
                          Toolbar,
                          Reorder,
                          Resize,
                          InfiniteScroll,
                          Search,
                          ExcelExport,
                        ]}
                      />
                    </GridComponent>
                  </TooltipComponent>
                )}
                {!ready && <></>}
              </>
            )}
          </Observer>
        </div>
      </section>
    </div>
  );
};

export const UserApplicationsGrid = withAITracking(
  reactPlugin,
  UserApplicationsGridComponent,
  "UserApplicationsGrid",
  "AI-flex-container"
);
