import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import { Observer } from "mobx-react";
import Form from "../../Forms";
import { commentFormSchema } from "../../Forms/CommentForm/CommentForm";
import { useState, useEffect, useMemo } from "react";
import { BaseDialogProps } from "../types/types";
import { dialogsViewerStore } from "../stores/DialogsViewerStore";
import {
  Comment,
  NewComment,
  CommentSource,
  CommentLevel,
} from "../../../types/Comment";
import { commentsApiClient } from "../../../lib/apiClients/comments/commentsApiClient";
import { CommentCard } from "./CommentCard";
import { getRandomColor } from "../../../utils/miscellaneous";
import { userStore } from "../../../stores/UserStore";
import { Loading } from "../../Loading/Loading";
import { shortCommentFormSchema } from "../../Forms/CommentForm/ShortCommentForm";
import { defaultNewComment } from "../../../stores/CommentStore";

export interface CommentsDialogProps extends BaseDialogProps {
  isSrcApplication?: boolean;
  applicationId?: string;
  applicationFileNumber?: string;
  sourceId?: string;
  sourceName?: string;
  onSuccess?: (comment: Comment) => void;
}

export const CommentsDialog: React.FC<CommentsDialogProps> = ({
  isOpen,
  closeDialog,
  isSrcApplication,
  applicationId,
  applicationFileNumber,
  sourceId,
  sourceName,
}) => {
  const [ready, setReady] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentFormValue, setCommentFormValue] = useState<Partial<NewComment>>(
    defaultNewComment as NewComment
  );

  const loadSourceComments = () => {
    if (!!sourceId) {
      commentsApiClient
        .getSourceComments(sourceId)
        .then((items) => {
          setComments(items);
        })
        .finally(() => setReady(true));
    }
  };

  const loadApplicationComments = () => {
    if (!!applicationId) {
      commentsApiClient
        .getApplicationComments(applicationId)
        .then((items) => {
          setComments(items);
        })
        .finally(() => setReady(true));
    }
  };

  const loadComments = () => {
    setReady(false);

    if (!!isSrcApplication) {
      loadApplicationComments();
    } else {
      loadSourceComments();
    }
  };

  useEffect(() => {
    setCommentFormValue(defaultNewComment);
    if (!!dialogsViewerStore.commentsDialogOpenOptions) {
      loadComments();
    }
  }, [dialogsViewerStore.commentsDialogOpenOptions]);

  const randomColor = useMemo(() => getRandomColor(), []);

  const commenterInitials: any = {};

  const getCommenter = (comment: Comment) => {
    if (!commenterInitials[comment.createdBy]) {
      const initials = comment.createdBy_AsName
        .split(" ")
        .map((n) => n[0])
        .join("");

      commenterInitials[comment.createdBy] = {
        initials: initials,
        badgeColor: randomColor,
      };
    }

    return commenterInitials[comment.createdBy];
  };

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Comments"}
      onClose={closeDialog}
    >
      <div className={`${Classes.DIALOG_BODY}__comments`}>
        <Observer>
          {() => (
            <div className="comment-container">
              <section>
                {ready &&
                  comments
                    .sort(
                      (a, b) =>
                        Number(b.isPinned) - Number(a.isPinned) ||
                        +new Date(a.pinnedDate) - +new Date(b.pinnedDate) ||
                        +new Date(b.createdAt).getTime() -
                          +new Date(a.createdAt).getTime()
                    )
                    .map((comment, index) => {
                      const commenter = getCommenter(comment);
                      return (
                        <CommentCard
                          key={index}
                          comment={comment}
                          commenter={commenter}
                          loadComments={loadComments}
                          isComponentEnabled={
                            true && !userStore.user?.isExternal
                          }
                          canDeleteAllComment={
                            !!userStore.user?.canDeleteComment
                          }
                          canEditAllComment={
                            !!userStore.user?.canEditAllComment
                          }
                          canEditSelfComment={
                            !!userStore.user?.canEditSelfComment
                          }
                          canDeleteSelfComment={
                            !!userStore.user?.canDeleteSelfComment
                          }
                          canPinComment={!userStore.user?.isExternal}
                        />
                      );
                    })}
              </section>
            </div>
          )}
        </Observer>
      </div>
      <div className={`${Classes.DIALOG_FOOTER}__comments`}>
        <section>
          {ready && (
            <Form<Partial<NewComment>>
              formId="comment-form"
              formSchemaBuilder={
                !!isSrcApplication ? commentFormSchema : shortCommentFormSchema
              }
              value={commentFormValue}
              onFormSubmit={async (comment: Partial<NewComment>) => {
                setIsSaving(true);
                try {
                  comment.applicationId = applicationId;
                  comment.application_FileNumber = applicationFileNumber;
                  comment.sourceId = sourceId;
                  if (!isSrcApplication) {
                    comment.level = CommentLevel.Internal;
                    comment.source = CommentSource.Document;
                    comment.sourceName = sourceName;
                  }
                  if (!!comment.isPinned) {
                    comment.pinnedDate = new Date();
                  }
                  const newComment = await commentsApiClient.createComment({
                    ...comment,
                  });
                  loadComments();

                  if (
                    !!dialogsViewerStore.commentsDialogOpenOptions?.onSubmit
                  ) {
                    dialogsViewerStore.commentsDialogOpenOptions?.onSubmit();
                  }

                  setCommentFormValue({
                    message: "",
                    level: newComment.level,
                    source: newComment.source,
                    isPinned: newComment.isPinned,
                    pinnedDate: undefined,
                  });
                } finally {
                  setIsSaving(false);
                }
              }}
            />
          )}
        </section>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="comment-button"
          isSaving={isSaving}
          formId="comment-form"
          text="Save"
        />
      </div>
      {!ready && <Loading />}
    </Dialog>
  );
};
