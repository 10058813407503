import { Elevation } from "@blueprintjs/core";
import { FC, useState, useEffect } from "react";
import { Application } from "../../../../../../types/Application";
import { Income } from "../../../../../../types/Application/Income";
import { DeleteButton } from "../../../../../Buttons/DeleteButton";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../../../Forms";
import {
  incomeFormSchema,
  newIncome,
} from "../../../../../Forms/ApplicationForm/Income";
import { showSuccessfulSaveAppToaster } from "../../../../../Toast/Toast";
import { SaveButton } from "../../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../../Buttons/UndoButton";
import { incomeOwnedEntityStore } from "../../../../../../stores/OwnedEntityStores/IncomeOwnedEntityStore";
import { FIARequest } from "../../../../../FIARequests/configurations/types";
import { fiaRequestApiClient } from "../../../../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { OwnedEntityFIARequestSubCard } from "../../../../../FIARequests/OwnedEntityFIARequestsSubCard";

export interface IncomePageCardProps {
  income: Income;
  localIncomes: Income[];
  setLocalIncomes: (incomes: Income[]) => void;
  application?: Application;
  canCreate: boolean;
  canEdit: boolean;
  createAndUpdateIncomes: () => Promise<void>;
  deleteIncome: (income: Income) => Promise<void>;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  index: number;
  isAddingAction: boolean;
  title: string;
  applicationId?: string;
}

const newIncomeId = "new-income";
const baseFormId = "income-form";
const sectionHeader = "Additional Income";

export const IncomePageCard: FC<IncomePageCardProps> = (props) => {
  const [isInDeleteAction, setIsInDeleteAction] = useState<boolean>(false);
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );
  const isNewIncome = props.income.id === newIncomeId;

  const handleReset = async (incomeToReset: Income) => {
    const existingLocalIncomes = [...props.localIncomes];

    existingLocalIncomes[props.index] = { ...props.income, ...incomeToReset };

    props.setLocalIncomes(existingLocalIncomes);
  };

  useEffect(() => {
    if (!!props.applicationId && !isNewIncome) {
      fiaRequestApiClient
        .getMostRecentRequestByOwnedEntityId(
          props.applicationId,
          props.income.id
        )
        .then((res) => setFiaRequest(res));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [props.income.id]);

  return (
    <RelativityCard
      key={props.income.id}
      elevation={Elevation.TWO}
      headerContent={
        <div className="owned-entity-header income-card-header">
          <SectionHeader
            label={`${sectionHeader} ${props.index + 1}`}
            type="Section"
            inAction={props.formIsSaving}
          />
          <div>
            {props.canEdit && (
              <>
                <>
                  <SaveButton
                    formId="income-information-form"
                    isSaving={props.formIsSaving}
                    disabled={props.shouldDisableForm || isInDeleteAction}
                    hidden={isInDeleteAction}
                  />
                  <UndoButton
                    isSaving={props.formIsSaving || isInDeleteAction}
                    disabled={props.shouldDisableForm}
                    onClick={async () => {
                      //Need to Await Changes in states in order to update correctly
                      await handleReset(props.income);
                    }}
                  />
                  <DeleteButton
                    isDeleting={isInDeleteAction}
                    disabled={props.formIsSaving || props.shouldDisableForm}
                    hidden={props.formIsSaving}
                    onClick={async () => {
                      props.setShouldDisableForm(true);
                      setIsInDeleteAction(true);
                      if (isNewIncome) {
                        props.setLocalIncomes(
                          props.localIncomes.filter(
                            (income) => income.id !== newIncomeId
                          )
                        );
                        incomeOwnedEntityStore.removeLocalIncome(props.income);
                      } else {
                        await props.deleteIncome(props.income);
                      }

                      setIsInDeleteAction(false);
                      props.setShouldDisableForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </div>
      }
    >
      <Form<Income>
        formId={`${baseFormId}-${props.income.id}`}
        shouldBlockNavigation={true}
        value={props.income}
        shouldResetForm={true}
        formSchemaBuilder={incomeFormSchema}
        disableAllFields={
          !props.canEdit ||
          props.isAddingAction ||
          isInDeleteAction ||
          props.shouldDisableForm ||
          props.formIsSaving
        }
        shouldSaveEntiityToLocalStore={true}
        ownedEntityStoreAction={(updatedIncome) => {
          if (JSON.stringify(updatedIncome) !== JSON.stringify(newIncome)) {
            incomeOwnedEntityStore.addLocalIncome(updatedIncome);
          }
        }}
        onFormSubmit={async () => {
          props.setShouldDisableForm(true);
          props.setFormIsSaving(true);

          await props.createAndUpdateIncomes().then(() => {
            showSuccessfulSaveAppToaster(props.title);
          });

          props.setFormIsSaving(false);
          props.setShouldDisableForm(false);
        }}
      />
      {fiaRequest && <OwnedEntityFIARequestSubCard fiaRequest={fiaRequest} />}
    </RelativityCard>
  );
};
