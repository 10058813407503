import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { ExistingMonitor } from "../../../types/Monitor";
import { dateBuilderAddYearsToTodaysDate } from "../../../utils/date";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const endMonitorFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "applicationMonitor";
  const monitor = form as ExistingMonitor;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "endDate"),
      value: monitor.endDate,
      onSubmit: (endDate) => {
        onValueChange({ endDate });
      },
      errorMessages: get(errors, "endDate"),
      isRequired: true,
      maxDate: dateBuilderAddYearsToTodaysDate(1),
      minDate: monitor.startDate
        ? new Date(monitor.startDate)
        : dateBuilderAddYearsToTodaysDate(-1),
    } as AllFields,
  ];
};
