import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { Application } from "../../../../types/Application";
import { PrimaryInformation } from "../../../../types/Application/PrimaryInformation";
import RelativityCard from "../../../Card/RelativityCard";
import Form from "../../../Forms";
import { newPrimaryInformation } from "../../../Forms/ApplicationForm/PrimaryInformation";
import { fileInformationFormSchema } from "../../../Forms/ApplicationPage/FileInformationFormSchema";
import { AppToaster } from "../../../Toast/Toast";

export interface FileInformationPageCardProps {
  application?: Application;
  shouldBlockNavigation?: boolean;
  submitForm: (application: PrimaryInformation) => Promise<void>;
  canEdit: boolean;
}

export const FileInformationPageCard: FC<FileInformationPageCardProps> = ({
  application,
  shouldBlockNavigation,
  submitForm,
  canEdit,
}) => {
  const fileInformation = application as PrimaryInformation;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<PrimaryInformation>(
    fileInformation || newPrimaryInformation
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as PrimaryInformation);
  };

  return (
    <>
      <RelativityCard className="primary-information-card">
        <Observer>
          {() => (
            <>
              <div className="page-card-header">
                {canEdit && (
                  <>
                    <div className="page-card-header-buttons">
                      <Button
                        type="submit"
                        form="primary-information-form"
                        minimal
                        loading={isSaving}
                        disabled={isSaving}
                        intent={Intent.PRIMARY}
                        icon={IconNames.FLOPPY_DISK}
                      />
                      <Button
                        minimal
                        intent={Intent.DANGER}
                        icon={IconNames.Undo}
                        hidden={isSaving}
                        onClick={async () => {
                          setShouldResetForm(true);
                          await handleReset();
                          setShouldResetForm(false);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <Form<PrimaryInformation>
                formId="primary-information-form"
                shouldBlockNavigation={shouldBlockNavigation}
                value={activeFormValue}
                disableAllFields={isSaving || !canEdit}
                shouldResetForm={shouldResetForm}
                formSchemaBuilder={fileInformationFormSchema}
                onFormSubmit={async (application) => {
                  setIsSaving(true);
                  await submitForm(application)
                    .then(() => {
                      AppToaster.show({
                        message: (
                          <div>
                            <h3>Success</h3>Application File Information Saved
                          </div>
                        ),
                        intent: Intent.SUCCESS,
                      });
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }}
              />
            </>
          )}
        </Observer>
      </RelativityCard>
    </>
  );
};
