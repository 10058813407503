import { matchupApiClient } from "../../../../lib/apiClients/matchup/matchupApiClient";
import { UserRoles } from "../../../../types/User";
import GridColumn from "../../../Grid/Grid/types/GridColumn";
import GridDropdownColumn from "../../../Grid/Grid/types/GridDropdownColumn";
import GridMaskedSSNColumn from "../../../Grid/Grid/types/GridMaskedSSNColumn";

export const gridColumnsConfiguration: GridColumn[] = [
  new GridMaskedSSNColumn({
    field: "socialSecurityNumber",
    headerText: "SSN",
    type: "string",
  }),
  new GridColumn({
    field: "facility_FacilityName",
    headerText: "Facility Name",
    type: "string",
  }),
  new GridColumn({
    field: "firstName",
    headerText: "First Name",
    type: "string",
  }),
  new GridColumn({
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "fieldRepFullName",
    headerText: "Field Rep",
    type: "string",
  }),
  new GridColumn({
    field: "productType_AsString",
    headerText: "Product Type",
    type: "string",
  }),
  new GridColumn({
    field: "nextRecertDate",
    headerText: "Recert Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridDropdownColumn({
    field: "recertRequired",
    headerText: "Recert Required",
    type: "boolean",
    fieldValueParser: (value) =>
      value === undefined ? undefined : Number(value),
    optionValues: [
      { label: "Select...", value: undefined },
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    onSubmit: async (id, value) => {
      value = value as unknown as 1 | 0 | undefined;
      const parsedValue = value === undefined ? undefined : Boolean(value);
      await matchupApiClient.updateRecertRequired(id, parsedValue);
    },
  }),
  new GridDropdownColumn({
    field: "hasFaceSheet",
    headerText: "Has Facesheet",
    type: "boolean",
    fieldValueParser: (value) =>
      value === undefined ? undefined : Number(value),
    optionValues: [
      { label: "Select...", value: undefined },
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    onSubmit: async (id, value) => {
      value = value as unknown as 1 | 0 | undefined;
      const parsedValue = value === undefined ? undefined : Boolean(value);
      await matchupApiClient.updateHasFaceSheet(id, parsedValue);
    },
  }),
  new GridDropdownColumn({
    field: "hasARForm",
    headerText: "Has AR Form",
    type: "boolean",
    fieldValueParser: (value) =>
      value === undefined ? undefined : Number(value),
    optionValues: [
      { label: "Select...", value: undefined },
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    onSubmit: async (id, value) => {
      value = value as unknown as 1 | 0 | undefined;
      const parsedValue = value === undefined ? undefined : Boolean(value);
      await matchupApiClient.updateHasARForm(id, parsedValue);
    },
  }),
  new GridColumn({
    field: "facility_State_AsString",
    headerText: "Facility State",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "recertRequired",
  "facility_FacilityName",
  "facility_State_AsString",
  "firstName",
  "lastName",
  "fieldRepFullName",
  "productType_AsString",
  "nextRecertDate",
  "hasFaceSheet",
  "hasARForm",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
