import { FormGroup, Icon, Intent } from "@blueprintjs/core";
import { DateInput2 } from "@blueprintjs/datetime2";
import { IconNames } from "@blueprintjs/icons";
import { format } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { isValidDate, parseDateToDateString } from "../../../utils/date";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { DateFieldProps } from "../types/fieldTypes";
import { AppToaster } from "../../Toast/Toast";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";

export const DateField: React.FC<DateFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  minDate,
  maxDate,
  useTimePrecision,
}) => {
  if (hidden) {
    return null;
  }
  const dateFnsFormat = "MM/dd/yyyy";
  const dateTimeFnsFormat = "MM/dd/yyyy h:mm a";

  const formatDate = useCallback(
    (date: Date) => format(date, dateFnsFormat),
    []
  );

  const formatDateTime = useCallback(
    (date: Date) => format(date, dateTimeFnsFormat),
    []
  );

  const parseDateString = (dateString: string) => {
    let convertedString = dateString;

    if (!dateString.includes("/") && dateString.length >= 8) {
      convertedString = `${dateString.slice(0, 2)}/
      ${dateString.slice(2, 4)}/
      ${dateString.slice(4, 8)}`;
    }
    return new Date(convertedString);
  };

  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const isTooltipVisible = useOverflowTooltip(dateInputRef.current, value);

  // Initialize
  useEffect(() => {
    if (!!dateInputRef.current && !initialized) {
      setInitialized(true);
    }
  }, []);

  const displayInvalidDateToast = () => {
    AppToaster.show({
      message: (
        <div>
          <h3>Invalid Date</h3>
          Please enter a valid date
        </div>
      ),
      intent: Intent.DANGER,
    });
  };

  return (
    <FormGroup
      className={`${
        isRequired && !value ? "has-required-background" : "base-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <WithTooltip
        shouldShowTooltip={isTooltipVisible}
        content={
          !!value && isTooltipVisible ? parseDateToDateString(value) : ""
        }
      >
        <DateInput2
          inputProps={{ inputRef: dateInputRef }}
          timePrecision={!!useTimePrecision ? "minute" : undefined}
          timePickerProps={!!useTimePrecision ? { useAmPm: true } : undefined}
          defaultTimezone={!!useTimePrecision ? "America/New_York" : undefined}
          showTimezoneSelect={false}
          value={value}
          showActionsBar={true}
          placeholder="MM/DD/YYYY"
          closeOnSelection={!!useTimePrecision ? false : true}
          formatDate={!!useTimePrecision ? formatDateTime : formatDate}
          parseDate={(input) => parseDateString(input)}
          onChange={(date) => {
            const dateString = !date ? "" : date;
            if (isValidDate(dateString)) {
              onSubmit(dateString);
            } else {
              displayInvalidDateToast();
            }
          }}
          disabled={disabled}
          minDate={minDate || new Date("1900-01-01")}
          maxDate={maxDate || new Date("2030-01-01")}
          rightElement={
            <Icon className="date-right-icon" icon={IconNames.Calendar} />
          }
        />
      </WithTooltip>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
