import {
  AutoCompleteComponent,
  AutoCompleteModel,
} from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import variables from "../../config/variables";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ChecklistDocumentOdataReadDTO } from "../TemplateGrid/configurations/types";
import "./styles.scss";
import { checklistDocumentPickerStore } from "./stores/ChecklistDocumentPickerStore";

export interface ChecklistDocumentPickerProps {
  onDocumentPicked: (
    templateODataReadDTO?: ChecklistDocumentOdataReadDTO
  ) => void;
  odataExcludeIds?: string[];
  applicationId: string;
}
export const ChecklistDocumentPicker: React.FC<ChecklistDocumentPickerProps> = (
  props
) => {
  const autoCompleteRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);
  const [autoCompleteSettings, setAutoCompleteSettings] = React.useState<
    AutoCompleteModel & DefaultHtmlAttributes
  >();

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      checklistDocumentPickerStore.reset();
    };
  }, []);

  const setup = async () => {
    const autoCompleteModel = await checklistDocumentPickerStore.setup({
      odataUrl: `${variables.apiBaseUrl}odata/checklistitemdocumentodata/options`,
      getAccessToken,
      onTemplatePicked: props.onDocumentPicked,
      odataExcludeIds: props.odataExcludeIds,
      applicationId: props.applicationId,
    });

    setAutoCompleteSettings(autoCompleteModel);
    setReady(true);
  };

  React.useEffect(() => {
    if (autoCompleteRef.current) {
      checklistDocumentPickerStore.initialize(autoCompleteRef.current);
    }
  }, [ready, autoCompleteRef]);

  return (
    <div className="template-picker-toolbar">
      {ready && (
        <div className="template-picker-input-container">
          <AutoCompleteComponent
            ref={autoCompleteRef}
            id="template-picker-search-list"
            className="template-picker-input"
            {...autoCompleteSettings}
            fields={{
              value: "label",
              text: "label",
              groupBy: "fileNumber",
            }}
            popupWidth={650}
          />
          <Icon icon={IconNames.Search} size={14} />
        </div>
      )}
    </div>
  );
};
