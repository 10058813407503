import { Button, Intent } from "@blueprintjs/core";
import Footer from "../Footer/Footer";
import classNames from "classnames";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import Form from "../Forms";
import { useActionExecuting } from "../ActtionExecuting/useActionExecuting";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import RelativityCard from "../Card/RelativityCard";
import { createReportFormSchema } from "../Forms/ReportForm/ReportForm";
import "./styles.scss";
import { Report, ReportCreateDTO } from "./types";
import { useReport } from "./useReport";
import { Loading } from "../Loading/Loading";

export interface ReportCreateProps {
  onAction?: (refresh: boolean) => void;
}

export const ReportCreate: React.FC<ReportCreateProps> = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [blankTopic, setBlankReport] = useState<Report>();
  const { getBlankReport, createReport } = useReport();
  const { isActionExecuting } = useActionExecuting([isSaving]);

  useEffect(() => {
    setBlankReport(getBlankReport());
  }, []);

  const actionHandler = (refresh: boolean) => {
    props.onAction && props.onAction(refresh);
  };

  const create = async (reportCreateDTO: ReportCreateDTO) => {
    setIsSaving(true);

    const createdReport = await createReport(reportCreateDTO).finally(() => {
      setIsSaving(false);
    });

    if (!createdReport) {
      return;
    }

    // Call Action
    actionHandler(true);
  };

  return (
    <section className="report-create">
      {!blankTopic && <Loading />}
      {blankTopic && (
        <>
          <RelativityCard>
            <Observer>
              {() => (
                <div className="report-create-form-container">
                  <Form<ReportCreateDTO>
                    formId="report-create-form"
                    disableAllFields={isActionExecuting}
                    value={blankTopic}
                    formSchemaBuilder={createReportFormSchema}
                    onFormSubmit={async (reportCreateDTO) => {
                      await create(reportCreateDTO);
                    }}
                  />
                </div>
              )}
            </Observer>
          </RelativityCard>
          <Footer className={classNames("report-create-actions-container")}>
            <Button
              minimal
              intent={Intent.DANGER}
              disabled={isActionExecuting}
              text="Cancel"
              onClick={() => actionHandler(false)}
            />
            <FormSubmitButton
              buttonId="report-create-form-save-action"
              disabled={isActionExecuting}
              isSaving={isSaving}
              formId="report-create-form"
              text="Create"
            />
          </Footer>
        </>
      )}
    </section>
  );
};
