import { Classes, FormGroup, NumericInput } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { NumberFieldProps } from "../types/fieldTypes";
import "./styles.scss";

export const NumberField: React.FC<NumberFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  maxValue,
  minValue,
}) => {
  if (hidden) {
    return null;
  }

  const [localValue, setLocalValue] = useState(value || "");

  useEffect(() => {
    onSubmit(localValue);
  }, [localValue]);

  return (
    <FormGroup
      className={`${
        isRequired && !value
          ? "has-required-background numeric-input-field"
          : "base-field numeric-input-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <NumericInput
        max={maxValue}
        min={minValue}
        disabled={disabled}
        name={label as string}
        className={Classes.FOCUS_DISABLED}
        defaultValue={localValue}
        onValueChange={(_valueAsNumber, valueAsString) => {
          setLocalValue(valueAsString);
        }}
      />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
