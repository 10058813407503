import { Button, Intent, Spinner } from "@blueprintjs/core";
import { useState } from "react";
import { useNavigate } from "react-router";
import { dialogsViewerStore } from "../../components/Dialogs/stores/DialogsViewerStore";
import { AppToaster } from "../../components/Toast/Toast";
import { WithTooltip } from "../../components/Tooltip/Tooltip";
import { applicationStore } from "../../stores/ApplicationStore";
import { ApplicationStatuses } from "../../types/Application";
import { permissionStore } from "../../stores/PermissionStore";
import "./styles.scss";
import { checklistItemStore } from "../../stores/ChecklistItemStore";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { downloadFile } from "../../components/Document/PdfDocuments";

export interface ApplicationActionsProps {
  applicationId?: string;
  isWithdrawn?: boolean;
  canSubmitApplication: boolean;
  canWithdrawApplicationForm: boolean;
  canApplicationSubmitToState: boolean;
  canSubmitApplicationToState: boolean;
  hasApplicationStateSubmitted: boolean;
  canGenerateApplicationDocPackage: boolean;
}

export const ApplicationActions: React.FC<ApplicationActionsProps> = ({
  applicationId,
  isWithdrawn,
  canSubmitApplication,
  canWithdrawApplicationForm,
  canApplicationSubmitToState,
  canSubmitApplicationToState,
  hasApplicationStateSubmitted,
  canGenerateApplicationDocPackage,
}) => {
  const navigate = useNavigate();

  const [isReactivating, setIsReactivating] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isGeneratingDocs, setIsGeneratingDocs] = useState<boolean>(false);
  return (
    <div className="application-actions">
      {!!applicationId && canGenerateApplicationDocPackage && (
        <Button
          minimal
          intent={Intent.PRIMARY}
          loading={isGeneratingDocs}
          disabled={
            isGeneratingDocs ||
            !(applicationStore.application?.progress === 100)
          }
          onClick={async () => {
            try {
              setIsGeneratingDocs(true);
              const blob = (await applicationApiClient.getStateDocumentPackage(
                applicationId,
                false
              )) as BlobPart;
              downloadFile(blob, "state_document_package.pdf");
            } catch (e) {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Error</h3>Doc Generation Failed
                  </div>
                ),
                intent: Intent.DANGER,
              });
            } finally {
              checklistItemStore.setChecklistItems(true);
              setIsGeneratingDocs(false);
            }
          }}
        >
          Generate Docs
        </Button>
      )}
      {!!canApplicationSubmitToState && canSubmitApplicationToState && (
        <WithTooltip
          shouldShowTooltip={!permissionStore.canSubmitToState}
          content={
            "Please complete all required fields prior to applying to state"
          }
        >
          <Button
            minimal
            intent={Intent.PRIMARY}
            disabled={!permissionStore.canSubmitToState}
            onClick={async () => {
              dialogsViewerStore.setIsApplyToStateDialogOpen(true);
            }}
          >
            {`${hasApplicationStateSubmitted ? "Re-Apply" : "Apply"} to State`}
          </Button>
        </WithTooltip>
      )}
      {isWithdrawn === true && canWithdrawApplicationForm && (
        <Button
          minimal={false}
          intent={Intent.SUCCESS}
          onClick={async () => {
            setIsReactivating(true);
            await applicationStore.reactivateApplication();
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Application reactivated.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            setIsReactivating(false);
          }}
        >
          {isReactivating ? <Spinner size={18.391} /> : "Reactivate"}
        </Button>
      )}
      {isWithdrawn === false && canWithdrawApplicationForm && (
        <Button
          minimal={true}
          intent={Intent.DANGER}
          onClick={() => {
            dialogsViewerStore.setIsWithdrawApplicationDialogOpen(true);
          }}
        >
          Withdraw
        </Button>
      )}
      {
        <WithTooltip
          shouldShowTooltip={!canSubmitApplication}
          content="Please complete all required fields prior to submitting to the processing team."
          placement="auto"
        >
          <Button
            minimal={!canSubmitApplication}
            intent={Intent.SUCCESS}
            disabled={!canSubmitApplication}
            onClick={async () => {
              if (!!applicationStore.financialEligibilityConversationRequired) {
                dialogsViewerStore.setFinancialEligibilityConversationDialogOpen(
                  true,
                  {
                    financialEligibilityConversation: {
                      financialEligibilityConversationConfirmation:
                        applicationStore.application
                          ?.financialEligibilityConversationConfirmation ||
                        false,
                    },
                    medicaidEligibilityDecisionType_AsString:
                      applicationStore.application?.medicaidEligibilityDecision
                        ?.decisionType_AsString || "",
                  }
                );
              } else {
                setIsSubmitting(true);
                await applicationStore.updateApplicationStatus(
                  ApplicationStatuses.inScheduling
                );
                setIsSubmitting(false);
                navigate(`/application-page/${applicationId}`);
              }
            }}
          >
            {isSubmitting ? <Spinner size={18.391} /> : "Submit"}
          </Button>
        </WithTooltip>
      }
    </div>
  );
};
