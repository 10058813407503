import { useEffect, useRef, useState } from "react";
import {
  ChatConversation,
  ChatConversationMessageType,
  ChatMessage,
  ConversationRoleTypes,
  ConversationTopicTypes,
} from "./types";
import classNames from "classnames";
import { isUndefined, last, map } from "lodash";
import { chatBotApiClient } from "../../lib/apiClients/chatBot/chatBotApiClient";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { AppToaster } from "../Toast/Toast";
import { Button, Classes, Intent } from "@blueprintjs/core";
import {
  ChatUserConversationMessageMetadata,
  CommonMessageMetadata,
  ChatAssistantConversationMessageMetadata,
  HelpCenterTopicState,
  TopicSectionTypes,
  topicSectionTypeConfig,
} from "../HelpCenter/types";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import classnames from "classnames";
import { helpCenterTopicApiClient } from "../../lib/apiClients/helpCenterTopic/helpCenterTopicApiClient";

export interface ChatBot {
  getOpenConversation: () => Promise<ChatConversation>;
  isLoadingConversation: boolean;
  conversation: ChatConversation | undefined;
  startConversation: () => void;
  endConversation: (wasResolved: boolean) => void;
  newPrompt: string | undefined;
  setNewPrompt: React.Dispatch<React.SetStateAction<string | undefined>>;
  isAskingAssistant: boolean;
  chatTemplate: (data: any) => JSX.Element;
  mapMessagesToObject: () => any;
  setConversationTopicType: (topic: ConversationTopicTypes) => void;
  setConversationUsState: (state: number) => void;
  topicType: ConversationTopicTypes | undefined;
  usState: number | undefined;
  usStateAsString: string | undefined;
  usStateHasStatePolicy: boolean;
  availableUsStates: HelpCenterTopicState[] | undefined;
  isMDRQuestion?: boolean;
  isUsStateQuestion?: boolean;
  haveUsStateQuestionState?: boolean;
  searchStatePolicyTopics: boolean;
  searchSameQuestionOnStatePolicyTopics: () => void;
  lastMessageWasSearchStatePolicyTopics: boolean;
  reset: () => void;
}

export const useChatbot = (closeConversationHandler: () => void): ChatBot => {
  const [conversation, setConversation] = useState<ChatConversation>();
  const [newPrompt, setNewPrompt] = useState<string>();
  const [previousPrompt, setPreviousPrompt] = useState<string>();
  const [isAskingAssistant, setIsAskingAssistant] = useState<boolean>(false);
  const [topicType, setTopicType] = useState<ConversationTopicTypes>();
  const [usState, setUsState] = useState<number>();
  const [usStateAsString, setUsStateAsString] = useState<string>();
  const [usStateHasStatePolicy, setUSStateHasStatePolicy] =
    useState<boolean>(false);
  const [availableUsStates, setAvailableUsStates] =
    useState<HelpCenterTopicState[]>();
  const [searchStatePolicyTopics, setSearchStatePolicyTopics] =
    useState<boolean>(false);

  const [isLoadingConversation, setIsLoadingConversation] =
    useState<boolean>(false);

  const closeConversationHandlerRef = useRef(closeConversationHandler);

  useEffect(() => {
    if (newPrompt) {
      promptAssistant(newPrompt);
      setPreviousPrompt(newPrompt);
    }
  }, [newPrompt]);

  // Computed
  const isMDRQuestion =
    topicType && topicType === ConversationTopicTypes.MDRQuestion;
  const isUsStateQuestion =
    topicType && topicType === ConversationTopicTypes.StateQuestion;
  const haveUsStateQuestionState =
    topicType &&
    topicType === ConversationTopicTypes.StateQuestion &&
    !isUndefined(usState);
  const lastMessageWasSearchStatePolicyTopics = (
    last(
      conversation?.messages.filter(
        (x) => x.role === ConversationRoleTypes.Assistant
      )
    )?.messageMetadataObj as CommonMessageMetadata
  )?.isSearchStatePolicyTopics;

  // Methods
  const searchSameQuestionOnStatePolicyTopics = () => {
    setSearchStatePolicyTopics(true);
    setNewPrompt(previousPrompt);
  };

  const setConversationTopicType = async (topic: ConversationTopicTypes) => {
    setTopicType(topic);
  };

  const setConversationUsState = async (
    state?: number,
    usStatesList?: HelpCenterTopicState[]
  ): Promise<boolean> => {
    setUsState(state);
    if (!isUndefined(state)) {
      const stateStr = (usStatesList || availableUsStates)?.find(
        (s) => s.usState === state
      )?.usState_AsString;

      if (!stateStr) {
        console.warn(
          "Could not find us state as string. Us State seems to not be longer available for the previous conversation."
        );
        return false;
      }

      const hasStatePolicy = (usStatesList || availableUsStates)?.find(
        (s) => s.usState === state
      )?.hasStatePolicy;

      if (isUndefined(hasStatePolicy)) {
        console.warn(
          "Could not find hasStatePolicy. Us State seems to not be longer available for the previous conversation."
        );
      }

      setUsStateAsString(stateStr);
      setUSStateHasStatePolicy(hasStatePolicy || false);
    }
    return true;
  };

  const promptAssistant = async (userPrompt: string) => {
    setIsAskingAssistant(true);

    let openConversation = await chatBotApiClient
      .promptAssistant(userPrompt, usState, searchStatePolicyTopics)
      .catch(() => {
        AppToaster.show({
          message: (
            <div>
              Could not provide an answer at this time. Please try again later.
            </div>
          ),
          intent: Intent.WARNING,
        });
        closeConversation();
      })
      .finally(() => {
        setIsAskingAssistant(false);
        setSearchStatePolicyTopics(false);
      });

    if (!openConversation) {
      return;
    }

    // Set Values
    setNewPrompt(undefined);

    openConversation = processConversation(openConversation);
    setConversation(openConversation);
  };

  const getOpenConversation = async () => {
    const openConversation = await chatBotApiClient.getOpenConversation();
    return openConversation;
  };

  const startConversation = async () => {
    // Validate
    if (conversation) {
      return;
    }

    // Setup
    reset();
    setIsLoadingConversation(true);

    // Fetch Again
    await Promise.all([
      getOpenConversation(),
      helpCenterTopicApiClient.getHelpCenterTopicStatesByType(
        TopicSectionTypes.STR,
        true
      ),
    ])
      .then(async ([conv, fetchedTopicStates]) => {
        setAvailableUsStates(fetchedTopicStates);

        if (conv) {
          setConversationTopicType(
            conv.usState
              ? ConversationTopicTypes.StateQuestion
              : ConversationTopicTypes.MDRQuestion
          );
          const result = await setConversationUsState(
            conv.usState,
            fetchedTopicStates
          );
          if (!result) {
            await endConversation(false, conv);
          } else {
            conv = processConversation(conv);
            setPreviousPrompt(
              last(
                conv.messages.filter(
                  (x) => x.role === ConversationRoleTypes.User
                )
              )?.message
            );
            setConversation(conv || undefined);
          }
        }
      })
      .catch(() => {
        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
        closeConversation();
      })
      .finally(() => {
        setIsLoadingConversation(false);
      });
  };

  const processConversation = (conv: ChatConversation) => {
    // Process messages
    conv.messages = conv.messages.map((message) => {
      // Convert metadata to object
      let metadata = undefined;
      if (typeof message.messageMetadata === "string") {
        try {
          if (message.role === ConversationRoleTypes.Assistant) {
            metadata = JSON.parse(
              message.messageMetadata
            ) as ChatAssistantConversationMessageMetadata;
          } else {
            metadata = JSON.parse(
              message.messageMetadata
            ) as ChatUserConversationMessageMetadata;
          }
        } catch (error) {
          metadata = undefined;
        }
      }

      // Convert each ChatMessage to an object with key-value pairs
      return {
        ...message,
        messageMetadataObj: metadata,
      };
    });

    return conv;
  };

  const closeConversation = () => {
    reset();
    closeConversationHandlerRef.current();
  };

  const endConversation = async (
    wasResolved: boolean,
    conv?: ChatConversation
  ) => {
    if (!(conv || conversation)) {
      closeConversation();
      return;
    }

    await chatBotApiClient
      .complete(wasResolved)
      .catch(() => {
        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        reset();
      });
  };

  const reset = () => {
    setConversation(undefined);
    setIsAskingAssistant(false);
    setNewPrompt(undefined);
    setTopicType(undefined);
    setUsState(undefined);
  };

  const mapMessagesToObject = () => {
    const object = map(conversation?.messages || [], (message) => {
      const {
        id,
        pairId,
        message: msg,
        messageMetadataObj,
        role,
        messageType,
        timestamp,
      } = message;

      // Convert each ChatMessage to an object with key-value pairs
      return {
        id: id,
        pairId: pairId,
        message: msg,
        messageMetadataObj: messageMetadataObj,
        role: role,
        messageType: messageType,
        timestamp: timestamp,
      };
    });

    return object;
  };

  const isBase64 = (message: string) => {
    if (message === "" || message.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(message)) == message;
    } catch (err) {
      return false;
    }
  };

  const renderUrlsAsLinks = (text: string): string => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with HTML <a> tags
    const replacedText = text.replace(urlRegex, (url: string) => {
      return `<a href="${url}" target="_blank">[Click Here]</a>`;
    });

    return replacedText;
  };

  const chatTemplate = (message: ChatMessage): JSX.Element => {
    // Detect Base64 and Replace "\n" characters with "<br>" tags
    let formattedContent = isBase64(message.message)
      ? window.atob(message.message)
      : message.message;
    formattedContent = formattedContent.replace(/\n/g, "<br>");
    formattedContent = renderUrlsAsLinks(formattedContent);

    const isAnswered =
      message.messageType === ChatConversationMessageType.AssistantAnswer ||
      message.messageType ===
        ChatConversationMessageType.AssistantStatePolicyMessage;

    if (message.role === ConversationRoleTypes.Assistant && !isAnswered) {
      // Add Support Link to formatted content
      formattedContent += ` <a href="/helpcenter/submit-support-request?chatMinimized=true" target="_blank">Submit Support Request.</a>`;
    }

    const msgMetadata = message.messageMetadataObj;
    let isSearchStatePolicyTopics = false;
    if (msgMetadata) {
      isSearchStatePolicyTopics = (msgMetadata as CommonMessageMetadata)
        ?.isSearchStatePolicyTopics;
    }

    const template = (
      <div
        className={classNames(`chat-message chat-message-${message.role}`, {
          "chat-message-search-state-policy-topics": isSearchStatePolicyTopics,
        })}
      >
        <div
          id={message.id}
          className={classNames("message", `message-pair-${message.pairId}`)}
          dangerouslySetInnerHTML={{ __html: formattedContent }}
        ></div>
        {(
          (message.role === ConversationRoleTypes.Assistant &&
            isAnswered &&
            message.messageMetadataObj !== undefined &&
            (
              message.messageMetadataObj as ChatAssistantConversationMessageMetadata
            ).helpCenterTopicHeaders) ||
          []
        ).length > 0 && (
          <div className="chat-message-references">
            <Popover2
              interactionKind="hover"
              popoverClassName={classnames(
                "chat-message-references-popover",
                Classes.POPOVER_CONTENT_SIZING
              )}
              portalClassName="chat-message-references-popover-portal"
              placement="right"
              hasBackdrop={false}
              content={
                <div className="chat-message-links">
                  {(
                    (
                      message.messageMetadataObj as ChatAssistantConversationMessageMetadata
                    ).helpCenterTopicHeaders || []
                  ).map((metadata) => {
                    const redirectString = `/helpcenter/${
                      topicSectionTypeConfig[metadata.sectionType].path
                    }${
                      topicSectionTypeConfig[metadata.sectionType]
                        .filterByUsState
                        ? "/" + usStateAsString?.toLowerCase()
                        : ""
                    }`;

                    const link = {
                      url: `${redirectString}?t=${metadata.id}&chatMinimized=true`,
                      text: metadata.title,
                    };

                    return (
                      <Button
                        key={metadata.id}
                        icon={IconNames.DocumentOpen}
                        className="chat-message-link"
                        minimal={true}
                        intent={Intent.PRIMARY}
                        text={link.text}
                        onClick={() => window.open(link.url, "_blank")}
                      />
                    );
                  })}
                </div>
              }
            >
              <Button
                rightIcon={IconNames.ArrowRight}
                className="chat-message-reference-link"
                minimal={true}
                intent={Intent.PRIMARY}
                text="References"
              />
            </Popover2>
          </div>
        )}
      </div>
    );

    return template;
  };

  return {
    getOpenConversation,
    isLoadingConversation,
    conversation,
    startConversation,
    endConversation,
    newPrompt,
    setNewPrompt,
    isAskingAssistant,
    chatTemplate,
    mapMessagesToObject,
    setConversationTopicType,
    setConversationUsState,
    topicType,
    usState,
    usStateAsString,
    usStateHasStatePolicy,
    availableUsStates,
    isMDRQuestion,
    isUsStateQuestion,
    haveUsStateQuestionState,
    searchStatePolicyTopics,
    searchSameQuestionOnStatePolicyTopics,
    lastMessageWasSearchStatePolicyTopics,
    reset,
  };
};
