import { Dialog } from "@blueprintjs/core";
import { useState } from "react";
import { ChecklistSplit } from "../Checklist/ChecklistSplit";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";

export interface ChecklistSplitDialogProps extends BaseDialogProps {
  applicationId?: string;
  checklistItemId?: string;
  onClose?: (refresh: boolean) => void;
}

export const ChecklistSplitDialog: React.FC<ChecklistSplitDialogProps> = (
  props
) => {
  const [isSplitted, setIsSplitted] = useState<boolean>(false);

  const closeDialogHandler = () => {
    props.onClose && props.onClose(isSplitted);
  };
  return (
    <Dialog
      isOpen={props.isOpen}
      hasBackdrop={false}
      portalClassName="mdr-dialog checklist-split-dialog-container fullscreen-dialog"
      isCloseButtonShown={false}
      title={"Checklist Split"}
      canOutsideClickClose={false}
      onClose={closeDialogHandler}
    >
      <div className="checklist-split-dialog">
        {props.applicationId && props.checklistItemId && (
          <ChecklistSplit
            applicationId={props.applicationId}
            checklistItemId={props.checklistItemId}
            setIsSplitted={setIsSplitted}
            onClose={() => {
              closeDialogHandler();
            }}
          />
        )}
      </div>
    </Dialog>
  );
};
