import GridColumn from "../../Grid/Grid/types/GridColumn";
import GridBillingRuleTooltipColumn from "./GridBillingRuleTooltipColumn";

export const billingRuleGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "corporateId",
    headerText: "Corporate Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "productType_AsString",
    headerText: "Product Type",
    type: "string",
  }),
  new GridColumn({
    field: "billingTrigger_AsString",
    headerText: "Billing Trigger",
    type: "string",
  }),
  new GridColumn({
    field: "name",
    headerText: "Name",
    type: "string",
  }),
  new GridColumn({
    field: "amount",
    headerText: "Amount",
    type: "number",
    format: "C",
  }),
  new GridColumn({
    field: "shouldShow",
    headerText: "Show On Bill",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
  }),
  new GridColumn({
    field: "shouldDiscounted",
    headerText: "Should Be Discounted?",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
  }),
  new GridColumn({
    field: "startDate",
    headerText: "Start Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "endDate",
    headerText: "End Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new GridBillingRuleTooltipColumn({
    field: "",
    headerText: "Next Billing Increase",
    type: "string",
    width: 160,
  }),
];

export const discountGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "corporateId",
    headerText: "Corporate Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "quantityStartRange",
    headerText: "Quantity Start Range",
    type: "number",
  }),
  new GridColumn({
    field: "discountAmount",
    headerText: "Discount Amount",
    type: "number",
    format: "C",
  }),
  new GridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
];

export const billingGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "id",
    headerText: "Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "corporateName",
    headerText: "Corporate Name",
    type: "string",
  }),
  new GridColumn({
    field: "facilityName",
    headerText: "Facility Name",
    type: "string",
  }),
  new GridColumn({
    field: "fileNumber",
    headerText: "File #",
    type: "string",
  }),
  new GridColumn({
    field: "residentFirstName",
    headerText: "Resident First Name",
    type: "string",
  }),
  new GridColumn({
    field: "residentLastName",
    headerText: "Resident Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "name",
    headerText: "Name",
    type: "string",
  }),
  new GridColumn({
    field: "billingRuleTrigger",
    headerText: "Bill Trigger",
    type: "string",
  }),
  new GridColumn({
    field: "isShown",
    headerText: "Show On Bill",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
  }),
  new GridColumn({
    field: "isDiscounted",
    headerText: "Is Discounted?",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
  }),
  new GridColumn({
    field: "billingDate_AsDateOnly",
    headerText: "Service Date",
    type: "date",
    allowSearching: false,
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "billingRuleAmount",
    headerText: "Billing Amount",
    type: "number",
    format: "C",
  }),
  new GridColumn({
    field: "discountAmount",
    headerText: "Discount Amount",
    type: "number",
    format: "C",
  }),
  new GridColumn({
    field: "amount",
    headerText: "Final Amount",
    type: "number",
    format: "C",
  }),
  new GridColumn({
    field: "changed",
    headerText: "Changed",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    allowSorting: false,
  }),
  new GridColumn({
    field: "changeNote",
    headerText: "Change Note",
    type: "string",
    allowSorting: false,
    width: 150,
  }),
  new GridColumn({
    field: "invoiceNumber",
    headerText: "Invoice #",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created At",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),

  new GridColumn({
    field: "billingPeriod",
    headerText: "Billing Period",
    format: { format: "MM/dd/yyyy", type: "date" },
    type: "Date",
    allowSearching: false,
  }),
  new GridColumn({
    field: "billingPeriodMonth",
    headerText: "Billing Period Month",
    type: "string",
  }),
  new GridColumn({
    field: "billingPeriodYear",
    headerText: "Billing Period Year",
    type: "string",
  }),
  new GridColumn({
    field: "facilityState_AsString",
    headerText: "Facility State",
    type: "string",
  }),
  new GridColumn({
    field: "companyCode",
    headerText: "Company Code",
    type: "string",
  }),
  new GridColumn({
    field: "facilityInvoiceNumber",
    headerText: "Facility Invoice #",
    type: "string",
  }),
];
