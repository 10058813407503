import { FormGroup } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import {
  ChangeEventArgs,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { NumericTextFieldProps } from "../types/fieldTypes";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";

export const NumericField: React.FC<NumericTextFieldProps> = ({
  label,
  description,
  value,
  formatValue,
  disabled,
  onSubmit,
  hidden,
  type,
  numericFieldProps,
  formId,
  errorMessages,
  validationModel,
  fieldName,
  isRequired,
}) => {
  if (hidden) {
    return null;
  }
  const [localValue, setLocalValue] = React.useState<number | undefined>(value);
  const [formObject, setFormObject] = React.useState<FormValidator>();
  const numericInputRef = useRef<NumericTextBoxComponent | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const isTooltipVisible = useOverflowTooltip(
    numericInputRef.current?.element,
    localValue?.toString() || ""
  );
  // Initialize
  useEffect(() => {
    if (!!numericInputRef.current && !initialized) {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    onSubmit(localValue);
    setFormObject(new FormValidator(`#${formId}`, validationModel));
  }, [localValue, formId]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <FormGroup
      className={`${
        isRequired && !value && value !== 0
          ? "has-required-background"
          : "base-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <WithTooltip
        shouldShowTooltip={isTooltipVisible}
        content={!!localValue && isTooltipVisible ? localValue.toString() : ""}
      >
        <NumericTextBoxComponent
          ref={numericInputRef}
          type={type}
          name={fieldName}
          value={localValue}
          readOnly={!!disabled}
          min={numericFieldProps?.min}
          max={numericFieldProps?.max}
          showSpinButton={false}
          currency="numericFieldProps?.currency"
          format={numericFieldProps?.format}
          validateDecimalOnType={true}
          change={(e: ChangeEventArgs) => {
            setLocalValue(e.value);
          }}
          blur={() => {
            if (!!formatValue && !!localValue) {
              setLocalValue(formatValue(localValue));
            }
            formObject?.validate(fieldName);
          }}
          decimals={numericFieldProps?.decimals}
          placeholder={numericFieldProps?.placeholder}
          step={0}
        />
      </WithTooltip>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage: string, idx: number) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
