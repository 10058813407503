import { UserRoles } from "../../../types/User";
import GridColoredColumn from "../../Grid/Grid/types/GridColoredColumn";
import GridColumn from "../../Grid/Grid/types/GridColumn";
import moment from "moment";

export const gridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "firstName",
    headerText: "Notary First Name",
    type: "string",
  }),
  new GridColumn({
    field: "lastName",
    headerText: "Notary Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "state_AsString",
    headerText: "Notary State",
    type: "string",
  }),
  new GridColumn({
    field: "county_AsString",
    headerText: "Notary County",
    type: "string",
  }),
  new GridColumn({
    field: "number",
    headerText: "Notary Number",
    type: "string",
  }),
  new GridColoredColumn<Date>({
    field: "expirationDate_AsDateOnly",
    fieldToColor: "expirationDate_AsDateOnly",
    headerText: "Notary Expiration Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
    width: 120,
    color: "red",
    shouldUpdateFieldColor: (expirationDate) =>
      moment(expirationDate).isBefore(moment(), "D"),
    formatField: (expirationDate) =>
      moment(expirationDate).format("MM/DD/yyyy"),
  }),
  new GridColumn({
    field: "bondCompany",
    headerText: "Notary Bond Company",
    type: "string",
  }),
  new GridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedBy",
    headerText: "Updated By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created At",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "createdBy",
    headerText: "Created By",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "firstName",
  "lastName",
  "state",
  "county_AsString",
  "number",
  "expirationDate_AsString",
  "bondCompany",
  "updatedAt_AsDateOnly",
  "updatedBy",
  "createdAt_AsDateOnly",
  "createdBy",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
