import { get } from "lodash";
import { useEffect, useState } from "react";
import { applicationApiClient } from "../../../lib/apiClients/application/applicationApiClient";
import { corporateApiClient } from "../../../lib/apiClients/corporate/corporateApiClient";
import { facilityApiClient } from "../../../lib/apiClients/facility/facilityApiClient";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  Application,
  getApplicationProductType,
} from "../../../types/Application";
import { Billing } from "../../../types/Billing";
import { Corporate } from "../../../types/Corporate";
import { Facility } from "../../../types/Facility";
import {
  AllFields,
  SectionHeaderProps,
  Option,
} from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getInvoiceNumberAsyncAutocompleteFieldProps,
} from "../types";

export const updateBillingEventFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "billing";
  const billing = form as Billing;
  const [application, setApplication] = useState<Application>();
  const [facility, setFacility] = useState<Facility>();
  const [corporate, setCorporate] = useState<Corporate>();

  useEffect(() => {
    if (billing.applicationId) {
      applicationApiClient
        .getApplicationById(billing.applicationId)
        .then(setApplication);
    }
  }, [billing.applicationId]);

  useEffect(() => {
    if (application?.facilityId) {
      facilityApiClient
        .getFacilityDetail(application.facilityId)
        .then(setFacility);
    }
  }, [application]);

  useEffect(() => {
    if (facility?.corporateId) {
      billing.corporateId = facility.corporateId;

      corporateApiClient
        .getCorporateDetail(facility.corporateId)
        .then(setCorporate);
    }
  }, [facility]);

  return [
    {
      type: "Text",
      label: "Application File Number",
      value: application?.fileNumber,
      onSubmit: (value: string) => {
        onValueChange({ applicationId: value });
      },
      isRequired: true,
      disabled: true,
    } as unknown as AllFields,
    {
      type: "Text",
      label: "Resident First Name",
      value: application?.firstName || "",
      errorMessages: get(errors, "firstName"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      type: "Text",
      label: "Resident Last Name",
      value: application?.lastName || "",
      errorMessages: get(errors, "lastName"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      type: "Text",
      label: "Facility",
      value: facility?.facilityName || "",
      errorMessages: get(errors, "facility"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "corporateId"),
      value: billing.corporateId,
      onSubmit: (corporateId) => {
        onValueChange({ corporateId });
      },
      errorMessages: get(errors, "corporateId"),
      disabled: true,
      isRequired: true,
      hidden: true,
    } as AllFields,
    {
      type: "Text",
      label: "Corporate",
      value: corporate?.companyName || "",
      errorMessages: get(errors, "corporate"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      type: "Text",
      label: "Product Type",
      value: getApplicationProductType(application?.productType) || "",
      errorMessages: get(errors, "productType"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "name"),
      value: billing.name,
      onSubmit: (name) => {
        onValueChange({ name });
      },
      errorMessages: get(errors, "name"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "invoiceNumber_AsString"),
      ...getInvoiceNumberAsyncAutocompleteFieldProps(),
      value: billing.invoiceNumber_AsString,
      onSubmit: (option: Option & { invoiceId: string }) => {
        onValueChange({
          invoiceNumber_AsString: option?.value,
          invoiceId: option?.invoiceId,
        });
      },
      disabled: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "billingDate"),
      value: billing.billingDate,
      onSubmit: (billingDate) => {
        onValueChange({ billingDate });
      },
      errorMessages: get(errors, "billingDate"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "amount"),
      value: billing.amount,
      onSubmit: (amount) => {
        onValueChange({ amount });
      },
      errorMessages: get(errors, "amount"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isShown"),
      value: billing.isShown,
      onSubmit: (isShown) => {
        onValueChange({ isShown });
      },
      errorMessages: get(errors, "isShown"),
      isRequired: false,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isDiscounted"),
      value: billing.isDiscounted,
      disabled: true,
      onSubmit: (isDiscounted) => {
        onValueChange({ isDiscounted });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("billingChangeEvent", "changeNote"),
      onSubmit: (changeNote) => {
        onValueChange({ changeNote });
      },
      value: billing.changeNote,
      errorMessages: get(errors, "changeNote"),
      isRequired: true,
    } as AllFields,
  ];
};
