import { YesNoFieldProps } from "../types/fieldTypes";
import { RadioField } from "./RadioField";

export const YesNoField: React.FC<YesNoFieldProps> = ({
  label,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  description,
}) => {
  const yesNoValue = value === true ? "yes" : value === false ? "no" : "";

  return (
    <RadioField
      type="Radio"
      label={label}
      description={description}
      value={yesNoValue}
      disabled={disabled}
      isRequired={isRequired}
      onSubmit={(value) => {
        const parsedValue =
          value === "yes" ? true : value === "no" ? false : undefined;
        onSubmit(parsedValue);
      }}
      hidden={hidden}
      errorMessages={errorMessages}
      optionValues={[
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ]}
    />
  );
};
