import { Intent, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  Filter,
  GridComponent,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import React, { FC } from "react";
import { reactPlugin } from "../../AppInsights";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { applicationStore } from "../../stores/ApplicationStore";
import {
  UIChecklistItem,
  checklistItemStore,
} from "../../stores/ChecklistItemStore";
import { commentStore } from "../../stores/CommentStore";
import { schemaStore } from "../../stores/SchemaStore";
import { userStore } from "../../stores/UserStore";
import { ChecklistItem } from "../../types/Checklist";
import { User } from "../../types/User";
import { getObjectValueClassName } from "../../utils/miscellaneous";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { PdfDocuments } from "../Document/PdfDocuments";
import { buildField } from "../Fields";
import { AllFields, Option } from "../Fields/types/fieldTypes";
import { GridActionsColumnDirective } from "../Grid/Grid/types/GridActionsColumn";
import { ChecklistDocumentPicker } from "../TemplatePicker/ChecklistDocumentPicker";
import { WithTooltip } from "../Tooltip/Tooltip";
import "./styles.scss";
import { set } from "lodash";
import { shouldVerifyChecklistDocument } from "./Verify/useVerifyComponent";
import { checklistApiClient } from "../../lib/apiClients/checklist/checklistApiClient";
import { getExistingOwnedEntity } from "../Application/UseOwnedEntityComponent";
import { Asset } from "../../types/Application/Asset";
import moment from "moment";
import { getValidAggregatedStatements_AsListString } from "../../utils/getStatementOptions";
import { Income } from "../../types/Application/Income";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface ChecklistGridProps {
  canSplitChecklist: boolean;
  checklistItems: UIChecklistItem[];
  user?: User;
}

const checklistDocumentTypesPermissions: {
  [key: number]: (user?: User, responsiblePartyType?: number) => boolean;
} = {
  // Rep Uploads
  219: (user?: User) => !!user?.canEditRepUploadsChecklistItemDocument,
  // DVT Uploads
  156: (user?: User) => !!user?.canEditDVTUploadsChecklistItemDocument,
  // Can Edit Poa Document Checklist Item Document
  20: (user?: User) => !!user?.canEditPoaDocumentChecklistItemDocument,
  // Can Edit Poa Identification Checklist Item Document
  1: (user?: User, responsiblePartyType?: number) => {
    switch (responsiblePartyType) {
      case 4:
        return (
          !!user?.canEditPoaIdChecklistItemDocument ||
          !!user?.canEditSpousePoaIdChecklistItemDocument
        );
      case 5:
        return !!user?.canEditGuardianIdChecklistItemDocument;
      default:
        return true;
    }
  },
  10_001: (user?: User) =>
    !!user?.canEditSpousePoaDocumentChecklistItemDocument,
  21: (user?: User) => !!user?.canEditGuardianDocumentChecklistItemDocument,
};

const ChecklistGridComponent: FC<ChecklistGridProps> = ({
  canSplitChecklist,
  checklistItems,
  user,
}) => {
  const [localChecklistItems] =
    React.useState<UIChecklistItem[]>(checklistItems);
  const gridRef = React.useRef<GridComponent | null>(null);

  React.useEffect(() => {
    set(gridRef, "current.dataSource", checklistItems);
  }, [checklistItems]);

  const updateGridRow = (
    index: number | string,
    updatedChecklistItem: UIChecklistItem
  ) => {
    if (gridRef) {
      gridRef.current?.setRowData(index, updatedChecklistItem);
    }
  };

  const detailCollapseAll = () => {
    if (gridRef) {
      gridRef.current?.detailCollapseAll();
    }
  };

  const findGridChecklist = (checklistId: string) => {
    return gridRef.current
      ?.getRowsObject()
      .find(
        (rowObject) => checklistId === (rowObject.data as UIChecklistItem)?.id
      )?.data as UIChecklistItem;
  };

  const shouldUserEditDocuments = (checklistItem: ChecklistItem) => {
    // If the checklist item doesn't have a document type,
    // or the document type isn't in the permissions list,
    // allow the user to edit the documents.
    if (
      !checklistItem.documentType ||
      !Object.keys(checklistDocumentTypesPermissions).includes(
        String(checklistItem.documentType)
      )
    ) {
      return !!user?.canEditRegularApplicationChecklistItemDocument;
    }
    return checklistDocumentTypesPermissions[checklistItem.documentType](
      user,
      checklistItem.responsiblePartyType
    );
  };

  const checklistStatementsReceivedColumnTemplate = (
    checklistItem: UIChecklistItem
  ) => {
    let ownedEntity: any = undefined;
    let allStatementsReceived = false;
    let statementsStillNeeded: string[] = [];
    let statementsNeededAndReceived: string[] = [];
    let allStatementsNeeded: string[] = [];

    if (checklistItem.useStatementsReceived) {
      ownedEntity = getExistingOwnedEntity(
        checklistItem.entityType,
        checklistItem.entityId
      );

      allStatementsNeeded = getValidAggregatedStatements_AsListString(
        (ownedEntity as Asset | Income)?.aggregatedStatementsNeeded
      );
      statementsStillNeeded =
        allStatementsNeeded?.filter(
          (item) => !checklistItem.statementsReceived_AsList?.includes(item)
        ) ?? [];

      statementsNeededAndReceived =
        checklistItem.statementsReceived_AsList?.filter((item) =>
          allStatementsNeeded?.includes(item)
        ) ?? [];

      allStatementsReceived =
        (statementsNeededAndReceived.length ?? 0) >=
        (allStatementsNeeded?.length ?? 0);
    }
    return (
      <WithTooltip
        shouldShowTooltip={
          checklistItem.useStatementsReceived &&
          !!ownedEntity &&
          statementsStillNeeded.length > 0
        }
        content={
          <div>
            {statementsStillNeeded.length > 0 && (
              <div>
                <p>Statements Needed</p>
                <ul>
                  {statementsStillNeeded.map((statement, index) => (
                    <li key={index}>{moment(statement).format("MMMM yyyy")}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        }
      >
        <div className="checklist-grid-statements-received">
          {checklistItem.useStatementsReceived && !!ownedEntity && (
            <td
              className={`checklist-grid-statements-received-progress__${getObjectValueClassName(
                allStatementsReceived ? "complete" : "incomplete"
              )}`}
            >
              {`${statementsNeededAndReceived?.length ?? 0}/${
                allStatementsNeeded?.length ?? 0
              }`}
            </td>
          )}
        </div>
      </WithTooltip>
    );
  };
  return (
    <div>
      {/* {!localChecklistItems && <Loading />} */}
      {localChecklistItems && localChecklistItems?.length <= 0 && (
        <NonIdealState title="No Information Available" />
      )}
      {localChecklistItems && localChecklistItems?.length > 0 && (
        <GridComponent
          id="checklist-grid"
          key={`checklist-grid-at-${checklistItemStore.gridLastRefreshedAt}`}
          ref={gridRef}
          dataSource={localChecklistItems}
          allowSorting={true}
          allowFiltering={true}
          filterSettings={{
            type: "Excel",
          }}
          statelessTemplates={["detailTemplate"]}
          detailTemplate={(checklistItem: ChecklistItem) => {
            return (
              <div>
                <ChecklistDocumentPicker
                  onDocumentPicked={(checklistDocument) => {
                    documentApiClient
                      .reuploadChecklistItemDocument(
                        checklistDocument?.value || "",
                        checklistItem
                      )
                      .then(() => {
                        checklistItemStore.setChecklistItems(true);
                      });
                  }}
                  applicationId={applicationStore.application?.id || ""}
                />

                <PdfDocuments
                  checklistItem={checklistItem}
                  disableEditDoc={!shouldUserEditDocuments(checklistItem)}
                  disableRenameDoc={!shouldUserEditDocuments(checklistItem)}
                  disableDeleteDoc={!shouldUserEditDocuments(checklistItem)}
                  disableUploadDoc={!shouldUserEditDocuments(checklistItem)}
                  disableVerifyDoc={
                    !shouldUserEditDocuments(checklistItem) ||
                    !userStore.user?.canVerifyDocument ||
                    !shouldVerifyChecklistDocument(checklistItem)
                  }
                  setUIChecklistItemFileCount={async (
                    _,
                    action,
                    updatedCount
                  ) => {
                    const ogChecklistItem = findGridChecklist(checklistItem.id);
                    let count = ogChecklistItem.count ?? 0;

                    if (action === "minus") {
                      count -= updatedCount;
                    } else if (action === "plus") {
                      count += updatedCount;
                      //Set Status to Needs Review On Doc Upload.
                      updateGridRow(ogChecklistItem.id, {
                        ...ogChecklistItem,
                        status: 4,
                        count,
                      });
                    } else if (action === "verify") {
                      const checklistDocs =
                        await checklistApiClient.getChecklistItemDocuments(
                          ogChecklistItem.id
                        );
                      const allDocumentsVerified =
                        checklistDocs.documents.length > 0 &&
                        checklistDocs.documents.every((doc) => doc.isVerified);

                      const documentStatementsReceived =
                        checklistDocs.documents.flatMap(
                          (document) => document.statementsReceived_AsList
                        );
                      // Set Status to Complete if all checklist documents are verified
                      updateGridRow(ogChecklistItem.id, {
                        ...ogChecklistItem,
                        status: allDocumentsVerified
                          ? 2
                          : ogChecklistItem.status,
                        statementsReceived_AsList:
                          checklistItem.useStatementsReceived
                            ? (documentStatementsReceived as string[])
                            : ogChecklistItem.statementsReceived_AsList,
                        count,
                      });
                    }

                    detailCollapseAll();
                    checklistItemStore.setChecklistItems(false);
                  }}
                />
              </div>
            );
          }}
        >
          <Inject services={[Sort, Filter, DetailRow]} />
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              isPrimaryKey={true}
              visible={false}
              width="100"
            />
            <ColumnDirective
              {...GridActionsColumnDirective<
                ChecklistItem & { commentCount: number }
              >({
                headerText: "Actions",
                width: 60,
                clipMode: "Clip",
                actions: [
                  ...(canSplitChecklist
                    ? [
                        {
                          icon: IconNames.Fork,
                          disabled: (checklistItem: ChecklistItem) =>
                            !checklistItem.count || checklistItem.count <= 0,
                          onActionClick: (checklistItem: ChecklistItem) => {
                            dialogsViewerStore.setIsChecklistSplitDialogOpen(
                              true,
                              {
                                applicationId: checklistItem.applicationId,
                                checklistItemId: checklistItem.id,
                                onClose: (refresh: boolean) => {
                                  if (refresh) {
                                    checklistItemStore.setChecklistItems(true);
                                  }

                                  dialogsViewerStore.setIsChecklistSplitDialogOpen(
                                    false
                                  );
                                },
                              }
                            );
                          },
                          alwaysEnabled: true,
                        },
                      ]
                    : []),
                  {
                    icon: IconNames.Comment,
                    intent: (checklistItem) =>
                      checklistItem.commentCount > 0
                        ? Intent.PRIMARY
                        : Intent.NONE,
                    disabled: (checklistItem) =>
                      !shouldUserEditDocuments(checklistItem),
                    onActionClick: (checklistItem) => {
                      dialogsViewerStore.setIsCommentsDialogOpen(true, {
                        sourceId: checklistItem.id,
                        sourceName: checklistItem.displayName,
                        applicationId: applicationStore.application?.id,
                        applicationFileNumber:
                          applicationStore.application?.fileNumber,
                        isSrcApplication: false,
                        onSuccess: () => {
                          const ogChecklistItem = findGridChecklist(
                            checklistItem.id
                          );
                          updateGridRow(checklistItem.id, {
                            ...ogChecklistItem,
                            commentCount: commentStore.comments.filter(
                              (c) => c.sourceId === checklistItem.id
                            ).length,
                          });
                        },
                      });
                    },
                  },
                  {
                    icon: IconNames.EDIT,
                    disabled: (checklistItem) =>
                      !shouldUserEditDocuments(checklistItem),
                    onActionClick: (checklistItem) => {
                      dialogsViewerStore.setIsChecklistItemUpdateDialogOpen(
                        true,
                        {
                          checklistItem: checklistItem,
                          onSuccess: (updatedChecklistItem) => {
                            updateGridRow(
                              checklistItem.id,
                              updatedChecklistItem as UIChecklistItem
                            );
                          },
                        }
                      );
                    },
                  },
                ],
              })}
            />
            <ColumnDirective
              headerText="Name"
              field="displayName"
              width="100"
              template={(checklistItem: ChecklistItem) => {
                return (
                  <WithTooltip
                    shouldShowTooltip={true}
                    content={checklistItem.displayName || ""}
                  >
                    <td className={"checklist-grid-text"}>
                      {checklistItem.displayName}
                    </td>
                  </WithTooltip>
                );
              }}
            />
            <ColumnDirective
              headerText="Description"
              field="shortDescription"
              width="100"
              template={(checklistItem: ChecklistItem) => (
                <div className="checklist-grid-description">
                  <WithTooltip
                    shouldShowTooltip={true}
                    content={checklistItem.shortDescription || ""}
                  >
                    <td className={"checklist-grid-text"}>
                      {checklistItem.shortDescription}
                    </td>
                  </WithTooltip>
                </div>
              )}
            />
            <ColumnDirective
              headerText="Category"
              field="documentCategory_AsString"
              width="100"
            />
            {featureToggleStore.featureToggles?.EnableVerify && (
              <ColumnDirective
                headerText="Statements Received"
                field="statementsReceived"
                width="75"
                allowFiltering={false}
                allowSorting={false}
                template={(
                  checklistItem: UIChecklistItem & { index: number }
                ) => {
                  return checklistStatementsReceivedColumnTemplate(
                    checklistItem
                  );
                }}
              />
            )}

            <ColumnDirective
              headerText="Status"
              field="status_AsString"
              width="100"
              template={(
                checklistItem: UIChecklistItem & { index: number }
              ) => (
                <div
                  className={`checklist-item-status__${getObjectValueClassName(
                    checklistItem.status.toString()
                  )}`}
                >
                  {buildField(
                    {
                      ...schemaStore.getFieldSchemaByName(
                        "checklistItem",
                        "status"
                      ),
                      label: "",
                      value: checklistItem.status,
                      disabled:
                        !userStore.user?.canEditApplicationChecklistItemStatus,
                      onSubmit: async (status: Option) => {
                        updateGridRow(checklistItem.id, {
                          ...checklistItem,
                          status: Number(status.value),
                        });

                        await checklistItemStore
                          .updateChecklistItem(checklistItem.id, {
                            ...checklistItem,
                            status: Number(status.value),
                          })
                          .then(async () => {
                            await applicationStore.getEligibilitySummary();
                          });
                      },
                    } as AllFields,
                    0
                  )}
                </div>
              )}
            />
            <ColumnDirective
              headerText="Files"
              field="count"
              type="number"
              width="30"
            />
            <ColumnDirective
              headerText="Updated"
              field="updatedAt"
              type="datetime"
              width="50"
              format={{
                format: "MM/dd/yyyy hh:mm a",
                type: "dateTime",
              }}
            />
          </ColumnsDirective>
        </GridComponent>
      )}
    </div>
  );
};

export const ChecklistGrid = withAITracking(
  reactPlugin,
  ChecklistGridComponent,
  "ChecklistGrid",
  "AI-flex-container"
);
