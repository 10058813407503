import {
  Alignment,
  BreadcrumbProps,
  Breadcrumbs,
  Navbar,
} from "@blueprintjs/core";
import { Observer } from "mobx-react";
import logo from "../../assets/Medicaid-Done-Right-Logo.png";
import { userStore } from "../../stores/UserStore";
import { Application } from "../../types/Application";
import { User } from "../../types/User";
import { ApplicationPageHeaderCard } from "../Application/Cards/ApplicationPage/ApplicationPageHeaderCard";
import { Avatar } from "../Avatar/Avatar";
import { ApplicationSearchToolbar } from "./ApplicationSearchToolbar";
import "./styles.scss";

export interface HeaderProps {
  id?: string;
  address?: string;
  name?: string;
  breadCrumbs?: BreadcrumbProps[];
  renderHeading?: () => React.ReactNode;
  renderActions?: () => React.ReactNode;
  applicationPageHeaderProps?: ApplicationPageHeaderCardProps;
}

export interface ApplicationPageHeaderCardProps {
  application?: Application;
  activeUsers?: User[];
}

export const Header: React.FC<HeaderProps> = ({
  name,
  breadCrumbs,
  renderHeading,
  renderActions,
  applicationPageHeaderProps,
}) => {
  return (
    <Navbar className="navbar">
      <Navbar.Group className="navbar_left" align={Alignment.LEFT}>
        <img src={logo} width={100} />
        <span className="navbar_left_title">{name}</span>
        {breadCrumbs && <Breadcrumbs items={breadCrumbs} />}
        {!userStore.user?.isExternal && <ApplicationSearchToolbar />}
      </Navbar.Group>
      <Navbar.Group>
        {renderHeading && (
          <span className="navbar__checklist-title">{renderHeading()} </span>
        )}
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {applicationPageHeaderProps && (
          <Observer>
            {() => (
              <ApplicationPageHeaderCard
                application={applicationPageHeaderProps.application}
                activeUsers={applicationPageHeaderProps.activeUsers}
                canWithdrawApplicationPage={
                  !!userStore.user?.canWithdrawApplicationPage
                }
                canGenerateApplicationDocPackage={
                  !!userStore.user?.canGenerateApplicationDocPackage ||
                  !!userStore.user?.canGenerateDocsWhileClosed
                }
                canMonitorApplicationPage={
                  !!userStore.user?.canMonitorApplicationPage
                }
                canSubmitApplicationToState={
                  !!userStore.user?.canSubmitApplicationToState
                }
                canConvertApplicationToRecert={
                  !!userStore.user?.canConvertApplicationToRecert
                }
              />
            )}
          </Observer>
        )}

        {renderActions && <div>{renderActions()}</div>}
        <Avatar />
      </Navbar.Group>
    </Navbar>
  );
};
