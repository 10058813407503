import { ColumnModel } from "@syncfusion/ej2-react-grids";
import GridColumn from "./GridColumn";
import "../styles.scss";

interface GridMaskedSSNColumnOptions extends ColumnModel {}

export default class GridMaskedSSNColumn extends GridColumn {
  constructor(opts: GridMaskedSSNColumnOptions) {
    const { ...superOpts } = opts;

    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const maskSSN = (ssn: string) => {
          if (ssn.length === 9) {
            return ssn.replace(/(\d{3})(\d{2})(\d{4})/, "XXX-XX-$3");
          } else if (ssn.length === 8) {
            return ssn.replace(/(\d{2})(\d{2})(\d{4})/, "XX-XX-$3");
          }
          return ssn;
        };

        const ssnValue = opts.field ? model[opts.field] ?? "" : "";
        const maskedSsn = maskSSN(ssnValue);

        return <div>{maskedSsn}</div>;
      }) as any,
    });
  }
}
