import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { applicationStore } from "../../stores/ApplicationStore";
import { Application } from "../../types/Application";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { applicationPageFacilityUpdateFormSchema } from "../Forms/ApplicationPage/ApplicationPageFacilityUpdate";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";

export interface ApplicationPageUpdateFacilityDialogProps
  extends BaseDialogProps {}

export const ApplicationPageUpdateFacilityDialog: React.FC<
  ApplicationPageUpdateFacilityDialogProps
> = ({ isOpen, closeDialog }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Facility on Application"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Form
          formId="checklist-item-form-update"
          value={
            dialogsViewerStore.applicationPageUpdateFacilityDialogOpenOptions
              ?.application
          }
          formSchemaBuilder={applicationPageFacilityUpdateFormSchema}
          onFormSubmit={async (application: Application) => {
            setIsSaving(true);
            try {
              await applicationStore.updateApplicationFacility(application);
              closeDialog();
            } finally {
              setIsSaving(false);
            }
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="checklist-item-button"
          isSaving={isSaving}
          formId="checklist-item-form-update"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
