import { Intent, Position, Toaster } from "@blueprintjs/core";

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  position: Position.TOP,
});

export const showSuccessfulSaveAppToaster = (name: string) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>Success</h3>
        {name} Saved
      </div>
    ),
    intent: Intent.SUCCESS,
  });
};

export const showDownloadingFileAppToaster = (fileName: string) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>Exporting...</h3>
        {fileName} is downloading...
      </div>
    ),
    intent: Intent.NONE,
  });
};

export const showSuccessAppToaster = (successActionName: string) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>Success!</h3>
        {successActionName}
      </div>
    ),
    intent: Intent.SUCCESS,
  });
};

export const showFailedSaveAppToaster = (itemsToDisplay: string[]) => {
  return AppToaster.show({
    message: (
      <div>
        <h3>Error</h3>
        {itemsToDisplay.map((x) => x)} had errors while saving, and did not save
      </div>
    ),
    intent: Intent.DANGER,
  });
};

export const showGenericFailedSaveAppToaster = () => {
  return AppToaster.show({
    message: (
      <div>
        <h3>Error</h3>
        An error occured while saving the application....
      </div>
    ),
    intent: Intent.DANGER,
  });
};
