import { get } from "lodash";
import { schemaStore } from "../../../../stores/SchemaStore";
import { Asset, AssetInformation } from "../../../../types/Application/Asset";
import { notInListValue } from "../../../Fields/AsyncAutocompleteField/AsyncAutocompleteODataV4Adaptor";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getInstitutionAsyncAutocompleteFieldProps,
} from "../../types";
import { getStatementOptions } from "../../../../utils/getStatementOptions";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { ApplicationStatuses } from "../../../../types/Application";
import { userStore } from "../../../../stores/UserStore";
import { featureToggleStore } from "../../../../lib/featureToggles/FeatureToggleStore";
import { getAssetOwnerOptions } from "../../../../types/Application/AssetHelpers";

export const lifeInsuranceApplicationFormSchema: FormSchemaBuilder = (
  _onValueChange,
  form,
  _errors,
  _formId,
  onFormSubmit
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as AssetInformation;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasAssetLifeInsurance"),
      value: application.hasAssetLifeInsurance,
      onSubmit: (hasAssetLifeInsurance) => {
        if (onFormSubmit) {
          onFormSubmit({ ...application, hasAssetLifeInsurance });
        }
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseHasAssetLifeInsurance"
      ),
      value: application.spouseHasAssetLifeInsurance,
      onSubmit: (spouseHasAssetLifeInsurance) => {
        if (onFormSubmit) {
          onFormSubmit({ ...application, spouseHasAssetLifeInsurance });
        }
      },
      isRequired: true,
      hidden: !applicationStore.application?.isMarried,
    } as AllFields,
  ];
};

export const lifeInsuranceFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "asset";
  const asset = form as Asset;

  const canEdit = !!applicationStore.applicationCanBeEdited;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "institutionId"),
      ...getInstitutionAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "institutionId"),
      hasNotInListOption: true,
      groupByActiveStatus: true,
      width: "48.5%",
      isRequired: true,
      value: asset.hasOtherInstitutionName
        ? notInListValue
        : asset.institutionId,
      onSubmit: (institution: { id: string }) => {
        const hasOtherInstitutionName = institution?.id === notInListValue;
        if (hasOtherInstitutionName) {
          onValueChange({
            institutionId: undefined,
            hasOtherInstitutionName: true,
          });
        } else {
          onValueChange({
            institutionId: institution?.id,
            hasOtherInstitutionName: false,
          });
        }
      },
    } as unknown as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "otherInstitutionName"),
      value: asset.otherInstitutionName,
      hidden: !asset.hasOtherInstitutionName,
      onSubmit: (otherInstitutionName) => {
        onValueChange({ otherInstitutionName });
      },
      isRequired: true,
      errorMessages: get(errors, "otherInstitutionName"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "owner"),
      value: asset.owner,
      onSubmit: (owner: Option) => {
        onValueChange({ owner: owner.value });
      },
      isRequired: true,
      errorMessages: get(errors, "owner"),
      width: "48.5%",
      optionValues: getAssetOwnerOptions(),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "subType"),
      optionValues: schemaStore.getMappingsByName("lifeInsurance"),
      value: asset.subType,
      onSubmit: (subType: Option) => {
        onValueChange({ subType: subType.value });
      },
      isRequired: true,
      errorMessages: get(errors, "subType"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "accountNumber"),
      value: asset.accountNumber,
      onSubmit: (accountNumber) => {
        onValueChange({ accountNumber });
      },
      isRequired: false,
      errorMessages: get(errors, "accountNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "estimatedValue"),
      label: "Face Value",
      value: asset.estimatedValue,
      onSubmit: (estimatedValue) => {
        onValueChange({ estimatedValue });
      },
      isRequired: true,
      errorMessages: get(errors, "estimatedValue"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "value"),
      label: "Cash Value",
      value: asset.value,
      onSubmit: (value) => {
        onValueChange({ value });
      },
      isRequired: true,
      errorMessages: get(errors, "value"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "asOfDate"),
      value: asset.asOfDate,
      onSubmit: (asOfDate) => {
        onValueChange({ asOfDate });
      },
      isRequired: true,
      errorMessages: get(errors, "asOfDate"),
      width: "22.5%",
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isExempt"),
      value: asset.isExempt,
      onSubmit: (isExempt) => {
        onValueChange({ isExempt });
      },
      disabled: !canEdit || !userStore.user?.canEditAssetExempt,
      hidden:
        applicationStore.application?.status ==
        ApplicationStatuses.interviewing,
      isRequired:
        applicationStore.application?.status !=
        ApplicationStatuses.interviewing,
      errorMessages: get(errors, "isExempt"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isVerified"),
      value: asset.isVerified,
      onSubmit: (isVerified) => {
        onValueChange({ isVerified });
      },
      disabled: !canEdit || !userStore.user?.canEditAssetVerified,
      hidden:
        applicationStore.application?.status ==
        ApplicationStatuses.interviewing,
      isRequired:
        applicationStore.application?.status !=
        ApplicationStatuses.interviewing,
      errorMessages: get(errors, "isVerified"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isIrrevocablyAssigned"),
      value: asset.isIrrevocablyAssigned,
      onSubmit: (isIrrevocablyAssigned) => {
        onValueChange({ isIrrevocablyAssigned });
      },
      isRequired: true,
      errorMessages: get(errors, "isIrrevocablyAssigned"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "irrevocablyAssignedType"),
      value: asset.irrevocablyAssignedType,
      onSubmit: (irrevocablyAssignedType: Option) => {
        onValueChange({
          irrevocablyAssignedType: irrevocablyAssignedType.value,
        });
      },
      isRequired: true,
      hidden: !asset.isIrrevocablyAssigned,
      errorMessages: get(errors, "irrevocablyAssignedType"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "irrevocablyAssignedDate"),
      value: asset.irrevocablyAssignedDate,
      onSubmit: (irrevocablyAssignedDate) => {
        onValueChange({ irrevocablyAssignedDate });
      },
      isRequired: true,
      hidden: !asset.isIrrevocablyAssigned,
      errorMessages: get(errors, "irrevocablyAssignedDate"),
      width: "48.5%",
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDateRangeStart"),
      optionValues: getStatementOptions(),
      value: asset.statementDateRangeStart,
      onSubmit: (statementDateRangeStart: Option) => {
        onValueChange({
          statementDateRangeStart: statementDateRangeStart?.value,
        });
      },
      shouldShowClearButton: true,
      errorMessages: get(errors, "statementDateRangeStart"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDates_AsList"),
      optionValues: getStatementOptions(),
      value: asset.statementDates_AsList,
      onSubmit: (statementDates_AsList) => {
        onValueChange({ statementDates_AsList });
      },
      errorMessages: get(errors, "statementDates_AsList"),
      width: "48.5%",
    } as AllFields,
    ...(featureToggleStore.featureToggles?.EnableStatementFrequency
      ? [
          {
            ...schemaStore.getFieldSchemaByName(
              modelName,
              "statementFrequency"
            ),
            value: asset.statementFrequency,
            onSubmit: (statementFrequency: Option) => {
              onValueChange({ statementFrequency: statementFrequency.value });
            },
            isRequired: false,
            errorMessages: get(errors, "statementFrequency"),
            width: "48.5%",
          } as AllFields,
        ]
      : []),
    ...(featureToggleStore.featureToggles?.EnableStatementCycleEndDay
      ? [
          {
            ...schemaStore.getFieldSchemaByName(
              modelName,
              "statementCycleEndDay"
            ),
            onSubmit: (statementCycleEndDay) => {
              onValueChange({ statementCycleEndDay });
            },
            value: asset.statementCycleEndDay,
            minValue: 1,
            maxValue: 31,
            errorMessages: get(errors, "statementCycleEndDay"),
            width: "48.5%",
          } as AllFields,
        ]
      : []),
  ];
};
