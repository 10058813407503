import { action, makeObservable, observable } from "mobx";
import { Bill } from "../../types/Application/Bill";
import { Income } from "../../types/Application/Income";
import { OwnedEntityViewerStore } from "./OwnedEntityViewerStore";

export interface IncomeStore {
  addLocalIncome: (incomeToAdd: Income) => void;
  removeLocalIncome: (incomeToRemove: Income) => void;
  saveLocalIncomes: () => void;
  localIncomes?: Bill[];
}

export class IncomeOwnedEntityStore
  extends OwnedEntityViewerStore
  implements IncomeStore
{
  public localIncomes?: Income[] = [];

  constructor() {
    super();
    makeObservable(this, {
      localIncomes: observable,
      addLocalIncome: action,
      removeLocalIncome: action,
      saveLocalIncomes: action,
    });
  }

  public addLocalIncome(incomeToAdd: Income) {
    if (!this.localIncomes) {
      this.localIncomes = [];
    }

    const index = this.localIncomes.findIndex(
      (bill) => bill.id === incomeToAdd.id
    );
    if (index === -1) {
      // Bill not found, add the new bill
      this.localIncomes = [...this.localIncomes, incomeToAdd];
    } else {
      // Bill found, update the existing bill
      this.localIncomes = this.localIncomes.map((bill, i) =>
        i === index ? incomeToAdd : bill
      );
    }
  }

  public removeLocalIncome(incomeToRemove: Income) {
    if (!this.localIncomes) {
      return;
    }
    this.localIncomes = this.localIncomes.filter(
      (x) => x.id !== incomeToRemove.id
    );
  }

  public saveLocalIncomes = async () => {
    const promisesToAwait = [];

    if (!!this.localIncomes && this.localIncomes.length > 0) {
      for (let i = 0; i < this.localIncomes.length; i++) {
        const asset = this.localIncomes[i];
        if (asset.id === "new-income") {
          promisesToAwait.push(
            this.createAndAwaitApplicationOwnedEntity({
              page: "income",
              ownedEntity: asset,
            })
          );
        } else {
          promisesToAwait.push(
            this.updateAndAwaitApplicationOwnedEntity({
              page: "income",
              ownedEntity: asset,
              ownedEntityId: asset.id,
            })
          );
        }
      }
      await this.awaitAndValidateOwnedEntityPromises(promisesToAwait, "income");
      this.localIncomes = [];
    }
  };
}

export const incomeOwnedEntityStore = new IncomeOwnedEntityStore();
