import { action, makeObservable, observable } from "mobx";
import { get } from "lodash";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import {
  AjaxRequestInitiateEventArgs,
  AjaxRequestSettingsModel,
  ExportStartEventArgs,
  IAjaxHeaders,
  ImportSuccessEventArgs,
  IPoint,
  IStampAnnotation,
  PdfAnnotationBase,
  PdfViewerComponent,
  PdfViewerModel,
} from "@syncfusion/ej2-react-pdfviewer";
import { FileDocument } from "../../../types/FileDocument";
import { ExpressionData, expressionsStore } from "./ExpressionsStore";

export class TemplateDesignerStore {
  // computed

  // public
  public pdfViewerInstance?: PdfViewerComponent;
  public accessToken?: string;
  public fileDocument?: FileDocument;
  public pdfViewerId?: string;
  public isReadOnlyMode?: boolean;
  public isSaving?: boolean;
  public isDeleting?: boolean;
  public hasAnnotationsModified = false;
  public onSave?: (fileFormData?: string) => Promise<void>;
  public onDelete?: (id?: string) => Promise<void>;

  private defaultPdfViewerSettings: PdfViewerModel & DefaultHtmlAttributes = {
    enableThumbnail: true,
    isThumbnailViewOpen: true,
    enableNavigationToolbar: true,
    enableBookmark: false,
    enableCommentPanel: false,
    enablePrint: false,
    enableToolbar: false,
    annotationAdd: () => {
      this.hasAnnotationsModified = true;
    },
    annotationRemove: () => {
      this.hasAnnotationsModified = true;
    },
    annotationResize: () => {
      this.hasAnnotationsModified = true;
    },
    toolbarSettings: {
      toolbarItems: [],
      annotationToolbarItems: [
        "AnnotationDeleteTool",
        "FontFamilyAnnotationTool",
        "FontSizeAnnotationTool",
        "FontStylesAnnotationTool",
        "FontAlignAnnotationTool",
        "FontColorAnnotationTool",
        "ColorEditTool",
        "StrokeColorEditTool",
        "ThicknessEditTool",
        "OpacityEditTool",
      ],
      formDesignerToolbarItems: [],
    },
  };

  constructor() {
    makeObservable(this, {
      pdfViewerInstance: observable,
      pdfViewerId: observable,
      isReadOnlyMode: observable,
      isSaving: observable,
      onSave: observable,
      hasAnnotationsModified: observable,
      save: action,
      isDeleting: observable,
      onDelete: observable,
      delete: action,
    });
  }

  public addExpressionAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    return expressionsStore.addExpressionAnnotation(templateExpression, point);
  };

  public save = async () => {
    this.isSaving = true;

    // Get Annotations data
    const annotationsData = await expressionsStore.exportAnnotations();

    // Trigger event
    !!this.onSave && (await this.onSave(annotationsData));

    this.isSaving = false;
    this.hasAnnotationsModified = false;
  };

  public delete = async () => {
    this.isDeleting = true;

    // Trigger event
    !!this.onDelete && (await this.onDelete(this.pdfViewerId));

    this.isDeleting = false;
  };

  public importAnnotations = async (annotationsData?: string) => {
    if (annotationsData) {
      await expressionsStore.importAnnotations(annotationsData);
    }
  };

  public setDesignMode = () => {
    if (!this.pdfViewerInstance) {
      return;
    }

    // Transform Annotations
    if (this.pdfViewerInstance.annotations.length <= 0) {
      //"No Annotations"
      return;
    }

    expressionsStore.designAnnotations();
  };

  public setPreviewMode = async () => {
    if (!this.pdfViewerInstance) {
      return;
    }

    // Transform Annotations
    if (this.pdfViewerInstance.annotationCollection.length <= 0) {
      // No Annotations
      return;
    }

    await expressionsStore.previewAnnotations();
  };

  private handleExportStart = (args: ExportStartEventArgs) => {
    const stampAnnotations: IStampAnnotation[][] = JSON.parse(
      args.exportData.stampAnnotations
    );

    const newStampAnnotations = stampAnnotations.map((page) =>
      page.map((annotation) => {
        const foundOgAnnotation = expressionsStore.originalImportedData?.find(
          (ogAnnotation) => ogAnnotation.AnnotName === annotation.annotName
        );
        const foundOgAnnotationImageData = get(
          foundOgAnnotation?.Apperarance.find(
            (apperarance) => apperarance["imagedata"]
          ),
          "imagedata"
        );

        const isUnloadedStamped =
          annotation.stampAnnotationType === "path" && !!annotation.customData;

        if (isUnloadedStamped) {
          return {
            ...annotation,
            stampAnnotationType: "image",
            stampAnnotationPath:
              foundOgAnnotationImageData ?? annotation.stampAnnotationPath,
          };
        } else {
          return annotation;
        }
      })
    );

    args.exportData.stampAnnotations = JSON.stringify(newStampAnnotations);
  };

  private handleAjaxRequestInitiate = (args: AjaxRequestInitiateEventArgs) => {
    // Set file data
    args.JsonData["fileName"] = this.fileDocument?.fileName;
    args.JsonData["folderName"] = this.fileDocument?.folderName;
    args.JsonData["isChunked"] = this.fileDocument?.isChunked;
  };

  public setup = (args: {
    pdfViewerId: string;
    accessToken: string;
    fileDocument: FileDocument;
    isReadOnlyMode: boolean;
  }): PdfViewerModel & DefaultHtmlAttributes => {
    // Setup Properties
    this.accessToken = args.accessToken;
    this.fileDocument = args.fileDocument;
    this.pdfViewerId = args.pdfViewerId;
    this.isReadOnlyMode = args.isReadOnlyMode;

    // Setup Settings
    const settings = { ...this.defaultPdfViewerSettings } as PdfViewerModel &
      DefaultHtmlAttributes;

    if (this.isReadOnlyMode) {
      settings.toolbarSettings!.annotationToolbarItems = [];
    }

    settings.id = args.pdfViewerId;

    settings.exportStart = this.handleExportStart;
    // Ajax
    settings.ajaxRequestSettings = {
      ajaxHeaders: [
        {
          headerName: "Authorization",
          headerValue: `Bearer ${this.accessToken}`,
        },
      ] as IAjaxHeaders[],
      withCredentials: false,
    } as AjaxRequestSettingsModel;
    settings.ajaxRequestInitiate = this.handleAjaxRequestInitiate;

    // Document
    settings.documentPath = this.fileDocument.fileName;

    // Events
    if (!this.isReadOnlyMode) {
      settings.annotationSelect = expressionsStore.handleAnnotationSelect;
      settings.annotationUnSelect = expressionsStore.handleAnnotationUnselect;
      settings.annotationRemove = expressionsStore.annotationRemove;
    }

    settings.importSuccess = (args: ImportSuccessEventArgs) => {
      if (args?.importData) {
        const importedData = (
          Object.values(args.importData)
            .map((x: any) => Object.values(x))
            .flat() as any[]
        )
          .filter((x) => x.length > 0)
          .flat();

        // Set Imported Data
        expressionsStore.setOriginalImportedData(importedData);
      }
    };

    // Expressions
    expressionsStore.setup({ pdfViewerId: args.pdfViewerId });

    return settings;
  };

  public initialize = async (
    newPdfViewerInstance: PdfViewerComponent,
    fileFormData?: string
  ) => {
    this.setInstance(newPdfViewerInstance);
    expressionsStore.initialize(newPdfViewerInstance);

    // Import Annotations
    await this.importAnnotations(fileFormData);
  };

  public reset = () => {
    this.setInstance(undefined);
    expressionsStore.reset();
    this.hasAnnotationsModified = false;
  };

  public setInstance = (scopePdfViewerInstance?: PdfViewerComponent) => {
    this.pdfViewerInstance = scopePdfViewerInstance;
  };
  public setPdfViewerId = (pdfViewerId: string) => {
    this.pdfViewerId = pdfViewerId;
  };
}

export const templateDesignerStore = new TemplateDesignerStore();

export interface TemplateDesignerSetup {
  pdfViewerId: string;
  accessToken: string;
  fileDocument: FileDocument;
  isReadOnlyMode: boolean;
}
