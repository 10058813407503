import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { ApplicationChild } from "../../types/Application/ApplicationChild";
import { applicationChildFormSchema } from "../Forms/ApplicationPage/ApplicationChildForm";
import { useApplicationChildCreationDialog } from "./useApplicationChildCreationDialog";

export interface ApplicationChildCreationDialogProps extends BaseDialogProps {}

export const ApplicationChildCreationDialog: React.FC<
  ApplicationChildCreationDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const { isSaving, localApplicationChild, submitChildApplicationForm } =
    useApplicationChildCreationDialog({
      submitDialog,
    });

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Application Child"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<ApplicationChild>
              formId="create-application-child-form"
              value={localApplicationChild}
              formSchemaBuilder={applicationChildFormSchema}
              onFormSubmit={async (applicationChild) => {
                await submitChildApplicationForm(applicationChild);
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="user-save-button"
          isSaving={isSaving}
          formId="create-application-child-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
