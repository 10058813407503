import { Button, Elevation, Intent } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { billOwnedEntityStore } from "../../../stores/OwnedEntityStores/BillOwnedEntityStore";
import { Bill } from "../../../types/Application/Bill";
import { AddButton } from "../../Buttons/AddButton";
import { DeleteButton } from "../../Buttons/DeleteButton";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import RelativityCard from "../../Card/RelativityCard";
import { SectionHeader } from "../../Fields/SectionHeader/SectionHeader";
import Form from "../../Forms";
import { billFormSchema, newBill } from "../../Forms/ApplicationForm/Bill";
import { showSuccessfulSaveAppToaster } from "../../Toast/Toast";
import "../styles.scss";
import _, { orderBy } from "lodash";

export interface BillCardProps {
  bills?: Bill[];
  createAndUpdateBills: () => Promise<void>;
  deleteBill?: (bill: Bill) => Promise<void>;
  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  showCancelButton: boolean;
  onCancel?: () => void;
  showAddButton: boolean;
}

export const newBillId = "new-bill";
export const BillCard: FC<BillCardProps> = ({
  bills,
  createAndUpdateBills,
  deleteBill,
  shouldDisableForm,
  setShouldDisableForm,
  formIsSaving,
  setFormIsSaving,
  showCancelButton,
  onCancel,
  showAddButton,
}) => {
  const [localBills, setLocalBills] = useState<Bill[]>(bills || []);

  const [isInDeleteAction, setIsInDeleteAction] = useState<string>();
  const [isAddingAction, setIsAddingAction] = useState<boolean>(false);

  const isActiveDelete = (asset: Bill) => {
    return asset.id === isInDeleteAction;
  };

  const hasNewBill = localBills.some((bill) => bill.id === newBillId);

  useEffect(() => {
    setLocalBills(bills || []);
  }, [bills]);

  return (
    <RelativityCard
      headerContent={
        <div className="owned-entity-list-header">
          <SectionHeader type="Section" label="Bills" inAction={formIsSaving} />
          {!!showCancelButton && (
            <Button
              minimal
              intent={Intent.DANGER}
              text="Cancel"
              onClick={onCancel}
            />
          )}
          {!showCancelButton && showAddButton && (
            <AddButton
              loading={isAddingAction}
              disabled={shouldDisableForm || formIsSaving || hasNewBill}
              text="Add Bill"
              onClick={() => {
                setShouldDisableForm(true);
                setIsAddingAction(true);
                setLocalBills([
                  ...localBills,
                  { ...newBill, id: newBillId } as Bill,
                ]);
                setShouldDisableForm(false);
                setIsAddingAction(false);
              }}
            />
          )}
        </div>
      }
    >
      {localBills?.length > 0 &&
        orderBy(localBills, ["createdAt"], ["desc"]).map((bill, index) => {
          const isNewBill = bill.id === newBillId;

          return (
            <RelativityCard
              key={bill.id}
              className="owned-entity-card bill-card"
              elevation={Elevation.TWO}
              headerContent={
                <div className="owned-entity-header asset-card-header">
                  <SectionHeader
                    label={`Bill ${index + 1}`}
                    type="Section"
                    inAction={formIsSaving}
                  />
                  <div>
                    {deleteBill && (
                      <DeleteButton
                        isDeleting={isActiveDelete(bill)}
                        disabled={shouldDisableForm || formIsSaving}
                        onClick={async () => {
                          setShouldDisableForm(true);
                          setIsInDeleteAction(bill.id);
                          if (isNewBill) {
                            setLocalBills(
                              localBills.filter((bill) => bill.id !== newBillId)
                            );
                            billOwnedEntityStore.removeLocalBill(bill);
                          } else {
                            deleteBill && (await deleteBill(bill));
                          }

                          setIsInDeleteAction(undefined);

                          setShouldDisableForm(false);
                        }}
                      />
                    )}
                    <FormSubmitButton
                      buttonId={`bill-form-create-button-${bill.id}`}
                      isSaving={formIsSaving}
                      disabled={shouldDisableForm}
                      formId={`bill-form-${bill.id}`}
                      text={isNewBill ? "Create" : "Update"}
                    />
                  </div>
                </div>
              }
            >
              <Form<Bill>
                formId={`bill-form-${bill.id}`}
                value={bill || newBill}
                formSchemaBuilder={billFormSchema}
                disableAllFields={shouldDisableForm}
                shouldSaveEntiityToLocalStore={true}
                ownedEntityStoreAction={(updatedBill) => {
                  if (JSON.stringify(updatedBill) !== JSON.stringify(bill)) {
                    billOwnedEntityStore.addLocalBill(updatedBill);
                  }
                }}
                shouldBlockNavigation={true}
                onFormSubmit={async () => {
                  setShouldDisableForm(true);
                  setFormIsSaving(true);

                  createAndUpdateBills()
                    .then(() => {
                      showSuccessfulSaveAppToaster("Bills");
                    })
                    .finally(() => {
                      setFormIsSaving(false);
                      setShouldDisableForm(false);
                    });
                }}
              />
            </RelativityCard>
          );
        })}
    </RelativityCard>
  );
};
