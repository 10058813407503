import {
  FIARequest,
  FIARequestApproveSendDTO,
  FIARequestCancelDTO,
  FIARequestCreateDTO,
  FIARequestDetails,
  FIARequestResendDTO,
} from "../../../components/FIARequests/configurations/types";
import {
  DocumentFilingAssociatedChecklistItem,
  DocumentFilingCreate,
} from "../../../components/DocumentFiling/types";
import {
  OrderedDocumentCoverLetterCreateDTO,
  OrderedDocumentCreateDTO,
  OrderedDocumentReorderDTO,
} from "../../../components/OrderedDocumentsGrid/configurations/types";
import { Application } from "../../../types/Application";
import { ValidationFailure } from "../../exceptions/types";
import baseApiClient from "../baseApiClient";

export const getFiaRequestODataRoutes = () => {
  return {
    fiaRequestRoute: "odata/fiarequestsodatav2",
    fiaRequestDocumentsRoute: "odata/fiarequestdocumentsodatav2",
  };
};

class FIARequestApiClient {
  newRequest = async (applicationId: string): Promise<FIARequest> => {
    return baseApiClient.get<FIARequest>(
      `application/${applicationId}/fiarequests/v2/new`
    );
  };
  getRequest = async (
    applicationId: string,
    fiaRequestId: string
  ): Promise<FIARequest> => {
    return baseApiClient.get<FIARequest>(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}`
    );
  };

  getAssociatedChecklistItem = async (
    applicationId: string,
    fiaRequestId: string
  ): Promise<DocumentFilingAssociatedChecklistItem> => {
    return baseApiClient.get<DocumentFilingAssociatedChecklistItem>(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/associated-checklist-item`
    );
  };

  createFiaFiling = async (
    applicationId: string,
    fiaRequestId: string,
    fiaFilingCreate: DocumentFilingCreate
  ) => {
    return baseApiClient.post(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/filing`,
      fiaFilingCreate
    );
  };

  getMostRecentRequestByOwnedEntityId = async (
    applicationId: string,
    ownedEntityId: string
  ): Promise<FIARequest> => {
    return baseApiClient.get<FIARequest>(
      `application/${applicationId}/fiarequests/v2/entity/${ownedEntityId}`
    );
  };

  getSubmittedRequestsByChecklistItemId = async (
    applicationId: string,
    checklistItemId: string
  ): Promise<FIARequest[]> => {
    return baseApiClient.get<FIARequest[]>(
      `application/${applicationId}/fiarequests/v2/checklist-item/${checklistItemId}`
    );
  };

  getOutstandingFIARequestsCount = async (applicationId: string) => {
    return baseApiClient.get<number>(
      `application/${applicationId}/fiarequests/v2/outstanding`
    );
  };

  createRequest = async (
    applicationId: string,
    fiaRequestCreateDTO: FIARequestCreateDTO
  ): Promise<FIARequest> => {
    return baseApiClient.post<FIARequestDetails>(
      `application/${applicationId}/fiarequests/v2`,
      fiaRequestCreateDTO
    );
  };

  cancelRequest = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequestCancelDTO: FIARequestCancelDTO
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/cancel`,
      fiaRequestCancelDTO
    );
  };

  cancelAllRequests = async (
    applicationId: string,
    fiaRequestCancelDTO: FIARequestCancelDTO
  ): Promise<Application> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/cancel`,
      fiaRequestCancelDTO
    );
  };

  approveAndSendRequest = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequestApproveSendDTO: FIARequestApproveSendDTO,
    isBypass: boolean
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/approve-and-send${
        isBypass ? "/bypass" : ""
      }`,
      fiaRequestApproveSendDTO
    );
  };

  validateFIARequestRequirements = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequestApproveSendDTO: FIARequestApproveSendDTO
  ): Promise<ValidationFailure[]> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/validate-document-requirements`,
      fiaRequestApproveSendDTO
    );
  };

  approveRequest = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequestApproveSendDTO: FIARequestApproveSendDTO,
    isBypass: boolean
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/approve${
        isBypass ? "/bypass" : ""
      }`,
      fiaRequestApproveSendDTO
    );
  };

  resendRequest = async (
    applicationId: string,
    fiaRequestId: string,
    isBypass: boolean,
    fiaRequestResendDTO: FIARequestResendDTO
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/resend${
        isBypass ? "/bypass" : ""
      }`,
      fiaRequestResendDTO
    );
  };
  markAsReceivedRequest = async (
    applicationId: string,
    fiaRequestId: string
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/mark-as-received`
    );
  };

  markAsNotReceivedRequest = async (
    applicationId: string,
    fiaRequestId: string
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/mark-as-not-received`
    );
  };

  updateFIARequestDetails = async (
    applicationId: string,
    fiaRequestId: string,
    fiaRequestUpdateDTO: FIARequestDetails
  ): Promise<FIARequest> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}`,
      fiaRequestUpdateDTO
    );
  };

  createFIARequestDocument = async (
    applicationId: string,
    fiaRequestId: string,
    orderedDocumentsCreateDTO: OrderedDocumentCreateDTO
  ) => {
    return baseApiClient.post(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/documents`,
      orderedDocumentsCreateDTO
    );
  };

  createFIARequestCoverLetter = async (
    applicationId: string,
    fiaRequestId: string,
    orderedDocumentsCreateDTO: OrderedDocumentCoverLetterCreateDTO
  ) => {
    return baseApiClient.post(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/cover-letter`,
      orderedDocumentsCreateDTO
    );
  };

  reorderFIARequestDocument = async (
    applicationId: string,
    fiaRequestId: string,
    orderedDocumentReorderDTO: OrderedDocumentReorderDTO
  ) => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/documents/${orderedDocumentReorderDTO.discriminatorId}`,
      orderedDocumentReorderDTO
    );
  };

  sortFIARequestDocuments = async (
    applicationId: string,
    fiaRequestId: string
  ): Promise<void> => {
    return baseApiClient.put(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/documents/sort`
    );
  };

  deleteFIARequestDocument = async (
    applicationId: string,
    fiaRequestId: string,
    discriminatorId: string
  ) => {
    return baseApiClient.delete(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/documents/${discriminatorId}`
    );
  };

  previewFIARequestDocument = async (
    applicationId: string,
    fiaRequestId: string,
    discriminatorId: string
  ): Promise<BlobPart> => {
    return baseApiClient.download(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/documents/${discriminatorId}/preview`
    );
  };

  previewFIARequest = async (
    applicationId: string,
    fiaRequestId: string
  ): Promise<BlobPart> => {
    return baseApiClient.download(
      `application/${applicationId}/fiarequests/v2/${fiaRequestId}/preview`
    );
  };
}

export const fiaRequestApiClient = new FIARequestApiClient();
