import { Observer } from "mobx-react";
import { Header } from "../components/Header/Header";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { FIARequestsGrid } from "../components/FIARequests/FIARequestsGrid";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { featureToggleStore } from "../lib/featureToggles/FeatureToggleStore";
import ErrorWrapper from "./ErrorWrapper";
import { FIARequestODataReadDTO } from "../components/FIARequests/configurations/types";
import { userStore } from "../stores/UserStore";
import "./styles.scss";
import { useEffect, useState } from "react";
import { openFlyoutInPlace } from "@ucl/library";
import { FIARequestFlyout } from "../components/FIARequests/FIARequestFlyout";

const FIAPageComponent: React.FC = () => {
  const [gridNeedsRefresh, setGridNeedsRefresh] = useState<boolean>(false);

  const [isFiaFlyoutOpen, setIsFiaFlyoutOpen] = useState(false);
  const [fiaFlyoutApplicationId, setFiaFlyoutApplicationId] =
    useState<string>();
  const [fiaFlyoutRequestId, setFiaFlyoutRequestId] = useState<string>();
  const [fiaFlyoutApplicationURL, setFiaFlyoutApplicationURL] =
    useState<string>();

  useEffect(() => {
    if (!isFiaFlyoutOpen) {
      setFiaFlyoutApplicationId(undefined);
      setFiaFlyoutRequestId(undefined);
      setFiaFlyoutApplicationURL(undefined);
    }
  }, [isFiaFlyoutOpen]);

  const getFIARequestURL = (
    rowData: FIARequestODataReadDTO,
    openFlyout?: boolean
  ): string => {
    // Go to Application Form if Application status is Interviewing, else go to Application Page
    if (rowData.applicationStatus_AsString === "Interviewing") {
      return `/application-form/${rowData.applicationId}/fia-requests`;
    } else {
      return `/application-page/${rowData.applicationId}/fia-requests${
        openFlyout ? `/open/${rowData.id}` : ""
      }`;
    }
  };

  const openFIAFlyout = (rowData: FIARequestODataReadDTO) => {
    setIsFiaFlyoutOpen(true);
    setFiaFlyoutApplicationId(rowData.applicationId);
    setFiaFlyoutRequestId(rowData.id);
    setFiaFlyoutApplicationURL(getFIARequestURL(rowData));
  };

  const onRecordClick = async ({
    rowData,
  }: {
    rowData: FIARequestODataReadDTO;
  }) => {
    if (userStore.user?.canViewFIARequest) {
      openFIAFlyout(rowData);
    }
  };

  return featureToggleStore.featureToggles?.EnableFiaGrid ? (
    <div className="page board-page fia-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="fias" />
        <div className="page-main-content">
          <FIARequestsGrid
            gridKey="fia-requests-grid"
            defaultGridViewTitle="Active"
            defaultSort={{
              field: "applicationDueDate_AsDateOnly",
              direction: "Descending",
            }}
            shouldShowViews={true}
            shouldShowExportToExcel={true}
            shouldShowSearch={true}
            shouldShowOpenInNewTab={true}
            shouldShowMetrics={true}
            getFIARequestURL={(rowData) => getFIARequestURL(rowData, true)}
            onRecordClick={onRecordClick}
            gridNeedsRefresh={gridNeedsRefresh}
            setGridNeedsRefresh={setGridNeedsRefresh}
          />
        </div>
      </div>
      {isFiaFlyoutOpen &&
        fiaFlyoutApplicationId &&
        fiaFlyoutRequestId &&
        openFlyoutInPlace(
          <FIARequestFlyout
            applicationId={fiaFlyoutApplicationId}
            fiaRequestId={fiaFlyoutRequestId}
            applicationURL={fiaFlyoutApplicationURL}
            flyoutIsOpen={true}
            onClose={() => {
              setIsFiaFlyoutOpen(false);
              setGridNeedsRefresh(true);
            }}
          />,
          true,
          () => {
            setIsFiaFlyoutOpen(false);
            setGridNeedsRefresh(true);
          },
          { canOutsideClickClose: true }
        )}
    </div>
  ) : (
    <div className="page">
      <ErrorWrapper />
    </div>
  );
};

export const FIAPage = withAITracking(
  reactPlugin,
  FIAPageComponent,
  "FIAPage",
  "AI-flex-container"
);
