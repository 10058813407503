import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import { reactPlugin } from "../AppInsights";
import { ApplicationGrid } from "../components/Application/Grid/ApplicationGrid";
import { Header } from "../components/Header/Header";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { userStore } from "../stores/UserStore";
import "./styles.scss";

const HomePageComponent: React.FC = () => {
  return (
    <div className="page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="home" />
        <div className="page-main-content">
          <Observer>
            {() => (
              <ApplicationGrid
                canConfigureBoard={
                  !!userStore.user?.canConfigureApplicationBoard
                }
                canExportGridToExcel={
                  !!userStore.user?.canExportApplicationBoardToExcel
                }
                canCreateNewRecord={!!userStore.user?.canCreateApplication}
                canInvokeGridRecord={!userStore.user?.isExternal}
              />
            )}
          </Observer>
        </div>
      </div>
    </div>
  );
};

export const HomePage = withAITracking(
  reactPlugin,
  HomePageComponent,
  "HomePage",
  "AI-flex-container"
);
