import { Label } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { observer } from "mobx-react";
import { Icon } from "@blueprintjs/core";
import "./styles.scss";
import { WithTooltip } from "../../Tooltip/Tooltip";

export interface SelectableLabelProps {
  name: string | React.ReactNode;
  description?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const SelectableLabel: React.FC<SelectableLabelProps> = observer(
  ({ name, description, disabled, onClick }) => {
    return (
      <Label className="field-label" onClick={() => !disabled && onClick?.()}>
        <span className="label-title">{name}</span>
        <WithTooltip
          placement="auto"
          content={description || ""}
          interactionKind={"hover"}
          shouldShowTooltip={true}
          disabled={!description}
        >
          {description && <Icon iconSize={15} icon={IconNames.InfoSign} />}
        </WithTooltip>
      </Label>
    );
  }
);
