import { Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { applicationStore } from "../../stores/ApplicationStore";
import { Document } from "../../types/Document";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { AppToaster } from "../Toast/Toast";
import { DocumentPagesViewer } from "./DocumentPagesViewer";
import {
  PdfDocumentEntityType,
  PdfDocumentUploader,
} from "./PdfDocumentUploader";
import "./styles.scss";
import { useDocument } from "./useDocument";
import {
  DocumentAction,
  DocumentActionRender,
  DocumentActionTypes,
} from "./useDocumentPagesViewer";

interface SimplePdfDocumentsProps {
  id: string;
  documentIds: string[];
  entityId?: string;
  entityType: PdfDocumentEntityType;
  disableUploadDoc?: boolean;
  disablePreviewDoc?: boolean;
  disableOpenNewTabDoc?: boolean;
  disableDownloadDoc?: boolean;
  disableDeleteDoc?: boolean;
  confirmDeletion?: boolean;
  //hooks
  onDocChanged?: (docs: Document[]) => void;
  pdfDocumentUploaderProps?: Partial<PdfDocumentUploader>;
}

export const SimplePdfDocuments: FC<SimplePdfDocumentsProps> = (props) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const { documentActionTypes, fetchDocumentsByIds } = useDocument();

  const deleteDocument = async (doc: Document) => {
    await documentApiClient.deleteDocument(doc);

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Document Deleted.
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    setDocuments(documents.filter((oldDoc) => oldDoc.id !== doc.id));
  };

  const checklistDocumentActionTypes = () => {
    const documentActions = documentActionTypes({
      disableEditDoc: false,
      disableRenameDoc: false,
      disablePreviewDoc: props.disablePreviewDoc,
      disableOpenNewTabDoc: props.disableOpenNewTabDoc,
      disableOpenNewTabStampedDoc: true,
      disableDownloadDoc: props.disableDownloadDoc,
    });

    if (!props.disableOpenNewTabDoc) {
      documentActions.push(DocumentActionTypes.Refresh);
    }

    if (!props.disableDeleteDoc) {
      documentActions.push({
        name: "delete",
        minimal: true,
        icon: IconNames.TRASH,
        intent: Intent.DANGER,
        onActionClick: async (doc: Document) => {
          props.confirmDeletion
            ? dialogsViewerStore.confirm({
                content: (
                  <>Are you sure you would like to delete this document?</>
                ),
                onClose: () => {
                  null;
                },
                onConfirm: async () => {
                  await deleteDocument(doc);
                  return {
                    isSuccess: true,
                    error: undefined,
                  };
                },
              })
            : await deleteDocument(doc);
        },
      } as DocumentAction<Document>);
    }

    documentActions.push({
      name: "document-info",
      render: (doc: Document) => {
        return (
          <div className="document-info-container">
            <ul className="document-info">
              <li className="document-info-item">
                <span className="document-info-item-label">Uploaded By:</span>
                <span className="document-info-item-value">
                  {doc.createdBy_AsName}
                </span>
              </li>
            </ul>
          </div>
        );
      },
    } as DocumentActionRender<Document>);

    return documentActions;
  };

  const uploaderElement = document.getElementById(
    `pdf-document__uploader-${props.id}`
  );

  const showDocumentUploader = () => {
    if (!!uploaderElement) {
      uploaderElement.className = "pdf-document__uploader";
    }
  };

  useEffect(() => {
    if (!!props.onDocChanged) {
      props.onDocChanged(documents);
    }
  }, [documents]);

  useEffect(() => {
    setIsLoadingDocuments(true);
    fetchDocumentsByIds(props.documentIds)
      .then((documents) => {
        setDocuments(documents);
      })
      .finally(() => {
        setIsLoadingDocuments(false);
      });
  }, []);

  return (
    <Observer>
      {() => (
        <div className="pdf-document">
          <div
            id={`pdf-document__uploader-${props.id}`}
            className="pdf-document__uploader"
          >
            {!props.disableUploadDoc && (
              <PdfDocumentUploader
                pdfDocumentEntity={{
                  entityId: props.entityId,
                  entityType: props.entityType,
                  applicationId: applicationStore.application?.id,
                }}
                onCompleteUploading={(doc) => {
                  setDocuments([...documents, doc]);
                }}
                showDocumentUploader={showDocumentUploader}
                allowMultipleFiles={true}
                {...props.pdfDocumentUploaderProps}
              />
            )}
          </div>
          <div className="pdf-document__documents">
            {isLoadingDocuments && <Spinner size={36} />}
            {!isLoadingDocuments && (
              <DocumentPagesViewer
                key={documents.length}
                documents={documents}
                isAnimated={false}
                documentItems={checklistDocumentActionTypes()}
                readOnly={true}
                disabledSelectionOrder={true}
                disabledPagePreview={true}
              />
            )}
          </div>
        </div>
      )}
    </Observer>
  );
};
