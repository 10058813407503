import { FormGroup, Icon, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useEffect, useRef, useState } from "react";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { TextFieldProps } from "../types/fieldTypes";
import "./styles.scss";
import { containsOnlyNumbers } from "./utils";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";
import variables from "../../../config/variables";

export const TextField: React.FC<TextFieldProps> = ({
  label,
  value,
  inputMode,
  placeholder,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  description,
  maxLength,
  leftIcon,
  isRequired,
  readOnly,
  isSensitive,
  sanitize,
  onInitialized,
  onEnterKeyPress,
}) => {
  if (hidden) {
    return null;
  }

  const textInputRef = useRef<HTMLInputElement | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [maskValue, setMaskValue] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<string>("");

  const isTooltipVisible = useOverflowTooltip(textInputRef.current, localValue);

  // Initialize
  useEffect(() => {
    if (!!textInputRef.current && !initialized) {
      setInitialized(true);
      setAndSanitize(value);
      onInitialized && onInitialized(textInputRef.current);
    }
    if (!!isSensitive) {
      setMaskValue(true);
    }
  }, []);

  useEffect(() => {
    if (!!textInputRef.current && !!initialized) {
      setAndSanitize(value);
    }
  }, [value]);

  const setAndSanitize = (newValue: string) => {
    if (sanitize) {
      newValue = sanitize(newValue);
    }

    setLocalValue(newValue);

    return newValue;
  };

  return (
    <FormGroup
      className={`${
        isRequired && !value ? "has-required-background" : "base-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}

      <WithTooltip
        shouldShowTooltip={true}
        content={isTooltipVisible ? value : ""}
      >
        <InputGroup
          autoComplete="new-password"
          inputRef={textInputRef}
          value={localValue}
          inputMode={inputMode}
          disabled={disabled}
          placeholder={placeholder}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onEnterKeyPress && onEnterKeyPress();
            }
          }}
          onChange={(e) => {
            if (inputMode === "numeric") {
              if (containsOnlyNumbers(e.target.value)) {
                setAndSanitize(e.target.value.replace(/\D/g, ""));
                onSubmit(e.target.value.replace(/\D/g, ""));
              }
            } else {
              const sanitized = setAndSanitize(e.target.value);
              onSubmit(sanitized);
            }
          }}
          maxLength={maxLength}
          leftIcon={leftIcon}
          readOnly={readOnly}
          type={maskValue ? "password" : "text"}
          rightElement={
            isSensitive ? (
              <Icon
                className="show-password-icon"
                icon={maskValue ? IconNames.EyeOpen : IconNames.EyeOff}
                onClick={() => setMaskValue(!maskValue)}
              />
            ) : undefined
          }
          onBlur={() => (isSensitive ? setMaskValue(true) : undefined)}
        />
      </WithTooltip>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) =>
            errorMessage.startsWith("href") ? (
              <a
                key={idx}
                href={`${variables.redirectUri}/${errorMessage.substring(5)}`}
                target="_blank"
                className="error-link"
                rel="noreferrer"
              >
                Click here
              </a>
            ) : (
              <p key={idx} className="error-message">
                {errorMessage}
              </p>
            )
          )}
        </>
      )}
    </FormGroup>
  );
};
