import {
  Option,
  EnumSingleSelectFieldValue,
  DateFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { MeetingTypes } from "./types/MeetingTypes";

const getTimeLabel = (date: Date): string => {
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
};

export const toTimeOptions = (
  unixTimes: number[]
): Option<EnumSingleSelectFieldValue>[] => {
  return unixTimes.map((timestamp) => {
    const date = new Date(timestamp);
    return {
      label: getTimeLabel(date),
      value: timestamp,
    };
  });
};

export const getDateDisplayText = (date: DateFieldValue): string => {
  if (!date) {
    return "N/A";
  }
  return new Date(date).toLocaleDateString("en-US", {
    weekday: "long",
    month: "short",
    day: "2-digit",
  });
};

export const getTimespanDisplayText = (
  startTime: EnumSingleSelectFieldValue,
  endTime: EnumSingleSelectFieldValue
): string => {
  const startTimeDisplayText = startTime
    ? getTimeLabel(new Date(startTime as number))
    : "N/A";
  const endTimeDisplayText = endTime
    ? getTimeLabel(new Date(endTime as number))
    : "N/A";
  return `${startTimeDisplayText} - ${endTimeDisplayText}`;
};

export const getDurationDisplayText = (
  startTime: EnumSingleSelectFieldValue,
  endTime: EnumSingleSelectFieldValue
): string => {
  if (!startTime || !endTime) {
    return "";
  }

  const start = new Date(startTime);
  const end = new Date(endTime);
  const durationMinutes = (end.getTime() - start.getTime()) / 60000;

  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;
  const duration = `${
    hours > 0 ? `${hours} hour${hours === 1 ? "" : "s"} ` : ""
  }${
    minutes > 0 || hours === 0
      ? `${minutes} minute${minutes === 1 ? "" : "s"}`
      : ""
  }`;
  return duration.trimEnd();
};

export const getMeetingTypeDisplayText = (
  meetingType: EnumSingleSelectFieldValue
): string => {
  switch (meetingType) {
    case MeetingTypes.ResidentInterview:
      return "Resident Interview";
    case MeetingTypes.FollowUp:
      return "Follow-Up";
    default:
      return "N/A";
  }
};

export const getResidentNameDisplayText = (
  firstName: TextFieldValue,
  lastName: TextFieldValue
): string => {
  if (!firstName && !lastName) {
    return "N/A";
  }
  return `${firstName} ${lastName}`;
};
