export const getFacilityBlockStartEndTimes = (
  date: Date,
  availableTimeSlot: string
) => {
  switch (availableTimeSlot) {
    case "Morning":
      return {
        startTime: new Date(date.setHours(9, 0, 0, 0)),
        endTime: new Date(date.setHours(13, 0, 0, 0)),
      };
    case "Afternoon":
      return {
        startTime: new Date(date.setHours(13, 0, 0, 0)),
        endTime: new Date(date.setHours(17, 0, 0, 0)),
      };
    case "Full Day":
      return {
        startTime: new Date(date.setHours(9, 0, 0, 0)),
        endTime: new Date(date.setHours(17, 0, 0, 0)),
      };
    default:
      return {
        startTime: new Date(date.setHours(9, 0, 0, 0)),
        endTime: new Date(date.setHours(13, 0, 0, 0)),
      };
  }
};

export const getScheduleResourceColor = (index: number) => {
  const colors = [
    "#5C9BD5", // Light Blue
    "#A9D18E", // Light Green
    "#F4B084", // Light Orange
    "#C6B1DE", // Light Purple
    "#FFD966", // Light Yellow
    "#FF6666", // Light Red
    "#92D050", // Light Teal
    "#B7B7B7", // Light Gray
    "#2F5597", // Dark Blue
    "#548235", // Dark Green
    "#C65911", // Dark Orange
    "#7030A0", // Dark Purple
    "#BF8F00", // Dark Yellow
    "#C00000", // Dark Red
    "#00B050", // Dark Teal
    "#7F7F7F", // Dark Gray
  ];

  return colors[index % colors.length];
};
