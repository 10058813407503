import { facilityApiClient } from "../../../lib/apiClients/facility/facilityApiClient";
// import { userApiClient } from "../../../lib/apiClients/user/userApiClient";
import { FacilityUser } from "../../../types/FacilityUser";
import { User, UserRoles } from "../../../types/User";
import { AddressFieldValue } from "../../Fields/types/fieldTypes";

export const handleFacilitiesMultiselectChange = (
  user: User,
  setUser: (u: User) => void
) => {
  if (user.corporateId && user.facilityIds.length > 0) {
    facilityApiClient
      .getFacilitiesByCorporateId(user.corporateId)
      .then(async (allFacilitiesForCorporate) => {
        // const isNewUser = !user.id;
        const facilityIds = user.facilityIds.reduce(
          (acc: string[], id, index) => {
            const facility = allFacilitiesForCorporate.find((f) => f.id === id);
            if (facility) {
              acc[index] = facility.id;
            }
            return acc;
          },
          []
        );
        const facilities = user.facilityIds.reduce(
          (acc: FacilityUser[], id) => {
            const facility = allFacilitiesForCorporate.find((f) => f.id === id);
            if (facility) {
              const existingFacility = user.facilities.find(
                (f) => f.facilityId === facility.id
              );
              const isNewFacility = !existingFacility;

              acc.push({
                facilityId: facility.id,
                userId: user.id,
                facilityName: facility.facilityName,
                isFacilityPOC: isNewFacility
                  ? false
                  : existingFacility.isFacilityPOC,
                isBillingPOC: isNewFacility
                  ? false
                  : existingFacility.isBillingPOC,
                corporateId: facility.corporateId,
                companyAddress: facility.facilityAddress,
                facilityPhone: facility.facilityPhone,
                facilityFax: facility.facilityFax,
              });
            }
            return acc;
          },
          []
        );

        const userPayload: User = { ...user, facilityIds, facilities };

        /***
         * Having this in place is causing issues with dialog rendering
         * After commenting out, the obvious behavior still seems to be functioning
         * Commenting it out
         */
        // if (!isNewUser) {
        //   await userApiClient.updateUser(user.id, userPayload);
        // }

        if (user.role === UserRoles.CorporateUser) {
          setUser({
            ...user,
            ...userPayload,
          });
        } else {
          setUser({
            ...user,
            ...userPayload,
            address: facilities[0].companyAddress,
            phoneNumber: facilities[0].facilityPhone
              ? facilities[0].facilityPhone
              : "",
            faxNumber: facilities[0].facilityFax
              ? facilities[0].facilityFax
              : "",
          });
        }
      });
  } else if (user.corporateId && user.facilityIds.length === 0) {
    setUser({
      ...user,
      facilities: [],
    });
  }
};

export const validateCorporateUserAndAssignCoporateValues = (
  user: User,
  corporateAddress: AddressFieldValue | undefined,
  corporatePhone: string | undefined
) => {
  const isCorporateUser = user.role === UserRoles.CorporateUser;

  const userAddress = isCorporateUser ? corporateAddress : undefined;

  const userPhoneNumber = isCorporateUser ? corporatePhone : undefined;

  return { userAddress, userPhoneNumber };
};
