import { Button, Card, Classes, MenuItemProps } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { applicationStore } from "../../../stores/ApplicationStore";
import { checklistItemStore } from "../../../stores/ChecklistItemStore";
import { User } from "../../../types/User";
import { AddButton } from "../../Buttons/AddButton";
import { ChecklistGrid } from "../../Checklist/ChecklistGrid";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import { WithTooltip } from "../../Tooltip/Tooltip";
import "../styles.scss";
import { ChecklistDocumentGrid } from "../../Checklist/ChecklistDocumentGrid";
import { ViewSelectionDropdown } from "../../ViewSelector/components/ViewSelectionDropdown";
import {
  ChecklistCardView,
  ChecklistItemStatuses,
} from "../../../types/Checklist";
import { Loading } from "../../Loading/Loading";
import { SwitchField } from "../../Fields/SwitchField/SwitchField";

export interface ChecklistCardPros {
  applicationId?: string;
  hasGeneratedChecklist: boolean;
  canGenerateChecklist: boolean;
  unresolvedChecklistGenerationDependencies?: string[];
  canUserGenerateChecklist: boolean;
  canSplitChecklist: boolean;
  canCreate: boolean;
  user?: User;
}

export const ChecklistCard: FC<ChecklistCardPros> = ({
  applicationId,
  hasGeneratedChecklist,
  canGenerateChecklist,
  unresolvedChecklistGenerationDependencies,
  canUserGenerateChecklist,
  canSplitChecklist,
  canCreate,
  user,
}) => {
  const [isBusyDoingWork, setIsBusyDoingWork] = useState<boolean>(false);
  const [showNA, setShowNA] = useState<boolean>(false);
  const [showRelatedAppChecklistDocs, setShowRelatedAppChecklistDocs] =
    useState<boolean>(false);

  const shouldShowGenerateChecklist =
    canUserGenerateChecklist && !hasGeneratedChecklist;
  const isGenerateChecklistEnabled =
    canUserGenerateChecklist && canGenerateChecklist;

  const menuItems: MenuItemProps[] = [
    {
      text: ChecklistCardView.Items,
      onClick: () => setviewTitle(ChecklistCardView.Items),
    },
    {
      text: ChecklistCardView.Documents,
      onClick: () => setviewTitle(ChecklistCardView.Documents),
    },
  ];

  const [viewTitle, setviewTitle] = useState<string>();

  useEffect(() => {
    setTimeout(() => {
      setviewTitle(ChecklistCardView.Items);
    }, 300);
  }, []);

  return (
    <Observer>
      {() => (
        <Card className="checklist-card">
          <div className="checklist-card__actions">
            {viewTitle && (
              <ViewSelectionDropdown
                menuItems={menuItems}
                viewTitle={viewTitle}
              />
            )}
            <div className="checklist-card__actions__align-left">
              {!shouldShowGenerateChecklist && canCreate && (
                <AddButton
                  text="New Checklist Item"
                  onClick={() => {
                    dialogsViewerStore.setIsChecklistItemCreationDialogOpen(
                      true
                    );
                  }}
                />
              )}
            </div>
            <div className="checklist-card__actions__align-right">
              {viewTitle == ChecklistCardView.Documents && (
                <SwitchField
                  type="Switch"
                  label="View Related Docs"
                  value={showRelatedAppChecklistDocs}
                  onSubmit={() => 0}
                  handleClick={() =>
                    setShowRelatedAppChecklistDocs(!showRelatedAppChecklistDocs)
                  }
                />
              )}
              {shouldShowGenerateChecklist && (
                <WithTooltip
                  content={
                    <>
                      The following data point are required to generate the
                      document checklist, please ensure you have input these
                      data points:
                      {unresolvedChecklistGenerationDependencies?.map(
                        (key, index) => (
                          <li key={index}>{key}</li>
                        )
                      )}
                    </>
                  }
                  shouldShowTooltip={!isGenerateChecklistEnabled}
                  className={Classes.FOCUS_DISABLED}
                >
                  <AddButton
                    text="Generate Checklist"
                    loading={isBusyDoingWork}
                    disabled={isBusyDoingWork || !isGenerateChecklistEnabled}
                    onClick={async () => {
                      if (!!applicationId) {
                        setIsBusyDoingWork(true);
                        await checklistItemStore.generateApplicationChecklist(
                          applicationId
                        );
                        applicationStore.markChecklistAsGenerated();
                        setIsBusyDoingWork(false);
                      }
                    }}
                  />
                </WithTooltip>
              )}
              {viewTitle === ChecklistCardView.Items && (
                <Button
                  text={showNA ? "Hide N/A" : "Show N/A"}
                  onClick={() => setShowNA(!showNA)}
                />
              )}
            </div>
          </div>
          {useMemo(() => {
            return (
              <section>
                {!viewTitle && (
                  <div>
                    <Loading />
                  </div>
                )}
                {viewTitle === ChecklistCardView.Documents &&
                  applicationStore.application?.id && (
                    <ChecklistDocumentGrid
                      applicationId={applicationStore.application.id}
                      showRelatedAppChecklistDocs={showRelatedAppChecklistDocs}
                    />
                  )}
                {viewTitle === ChecklistCardView.Items &&
                  applicationStore.application?.id && (
                    <ChecklistGrid
                      canSplitChecklist={canSplitChecklist}
                      checklistItems={
                        showNA
                          ? checklistItemStore.checklistItemsWithCommentCount
                          : checklistItemStore.checklistItemsWithCommentCount.filter(
                              (item) =>
                                item.status !==
                                ChecklistItemStatuses.NotApplicable
                            )
                      }
                      user={user}
                    />
                  )}
              </section>
            );
          }, [
            checklistItemStore.gridLastRefreshedAt,
            viewTitle,
            showNA,
            showRelatedAppChecklistDocs,
          ])}
        </Card>
      )}
    </Observer>
  );
};
