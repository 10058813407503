import { Button, Callout, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState, useEffect } from "react";
import { billingApiClient } from "../../lib/apiClients/billing/billingApiClient";
import { Billing } from "../../types/Billing";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { updateBillingEventFormSchema } from "../Forms/BillingForm/UpdateBillingEventForm";
import { BaseDialogProps } from "./types/types";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { AppToaster } from "../Toast/Toast";
import { invoiceApiClient } from "../../lib/apiClients/invoice/invoiceApiClient";
import { genericErrorMessage } from "../../stores/ErrorStore";

export interface BillingUpdateDialogProps extends BaseDialogProps {}

export const BillingUpdateDialog: React.FC<BillingUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isIssuingImmediateCredit, setIsIssuingImmediateCredit] =
    useState(false);
  const [isIssuingFutureCredit, setIsIssuingFutureCredit] = useState(false);
  const [verifyIfUninvoicedCreditExists, setVerifyIfUninvoicedCreditExists] =
    useState<boolean>(true);
  const [billing, setBilling] = useState<Billing | null>(null);
  const isInvoicedBilling = billing?.invoiceNumber != null;

  useEffect(() => {
    if (!!dialogsViewerStore.updateBillingDialogOpenOptions) {
      billingApiClient
        .getBillingById(
          dialogsViewerStore.updateBillingDialogOpenOptions.billingId
        )
        .then(setBilling);

      return () => {
        setBilling(null);
        setVerifyIfUninvoicedCreditExists(true);
      };
    }
  }, [dialogsViewerStore.updateBillingDialogOpenOptions]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Billing Event"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            billing && (
              <div>
                <Form
                  formId="update-billing-form"
                  value={billing as Billing}
                  formSchemaBuilder={updateBillingEventFormSchema}
                  onFormSubmit={async (billing) => {
                    setIsSaving(true);
                    await billingApiClient
                      .updateBilling(billing.id, billing)
                      .then(() => {
                        gridStore.refresh();
                        submitDialog();
                      })
                      .finally(() => {
                        setIsSaving(false);
                      });
                  }}
                />
              </div>
            )
          }
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        {isInvoicedBilling && !verifyIfUninvoicedCreditExists && (
          <Callout
            className="duplicate-credit-warning"
            intent={Intent.DANGER}
            title="An uninvoiced credit already exists for this application. If you still wish to proceed with issuing this credit, select the 'Issue Credit' button again."
            icon="warning-sign"
          />
        )}
        {isInvoicedBilling && (
          <Button
            minimal
            text="Issue Immediate Credit"
            loading={isIssuingImmediateCredit}
            onClick={async () => {
              if (billing) {
                setIsIssuingImmediateCredit(true);
                await billingApiClient
                  .createImmediateCredit(
                    billing.id,
                    verifyIfUninvoicedCreditExists
                  )
                  .then(() => {
                    closeDialog();
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Immediate Credit Issued.
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                    setVerifyIfUninvoicedCreditExists(true);
                    gridStore.refresh();
                  })
                  .catch((err) => {
                    if (err.response?.status === 422) {
                      setVerifyIfUninvoicedCreditExists(false);
                    }
                  })
                  .finally(() => {
                    setIsIssuingImmediateCredit(false);
                  });
              }
            }}
          />
        )}
        {isInvoicedBilling && (
          <Button
            minimal
            text="Issue Future Credit"
            loading={isIssuingFutureCredit}
            onClick={async () => {
              if (billing) {
                setIsIssuingFutureCredit(true);
                await billingApiClient
                  .createFutureCredit(
                    billing.id,
                    verifyIfUninvoicedCreditExists
                  )
                  .then(() => {
                    closeDialog();
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Future Credit Issued.
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                    setVerifyIfUninvoicedCreditExists(true);
                    gridStore.refresh();
                  })
                  .catch((err) => {
                    if (err.response?.status === 422) {
                      setVerifyIfUninvoicedCreditExists(false);
                    }
                  })
                  .finally(() => {
                    setIsIssuingFutureCredit(false);
                  });
              }
            }}
          />
        )}
        {!!billing?.canBeSingleExported && !isInvoicedBilling && (
          <Button
            minimal
            text="Generate Invoice"
            onClick={async () => {
              if (!billing.isCredit || !billing.parentInvoiceId) {
                dialogsViewerStore.setIsSetInvoiceNumberDialogOpen(true, {
                  billingIds: [billing.id],
                });
              }
              if (billing.isCredit && billing.parentInvoiceId) {
                setIsSaving(true);
                try {
                  await invoiceApiClient
                    .createInvoiceForSelectedCredit(
                      billing.id,
                      billing.parentInvoiceId
                    )
                    .then(() => {
                      AppToaster.show({
                        message: (
                          <div>
                            <h3>Success</h3>Invoice Generated
                          </div>
                        ),
                        intent: Intent.SUCCESS,
                      });
                      gridStore.refresh();
                    });
                  setIsSaving(false);
                  closeDialog();
                } catch {
                  AppToaster.show({
                    message: <div>{genericErrorMessage}</div>,
                    intent: Intent.DANGER,
                  });
                  setIsSaving(false);
                }
              }
            }}
          />
        )}
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="save-billing-button"
          isSaving={isSaving}
          formId="update-billing-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
