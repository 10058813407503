import { useEffect, useState } from "react";
import { Loading } from "../Loading/Loading";
import "./styles.scss";
import {
  HelpCenterTopic,
  HelpCenterTopicUpdateDTO,
  TopicSectionTypes,
} from "./types";
import { useHelpCenterTopic } from "./useHelpCenterTopic";
import Form from "../Forms";
import { updateHelpCenterTopicFormSchema } from "../Forms/HelpCenterTopicForm/HelpCenterTopicForm";
import { Button, Intent } from "@blueprintjs/core";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import classNames from "classnames";
import RelativityCard from "../Card/RelativityCard";
import Footer from "../Footer/Footer";
import { useActionExecuting } from "../ActtionExecuting/useActionExecuting";
import { Observer } from "mobx-react";
export interface HelpCenterTopicUpdateProps {
  sectionType: TopicSectionTypes;
  id: string;
  onAction?: (refresh: boolean) => void;
}

export const HelpCenterTopicUpdate: React.FC<HelpCenterTopicUpdateProps> = (
  props
) => {
  const [isLoadingTopic, setIsLoadingTopic] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [topic, setTopic] = useState<HelpCenterTopic>();

  const { getHelpCenterTopic, updateHelpCenterTopic, deleteHelpCenterTopic } =
    useHelpCenterTopic();
  const { isActionExecuting } = useActionExecuting([
    isLoadingTopic,
    isSaving,
    isDeleting,
  ]);

  useEffect(() => {
    loadTopic();
  }, []);

  const actionHandler = (refresh: boolean) => {
    props.onAction && props.onAction(refresh);
  };

  const deleteTopic = async () => {
    setIsDeleting(true);

    const success = await deleteHelpCenterTopic(
      props.sectionType,
      props.id
    ).finally(() => {
      setIsDeleting(false);
    });

    if (!success) {
      return;
    }

    // Call Action
    actionHandler(true);
  };

  const loadTopic = async () => {
    setIsLoadingTopic(true);

    const loadedTopic = await getHelpCenterTopic(props.sectionType, props.id);

    if (!loadedTopic) {
      props.onAction && props.onAction(false);
      return;
    }

    setTopic(loadedTopic);
    setIsLoadingTopic(false);
  };

  const updateTopic = async (
    helpCenterTopicUpdateDTO: HelpCenterTopicUpdateDTO
  ) => {
    setIsSaving(true);

    const updatedTopic = await updateHelpCenterTopic(
      props.sectionType,
      props.id,
      helpCenterTopicUpdateDTO
    ).finally(() => {
      setIsSaving(false);
    });

    if (!updatedTopic) {
      return;
    }

    setTopic(updatedTopic);

    // Call Action
    actionHandler(true);
  };

  return (
    <section className="helpcenter-topic-update">
      {isLoadingTopic && <Loading />}
      {!isLoadingTopic && (
        <>
          <RelativityCard>
            <Observer>
              {() => (
                <div className="helpcenter-topic-update-form-container">
                  <Form<HelpCenterTopicUpdateDTO>
                    formId="helpcenter-topic-update-form"
                    disableAllFields={isActionExecuting}
                    value={topic}
                    formSchemaBuilder={updateHelpCenterTopicFormSchema}
                    onFormSubmit={async (helpCenterTopicUpdateDTO) => {
                      await updateTopic(helpCenterTopicUpdateDTO);
                    }}
                  />
                </div>
              )}
            </Observer>
          </RelativityCard>
          <Footer
            className={classNames("helpcenter-topic-update-actions-container")}
          >
            <Button
              className="helpcenter-topic-update-action-delete"
              icon="trash"
              intent={Intent.DANGER}
              disabled={isActionExecuting}
              onClick={deleteTopic}
            />
            <Button
              minimal
              intent={Intent.DANGER}
              disabled={isActionExecuting}
              text="Cancel"
              onClick={() => actionHandler(false)}
            />
            <FormSubmitButton
              buttonId="helpcenter-topic-update-form-save-action"
              disabled={isActionExecuting}
              isSaving={isSaving}
              formId="helpcenter-topic-update-form"
              text="Save"
            />
          </Footer>
        </>
      )}
    </section>
  );
};
