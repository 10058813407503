import { Switch, FormGroup } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { SwitchFieldProps } from "../types/fieldTypes";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import "./styles.scss";

export const SwitchField: React.FC<SwitchFieldProps> = ({
  label,
  value,
  disabled,
  hidden,
  errorMessages,
  onSubmit,
  handleClick,
  description,
}) => {
  if (hidden) {
    return null;
  }

  const [localValue, setLocalValue] = React.useState<boolean | undefined>(
    value
  );

  useEffect(() => {
    onSubmit(localValue);
  }, [localValue]);

  return (
    <FormGroup className="switch-field">
      <Switch
        label={label as string}
        disabled={disabled}
        checked={localValue}
        onChange={() => {
          setLocalValue(!localValue);
        }}
        onClick={handleClick}
      />
      <SelectableLabel name="" description={description} />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
