import { Dialog } from "@blueprintjs/core";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";
import { ReportDetails } from "../Reports/ReportDetails";

export interface ReportDialogProps extends BaseDialogProps {
  id?: string;
  onClose?: (refresh: boolean) => void;
}
export const ReportDialog: React.FC<ReportDialogProps> = (props) => {
  const closeDialogHandler = (refresh: boolean) => {
    props.onClose && props.onClose(refresh);
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      hasBackdrop={false}
      portalClassName="mdr-dialog report-dialog-container"
      isCloseButtonShown={true}
      title={`${props.id ? "Update" : "Create"} Report`}
      canOutsideClickClose={false}
      onClose={() => closeDialogHandler(false)}
    >
      <div className="helpcenter-topic-dialog">
        <ReportDetails id={props.id} onClose={closeDialogHandler} />
      </div>
      <div className="relativity-dialog-footer-block"></div>
    </Dialog>
  );
};
