import { FormGroup } from "@blueprintjs/core";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
  ChangeEventArgs,
  SelectEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import classNames from "classnames";
import React from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import {
  AutocompleteFieldProps,
  AutocompleteFieldValue,
} from "../types/fieldTypes";
import "./styles.scss";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";

export const AutocompleteField: React.FC<AutocompleteFieldProps> = (props) => {
  if (props.hidden) {
    return null;
  }

  const autoCompleteRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [localValue, setLocalValue] = React.useState<string | undefined>(
    props.value
  );
  const [localData, setLocalData] = React.useState<any>();
  const [autoCompleteSettings, setAutoCompleteSettings] = React.useState<
    AutoCompleteModel & DefaultHtmlAttributes
  >();

  const isTooltipVisible = useOverflowTooltip(
    autoCompleteRef.current?.element,
    localData?.label
  );

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [autoCompleteRef]);

  React.useEffect(() => {
    if (initialized) {
      setLocalValue(props.value);
    }
  }, [props.value]);

  // Observe and Save Filter Settings
  React.useEffect(() => {
    if (initialized) {
      setup();
    }
  }, [initialized]);

  React.useEffect(() => {
    setup();
  }, [props.autoCompleteSettings]);

  React.useEffect(() => {
    if (initialized) {
      props.onSubmit(localData);
    }
  }, [localData]);

  // Events
  const showSearchPopup = () => {
    if (!!autoCompleteRef.current && initialized) {
      autoCompleteRef.current.showPopup();
    }
  };

  const handleSearchSelect = async (args: SelectEventArgs) => {
    if (!!autoCompleteRef.current && initialized) {
      setLocalData(args.itemData.value);
    }
  };

  const handleChange = async (args: ChangeEventArgs) => {
    if (!!autoCompleteRef.current && initialized && args.isInteracted) {
      setValue(autoCompleteRef.current.value);
    }
  };

  const setValue = (val: string | number | boolean | undefined) => {
    if (!val) {
      // If localData is undefined it never triggers the update, in which case we trigger in this clause
      if (!!localData) {
        setLocalData(undefined);
      } else {
        props.onSubmit(undefined);
      }
    } else {
      setLocalData(val as string);
    }
  };

  const defaultAutoCompleteSettings: AutoCompleteModel & DefaultHtmlAttributes =
    {
      autofill: true,
      showClearButton: false,
      highlight: true,
      allowCustom: true,
      showPopupButton: true,
      //blur: handleBlur,
      // Inmutable
      select: handleSearchSelect,
      focus: showSearchPopup,
      onClick: showSearchPopup,
      change: handleChange,
    };

  // Setup
  const setup = async () => {
    if (!initialized) {
      return;
    }

    // Setup Properties
    const settings = {
      ...defaultAutoCompleteSettings,
      ...(props.autoCompleteSettings || {}),
    } as AutoCompleteModel & DefaultHtmlAttributes;

    settings.dataSource = props.optionValues.map((x) => x.label);
    settings.placeholder = props.placeholder || "Type to add new…";
    settings.value = props.value;

    setAutoCompleteSettings(settings);
    setReady(true);
  };

  return (
    <FormGroup
      className={classNames(
        `${
          props.isRequired && !localValue
            ? "has-required-background"
            : "autocomplete-field"
        }`
      )}
    >
      {props.label && (
        <SelectableLabel name={props.label} description={props.description} />
      )}
      <div className="autocomplete-toolbar">
        {ready && autoCompleteSettings?.dataSource && (
          <div className="autocomplete-input-container">
            <WithTooltip
              shouldShowTooltip={isTooltipVisible}
              content={(isTooltipVisible && localValue?.toString()) || ""}
            >
              <AutoCompleteComponent
                ref={autoCompleteRef}
                id="autocomplete-search-list"
                className="autocomplete-input"
                onChange={(e: { value: AutocompleteFieldValue }) => {
                  // Setting local value to detect change faster than rerendering allowing the UI to adjust styles like Require as it occurs
                  setLocalValue(e.value);
                }}
                readonly={props.disabled}
                {...autoCompleteSettings}
              />
            </WithTooltip>
          </div>
        )}
      </div>
      {props.errorMessages && (
        <>
          {props.errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
