import { ReportCreate } from "./ReportCreate";
import { ReportUpdate } from "./ReportUpdate";
import "./styles.scss";

export interface ReportDetailsProps {
  id?: string;
  onClose?: (refresh: boolean) => void;
}

export const ReportDetails: React.FC<ReportDetailsProps> = (props) => {
  const closeDialogHandler = (refresh: boolean) => {
    props.onClose && props.onClose(refresh);
  };

  return (
    <section className="report-details">
      {!props.id && <ReportCreate onAction={closeDialogHandler} />}
      {props.id && <ReportUpdate id={props.id} onAction={closeDialogHandler} />}
    </section>
  );
};
