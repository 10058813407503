import { FC, useEffect, useState } from "react";
import RelativityCard from "../Card/RelativityCard";
import {
  AsyncAutoCompleteField,
  AsyncAutoCompleteFieldProps,
  EnumSingleSelectField,
} from "@ucl/library";
import { DocumentFilingComponentReviewItem } from "./DocumentFilingComponentReviewItem";
import classNames from "classnames";
import { Document, DocumentVerifyDTO } from "../../types/Document";
import {
  AllFIARequestODataReadDTO,
  ApplicationFilingODataReadDTO,
  DocumentFilingAssociatedChecklistItem,
  FIARequestFilingProcessDocumentResult,
} from "./types";
import {
  faFloppyDisk,
  faInfoCircle,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIARequestInfo } from "../FIARequests/FIARequestInfo";
import { fiaRequestApiClient } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import {
  FIARequest,
  FIARequestFollowUpActionTypes,
  FIARequestFollowUpReasonTypes,
} from "../FIARequests/configurations/types";
import { FIARequestDetails } from "../FIARequests/FIARequestDetails";
import { useFiaRequestVerify } from "../Checklist/Verify/useFIARequestVerify";
import { FilenameField } from "../Fields/FilenameField/FilenameField";

export interface FIAFilingFormCardProps {
  document?: Document;
  newDocumentName?: string;
  setNewDocumentName: (newDocumentName: string) => void;
  fiaRequestResult?: AllFIARequestODataReadDTO;
  fiaProcessDocumentResult?: FIARequestFilingProcessDocumentResult;
  associatedChecklistItem?: DocumentFilingAssociatedChecklistItem;
  asyncAutoCompleteProps: AsyncAutoCompleteFieldProps;
  searchValue?: string;
  autoFillData?: AllFIARequestODataReadDTO[] | ApplicationFilingODataReadDTO[];
  isSaving?: boolean;
  fiaRequestVerifyDTO: DocumentVerifyDTO;
  setFiaRequestVerifyDTO: (fiaRequestVerifyDTO: DocumentVerifyDTO) => void;
}

export const FIAFilingFormCard: FC<FIAFilingFormCardProps> = (props) => {
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );
  const [isDocumentRenameActive, setIsDocumentRenameActive] = useState(false);

  const {
    getFiaRequestFollowUpReasonOptions,
    getFiaRequestFollowUpSecondaryReasonOptions,
    getFIAFollowUpActions,
  } = useFiaRequestVerify();

  useEffect(() => {
    if (props.fiaRequestResult?.applicationId && props.fiaRequestResult?.id) {
      fiaRequestApiClient
        .getRequest(
          props.fiaRequestResult.applicationId,
          props.fiaRequestResult.id
        )
        .then((fiaRequest) => setFiaRequest(fiaRequest));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [props.fiaRequestResult]);

  return (
    <>
      <RelativityCard headerContent={"FIA Filing"}>
        <div className="information">
          <FontAwesomeIcon size="sm" icon={faInfoCircle} />
          {props.fiaProcessDocumentResult?.fiaRequestData?.id
            ? `The following file has been identified with the same FIA request details. If this is incorrect, use the search to locate a different file.`
            : `To file your document, please search by Resident Name, Institution
        Name, or Request Number in the Search Bar below.`}
        </div>

        <div className="fiafiling-content-form-search">
          <AsyncAutoCompleteField
            {...props.asyncAutoCompleteProps}
            value={props.fiaRequestResult?.id || ""}
            disabled={props.isSaving}
            {...((props.autoFillData || [])?.length > 0
              ? { autoFillData: props.autoFillData }
              : { searchValue: props.searchValue })}
          />
        </div>
        <div className="fiafiling-content-form-review">
          <ul>
            {isDocumentRenameActive && (
              <li>
                <FontAwesomeIcon
                  className="clickable"
                  size="lg"
                  icon={faFloppyDisk}
                  onClick={() => setIsDocumentRenameActive(false)}
                />
                <FilenameField
                  isRequired={true}
                  readOnly={false}
                  disabled={false}
                  maxLength={250}
                  type={"Filename"}
                  value={props.newDocumentName || ""}
                  onSubmit={function (value: unknown): void {
                    props.setNewDocumentName(value as string);
                  }}
                  width="90%"
                />
              </li>
            )}

            {!isDocumentRenameActive && (
              <li
                className={classNames({
                  "fiafiling-content-form-review-disabled": !props.document?.id,
                })}
              >
                <DocumentFilingComponentReviewItem
                  title="Document"
                  content={
                    props.newDocumentName ||
                    props.document?.originalFileName ||
                    "-"
                  }
                  icon={faPencil}
                  iconClick={() => {
                    setIsDocumentRenameActive(true);
                  }}
                />
              </li>
            )}

            <li
              className={classNames({
                "fiafiling-content-form-review-disabled":
                  !props.fiaRequestResult?.id,
              })}
            >
              <DocumentFilingComponentReviewItem
                title="FIA Request"
                content={props.fiaRequestResult?.displayTitle || "-"}
              />
            </li>
            <li
              className={classNames({
                "fiafiling-content-form-review-disabled":
                  !props.associatedChecklistItem?.id,
              })}
            >
              <DocumentFilingComponentReviewItem
                title="Checklist Item"
                content={props.associatedChecklistItem?.displayName || "-"}
              />
            </li>
          </ul>
        </div>
      </RelativityCard>
      {props.fiaRequestResult && fiaRequest && (
        <>
          <FIARequestInfo fiaRequest={fiaRequest} numberOfColumns={3} />
          <FIARequestDetails
            fiaRequest={fiaRequest}
            applicationId={props.fiaRequestResult.applicationId}
            errors={{}}
            setFIARequest={setFiaRequest}
          />
          <div className="fia-follow-up-actions">
            <EnumSingleSelectField
              type="EnumSingleSelect"
              label="Complete or Resend FIA Request"
              optionValues={getFIAFollowUpActions([fiaRequest])}
              value={props.fiaRequestVerifyDTO.fiaRequestAction}
              onSubmit={(fiaRequestAction) => {
                props.setFiaRequestVerifyDTO({
                  ...props.fiaRequestVerifyDTO,
                  fiaRequestAction:
                    fiaRequestAction as FIARequestFollowUpActionTypes,
                  followUpReason:
                    fiaRequestAction ===
                    FIARequestFollowUpActionTypes.MarkComplete
                      ? undefined
                      : props.fiaRequestVerifyDTO.followUpReason,
                });
              }}
              isRequired={true}
            />
            {props.fiaRequestVerifyDTO.fiaRequestAction ===
              FIARequestFollowUpActionTypes.Resend && (
              <EnumSingleSelectField
                className="follow-up-reason-field"
                type="EnumSingleSelect"
                label="Follow Up Reason"
                optionValues={getFiaRequestFollowUpReasonOptions()}
                value={props.fiaRequestVerifyDTO.followUpReason}
                onSubmit={(followUpReason) =>
                  props.setFiaRequestVerifyDTO({
                    ...props.fiaRequestVerifyDTO,
                    followUpReason: Number(followUpReason),
                  })
                }
                isRequired={true}
              />
            )}
            {props.fiaRequestVerifyDTO.followUpReason ===
              FIARequestFollowUpReasonTypes.PreviousRequestHasBeenCorrected && (
              <EnumSingleSelectField
                className="follow-up-reason-field"
                type="EnumSingleSelect"
                label="Follow Up Secondary Reason"
                optionValues={getFiaRequestFollowUpSecondaryReasonOptions()}
                value={props.fiaRequestVerifyDTO.followUpSecondaryReason}
                onSubmit={(followUpSecondaryReason) =>
                  props.setFiaRequestVerifyDTO({
                    ...props.fiaRequestVerifyDTO,
                    followUpSecondaryReason: Number(followUpSecondaryReason),
                  })
                }
                isRequired={true}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
