import { NonIdealState } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { Application } from "../../../../../../types/Application";
import {
  Income,
  IncomeOwnerTypes,
} from "../../../../../../types/Application/Income";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import { newIncome } from "../../../../../Forms/ApplicationForm/Income";
import { AddButton } from "../../../../../Buttons/AddButton";
import { IncomePageCard } from "./IncomePageCard";
import _, { orderBy } from "lodash";

export interface IncomesPageCardProps {
  application?: Application;
  canCreate: boolean;
  canEdit: boolean;
  createAndUpdateIncomes: () => Promise<void>;
  deleteIncome: (income: Income) => Promise<void>;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  defaultIncomeOwner?: IncomeOwnerTypes;
}

const newIncomeId = "new-income";
const sectionTitle = "Incomes";
const sectionHeader = "Additional Income";
export const IncomesPageCard: FC<IncomesPageCardProps> = (props) => {
  const [localIncomes, setLocalIncomes] = useState<Income[]>(
    props.application?.incomes || []
  );

  const hasNewIncome = localIncomes.some((income) => income.id === newIncomeId);
  const [isAddingAction, setIsAddingAction] = useState<boolean>(false);

  useEffect(() => {
    setLocalIncomes(props.application?.incomes || []);
  }, [props.application?.incomes]);

  return (
    <div>
      <RelativityCard
        headerContent={
          <div className="owned-entity-list-header">
            <SectionHeader type="Section" label={sectionHeader} />
            {props.canCreate && (
              <AddButton
                loading={isAddingAction}
                disabled={
                  props.shouldDisableForm || props.formIsSaving || hasNewIncome
                }
                text={`Add ${sectionHeader}`}
                onClick={() => {
                  props.setShouldDisableForm(true);
                  setIsAddingAction(true);
                  const income = {
                    ...newIncome,
                    id: newIncomeId,
                    incomeOwner: props.defaultIncomeOwner,
                  } as Income;

                  setLocalIncomes([...localIncomes, income]);
                  props.setShouldDisableForm(false);
                  setIsAddingAction(false);
                }}
              />
            )}
          </div>
        }
      >
        {localIncomes?.length <= 0 && (
          <NonIdealState title="No Information Available" />
        )}
        {localIncomes?.length > 0 &&
          orderBy(localIncomes, ["createdAt"], ["desc"]).map(
            (income, index) => {
              return (
                <IncomePageCard
                  key={index}
                  income={income}
                  localIncomes={localIncomes}
                  setLocalIncomes={setLocalIncomes}
                  canCreate={props.canCreate}
                  canEdit={props.canEdit}
                  createAndUpdateIncomes={props.createAndUpdateIncomes}
                  deleteIncome={props.deleteIncome}
                  shouldDisableForm={props.shouldDisableForm}
                  setShouldDisableForm={props.setShouldDisableForm}
                  formIsSaving={props.formIsSaving}
                  setFormIsSaving={props.setFormIsSaving}
                  index={index}
                  isAddingAction={isAddingAction}
                  title={sectionTitle}
                  applicationId={props.application?.id}
                />
              );
            }
          )}
      </RelativityCard>
    </div>
  );
};
