import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { billingApiClient } from "../../lib/apiClients/billing/billingApiClient";
import { BillingRule } from "../../types/BillingRule";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { billingRuleFormSchema } from "../Forms/BillingForm/BillingRuleForm";
import { BaseDialogProps } from "./types/types";
import { billingRuleGridStore } from "../Billing/store/BillingRuleGridStore";

export interface BillingRuleUpdateDialogProps extends BaseDialogProps {
  billingRule?: Partial<BillingRule>;
}

export const BillingRuleUpdateDialog: React.FC<
  BillingRuleUpdateDialogProps
> = ({ billingRule, isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Billing Rule"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<BillingRule>
              formId="update-billing-form"
              value={billingRule as BillingRule}
              formSchemaBuilder={billingRuleFormSchema}
              onFormSubmit={async (billingRule) => {
                setIsSaving(true);
                await billingApiClient
                  .updateBillingRule(billingRule.id, billingRule)
                  .then(() => {
                    submitDialog();
                    billingRuleGridStore.refresh();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="update-billing-button"
          isSaving={isSaving}
          formId="update-billing-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
