import { Button, Card, Intent } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { MedicalCoverage } from "../../../../types/Application/MedicalCoverage";
import { AddButton } from "../../../Buttons/AddButton";
import RelativityCard from "../../../Card/RelativityCard";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import { newMedicalCoverage } from "../../../Forms/ApplicationForm/MedicalCoverage";
import "../../styles.scss";
import { MedicalCoverageFormCard } from "./MedicalCoverageFormCard";
import _, { orderBy } from "lodash";

export interface MedicalCoveragesFormCardProps {
  medicalCoverages?: MedicalCoverage[];
  canEdit?: boolean;
  createAndUpdateMedicalCoverages: () => Promise<void>;
  deleteMedicalCoverage?: (medicalCoverage: MedicalCoverage) => Promise<void>;
  getMedicalCoverageComputedState?: (
    value: Partial<MedicalCoverage>
  ) => Promise<Partial<MedicalCoverage>>;
  showCancelButton: boolean;
  showAddButton: boolean;
  onCancel?: () => void;
  applicationId?: string;
}

export const newMedicalCoverageId = "new-medical-coverage";
export const MedicalCoveragesFormCard: FC<MedicalCoveragesFormCardProps> = ({
  medicalCoverages,
  canEdit,
  createAndUpdateMedicalCoverages,
  deleteMedicalCoverage,
  getMedicalCoverageComputedState,
  showCancelButton,
  onCancel,
  applicationId,
  showAddButton,
}) => {
  const [localMedicalCoverages, setLocalMedicalCoverages] = useState<
    MedicalCoverage[]
  >(medicalCoverages || []);

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  const hasNewMedicalCoverage = localMedicalCoverages.some(
    (medicalCoverage) => medicalCoverage.id === newMedicalCoverageId
  );

  useEffect(() => {
    setLocalMedicalCoverages(medicalCoverages || []);
  }, [medicalCoverages]);

  return (
    <Card>
      <RelativityCard
        headerContent={
          <div className="owned-entity-header medical-coverage-header">
            <SectionHeader
              label="Medical Coverage"
              type="Section"
              inAction={formIsSaving}
            />
            {!!showCancelButton && (
              <Button
                minimal
                intent={Intent.DANGER}
                text="Cancel"
                onClick={onCancel}
              />
            )}
            {!showCancelButton && showAddButton && (
              <AddButton
                loading={isAdding}
                disabled={
                  shouldDisableForm ||
                  formIsSaving ||
                  hasNewMedicalCoverage ||
                  !canEdit
                }
                text="Add Medical Coverage"
                onClick={() => {
                  setShouldDisableForm(true);
                  setIsAdding(true);
                  setLocalMedicalCoverages([
                    ...localMedicalCoverages,
                    {
                      ...newMedicalCoverage,
                      id: newMedicalCoverageId,
                    } as MedicalCoverage,
                  ]);
                  setShouldDisableForm(false);
                  setIsAdding(false);
                }}
              />
            )}
          </div>
        }
      >
        {orderBy(localMedicalCoverages, ["createdAt"], ["desc"]).map(
          (medicalCoverage, index) => {
            return (
              <MedicalCoverageFormCard
                key={index}
                index={index}
                medicalCoverage={medicalCoverage}
                localMedicalCoverages={localMedicalCoverages}
                setLocalMedicalCoverages={setLocalMedicalCoverages}
                createAndUpdateMedicalCoverages={
                  createAndUpdateMedicalCoverages
                }
                deleteMedicalCoverage={deleteMedicalCoverage}
                getMedicalCoverageComputedState={
                  getMedicalCoverageComputedState
                }
                canEdit={canEdit}
                formIsSaving={formIsSaving}
                setFormIsSaving={setFormIsSaving}
                shouldDisableForm={shouldDisableForm}
                setShouldDisableForm={setShouldDisableForm}
                applicationId={applicationId}
              />
            );
          }
        )}
      </RelativityCard>
    </Card>
  );
};
