import {
  HelpCenterSearchResponse,
  HelpCenterTopic,
  HelpCenterTopicCreateDTO,
  HelpCenterTopicState,
  HelpCenterTopicUpdateDTO,
  TopicSectionTypes,
  topicSectionTypeConfig,
} from "../../../components/HelpCenter/types";
import baseApiClient from "../baseApiClient";

class HelpCenterTopicApiClient {
  getHelpCenterTopic = async (sectionType: TopicSectionTypes, id: string) => {
    return baseApiClient.get<HelpCenterTopic>(
      `help-center/${topicSectionTypeConfig[
        sectionType
      ].shortTitle.toLowerCase()}/${id}`
    );
  };

  getHelpCenterTopicStatesByType = async (
    sectionType?: TopicSectionTypes,
    onlyFeedAllowed?: boolean
  ) => {
    return baseApiClient.get<HelpCenterTopicState[]>(
      `help-center/${topicSectionTypeConfig[
        sectionType ?? TopicSectionTypes.STR
      ].shortTitle.toLowerCase()}/states?onlyFeedAllowed=${
        onlyFeedAllowed ?? false
      }`
    );
  };

  getHelpCenterTopicsByType = async (
    sectionType: TopicSectionTypes,
    usState?: number
  ) => {
    const params = usState !== undefined ? { usState } : {};

    return baseApiClient.get<HelpCenterTopic[]>(
      `help-center/${topicSectionTypeConfig[
        sectionType
      ].shortTitle.toLowerCase()}`,
      {
        params,
      }
    );
  };

  searchHelpCenterTopics = async (search: string) => {
    return baseApiClient.get<HelpCenterSearchResponse[]>("help-center/search", {
      params: {
        prompt: search,
      },
    });
  };

  createHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    helpCenterTopicCreateDTO: HelpCenterTopicCreateDTO
  ) => {
    return baseApiClient.post<HelpCenterTopic>(
      `help-center/${topicSectionTypeConfig[
        sectionType
      ].shortTitle.toLowerCase()}`,
      helpCenterTopicCreateDTO
    );
  };

  updateHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    id: string,
    helpCenterTopicUpdateDTO: HelpCenterTopicUpdateDTO
  ) => {
    return baseApiClient.put<HelpCenterTopic>(
      `help-center/${topicSectionTypeConfig[
        sectionType
      ].shortTitle.toLowerCase()}/${id}`,
      helpCenterTopicUpdateDTO
    );
  };

  deleteHelpCenterTopic = async (
    sectionType: TopicSectionTypes,
    id: string
  ) => {
    return baseApiClient.delete<void>(
      `help-center/${topicSectionTypeConfig[
        sectionType
      ].shortTitle.toLowerCase()}/${id}`
    );
  };
}

export const helpCenterTopicApiClient = new HelpCenterTopicApiClient();
