import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import { Report } from "../../Reports/types";

const modelName = "report";

const reportFormSchemaFields = {
  title: (
    onValueChange: (field: {}) => void,
    report: Report,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "title"),
      errorMessages: get(errors, "title"),
      value: report.title,
      width: "49%",
      onSubmit: (title) => {
        onValueChange({ ...report, title });
      },
      isRequired: true,
    } as AllFields;
  },
  reportRoles_AsList: (
    onValueChange: (field: {}) => void,
    report: Report,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "reportRoles_AsList"),
      value: report.reportRoles_AsList,
      width: "49%",
      errorMessages: get(errors, "reportRoles_AsList"),
      onSubmit: (reportRoles_AsList: string[]) => {
        onValueChange({ ...report, reportRoles_AsList });
      },
    } as AllFields;
  },
  externalLink: (
    onValueChange: (field: {}) => void,
    report: Report,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "externalLink"),
      errorMessages: get(errors, "externalLink"),
      isRequired: true,
      value: report.externalLink,
      onSubmit: (externalLink) => {
        onValueChange({ ...report, externalLink });
      },
    } as AllFields;
  },
};

export const createReportFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const topic = form as Report;
  const result: AllFields[] = [
    reportFormSchemaFields.title(onValueChange, topic, errors),
    reportFormSchemaFields.reportRoles_AsList(onValueChange, topic, errors),
    reportFormSchemaFields.externalLink(onValueChange, topic, errors),
  ];

  return result;
};

export const updateReportFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const topic = form as Report;
  const result: AllFields[] = [
    reportFormSchemaFields.title(onValueChange, topic, errors),
    reportFormSchemaFields.reportRoles_AsList(onValueChange, topic, errors),
    reportFormSchemaFields.externalLink(onValueChange, topic, errors),
  ];

  return result;
};
