import "./styles.scss";
import classNames from "classnames";

export interface FooterProps {
  className?: string;
  children?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <div className={classNames(`relativity-footer`, props.className)}>
      <div className="relativity-footer-children">{props.children}</div>
    </div>
  );
};

export default Footer;
