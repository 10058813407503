import { useEffect, useState } from "react";
import { documentApiClient } from "../../../lib/apiClients/document/documentApiClient";
import { Document } from "../../../types/Document";

export const useDocumentStatementsReceived = (
  documentId: string | undefined
) => {
  const [document, setDocument] = useState<Document | undefined>(undefined);

  useEffect(() => {
    documentId &&
      documentApiClient.getDocument(documentId).then((doc) => setDocument(doc));

    return () => {
      setDocument(undefined);
    };
  }, []);
  return { document };
};
