import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { DateInformation } from "../../../types/Application/DateInformation";
import {
  dateBuilderAddMonthsToTodaysDate,
  dateBuilderAddYearsToTodaysDate,
} from "../../../utils/date";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import {
  Application,
  ApplicationStatuses,
  InternalStatuses,
} from "../../../types/Application";
import { userStore } from "../../../stores/UserStore";
import { applicationStore } from "../../../stores/ApplicationStore";

export const newDateInformation: DateInformation = {
  admissionDate: undefined,
  coveragePossibleDate: undefined,
  medicaidNeededDate: undefined,
  applicationDate: undefined,
  repHandoffDate: undefined,
  medicaidEligibleDate: undefined,
  dueDate: undefined,
  approvedDate: undefined,
  closedDate: undefined,
  nextRecertDate: undefined,
  monitorStartDate: undefined,
  monitorEndDate: undefined,
  hearingDate: undefined,
  interviewDate: undefined,
};

// Helper functions for Medicaid Needed Date Validation and Tooltip
const recertificationProductTypes = [6, 10];
const medicaidNeededDateDescription = (
  application: Application,
  productTypes = recertificationProductTypes
) => {
  return application.productType &&
    productTypes.includes(application.productType)
    ? "Must be Less Than or Equal to 18 Months in the Future"
    : "Must be Less Than or Equal to 2 Months in the Future";
};

const medicaidNeededMaxDate = (
  application: Application,
  productTypes = recertificationProductTypes
) => {
  return application.productType &&
    productTypes.includes(application.productType)
    ? dateBuilderAddMonthsToTodaysDate(18)
    : dateBuilderAddMonthsToTodaysDate(2);
};

// Date Information - Form
export const dateInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const dateInformation = form as DateInformation;
  const application = form as Application;

  return [
    {
      type: "Section",
      label: "Date Information",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "admissionDate"),
      value: dateInformation.admissionDate,
      onSubmit: (admissionDate) => {
        onValueChange({ admissionDate });
      },
      isRequired: true,
      errorMessages: get(errors, "admissionDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "medicaidNeededDate"),
      description: medicaidNeededDateDescription(application),
      value: dateInformation.medicaidNeededDate,
      onSubmit: (medicaidNeededDate) => {
        onValueChange({ medicaidNeededDate });
      },
      disabled:
        (application.status === ApplicationStatuses.interviewing &&
          !userStore.user?.canEditMedicaidNeededDateOnlyInInterviewingStatus) ||
        (application.internalStatus === InternalStatuses.Closed &&
          !userStore.user?.canEditMedicaidNeededDateOnClosedApplication),
      isRequired: true,
      errorMessages: get(errors, "medicaidNeededDate"),
      maxDate: medicaidNeededMaxDate(application),
      minDate: dateBuilderAddYearsToTodaysDate(-5),
    } as AllFields,
  ];
};

// Date Information - Page
export const dateInformationPageFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const dateInformation = form as DateInformation;
  const application = form as Application;

  const canEdit =
    !!userStore.user?.canEditApplicationPageDateInformation &&
    !!applicationStore.applicationCanBeEdited;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "admissionDate"),
      value: dateInformation.admissionDate,
      onSubmit: (admissionDate) => {
        onValueChange({ admissionDate });
      },
      isRequired: true,
      width: "24%",
      disabled: !canEdit,
      errorMessages: get(errors, "admissionDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "repHandoffDate"),
      value: dateInformation.repHandoffDate,
      onSubmit: (repHandoffDate) => {
        onValueChange({ repHandoffDate });
      },
      errorMessages: get(errors, "repHandoffDate"),
      width: "24%",
      disabled: !userStore.user?.canEditRepHandoffDate || !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "monitorStartDate"),
      value: dateInformation.monitorStartDate,
      onSubmit: (monitorStartDate) => {
        onValueChange({ monitorStartDate });
      },
      errorMessages: get(errors, "monitorStartDate"),
      width: "24%",
      disabled: !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "monitorEndDate"),
      value: dateInformation.monitorEndDate,
      onSubmit: (monitorEndDate) => {
        onValueChange({ monitorEndDate });
      },
      errorMessages: get(errors, "monitorEndDate"),
      disabled: !userStore.user?.canEditMonitorEndDate || !canEdit,
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "medicaidNeededDate"),
      description: medicaidNeededDateDescription(application),
      value: dateInformation.medicaidNeededDate,
      onSubmit: (medicaidNeededDate) => {
        onValueChange({ medicaidNeededDate });
      },
      isRequired: true,
      errorMessages: get(errors, "medicaidNeededDate"),
      maxDate: medicaidNeededMaxDate(application),
      minDate: dateBuilderAddYearsToTodaysDate(-5),
      width: "24%",
      disabled:
        !userStore.user?.canEditMedicaidNeededDateInAnyStatus ||
        !canEdit ||
        (application.status === ApplicationStatuses.interviewing &&
          !userStore.user?.canEditMedicaidNeededDateOnlyInInterviewingStatus) ||
        (application.internalStatus === InternalStatuses.Closed &&
          !userStore.user?.canEditMedicaidNeededDateOnClosedApplication),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "medicaidEligibleDate"),
      value: dateInformation.medicaidEligibleDate,
      onSubmit: (medicaidEligibleDate) => {
        onValueChange({ medicaidEligibleDate });
      },
      errorMessages: get(errors, "medicaidEligibleDate"),
      disabled: !canEdit,
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "coveragePossibleDate"),
      value: dateInformation.coveragePossibleDate,
      onSubmit: (coveragePossibleDate) => {
        onValueChange({ coveragePossibleDate });
      },
      isRequired: true,
      errorMessages: get(errors, "coveragePossibleDate"),
      disabled: !canEdit,
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "dueDate"),
      value: dateInformation.dueDate,
      onSubmit: (dueDate) => {
        onValueChange({ dueDate });
      },
      errorMessages: get(errors, "dueDate"),
      disabled: !canEdit,
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "approvedDate"),
      value: dateInformation.approvedDate,
      onSubmit: (approvedDate) => {
        onValueChange({ approvedDate });
      },
      errorMessages: get(errors, "approvedDate"),
      disabled:
        !canEdit ||
        (application.isApplicationClosed &&
          !userStore.user?.canEditClosedApplicationApprovedDate),
      width: "24%",
      // Date must be greater or equal to current date
      maxDate: new Date(),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "closedDate"),
      value: dateInformation.closedDate,
      onSubmit: (closedDate) => {
        onValueChange({ closedDate });
      },
      errorMessages: get(errors, "closedDate"),
      disabled: !userStore.user?.canEditClosedDate || !canEdit,
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "nextRecertDate"),
      value: dateInformation.nextRecertDate,
      onSubmit: (nextRecertDate) => {
        onValueChange({ nextRecertDate });
      },
      errorMessages: get(errors, "nextRecertDate"),
      width: "24%",
      disabled: !userStore.user?.canEditNextRecertDateWhileClosed,
      maxDate: new Date(dateBuilderAddMonthsToTodaysDate(18)),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "deniedDate"),
      value: dateInformation.deniedDate,
      onSubmit: (deniedDate) => {
        onValueChange({ deniedDate });
      },
      errorMessages: get(errors, "deniedDate"),
      disabled: !canEdit,
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "addOnServiceDate"),
      value: dateInformation.addOnServiceDate,
      onSubmit: (addOnServiceDate) => {
        onValueChange({ addOnServiceDate });
      },
      errorMessages: get(errors, "addOnServiceDate"),
      disabled: !canEdit,
      width: "24%",
      hidden: !dateInformation.isEligibleForAddOnService,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "recertRequired"),
      value: dateInformation.recertRequired,
      onSubmit: (recertRequired) => {
        onValueChange({ recertRequired });
      },
      disabled: !userStore.user?.canEditRecertNeededWhileClosed,
      errorMessages: get(errors, "recertRequired"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "deceasedDate"),
      value: dateInformation.deceasedDate,
      hidden: !dateInformation.isDeceased,
      width: "24%",
      onSubmit: (deceasedDate) => {
        onValueChange({ deceasedDate });
      },
      isRequired: true,
      disabled: !canEdit,
      errorMessages: get(errors, "deceasedDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hospiceEffectiveDate"),
      value: dateInformation.hospiceEffectiveDate,
      hidden: !dateInformation.hasHospice,
      width: "24%",
      onSubmit: (hospiceEffectiveDate) => {
        onValueChange({ hospiceEffectiveDate });
      },
      disabled: !canEdit,
      errorMessages: get(errors, "hospiceEffectiveDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "dischargedDate"),
      value: dateInformation.dischargedDate,
      hidden: !dateInformation.isDischarged,
      width: "24%",
      onSubmit: (dischargedDate) => {
        onValueChange({ dischargedDate });
      },
      isRequired: true,
      errorMessages: get(errors, "dischargedDate"),
      disabled: !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hearingDate"),
      value: dateInformation.hearingDate,
      useTimePrecision: true,
      width: "24%",
      onSubmit: (hearingDate) => {
        onValueChange({ hearingDate });
      },
      errorMessages: get(errors, "hearingDate"),
      disabled: !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "interviewDate"),
      value: dateInformation.interviewDate,
      useTimePrecision: true,
      width: "24%",
      onSubmit: (interviewDate) => {
        onValueChange({ interviewDate });
      },
      errorMessages: get(errors, "interviewDate"),
      disabled: !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "extensionRequestDate"),
      value: dateInformation.extensionRequestDate,
      width: "24%",
      onSubmit: (extensionRequestDate) => {
        onValueChange({ extensionRequestDate });
      },
      errorMessages: get(errors, "extensionRequestDate"),
      disabled: !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "qcAuditDate"),
      value: dateInformation.qcAuditDate,
      width: "24%",
      onSubmit: (qcAuditDate) => {
        onValueChange({ qcAuditDate });
      },
      errorMessages: get(errors, "qcAuditDate"),
      disabled: !canEdit,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "postApprovalDate"),
      value: dateInformation.postApprovalDate,
      width: "24%",
      onSubmit: (postApprovalDate) => {
        onValueChange({ postApprovalDate });
      },
      errorMessages: get(errors, "postApprovalDate"),
      disabled: !canEdit,
    } as AllFields,
  ];
};
