import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getFacilityAsyncAutocompleteFieldProps,
  getFieldRepAsyncAutocompleteFieldProps,
} from "../types";
import { Lead } from "../../../types/Lead";

export const leadEditFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "lead";
  const lead = form as Lead;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityId"),
      ...getFacilityAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "facilityId"),
      isRequired: true,
      value: lead.facilityId,
      width: "49%",
      onSubmit: (facilityOptions: Option & { area_AsString: string }) => {
        onValueChange({
          facilityId: facilityOptions?.value,
          region: facilityOptions?.area_AsString,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fieldRepId"),
      ...getFieldRepAsyncAutocompleteFieldProps(),
      isRequired: true,
      value: lead.fieldRepId,
      width: "49%",
      errorMessages: get(errors, "fieldRepId"),
      onSubmit: (fieldRepOptions: Option) => {
        onValueChange({
          fieldRepId: fieldRepOptions?.value,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "firstName"),
      value: lead.firstName,
      onSubmit: (firstName) => {
        onValueChange({ firstName });
      },
      width: "24%",
      errorMessages: get(errors, "firstName"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "lastName"),
      value: lead.lastName,
      onSubmit: (lastName) => {
        onValueChange({ lastName });
      },
      width: "24%",
      errorMessages: get(errors, "lastName"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "socialSecurityNumber"),
      value: lead.socialSecurityNumber,
      onSubmit: (socialSecurityNumber) => {
        onValueChange({ socialSecurityNumber });
      },
      width: "24%",
      errorMessages: get(errors, "socialSecurityNumber"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "dateOfBirth"),
      value: lead.dateOfBirth,
      onSubmit: (dateOfBirth) => {
        onValueChange({ dateOfBirth });
      },
      width: "24%",
      errorMessages: get(errors, "dateOfBirth"),
      maxDate: new Date(Date.now()),
      minDate: new Date("1900-01-01"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "productType"),
      value: lead.productType,
      onSubmit: (productType: Option) => {
        onValueChange({ productType: productType.value });
      },
      width: "24%",
      errorMessages: get(errors, "productType"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "nextRecertDate"),
      value: lead.nextRecertDate,
      onSubmit: (nextRecertDate) => {
        onValueChange({ nextRecertDate });
      },
      width: "24%",
      errorMessages: get(errors, "nextRecertDate"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isDeceased"),
      value: lead.isDeceased,
      onSubmit: (isDeceased) => {
        onValueChange({ isDeceased });
      },
      width: "24%",
      errorMessages: get(errors, "isDeceased"),
      isRequired: false,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "deceasedDate"),
      value: lead.deceasedDate,
      onSubmit: (deceasedDate) => {
        onValueChange({ deceasedDate });
      },
      width: "24%",
      errorMessages: get(errors, "deceasedDate"),
      isRequired: lead.isDeceased === true,
      disabled: lead.isDeceased !== true,
      hidden: lead.isDeceased !== true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasFaceSheet"),
      value: lead.hasFaceSheet,
      onSubmit: (hasFaceSheet) => {
        onValueChange({ hasFaceSheet });
      },
      width: "24%",
      errorMessages: get(errors, "hasFaceSheet"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasARForm"),
      value: lead.hasARForm,
      onSubmit: (hasARForm) => {
        onValueChange({ hasARForm });
      },
      width: "24%",
      errorMessages: get(errors, "hasARForm"),
    } as AllFields,
  ];
};
