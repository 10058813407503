import { ExistingResource } from "../ExistingResource";
export interface NewBill {
  owner?: number;
  type?: number;
  nameDescription?: string;
  account?: string;
  amount?: number;
  verified?: boolean;
}

export type Bill = ExistingResource<NewBill>;

export enum BillOwnerTypes {
  Resident = 1,
  Joint = 2,
  Spouse = 3,
}

export const BillTypes = [
  "Electric",
  "Water",
  "Trash",
  "Phone",
  "Property Tax",
  "Homeowners Insurance",
  "Gas",
  "Cable",
  "Insurance",
  "Other",
];
