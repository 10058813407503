import { Address } from "cluster";
import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { Facility } from "../../../types/Facility";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getCorporateAsyncAutocompleteFieldProps,
  getFieldRepAsyncAutocompleteFieldProps,
} from "../types";

export const newFormFacility: Partial<Facility> = {
  facilityCode: "",
  facilityName: "",
  corporateId: undefined,
  facilityEmail: "",
  facilityPhone: "",
  facilityFax: "",
  facilityAddress: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
  area: 0,
  fieldRepId: undefined,
  fieldRepFullName: undefined,
  isActive: true,
};

export const facilityFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId
) => {
  const modelName = "facility";
  const facility = form as Facility;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityName"),
      value: facility.facilityName,
      onSubmit: (facilityName) => {
        onValueChange({ facilityName });
      },
      isRequired: true,
      errorMessages: get(errors, "facilityName"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "corporateId"),
      ...getCorporateAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      onSubmit: (corporate: Option & { companyAddress: Address }) => {
        onValueChange({
          corporateId: corporate?.value,
        });
      },
      value: facility.corporateId,
      isRequired: true,
      errorMessages: get(errors, "corporateId"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityAddress"),
      onSubmit: (facilityAddress) => {
        onValueChange({ facilityAddress });
      },
      value: facility.facilityAddress,
      isRequired: true,
      errorMessages: [
        ...(get(errors, "facilityAddress.Street") || []),
        ...(get(errors, "facilityAddress.City") || []),
        ...(get(errors, "facilityAddress.State") || []),
        ...(get(errors, "facilityAddress.Zip") || []),
        ...(get(errors, "facilityAddress.County") || []),
      ],
    } as AllFields,
    {
      type: "Phone",
      label: "Facility Primary Phone",
      onSubmit: (facilityPhone) => {
        onValueChange({ facilityPhone });
      },
      value: facility.facilityPhone,
      isRequired: true,
      errorMessages: get(errors, "facilityPhone"),
      width: "45%",
    },
    {
      type: "Phone",
      label: "Facility Fax",
      onSubmit: (facilityFax) => {
        onValueChange({ facilityFax });
      },
      value: facility.facilityFax,
      errorMessages: get(errors, "facilityFax"),
      width: "45%",
    },
    {
      ...schemaStore.getFieldSchemaByName(modelName, "area"),
      onSubmit: (option: Option) => {
        onValueChange({ area: option.value });
      },
      value: facility.area,
      errorMessages: get(errors, "area"),
      isRequired: true,
      sortItemListAlphabetically: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityEmail"),
      onSubmit: (facilityEmail) => {
        onValueChange({ facilityEmail });
      },
      errorMessages: get(errors, "facilityEmail"),
      formId: formId,
      value: facility.facilityEmail,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fieldRepId"),
      ...getFieldRepAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      onSubmit: (option: Option) => {
        onValueChange({
          fieldRepId: option?.value ?? undefined,
          fieldRepFullName: option?.label ?? undefined,
        });
      },
      value: facility.fieldRepId,
      errorMessages: get(errors, "fieldRepId"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "customPNA"),
      onSubmit: (customPNA) => {
        onValueChange({ customPNA });
      },
      errorMessages: get(errors, "customPNA"),
      formId: formId,
      value: facility.customPNA,
    } as AllFields,
    {
      type: "Checkbox-Bool",
      label: "Is Active",
      disabled: false,
      onSubmit: (isActive) => {
        onValueChange({ isActive });
      },
      value: facility.isActive,
      width: "25%",
    },
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isScheduled"),
      onSubmit: (isScheduled) => {
        onValueChange({ isScheduled });
      },
      errorMessages: get(errors, "isScheduled"),
      formId: formId,
      value: facility.isScheduled,
      width: "25%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "newRecertBilling"),
      onSubmit: (newRecertBilling) => {
        onValueChange({ newRecertBilling });
      },
      errorMessages: get(errors, "newRecertBilling"),
      formId: formId,
      value: facility.newRecertBilling,
      width: "25%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "recertBilling"),
      onSubmit: (recertBilling) => {
        onValueChange({ recertBilling });
      },
      errorMessages: get(errors, "recertBilling"),
      formId: formId,
      value: facility.recertBilling,
      width: "25%",
    } as AllFields,
  ];
};
