import { FC } from "react";
import RelativityCard from "../../Card/RelativityCard";
import { ChecklistItem } from "../../../types/Checklist";
import { ReadOnlyField } from "@ucl/library";
import { Asset } from "../../../types/Application/Asset";
import { MultiSelectField } from "../../Fields/SelectField/MultiSelectField";
import { MultiSelectFieldValue } from "../../Fields/types/fieldTypes";
import { getReadonlyAggregatedStatementsOptions } from "../../../utils/getStatementOptions";
import { Income } from "../../../types/Application/Income";

export interface ChecklistItemVerifyComponentProps {
  checklistItem?: ChecklistItem;

  // For now, only Asset supports Statements Received for owned entities, but this could be expanded to use OwnedEntityType
  ownedEntity?: Asset | Income;
}
export const ChecklistItemVerifyComponent: FC<
  ChecklistItemVerifyComponentProps
> = (props) => {
  return (
    <div>
      {props.checklistItem && (
        <RelativityCard
          headerContent={`Checklist Item: ${props.checklistItem.displayName}`}
        >
          <ReadOnlyField
            label="Document Category"
            value={props.checklistItem.documentCategory_AsString}
          />
          {props.checklistItem.useStatementsReceived && (
            <MultiSelectField
              type="Multiselect"
              label="Statements Received (All Documents)"
              value={
                props.checklistItem
                  .statementsReceived_AsList as MultiSelectFieldValue
              }
              optionValues={getReadonlyAggregatedStatementsOptions(
                props.checklistItem.statementsReceived_AsList
              )}
              onSubmit={() => 0}
              disabled={true}
              customTagSettings={{
                useCustomTags: true,
                customClassName: "statements-received",
                tagValues: props.ownedEntity?.aggregatedStatementsNeeded || [],
              }}
            />
          )}
          {props.ownedEntity && props.checklistItem.useStatementsReceived && (
            <MultiSelectField
              type="Multiselect"
              label="Statements Needed"
              value={
                props.ownedEntity
                  .aggregatedStatementsNeeded as MultiSelectFieldValue
              }
              optionValues={getReadonlyAggregatedStatementsOptions(
                props.ownedEntity.aggregatedStatementsNeeded
              )}
              onSubmit={() => 0}
              disabled={true}
              customTagSettings={{
                useCustomTags: true,
                customClassName: "statements-received",
                tagValues: props.checklistItem?.statementsReceived_AsList || [],
              }}
            />
          )}
        </RelativityCard>
      )}
    </div>
  );
};
