import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  Income,
  IncomeOwnerTypes,
  NewIncome,
} from "../../../types/Application/Income";
import { notInListValue } from "../../Fields/AsyncAutocompleteField/AsyncAutocompleteODataV4Adaptor";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getInstitutionAsyncAutocompleteFieldProps,
} from "../types";
import { getStatementOptions } from "../../../utils/getStatementOptions";
import { applicationStore } from "../../../stores/ApplicationStore";
import { featureToggleStore } from "../../../lib/featureToggles/FeatureToggleStore";

export const newIncome: NewIncome = {
  incomeType: undefined,
  description: undefined,
  incomeOwner: undefined,
  startDate: undefined,
  payFrequency: undefined,
  verified: undefined,
  statementDateRangeStart: undefined,
  statementDates_AsList: undefined,
  statementFrequency: undefined,
};

export const incomeFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "income";
  const income = form as Income;

  const getIncomeOwnerOptions = () => {
    let incomeOwnerOptions = schemaStore.getFieldSchemaByName(
      modelName,
      "incomeOwner"
    ).optionValues;
    if (!applicationStore.application?.isMarried) {
      incomeOwnerOptions = incomeOwnerOptions?.filter(
        (x) => x.value.toString() === IncomeOwnerTypes.Resident.toString()
      );
    }

    return incomeOwnerOptions;
  };

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "institutionId"),
      ...getInstitutionAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "institutionId"),
      hasNotInListOption: true,
      groupByActiveStatus: true,
      width: "48.5%",
      isRequired: true,
      value: income.hasOtherInstitutionName
        ? notInListValue
        : income.institutionId,
      onSubmit: (institution: { id: string }) => {
        const hasOtherInstitutionName = institution?.id === notInListValue;
        if (hasOtherInstitutionName) {
          onValueChange({
            institutionId: undefined,
            hasOtherInstitutionName: true,
          });
        } else {
          onValueChange({
            institutionId: institution?.id,
            hasOtherInstitutionName: false,
          });
        }
      },
    } as unknown as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "otherInstitutionName"),
      value: income.otherInstitutionName,
      hidden: !income.hasOtherInstitutionName,
      onSubmit: (otherInstitutionName) => {
        onValueChange({ otherInstitutionName });
      },
      isRequired: true,
      errorMessages: get(errors, "otherInstitutionName"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "incomeOwner"),
      value: income.incomeOwner,
      onSubmit: (incomeOwner: Option) => {
        onValueChange({ incomeOwner: incomeOwner.value });
      },
      optionValues: getIncomeOwnerOptions(),
      isRequired: true,
      errorMessages: get(errors, "incomeOwner"),
      width: "48.25%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "incomeType"),
      value: income.incomeType,
      onSubmit: (incomeType: Option) => {
        onValueChange({ incomeType: incomeType.value });
      },
      isRequired: true,
      errorMessages: get(errors, "incomeType"),
      width: "48.25%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "amount"),
      onSubmit: (amount) => {
        onValueChange({ amount });
      },
      value: income.amount,
      isRequired: true,
      errorMessages: get(errors, "amount"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "startDate"),
      onSubmit: (startDate) => {
        onValueChange({ startDate });
      },
      isRequired: true,
      value: income.startDate,
      errorMessages: get(errors, "startDate"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "payFrequency"),
      value: income.payFrequency,
      onSubmit: (payFrequency: Option) => {
        onValueChange({ payFrequency: payFrequency.value });
      },
      isRequired: true,
      errorMessages: get(errors, "payFrequency"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "verified"),
      onSubmit: (verified) => {
        onValueChange({ verified });
      },
      value: income.verified,
      isRequired: true,
      errorMessages: get(errors, "verified"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDateRangeStart"),
      optionValues: getStatementOptions(),
      value: income.statementDateRangeStart,
      onSubmit: (statementDateRangeStart: Option) => {
        onValueChange({
          statementDateRangeStart: statementDateRangeStart?.value,
        });
      },
      shouldShowClearButton: true,
      errorMessages: get(errors, "statementDateRangeStart"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDates_AsList"),
      optionValues: getStatementOptions(),
      value: income.statementDates_AsList,
      onSubmit: (statementDates_AsList) => {
        onValueChange({ statementDates_AsList });
      },
      errorMessages: get(errors, "statementDates_AsList"),
      width: "48.5%",
    } as AllFields,
    ...(featureToggleStore.featureToggles?.EnableStatementFrequency
      ? [
          {
            ...schemaStore.getFieldSchemaByName(
              modelName,
              "statementFrequency"
            ),
            value: income.statementFrequency,
            onSubmit: (statementFrequency: Option) => {
              onValueChange({ statementFrequency: statementFrequency.value });
            },
            isRequired: false,
            errorMessages: get(errors, "statementFrequency"),
            width: "48.5%",
          } as AllFields,
        ]
      : []),
    {
      ...schemaStore.getFieldSchemaByName(modelName, "description"),
      onSubmit: (description) => {
        onValueChange({ description });
      },
      isRequired: true,
      value: income.description,
      errorMessages: get(errors, "description"),
    } as AllFields,
  ];
};
