import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { ExistingMonitor, Monitor } from "../../../types/Monitor";
import { dateBuilderAddYearsToTodaysDate } from "../../../utils/date";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newMonitorForm: Monitor = {
  startDate: undefined,
  endDate: undefined,
  reason: undefined,
  notes: undefined,
  monitorAge: undefined,
};

export const monitorFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "applicationMonitor";
  const monitor = form as ExistingMonitor;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "startDate"),
      value: monitor.startDate,
      onSubmit: (startDate) => {
        onValueChange({ startDate });
      },
      errorMessages: get(errors, "startDate"),
      isRequired: true,
      minDate: dateBuilderAddYearsToTodaysDate(-1),
      maxDate: dateBuilderAddYearsToTodaysDate(1),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "endDate"),
      value: monitor.endDate,
      onSubmit: (endDate) => {
        onValueChange({ endDate });
      },
      errorMessages: get(errors, "endDate"),
      maxDate: dateBuilderAddYearsToTodaysDate(1),
      minDate: monitor.startDate
        ? new Date(monitor.startDate)
        : dateBuilderAddYearsToTodaysDate(-1),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "reason"),
      value: monitor.reason,
      onSubmit: (reason: Option) => {
        onValueChange({ reason: reason.value });
      },
      errorMessages: get(errors, "reason"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "notes"),
      value: monitor.notes,
      onSubmit: (notes) => {
        onValueChange({ notes });
      },
      errorMessages: get(errors, "notes"),
    } as AllFields,
  ];
};
