import {
  PdfViewerComponent,
  ThumbnailView,
  Annotation,
  Inject,
  Toolbar,
  PdfViewerModel,
  Navigation,
  Magnification,
} from "@syncfusion/ej2-react-pdfviewer";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import React from "react";
import variables from "../../config/variables";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import { FileDocument } from "../../types/FileDocument";
import {
  TemplateDesignerSetup,
  templateDesignerStore,
} from "./stores/TemplateDesignerStore";
import "./styles.scss";
import { ExpressionsToolbar } from "./ExpressionsToolbar";
import { HeaderToolbar } from "./HeaderToolbar";
import { Loading } from "../Loading/Loading";
import classNames from "classnames";
import { Callout, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { PreviewToolbar } from "./PreviewToolbar";
export interface TemplateDesignerProps {
  fileDocument: FileDocument;
  fileFormData?: string;
  onSave?: (fileFormData?: string) => Promise<void>;
  onDelete?: (id?: string) => Promise<void>;
}

export const TemplateDesigner: React.FC<TemplateDesignerProps> = (props) => {
  const pdfViewerRef = React.useRef<PdfViewerComponent>(null);
  const [pdfViewerSettings, setPdfViewerSettings] = React.useState<
    PdfViewerModel & DefaultHtmlAttributes
  >();
  const isReadOnlyMode = !props.onSave;
  const [ready, setReady] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [notSupported, setNotSupported] = React.useState<boolean>(false);
  const [isPreview, setIsPreview] = React.useState<boolean>(false);

  // Setup
  React.useEffect(() => {
    setup();
    templateDesignerStore.onSave = props.onSave;
    templateDesignerStore.onDelete = props.onDelete;

    return () => {
      templateDesignerStore.reset();
    };
  }, []);

  React.useEffect(() => {
    if (isPreview) {
      templateDesignerStore.setPreviewMode();
    } else {
      templateDesignerStore.setDesignMode();
    }
  }, [isPreview]);

  // Initialize
  const handleDocumentLoad = () => {
    if (!!pdfViewerRef.current) {
      templateDesignerStore.initialize(
        pdfViewerRef.current,
        props.fileFormData
      );
      setNotSupported(
        !!templateDesignerStore.onSave && !pdfViewerRef.current.annotationModule
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  // Setup Method
  const setup = async () => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      const pdfViewerModel = templateDesignerStore.setup({
        pdfViewerId: "template-designer-pdfviewer-container",
        accessToken: accessToken,
        fileDocument: props.fileDocument,
        isReadOnlyMode: isReadOnlyMode,
      } as TemplateDesignerSetup);

      setPdfViewerSettings(pdfViewerModel);
      setReady(true);

      // Force resize to help solve height adjustments
      window.dispatchEvent(new Event("resize"));
    }
  };

  return (
    <Observer>
      {() => (
        <>
          {isLoading && (
            <div className="template-loading">
              <Loading />
            </div>
          )}
          <section
            className={classNames("template-designer", {
              "not-supported": notSupported,
              "is-preview": isPreview,
              "is-readonly-mode": isReadOnlyMode,
            })}
          >
            {ready && (
              <>
                {notSupported && (
                  <Callout title="Not Supported" intent={Intent.WARNING}>
                    The PDF File is locked, protected, or not supported by the
                    system.
                  </Callout>
                )}
                {isReadOnlyMode && (
                  <Callout title="Read Only" intent={Intent.PRIMARY}>
                    The PDF File is loaded on read only mode.
                  </Callout>
                )}
                <HeaderToolbar
                  onPreviewToggle={(preview) => setIsPreview(preview)}
                  hideFieldsTab={isReadOnlyMode}
                />
                <div className="template-designer-controls">
                  <PdfViewerComponent
                    ref={pdfViewerRef}
                    className="e-control e-lib e-pdfviewer "
                    serviceUrl={`${variables.apiBaseUrl}api/pdfviewer`}
                    documentLoad={handleDocumentLoad}
                    disabled={
                      isReadOnlyMode ||
                      templateDesignerStore.isSaving ||
                      templateDesignerStore.isDeleting ||
                      false
                    }
                    enableAnnotation={!templateDesignerStore.isSaving}
                    {...pdfViewerSettings}
                  >
                    <Inject
                      services={[
                        Annotation,
                        Toolbar,
                        ThumbnailView,
                        Navigation,
                        Magnification,
                      ]}
                    />
                  </PdfViewerComponent>
                  {!isReadOnlyMode && (
                    <>
                      {!isPreview && (
                        <ExpressionsToolbar
                          disableFields={
                            isPreview ||
                            templateDesignerStore.isSaving ||
                            templateDesignerStore.isDeleting ||
                            false
                          }
                        />
                      )}
                      {isPreview && <PreviewToolbar />}
                    </>
                  )}
                </div>
              </>
            )}
            {!ready && <></>}
          </section>
        </>
      )}
    </Observer>
  );
};
