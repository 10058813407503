import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../../stores/ApplicationStore";
import { incomeOwnedEntityStore } from "../../../../../stores/OwnedEntityStores/IncomeOwnedEntityStore";
import { userStore } from "../../../../../stores/UserStore";
import { Application } from "../../../../../types/Application";
import { IncomeInformation } from "../../../../../types/Application/IncomeInformation";
import { SaveButton } from "../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../Buttons/UndoButton";
import RelativityCard from "../../../../Card/RelativityCard";
import { RelativityCardHeader } from "../../../../Card/RelativityCardHeader";
import Form from "../../../../Forms";
import {
  incomeInformationFormSchema,
  newIncomeInformation,
} from "../../../../Forms/ApplicationForm/IncomeInformation";
import { showSuccessfulSaveAppToaster } from "../../../../Toast/Toast";
import { IncomesPageCard } from "./Owned Entities/IncomesPageCard";
import { getDefaultIncomeOwner } from "../../../../../types/Application/Income";

export interface IncomeInformationPageCardProps {
  application?: Application;
  canEdit: boolean;
  submitForm: (application: IncomeInformation) => Promise<void>;
}

export const IncomeInformationPageCard: FC<IncomeInformationPageCardProps> = ({
  application,
  submitForm,
  canEdit,
}) => {
  const shouldShowChildren =
    application?.doesResidentHaveAdditionalIncome ||
    newIncomeInformation.doesResidentHaveAdditionalIncome ||
    application?.doesSpouseHaveAdditionalIncome ||
    newIncomeInformation.doesSpouseHaveAdditionalIncome;

  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<Application>(
    application as Application
  );

  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  return (
    <RelativityCard
      className="application-page income-information-card"
      headerContent={
        <RelativityCardHeader label="Income Information">
          <div>
            {canEdit && (
              <>
                <>
                  <SaveButton
                    formId="income-information-form"
                    isSaving={formIsSaving}
                  />
                  <UndoButton
                    isSaving={formIsSaving}
                    onClick={async () => {
                      setShouldResetForm(true);
                      await handleReset();
                      setShouldResetForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </RelativityCardHeader>
      }
    >
      <Observer>
        {() => (
          <Form<IncomeInformation>
            formId="income-information-form"
            shouldBlockNavigation={true}
            value={activeFormValue}
            shouldResetForm={shouldResetForm}
            disableAllFields={!!formIsSaving || !canEdit || shouldDisableForm}
            formSchemaBuilder={incomeInformationFormSchema}
            hideSectionHeader={true}
            onFormSubmit={async (application) => {
              setShouldDisableForm(true);
              setFormIsSaving(true);
              await submitForm(application)
                .then(() => {
                  showSuccessfulSaveAppToaster("Income Information");
                })
                .finally(() => {
                  setShouldDisableForm(false);
                  setFormIsSaving(false);
                });
            }}
          />
        )}
      </Observer>

      {shouldShowChildren && (
        <IncomesPageCard
          application={application}
          canCreate={!!userStore.user?.canCreateApplicationPageIncome}
          canEdit={!!userStore.user?.canEditApplicationPageIncome}
          createAndUpdateIncomes={async () => {
            await incomeOwnedEntityStore.saveLocalIncomes();
          }}
          deleteIncome={async (income) => {
            await incomeOwnedEntityStore.saveLocalIncomes();
            incomeOwnedEntityStore.removeLocalIncome(income);
            await applicationStore.deleteApplicationOwnedEntity(
              income.id,
              "income"
            );
          }}
          formIsSaving={formIsSaving}
          setFormIsSaving={setFormIsSaving}
          shouldDisableForm={shouldDisableForm || !canEdit}
          setShouldDisableForm={setShouldDisableForm}
          defaultIncomeOwner={getDefaultIncomeOwner(
            application?.isMarried ?? false,
            application?.doesResidentHaveAdditionalIncome ?? false,
            application?.doesSpouseHaveAdditionalIncome ?? false
          )}
        />
      )}
    </RelativityCard>
  );
};
