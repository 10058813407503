import { Classes, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MedicaidEligibilityDecision } from "../../../types/MedicaidEligibilityDecision";
import { WithTooltip } from "../../Tooltip/Tooltip";
import React from "react";
export interface AMDAssetEligbilityDisclaimerIconProps {
  medicaidEligibilityDecision: MedicaidEligibilityDecision | undefined;
}
export const AMDAssetEligibilityDisclaimerIcon: React.FC<
  AMDAssetEligbilityDisclaimerIconProps
> = (props) => {
  return (
    <div className="asset-disclaimer-icon">
      {props.medicaidEligibilityDecision
        ?.residentAssetsExceedsStateThreshold && (
        <WithTooltip
          content={
            "Maverick’s AMD Asset Eligibility determination does not automatically back out deposited income. Please follow your State’s published Medicaid financial eligibility guidelines."
          }
          shouldShowTooltip={true}
          className={Classes.FOCUS_DISABLED}
        >
          <Icon
            icon={IconNames.Issue}
            intent={
              props.medicaidEligibilityDecision?.isEligible
                ? Intent.SUCCESS
                : Intent.DANGER
            }
          />
        </WithTooltip>
      )}
    </div>
  );
};
