import React from "react";
import "./styles.scss";

interface LoadingProps {
  content?: React.ReactNode;
}
export const Loading: React.FC<LoadingProps> = ({ content }) => {
  return (
    <div className="loading__container">
      {!content && <div className="loader"></div>}
      {content && content}
    </div>
  );
};
