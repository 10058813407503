import { uniqBy } from "lodash";
import { makeObservable, observable, runInAction } from "mobx";
import { User } from "../types/User";
import { userStore } from "./UserStore";

export class FiaRequestViewerStore {
  public activeUsers: User[] = [];
  constructor() {
    makeObservable(this, {
      activeUsers: observable,
    });
  }

  addActiveUser = (user: User) => {
    runInAction(() => {
      this.activeUsers = uniqBy(
        this.activeUsers.concat(user),
        (user) => user.id
      ).filter((activeUser) => activeUser.id !== userStore.user?.id);
    });
  };

  removeActiveUser = (user: User) => {
    runInAction(() => {
      this.activeUsers = uniqBy(
        this.activeUsers.filter((activeUser) => activeUser.id !== user.id),
        (user) => user.id
      ).filter((activeUser) => activeUser.id !== userStore.user?.id);
    });
  };

  resetActiveUser = () => {
    runInAction(() => {
      this.activeUsers = [];
    });
  };
}

export const fiaRequestViewerStore = new FiaRequestViewerStore();
