import { Button, H3, H6, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { userStore } from "../../../../stores/UserStore";
import { Application } from "../../../../types/Application";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";
import { formatAddress } from "../../../Fields/AddressField/utils";
import { formatPhoneNumber } from "../../../Fields/PhoneField/utils";
import { ResidentApplicationHistoryInformationCard } from "../../Cards/ResidentPanel/ResidentApplicationHistoryInformationCard";
import { ResidentPrimaryInformationCard } from "../../Cards/ResidentPanel/ResidentPrimaryInformationCard";
import { ResidentSecondaryInformationCard } from "../../Cards/ResidentPanel/ResidentSecondaryInformationCard";
import { differenceInYears } from "../../../../utils/date";

export interface ResidentPanelProps {
  application: Application;
}

export const ResidentPanel: React.FC<ResidentPanelProps> = ({
  application,
}) => {
  const openReadonlyFacilityDialog = () => {
    if (!application.facility) {
      return;
    }

    dialogsViewerStore.setIsFacilityUpdateDialogOpen(true, {
      facilityId: application.facility.id,
      isReadonly: true,
    });
  };

  return (
    <div className="resident-panel">
      <section className="resident-panel__header">
        <div className="resident-panel__file-info">
          <div>
            <H3>
              {application.firstName} {application.lastName}
            </H3>
            <H6>File #: {application.fileNumber}</H6>
          </div>
          <div>
            <Button
              rightIcon={IconNames.Control}
              minimal={true}
              intent={Intent.PRIMARY}
              text={"Summary"}
              onClick={() => {
                dialogsViewerStore.setIsApplicationSummaryDialogOpen(true, {
                  applicationId: application.id,
                });
              }}
            />
          </div>
        </div>

        {application.facility && (
          <section className="resident-panel__facility-section">
            <section className="resident-panel__facility-section__header">
              <H6>
                Facility:{" "}
                <a onClick={openReadonlyFacilityDialog}>
                  {application.facility.facilityName}
                </a>
              </H6>
              {userStore.user?.canEditFacilityOnApplicationPage && (
                <Button
                  className="edit-facility-button"
                  minimal={true}
                  intent={Intent.PRIMARY}
                  rightIcon={IconNames.Edit}
                  onClick={() => {
                    dialogsViewerStore.setIsApplicationPageUpdateFacilityDialogOpen(
                      true,
                      { application: application }
                    );
                  }}
                />
              )}
            </section>
            <section className="resident-panel__facility-section__detail">
              <p className="resident-panel__facility-section__detail__item">
                <Icon icon={IconNames.MAP_MARKER} />
                {formatAddress(application.facility.facilityAddress)}
              </p>
              <p className="resident-panel__facility-section__detail__item">
                <Icon icon={IconNames.PHONE} />
                {formatPhoneNumber(application.facility.facilityPhone)}
              </p>
              <p className="resident-panel__facility-section__detail__item">
                <Icon icon={IconNames.PRINT} />
                {formatPhoneNumber(application.facility.facilityFax)}
              </p>
            </section>
          </section>
        )}
      </section>
      <section className="resident-panel__section">
        <ResidentPrimaryInformationCard
          application={application}
          shouldBlockNavigation={true}
          canEdit={
            !!userStore.user?.canEditApplicationPagePrimaryInformation &&
            !!applicationStore.applicationCanBeEdited
          }
          submitForm={async (form) => {
            await applicationStore.updateApplication(
              form,
              "primary-information",
              "resident-panel"
            );
          }}
          missingFieldCount={
            applicationStore.pageMissingFieldCount.find(
              (page) => page.pageName === "primary-information"
            )?.missingFieldCount
          }
          formKey="index"
        />
        {application.dateOfBirth && (
          <p className="resident-age">
            Age: {differenceInYears(application.dateOfBirth)}
          </p>
        )}
      </section>
      <section className="resident-panel__section">
        <ResidentSecondaryInformationCard
          application={application}
          shouldBlockNavigation={true}
          canEdit={
            !!userStore.user?.canEditApplicationPageSecondaryInformation &&
            !!applicationStore.applicationCanBeEdited
          }
          submitForm={async (form) => {
            await applicationStore.updateApplication(
              form,
              "secondary-information"
            );
          }}
          missingFieldCount={
            applicationStore.pageMissingFieldCount.find(
              (page) => page.pageName === "secondary-information"
            )?.missingFieldCount
          }
          formKey="index"
        />
      </section>
      <section className="resident-panel__section">
        <ResidentApplicationHistoryInformationCard
          application={application}
          canCreateChildApplication={
            !!applicationStore.applicationCanBeEdited ||
            !!applicationStore.canCreateChildApplication
          }
        />
      </section>
    </div>
  );
};
