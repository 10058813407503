import { Button, Classes, Intent } from "@blueprintjs/core";

export interface DialogFooterProps {
  closeDialog: () => void;
  submitDialog: () => void;
  isConfirmDisabled?: boolean;
  closeButtonText?: string;
  submitButtonText?: string;
}

export const DialogFooter: React.FC<DialogFooterProps> = ({
  closeDialog,
  submitDialog,
  isConfirmDisabled = false,
  closeButtonText = "No",
  submitButtonText = "Yes",
}) => {
  return (
    <div className={Classes.DIALOG_FOOTER}>
      <Button
        minimal
        intent={Intent.DANGER}
        text={closeButtonText}
        onClick={closeDialog}
      />
      <Button
        intent={Intent.SUCCESS}
        text={submitButtonText}
        disabled={isConfirmDisabled}
        onClick={submitDialog}
      />
    </div>
  );
};
