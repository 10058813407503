import { Elevation } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { incomeOwnedEntityStore } from "../../../../stores/OwnedEntityStores/IncomeOwnedEntityStore";
import { Income } from "../../../../types/Application/Income";
import { DeleteButton } from "../../../Buttons/DeleteButton";
import { FormSubmitButton } from "../../../Buttons/FormSubmitButton";
import RelativityCard from "../../../Card/RelativityCard";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../Forms";
import {
  incomeFormSchema,
  newIncome,
} from "../../../Forms/ApplicationForm/Income";
import { showSuccessfulSaveAppToaster } from "../../../Toast/Toast";
import "../../styles.scss";
import { FIARequest } from "../../../FIARequests/configurations/types";
import { fiaRequestApiClient } from "../../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { OwnedEntityFIARequestSubCard } from "../../../FIARequests/OwnedEntityFIARequestsSubCard";

export interface IncomeFormCardProps {
  localIncomes: Income[];
  setLocalIncomes: (incomes: Income[]) => void;
  createAndUpdateIncomes: () => Promise<void>;
  deleteIncome?: (income: Income) => Promise<void>;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  income: Income;
  index: number;
  applicationId?: string;
}

export const newIncomeId = "new-income";
export const IncomeFormCard: FC<IncomeFormCardProps> = ({
  localIncomes,
  setLocalIncomes,
  createAndUpdateIncomes,
  deleteIncome,
  shouldDisableForm,
  setShouldDisableForm,
  formIsSaving,
  setFormIsSaving,
  applicationId,
  income,
  index,
}) => {
  const [isInDeleteAction, setIsInDeleteAction] = useState<boolean>(false);
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );

  const isNewIncome = income.id === newIncomeId;

  useEffect(() => {
    if (!!applicationId && !isNewIncome) {
      fiaRequestApiClient
        .getMostRecentRequestByOwnedEntityId(applicationId, income.id)
        .then((res) => setFiaRequest(res));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [income.id]);

  return (
    <RelativityCard
      key={income.id}
      elevation={Elevation.TWO}
      headerContent={
        <div className="owned-entity-header income-card-header">
          <SectionHeader
            label={`Additional Income ${index + 1}`}
            type="Section"
            inAction={formIsSaving}
          />
          <div>
            {deleteIncome && (
              <DeleteButton
                isDeleting={isInDeleteAction}
                disabled={shouldDisableForm || formIsSaving}
                onClick={async () => {
                  setShouldDisableForm(true);
                  setIsInDeleteAction(true);
                  if (isNewIncome) {
                    setLocalIncomes(
                      localIncomes.filter((income) => income.id !== newIncomeId)
                    );
                    incomeOwnedEntityStore.removeLocalIncome(income);
                  } else {
                    deleteIncome && (await deleteIncome(income));
                  }

                  setIsInDeleteAction(false);
                  setShouldDisableForm(false);
                }}
              />
            )}
            <FormSubmitButton
              buttonId={`income-form-create-button-${income.id}`}
              isSaving={formIsSaving}
              disabled={shouldDisableForm}
              formId={`income-form-${income.id}`}
              text={isNewIncome ? "Create" : "Update"}
            />
          </div>
        </div>
      }
    >
      <Form<Income>
        formId={`income-form-${income.id}`}
        shouldBlockNavigation={true}
        value={income || newIncome}
        formSchemaBuilder={incomeFormSchema}
        disableAllFields={shouldDisableForm}
        shouldSaveEntiityToLocalStore={true}
        ownedEntityStoreAction={(updatedIncome) => {
          if (JSON.stringify(updatedIncome) !== JSON.stringify(income)) {
            incomeOwnedEntityStore.addLocalIncome(updatedIncome);
          }
        }}
        onFormSubmit={async () => {
          setShouldDisableForm(true);
          setFormIsSaving(true);

          await createAndUpdateIncomes().then(() => {
            showSuccessfulSaveAppToaster("Incomes");
          });

          setFormIsSaving(false);
          setShouldDisableForm(false);
        }}
      />
      {fiaRequest && <OwnedEntityFIARequestSubCard fiaRequest={fiaRequest} />}
    </RelativityCard>
  );
};
