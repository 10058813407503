import { Button, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import "../../../pages/styles.scss";
import { GridFiltersModule } from "../../Grid/Filters/Filters";
import { GridPermissions } from "../../Grid/types/GridPermissions";
import { GridViewPicker } from "../../Grid/Views/GridViewPicker";
import "../styles.scss";
import {
  getGridColumnsConfigurationByRole,
  gridColumnsConfiguration,
} from "./configurations/gridColumnsConfiguration";
import { ApplicationCannedViewPicker } from "./ApplicationCannedViewPicker";
import { useState } from "react";
import { ActiveApplicationGrid } from "./ApplicationGridCannedViews/ActiveApplicationGrid";
import { ClosedApplicationGrid } from "./ApplicationGridCannedViews/ClosedApplicationGrid";
import { LastThirtyDaysApplicationGrid } from "./ApplicationGridCannedViews/LastThirtyDaysApplicationGrid";
import { AllApplicationGrid } from "./ApplicationGridCannedViews/AllApplicationGrid";
import { GridFilterSettings, useGridView } from "@ucl/library";
import { autorun } from "mobx";
import { gridStore } from "../../Grid/Grid/stores/GridStore";
import baseApiClient from "../../../lib/apiClients/baseApiClient";

export interface ApplicationGridProps extends GridPermissions {}

export const ApplicationGrid: React.FC<ApplicationGridProps> = ({
  canConfigureBoard,
  canCreateNewRecord,
  canInvokeGridRecord,
  canExportGridToExcel,
}) => {
  const navigate = useNavigate();
  const gridKey = "application-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Application Board",
    gridColumnsConfiguration(),
    getGridColumnsConfigurationByRole,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  const [gridCannedView, setGridCannedView] = useState<string>("Closed");

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>View</li>
          <li>
            <ApplicationCannedViewPicker
              setGridCannedView={setGridCannedView}
            />
          </li>
          <li>
            {canConfigureBoard && (
              <GridViewPicker
                gridView={gridView.currentGridView}
                baseGridColumnsConfiguration={
                  gridView.baseGridColumnsConfiguration
                }
                updateViewColumnSettings={gridView.updateViewColumnSettings}
              />
            )}
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            <GridFiltersModule />
          </li>
          <li>
            {canCreateNewRecord && (
              <Button
                intent={Intent.PRIMARY}
                text="Create New Application"
                onClick={() => {
                  navigate("/application-form");
                }}
              />
            )}
          </li>
        </ul>
      </section>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && gridCannedView === "Active" && (
                <ActiveApplicationGrid
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                />
              )}
              {!!gridView.currentGridView && gridCannedView === "Closed" && (
                <ClosedApplicationGrid
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                />
              )}
              {!!gridView.currentGridView &&
                gridCannedView === "Active + Closed Last 30 Days" && (
                  <LastThirtyDaysApplicationGrid
                    canConfigureBoard={canConfigureBoard}
                    canExportGridToExcel={canExportGridToExcel}
                    canCreateNewRecord={canCreateNewRecord}
                    canInvokeGridRecord={canInvokeGridRecord}
                  />
                )}
              {!!gridView.currentGridView && gridCannedView === "All" && (
                <AllApplicationGrid
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                />
              )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};
