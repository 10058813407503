import GridColumn from "../../../Grid/Grid/types/GridColumn";

export const userApplicationsGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "firstName",
    headerText: "First Name",
    type: "string",
  }),
  new GridColumn({
    field: "lastName",
    headerText: "Last Name",
    type: "string",
  }),
  new GridColumn({
    field: "fileNumber",
    headerText: "File Number",
    type: "string",
  }),
  new GridColumn({
    field: "internalStatus_AsString",
    headerText: "Status",
    type: "string",
  }),
  new GridColumn({
    field: "productType_AsString",
    headerText: "Type",
    type: "string",
    width: 250,
  }),
];
