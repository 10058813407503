import { action, computed, makeObservable, observable } from "mobx";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { L10n } from "@syncfusion/ej2-base";
import {
  ActionEventArgs,
  AddEventArgs,
  Column,
  DeleteEventArgs,
  EditEventArgs,
  FailureEventArgs,
  FilterEventArgs,
  FilterSearchBeginEventArgs,
  GridColumnDirTypecast,
  GridColumnModel,
  GridComponent,
  GridModel,
  GroupEventArgs,
  PageEventArgs,
  PredicateModel,
  RecordClickEventArgs,
  SaveEventArgs,
  SearchEventArgs,
  SortDescriptorModel,
  SortEventArgs,
} from "@syncfusion/ej2-react-grids";
import { Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import GridHeightSettings from "../../Grid/types/GridHeightSettings";
import GridColumn from "../../Grid/types/GridColumn";
import { BaseGridModel } from "../types/BaseGridModel";
import GridRecordClickEventArgs from "../../Grid/types/GridRecordClickEventArgs";
import { ODataBeforeSendArgs } from "../../Grid/types/ODataBeforeSendArgs";
import { GridFilterSettings } from "../../Grid/types/GridFilterSettings";
import { GridPersistData } from "../../Grid/types/GridPersistData";
import {
  showDownloadingFileAppToaster,
  showSuccessAppToaster,
} from "../../../Toast/Toast";
import { ODataStore } from "../../../../stores/ODataStore";

export class GridStore extends ODataStore {
  // computed
  public get getSelectedRecords() {
    return this.gridInstance?.getSelectedRecords() ?? [];
  }

  public selectedRowsCount = 0;

  // public
  public gridKey?: string;
  public gridViewTitle?: string;
  public gridInstance?: GridComponent;
  public requestFilterQuery?: string;
  public requestType?: string;
  public filterSettings?: GridFilterSettings;
  public persistData?: GridPersistData;
  public defaultSort?: SortDescriptorModel;

  public clearFilters = (gridFilterSettings?: GridFilterSettings) => {
    if (!gridFilterSettings) {
      gridFilterSettings = this.getGridFilterSettings(this.gridInstance);
    }

    // Clear  Filters (Direct, avoid errors)
    const fieldsToRemove = gridFilterSettings.rawFilterSettings?.map(
      (x) => x.field
    );
    if (
      gridFilterSettings.rawFilterSettings?.length > 0 &&
      !!this.gridInstance
    ) {
      this.gridInstance.filterSettings.columns =
        this.gridInstance?.filterSettings.columns?.filter(
          (x) => !fieldsToRemove.includes(String(x.field))
        );

      this.gridInstance?.setProperties({
        filterSettings: this.gridInstance.filterSettings,
      });
    }

    // Clear Sorts
    if (gridFilterSettings.rawSortSettings?.length > 0) {
      gridFilterSettings.rawSortSettings.forEach((col) => {
        this.gridInstance?.removeSortColumn(String(col.field));
      });
    }

    if (this.gridInstance?.headerModule) {
      this.gridInstance?.refreshHeader();
    }
  };

  public removeSort = (field: string) => {
    this.clearFilters({
      rawSortSettings: [{ field: field }],
    } as GridFilterSettings);
  };

  public removeFilter = (field: string) => {
    this.clearFilters({
      rawFilterSettings: [{ field: field }],
    } as GridFilterSettings);
  };

  // private

  private defaultGridHeightSettings: GridHeightSettings = {
    height: 600,
    autoHeightOffset: 250,
  };

  private gridHeightSettings: GridHeightSettings =
    this.defaultGridHeightSettings;

  private defaultGridColumnSettings: GridColumnModel | GridColumnDirTypecast = {
    textAlign: "Left",
    displayAsCheckBox: false,
  };

  private defaultGridSettings: GridModel & DefaultHtmlAttributes = {
    enableColumnVirtualization: true,
    enableInfiniteScrolling: true,
    height: this.defaultGridHeightSettings.height,
    allowReordering: true,
    allowResizing: true,
    resizeSettings: { mode: "Normal" },
    pageSettings: { pageSize: 20 },
    allowExcelExport: true,
    allowSorting: true,
    allowFiltering: true,
    loadingIndicator: { indicatorType: "Shimmer" },
    enableVirtualMaskRow: true,
    filterSettings: {
      type: "Excel",
      ignoreAccent: true,
      operators: {
        stringOperator: [{ value: "contains", text: "Contains " }],
      },
    },
    toolbar: [
      { text: "Search" },
      {
        text: "Export to Excel",
        tooltipText: "Export to Excel",
        prefixIcon: "e-upload-2",
        id: "ExcelExport",
      },
    ],
    searchSettings: {
      operator: "contains",
      ignoreCase: true,
    },
  };

  constructor() {
    super();

    makeObservable(this, {
      gridKey: observable,
      gridViewTitle: observable,
      gridInstance: observable,
      requestFilterQuery: observable,
      requestType: observable,
      filterSettings: observable,
      persistData: observable,
      setGridInstance: action,
      setRequestFilterQuery: action,
      setRequestType: action,
      setFilterSettings: action,
      setPersistData: action,
      clearFilters: action,
      getSelectedRecords: computed,
      selectedRowsCount: observable,
    });
  }

  public setup = async <T extends object>(args: {
    gridKey?: string;
    gridViewTitle?: string;
    dataSource: string;
    getAccessToken: () => Promise<string>;
    columnsConfiguration: Readonly<GridColumn[]>;
    gridConfiguration?: Readonly<BaseGridModel<T>>;
    gridHeightSettings?: GridHeightSettings;
    setupActions?: (columns: GridColumn[]) => GridColumn[];
  }): Promise<GridModel & DefaultHtmlAttributes> => {
    // Setup Grid Settings
    const gridSettings = await this.setupGridSettings(args);
    return gridSettings;
  };

  private setupGridSettings = async <T extends object>(args: GridSetup<T>) => {
    this.gridKey = args.gridKey;
    this.gridHeightSettings =
      args.gridHeightSettings || this.defaultGridHeightSettings;

    // Setup token
    await this.init(args.getAccessToken);

    const gridSettings = {
      ...this.defaultGridSettings,
      ...args.gridConfiguration,
    };

    // Update Filter and Sort Settings
    gridSettings.filterSettings = {
      ...gridSettings.filterSettings,
      ...{ columns: args.filterConfiguration },
    };

    gridSettings.sortSettings = {
      ...gridSettings.sortSettings,
      ...{ columns: args.sortConfiguration },
    };

    // Setup Properties
    gridSettings.columns = this.setupGridSettingsColumns(
      args.columnsConfiguration
    );
    if (!!args.setupActions) {
      gridSettings.columns = args.setupActions(
        gridSettings.columns as GridColumn[]
      );
    }

    gridSettings.query = this.setupGridSettingsQuery(args.columnsConfiguration);
    gridSettings.dataSource = this.setupODataSource(
      args.dataSource,
      args.columnsConfiguration as GridColumn[],
      this.onBeforeSend
    );

    // Setup Events
    gridSettings.recordClick = this.setupRecordClickEvent(
      args.gridConfiguration
    );
    gridSettings.toolbarClick = this.setupToolbarClickEvent(
      args.gridConfiguration
    );
    gridSettings.dataBound = this.setupDataboundEvent(args.gridConfiguration);
    gridSettings.created = this.setupCreatedEvent(args.gridConfiguration);
    gridSettings.actionBegin = this.setupActionBeginEvent(
      args.gridConfiguration
    );
    gridSettings.actionFailure = this.setupActionFailureEvent(
      args.gridConfiguration
    );
    gridSettings.actionComplete = this.setupActionCompleteEvent(
      args.gridConfiguration,
      args.columnsConfiguration
    );
    gridSettings.rowSelected = () => {
      this.selectedRowsCount =
        this.gridInstance?.getSelectedRecords().length || 0;
    };
    gridSettings.rowDeselected = () => {
      this.selectedRowsCount =
        this.gridInstance?.getSelectedRecords().length || 0;
    };

    // Set default sort
    this.defaultSort = args.gridConfiguration?.defaultSort;

    this.gridViewTitle = args.gridViewTitle;

    // Update locale for boolean values
    L10n.load({
      "en-US": {
        grid: {
          True: "Yes",
          False: "No",
        },
      },
    });

    // Return Grid Model
    return gridSettings;
  };

  private setupGridSettingsColumns = (
    columnsConfiguration: Readonly<GridColumn[]>
  ) => {
    const gridColumnSettings = columnsConfiguration.map((colsConfig) => ({
      ...this.defaultGridColumnSettings,
      ...colsConfig,
    })) as GridColumn[];

    return gridColumnSettings;
  };

  private setupGridSettingsQuery = (
    columnsConfiguration: Readonly<GridColumn[]>
  ) => {
    const query = new Query();
    query?.select(columnsConfiguration.map((x) => x.field));

    if (this.gridKey) {
      query?.addParams("gridId", this.gridKey);
    }

    if (this.gridViewTitle) {
      query?.addParams("gridViewTitle", this.gridViewTitle);
    }

    return query;
  };

  private setupRecordClickEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return (x: RecordClickEventArgs) => {
      if (gridConfiguration?.recordClick) {
        gridConfiguration.recordClick(x);
      }

      if (gridConfiguration?.onRecordClick) {
        const recordClickEventArgs = {
          ...x,
        } as GridRecordClickEventArgs<T>;
        gridConfiguration.onRecordClick(recordClickEventArgs);
      }
    };
  };

  private setupToolbarClickEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return (clickEventArgs: ClickEventArgs) => {
      if (gridConfiguration?.toolbarClick) {
        gridConfiguration.toolbarClick(clickEventArgs);
      }

      if (clickEventArgs.item.id === "ExcelExport") {
        showDownloadingFileAppToaster("Excel file");
        this.gridInstance
          ?.excelExport({
            fileName: gridConfiguration?.exportFilename ?? "Export.xlsx",
          })
          .then(() => {
            showSuccessAppToaster("Excel download finished");
          });
      }
    };
  };

  private setupDataboundEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return (obj: Object) => {
      if (gridConfiguration?.dataBound) {
        gridConfiguration.dataBound(obj);
      }

      this.gridInstance?.autoFitColumns(
        (this.gridInstance?.columns as Column[])
          .filter((x) =>
            // only autofit columns whose width is set at its default
            typeof x.width === "number" ? x.width === 200 : true
          )
          .map((x) => x.foreignKeyField)
      );
    };
  };

  private setupCreatedEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return (obj: Object) => {
      if (gridConfiguration?.created) {
        gridConfiguration.created(obj);
      }

      this.storeFilterSettings();
    };
  };

  private setupActionBeginEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return (
      eventArgs:
        | PageEventArgs
        | GroupEventArgs
        | FilterEventArgs
        | SearchEventArgs
        | SortEventArgs
        | AddEventArgs
        | SaveEventArgs
        | EditEventArgs
        | DeleteEventArgs
        | ActionEventArgs
        | FilterSearchBeginEventArgs
    ) => {
      if (gridConfiguration?.actionBegin) {
        gridConfiguration.actionBegin(eventArgs);
      }

      this.setRequestType(eventArgs.requestType);

      // Set Filter Choice Count
      if (eventArgs.requestType === "filterchoicerequest") {
        (eventArgs as FilterSearchBeginEventArgs).filterChoiceCount = 9999;
      }

      this.configureCustomColumnFilterSettingsOnActionBegin(
        eventArgs as FilterSearchBeginEventArgs
      );
    };
  };

  private setupActionCompleteEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>,
    columnsConfiguration?: Readonly<GridColumn[]>
  ) => {
    return (
      eventArgs:
        | PageEventArgs
        | GroupEventArgs
        | FilterEventArgs
        | SearchEventArgs
        | SortEventArgs
        | AddEventArgs
        | SaveEventArgs
        | EditEventArgs
        | DeleteEventArgs
        | ActionEventArgs
    ) => {
      if (gridConfiguration?.actionComplete) {
        gridConfiguration.actionComplete(eventArgs);
      }

      this.configureCustomColumnFilterSettingsOnActionComplete(
        eventArgs as FilterEventArgs,
        columnsConfiguration
      );
      this.baseActionCompleteEventHandler();
    };
  };

  private setupActionFailureEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return async (eventArgs: FailureEventArgs) => {
      if (gridConfiguration?.actionFailure) {
        gridConfiguration.actionFailure(eventArgs);
      }
      // Process Error
      await this.baseActionFailureEventHandler(eventArgs, () =>
        this.gridInstance?.refresh()
      );
    };
  };

  private configureCustomColumnFilterSettingsOnActionBegin = (
    eventArgs: FilterSearchBeginEventArgs
  ) => {
    const { filterModel } = eventArgs;

    if (
      eventArgs.requestType === "filterbeforeopen" &&
      filterModel?.options?.column?.filter?.dataSource
    ) {
      filterModel.options.dataSource =
        filterModel.options.column.filter.dataSource;
      filterModel.options.filteredColumns =
        filterModel.options.filteredColumns?.filter(
          (col: PredicateModel) =>
            col.field === filterModel.options.column?.field
        );
    }
  };

  private configureCustomColumnFilterSettingsOnActionComplete = (
    eventArgs: FilterEventArgs,
    columnsConfiguration?: Readonly<GridColumn[]>
  ) => {
    if (eventArgs.requestType === "filtering" && eventArgs.columns) {
      const operatorOverride = columnsConfiguration?.find(
        (x) => x.field === eventArgs.currentFilteringColumn
      )?.filter?.operator;

      if (!!operatorOverride) {
        eventArgs.columns.map((x) =>
          x.field === eventArgs.currentFilteringColumn
            ? (x.operator = operatorOverride)
            : x.operator
        );
      }
    }
  };

  private initializeAutoHeight = () => {
    // Inner Function
    const autoHeight = () => {
      if (this.gridInstance) {
        this.gridInstance.height =
          window.innerHeight - this.gridHeightSettings.autoHeightOffset;
      }
    };

    // Init
    autoHeight();

    // On Resize
    window.addEventListener("resize", () => {
      autoHeight();
    });
  };

  public initialize = (newGridInstance: GridComponent) => {
    this.setGridInstance(newGridInstance);
    this.storeFilterSettings();
    this.initializeAutoHeight();
  };

  public reset = () => {
    this.setGridInstance(undefined);
  };

  public refresh = () => {
    this.gridInstance?.refresh();
  };

  private onBeforeSend = ({
    queryString,
  }: ODataBeforeSendArgs): [string, string | undefined] => {
    this.setRequestFilterQuery(queryString);

    if (this.requestType) {
      this.storeFilterSettings(this.requestType);
    }

    // Initial Sorting
    if (this.defaultSort && !queryString.includes("$orderby")) {
      queryString =
        queryString +
        `&$orderby=${this.defaultSort.field}%20${
          this.defaultSort.direction === "Ascending" ? "asc" : "desc"
        }`;
    }

    // Grouping Filters missing Filter
    if (
      queryString.includes("$apply=groupby") &&
      !queryString.includes("$filter")
    ) {
      const params = new URLSearchParams(this.filterSettings?.rawQuery);
      if (params.has("$filter")) {
        queryString =
          queryString +
          `&$filter=${encodeURIComponent(params.get("$filter") as string)}`;
      }
    }

    return [queryString, this.accessToken];
  };

  private storeFilterSettings = (requestType?: string) => {
    if (!this.gridInstance) {
      return;
    }

    if (
      !requestType ||
      ["sorting", "filtering", "searching", "reorder", "refresh"].includes(
        String(requestType)
      )
    ) {
      // Set Persist Data
      const persistData = JSON.parse(
        this.gridInstance?.getPersistData()
      ) as GridPersistData;
      this.setPersistData(persistData);

      // Set Filter Settings
      const filterSettings = this.getGridFilterSettings(this.gridInstance);
      if (filterSettings) {
        this.setFilterSettings(filterSettings);
      }
    }
  };

  private getGridFilterSettings = (
    scopeGridInstance?: GridComponent
  ): GridFilterSettings => {
    const gridFilterSettings = {
      rawQuery: this.requestFilterQuery,
      rawSearch: scopeGridInstance?.searchSettings.key,
      rawColumnSettings: (scopeGridInstance?.columns as Column[]).filter(
        (x) => x.visible
      ),
      rawFilterSettings: this.persistData?.filterSettings.columns || [],
      rawSortSettings: this.persistData?.sortSettings.columns || [],
      columnNames: (scopeGridInstance?.columns as Column[])
        .filter((x) => x.visible)
        ?.map((x) => x.field)
        ?.join(","),
    } as GridFilterSettings;

    return gridFilterSettings;
  };

  public setGridInstance = (scopeGridInstance?: GridComponent) => {
    this.gridInstance = scopeGridInstance;
  };

  public setRequestFilterQuery = (filterQuery?: string) => {
    this.requestFilterQuery = filterQuery;
  };

  public setRequestType = (requestType?: string) => {
    this.requestType = requestType;
  };

  public setFilterSettings = (gridFilterSettings?: GridFilterSettings) => {
    if (!!gridFilterSettings) {
      gridFilterSettings.gridKey = this.gridKey;
    }
    this.filterSettings = gridFilterSettings;
  };

  public setPersistData = (persistData: GridPersistData) => {
    this.persistData = persistData;
  };
}

export const gridStore = new GridStore();

export interface GridSetup<T extends object> {
  gridKey?: string;
  gridViewTitle?: string;
  dataSource: string;
  getAccessToken: () => Promise<string>;
  columnsConfiguration: Readonly<GridColumn[]>;
  filterConfiguration?: PredicateModel[];
  sortConfiguration?: SortDescriptorModel[];
  gridConfiguration?: Readonly<BaseGridModel<T>>;
  gridHeightSettings?: GridHeightSettings;
  setupActions?: (columnsConfiguration: GridColumn[]) => GridColumn[];
}
