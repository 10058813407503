import React, { useEffect } from "react";
import { Observer } from "mobx-react";
import { Loading } from "../Loading/Loading";
import classNames from "classnames";
import {
  AccordionComponent,
  ExpandEventArgs,
} from "@syncfusion/ej2-react-navigations";
import { Document } from "../../types/Document";
import { Button, NonIdealState } from "@blueprintjs/core";
import "./documentPagesViewerStyles.scss";
import { ThumbnailImagesData } from "./PdfDocumentStore";
import {
  DocumentPagesViewerItem,
  DocumentPagesViewerItemModule,
} from "./DocumentPagesViewerItem";
import {
  DocumentAction,
  DocumentActionRender,
  DocumentActionTypes,
  DocumentThumbnailsProperties,
  SupportedDocumentPageActionTypes,
  useDocumentPagesViewer,
} from "./useDocumentPagesViewer";
import { DocumentPreview } from "./DocumentPreview";
import { isUndefined } from "lodash";
import { DocumentPagePreview } from "./DocumentPagePreview";
import { useDocument } from "./useDocument";
import { AppToaster } from "../Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { parseDateToDateTimeString } from "../../utils/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import {
  DocumentPageAction,
  DocumentPageActionRender,
  DocumentPageActionTypes,
} from "./useDocumentPagesViewerItem";
import {
  CroppedChangedEventArgs,
  DocumentPageEditor,
} from "./DocumentPageEditor";
import {
  DocumentThumbnailOperation,
  DocumentThumbnailCropOperation,
  DocumentThumbnailRotateOperation,
} from "./useDocumentEditor";
import { formatBytes } from "../../utils/miscellaneous";
import { DocumentEditor } from "./DocumentEditor";
import { IconNames } from "@blueprintjs/icons";

export interface DocumentPagesViewerProps {
  documents: Document[];
  documentThumbnailsProperties?: DocumentThumbnailsProperties[];
  /**
   * Disable selection and actions
   */
  disabled?: boolean;
  /**
   * Disable just the selection
   */
  readOnly?: boolean;
  /**
   * Items to show on toolbar. Can be expanded. Readonly allow them to still work. Disable will apply just to DocumentActionTypes, and DocumentAction.
   */
  documentItems?: Array<
    | DocumentActionTypes
    | DocumentAction<Document>
    | DocumentActionRender<Document>
  >;
  onSelectionChanged?: (value: ThumbnailImagesData[]) => void;
  isAnimated?: boolean;
  expandAll?: boolean;
  disabledCollapse?: boolean;
  disabledSelectionOrder?: boolean;
  disabledPagePreview?: boolean;
  onPreviewModeChanged?: (isPreview: boolean) => void;
  onPageEditorMode?: (isEditorMode: boolean) => void;
  onRotateMode?: (isRotateMode: boolean) => void;
  documentPageItems?: Array<
    | SupportedDocumentPageActionTypes
    | DocumentPageAction<Document>
    | DocumentPageActionRender<Document>
  >;
  onDocumentOperationsChanged?: (
    documentOperations: DocumentThumbnailOperation[]
  ) => void;
}
export interface DocumentPagesViewerModule {
  unselectAll: () => void;
  resetMode: () => void;
}

export const DocumentPagesViewerComponent = (
  props: DocumentPagesViewerProps,
  ref: React.ForwardedRef<DocumentPagesViewerModule>
) => {
  React.useImperativeHandle(
    ref,
    () =>
      ({
        unselectAll: () => {
          unselectAll();
        },
        resetMode: () => {
          resetMode();
        },
      } as DocumentPagesViewerModule)
  );

  const {
    ready,
    documentThumbnails,
    setDocumentThumbnails,
    selectedDocumentThumbnails,
    selectionChangedHandler,
    fetchDocumentThumbnailsByDocumentIdx,
    fetchDocumentThumbnailsByDocumentId,
    documentPagesViewerItemModule,
    isSelectAll,
    unselectAll,
  } = useDocumentPagesViewer(
    props.documents,
    props.documentThumbnailsProperties
  );

  const {
    getDocument,
    getDocumentPage,
    getDocumentPageBlobUrl,
    getBase64Url,
    openDocumentNewTab,
  } = useDocument();

  const [isActionIntent, setIsActionIntent] = React.useState<boolean>(false);
  const [isDocumentPreviewMode, setIsDocumentPreviewMode] =
    React.useState<boolean>(false);
  const [isDocumentRotateMode, setIsDocumentRotateMode] =
    React.useState<boolean>(false);
  const [isPagePreviewMode, setIsPagePreviewMode] =
    React.useState<boolean>(false);
  const [isPageEditorMode, setIsPageEditorMode] =
    React.useState<boolean>(false);
  const [previewDocumentId, setPreviewDocumentId] = React.useState<string>();
  const [rotateDocumentId, setRotateDocumentId] = React.useState<
    string | undefined
  >();
  const [previewPageData, setPreviewPageData] =
    React.useState<ThumbnailImagesData>();
  const [editorPageData, setEditorPageData] =
    React.useState<ThumbnailImagesData>();
  const [getPreviewDocument, setGetPreviewDocument] =
    React.useState<Promise<BlobPart>>();
  const [isOpeningDocumentInNewTab, setIsOpeningDocumentInNewTab] =
    React.useState<Record<string, boolean>>({});
  const [refreshingDocument, setRefreshingDocument] = React.useState<string>();
  const accordionRef = React.useRef<AccordionComponent | null>(null);
  const [isExpanded, setIsExpanded] = React.useState<Record<number, boolean>>();
  const [documentOperations, setDocumentOperations] = React.useState<
    DocumentThumbnailOperation[]
  >([]);

  // Crop States
  const [croppedArgs, setCroppedArgs] =
    React.useState<CroppedChangedEventArgs>();

  useEffect(() => {
    if (!isUndefined(refreshingDocument)) {
      setTimeout(() => {
        setRefreshingDocument(undefined);
      }, 800);
    }
  }, [documentThumbnails]);

  useEffect(() => {
    if (isPageEditorMode) {
      if (croppedArgs?.isCropped && croppedArgs?.base64CropImage) {
        // When crop, it should be the only operation
        setDocumentOperations([
          new DocumentThumbnailCropOperation(
            Number(selectedDocumentThumbnails.at(0)?.pageId),
            { base64Image: croppedArgs.base64CropImage }
          ),
        ]);
      } else {
        setDocumentOperations([]);
      }
    }
  }, [croppedArgs]);

  useEffect(() => {
    props.onDocumentOperationsChanged?.(documentOperations);
  }, [documentOperations]);

  React.useEffect(() => {
    props.onSelectionChanged &&
      props.onSelectionChanged(selectedDocumentThumbnails);
  }, [selectedDocumentThumbnails]);

  React.useEffect(() => {
    props.onPreviewModeChanged &&
      props.onPreviewModeChanged(isDocumentPreviewMode || isPagePreviewMode);
  }, [isDocumentPreviewMode, isPagePreviewMode]);

  React.useEffect(() => {
    props.onPageEditorMode && props.onPageEditorMode(isPageEditorMode);
  }, [isPageEditorMode]);

  React.useEffect(() => {
    props.onRotateMode && props.onRotateMode(isDocumentRotateMode);
  }, [isDocumentRotateMode]);

  const resetMode = () => {
    if (isDocumentPreviewMode) {
      togglePreview(false);
    }
    if (isPagePreviewMode) {
      togglePagePreview(false);
    }
    if (isPageEditorMode) {
      togglePageEditor(false);
    }
    if (isDocumentRotateMode) {
      toggleRotate(false);
    }
  };

  const togglePreview = async (preview: boolean, documentId?: string) => {
    if (isPagePreviewMode) {
      togglePagePreview(false);
    }
    if (isPageEditorMode) {
      togglePageEditor(false);
    }

    setIsDocumentPreviewMode(preview);
    setPreviewDocumentId(documentId);

    if (preview && documentId) {
      setGetPreviewDocument(getDocument(documentId));
    }
  };

  const toggleRotate = (open: boolean, documentId?: string) => {
    if (isDocumentPreviewMode) {
      togglePreview(false);
    }
    if (isPageEditorMode) {
      togglePageEditor(false);
    }
    if (isPagePreviewMode) {
      togglePagePreview(false);
    }
    setIsDocumentRotateMode(open);
    setRotateDocumentId(documentId);
  };

  const togglePagePreview = async (
    preview: boolean,
    data?: ThumbnailImagesData
  ) => {
    if (isDocumentPreviewMode) {
      togglePreview(false);
    }
    if (isPageEditorMode) {
      togglePageEditor(false);
    }

    setIsPagePreviewMode(preview);
    setPreviewPageData(data);
  };

  const togglePageEditor = async (
    preview: boolean,
    data?: ThumbnailImagesData
  ) => {
    if (isDocumentPreviewMode) {
      togglePreview(false);
    }
    if (isPagePreviewMode) {
      togglePagePreview(false);
    }

    setIsPageEditorMode(preview);
    setEditorPageData(data);

    unselectAll();

    if (preview && data) {
      const doxIdx = props.documents.findIndex((x) => x.id === data.documentId);
      if (doxIdx >= 0) {
        documentPagesViewerItemModule.current[doxIdx].select(data);
      }
    }
  };

  const expandingHandler = (args: ExpandEventArgs) => {
    if (isActionIntent) {
      args.cancel = true;
    }

    if (!args.isExpanded && props.disabledCollapse) {
      args.cancel = true;
    }
  };

  const expandedHandler = (args: ExpandEventArgs) => {
    if (args.isExpanded && !isUndefined(args.index)) {
      fetchDocumentThumbnailsByDocumentIdx(args.index);
    }

    if (!isUndefined(args.index)) {
      setIsExpanded({
        ...isExpanded,
        [args.index]: args.isExpanded || false,
      });
    }
  };

  const openDocumentInNewTab = async (document: Document, stamped = false) => {
    isOpeningDocumentInNewTab[document.id] = true;
    setIsOpeningDocumentInNewTab({ ...isOpeningDocumentInNewTab });
    openDocumentNewTab(document, stamped);
    // Set document as opened
    isOpeningDocumentInNewTab[document.id] = false;
    setIsOpeningDocumentInNewTab({ ...isOpeningDocumentInNewTab });
  };

  const openPageInNewTab = async (data?: ThumbnailImagesData) => {
    // Open page in new tab
    if (data?.hdImage) {
      const appWindow = window.open("", "_blank") as Window;

      if (data.isBase64) {
        appWindow.location = await getBase64Url(data.hdImage);
      } else {
        // Get document page from cache or api
        const documentPageBlobPart = await getDocumentPage(
          data.documentId,
          data.pageId
        );

        // Open
        appWindow.location = getDocumentPageBlobUrl(documentPageBlobPart);
      }
    }
  };

  return (
    <section className="document-viewer-container">
      <Observer>
        {() => (
          <section
            className={classNames("document-viewer", {
              "document-viewer-preview-mode": isDocumentPreviewMode,
              "document-viewer-rotate-mode": isDocumentRotateMode,
              "document-viewer-page-preview-mode": isPagePreviewMode,
              "document-viewer-page-editor-mode": isPageEditorMode,
              "document-viewer-disabled-mode": props.disabled,
              "document-viewer-readOnly-mode": props.readOnly,
              "document-viewer-disabled-collapse": props.disabledCollapse,
              "document-viewer-disabled-selection-order":
                props.disabledSelectionOrder,
              "document-viewer-disabled-page-preview":
                props.disabledPagePreview,
              "document-viewer-no-docs": ready && props.documents.length <= 0,
            })}
          >
            {ready && props.documents.length <= 0 && (
              <NonIdealState title="No Documents Available" />
            )}
            {ready && props.documents.length > 0 && (
              <>
                <div
                  className="document-viewer-documents control_wrapper"
                  id="control_wrapper"
                >
                  <AccordionComponent
                    ref={accordionRef}
                    expandedIndices={
                      props.expandAll
                        ? Array(props.documents.length)
                            .fill(undefined)
                            .map((_, index) => index)
                        : []
                    }
                    expanding={expandingHandler}
                    expanded={expandedHandler}
                  >
                    {props.documents.map((doc, docIdx) => (
                      <div key={doc.id}>
                        <div className="document-viewer-header">
                          <ul className="document-viewer-actions">
                            {props.documentItems?.map((item, idx) => (
                              <>
                                {/* Predefined Options */}
                                {item &&
                                  (item as string) in DocumentActionTypes &&
                                  (item as DocumentActionTypes) ===
                                    DocumentActionTypes.SelectAll &&
                                  documentThumbnails?.some(
                                    (x) => x.documentId === doc.id
                                  ) &&
                                  !props.disabledSelectionOrder && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-document-action-types",
                                        `document-viewer-actions-item-${
                                          item as string
                                        }`
                                      )}
                                    >
                                      {isSelectAll[doc.id] && (
                                        <Button
                                          onMouseEnter={() => {
                                            setIsActionIntent(true);
                                          }}
                                          onMouseLeave={() => {
                                            setIsActionIntent(false);
                                          }}
                                          onClick={() => {
                                            documentPagesViewerItemModule.current[
                                              docIdx
                                            ].unselectAll();
                                          }}
                                          disabled={props.disabled}
                                        >
                                          <FontAwesomeIcon
                                            color=""
                                            icon={faSquareCheck}
                                          />{" "}
                                          Unselect All
                                        </Button>
                                      )}
                                      {!isSelectAll[doc.id] && (
                                        <Button
                                          onMouseEnter={() => {
                                            setIsActionIntent(true);
                                          }}
                                          onMouseLeave={() => {
                                            setIsActionIntent(false);
                                          }}
                                          onClick={() => {
                                            documentPagesViewerItemModule.current[
                                              docIdx
                                            ].selectAll();
                                          }}
                                          disabled={props.disabled}
                                        >
                                          <FontAwesomeIcon
                                            color=""
                                            icon={faSquare}
                                          />{" "}
                                          Select All
                                        </Button>
                                      )}
                                    </li>
                                  )}
                                {item &&
                                  (item as string) in DocumentActionTypes &&
                                  (item as DocumentActionTypes) ===
                                    DocumentActionTypes.Preview && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-document-action-types",
                                        `document-viewer-actions-item-${
                                          item as string
                                        }`
                                      )}
                                    >
                                      <Button
                                        icon="zoom-in"
                                        onMouseEnter={() => {
                                          setIsActionIntent(true);
                                        }}
                                        onMouseLeave={() => {
                                          setIsActionIntent(false);
                                        }}
                                        onClick={() => {
                                          togglePreview(true, doc.id);
                                        }}
                                        disabled={props.disabled}
                                      />
                                    </li>
                                  )}
                                {item &&
                                  (item as string) in DocumentActionTypes &&
                                  (item as DocumentActionTypes) ===
                                    DocumentActionTypes.Rotate && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-document-action-types",
                                        `document-viewer-actions-item-${
                                          item as string
                                        }`
                                      )}
                                    >
                                      <Button
                                        text="Rotate"
                                        icon={IconNames.RotatePage}
                                        className="document-viewer-rotate-button"
                                        onMouseEnter={() => {
                                          setIsActionIntent(true);
                                        }}
                                        onMouseLeave={() => {
                                          setIsActionIntent(false);
                                        }}
                                        onClick={() => {
                                          toggleRotate(true, doc.id);
                                        }}
                                        disabled={props.disabled}
                                      />
                                    </li>
                                  )}
                                {item &&
                                  (item as string) in DocumentActionTypes &&
                                  (item as DocumentActionTypes) ===
                                    DocumentActionTypes.OpenInNewTab && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-document-action-types",
                                        `document-viewer-actions-item-${
                                          item as string
                                        }`
                                      )}
                                    >
                                      <Button
                                        icon="document-open"
                                        onMouseEnter={() => {
                                          setIsActionIntent(true);
                                        }}
                                        onMouseLeave={() => {
                                          setIsActionIntent(false);
                                        }}
                                        onClick={() => {
                                          if (doc.documentStatus === 4) {
                                            AppToaster.show({
                                              message: (
                                                <div>
                                                  Something went wrong while
                                                  processing the file. <br />
                                                  Please upload again or contact
                                                  support.
                                                </div>
                                              ),
                                              intent: Intent.DANGER,
                                            });
                                          } else {
                                            openDocumentInNewTab(doc);
                                          }
                                        }}
                                        disabled={
                                          props.disabled ||
                                          isOpeningDocumentInNewTab[doc.id]
                                        }
                                        loading={
                                          isOpeningDocumentInNewTab[doc.id]
                                        }
                                      />
                                      <Button
                                        icon="document-open"
                                        className="stamped-document-open-button"
                                        style={{ marginLeft: "4px" }}
                                        onMouseEnter={() => {
                                          setIsActionIntent(true);
                                        }}
                                        onMouseLeave={() => {
                                          setIsActionIntent(false);
                                        }}
                                        onClick={() => {
                                          if (doc.documentStatus === 4) {
                                            AppToaster.show({
                                              message: (
                                                <div>
                                                  Something went wrong while
                                                  processing the file. <br />
                                                  Please upload again or contact
                                                  support.
                                                </div>
                                              ),
                                              intent: Intent.DANGER,
                                            });
                                          } else {
                                            openDocumentInNewTab(doc, true);
                                          }
                                        }}
                                        disabled={
                                          props.disabled ||
                                          isOpeningDocumentInNewTab[doc.id]
                                        }
                                        loading={
                                          isOpeningDocumentInNewTab[doc.id]
                                        }
                                      >
                                        w/#
                                      </Button>
                                    </li>
                                  )}
                                {item &&
                                  (item as string) in DocumentActionTypes &&
                                  (item as DocumentActionTypes) ===
                                    DocumentActionTypes.Refresh && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-document-action-types",
                                        `document-viewer-actions-item-${
                                          item as string
                                        }`
                                      )}
                                    >
                                      {!documentThumbnails?.some(
                                        (x) => x.documentId === doc.id
                                      ) && (
                                        <Button
                                          icon="refresh"
                                          onMouseEnter={() => {
                                            setIsActionIntent(true);
                                          }}
                                          onMouseLeave={() => {
                                            setIsActionIntent(false);
                                          }}
                                          onClick={() => {
                                            setRefreshingDocument(doc.id);
                                            fetchDocumentThumbnailsByDocumentId(
                                              doc.id
                                            );
                                          }}
                                          disabled={
                                            props.disabled ||
                                            refreshingDocument === doc.id
                                          }
                                          hidden={isUndefined(
                                            isExpanded?.[docIdx]
                                          )}
                                        />
                                      )}
                                    </li>
                                  )}
                                {item instanceof Object &&
                                  "onActionClick" in item && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-custom",
                                        `document-viewer-actions-item-${
                                          (item as DocumentAction<Document>)
                                            ?.name || "noname"
                                        }`,
                                        {
                                          "document-viewer-actions-item-icon-only":
                                            !(item as DocumentAction<Document>)
                                              ?.text,
                                        }
                                      )}
                                    >
                                      <Button
                                        {...item}
                                        onMouseEnter={() => {
                                          setIsActionIntent(true);
                                        }}
                                        onMouseLeave={() => {
                                          setIsActionIntent(false);
                                        }}
                                        onClick={() => {
                                          item.onActionClick &&
                                            item.onActionClick(doc);
                                        }}
                                        disabled={
                                          props.disabled || item.disabled
                                        }
                                      />
                                    </li>
                                  )}
                                {item instanceof Object &&
                                  "render" in item &&
                                  item.render && (
                                    <li
                                      key={`${doc.id}${idx}`}
                                      className={classNames(
                                        "document-viewer-actions-item",
                                        "document-viewer-actions-item-element",
                                        `document-viewer-actions-item-${
                                          (
                                            item as DocumentActionRender<Document>
                                          )?.name || "noname"
                                        }`
                                      )}
                                    >
                                      {item.render(doc)}
                                    </li>
                                  )}
                              </>
                            ))}
                          </ul>
                          <div className="document-viewer-upload-date">
                            <div>
                              Uploaded At:{" "}
                              <b>
                                {parseDateToDateTimeString(doc.uploadedAt!)}{" "}
                              </b>
                            </div>
                          </div>
                          <div className="document-viewer-upload-date">
                            <div>
                              File Size: <b>{formatBytes(doc.fileSize)}</b>
                            </div>
                          </div>
                          <div className="document-viewer-title">
                            {doc.originalFileName}
                            {selectedDocumentThumbnails.some(
                              (x) => x.documentId === doc.id
                            ) && (
                              <span className="document-viewer-selected-count">
                                (
                                {
                                  selectedDocumentThumbnails.filter(
                                    (x) => x.documentId === doc.id
                                  ).length
                                }
                                )
                              </span>
                            )}
                          </div>
                        </div>
                        <div>
                          <div>
                            <DocumentPagesViewerItem
                              ref={(el) =>
                                (documentPagesViewerItemModule.current[docIdx] =
                                  el as DocumentPagesViewerItemModule)
                              }
                              disabled={
                                props.disabled ||
                                props.readOnly ||
                                isDocumentPreviewMode ||
                                isDocumentRotateMode ||
                                isPagePreviewMode ||
                                isPageEditorMode ||
                                props.disabledSelectionOrder
                              }
                              documentId={doc.id}
                              documentStatus={doc.documentStatus}
                              thumbnailImagesData={
                                refreshingDocument === doc.id
                                  ? undefined
                                  : documentThumbnails?.filter(
                                      (x) => x.documentId === doc.id
                                    )
                              }
                              onSelectionChanged={(value) => {
                                selectionChangedHandler(doc.id, value);
                              }}
                              isAnimated={props.isAnimated}
                              onPagePreviewClicked={(
                                data: ThumbnailImagesData
                              ) => {
                                togglePagePreview(true, data);
                              }}
                              onPagePreviewNewTabClicked={(
                                data: ThumbnailImagesData
                              ) => {
                                openPageInNewTab(data);
                              }}
                              onPageEditorClicked={(
                                data: ThumbnailImagesData
                              ) => {
                                togglePageEditor(true, data);
                              }}
                              documentPageItems={[
                                DocumentPageActionTypes.Preview,
                                DocumentPageActionTypes.OpenInNewTab,
                                ...((props.documentPageItems || []) as Array<
                                  | DocumentPageActionTypes
                                  | DocumentPageAction<ThumbnailImagesData>
                                  | DocumentPageActionRender<ThumbnailImagesData>
                                >),
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionComponent>
                </div>
                <div className="document-viewer-preview">
                  <div className="document-viewer-preview-close">
                    <Button
                      icon="zoom-out"
                      onClick={() => {
                        // Turn off preview mode
                        if (isDocumentPreviewMode) {
                          togglePreview(false);
                        }
                        if (isPagePreviewMode) {
                          togglePagePreview(false);
                        }
                        if (isPageEditorMode) {
                          togglePageEditor(false);
                        }

                        if (isDocumentRotateMode) {
                          toggleRotate(false);
                        }
                      }}
                    />
                  </div>
                  <div className="document-viewer-preview-frame">
                    {isDocumentPreviewMode && previewDocumentId && (
                      <DocumentPreview getDocument={getPreviewDocument} />
                    )}
                  </div>
                  <div className="document-viewer-rotate-frame">
                    {isDocumentRotateMode && rotateDocumentId && (
                      <DocumentEditor
                        documentId={rotateDocumentId}
                        onCancel={() => {
                          toggleRotate(false);
                        }}
                        onUpdate={(
                          documentOperations,
                          documentPagesHdImages
                        ) => {
                          const updatedDocumentThumbnails =
                            documentThumbnails?.map((t) => {
                              if (t.documentId === rotateDocumentId) {
                                const pageOperations =
                                  documentOperations.filter(
                                    (o) => o.pageId === t.pageId
                                  );
                                for (const operation of pageOperations) {
                                  if (
                                    operation instanceof
                                    DocumentThumbnailRotateOperation
                                  ) {
                                    t.rotation =
                                      t.rotation === 3 ? 0 : t.rotation + 1;
                                  }
                                }

                                if (documentPagesHdImages) {
                                  const pageHdImage =
                                    documentPagesHdImages[t.pageId];
                                  if (pageHdImage) {
                                    t.hdImage = pageHdImage;
                                    t.isBase64 = true;
                                  }
                                }
                              }

                              return t;
                            });
                          setDocumentThumbnails(updatedDocumentThumbnails);
                          toggleRotate(false);
                        }}
                      />
                    )}
                  </div>
                  <div className="document-viewer-page-preview-frame">
                    {isPagePreviewMode && previewPageData && (
                      <DocumentPagePreview src={previewPageData} />
                    )}
                  </div>
                  <div className="document-viewer-page-editor-frame">
                    {isPageEditorMode && editorPageData && (
                      <DocumentPageEditor
                        src={editorPageData.hdImage}
                        onCroppedChanged={(args) => setCroppedArgs(args)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {!ready && <Loading />}
          </section>
        )}
      </Observer>
    </section>
  );
};

export const DocumentPagesViewer = React.forwardRef<
  DocumentPagesViewerModule,
  DocumentPagesViewerProps
>(DocumentPagesViewerComponent);
