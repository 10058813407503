import { Button, Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { applicationStore } from "../../stores/ApplicationStore";
import { checklistItemStore } from "../../stores/ChecklistItemStore";
import { ChecklistItem } from "../../types/Checklist";
import { Document } from "../../types/Document";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { AppToaster } from "../Toast/Toast";
import { DocumentPagesViewer } from "./DocumentPagesViewer";
import {
  PdfDocumentEntityType,
  PdfDocumentUploader,
} from "./PdfDocumentUploader";
import "./styles.scss";
import {
  DocumentAction,
  DocumentActionRender,
  DocumentActionTypes,
} from "./useDocumentPagesViewer";
import { useDocument } from "./useDocument";
import { getObjectValueClassName } from "../../utils/miscellaneous";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

interface PdfDocumentsProps {
  checklistItem: ChecklistItem;
  setUIChecklistItemFileCount?: (
    checklistItem: ChecklistItem,
    action: string,
    count: number
  ) => void;
  disableUploadDoc?: boolean;
  disableEditDoc?: boolean;
  disableRenameDoc?: boolean;
  disablePreviewDoc?: boolean;
  disableOpenNewTabDoc?: boolean;
  disableDownloadDoc?: boolean;
  disableDeleteDoc?: boolean;
  disableVerifyDoc?: boolean;
}

export const downloadFile = (content: BlobPart, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement("a");
  link.href = url;
  link.target = "_top";
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const PdfDocuments: FC<PdfDocumentsProps> = (props) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [documents, setDocuments] = useState<Document[]>([]);

  const { documentActionTypes } = useDocument();

  const checklistDocumentActionTypes = () => {
    const documentActions = documentActionTypes(
      {
        disableEditDoc: props.disableEditDoc,
        disableRenameDoc: props.disableRenameDoc,
        disablePreviewDoc: props.disablePreviewDoc,
        disableOpenNewTabDoc: props.disableOpenNewTabDoc,
        disableDownloadDoc: props.disableDownloadDoc,
      }
      // refreshChecklistItemDocuments
    );

    if (!props.disableEditDoc) {
      documentActions.push({
        name: "edit",
        minimal: true,
        icon: IconNames.EDIT,
        onActionClick: (doc: Document) => {
          dialogsViewerStore.openDocumentEditorDialog({
            documentId: doc.id,
            onUpdate: () => {
              refreshChecklistItemDocuments();
              checklistItemStore.setChecklistItems();
            },
          });
        },
      } as DocumentAction<Document>);
    }
    if (!props.disableRenameDoc) {
      documentActions.push({
        name: "rename",
        minimal: true,
        icon: IconNames.TEXT_HIGHLIGHT,
        onActionClick: (doc: Document) => {
          dialogsViewerStore.openDocumentRenameDialog({
            documentId: doc.id,
            onUpdate: () => {
              refreshChecklistItemDocuments();
              checklistItemStore.setChecklistItems();
            },
          });
        },
      } as DocumentAction<Document>);
    }

    if (!props.disableOpenNewTabDoc) {
      documentActions.push(DocumentActionTypes.Refresh);
    }

    if (!props.disableDeleteDoc) {
      documentActions.push({
        name: "delete",
        minimal: true,
        icon: IconNames.TRASH,
        intent: Intent.DANGER,
        onActionClick: (doc: Document) => {
          dialogsViewerStore.confirm({
            content: <>Are you sure you would like to delete this document?</>,
            onClose: () => {
              null;
            },
            onConfirm: async () => {
              const result = {
                isSuccess: true,
                error: undefined,
              };
              await documentApiClient.deleteDocument(doc);

              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Document Deleted.
                  </div>
                ),
                intent: Intent.SUCCESS,
              });

              refreshChecklistItemDocuments();
              checklistItemStore.setChecklistItems();
              if (props.setUIChecklistItemFileCount) {
                props.setUIChecklistItemFileCount(
                  props.checklistItem,
                  "minus",
                  1
                );
              }
              return result;
            },
          });
        },
      } as DocumentAction<Document>);
    }
    if (
      !props.disableVerifyDoc &&
      featureToggleStore.featureToggles?.EnableVerify
    ) {
      documentActions.push({
        name: "verify",
        render: (doc: Document) => {
          return (
            <Button
              className={`verified-${getObjectValueClassName(
                (!!doc.isVerified).toString()
              )}`}
              text={doc.isVerified ? "Verified" : "Verify"}
              rightIcon={doc.isVerified ? IconNames.TICK_CIRCLE : undefined}
              disabled={doc.isVerified}
              onClick={() => {
                dialogsViewerStore.setIsVerifyDialogOpen(true, {
                  checklistItemId: props.checklistItem.id,
                  documentId: doc.id,
                  onSave: () => {
                    refreshChecklistItemDocuments();
                    props.setUIChecklistItemFileCount?.(
                      props.checklistItem,
                      "verify",
                      0
                    );
                  },
                });
              }}
            />
          );
        },
      } as DocumentActionRender<Document>);
    }
    documentActions.push({
      name: "document-info",
      render: (doc: Document) => {
        return (
          <div className="document-info-container">
            <ul className="document-info">
              <li className="document-info-item">
                <span className="document-info-item-label">Uploaded By:</span>
                <span className="document-info-item-value">
                  {doc.createdBy_AsName}
                </span>
              </li>
            </ul>
          </div>
        );
      },
    } as DocumentActionRender<Document>);

    return documentActions;
  };

  const refreshChecklistItemDocuments = () => {
    setIsLoadingDocuments(true);
    documentApiClient
      .getDocumentsByEntity(
        PdfDocumentEntityType.Checklist,
        props.checklistItem.id
      )
      .then((documents) => {
        setDocuments(documents);
      })
      .finally(() => {
        setIsLoadingDocuments(false);
      });
  };

  const uploaderElement = document.getElementById(
    `pdf-document__uploader-${props.checklistItem.id}`
  );

  const showDocumentUploader = () => {
    if (!!uploaderElement) {
      uploaderElement.className = "pdf-document__uploader";
    }
  };

  useEffect(() => {
    if (props.checklistItem.id) {
      refreshChecklistItemDocuments();
    }
  }, []);

  return (
    <Observer>
      {() => (
        <div className="pdf-document">
          <div
            id={`pdf-document__uploader-${props.checklistItem.id}`}
            className="pdf-document__uploader"
          >
            {!props.disableUploadDoc && (
              <PdfDocumentUploader
                pdfDocumentEntity={{
                  entityId: props.checklistItem.id,
                  entityType: 3,
                  applicationId: applicationStore.application?.id,
                  useStatementsReceived:
                    props.checklistItem.useStatementsReceived,
                }}
                showDocumentUploader={showDocumentUploader}
                onAllActionComplete={async (args) => {
                  if (props.setUIChecklistItemFileCount) {
                    props.setUIChecklistItemFileCount(
                      props.checklistItem,
                      "plus",
                      args.fileData.length
                    );
                    await checklistItemStore
                      .updateChecklistItem(props.checklistItem.id, {
                        ...props.checklistItem,
                        status: 4,
                      })
                      .then(async () => {
                        await applicationStore.getEligibilitySummary();
                      });
                  }
                }}
                allowMultipleFiles={true}
              />
            )}
          </div>
          <div className="pdf-document__documents">
            {isLoadingDocuments && <Spinner size={36} />}
            {!isLoadingDocuments && (
              <DocumentPagesViewer
                documents={documents}
                isAnimated={false}
                documentItems={checklistDocumentActionTypes()}
                readOnly={true}
                disabledSelectionOrder={true}
                disabledPagePreview={true}
              />
            )}
          </div>
        </div>
      )}
    </Observer>
  );
};
