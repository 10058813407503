import { NonIdealState } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { Application } from "../../../../../types/Application";
import { MedicalCoverage } from "../../../../../types/Application/MedicalCoverage";
import { AddButton } from "../../../../Buttons/AddButton";

import RelativityCard from "../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../Fields/SectionHeader/SectionHeader";

import { newMedicalCoverage } from "../../../../Forms/ApplicationForm/MedicalCoverage";
import { MedicalCoveragePageCard } from "./Owned Entities/MedicalCoveragePageCard";
import _, { orderBy } from "lodash";

export interface MedicalCoveragesPageCardProps {
  application?: Application;
  canCreate: boolean;
  canEdit: boolean;
  createAndUpdateMedicalCoverages: () => Promise<void>;
  deleteMedicalCoverage: (medicalCoverage: MedicalCoverage) => Promise<void>;
  getMedicalCoverageComputedState: (
    value: Partial<MedicalCoverage>
  ) => Promise<Partial<MedicalCoverage>>;
}

const newMedicalCoverageId = "new-medical-coverage";
const sectionTitle = "Medical Coverage";

export const MedicalCoveragesPageCard: FC<MedicalCoveragesPageCardProps> = (
  props
) => {
  const [localMedicalCoverages, setLocalMedicalCoverages] = useState<
    MedicalCoverage[]
  >(props.application?.medicalCoverages || []);

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  const hasNewMedicalCoverage = localMedicalCoverages.some(
    (medicalCoverage) => medicalCoverage.id === newMedicalCoverageId
  );

  useEffect(() => {
    setLocalMedicalCoverages(props.application?.medicalCoverages || []);
  }, [props.application?.medicalCoverages]);

  return (
    <RelativityCard
      headerContent={
        <div className="owned-entity-header medical-coverage-header">
          <SectionHeader
            label={sectionTitle}
            type="Section"
            inAction={formIsSaving}
          />
          {props.canCreate && (
            <AddButton
              loading={isAdding}
              disabled={
                shouldDisableForm || formIsSaving || hasNewMedicalCoverage
              }
              text={`Add ${sectionTitle}`}
              onClick={() => {
                setShouldDisableForm(true);
                setIsAdding(true);
                setLocalMedicalCoverages([
                  ...localMedicalCoverages,
                  {
                    ...newMedicalCoverage,
                    id: newMedicalCoverageId,
                  } as MedicalCoverage,
                ]);
                setShouldDisableForm(false);
                setIsAdding(false);
              }}
            />
          )}
        </div>
      }
    >
      {localMedicalCoverages?.length <= 0 && (
        <NonIdealState title="No Information Available" />
      )}
      {localMedicalCoverages?.length > 0 &&
        orderBy(localMedicalCoverages, ["createdAt"], ["desc"]).map(
          (medicalCoverage, index) => {
            return (
              <MedicalCoveragePageCard
                key={index}
                medicalCoverage={medicalCoverage}
                localMedicalCoverages={localMedicalCoverages}
                setLocalMedicalCoverages={setLocalMedicalCoverages}
                index={index}
                canEdit={props.canEdit}
                createAndUpdateMedicalCoverages={
                  props.createAndUpdateMedicalCoverages
                }
                deleteMedicalCoverage={props.deleteMedicalCoverage}
                getMedicalCoverageComputedState={
                  props.getMedicalCoverageComputedState
                }
                isAdding={isAdding}
                shouldDisableForm={shouldDisableForm}
                setShouldDisableForm={setShouldDisableForm}
                formIsSaving={formIsSaving}
                setFormIsSaving={setFormIsSaving}
                applicationId={props.application?.id}
              />
            );
          }
        )}
    </RelativityCard>
  );
};
