import { Button, Intent } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import {
  Asset,
  AssetTypes,
  NewAsset,
} from "../../../../types/Application/Asset";
import { AddButton } from "../../../Buttons/AddButton";
import RelativityCard from "../../../Card/RelativityCard";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import { newAsset } from "../../../Forms/ApplicationForm/Assets";
import { FormSchemaBuilder } from "../../../Forms/types";
import "../../styles.scss";
import { AssetFormCard } from "./AssetFormCard";
import _, { orderBy } from "lodash";

export interface AssetsFormCardProps {
  assets?: Asset[];
  applicationId?: string;
  assetType: AssetTypes;
  title: string;
  assetFormSchema: FormSchemaBuilder;
  createAsset: (asset: NewAsset) => Promise<void>;
  updateAsset?: () => Promise<void>;
  deleteAsset?: (asset: Asset) => Promise<void>;
  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  showCancelButton: boolean;
  onCancel?: () => void;
  shouldSaveEntityToLocalStore: boolean;
  showAddButton: boolean;
}

export const newAssetId = "new-asset";
export const AssetsFormCard: FC<AssetsFormCardProps> = ({
  assets,
  assetType,
  applicationId,
  title,
  deleteAsset,
  assetFormSchema,
  createAsset,
  updateAsset,
  shouldDisableForm,
  setShouldDisableForm,
  formIsSaving,
  setFormIsSaving,
  showCancelButton,
  onCancel,
  shouldSaveEntityToLocalStore,
  showAddButton,
}) => {
  const [localAssets, setLocalAssets] = useState<Asset[]>(assets || []);
  const [isAddingAsset, setIsAddingAsset] = useState<boolean>(false);

  useEffect(() => {
    setLocalAssets(assets || []);
  }, [assets]);

  return (
    <RelativityCard
      headerContent={
        <div className="owned-entity-header asset-header">
          <SectionHeader type="Section" label={title} inAction={formIsSaving} />
          {!!showCancelButton && (
            <Button
              minimal
              intent={Intent.DANGER}
              text="Cancel"
              onClick={onCancel}
            />
          )}
          {!showCancelButton && showAddButton && (
            <AddButton
              loading={isAddingAsset}
              disabled={shouldDisableForm || formIsSaving}
              text="Add Asset"
              onClick={async () => {
                setShouldDisableForm(true);
                setIsAddingAsset(true);
                /**
                 * Unfortunately, when mounting Syncfusion component
                 * specifically the Instuition Component
                 * The component hijack the reference in Application Store
                 * and is forcing Application Form level refersh
                 *
                 * This then will clear out the child state, to combat this at this moment
                 * We will create database record on Add Asset
                 */

                await createAsset({ ...newAsset, type: assetType });

                setShouldDisableForm(false);
                setIsAddingAsset(false);
              }}
            />
          )}
        </div>
      }
    >
      {orderBy(localAssets, ["createdAt"], ["desc"]).map((asset, index) => {
        return (
          <AssetFormCard
            key={index}
            index={index}
            asset={asset}
            applicationId={applicationId}
            assetType={assetType}
            title={title}
            assetFormSchema={assetFormSchema}
            deleteAsset={deleteAsset}
            updateAsset={updateAsset}
            createAsset={createAsset}
            formIsSaving={formIsSaving}
            setFormIsSaving={setFormIsSaving}
            shouldSaveEntityToLocalStore={shouldSaveEntityToLocalStore}
            shouldDisableForm={shouldDisableForm}
            setShouldDisableForm={setShouldDisableForm}
            localAssets={localAssets}
            setLocalAssets={setLocalAssets}
          />
        );
      })}
    </RelativityCard>
  );
};
