import {
  AutoCompleteComponent,
  AutoCompleteModel,
} from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import "./styles.scss";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import variables from "../../config/variables";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { applicationSearchToolbarStore } from "./stores/ApplicationSearchToolbarStore";

export interface ApplicationSearchToolbarProps {}
export const ApplicationSearchToolbar: React.FC<
  ApplicationSearchToolbarProps
> = () => {
  const autoCompleteRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);
  const [autoCompleteSettings, setAutoCompleteSettings] = React.useState<
    AutoCompleteModel & DefaultHtmlAttributes
  >();

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      applicationSearchToolbarStore.reset();
    };
  }, []);

  const setup = async () => {
    const autoCompleteModel = await applicationSearchToolbarStore.setup({
      odataUrl: `${variables.apiBaseUrl}odata/applicationdeepsearchodata/options`,
      getAccessToken: getAccessToken,
    });

    setAutoCompleteSettings(autoCompleteModel);
    setReady(true);
  };

  React.useEffect(() => {
    if (autoCompleteRef.current) {
      applicationSearchToolbarStore.initialize(autoCompleteRef.current);
    }
  }, [ready, autoCompleteRef]);

  return (
    <div className="preview-toolbar">
      {ready && (
        <div className="search-expressions-input-container">
          <AutoCompleteComponent
            ref={autoCompleteRef}
            id="preview-search-list"
            className="search-expressions-input"
            popupWidth="520px"
            {...autoCompleteSettings}
          />
          <Icon icon={IconNames.Search} size={14} />
        </div>
      )}
    </div>
  );
};
