import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../../stores/ApplicationStore";
import { Application } from "../../../../../types/Application";
import { SpouseInformation } from "../../../../../types/Application/SpouseInformation";
import { SaveButton } from "../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../Buttons/UndoButton";
import RelativityCard from "../../../../Card/RelativityCard";
import { RelativityCardHeader } from "../../../../Card/RelativityCardHeader";
import Form from "../../../../Forms";
import { spouseInformationFormSchema } from "../../../../Forms/ApplicationForm/SpouseInformation";
import { showSuccessfulSaveAppToaster } from "../../../../Toast/Toast";

export interface SpouseInformationCardProps {
  application?: Application;
  canEdit: boolean;
  submitForm: (application: SpouseInformation) => Promise<void>;
}

export const SpouseInformationPageCard: FC<SpouseInformationCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const formId = "spouse-information-form";
  const sectionTitle = "Spouse";
  const cardClassName = "application-page spouse-information-card";

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<Application>(
    application as Application
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  return (
    <RelativityCard
      className={cardClassName}
      headerContent={
        <RelativityCardHeader label={sectionTitle}>
          <div>
            {canEdit && (
              <>
                <>
                  <SaveButton formId={formId} isSaving={isSaving} />
                  <UndoButton
                    isSaving={isSaving}
                    onClick={async () => {
                      setShouldResetForm(true);
                      await handleReset();
                      setShouldResetForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </RelativityCardHeader>
      }
    >
      <Observer>
        {() => (
          <Form<SpouseInformation>
            formId={formId}
            shouldBlockNavigation={true}
            value={activeFormValue}
            shouldResetForm={shouldResetForm}
            disableAllFields={!canEdit}
            formSchemaBuilder={spouseInformationFormSchema}
            hideSectionHeader={true}
            onFormSubmit={async (application) => {
              setIsSaving(true);
              await submitForm(application)
                .then(() => {
                  showSuccessfulSaveAppToaster(sectionTitle);
                })
                .finally(() => {
                  setIsSaving(false);
                });
            }}
          />
        )}
      </Observer>
    </RelativityCard>
  );
};
