import { ExistingResource } from "./ExistingResource";

export enum CommentLevel {
  Public = 1,
  Internal = 2,
}

export enum CommentSource {
  Document = 1,
  File = 2,
  FIA = 3,
  StateSubmission = 4,
}

export const getCommentSource = (source: number) => {
  switch (source) {
    case CommentSource.Document:
      return "Document";
    case CommentSource.File:
      return "File";
    case CommentSource.FIA:
      return "FIA";
    case CommentSource.StateSubmission:
      return "State Submission";
    default:
      return "";
  }
};

export const getCommentLevel = (level: number) => {
  switch (level) {
    case CommentLevel.Public:
      return "Public";
    case CommentLevel.Internal:
      return "Internal";
    default:
      return "";
  }
};

export interface NewComment {
  applicationId: string;
  sourceId: string;
  message: string | undefined;
  level: number;
  isPinned: boolean;
  source: number;
  pinnedDate: Date;
  application_FileNumber: string;
  sourceName: string;
}

export type CommentFilters = {
  applicationIds: string[];
  levels: string[];
  sources: string[];
  createdAtRange: [Date | null, Date | null];
  createdByIds: string[];
  sortBy: string | undefined;
  sortAscending: boolean;
};

export type Comment = ExistingResource<NewComment>;
