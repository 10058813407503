import { Schema } from "../../../stores/SchemaStore";
import baseApiClient from "../baseApiClient";

class AppSettingsApiClient {
  getSchema = async () => {
    return baseApiClient.get<Schema>(`app-settings/schema`);
  };
}

export const appSettingsApiClient = new AppSettingsApiClient();
