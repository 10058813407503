import { get } from "lodash";
import { makeObservable, observable, runInAction } from "mobx";
import {
  AllFields,
  AllFieldValues,
  FieldType,
  Option,
} from "../components/Fields/types/fieldTypes";
import { appSettingsApiClient } from "../lib/apiClients/appSettings/appSettingsApiClient";

export interface Schema {
  schema: { [key: string]: FieldSchemas };
  mappings: { [key: string]: Option[] };
}

export interface FieldSchemas {
  [key: string]: {
    type: string;
    type_AsString: string;
    label: string;
    description: unknown;
    default: unknown;
    options: Option[];
  };
}

export interface FieldSchema {
  type: string;
  type_AsString: string | FieldType;
  label: string;
  description: string;
  default: unknown;
  optionValuesODataUrl: string;
  options: Option[];
}

export class SchemaStore {
  public schema?: Schema;
  constructor() {
    makeObservable(this, {
      schema: observable,
    });
  }

  async initSchema() {
    const schema = await appSettingsApiClient.getSchema();

    runInAction(() => {
      this.schema = schema;
    });
  }

  getMappingsByName(name: string): Option[] {
    const options: Option[] | undefined = get(this.schema, `mappings.${name}`);
    if (!options) {
      return [];
    }

    return options;
  }

  getFieldSchemaByName(
    modelName: string,
    fieldName: string
  ): Partial<AllFields> {
    if (!this.schema) {
      throw new Error("Schema not initialized");
    }

    const fieldSchema = get(this.schema, `schema.${modelName}.${fieldName}`);

    if (!fieldSchema) {
      throw new Error(`Missing Field Schema ${modelName}.${fieldName}`);
    }

    return {
      label: (fieldSchema as FieldSchema).label,
      type: (fieldSchema as FieldSchema).type_AsString as any,
      description: (fieldSchema as FieldSchema).description,
      optionValues: (fieldSchema as FieldSchema).options,
      defaultValue: (fieldSchema as FieldSchema).default as AllFieldValues,
    };
  }
}

export const schemaStore = new SchemaStore();
