import {
  GridComponent,
  GridModel,
  RowDragEventArgs,
} from "@syncfusion/ej2-react-grids";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Query } from "@syncfusion/ej2-data";
import _ from "lodash";
import {
  OrderedDocumentReadDTO,
  OrderedDocumentReorderDTO,
} from "../configurations/types";
import {
  orderedDocumentGridColumnsConfiguration,
  setupActions,
} from "../configurations/gridColumnsConfiguration";
import { action, makeObservable, observable } from "mobx";
import { ODataStore } from "../../../stores/ODataStore";

export class OrderedDocumentsGridStore extends ODataStore {
  // public
  public gridInstance?: GridComponent;
  public reorderAction?: (rowData: OrderedDocumentReorderDTO) => void;

  constructor() {
    super();

    makeObservable(this, {
      gridInstance: observable,
      setInstance: action,
    });
  }

  rowDropHandler = async (args: RowDragEventArgs) => {
    if (!args?.data || args.dropIndex === undefined) {
      return;
    }

    const data = _.first(args.data) as OrderedDocumentReadDTO;
    const orderedDocumentReorderDTO = {
      discriminatorId: data.discriminatorId,
      order: args.dropIndex + 1,
    } as OrderedDocumentReorderDTO;

    this.reorderAction && (await this.reorderAction(orderedDocumentReorderDTO));
    this.gridInstance?.refresh();
  };

  private defaultPdfViewerSettings: GridModel & DefaultHtmlAttributes = {
    enableInfiniteScrolling: false,
    clipMode: "EllipsisWithTooltip",
    gridLines: "Both",
    allowSelection: false,
    loadingIndicator: { indicatorType: "Shimmer" },
    enableVirtualMaskRow: true,
  };

  public setup = async (args: {
    odataUrl: string;
    getAccessToken: () => Promise<string | undefined>;
    odataQueryParams?: { key: string; value: Function | string | null }[];
    previewAction?: (rowData: OrderedDocumentReadDTO) => void;
    downloadAction?: (rowData: OrderedDocumentReadDTO) => void;
    deleteAction?: (rowData: OrderedDocumentReadDTO) => void;
    reorderAction?: (rowData: OrderedDocumentReorderDTO) => void;
  }): Promise<GridModel & DefaultHtmlAttributes> => {
    await this.init(args.getAccessToken);

    // Setup Properties
    this.reorderAction = args.reorderAction;

    // Setup Settings
    const settings = { ...this.defaultPdfViewerSettings } as GridModel &
      DefaultHtmlAttributes;

    settings.dataSource = this.setupODataSource(
      args.odataUrl,
      orderedDocumentGridColumnsConfiguration
    );

    // Setup Query
    let query = new Query()
      .select(orderedDocumentGridColumnsConfiguration.map((x) => x.field))
      .sortBy("order");

    if (args.odataQueryParams) {
      args.odataQueryParams.forEach((param) => {
        query = query.addParams(param.key, param.value);
      });
    }

    settings.query = query;

    // Setup Actions
    settings.columns = setupActions(
      args.previewAction,
      args.downloadAction,
      args.deleteAction &&
        (async (rowData: OrderedDocumentReadDTO) => {
          if (args.deleteAction) {
            await args.deleteAction(rowData);
            orderedDocumentsGridStore.gridInstance?.refresh();
          }
        })
    );

    if (this.reorderAction) {
      settings.allowRowDragAndDrop = true;
      settings.rowDrop = this.rowDropHandler;
    }

    settings.actionFailure = this.baseActionFailureEvent(() => {
      this.gridInstance?.refresh();
    });
    settings.actionComplete = this.baseActionCompleteEvent();

    return settings;
  };

  public initialize = async (newGridInstance: GridComponent) => {
    this.setInstance(newGridInstance);
  };

  public reset = () => {
    this.setInstance(undefined);
  };

  public setInstance = (scopeGridInstance?: GridComponent) => {
    this.gridInstance = scopeGridInstance;
  };
}

export const orderedDocumentsGridStore = new OrderedDocumentsGridStore();

export interface OrderedDocumentsGridSetup {
  odataUrl: string;
  getAccessToken: () => Promise<string | undefined>;
  odataQueryParams: { key: string; value: Function | string | null }[];
  previewAction?: (rowData: OrderedDocumentReadDTO) => void;
  downloadAction?: (rowData: OrderedDocumentReadDTO) => void;
  deleteAction?: (rowData: OrderedDocumentReadDTO) => void;
  reorderAction?: (rowData: OrderedDocumentReorderDTO) => void;
}
