import { useEffect, useState } from "react";
import { checklistApiClient } from "../../../lib/apiClients/checklist/checklistApiClient";
import {
  ChecklistItem,
  ChecklistItemDocCategories,
} from "../../../types/Checklist";
import {
  assetTypeString,
  incomeTypeString,
  useOwnedEntityComponent,
} from "../../Application/UseOwnedEntityComponent";
import { applicationStore } from "../../../stores/ApplicationStore";
import { fiaRequestApiClient } from "../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import {
  FIARequest,
  FIARequestFollowUpActionTypes,
} from "../../FIARequests/configurations/types";

import { Document, DocumentVerifyDTO } from "../../../types/Document";
import { newFIARequestResendDTO } from "../../Dialogs/FIARequestResendDialog";
import { documentApiClient } from "../../../lib/apiClients/document/documentApiClient";
import { VerifyFormProps } from "./VerifyForm";

export const shouldVerifyChecklistDocument = (checklistItem: ChecklistItem) => {
  return (
    checklistItem.entityType === assetTypeString ||
    checklistItem.entityType === incomeTypeString ||
    checklistItem.documentCategory === ChecklistItemDocCategories.Identification
  );
};

export const newDocumentVerifyDTO: DocumentVerifyDTO = {
  applicationId: undefined,
  fiaRequestIds: [],
  fiaRequestAction: undefined,
  followUpReason: newFIARequestResendDTO.followUpReason,
  followUpSecondaryReason: newFIARequestResendDTO.followUpSecondaryReason,
};

export const useVerifyComponent = (
  checklistItemId: string | undefined,
  documentId: string | undefined
) => {
  const {
    getOwnedEntityByChecklistItemEntityType,
    resetOwnedEntityStates,
    assetTypeSchema,
    ownedEntityTypeString,
    setOwnedEntityTypeString,
    existingOwnedEntity,
  } = useOwnedEntityComponent();

  const [document, setDocument] = useState<Document | undefined>(undefined);
  const [checklistItem, setChecklistItem] = useState<ChecklistItem | undefined>(
    undefined
  );
  const [outstandingFiaRequests, setOutstandingFiaRequests] = useState<
    FIARequest[]
  >([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const applicationId = applicationStore.application?.id || "";

  const newDocumentVerifyDTO: DocumentVerifyDTO = {
    applicationId: applicationId,
    fiaRequestIds: [],
    fiaRequestAction: undefined,
    followUpReason: newFIARequestResendDTO.followUpReason,
  };

  const [documentVerifyDTO, setDocumentVerifyDTO] =
    useState<DocumentVerifyDTO>(newDocumentVerifyDTO);
  const refreshChecklistItem = () => {
    checklistItemId &&
      checklistApiClient
        .getChecklistItemById(checklistItemId)
        .then((checklistItem) => setChecklistItem(checklistItem));
  };

  const refreshOwnedEntity = () => {
    applicationStore.application?.id &&
      applicationStore.getApplicationById(applicationStore.application.id);
    checklistItem && getOwnedEntityByChecklistItemEntityType(checklistItem);
  };

  const mapFiaRequestIds = (fiaRequests: FIARequest[]) => {
    return fiaRequests.map((fiaRequest) => fiaRequest.id || "");
  };
  const getOutstandingFiaRequests = (checklistItemId: string) => {
    applicationStore.application?.id &&
      fiaRequestApiClient
        .getSubmittedRequestsByChecklistItemId(
          applicationStore.application?.id,
          checklistItemId
        )
        .then((fiaRequests) => {
          setOutstandingFiaRequests(fiaRequests);
          setDocumentVerifyDTO({
            ...newDocumentVerifyDTO,
            fiaRequestIds: mapFiaRequestIds(fiaRequests),
            applicationId: applicationStore.application?.id || "",
          });
        });
  };

  const getDocument = (documentId: string) => {
    documentApiClient
      .getDocument(documentId)
      .then((document) => setDocument(document));
  };

  useEffect(() => {
    checklistItemId &&
      checklistApiClient
        .getChecklistItemById(checklistItemId)
        .then((checklistItem) => {
          setChecklistItem(checklistItem);
          getOwnedEntityByChecklistItemEntityType(checklistItem);
          getOutstandingFiaRequests(checklistItemId);
        });

    documentId && getDocument(documentId);

    return () => {
      setChecklistItem(undefined);
      resetOwnedEntityStates();
      setOutstandingFiaRequests([]);
      setDocumentVerifyDTO(newDocumentVerifyDTO);
      setDocument(undefined);
    };
  }, [checklistItemId, documentId]);

  const showDocStatementsReceived = !!(
    checklistItem &&
    checklistItem.useStatementsReceived &&
    existingOwnedEntity
  );

  const showIdentification = !!(
    checklistItem &&
    checklistItem.documentCategory_AsString === "Identification"
  );

  const showResidentIdentification = !!(
    checklistItem &&
    checklistItem.entityType === "Resident" &&
    checklistItem.documentCategory_AsString === "Identification"
  );

  const showSpouseIdentification = !!(
    checklistItem &&
    checklistItem.entityType === "Spouse" &&
    checklistItem.entityId &&
    checklistItem.documentCategory_AsString === "Identification"
  );

  const showContactIdentification = !!(
    checklistItem &&
    checklistItem.entityType !== "Spouse" &&
    checklistItem.entityId &&
    checklistItem.documentCategory_AsString === "Identification"
  );

  const showFiaRequests = outstandingFiaRequests.length > 0;

  const fiaFollowUpReasonNotSelected =
    showFiaRequests &&
    documentVerifyDTO.fiaRequestAction ===
      FIARequestFollowUpActionTypes.Resend &&
    !documentVerifyDTO.followUpReason;

  const isDocumentVerified = !!document?.isVerified;

  const verifyFormProperties: Omit<VerifyFormProps, "onCancel"> = {
    checklistItem,
    refreshChecklistItem,
    assetTypeSchema,
    ownedEntityTypeString,
    setOwnedEntityTypeString,
    existingOwnedEntity,
    showDocStatementsReceived,
    showIdentification,
    showResidentIdentification,
    showSpouseIdentification,
    showContactIdentification,
    refreshOwnedEntity,
    outstandingFiaRequests,
    showFiaRequests,
    documentVerifyDTO,
    setDocumentVerifyDTO,
    isSaving,
    setIsSaving,
  };
  return {
    verifyFormProperties,
    fiaFollowUpReasonNotSelected,
    isDocumentVerified,
  };
};
