import { Button, Intent } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { reactPlugin } from "../../AppInsights";
import "../../pages/styles.scss";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { GridFiltersModule } from "../Grid/Filters/Filters";
import {
  getGridColumnsConfigurationByRole,
  gridColumnsConfiguration,
} from "../Grid/Grid/configurations/gridColumnsConfiguration";
import { GridPermissions } from "../Grid/types/GridPermissions";
import { GridViewPicker } from "../Grid/Views/GridViewPicker";
import { useState } from "react";
import { InternalUserGrid } from "./UserGridCannedViews/InternalUserGrid";
import { UserCannedViewPicker } from "./UserCannedViewPicker";
import { ExternalUserGrid } from "./UserGridCannedViews/ExternalUserGrid";
import { GridFilterSettings } from "../Grid/Grid/types/GridFilterSettings";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../lib/apiClients/baseApiClient";
import { autorun } from "mobx";
import { gridStore } from "../Grid/Grid/stores/GridStore";

export interface BoardProps extends GridPermissions {}

const UserGridComponent: React.FC<BoardProps> = ({
  canConfigureBoard,
  canExportGridToExcel,
  canInvokeGridRecord,
  canCreateNewRecord,
}) => {
  const gridKey = "user-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "User Board",
    gridColumnsConfiguration,
    getGridColumnsConfigurationByRole,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  const [gridCannedView, setGridCannedView] = useState<string>("External");

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>View</li>
          <li>
            <UserCannedViewPicker setGridCannedView={setGridCannedView} />
          </li>
          <li>
            {/* Grid Views Module - Picker */}
            {canConfigureBoard && (
              <GridViewPicker
                gridView={gridView.currentGridView}
                baseGridColumnsConfiguration={
                  gridView.baseGridColumnsConfiguration
                }
                updateViewColumnSettings={gridView.updateViewColumnSettings}
              />
            )}
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            {/* Grid Filters Module */}
            <GridFiltersModule />
          </li>
          <li>
            {canCreateNewRecord && (
              <Button
                intent={Intent.PRIMARY}
                text="Create New User"
                onClick={() =>
                  dialogsViewerStore.setIsUserCreationDialogOpen(true)
                }
              />
            )}
          </li>
        </ul>
      </section>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        {/* User Admin Grid */}
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && gridCannedView === "External" && (
                <ExternalUserGrid
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                />
              )}
              {!!gridView.currentGridView && gridCannedView === "Internal" && (
                <InternalUserGrid
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                />
              )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};

export const UserGrid = withAITracking(
  reactPlugin,
  UserGridComponent,
  "UserGrid",
  "AI-flex-container"
);
