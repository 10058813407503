import { computed, makeObservable } from "mobx";
import { applicationStore } from "./ApplicationStore";

export class PermissionStore {
  constructor() {
    makeObservable(this, {
      canSubmitApplication: computed,
      canSubmitToState: computed,
    });
  }

  get canSubmitApplication() {
    return applicationStore.application?.progress === 100;
  }

  get canSubmitToState() {
    return applicationStore.application?.progressSubmitToState === 100;
  }
}

export const permissionStore = new PermissionStore();
