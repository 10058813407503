import { ExistingResource } from "../ExistingResource";

export enum SignatureTypes {
  Resident = 1,
  ResidentPoa = 2,
  Spouse = 3,
  SpousePoa = 4,
  Witness = 5,
  Guardianship = 6,
}

export interface NewSignature {
  type?: SignatureTypes;
  contactId: string;
  type_AsString: string;
  signatureHolderName?: string;
  signatureBase64?: string;
  timestamp?: Date;
}

export interface SignatureHolderReadDTO {
  contactId: string;
  types?: SignatureTypes[];
  types_AsString: string[];
  signatureHolderName?: string;
  signatureBase64?: string;
  timestamp?: Date;
  isMark?: boolean;
  canSetIsMark?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export interface SignaturesValidationDetailsDTO {
  conditions?: string[];
  requiredSignatures?: string[];
  requiredSignaturesData?: SignatureHolderReadDTO[];
  residentGovermentIdRequirePOAGuardian?: boolean;
  spouseGovermentIdRequirePOA?: boolean;
}

export type Signature = ExistingResource<NewSignature>;
