import { Button, Classes, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import classnames from "classnames";
import React, { useEffect, useRef } from "react";
import { ChatBotComponent } from "./ChatBotComponent";
import { useChatbot } from "./useChatbot";
import { Loading } from "../Loading/Loading";

interface PageSidebarChatBotProps {
  onOpenChanged?: (isOpen: boolean) => void;
  disabled?: boolean;
}
export const PageSidebarChatBot: React.FC<PageSidebarChatBotProps> = (
  props
) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [newConversation, setNewConversation] = React.useState(false);
  const startOpenConversationMinimized = useRef<boolean | undefined>();
  const componentLoaded = useRef<boolean>(false);

  const chatBot = useChatbot(() => setIsOpen(false));
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    props.onOpenChanged && props.onOpenChanged(isOpen);
  }, [isOpen]);

  // Check Open Conversations
  useEffect(() => {
    (async () => {
      // If we want a new conversation and none has started, start one
      if (newConversation && !chatBot.conversation) {
        const openConversation = await chatBot.getOpenConversation();
        if (openConversation) {
          await startConversation(startOpenConversationMinimized.current);
          startOpenConversationMinimized.current = undefined;
        }
      }
    })();
  }, [newConversation]);

  useEffect(() => {
    if (!componentLoaded.current) {
      const chatMinimized = queryParams.get("chatMinimized");
      startOpenConversationMinimized.current =
        chatMinimized?.toLowerCase() === "true";

      componentLoaded.current = true;
      setNewConversation(true);
    }
  }, []);

  const startConversation = async (startMinimized?: boolean) => {
    if (!chatBot.conversation) {
      await chatBot.startConversation();

      setIsMinimized(startMinimized || false);
      setIsOpen(!startMinimized);
      setNewConversation(false);
    }
  };

  const endConversation = async (
    wasResolved: boolean,
    startNewConversation?: boolean
  ) => {
    await chatBot.endConversation(wasResolved);

    if (startNewConversation) {
      setNewConversation(true);
    } else {
      setIsOpen(false);
    }
  };

  const minimizeConversation = () => {
    if (!chatBot.conversation) {
      endConversation(true);
      return;
    }

    setIsMinimized(true);
    setIsOpen(false);
  };

  const maximizeConversation = () => {
    setIsMinimized(false);
    setIsOpen(true);
  };

  const sidebarButtonClickHandler = () => {
    if (!isOpen && !chatBot.conversation) {
      startConversation();
      return;
    }

    if (isMinimized) {
      maximizeConversation();
      return;
    } else {
      minimizeConversation();
      return;
    }
  };

  return (
    <div
      className={classnames("page-sidebar-item", {
        "page-sidebar-item__selected": isOpen || isMinimized,
        "page-sidebar-item__attention": isMinimized,
      })}
    >
      <Popover2
        interactionKind="click"
        popoverClassName={classnames(
          "page-sidebar-item-popover",
          "page-sidebar-item-popover__chatbot",
          Classes.POPOVER_CONTENT_SIZING
        )}
        portalClassName="page-sidebar-item-popover-portal page-sidebar-item-popover-portal__chatbot"
        placement="right"
        isOpen={isOpen}
        hasBackdrop={false}
        content={
          <>
            {chatBot.isLoadingConversation && <Loading />}
            {!chatBot.isLoadingConversation && (
              <ChatBotComponent
                key={chatBot.conversation?.id}
                chatBot={chatBot}
                endConversationHandler={endConversation}
                minimizeConversationHandler={minimizeConversation}
              />
            )}
          </>
        }
      >
        <Button
          minimal={true}
          intent={Intent.PRIMARY}
          onClick={sidebarButtonClickHandler}
          disabled={props.disabled}
        >
          <Icon icon={IconNames.Chat} size={20} />
          {isMinimized && (
            <span className="minimize-badge e-badge e-badge-primary">
              {chatBot.conversation && chatBot.conversation?.messages.length}
            </span>
          )}
        </Button>
      </Popover2>
    </div>
  );
};
