import {
  Button,
  Card,
  Elevation,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC, useEffect, useState } from "react";
import { Application } from "../../../types/Application";
import {
  ApplicationContact,
  ExistingApplicationContact,
} from "../../../types/Application/ApplicationContact";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import { DeleteButton } from "../../Buttons/DeleteButton";
import RelativityCard from "../../Card/RelativityCard";
import { SectionHeader } from "../../Fields/SectionHeader/SectionHeader";
import Form from "../../Forms";
import {
  applicationContactFormSchema,
  newApplicationContact,
} from "../../Forms/ApplicationForm/ApplicationContact";
import { AppToaster } from "../../Toast/Toast";
import "../styles.scss";
import { applicationStore } from "../../../stores/ApplicationStore";
import { Signature } from "../../../types/Application/Signature";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import _, { orderBy } from "lodash";
import classNames from "classnames";
import { getApplicationStatusClassName } from "../../../utils/miscellaneous";

export interface ApplicationContactCardProps {
  application?: Application;
  canEdit?: boolean;
  createContact: (contact: ApplicationContact) => Promise<void>;
  updateContact: (contact: ExistingApplicationContact) => Promise<void>;
  deleteContact: (contact: ExistingApplicationContact) => Promise<void>;
}

const newApplicationContactId = "new-application-contact";

export const ApplicationContactCard: FC<ApplicationContactCardProps> = ({
  application,
  canEdit,
  createContact,
  updateContact,
  deleteContact,
}) => {
  const [localApplicationContacts, setLocalApplicationContacts] = useState<
    ExistingApplicationContact[]
  >(application?.contacts || []);
  const [busyContact, setBusyContact] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const isActiveContact = (contact: ExistingApplicationContact) => {
    return contact.id === busyContact;
  };

  const hasNewApplicationContact = localApplicationContacts.some(
    (contact) => contact.id === newApplicationContactId
  );

  const isActiveDelete = (contact: ExistingApplicationContact) => {
    return isActiveContact(contact) && !!isDeleting;
  };

  const isActiveSaving = (contact: ExistingApplicationContact) => {
    return isActiveContact(contact) && !isDeleting;
  };

  useEffect(() => {
    setLocalApplicationContacts(application?.contacts || []);
  }, [application?.contacts]);

  return (
    <>
      <Card>
        <RelativityCard
          headerContent={
            <div className="owned-entity-header application-contact-header">
              <SectionHeader label="Contact Information" type="Section" />
              <Button
                intent={Intent.PRIMARY}
                disabled={hasNewApplicationContact || !canEdit}
                text="Add Contact"
                minimal
                icon={IconNames.Plus}
                onClick={() => {
                  const contact = {
                    ...newApplicationContact,
                    id: newApplicationContactId,
                  } as ExistingApplicationContact;

                  setLocalApplicationContacts([
                    ...localApplicationContacts,
                    contact,
                  ]);
                }}
              />
            </div>
          }
        >
          {localApplicationContacts?.length <= 0 && (
            <NonIdealState title="No Information Available" />
          )}
          {localApplicationContacts?.length > 0 &&
            orderBy(localApplicationContacts, ["createdAt"], ["desc"]).map(
              (applicationContact, index) => {
                const isNewApplicationContact =
                  applicationContact.id === newApplicationContactId;

                return (
                  <RelativityCard
                    elevation={Elevation.TWO}
                    key={applicationContact.id}
                    className={classNames(
                      "owned-entity-card",
                      "application-contact-card",
                      getApplicationStatusClassName(
                        application?.status_AsString
                      )
                    )}
                    headerContent={
                      <div className="owned-entity-header application-contact-card-header">
                        <SectionHeader
                          label={`Contact ${index + 1}`}
                          type="Section"
                        />
                        <div>
                          <DeleteButton
                            isDeleting={isActiveDelete(applicationContact)}
                            disabled={
                              isActiveSaving(applicationContact) || !canEdit
                            }
                            onClick={async () => {
                              setIsDeleting(true);
                              setBusyContact(applicationContact.id);

                              if (isNewApplicationContact) {
                                setLocalApplicationContacts(
                                  localApplicationContacts.filter(
                                    (applicationContacts) =>
                                      applicationContacts.id !==
                                      newApplicationContactId
                                  )
                                );
                              } else {
                                const contactHaveSignature =
                                  applicationStore.application?.signatures.some(
                                    (signature: Signature) =>
                                      signature.contactId ===
                                      applicationContact.id
                                  );

                                if (contactHaveSignature) {
                                  dialogsViewerStore.setIsConfirmDialogOpen(
                                    true,
                                    {
                                      content:
                                        "There is an active signature for this contact, are you sure you want delete?",
                                      onConfirm: async () => {
                                        await deleteContact(applicationContact);
                                        setIsDeleting(false);
                                        setBusyContact(undefined);
                                        return { isSuccess: true, error: null };
                                      },
                                      onClose: () => {
                                        setIsDeleting(false);
                                        setBusyContact(undefined);
                                      },
                                    }
                                  );
                                } else {
                                  await deleteContact(applicationContact);
                                }
                              }

                              setBusyContact(undefined);
                              setIsDeleting(false);
                            }}
                          />
                          <FormSubmitButton
                            buttonId={`application-contact-form-create-button-${applicationContact.id}`}
                            isSaving={isActiveSaving(applicationContact)}
                            disabled={
                              isActiveDelete(applicationContact) || !canEdit
                            }
                            formId={`application-contact-form-${applicationContact.id}`}
                            text={isNewApplicationContact ? "Create" : "Update"}
                          />
                        </div>
                      </div>
                    }
                  >
                    <Form<ExistingApplicationContact>
                      formId={`application-contact-form-${applicationContact.id}`}
                      value={applicationContact || newApplicationContact}
                      formSchemaBuilder={applicationContactFormSchema}
                      shouldBlockNavigation={true}
                      disableAllFields={!canEdit}
                      onFormSubmit={async (applicationContact) => {
                        setBusyContact(applicationContact.id);

                        if (isNewApplicationContact) {
                          await createContact(applicationContact)
                            .then(() => {
                              AppToaster.show({
                                message: (
                                  <div>
                                    <h3>Success</h3>Contact Saved
                                  </div>
                                ),
                                intent: Intent.SUCCESS,
                              });
                            })
                            .finally(() => {
                              setBusyContact(undefined);
                            });
                        } else {
                          await updateContact(applicationContact)
                            .then(() => {
                              AppToaster.show({
                                message: (
                                  <div>
                                    <h3>Success</h3>Contact Saved
                                  </div>
                                ),
                                intent: Intent.SUCCESS,
                              });
                            })
                            .finally(() => {
                              setBusyContact(undefined);
                            });
                        }
                      }}
                    />
                  </RelativityCard>
                );
              }
            )}
        </RelativityCard>
      </Card>
    </>
  );
};
