import React from "react";
import {
  Day,
  DragAndDrop,
  Inject,
  ScheduleComponent,
  Week,
} from "@syncfusion/ej2-react-schedule";
import { SchedulerSetupSettings } from "./types/Scheduler";
import { useSchedule } from "./useSchedule";

export interface SchedulerComponentProps {
  schedulerSetupSettings: SchedulerSetupSettings;
}

export const SchedulerComponent: React.FC<SchedulerComponentProps> = (
  props
) => {
  const ScheduleInstanceContext = React.createContext<ScheduleComponent | null>(
    null
  );

  const {
    scheduleRef,
    scheduleSettings,
    ready,
    generateQuery,
    selectedDate,
    currentView,
  } = useSchedule(props.schedulerSetupSettings);

  return (
    <ScheduleInstanceContext.Provider value={scheduleRef.current}>
      {ready && (
        <ScheduleComponent
          ref={scheduleRef}
          {...scheduleSettings}
          selectedDate={selectedDate}
          currentView={currentView}
          eventSettings={{
            ...scheduleSettings.eventSettings,
            query: generateQuery(),
          }}
        >
          <Inject services={[Day, Week, DragAndDrop]} />
        </ScheduleComponent>
      )}
      {!ready && <></>}
    </ScheduleInstanceContext.Provider>
  );
};
