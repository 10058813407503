import { Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";

interface SavingSpinnerProps {
  content?: React.ReactNode;
}
export const SavingSpinner: React.FC<SavingSpinnerProps> = ({ content }) => {
  return (
    <div className="saving-spinner-container">
      <b className="saving-spinner-title">{!!content ? content : ""}</b>
      <Spinner size={SpinnerSize.SMALL} intent={Intent.PRIMARY} />
    </div>
  );
};
