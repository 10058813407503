import React from "react";
import "./documentPagePreviewStyles.scss";
import { first } from "lodash";
import { ThumbnailImagesData } from "./PdfDocumentStore";
import classNames from "classnames";

export interface DocumentPagePreviewProps {
  src: ThumbnailImagesData | ThumbnailImagesData[];
}

export const DocumentPagePreview: React.FC<DocumentPagePreviewProps> = ({
  src,
}) => {
  const [currentSrc, setCurrentSrc] = React.useState<ThumbnailImagesData>();

  React.useEffect(() => {
    if (Array.isArray(src) && src.length > 0) {
      setCurrentSrc(first(src));
    } else if (src) {
      setCurrentSrc(src as ThumbnailImagesData);
    }
  }, [src]);

  return (
    <section className="document-page-preview-container">
      {Array.isArray(src) && src.length > 1 && (
        <ul className="document-page-preview-pagination">
          {src.map((x, index) => (
            <li key={index}>
              <button
                className={classNames(
                  "document-thumbnail",
                  `document-thumbnail-og-rotate-${x.originalRotation}`,
                  `document-thumbnail-rotate-${x.rotation}`,
                  {
                    "page-selected":
                      currentSrc?.thumbnailImage == x.thumbnailImage,
                  }
                )}
                onClick={() => setCurrentSrc(x)}
              >
                <img
                  className="document-thumbnail-image"
                  src={x?.thumbnailImage}
                />
              </button>
            </li>
          ))}
        </ul>
      )}
      <div className="document-page-preview">
        <img src={currentSrc?.hdImage} />
      </div>
    </section>
  );
};
