import { Elevation } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { medicalCoverageOwnedEntityStore } from "../../../../stores/OwnedEntityStores/MedicalCoverageOwnedEntityStore";
import { MedicalCoverage } from "../../../../types/Application/MedicalCoverage";
import { DeleteButton } from "../../../Buttons/DeleteButton";
import { FormSubmitButton } from "../../../Buttons/FormSubmitButton";
import RelativityCard from "../../../Card/RelativityCard";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../Forms";
import {
  medicalCoverageFormSchema,
  newMedicalCoverage,
} from "../../../Forms/ApplicationForm/MedicalCoverage";
import { showSuccessfulSaveAppToaster } from "../../../Toast/Toast";
import "../../styles.scss";
import { OwnedEntityFIARequestSubCard } from "../../../FIARequests/OwnedEntityFIARequestsSubCard";
import { FIARequest } from "../../../FIARequests/configurations/types";
import { fiaRequestApiClient } from "../../../../lib/apiClients/fiaRequest/fiaRequestApiClient";

export interface MedicalCoverageFormCardProps {
  medicalCoverage: MedicalCoverage;
  localMedicalCoverages: MedicalCoverage[];
  setLocalMedicalCoverages: (medicalCoverages: MedicalCoverage[]) => void;
  canEdit?: boolean;
  createAndUpdateMedicalCoverages: () => Promise<void>;
  deleteMedicalCoverage?: (medicalCoverage: MedicalCoverage) => Promise<void>;
  getMedicalCoverageComputedState?: (
    value: Partial<MedicalCoverage>
  ) => Promise<Partial<MedicalCoverage>>;
  showCancelButton?: boolean;
  onCancel?: () => void;
  index: number;
  applicationId?: string;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
}

export const newMedicalCoverageId = "new-medical-coverage";
export const MedicalCoverageFormCard: FC<MedicalCoverageFormCardProps> = ({
  medicalCoverage,
  localMedicalCoverages,
  setLocalMedicalCoverages,
  canEdit,
  createAndUpdateMedicalCoverages,
  deleteMedicalCoverage,
  getMedicalCoverageComputedState,
  index,
  applicationId,
  formIsSaving,
  setFormIsSaving,
  shouldDisableForm,
  setShouldDisableForm,
}) => {
  const [isInDeleteAction, setIsInDeleteAction] = useState<boolean>(false);
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );

  const isNewMedicalCoverage = medicalCoverage.id === newMedicalCoverageId;

  useEffect(() => {
    if (!!applicationId && !isNewMedicalCoverage) {
      fiaRequestApiClient
        .getMostRecentRequestByOwnedEntityId(applicationId, medicalCoverage.id)
        .then((res) => setFiaRequest(res));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [medicalCoverage.id]);

  return (
    <RelativityCard
      key={medicalCoverage.id}
      elevation={Elevation.TWO}
      className="owned-entity-card medical-coverage-card"
      headerContent={
        <div className="owned-entity-header medical-coverage-card-header">
          <SectionHeader
            label={`Medical Coverage ${index + 1}`}
            type="Section"
            inAction={formIsSaving}
          />
          <div>
            {deleteMedicalCoverage && (
              <DeleteButton
                isDeleting={isInDeleteAction}
                disabled={shouldDisableForm || formIsSaving || !canEdit}
                onClick={async () => {
                  setShouldDisableForm(true);
                  setIsInDeleteAction(true);

                  if (isNewMedicalCoverage) {
                    setLocalMedicalCoverages(
                      localMedicalCoverages.filter(
                        (medicalCoverage) =>
                          medicalCoverage.id !== newMedicalCoverageId
                      )
                    );
                    medicalCoverageOwnedEntityStore.removeLocalMedicalCoverage(
                      medicalCoverage
                    );
                  } else {
                    !!deleteMedicalCoverage &&
                      (await deleteMedicalCoverage(medicalCoverage));
                  }

                  setIsInDeleteAction(false);
                  setShouldDisableForm(false);
                }}
              />
            )}
            <FormSubmitButton
              buttonId={`medical-coverage-form-create-button-${medicalCoverage.id}`}
              isSaving={formIsSaving}
              disabled={shouldDisableForm || !canEdit}
              formId={`medical-coverage-form-${medicalCoverage.id}`}
              text={isNewMedicalCoverage ? "Create" : "Update"}
            />
          </div>
        </div>
      }
    >
      <Form<MedicalCoverage>
        formId={`medical-coverage-form-${medicalCoverage.id}`}
        shouldBlockNavigation={true}
        value={medicalCoverage || newMedicalCoverage}
        formSchemaBuilder={medicalCoverageFormSchema}
        disableAllFields={shouldDisableForm || !canEdit}
        shouldSaveEntiityToLocalStore={true}
        ownedEntityStoreAction={(updatedMedicalCoverage) => {
          if (
            JSON.stringify(updatedMedicalCoverage) !==
            JSON.stringify(medicalCoverage)
          ) {
            medicalCoverageOwnedEntityStore.addLocalMedicalCoverage(
              updatedMedicalCoverage
            );
          }
        }}
        onFormSubmit={async () => {
          setShouldDisableForm(true);
          setFormIsSaving(true);

          await createAndUpdateMedicalCoverages().then(() => {
            showSuccessfulSaveAppToaster("Medical Coverages");
          });

          setShouldDisableForm(false);
          setFormIsSaving(false);
        }}
        computedStateOption={{
          fields: ["coverageType"],
          getComputedState: getMedicalCoverageComputedState,
        }}
      />
      {fiaRequest && <OwnedEntityFIARequestSubCard fiaRequest={fiaRequest} />}
    </RelativityCard>
  );
};
