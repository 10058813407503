import { FormGroup, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DataManager, Predicate, Query } from "@syncfusion/ej2-data";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
  ChangeEventArgs,
  FieldSettingsModel,
  FilteringEventArgs,
  SelectEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import variables from "../../../config/variables";
import { getAccessToken } from "../../../lib/apiClients/baseApiClient";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import {
  AsyncAutocompleteFieldProps,
  AsyncAutocompleteFieldValue,
} from "../types/fieldTypes";
import AsyncAutocompleteODataV4Adaptor, {
  inactiveCategory,
  notInListValue,
} from "./AsyncAutocompleteODataV4Adaptor";
import "./styles.scss";
import { ODataBeforeSendArgs } from "../../Grid/Grid/types/ODataBeforeSendArgs";
import { FailureEventArgs } from "@syncfusion/ej2-react-grids";
import { AppToaster } from "../../Toast/Toast";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";
import { WithTooltip } from "../../Tooltip/Tooltip";

export const AsyncAutocompleteField: React.FC<AsyncAutocompleteFieldProps> = (
  props
) => {
  if (props.hidden) {
    return null;
  }
  const autoCompleteRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ready, setReady] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState<boolean>(false);
  const [instancePredicate, setInstancePredicate] = React.useState<
    Predicate | undefined
  >();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [localValue, setLocalValue] = React.useState<any>(props.value);
  const [localData, setLocalData] = React.useState<any>();
  const [autoCompleteSettings, setAutoCompleteSettings] = React.useState<
    AutoCompleteModel & DefaultHtmlAttributes
  >();

  const [queryKey, setQueryKey] = React.useState<string>();
  const [accessToken, setAccessToken] = React.useState<string>();
  const [failureTimeoutCount, setFailureTimeoutCount] =
    React.useState<number>(0);

  const isTooltipVisible = useOverflowTooltip(
    autoCompleteRef.current?.element,
    localData?.label
  );

  const handleValueChange = (newValue: string) => {
    if (initialized) {
      // Setting local value to detect change faster than rerendering,
      // allowing the UI to adjust styles like require as it occurs.
      if (localValue !== newValue) {
        setLocalValue(newValue);
      }

      // If autoCompleteRef.current is defined, then set its value.
      if (
        autoCompleteRef &&
        autoCompleteRef.current &&
        autoCompleteRef.current.value !== newValue
      ) {
        autoCompleteRef.current.value = newValue;
      }
    }
  };

  React.useEffect(() => {
    setQueryKey("initital-key");
  }, []);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [autoCompleteRef]);

  React.useEffect(() => {
    // On error, retry fetch
    if (initialized && failureTimeoutCount > 0) {
      retryFetch();
    }
  }, [failureTimeoutCount]);

  React.useEffect(() => {
    // Initial Fetch
    if (initialized && !ready) {
      setup();
    }
  }, [accessToken]);

  React.useEffect(() => {
    // If we geta new token, reset datasource
    if (initialized && autoCompleteRef.current && ready) {
      autoCompleteRef.current.dataSource = setupODataSource(
        `${variables.apiBaseUrl}${props.odataUrl}`,
        autoCompleteRef.current.fields
      );

      autoCompleteRef.current?.hidePopup();
      setTimeout(() => {
        autoCompleteRef.current?.showPopup();
      }, 100);
    }
  }, [accessToken]);

  React.useEffect(() => {
    handleValueChange(props.value as string);
  }, [props.value]);

  // Observe and Save Filter Settings
  React.useEffect(() => {
    if (initialized) {
      fetchAccessToken();
    }
  }, [queryKey]);

  const fetchAccessToken = async () => {
    const token = await getAccessToken();
    setAccessToken(token);
  };

  React.useEffect(() => {
    const requestKey = JSON.stringify(props.autoCompleteSettings);
    if (requestKey !== queryKey) {
      setQueryKey(requestKey);
    }
  }, [props.autoCompleteSettings]);

  React.useEffect(() => {
    if (initialized) {
      // If localData is undefined it never triggers the update, in which case we trigger in this clause
      if (localData === "undefined") {
        props.onSubmit(undefined);
      } else {
        props.onSubmit(localData);
      }
    }
  }, [localData]);

  React.useEffect(() => {
    const predicate = props.autoCompleteSettings?.query?.queries.find(
      (x) => x.fn === "onWhere"
    )?.e as Predicate;
    setInstancePredicate(predicate);
  }, [props.autoCompleteSettings?.query?.queries]);

  const retryFetch = async () => {
    if (!autoCompleteRef.current) {
      return;
    }

    // Get new token if we have a failure
    if (failureTimeoutCount < 3) {
      await fetchAccessToken();
    } else {
      AppToaster.show({
        message: "Authorization Error. Please refresh and try again.",
        intent: Intent.WARNING,
      });
    }
    setIsLoading(false);
  };

  // Events
  const showSearchPopup = () => {
    if (!!autoCompleteRef.current && initialized) {
      autoCompleteRef.current.showPopup();
    }
  };

  const handleSearchSelect = async (args: SelectEventArgs) => {
    if (_.get(args.itemData, "category") === inactiveCategory) {
      args.cancel = true;

      setTimeout(() => {
        if (!!autoCompleteRef.current && initialized) {
          autoCompleteRef.current.showPopup();
        }
      }, 100);
    } else {
      if (!!autoCompleteRef.current && initialized) {
        setLocalData(args.itemData);
      }
    }
  };

  const handleChange = async (args: ChangeEventArgs) => {
    if (!!autoCompleteRef.current && initialized && args.isInteracted) {
      if (!args?.value) {
        // If localData is undefined it never triggers the update, in which case we trigger in this clause
        if (!!localData) {
          setLocalData(undefined);
        } else {
          setLocalData("undefined");
        }
      } else {
        setLocalData(args.itemData);
      }
    }
  };

  const buildDefaultQuery = () => {
    return new Query()
      .select(props.fieldNames || ["label", "value"])
      .sortBy(props.sortBy || "label")
      .take(50);
  };

  const handleFiltering = (args: FilteringEventArgs) => {
    if (autoCompleteRef.current) {
      args.preventDefaultAction = true;

      let query = buildDefaultQuery();

      const containsPredicate = new Predicate(
        autoCompleteRef.current.fields.text || "label",
        "contains",
        args.text,
        true
      );

      if (!autoCompleteRef.current.query) {
        query = query.where(containsPredicate);
      } else {
        let predicate = containsPredicate;
        if (props.searchBy) {
          predicate = props.searchBy(args.text);
        }

        if (predicate) {
          if (instancePredicate) {
            query.where(predicate.and(instancePredicate));
          } else {
            query.where(predicate);
          }
        }
      }
      args.updateData(autoCompleteRef.current.dataSource, query);
    }
  };

  const defaultAutoCompleteSettings: AutoCompleteModel & DefaultHtmlAttributes =
    {
      autofill: true,
      showClearButton: true,
      highlight: true,
      allowCustom: false,
      fields: { text: "label", value: "value" },
      itemTemplate: "<table><tr><td>${label}</td></tr><table>",
      // Inmutable
      select: handleSearchSelect,
      focus: showSearchPopup,
      onClick: showSearchPopup,
      change: handleChange,
      filtering: handleFiltering,
    };

  const beforeSend = ({
    queryString,
  }: ODataBeforeSendArgs): [string, string | undefined] => {
    return [queryString, accessToken];
  };

  // Setup
  const setupODataSource = (
    odataUrl: string,
    fieldSettings?: FieldSettingsModel
  ): DataManager => {
    const dataManager = new DataManager({
      url: odataUrl,
      adaptor: new AsyncAutocompleteODataV4Adaptor(
        !!props.hasNotInListOption,
        !!props.groupByActiveStatus,
        fieldSettings,
        beforeSend
      ),
      crossDomain: true,
    });
    return dataManager;
  };

  const setupActionCompleteEvent = (
    settings?: AutoCompleteModel & DefaultHtmlAttributes
  ) => {
    return (eventArgs: Object) => {
      if (settings?.actionComplete) {
        settings.actionComplete(eventArgs);
      }

      // Reset failure count
      setFailureTimeoutCount(0);
    };
  };

  const setupActionFailureEvent = (
    settings?: AutoCompleteModel & DefaultHtmlAttributes
  ) => {
    return (eventArgs: FailureEventArgs) => {
      if (settings?.actionFailure) {
        settings.actionFailure(eventArgs);
      }
      // Process Error
      const error =
        !!eventArgs.error && (eventArgs.error as unknown as XMLHttpRequest);
      if (!!error && [401, 403].includes(error.status)) {
        setFailureTimeoutCount((count) => count + 1);
      } else {
        AppToaster.show({
          message: "An error ocurred executing the current action.",
          intent: Intent.DANGER,
        });
      }

      setIsLoading(false);
    };
  };

  const setup = async () => {
    if (!queryKey) {
      return;
    }

    // Setup Properties
    const settings = {
      ...defaultAutoCompleteSettings,
      ...(props.autoCompleteSettings || {}),
    } as AutoCompleteModel & DefaultHtmlAttributes;

    // Set Item Template Wrapper
    settings.itemTemplate =
      "<span class='async-autocomplete-item async-autocomplete-item-disabled-${isActive}'>" +
      String(settings.itemTemplate) +
      "</span>";

    // System not allows groupby if not in list option is set up
    if (
      settings.fields &&
      (!!props.hasNotInListOption || !!props.groupByActiveStatus)
    ) {
      settings.fields.groupBy = "category";
    }

    const dm = setupODataSource(
      `${variables.apiBaseUrl}${props.odataUrl}`,
      settings.fields
    );
    settings.dataSource = dm;
    settings.value = props.value;

    settings.actionComplete = setupActionCompleteEvent(
      props.autoCompleteSettings
    );
    settings.actionFailure = setupActionFailureEvent(
      props.autoCompleteSettings
    );

    setAutoCompleteSettings(settings);
    setReady(true);
  };

  return (
    <FormGroup
      className={classNames(
        `${
          props.isRequired && !localValue
            ? "has-required-background"
            : "async-autocomplete-field"
        }`
      )}
    >
      {props.label && (
        <SelectableLabel name={props.label} description={props.description} />
      )}
      <div className="async-autocomplete-toolbar">
        {ready && autoCompleteSettings?.dataSource && (
          <div className="async-autocomplete-input-container">
            <WithTooltip
              shouldShowTooltip={isTooltipVisible}
              content={isTooltipVisible && localData?.label}
            >
              <AutoCompleteComponent
                ref={autoCompleteRef}
                id="async-autocomplete-search-list"
                className="async-autocomplete-input"
                onChange={(e: { value: AsyncAutocompleteFieldValue }) => {
                  handleValueChange(e.value as string);
                }}
                readonly={props.disabled || isLoading}
                placeholder={isLoading ? "Loading..." : "Type to search..."}
                actionBegin={(args: any) => {
                  // Initial or Filtering
                  if (_.has(args, "query")) {
                    if (!isDataLoaded) {
                      // Load Initial Value
                      setIsLoading(true);

                      if (props.value && props.value !== notInListValue) {
                        const query = new Query().where(
                          new Predicate(
                            String(autoCompleteSettings.fields?.value),
                            "equal",
                            String(props.value),
                            true
                          )
                        );
                        args.query = query;
                      } else if (props.value === notInListValue) {
                        args.query = buildDefaultQuery();
                      }
                    }
                    // Initial
                  } else {
                    // Load
                    args.query =
                      props.autoCompleteSettings?.query || buildDefaultQuery();
                  }
                  setIsLoading(false);
                }}
                dataBound={() => {
                  setIsDataLoaded(true);
                  setIsLoading(false);
                }}
                {...autoCompleteSettings}
              />
              <Icon icon={IconNames.Search} size={14} />
            </WithTooltip>
          </div>
        )}
      </div>
      {props.errorMessages && (
        <>
          {props.errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
