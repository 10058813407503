import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import React from "react";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { templateDesignerStore } from "./stores/TemplateDesignerStore";
import "./styles.scss";
export interface HeaderToolbarProps {
  onPreviewToggle: (isPreview: boolean) => void;
  hideFieldsTab?: boolean;
}

export const HeaderToolbar: React.FC<HeaderToolbarProps> = (props) => {
  const [isPreview, setIsPreview] = React.useState<boolean>(false);

  React.useEffect(() => {
    props.onPreviewToggle(isPreview);
  }, [isPreview]);

  return (
    <div className="header-toolbar">
      <div className="header-toolbar-page-thumbnails">
        <h3>Page Thumbnails</h3>
      </div>
      <div className="header-toolbar-application-form">
        <h3>Application Form</h3>
        <div className="header-toolbar-actions">
          <Button
            className={classNames("preview-template", {
              "is-preview": isPreview,
            })}
            intent={isPreview ? Intent.DANGER : Intent.NONE}
            icon={
              <Icon
                icon={isPreview ? IconNames.Cross : IconNames.EyeOff}
                iconSize={14}
              />
            }
            disabled={
              templateDesignerStore.isSaving || templateDesignerStore.isDeleting
            }
            minimal={!isPreview}
            text={isPreview ? "Exit Preview" : "Preview"}
            onClick={() => setIsPreview(!isPreview)}
          />
          {!!templateDesignerStore.onSave && (
            <Button
              className={classNames("save-template", {
                "is-saving": templateDesignerStore.isSaving,
              })}
              loading={templateDesignerStore.isSaving}
              disabled={isPreview || templateDesignerStore.isDeleting}
              intent={Intent.SUCCESS}
              text={templateDesignerStore.isSaving ? "Saving" : "Save"}
              onClick={() => templateDesignerStore.save()}
            />
          )}
          {!!templateDesignerStore.onDelete && (
            <Button
              className={classNames("delete-template", {
                "is-deleting": templateDesignerStore.isDeleting,
              })}
              loading={templateDesignerStore.isDeleting}
              disabled={isPreview || templateDesignerStore.isSaving}
              intent={Intent.DANGER}
              minimal
              text={templateDesignerStore.isDeleting ? "Deleting" : "Delete"}
              onClick={() =>
                dialogsViewerStore.confirm({
                  content: (
                    <>Are you sure you would like to delete this Template?</>
                  ),
                  onClose: () => {
                    null;
                  },
                  onConfirm: async () => {
                    const result = { isSuccess: true, error: undefined };
                    templateDesignerStore.delete();
                    return result;
                  },
                })
              }
              icon={IconNames.Trash}
            />
          )}
        </div>
      </div>
      {!props.hideFieldsTab && (
        <div className="header-toolbar-expression-toolbar">
          {!isPreview && <h3>Fields</h3>}
          {isPreview && <h3>Application Preview</h3>}
        </div>
      )}
    </div>
  );
};
