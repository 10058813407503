import { FormGroup } from "@blueprintjs/core";
import { DateRangeInput2 } from "@blueprintjs/datetime2";
import { format, parse } from "date-fns";
import { useCallback } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { DateRangeFieldProps } from "../types/fieldTypes";

export const DateRangeField: React.FC<DateRangeFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  minDate,
  maxDate,
}) => {
  if (hidden) {
    return null;
  }

  const dateFnsFormat = "MM/dd/yyyy";
  const formatDate = useCallback(
    (date: Date) => format(date, dateFnsFormat),
    []
  );
  const parseDate = useCallback(
    (str: string) => parse(str, dateFnsFormat, new Date()),
    []
  );

  return (
    <FormGroup
      className={`${
        isRequired && !value ? "has-required-background" : "base-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <DateRangeInput2
        value={value}
        placeholder="MM/DD/YYYY"
        closeOnSelection
        formatDate={formatDate}
        parseDate={parseDate}
        onChange={(date) => {
          onSubmit(!date ? "" : date);
        }}
        disabled={disabled}
        minDate={minDate || new Date("1900-01-01")}
        maxDate={maxDate}
      />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
