import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { userApiClient } from "../../lib/apiClients/user/userApiClient";
import { userStore } from "../../stores/UserStore";
import { UserSignatures } from "../../types/User";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import {
  newFormUserSignature,
  userSignatureFormSchema,
} from "../Forms/UserSignatureForm/UserSignatureForm";
import { BaseDialogProps } from "./types/types";
import classnames from "classnames";
import { signaturePadStore } from "../Fields/SignatureField/signaturePadStore";

export interface UpdateSignatureDialogProps extends BaseDialogProps {}

export const UpdateSignatureDialog: React.FC<UpdateSignatureDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [userSignatures, setUserSignatures] = useState<UserSignatures | null>(
    null
  );

  useEffect(() => {
    const userId = userStore.user?.id;

    if (!!userId) {
      userApiClient.getUserSignatures(userId).then(setUserSignatures);
    }

    return () => {
      signaturePadStore.reset();
    };
  }, []);

  return (
    <Dialog
      portalClassName="mdr-dialog user-update-dialog-container fullscreen-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Signature"}
      onClose={closeDialog}
    >
      <div className={classnames(Classes.DIALOG_BODY, "user-update-dialog")}>
        {userSignatures && (
          <Form<{
            signature: string;
            signature2: string;
            signature3: string;
            signature4: string;
            signature5: string;
            signature6: string;
          }>
            formId="update-signature-form"
            value={userSignatures || newFormUserSignature}
            formSchemaBuilder={userSignatureFormSchema}
            onFormSubmit={async (form) => {
              setIsSaving(true);
              if (!!userSignatures && userStore.user?.id) {
                await userApiClient
                  .updateUserSignatures(userStore.user.id, {
                    signature: form.signature,
                    signature2: form.signature2,
                    signature3: form.signature3,
                    signature4: form.signature4,
                    signature5: form.signature5,
                    signature6: form.signature6,
                  })
                  .then(() => {
                    submitDialog();
                  })
                  .finally(async () => {
                    setIsSaving(false);
                    setUserSignatures({
                      signature: form.signature,
                      signature2: form.signature2,
                      signature3: form.signature3,
                      signature4: form.signature4,
                      signature5: form.signature5,
                      signature6: form.signature6,
                    });
                  });
              }
            }}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="save-user-signature-button"
          isSaving={isSaving}
          formId="update-signature-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
