import * as signalR from "@microsoft/signalr";
import variables from "../../config/variables";
import { applicationViewerStore } from "../../stores/ApplicationViewerStore";
import { getAccessToken } from "../apiClients/baseApiClient";

export class ApplicationConnectivityHub {
  private connection: signalR.HubConnection;

  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${variables.apiBaseUrl}application-connectivity-hub`, {
        accessTokenFactory: getAccessToken as () => Promise<string>,
        withCredentials: false,
      })
      .build();

    this.connection.on("exit", applicationViewerStore.removeActiveUser);
    this.connection.on("enter", applicationViewerStore.addActiveUser);
  }

  public subscribe = async (applicationId: string) => {
    const doEnter = () =>
      this.connection.invoke("SubscribeApplication", applicationId);

    if (!this.connection.connectionId) {
      try {
        await this.connection.start();
        await doEnter();
      } catch (_e) {}
    } else {
      await doEnter();
    }
  };

  public unsubscribe = async (applicationId: string) => {
    const doExit = async () => {
      applicationViewerStore.resetActiveUser();
      await this.connection.invoke("UnsubscribeApplication", applicationId);
      return this.connection.stop();
    };

    if (!this.connection.connectionId) {
      try {
        await this.connection.start();
        await doExit();
      } catch (_e) {}
    } else {
      await doExit();
    }
  };
}

export const applicationConnectivityHub = new ApplicationConnectivityHub();
