import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import classnames from "classnames";
import React, { useEffect } from "react";
import { ChatBot } from "./useChatbot";
import "./styles.scss";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { ConversationRoleTypes, ConversationTopicTypes } from "./types";
import { isUndefined, last } from "lodash";
import { TextAreaField } from "../Fields/TextAreaField/TextAreaField";
//import { CommonMessageMetadata } from "../HelpCenter/types";

interface ChatBotComponentProps {
  chatBot: ChatBot;
  endConversationHandler: (
    wasResolved: boolean,
    startNewConversation?: boolean
  ) => void;
  minimizeConversationHandler: () => void;
}
export const ChatBotComponent: React.FC<ChatBotComponentProps> = (props) => {
  const chatRef = React.useRef<ListViewComponent | null>(null);
  const [showIntro, setShowIntro] = React.useState<boolean>();

  const [showConversationPrompt, setShowConversationPrompt] =
    React.useState<boolean>();
  const [prompt, setPrompt] = React.useState<string>();
  const [inputElement, setInputElement] =
    React.useState<HTMLTextAreaElement | null>();

  const focusInput = () => {
    if (inputElement) {
      inputElement.focus();
    }
  };

  useEffect(() => {
    if (chatRef.current) {
      setShowIntro(
        props.chatBot.conversation === undefined ||
          props.chatBot.conversation?.messages?.length == 0
      );
    }
  }, [props.chatBot.conversation?.messages?.length]);

  useEffect(() => {
    if (chatRef.current) {
      const showConversationPrompt =
        props.chatBot.conversation &&
        last(props.chatBot.conversation?.messages || [])?.role ==
          ConversationRoleTypes.Assistant;
      setShowConversationPrompt(showConversationPrompt);
    }
  }, [props.chatBot.conversation?.messages?.length]);

  useEffect(() => {
    if (showConversationPrompt) {
      scrollToEnd();
    } else {
      focusInput();
    }
  }, [showConversationPrompt]);

  useEffect(() => {
    if (props.chatBot.topicType === ConversationTopicTypes.MDRQuestion) {
      focusInput();
    }
  }, [props.chatBot.topicType]);

  useEffect(() => {
    if (
      props.chatBot.topicType === ConversationTopicTypes.StateQuestion &&
      !isUndefined(props.chatBot.usState)
    ) {
      focusInput();
    }
  }, [props.chatBot.usState]);

  useEffect(() => {
    if (chatRef.current && props.chatBot.newPrompt) {
      const chatMessage = {
        message: props.chatBot.newPrompt,
        role: ConversationRoleTypes.User,
        timestamp: new Date(),
        messageMetadataObj: {
          isSearchStatePolicyTopics: props.chatBot.searchStatePolicyTopics,
        },
      };

      setPrompt(undefined);

      chatRef.current.addItem([chatMessage]);

      scrollToEnd();
    }
  }, [props.chatBot.newPrompt]);

  const scrollToEnd = () => {
    setTimeout(() => {
      if (chatRef.current) {
        const listViewContentElement = chatRef.current.element.querySelector(
          ".e-content"
        ) as HTMLElement;

        if (listViewContentElement) {
          listViewContentElement.scrollTo(
            0,
            listViewContentElement.scrollHeight
          );
        }
      }
    }, 100);
  };

  const initializeInput = (element: HTMLTextAreaElement) => {
    if (element) {
      setInputElement(element);
      element.focus();
    }
  };

  const submitPrompt = () => {
    if (!prompt) {
      return;
    }

    props.chatBot.setNewPrompt(prompt);
  };

  // Computed
  const showIntroTopicType = showIntro && !props.chatBot.topicType;
  const showIntroState =
    showIntro &&
    props.chatBot.topicType &&
    props.chatBot.topicType === ConversationTopicTypes.StateQuestion &&
    isUndefined(props.chatBot.usState);
  const showIntroReadyContent =
    showIntro && props.chatBot.topicType && !showIntroState;

  return (
    <>
      <div
        className={classnames(
          "chatbot-container",
          {
            "chat-conversation-intro-active":
              showIntro && !props.chatBot.newPrompt,
          },
          {
            "chat-conversation-intro-guide-topic-type-active":
              showIntroTopicType,
          },
          {
            "chat-conversation-intro-guide-usstate-active": showIntroState,
          },
          {
            "chat-conversation-continuation-prompt-active":
              showConversationPrompt,
          },
          {
            "chat-conversation-new-prompt-active":
              props.chatBot.newPrompt !== undefined,
          },
          {
            "chat-conversation-asking-assistance-active":
              props.chatBot.isAskingAssistant,
          },
          {
            "chat-conversation-search-state-policy-topics-active":
              props.chatBot.searchStatePolicyTopics,
          }
        )}
      >
        <div className="chat-conversation">
          {/* Conversation */}
          <ListViewComponent
            ref={chatRef}
            id="ChatBotChat"
            dataSource={props.chatBot.mapMessagesToObject()}
            headerTitle="Medicaid Done Right"
            showHeader={true}
            template={props.chatBot.chatTemplate}
            enableHtmlSanitizer={true}
          />
        </div>
        {/* Minimize */}
        <div className={classNames("chat-conversation-minimize")}>
          <Button
            icon={IconNames.Minus}
            className="chat-conversation-action"
            minimal={true}
            intent={Intent.PRIMARY}
            title="Minimize"
            onClick={props.minimizeConversationHandler}
          />
        </div>
        {/* End Conversation */}
        <div className={classNames("chat-conversation-close")}>
          <Button
            icon={IconNames.LogOut}
            className="chat-conversation-action"
            minimal={true}
            intent={Intent.PRIMARY}
            text="End Conversation"
            onClick={() => props.endConversationHandler(true)}
          />
        </div>
        {/* Intro */}
        <div className={classNames("chat-conversation-intro")}>
          {showIntroTopicType && (
            <div className="chat-conversation-intro-topic-type">
              <span>
                Hi! My name is <b>Goose</b> and I&apos;m trained on Medicaid
                Done Right documentation. What type of question may you have?
              </span>
            </div>
          )}

          {showIntroState && (
            <div className="chat-conversation-intro-state">
              <span>Which State?</span>
            </div>
          )}

          {showIntroReadyContent && (
            <>
              <div className="chat-conversation-intro-ready">
                <span>How can I help you?</span>
              </div>
              <div className="format-tips">
                To enhance your experience, please follow these guidelines when
                formatting your questions:
                <ul>
                  <li>Be specific and clear in your question</li>
                  <li>Avoid asking broad or ambiguous questions</li>
                  <li>Double-check for typos and grammatical errors</li>
                </ul>
              </div>
              <div className="format-examples">
                For example, you can ask:
                {isUndefined(props.chatBot.usState) && (
                  <ul>
                    <li>How do I add my internal user signature?</li>
                    <li>What does Monitor status mean?</li>
                    <li>How do I withdraw a file?</li>
                  </ul>
                )}
                {!isUndefined(props.chatBot.usState) && (
                  <ul>
                    <li>What is the Personal Needs Allowance?</li>
                    <li>Is a Burial Plot considered an excluded asset?</li>
                    <li>Are Vehicles exempt?</li>
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
        {/* Asking Assistance */}
        <div className={classNames("chat-conversation-asking-assistance")}>
          <div className="loading"></div>
        </div>
        {/* Guide Badges */}
        <div
          className={classNames("chat-conversation-guide-badges", {
            "chat-conversation-guide-badges-active":
              props.chatBot.isMDRQuestion ||
              props.chatBot.isUsStateQuestion ||
              props.chatBot.haveUsStateQuestionState,
          })}
        >
          {props.chatBot.isMDRQuestion && (
            <span className="chat-conversation-guide-badge-topic-type-mdr e-badge e-badge-primary">
              MDR Question
            </span>
          )}
          {props.chatBot.isUsStateQuestion && (
            <span className="chat-conversation-guide-badge-topic-type-state e-badge e-badge-primary">
              US State Question
            </span>
          )}
          {props.chatBot.haveUsStateQuestionState && (
            <span className="chat-conversation-guide-badge-state e-badge e-badge-primary">
              {props.chatBot.usStateAsString}
            </span>
          )}
        </div>
        {/* Intro Guide */}
        <div className="chat-conversation-intro-guide-topic-type">
          <Button
            icon={IconNames.Comment}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.PRIMARY}
            text={
              <span>
                I have an <b>MDR Specific Question</b>.
              </span>
            }
            onClick={() =>
              props.chatBot.setConversationTopicType(
                ConversationTopicTypes.MDRQuestion
              )
            }
          />
          {props.chatBot.availableUsStates &&
            props.chatBot.availableUsStates.length > 0 && (
              <Button
                icon={IconNames.Comment}
                className="chat-conversation-action"
                minimal={false}
                intent={Intent.PRIMARY}
                text={
                  <span>
                    I have a <b>State Specific Question</b>.
                  </span>
                }
                onClick={() =>
                  props.chatBot.setConversationTopicType(
                    ConversationTopicTypes.StateQuestion
                  )
                }
              />
            )}
          <Button
            icon={IconNames.LogOut}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.SUCCESS}
            text="End conversation."
            onClick={() => props.endConversationHandler(true)}
          />
        </div>
        <div className="chat-conversation-intro-guide-usstate">
          {props.chatBot.availableUsStates?.map((state) => (
            <Button
              key={state.usState}
              icon={IconNames.Comment}
              className="chat-conversation-action"
              minimal={false}
              intent={Intent.PRIMARY}
              text={<span>{state.usState_AsString}</span>}
              onClick={() => {
                props.chatBot.setConversationUsState(state.usState);
              }}
            />
          ))}
          <Button
            icon={IconNames.LogOut}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.SUCCESS}
            text="End conversation."
            onClick={() => props.endConversationHandler(true)}
          />
        </div>
        {/* Actions */}
        <div className={classNames("chat-conversation-continuation-prompt")}>
          {props.chatBot.haveUsStateQuestionState &&
            !props.chatBot.searchStatePolicyTopics &&
            !props.chatBot.lastMessageWasSearchStatePolicyTopics &&
            props.chatBot.usStateHasStatePolicy && (
              <Button
                icon={IconNames.Comment}
                className="chat-conversation-action"
                minimal={false}
                intent={Intent.WARNING}
                text="Search through state policy?"
                onClick={() => {
                  setShowConversationPrompt(false);
                  props.chatBot.searchSameQuestionOnStatePolicyTopics();
                }}
              />
            )}
          <Button
            icon={IconNames.Comment}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.PRIMARY}
            text="I have a related question."
            onClick={() => {
              setShowConversationPrompt(false);
            }}
          />
          <Button
            icon={IconNames.Comment}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.PRIMARY}
            text="I have a new unrelated question."
            onClick={() => props.endConversationHandler(true, true)}
          />
          <Button
            icon={IconNames.LogOut}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.SUCCESS}
            text="My question was resolved, end conversation."
            onClick={() => props.endConversationHandler(true)}
          />
          <Button
            icon={IconNames.LogOut}
            className="chat-conversation-action"
            minimal={false}
            intent={Intent.DANGER}
            text="My question was not resolved, end conversation."
            onClick={() => props.endConversationHandler(false)}
          />
        </div>
        {/* Chat Input */}
        <div className="chat-input">
          <TextAreaField
            label={""}
            type={"Textarea"}
            maxLength={250}
            disabled={
              showConversationPrompt || props.chatBot.newPrompt !== undefined
            }
            placeholder="Send a message..."
            value={prompt || ""}
            onSubmit={function (value: unknown): void {
              setPrompt(value as string);
            }}
            onInitialized={initializeInput}
            onEnterKeyPress={submitPrompt}
            hideRemainingCharacters={true}
          />
          <Button
            icon={IconNames.SendMessage}
            minimal={true}
            intent={Intent.PRIMARY}
            disabled={
              showConversationPrompt || props.chatBot.newPrompt !== undefined
            }
            onClick={submitPrompt}
          />
        </div>
        {/* Disclaimer */}
      </div>
      <div className="chat-conversation-disclaimer">
        <b>Disclaimer:</b> These guidelines are for informational purposes only
        and do not constitute legal advice.
      </div>
    </>
  );
};
