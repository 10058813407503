import { Button, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { Application } from "../../../../types/Application";
import { DateInformation } from "../../../../types/Application/DateInformation";
import Form from "../../../Forms";
import {
  dateInformationPageFormSchema,
  newDateInformation,
} from "../../../Forms/ApplicationForm/DateInformation";
import { AppToaster } from "../../../Toast/Toast";
import RelativityCard from "../../../Card/RelativityCard";
import { IconNames } from "@blueprintjs/icons";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { convertDateTo_UTC_AsString } from "../../../../utils/momentTimeZone";

export interface DateInformationPageCardProps {
  application?: Application;
  submitForm: (application: DateInformation) => Promise<void>;
  canEdit: boolean;
}

export const DateInformationPageCard: FC<DateInformationPageCardProps> = ({
  application,
  submitForm,
  canEdit,
}) => {
  const dateInformation = application as DateInformation;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<DateInformation>(
    dateInformation || newDateInformation
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as DateInformation);
  };

  return (
    <>
      <RelativityCard className="date-information-card">
        <div className="page-card-header">
          {canEdit && (
            <>
              <div className="page-card-header-buttons">
                <Button
                  type="submit"
                  form="date-information-form"
                  minimal
                  loading={isSaving}
                  disabled={isSaving}
                  intent={Intent.PRIMARY}
                  icon={IconNames.FLOPPY_DISK}
                />
                <Button
                  minimal
                  intent={Intent.DANGER}
                  icon={IconNames.Undo}
                  hidden={isSaving}
                  onClick={async () => {
                    setShouldResetForm(true);
                    await handleReset();
                    setShouldResetForm(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
        <Observer>
          {() => (
            <Form<DateInformation>
              formId="date-information-form"
              shouldBlockNavigation={true}
              disableAllFields={isSaving}
              value={activeFormValue}
              shouldResetForm={shouldResetForm}
              formSchemaBuilder={dateInformationPageFormSchema}
              onFormSubmit={async (application) => {
                setIsSaving(true);
                // Convert Hearing Date and Interview Date to UTC before submitting
                const applicationToSubmit = {
                  ...application,
                  hearingDate: convertDateTo_UTC_AsString(
                    application.hearingDate
                  ),
                  interviewDate: convertDateTo_UTC_AsString(
                    application.interviewDate
                  ),
                };
                await submitForm(applicationToSubmit)
                  .then(() => {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Application Date Information Saved
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </RelativityCard>
    </>
  );
};
