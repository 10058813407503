import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { IconNames } from "@blueprintjs/icons";
import { useState, useEffect } from "react";
import "../../Grid/Views/styles.scss";
import { userStore } from "../../../stores/UserStore";

export interface ApplicationCannedViewPickerProps {
  setGridCannedView: Function;
}

export const ApplicationCannedViewPicker: React.FC<ApplicationCannedViewPickerProps> =
  observer((props) => {
    const [cannedView, setCannedView] = useState<string>(
      userStore.user?.isExternal ? "Active + Closed Last 30 Days" : "Active"
    );

    useEffect(() => {
      props.setGridCannedView(cannedView);

      return () => {
        props.setGridCannedView("Active");
      };
    }, [cannedView, setCannedView]);

    return (
      <div className="canned-view-picker-popover">
        <Popover2
          placement="bottom-end"
          content={
            <Menu>
              {!userStore.user?.isExternal && (
                <MenuItem
                  text="Active"
                  className="active"
                  onClick={() => {
                    setCannedView("Active");
                  }}
                />
              )}
              <MenuItem
                text="Active + Closed Last 30 Days"
                className="last-30-days"
                onClick={() => {
                  setCannedView("Active + Closed Last 30 Days");
                }}
              />
              <MenuItem
                text="Closed"
                className="closed"
                onClick={() => {
                  setCannedView("Closed");
                }}
              />
              {!userStore.user?.isExternal && (
                <MenuItem
                  text="All"
                  className="all"
                  onClick={() => {
                    setCannedView("All");
                  }}
                />
              )}
            </Menu>
          }
        >
          <div className="canned-view-picker-text">
            <div>{cannedView}</div>
            <div>
              <Icon iconSize={15} icon={IconNames.CHEVRON_DOWN} />
            </div>
          </div>
        </Popover2>
      </div>
    );
  });
