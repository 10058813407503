import { Button, Classes, FileInput, Intent } from "@blueprintjs/core";
import React, { BaseSyntheticEvent, useState } from "react";
import { matchupApiClient } from "../../lib/apiClients/matchup/matchupApiClient";
import { Loading } from "../Loading/Loading";
import "./styles.scss";
import RelativityCard from "../Card/RelativityCard";
import { downloadFile } from "../Document/PdfDocuments";
import { Footer } from "@ucl/library";

export interface MatchupImportFlyoutProps {
  flyoutIsOpen: boolean;
  onClose?: () => void;
}

export interface LeadImportValidation {
  row: number;
  column: number;
  validationMessage: string;
}

export const MatchupImportFlyout: React.FC<MatchupImportFlyoutProps> = () => {
  const [file, setFile] = useState(null);
  const [importFileName, setImportFileName] = useState<string | undefined>(
    undefined
  );
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [importValidations, setImportValidations] = useState<
    LeadImportValidation[] | undefined
  >(undefined);
  const [showSuccessfulValidation, setShowSuccessfulValidation] =
    useState<boolean>(false);
  const handleFileChange = (event: BaseSyntheticEvent) => {
    setFile(event.target.files[0]);
    setImportFileName(event.target.files[0].name);
    setShowSuccessfulValidation(false);
  };

  const handleUpload = async () => {
    if (file) {
      setIsImporting(true);
      try {
        await matchupApiClient.importLeads(file);
        setShowSuccessfulValidation(true);
      } catch (error: any) {
        setImportValidations(
          error.response.data.additionalInfo
            .leadImportValidationDTOs as LeadImportValidation[]
        );
      } finally {
        setIsImporting(false);
      }
    }
  };

  const downloadLeadsImportTemplate = async () => {
    const blob = await matchupApiClient.downloadImportLeadsTemplate();
    downloadFile(blob, "Maverick_MatchupsImportTemplate.xlsx");
  };

  return (
    <>
      <div className={Classes.DRAWER_HEADER}>
        <h3>Import Matchups</h3>
      </div>
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <section>
            <FileInput
              large={true}
              fill={true}
              text={importFileName || "Choose file..."}
              inputProps={{ accept: ".xlsx" }}
              onInputChange={(event: BaseSyntheticEvent) => {
                handleFileChange(event);
              }}
              hasSelection={importFileName !== undefined}
            />
          </section>
          <section className="leads-import-buttons">
            <div className="flex-start">
              <Button
                text="Download Template"
                onClick={downloadLeadsImportTemplate}
                intent={Intent.PRIMARY}
              />
            </div>
            <div className="flex-end">
              <Button
                text="Import File"
                onClick={handleUpload}
                intent={Intent.PRIMARY}
                disabled={!file}
              />
            </div>
          </section>
        </div>
        {isImporting && <Loading />}
        {!isImporting && importValidations && (
          <div className="leads-import-validations-container">
            <RelativityCard headerContent="Import Validations" columns={1}>
              <ul>
                {importValidations.map((validation, index) => (
                  <li
                    key={index}
                  >{`Row ${validation.row} | Column ${validation.column} | ${validation.validationMessage}`}</li>
                ))}
              </ul>
            </RelativityCard>
          </div>
        )}
        {showSuccessfulValidation && (
          <RelativityCard headerContent="Import Successful" columns={1}>
            <div>
              The import file was successfully validated and processed. Please
              wait a few moments, then refresh the grid to see the imported
              data.
            </div>
          </RelativityCard>
        )}
        <Footer />
      </div>
      <div
        className={Classes.DRAWER_FOOTER}
        style={{ height: 5, boxShadow: "none", padding: 0 }}
      ></div>
    </>
  );
};
