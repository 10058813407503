import { User } from "../../../types/User";
import { YesNoField } from "../../Fields/RadioField/YesNoField";
import { CustomRendererBuilder } from "../../Fields/types/fieldTypes";

export const buildFacilityPOCFields: CustomRendererBuilder<User> = ({
  form,
  rendererProps,
}) =>
  // user,
  // { onSubmit }
  {
    return (
      <>
        {form.facilities?.map((facility) => (
          // TODO: move in-line styles to a scss file.
          <div key={facility.facilityId} style={{ display: "flex" }}>
            <div style={{ width: "47.5%" }}>
              <YesNoField
                type="YesNo"
                label={`${facility.facilityName} - POC`}
                value={facility.isFacilityPOC}
                onSubmit={(isFacilityPOC) => {
                  const newFacilities = form.facilities.map((f) => {
                    if (f.facilityId === facility.facilityId) {
                      f.isFacilityPOC = (isFacilityPOC || false) as boolean;
                    }

                    return f;
                  });

                  rendererProps.onSubmit(newFacilities);
                }}
              />
            </div>
            <div style={{ width: "47.5%" }}>
              <YesNoField
                type="YesNo"
                label={`${facility.facilityName} - Billing POC`}
                value={facility.isBillingPOC}
                onSubmit={(isBillingPOC: unknown) => {
                  const newFacilities = form.facilities.map((f) => {
                    if (f.facilityId === facility.facilityId) {
                      f.isBillingPOC = (isBillingPOC || false) as boolean;
                    }

                    return f;
                  });

                  rendererProps.onSubmit(newFacilities);
                }}
              />
            </div>
          </div>
        ))}
      </>
    );
  };
