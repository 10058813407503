import { Classes, Dialog } from "@blueprintjs/core";
import { useState } from "react";
import { Location, useNavigate } from "react-router";
import { DialogFooter } from "./DialogFooter";
import "./styles.scss";
import { unstable_useBlocker as useBlocker } from "react-router-dom";

export interface SaveChangeDialogProps {
  isDirty: boolean;
  shouldBlockNavigation?: (location?: Location) => boolean;
}

export const SaveChangeDialog: React.FC<SaveChangeDialogProps> = ({
  isDirty,
  shouldBlockNavigation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const blocker = useBlocker(() => {
    if (isDirty && !isOpen) {
      const checkCondition = !!shouldBlockNavigation
        ? shouldBlockNavigation(blocker.location)
        : true;

      if (checkCondition) {
        setIsOpen(true);
        return true;
      }
    }
    return false;
  });

  return (
    <>
      <Dialog
        portalClassName="save-change-dialog"
        isOpen={isOpen}
        hasBackdrop={true}
        isCloseButtonShown={true}
        title={"Save Changes"}
        onClose={() => setIsOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>Do you want to leave without saving changes?</div>
        </div>
        <DialogFooter
          closeDialog={() => {
            if (blocker.location) {
              navigate(blocker.location);
              setIsOpen(false);
            }
          }}
          submitDialog={() => setIsOpen(false)}
          closeButtonText="Leave"
          submitButtonText="Stay"
        />
      </Dialog>
    </>
  );
};
