import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { commentsApiClient } from "../../lib/apiClients/comments/commentsApiClient";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { Comment } from "../../types/Comment";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { commentFormSchema } from "../Forms/CommentForm/CommentForm";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";

export interface CommentUpdateDialogProps extends BaseDialogProps {}

export const CommentUpdateDialog: React.FC<CommentUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [comment, setComment] = useState<Comment | null>(null);

  useEffect(() => {
    if (!!dialogsViewerStore.commentUpdateDialogOpenOptions) {
      setComment(dialogsViewerStore.commentUpdateDialogOpenOptions.comment);
    }

    return () => {
      setComment(null);
    };
  }, [dialogsViewerStore.commentUpdateDialogOpenOptions]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Comment"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Form
          formId="comment-form"
          formSchemaBuilder={commentFormSchema}
          value={comment as Comment}
          onFormSubmit={async (comment: Partial<Comment>) => {
            setIsSaving(true);
            try {
              await commentsApiClient.updateComment({ ...comment });
              if (dialogsViewerStore.commentUpdateDialogOpenOptions?.onSubmit) {
                dialogsViewerStore.commentUpdateDialogOpenOptions?.onSubmit();
              }
              submitDialog();
            } finally {
              setIsSaving(false);
            }
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="comment-button"
          isSaving={isSaving}
          formId="comment-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
