import { FormGroup } from "@blueprintjs/core";
import React from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { ReadOnlyFieldProps } from "../types/fieldTypes";
import "./styles.scss";
import _ from "lodash";
import classNames from "classnames";

export const ReadOnlyField: React.FC<ReadOnlyFieldProps> = (props) => {
  if (props.hidden) {
    return null;
  }

  const [ready, setReady] = React.useState<boolean>(false);
  const [readOnlySettings, setReadOnlySettings] =
    React.useState<ReadOnlyFieldProps>();

  React.useEffect(() => {
    setup();
  }, []);

  React.useEffect(() => {
    readOnlySettings &&
      setReadOnlySettings({ ...readOnlySettings, value: props.value });
  }, [props.value]);

  const defaultReadOnlySettings = {};

  // Setup
  const setup = async () => {
    // Setup Properties
    const settings = {
      ...defaultReadOnlySettings,
      ...props,
    } as ReadOnlyFieldProps;

    setReadOnlySettings(settings);
    setReady(true);
  };

  return (
    <FormGroup>
      {props.label && (
        <SelectableLabel name={props.label} description={props.description} />
      )}
      {ready && (
        <div className={classNames("readonly-toolbar")}>
          {ready && (
            <div
              className={classNames("readonly-container", {
                "bp4-disabled bp4-input": !props.minimal,
              })}
            >
              {readOnlySettings?.value}
            </div>
          )}
        </div>
      )}
    </FormGroup>
  );
};
