import {
  FilterSearchBeginEventArgs,
  GridComponent,
  GridModel,
  SortSettingsModel,
} from "@syncfusion/ej2-react-grids";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-react-navigations";
import variables from "../config/variables";
import GridHeightSettings from "../components/Grid/Grid/types/GridHeightSettings";
import { BaseGridModel } from "../components/Grid/Grid/types/BaseGridModel";
import {
  showDownloadingFileAppToaster,
  showSuccessAppToaster,
} from "../components/Toast/Toast";
import GridColumn, {
  GRID_COLUMN_DEFAULT_WIDTH,
} from "../components/Grid/Grid/types/GridColumn";
import { ODataStore } from "./ODataStore";

export interface SimpleGridSetup<T extends object> {
  getAccessToken?: () => Promise<string | undefined>;
  canExportGridToExcel: boolean;
  canInvokeGridRecord: boolean;
  gridConfiguration?: Readonly<BaseGridModel<T>>;

  gridColumnsConfiguration: GridColumn[];
  dataSource: string | T[];
  predicate?: Predicate;
  sortSettings?: SortSettingsModel;
}

export class SimpleGridStore extends ODataStore {
  public gridInstance?: GridComponent;

  private gridHeightSettings: GridHeightSettings = {
    height: 0,
    autoHeightOffset: 500,
  };

  private defaultPdfViewerSettings: GridModel & DefaultHtmlAttributes = {
    enableInfiniteScrolling: true,
    allowReordering: false,
    allowResizing: true,
    resizeSettings: { mode: "Normal" },
    loadingIndicator: { indicatorType: "Shimmer" },
    pageSettings: { pageSize: 20 },
    enableVirtualMaskRow: true,
    allowExcelExport: true,
    searchSettings: { operator: "contains", ignoreCase: true },
    allowFiltering: true,
    allowSorting: true,
    filterSettings: {
      type: "Excel",
      ignoreAccent: true,
      operators: {
        stringOperator: [{ value: "contains", text: "Contains " }],
      },
    },
    toolbar: [
      { text: "Search" },
      {
        text: "Export to Excel",
        tooltipText: "Export to Excel",
        prefixIcon: "e-upload-2",
        id: "ExcelExport",
      },
    ],
  };

  public refresh = () => {
    this.gridInstance?.refresh();
  };

  public setup = async <T extends object>(
    args: SimpleGridSetup<T>
  ): Promise<GridModel & DefaultHtmlAttributes> => {
    if (args.getAccessToken) {
      await this.init(args.getAccessToken);
    }

    // Setup Settings
    const settings = { ...this.defaultPdfViewerSettings } as GridModel &
      DefaultHtmlAttributes;

    if (typeof args.dataSource === "string") {
      settings.dataSource = this.setupODataSource(
        `${variables.apiBaseUrl}odata/${args.dataSource}`,
        args.gridColumnsConfiguration
      );
    } else {
      settings.dataSource = args.dataSource;
    }

    // Setup Query
    settings.query = new Query().select(
      args.gridColumnsConfiguration.map((x) => x.field)
    );

    if (args.predicate) {
      settings.query = settings.query.where(args.predicate);
    }

    // Setup Columns
    settings.columns = this.setupGridSettingsColumns(
      args.gridColumnsConfiguration
    );
    settings.allowExcelExport = args.canExportGridToExcel;

    // Toolbar click
    if (args.canInvokeGridRecord) {
      settings.toolbarClick = this.setupToolbarClickEvent(
        args.gridConfiguration
      );
    }

    // Setup Default Sort if any
    if (!!args.sortSettings) {
      settings.sortSettings = args.sortSettings;
    }

    // Setup Action Events
    settings.actionBegin = this.setupActionBeginEvent();
    settings.actionFailure = this.baseActionFailureEvent(() =>
      this.gridInstance?.refresh()
    );
    settings.actionComplete = this.baseActionCompleteEvent();

    return settings;
  };

  setupActionBeginEvent = () => {
    return (eventArgs: FilterSearchBeginEventArgs) => {
      // Set Filter Choice Count
      if (eventArgs.requestType === "filterchoicerequest") {
        eventArgs.filterChoiceCount = 9999;
      }
    };
  };

  private setupGridSettingsColumns = (
    columnsConfiguration: Readonly<GridColumn[]>
  ) => {
    const gridColumnSettings = columnsConfiguration.map((colsConfig) => ({
      ...colsConfig,
    })) as GridColumn[];

    return gridColumnSettings;
  };

  private setupToolbarClickEvent = <T extends object>(
    gridConfiguration?: Readonly<BaseGridModel<T>>
  ) => {
    return (clickEventArgs: ClickEventArgs) => {
      if (gridConfiguration?.toolbarClick) {
        gridConfiguration.toolbarClick(clickEventArgs);
      }

      if (clickEventArgs.item.id === "ExcelExport") {
        showDownloadingFileAppToaster("Excel file");
        this.gridInstance
          ?.excelExport({
            fileName: gridConfiguration?.exportFilename ?? "Export.xlsx",
          })
          .then(() => {
            showSuccessAppToaster("Excel download finished");
          });
      }
    };
  };

  private initializeAutoHeight = () => {
    // Inner Function
    const autoHeight = () => {
      if (this.gridInstance) {
        this.gridInstance.height =
          window.innerHeight - this.gridHeightSettings.autoHeightOffset;
      }
    };

    const autoFit = () => {
      if (this.gridInstance) {
        const allColumns = this.gridInstance.columns as GridColumn[];
        const hasDefaultWidth = (x: GridColumn) =>
          typeof x.width === "number" && x.width === GRID_COLUMN_DEFAULT_WIDTH;

        const columnsToAutoFit = allColumns
          .filter(hasDefaultWidth)
          .map((x) => x.foreignKeyField);

        this.gridInstance.autoFitColumns(columnsToAutoFit);
      }
    };

    // Init
    autoHeight();
    autoFit();

    // On Resize
    window.addEventListener("resize", () => {
      autoHeight();
      autoFit();
    });
  };

  public initialize = async (newGridInstance: GridComponent) => {
    this.setInstance(newGridInstance);
    this.initializeAutoHeight();
  };

  public reset = () => {
    this.setInstance(undefined);
  };

  public setInstance = (scopeGridInstance?: GridComponent) => {
    this.gridInstance = scopeGridInstance;
  };
}
