import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { reactPlugin } from "../../../AppInsights";
import variables from "../../../config/variables";
import "../../../pages/styles.scss";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import {
  getGridColumnsConfigurationByRoleExternal,
  gridColumnsConfiguration,
} from "../../Grid/Grid/configurations/gridColumnsConfiguration";
import { Grid } from "../../Grid/Grid/Grid";
import { GridUserReadDTO } from "../../Grid/types/dtos/GridUserReadDTO";
import { GridPermissions } from "../../Grid/types/GridPermissions";
import { GridFilterSettings } from "../../Grid/Grid/types/GridFilterSettings";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../../lib/apiClients/baseApiClient";
import { autorun } from "mobx";
import { gridStore } from "../../Grid/Grid/stores/GridStore";

export interface BoardProps extends GridPermissions {}

const ExternalUserGridComponent: React.FC<BoardProps> = ({
  canExportGridToExcel,
  canInvokeGridRecord,
}) => {
  const gridKey = "user-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "User Board",
    gridColumnsConfiguration,
    getGridColumnsConfigurationByRoleExternal,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  return (
    <div>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        {/* User Admin Grid */}
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && (
                <Grid<GridUserReadDTO>
                  key={gridView.currentGridViewKey}
                  gridKey={gridKey}
                  dataSource={`${variables.apiBaseUrl}odata/userodataexternal`}
                  columnsConfiguration={[
                    ...gridView.currentGridView.columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(gridView.currentGridView.filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(gridView.currentGridView.sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    allowExcelExport: canExportGridToExcel,
                    exportFilename: "Users.xlsx",
                    onRecordClick: ({ rowData }) => {
                      if (canInvokeGridRecord) {
                        dialogsViewerStore.setIsUserUpdateDialogOpen(true, {
                          userId: rowData.id,
                        });
                      }
                    },
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};

export const ExternalUserGrid = withAITracking(
  reactPlugin,
  ExternalUserGridComponent,
  "ExternalUserGrid",
  "AI-flex-container"
);
