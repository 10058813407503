import { Button, Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC, useEffect, useState } from "react";
import { applicationApiClient } from "../../../../lib/apiClients/application/applicationApiClient";
import { Application } from "../../../../types/Application";
import { ApplicationChildRead } from "../../../../types/Application/ApplicationChild";
import RelativityCard from "../../../Card/RelativityCard";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";
import { WithTooltip } from "../../../Tooltip/Tooltip";
import "./styles.scss";

export interface ResidentApplicationHistoryInformationCardProps {
  application?: Application;
  canCreateChildApplication: boolean;
}

export const ResidentApplicationHistoryInformationCard: FC<
  ResidentApplicationHistoryInformationCardProps
> = ({ application, canCreateChildApplication }) => {
  const activeApplication = application?.id;
  const [isViewing, setIsViewing] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [applicationChildren, setApplicationChildren] = useState<
    ApplicationChildRead[]
  >([]);
  const latestChildApp = applicationChildren.sort(
    (prev, next) =>
      new Date(prev.createdAt).getTime() - new Date(next.createdAt).getTime()
  )[applicationChildren?.length - 1];

  useEffect(() => {
    if (activeApplication) {
      applicationApiClient
        .getRelatedApplicationChildren(activeApplication)
        .then(setApplicationChildren)
        .finally(() => {
          setIsReady(true);
        });
    }
  }, [application]);

  const tableHeaderClass = "history-table-header";
  const tableDataClass = "history-table-data";
  const isLatestChildApp = latestChildApp?.applicationId === activeApplication;

  return isReady ? (
    <>
      {activeApplication && applicationChildren.length > 0 && (
        <RelativityCard
          headerContent={
            <>
              <Button
                className="resident-panel__section__view-toggle-button"
                minimal
                intent={Intent.PRIMARY}
                onClick={() => setIsViewing((prev) => !prev)}
              >
                History
              </Button>
              {canCreateChildApplication && (
                <WithTooltip
                  shouldShowTooltip={!isLatestChildApp}
                  content={`Please select ${latestChildApp.fileNumber} to create an additional child application`}
                >
                  <Button
                    minimal
                    intent={Intent.PRIMARY}
                    icon={IconNames.Plus}
                    disabled={!isLatestChildApp}
                    onClick={async () => {
                      dialogsViewerStore.setIsApplicationChildCreationDialogOpen(
                        true,
                        { facilityId: application?.facilityId }
                      );
                    }}
                  />
                </WithTooltip>
              )}
            </>
          }
          hideBody={!isViewing}
        >
          <div className="history-table-container">
            {applicationChildren.length > 0 && (
              <table className="history-table">
                <tbody>
                  <tr className="history-table-header">
                    <th className={tableHeaderClass}>File Number</th>
                    <th className={tableHeaderClass}>Product Type</th>
                    <th className={tableHeaderClass}>Status</th>
                    <th className={tableHeaderClass}>Internal Status</th>
                  </tr>
                  {applicationChildren.map((x) => (
                    <tr className="history-row" key={x.applicationId}>
                      <td className={tableDataClass}>
                        <a href={`/application-page/${x.applicationId}`}>
                          {x.fileNumber}
                        </a>
                      </td>
                      <td className={tableDataClass}>
                        {x.productType_AsString}
                      </td>
                      <td className={tableDataClass}>{x.status_AsString}</td>
                      <td className={tableDataClass}>
                        {x.internalStatus_AsString}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </RelativityCard>
      )}
    </>
  ) : (
    <Spinner size={SpinnerSize.STANDARD} intent={Intent.PRIMARY} />
  );
};
