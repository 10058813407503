import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { reactPlugin } from "../../AppInsights";
import { ApplicationFormProgress } from "../../components/Application/ApplicationFormProgress";
import { applicationFormCards } from "../../components/Application/Cards";
import { Header } from "../../components/Header/Header";
import { PageSidebar } from "../../components/PageSidebar/PageSidebar";
import { applicationStore } from "../../stores/ApplicationStore";
import { permissionStore } from "../../stores/PermissionStore";
import { userStore } from "../../stores/UserStore";
import { ApplicationFormPage } from "../../types/Application";
import "../styles.scss";
import { ApplicationActions } from "./ApplicationActions";
import { errorStore, genericErrorMessage } from "../../stores/ErrorStore";
import { useTitle } from "../../components/UseTitle/useTitle";
import EligibilityBanner from "./EligibilityBanner";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

const ApplicationFormComponent: React.FC = () => {
  const params = useParams<{ id: string; page: ApplicationFormPage }>();
  const navigate = useNavigate();
  const { setTitle } = useTitle("Medicaid Done Right");
  const [currentPage, setCurrentPage] = useState<ApplicationFormPage>(
    params.page || "primary-information"
  );

  useEffect(() => {
    if (!params.id) {
      errorStore.setErrorMessage(genericErrorMessage);
      return;
    }

    if (userStore.user?.canViewApplicationDetail) {
      applicationStore.getApplicationById(params.id);
    } else {
      navigate("/");
    }

    return () => {
      applicationStore.unsetApplication();
    };
  }, [params.id]);

  useEffect(() => {
    if (!params.page) {
      setCurrentPage("primary-information");
      return;
    }

    setCurrentPage(params.page);

    return () => setCurrentPage("primary-information");
  }, [params.page]);

  return (
    <div className="page application-form-page">
      <Observer>
        {() => (
          <>
            {setTitle(
              applicationStore.application
                ? `${applicationStore.application?.firstName} ${applicationStore.application?.lastName}`
                : "Medicaid Done Right"
            )}
            <Header
              breadCrumbs={[
                { text: "", children: <Link to="/">Board</Link> },
                {
                  text: applicationStore.application?.fileNumber ? (
                    `${applicationStore.application?.firstName} ${applicationStore.application?.lastName} - ${applicationStore.application?.fileNumber}`
                  ) : (
                    <SkeletonComponent
                      className="loading-skeleton"
                      type="rectangle"
                      width={150}
                      height={15}
                    />
                  ),
                },
              ]}
            />
            <div className="content">
              <PageSidebar activePage="home" />
              {applicationStore.application && (
                <div className="page-main-content">
                  <ApplicationFormProgress
                    hasMedicalCoverage={
                      applicationStore.application?.hasMedicalCoverage ===
                        true ||
                      applicationStore.application?.hasMedicarePartA === true ||
                      applicationStore.application?.hasMedicarePartB == true ||
                      applicationStore.application?.medicalCoverages?.length > 0
                    }
                    hasSpouse={applicationStore.application?.isMarried === true}
                    progress={applicationStore.application?.progress}
                    pageMissingFieldsCounters={
                      applicationStore.pageMissingFieldCount
                    }
                    currentPage={currentPage}
                    setCurrentPage={(page) => {
                      navigate(`/application-form/${params.id}/${page}`);
                    }}
                  />
                  <div className="application-card">
                    {(!applicationStore.application.isRecertProductType ||
                      (applicationStore.application.isRecertProductType &&
                        featureToggleStore.featureToggles?.EnableRecertAMD)) &&
                      applicationStore.eligibilitySummary && (
                        <EligibilityBanner
                          application={applicationStore.application}
                          eligibilitySummary={
                            applicationStore.eligibilitySummary
                          }
                        />
                      )}
                    <ApplicationActions
                      applicationId={applicationStore.application?.id}
                      isWithdrawn={applicationStore.application?.isWithdrawn}
                      canSubmitApplication={
                        permissionStore.canSubmitApplication &&
                        !!userStore.user?.canSubmitApplicationForm
                      }
                      canWithdrawApplicationForm={
                        !!userStore.user?.canWithdrawApplicationForm
                      }
                      canApplicationSubmitToState={
                        applicationStore.application
                          ?.canApplicationSubmitToState
                      }
                      canSubmitApplicationToState={
                        !!userStore.user?.canSubmitApplicationToState
                      }
                      hasApplicationStateSubmitted={
                        applicationStore.application
                          ?.hasApplicationStateSubmitted
                      }
                      canGenerateApplicationDocPackage={
                        !!userStore.user?.canGenerateApplicationDocPackage ||
                        !!userStore.user?.canGenerateDocsWhileClosed
                      }
                    />
                    {applicationFormCards[currentPage](
                      applicationStore.application
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Observer>
    </div>
  );
};

export const ApplicationForm = withAITracking(
  reactPlugin,
  ApplicationFormComponent,
  "ApplicationForm",
  "AI-flex-container"
);
