import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router";
import "./styles.scss";
import { Dialogs as MDRDialogs } from "../components/Dialogs";
import ErrorWrapper from "./ErrorWrapper";
import { autorun } from "mobx";
import { errorStore } from "../stores/ErrorStore";
import { ErrorBoundary } from "react-error-boundary";
import { Dialogs as UCLDialogs } from "@ucl/library";
export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const PagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (props) => {
  const outlet = useOutlet();
  const [hasError, setHasError] = React.useState<boolean>(false);

  useEffect(() => {
    autorun(() => {
      setHasError(!!errorStore.errorMessage);
    });
  }, []);

  return (
    <>
      <div className="page">
        {hasError && <ErrorWrapper />}
        {!hasError && (
          <ErrorBoundary FallbackComponent={ErrorWrapper}>
            <>{outlet || <Navigate to={props.redirectPath} replace />}</>
          </ErrorBoundary>
        )}
      </div>
      <MDRDialogs />
      <UCLDialogs />
    </>
  );
};

export const PagesLayout = PagesLayoutComponent;
