import { ExistingResource } from "../ExistingResource";

/**
 * Gets the default asset owner based on the given conditions.
 * @param isMarried Whether the resident is married.
 * @param doesResidentOwnAnyAssets Whether the resident owns any assets.
 * @param doesSpouseSolelyOwnAnyAsset Whether the spouse solely owns any asset.
 * @returns {AssetOwnerTypes | undefined} The default asset owner based on the given conditions.
 */
export const getDefaultAssetOwner = (
  isMarried: boolean,
  doesResidentOwnAnyAssets: boolean,
  doesSpouseSolelyOwnAnyAsset: boolean
) => {
  // If the resident is single, then the default should be the resident
  if (!isMarried) {
    return AssetOwnerTypes.Resident;
  }

  // If only the spouse owns an asset, the default should be the spouse
  if (!doesResidentOwnAnyAssets && doesSpouseSolelyOwnAnyAsset) {
    return AssetOwnerTypes.Spouse;
  }

  // The default should be empty if there are no conditions met
  return undefined;
};

export enum AssetTypes {
  RealProperty = 1,
  Vehicle = 2,
  Institution = 3,
  LiquidAsset = 4,
  LifeInsurance = 5,
  BurialPreNeed = 6,
  FiveYearLookback = 7,
}

export enum AssetOwnerTypes {
  Resident = 1,
  Joint = 2,
  Spouse = 3,
}

export interface AssetInformation {
  hasAssetRealProperty?: boolean;
  hasAssetVehicle?: boolean;
  hasAssetInstitution?: boolean;
  hasAssetLiquidAsset?: boolean;
  hasAssetLifeInsurance?: boolean;
  hasAssetBurialPreneed?: boolean;
  hasAssetFiveYearLookback?: boolean;
  //Spouse
  spouseHasAssetRealProperty?: boolean;
  spouseHasAssetVehicle?: boolean;
  spouseHasAssetInstitution?: boolean;
  spouseHasAssetLiquidAsset?: boolean;
  spouseHasAssetLifeInsurance?: boolean;
  spouseHasAssetBurialPreneed?: boolean;
  spouseHasAssetFiveYearLookback?: boolean;
}

export interface NewAsset {
  type?: number;
  subType?: number;
  description?: string;
  vehicleYear?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  isVehicleRegistered?: boolean;
  accountNumber?: string;
  institutionId?: string;
  owner?: number;
  estimatedValue?: number;
  value?: number;
  isExempt?: number;
  asOfDate?: Date;
  isVerified?: boolean;
  isBurialPreneed?: boolean;
  hasOtherInstitutionName?: boolean;
  otherInstitutionName?: string;
  statementDates_AsList?: string[];
  statementDateRangeStart?: string;
  statementCycleEndDay?: number;
  statementFrequency?: number;
  aggregatedStatementsNeeded?: string[];
  funeralHome?: string;
  isIrrevocable?: boolean;
  isFunded?: boolean;
  fundingSource?: string;
  recipient?: number;
  transferDate?: Date;
  willPenaltyOccur?: boolean;
  isClosed?: boolean;
  closedDate?: Date;
  isIrrevocablyAssigned?: boolean;
  irrevocablyAssignedType?: number;
  irrevocablyAssignedDate?: Date;
}

export type Asset = ExistingResource<NewAsset>;
