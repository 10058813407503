import { Card, CardProps, Collapse } from "@blueprintjs/core";
import { FC, ReactNode } from "react";
import { RelativitySubCardHeader } from "./RelativitySubCardHeader";
import "./styles.scss";

interface RelativitySubCardProps extends CardProps {
  headerContent?: string | ReactNode | undefined;
  hideBody?: boolean;
  columns?: number;
}

/**
 * Used Only As A Nested Child of Relativity Card
 * `RelativitySubCard` is a wrapper around @blueprintjs/core's `Card` component.
 *
 * This wrapper adds header & body capabilities.
 */
const RelativitySubCard: FC<RelativitySubCardProps> = ({
  headerContent,
  hideBody,
  columns,
  children,
  ...cardProps
}) => {
  return (
    <div className="relativity-subcard">
      <Card {...cardProps}>
        {!!headerContent &&
          (typeof headerContent === "string" ||
            headerContent instanceof String) && (
            <RelativitySubCardHeader label={headerContent as string} />
          )}
        {!!headerContent &&
          !(
            typeof headerContent === "string" || headerContent instanceof String
          ) && (
            <section className="relativity-subcard__header">
              {headerContent}
            </section>
          )}
        <Collapse isOpen={!hideBody}>
          <div
            className={`relativity-card__body ${
              columns ? "relativity-grid relativity-grid-" + columns : ""
            }`}
          >
            {children}
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

export default RelativitySubCard;
