export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return "";
  }

  phoneNumber = phoneNumber.trim();

  const correctLength = phoneNumber.length === 10;
  const correctChars = /^\d+$/.test(phoneNumber);

  if (!correctLength || !correctChars) {
    // don't format phone numbers we aren't expecting
    return phoneNumber;
  }

  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};
