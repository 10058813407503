import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { ExistingMonitor } from "../../types/Monitor";
import { monitorFormSchema } from "../Forms/MonitorForm/MonitorForm";
import { applicationStore } from "../../stores/ApplicationStore";
export interface MonitorUpdateDialogProps extends BaseDialogProps {}

export const MonitorUpdateDialog: React.FC<MonitorUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [monitor, setMonitor] = useState<ExistingMonitor | null>(null);

  useEffect(() => {
    if (dialogsViewerStore.isMonitorUpdateDialogOpen) {
      if (applicationStore.application?.monitor) {
        setMonitor(applicationStore.application.monitor);
      }
    }
  }, [dialogsViewerStore.isMonitorUpdateDialogOpen]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Monitor"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            monitor && (
              <Form
                formId="update-monitor-form"
                value={monitor}
                formSchemaBuilder={monitorFormSchema}
                onFormSubmit={async (monitor) => {
                  setIsSaving(true);

                  await applicationStore
                    .updateApplicationMonitor(monitor)
                    .then(() => {
                      gridStore.refresh();
                      submitDialog();
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }}
              />
            )
          }
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="monitor-save-button"
          isSaving={isSaving}
          formId="update-monitor-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
