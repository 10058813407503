import { Card, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC, useState } from "react";
import { Application } from "../../../types/Application";
import { SecondaryInformation } from "../../../types/Application/SecondaryInformation";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import Form from "../../Forms";
import {
  newSecondaryInformation,
  secondaryInformationFormSchema,
} from "../../Forms/ApplicationForm/SecondaryInformation";
import { AppToaster } from "../../Toast/Toast";
import classNames from "classnames";
import { getApplicationStatusClassName } from "../../../utils/miscellaneous";

export interface SecondaryInformationCardProps {
  application?: Application;
  canEdit?: boolean;
  submitForm: (application: SecondaryInformation) => Promise<void>;
}

export const SecondaryInformationCard: FC<SecondaryInformationCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <>
      <Card
        className={classNames(
          "secondary-information-card",
          getApplicationStatusClassName(application?.status_AsString)
        )}
      >
        <Observer>
          {() => (
            <Form<SecondaryInformation>
              formId="secondary-information-form"
              shouldBlockNavigation={true}
              value={application || newSecondaryInformation}
              disableAllFields={!canEdit}
              formSchemaBuilder={secondaryInformationFormSchema}
              onFormSubmit={async (application) => {
                setIsSaving(true);
                await submitForm(application)
                  .then(() => {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Application Saved
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </Card>
      <div className="application-card-footer">
        <FormSubmitButton
          disabled={!canEdit}
          buttonId="secondary-information-save-button"
          isSaving={isSaving}
          formId="secondary-information-form"
          text="Save"
        />
      </div>
    </>
  );
};
