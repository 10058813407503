import React from "react";
import classnames from "classnames";
import "./styles.scss";

export interface GenericListBoxItem {
  id: string;
}

export interface ListBoxProps<T extends GenericListBoxItem> {
  value: T[];
  change: (data: T) => void;
  itemTemplate: (data: T) => React.ReactNode;
  isItemSelected?: (data: T) => boolean;
}

export const ListBox = <T extends GenericListBoxItem>(
  props: React.PropsWithChildren<ListBoxProps<T>>
) => {
  return (
    <div className="list-box-wrapper">
      <ul className="list-box">
        {props.value.map((data) => (
          <li
            key={data.id}
            className={classnames({
              selected: props.isItemSelected && props.isItemSelected(data),
            })}
            onClick={() => props.change(data)}
          >
            {props.itemTemplate(data)}
          </li>
        ))}
      </ul>
    </div>
  );
};
