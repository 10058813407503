import { Button, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import React from "react";
import "./styles.scss";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";

interface PageSidebarDocumentFilingProps {
  onOpenChanged?: (isOpen: boolean) => void;
  disabled?: boolean;
}
export const PageSidebarDocumentFiling: React.FC<
  PageSidebarDocumentFilingProps
> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const sidebarButtonClickHandler = () => {
    dialogsViewerStore.setIsDocumentFilingDialogOpen(true);
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={classnames("page-sidebar-item", {
        "page-sidebar-item__selected": isOpen,
      })}
    >
      <Button
        minimal={true}
        intent={Intent.PRIMARY}
        onClick={sidebarButtonClickHandler}
        disabled={props.disabled}
      >
        <Icon icon={IconNames.UPLOAD} size={20} />
      </Button>
    </div>
  );
};
