import { Dialog } from "@blueprintjs/core";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";
import { VerifyDialogComponent } from "../Checklist/Verify/VerifyDialogComponent";

export interface VerifyDialogProps extends BaseDialogProps {
  onSave: () => void;
  checklistItemId?: string;
  documentId?: string;
}

export const VerifyDialog: React.FC<VerifyDialogProps> = (props) => {
  return (
    <Dialog
      portalClassName="mdr-dialog fullscreen-dialog"
      className="verify-dialog"
      isOpen={props.isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Verify Document"}
      onClose={props.closeDialog}
    >
      <VerifyDialogComponent
        checklistItemId={props.checklistItemId}
        documentId={props.documentId}
        onSave={props.onSave}
        onCancel={props.closeDialog}
        closeDialog={props.closeDialog}
        submitDialog={props.submitDialog}
      />
    </Dialog>
  );
};
