import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const applyToStateFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "comment";
  const applyToStateRequest = form as { comment: string };

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "message"),
      value: applyToStateRequest.comment,
      onSubmit: (comment: string) => {
        onValueChange({ comment });
      },
      isRequired: true,
      errorMessages: get(errors, "comment"),
    } as AllFields,
  ];
};
