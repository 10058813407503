import { AddressField } from "./AddressField/AddressField";
import { CheckboxBooleanField } from "./CheckboxField/CheckboxBooleanField";
import { CheckboxField } from "./CheckboxField/CheckboxField";
import { CustomField } from "./CustomField/CustomField";
import { EmailField } from "./EmailField/EmailField";
import { NameField } from "./NameField/NameField";
import { NumberField } from "./NumberField/NumberField";
import { PhoneField } from "./PhoneField/PhoneField";
import { RadioField } from "./RadioField/RadioField";
import { MultiSelectField } from "./SelectField/MultiSelectField";
import { SelectField } from "./SelectField/SelectField";
import { TextAreaField } from "./TextAreaField/TextAreaField";
import { TextField } from "./TextField/TextField";
import { SignatureField } from "./SignatureField/SignatureField";
import { SocialSecurityNumberField } from "./SocialSecurityNumberField/SocialSecurityNumberField";
import { FilenameField } from "./FilenameField/FilenameField";
import { SwitchField } from "./SwitchField/SwitchField";
import { CurrencyField } from "./CurrencyField/CurrencyField";
import {
  AddressFieldProps,
  AllFields,
  AsyncMultiselectFieldProps,
  CheckboxBooleanFieldProps,
  CheckboxFieldProps,
  CustomFieldProps,
  DateFieldProps,
  EmailFieldProps,
  FileFieldProps,
  MultiSelectFieldProps,
  NameFieldProps,
  NumberFieldProps,
  PhoneFieldProps,
  RadioFieldProps,
  SectionHeaderProps,
  SelectFieldProps,
  SignatureFieldProps,
  TextAreaFieldProps,
  TextFieldProps,
  YesNoFieldProps,
  SocialSecurityNumberFieldProps,
  FilenameFieldProps,
  CurrencyFieldProps,
  FullNameFieldProps,
  DateRangeFieldProps,
  AsyncAutocompleteFieldProps,
  AutocompleteFieldProps,
  TagsFieldProps,
  ReadOnlyFieldProps,
  FieldType,
  SwitchFieldProps,
  AsyncMultiSelectCheckboxFieldProps,
  BaseFieldProps,
  PercentageFieldProps,
} from "./types/fieldTypes";
import "./styles.scss";
import { DateField } from "./DateField/DateField";
import { SectionHeader } from "./SectionHeader/SectionHeader";
import { FileField } from "./FileField/FileField";
import { YesNoField } from "./RadioField/YesNoField";
import { AsyncMultiselectField } from "./SelectField/AsyncMultiselectField";
import { FullNameField } from "./NameField/FullNameField";
import { DateRangeField } from "./DateRangeField/DateRangeField";
import { AsyncAutocompleteField } from "./AsyncAutocompleteField/AsyncAutocompleteField";
import { TagsField } from "./TagsField/TagsField";
import { ReadOnlyField } from "./ReadOnlyField/ReadOnlyField";
import { getObjectValueClassName } from "../../utils/miscellaneous";
import { AutocompleteField } from "./AutocompleteField/AutocompleteField";
import { AsyncMultiSelectCheckoxField } from "./SelectField/AsyncMultiSelectCheckboxField";
import { userStore } from "../../stores/UserStore";
import { SwitchField as UclSwitchField } from "@ucl/library";
import { SwitchProps as UclSwitchFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { PercentageField } from "./PercentageField/PercentageField";
import classNames from "classnames";

export const buildField = (
  fieldProps: AllFields | SectionHeaderProps,
  idx: number,
  handleOnBlur?: any
) => {
  const typeClassName = !fieldProps.label
    ? ""
    : getObjectValueClassName(fieldProps.type);
  const labelClassName = !fieldProps.label
    ? ""
    : getObjectValueClassName(
        typeof fieldProps.label === "string" ? (fieldProps.label as string) : ""
      );

  const renderField = (type: FieldType | "Section") => {
    switch (type) {
      case "Text":
        return <TextField key={idx} {...(fieldProps as TextFieldProps)} />;
      case "Textarea":
        return (
          <TextAreaField key={idx} {...(fieldProps as TextAreaFieldProps)} />
        );
      case "Email":
        return <EmailField key={idx} {...(fieldProps as EmailFieldProps)} />;
      case "Number":
        return (
          <NumberField
            key={(fieldProps as BaseFieldProps).fieldKey || idx}
            {...(fieldProps as NumberFieldProps)}
          />
        );
      case "Name":
        return <NameField key={idx} {...(fieldProps as NameFieldProps)} />;
      case "FullName":
        return (
          <FullNameField key={idx} {...(fieldProps as FullNameFieldProps)} />
        );
      case "Radio":
        return <RadioField key={idx} {...(fieldProps as RadioFieldProps)} />;
      case "Checkbox":
        return (
          <CheckboxField key={idx} {...(fieldProps as CheckboxFieldProps)} />
        );
      case "Checkbox-Bool":
        return (
          <CheckboxBooleanField
            key={idx}
            {...(fieldProps as CheckboxBooleanFieldProps)}
          />
        );
      case "Select":
        return (
          <SelectField
            key={idx}
            permissions={userStore.user as any}
            {...(fieldProps as SelectFieldProps)}
          />
        );
      case "Switch":
        return <SwitchField key={idx} {...(fieldProps as SwitchFieldProps)} />;
      case "Tags":
        return <TagsField key={idx} {...(fieldProps as TagsFieldProps)} />;
      case "ReadOnly":
        return (
          <ReadOnlyField key={idx} {...(fieldProps as ReadOnlyFieldProps)} />
        );
      case "Autocomplete":
        return (
          <AutocompleteField
            key={idx}
            {...(fieldProps as AutocompleteFieldProps)}
          />
        );
      case "AsyncAutocomplete":
        return (
          <AsyncAutocompleteField
            key={(fieldProps as AsyncAutocompleteFieldProps).fieldKey || idx}
            {...(fieldProps as AsyncAutocompleteFieldProps)}
          />
        );
      case "AsyncMultiselect":
        return (
          <AsyncMultiselectField
            key={idx}
            {...(fieldProps as AsyncMultiselectFieldProps)}
          />
        );
      case "AsyncMultiSelectCheckbox":
        return (
          <AsyncMultiSelectCheckoxField
            key={
              (fieldProps as AsyncMultiSelectCheckboxFieldProps).fieldKey || idx
            }
            {...(fieldProps as AsyncMultiSelectCheckboxFieldProps)}
          />
        );
      case "Multiselect":
        return (
          <MultiSelectField
            key={(fieldProps as MultiSelectFieldProps).fieldKey || idx}
            {...(fieldProps as MultiSelectFieldProps)}
          />
        );
      case "Address":
        return (
          <AddressField key={idx} {...(fieldProps as AddressFieldProps)} />
        );
      case "File":
        return <FileField key={idx} {...(fieldProps as FileFieldProps)} />;
      case "Phone":
        return <PhoneField key={idx} {...(fieldProps as PhoneFieldProps)} />;
      case "Signature":
        return (
          <SignatureField key={idx} {...(fieldProps as SignatureFieldProps)} />
        );
      case "Datetime":
        return <DateField key={idx} {...(fieldProps as DateFieldProps)} />;
      case "Custom":
        return <CustomField key={idx} {...(fieldProps as CustomFieldProps)} />;
      case "Section":
        return (
          <SectionHeader key={idx} {...(fieldProps as SectionHeaderProps)} />
        );
      case "YesNo":
        return <YesNoField key={idx} {...(fieldProps as YesNoFieldProps)} />;
      case "SocialSecurityNumber":
        return (
          <SocialSecurityNumberField
            key={idx}
            {...(fieldProps as SocialSecurityNumberFieldProps)}
          />
        );
      case "Filename":
        return (
          <FilenameField key={idx} {...(fieldProps as FilenameFieldProps)} />
        );
      case "Currency":
        return (
          <CurrencyField key={idx} {...(fieldProps as CurrencyFieldProps)} />
        );
      case "Percentage":
        return (
          <PercentageField
            key={idx}
            {...(fieldProps as PercentageFieldProps)}
          />
        );
      case "DateRange":
        return (
          <DateRangeField key={idx} {...(fieldProps as DateRangeFieldProps)} />
        );
      // UCL Fields
      case "UclSwitch":
        return (
          <UclSwitchField key={idx} {...(fieldProps as UclSwitchFieldProps)} />
        );
      default:
        console.warn("Something went wrong");
        return null;
    }
  };
  return (
    <div
      className={classNames(
        `base-field base-field__${typeClassName}`,
        `base-field__${labelClassName}`,
        fieldProps.className
      )}
      onBlur={handleOnBlur}
    >
      {renderField(fieldProps.type)}
    </div>
  );
};
