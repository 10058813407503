import moment from "moment-timezone";

export const convertDateTo_UTC_AsString = (date?: string | Date) => {
  if (!date) {
    return undefined;
  }

  return moment(date).utc().format("MM/DD/YYYY hh:mm A");
};

export const convertDateTo_EST_timeZone_AsString = (date?: string | Date) => {
  if (!date) {
    return undefined;
  }

  return moment.utc(date).tz("America/New_York").format("MM/DD/YYYY hh:mm A");
};

export const convertDateTo_EST_timeZone_AsDate = (date: string | Date) => {
  return moment.utc(date).tz("America/New_York").toDate();
};

export const removeOffSetFromDate = (dateString: string): string => {
  // Validation
  if (!dateString) {
    return dateString;
  }
  if (dateString.slice(-1) === "Z") {
    return dateString;
  }

  // Get Offset
  const offsetStr = dateString.substring(dateString.lastIndexOf("-"));

  // Convert Offset to Z
  if (/[\+\-]\d{2}:\d{2}$/.test(offsetStr)) {
    dateString = `${dateString.replace(offsetStr, "")}Z`;
  }

  // Return
  return dateString;
};
