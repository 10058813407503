import { action, makeObservable } from "mobx";
import {
  showFailedSaveAppToaster,
  showGenericFailedSaveAppToaster,
} from "../../components/Toast/Toast";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { Application } from "../../types/Application";
import { applicationStore, OwnedEntityPageItem } from "../ApplicationStore";

export interface OwnedEntityStore {
  createAndAwaitApplicationOwnedEntity: (
    itemToCreate: OwnedEntityPageItem
  ) => void;
  updateAndAwaitApplicationOwnedEntity: (
    itemToUpdate: OwnedEntityPageItem
  ) => void;
  awaitAndValidateOwnedEntityPromises: (
    promises: Promise<Application | undefined>[],
    entityName: string
  ) => void;
}

export class OwnedEntityViewerStore implements OwnedEntityStore {
  constructor() {
    makeObservable(this, {
      createAndAwaitApplicationOwnedEntity: action,
      updateAndAwaitApplicationOwnedEntity: action,
      awaitAndValidateOwnedEntityPromises: action,
    });
  }

  public createAndAwaitApplicationOwnedEntity = async (
    item: OwnedEntityPageItem
  ) => {
    if (!applicationStore.application) {
      return;
    }

    return applicationApiClient.createApplicationOwnedEntity(
      applicationStore.application.id,
      item.ownedEntity,
      item.page
    );
  };

  public updateAndAwaitApplicationOwnedEntity = async (
    item: OwnedEntityPageItem
  ) => {
    if (!applicationStore.application || !item.ownedEntityId) {
      return;
    }

    return applicationApiClient.updateApplicationOwnedEntity(
      applicationStore.application.id,
      item.ownedEntityId,
      item.ownedEntity,
      item.page
    );
  };

  public awaitAndValidateOwnedEntityPromises = async (
    promises: Promise<Application | undefined>[],
    entityName: string
  ) => {
    const failedPromises: string[] = [];
    await Promise.all(promises)
      .then(async (e) => {
        for (let i = 0; i < e.length; i++) {
          const promise = e[i];

          if (!promise) {
            failedPromises.push(`${entityName} ${i},`);
          }
        }

        if (failedPromises.length > 0) {
          showFailedSaveAppToaster(failedPromises);
        }
        //Get the last most up to date excuted promise value, and evaluate AMD decision if Income/Asset (both methods set app on store)
        switch (entityName) {
          case "asset":
            await applicationStore.evaluateApplicationAMDDecision(
              applicationStore.application?.id || "",
              `${entityName} - OwnedEntityViewerStore`
            );
            break;
          case "income":
            await applicationStore.evaluateApplicationAMDDecision(
              applicationStore.application?.id || "",
              `${entityName} - OwnedEntityViewerStore`
            );
            break;
          default:
            await applicationStore.getApplicationById(
              applicationStore.application?.id || ""
            );
        }
      })
      .catch(() => {
        showGenericFailedSaveAppToaster;
      });
  };
}

export const ownedEntityViewerStore = new OwnedEntityViewerStore();
