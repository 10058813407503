import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Header } from "../components/Header/Header";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import "./styles.scss";
import { reactPlugin } from "../AppInsights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { MatchupGridV2 } from "../components/Matchup/MatchupGridV2/MatchupGridV2";
import { userStore } from "../stores/UserStore";
import { openFlyoutInPlace, useFlyout } from "@ucl/library";
import { MatchupFlyout } from "../components/Matchup/MatchupFlyout";
import { useOutlet } from "react-router";

const MatchupPageComponent: React.FC = () => {
  const outlet = useOutlet();
  const { isOpen, openFlyout, closeFlyout } = useFlyout();
  const [matchupId, setMatchupId] = useState<string>();

  useEffect(() => {
    if (matchupId) {
      openFlyout();
    }

    return () => {
      closeFlyout();
    };
  }, [matchupId]);

  const onRecordClick = (matchupId: string) => {
    setMatchupId(matchupId);
  };

  return (
    <div className="page board-page">
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="matchup" />
        <div className="page-main-content">
          <section className="board-grid-container"></section>
          <MatchupGridV2
            canConfigureBoard={true}
            canCreateNewRecord={false}
            canExportGridToExcel={true}
            canInvokeGridRecord={true}
            canImportRecords={userStore.user?.canImportMatchups || false}
            onRecordClick={onRecordClick}
          />
          {isOpen &&
            openFlyoutInPlace(
              <MatchupFlyout
                matchupId={String(matchupId)}
                flyoutIsOpen={true}
                onClose={() => {
                  closeFlyout();
                  setMatchupId(undefined);
                }}
              />,
              isOpen,
              () => {
                closeFlyout();
                setMatchupId(undefined);
              },
              { canOutsideClickClose: true }
            )}
          {outlet}
        </div>
      </div>
    </div>
  );
};

export const MatchupPage = withAITracking(
  reactPlugin,
  MatchupPageComponent,
  "MatchupPage",
  "AI-flex-container"
);
