import { Button, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import React from "react";
import { FilenameField } from "../Fields/FilenameField/FilenameField";
import { DIALOG_FOOTER } from "@blueprintjs/core/lib/esm/common/classes";
import classNames from "classnames";
import { useDocumentEditor } from "../Document/useDocumentEditor";
import { get } from "lodash";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";

export interface DocumentRenameDialogProps extends BaseDialogProps {
  documentId?: string;
}

export const DocumentRenameDialog: React.FC<DocumentRenameDialogProps> = ({
  documentId,
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  React.useState<boolean>(false);
  const [isActionExecuting, setIsActionExecuting] =
    React.useState<boolean>(false);

  const {
    isLoadingDocument,
    updateDocument,
    updatedDocumentName,
    isDocumentNameChanged,
    isOriginalFileNameModified,
    setUpdatedDocumentName,
    errors,
  } = useDocumentEditor({ documentId: documentId as string });

  React.useEffect(() => {
    const actionExecuting = isLoadingDocument || isSaving;
    setIsActionExecuting(actionExecuting);
  }, [isLoadingDocument, isSaving]);

  const closeDocumentNameDialog = async (updateValue?: boolean) => {
    if (updateValue) {
      setIsSaving(true);

      await updateDocument(`${updatedDocumentName || ""}.pdf`);

      setIsSaving(false);

      submitDialog();
    } else {
      closeDialog();
    }
  };

  return (
    <Dialog
      portalClassName="mdr-dialog document-rename-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Document Rename"}
      onClose={closeDialog}
    >
      {isLoadingDocument && (
        <div className="loading">
          <SkeletonComponent height="15px"></SkeletonComponent>
          <br />
        </div>
      )}
      {!isLoadingDocument && (
        <div
          className={classNames(
            "checklist-split-document-name-dialog-container",
            {
              "document-editor-original-filename-modified":
                isOriginalFileNameModified,
            }
          )}
        >
          <div className="checklist-split-document-name-dialog-form">
            <div className="checklist-split-document-name-dialog-form-name">
              <FilenameField
                label={"Document Name"}
                isRequired={true}
                readOnly={false}
                disabled={false}
                maxLength={250}
                type={"Filename"}
                value={updatedDocumentName || ""}
                errorMessages={get(errors, "originalFileName")}
                onSubmit={function (value: unknown): void {
                  setUpdatedDocumentName(value as string);
                }}
              />
            </div>

            <div
              className={classNames(
                DIALOG_FOOTER,
                "checklist-split-document-name-dialog-form-footer"
              )}
            >
              <Button
                minimal
                intent={Intent.DANGER}
                text="Cancel"
                disabled={isActionExecuting}
                onClick={() => closeDocumentNameDialog()}
              />
              <Button
                intent={Intent.SUCCESS}
                disabled={
                  isActionExecuting ||
                  !isDocumentNameChanged ||
                  !updatedDocumentName
                }
                loading={isSaving}
                text="Save"
                onClick={() => closeDocumentNameDialog(true)}
              />
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};
