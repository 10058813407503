import {
  Button,
  Classes,
  Dialog,
  Intent,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { BaseDialogProps } from "./types/types";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { useNavigate } from "react-router";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import {
  ApplicationSummaryResponseDTO,
  defaultApplicationSummaryResponseDTO,
} from "../../types/Application/ApplicationSummary";
import { OutstandingDocumentsCard } from "../Application/Cards/SummaryDialog/OutstandingDocumentsCard";
import { OutstandingFIARequestsCard } from "../Application/Cards/SummaryDialog/OutstandingFIARequestsCard";
import { KeyDatesCard } from "../Application/Cards/SummaryDialog/KeyDatesCard";
import { FileInfoCard } from "../Application/Cards/SummaryDialog/FileInfoCard";
import { TopPinnedCommentsCard } from "../Application/Cards/SummaryDialog/TopPinnedCommentsCard";
import { EligibilitySummaryCard } from "../Application/Cards/SummaryDialog/EligibilitySummaryCard";
import { errorStore, genericErrorMessage } from "../../stores/ErrorStore";
import { userStore } from "../../stores/UserStore";
import { UploadDocumentsCard } from "../Application/Cards/SummaryDialog/UploadDocumentsCard";
import { EligibilitySummaryResponseDTO } from "../../types/Application/EligibilitySummary";
import { FinancialSummaryCard } from "../Application/Cards/SummaryDialog/FinancialSummary";
import variables from "../../config/variables";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface ApplicationSummaryDialogProps extends BaseDialogProps {
  applicationId?: string;
}

export const ApplicationSummaryDialog: React.FC<
  ApplicationSummaryDialogProps
> = ({ isOpen, closeDialog, applicationId }) => {
  const navigate = useNavigate();

  const [applicationSummary, setApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(
      defaultApplicationSummaryResponseDTO
    );
  const [eligibilitySummary, setEligibilitySummary] = useState<
    EligibilitySummaryResponseDTO | undefined
  >(undefined);
  const [ready, setReady] = useState<boolean>(false);

  const fetchApplicationSummary = async () => {
    if (applicationId && dialogsViewerStore.isApplicationSummaryDialogOpen) {
      const applicationSummary = userStore.user?.isExternal
        ? await applicationApiClient
            .getExternalUserApplicationSummary(applicationId)
            .catch(() => {
              errorStore.setErrorMessage(genericErrorMessage);
            })
        : await applicationApiClient
            .getApplicationSummary(applicationId)
            .catch(() => {
              errorStore.setErrorMessage(genericErrorMessage);
            });

      // Set Eligibility Summary
      await applicationApiClient
        .getEligibilitySummary(applicationId)
        .then(setEligibilitySummary);

      // Set Application Summary
      applicationSummary &&
        setApplicationSummary({
          ...defaultApplicationSummaryResponseDTO,
          ...applicationSummary,
        });
      setReady(true);
    }
  };
  useEffect(() => {
    (async () => {
      // fetch
      fetchApplicationSummary();
    })();
    return () => {
      setReady(false);
    };
  }, [dialogsViewerStore.isApplicationSummaryDialogOpen]);

  return (
    <>
      <Dialog
        portalClassName="mdr-dialog"
        isOpen={isOpen}
        hasBackdrop={false}
        isCloseButtonShown={true}
        title={"Application Summary"}
        onClose={closeDialog}
        className={"summary-dialog"}
      >
        {ready ? (
          <div className={Classes.DIALOG_BODY}>
            <div className="summary-dialog__body">
              <FileInfoCard applicationSummary={applicationSummary} />
              {(!applicationSummary.isRecertProductType ||
                (applicationSummary.isRecertProductType &&
                  featureToggleStore.featureToggles?.EnableRecertAMD)) &&
              ((userStore.user?.isExternal &&
                variables.featureToggleConfig
                  .externalSummaryEligibilitySection) ||
                !userStore.user?.isExternal) ? (
                <EligibilitySummaryCard
                  applicationSummary={applicationSummary}
                  eligibilitySummary={eligibilitySummary}
                />
              ) : (
                <FinancialSummaryCard applicationSummary={applicationSummary} />
              )}
              <KeyDatesCard applicationSummary={applicationSummary} />
              <OutstandingDocumentsCard
                applicationSummary={applicationSummary}
              />
              {userStore.user?.isExternal && (
                <UploadDocumentsCard applicationSummary={applicationSummary} />
              )}
              <OutstandingFIARequestsCard
                applicationSummary={applicationSummary}
              />
              <TopPinnedCommentsCard
                applicationSummary={applicationSummary}
                isPinnedComments={true}
              />
              {userStore.user?.isExternal && (
                <TopPinnedCommentsCard
                  applicationSummary={applicationSummary}
                  isPinnedComments={false}
                  fetchApplicationSummary={fetchApplicationSummary}
                  setReady={setReady}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={`${Classes.DIALOG_BODY}__spinner`}>
            <Spinner intent={Intent.PRIMARY} size={SpinnerSize.LARGE} />
          </div>
        )}

        <div className={`${Classes.DIALOG_FOOTER}__summary-dialog`}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={closeDialog}
          />
          {!userStore.user?.isExternal && (
            <Button
              minimal
              intent={Intent.PRIMARY}
              text="View Application"
              onClick={() => {
                const route =
                  applicationSummary.status_AsString === "Interviewing"
                    ? `/application-form/${applicationId}/primary-information`
                    : `/application-page/${applicationId}`;
                navigate(route);
                closeDialog();
              }}
              rightIcon={IconNames.ArrowRight}
            />
          )}
        </div>
      </Dialog>
    </>
  );
};
