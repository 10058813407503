import { Intent } from "@blueprintjs/core";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { AppToaster } from "../Toast/Toast";
import { Report, ReportCreateDTO, ReportUpdateDTO } from "./types";
import { reportApiClient } from "../../lib/apiClients/report/reportApiClient";

export const useReport = () => {
  const getReports = async (): Promise<Report[] | undefined> => {
    const report = await reportApiClient.getReports().catch(() => {
      AppToaster.show(
        {
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        },
        "report-get-error"
      );
    });

    if (!report) {
      const isErrorToastShown = AppToaster.getToasts().some(
        (toast) => toast.key === "report-get-error"
      );

      if (!isErrorToastShown) {
        AppToaster.show(
          {
            message: <div>{genericErrorMessage}</div>,
            intent: Intent.DANGER,
          },
          "report-get-error"
        );
      }

      return undefined;
    }

    return report;
  };

  const getReport = async (id: string): Promise<Report | undefined> => {
    const report = await reportApiClient.getReport(id).catch(() => {
      AppToaster.show(
        {
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        },
        "report-get-error"
      );
    });

    if (!report) {
      const isErrorToastShown = AppToaster.getToasts().some(
        (toast) => toast.key === "report-get-error"
      );

      if (!isErrorToastShown) {
        AppToaster.show(
          {
            message: <div>{genericErrorMessage}</div>,
            intent: Intent.DANGER,
          },
          "report-get-error"
        );
      }

      return undefined;
    }

    return report;
  };

  const createReport = async (
    newReport: ReportCreateDTO
  ): Promise<Report | undefined> => {
    const report = await reportApiClient.createReport(newReport).catch(() => {
      AppToaster.show(
        {
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        },
        "report-get-error"
      );
    });

    if (!report) {
      return undefined;
    }

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Report was Created
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    return report;
  };

  const updateReport = async (
    id: string,
    modifiedReport: ReportUpdateDTO
  ): Promise<Report | undefined> => {
    const report = await reportApiClient
      .updateReport(id, modifiedReport)
      .catch(() => {
        AppToaster.show(
          {
            message: <div>{genericErrorMessage}</div>,
            intent: Intent.DANGER,
          },
          "report-get-error"
        );
      });

    if (!report) {
      return undefined;
    }

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Report was Updated
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    return report;
  };

  const deleteReport = async (id: string): Promise<boolean> => {
    let success = true;

    await reportApiClient.deleteReport(id).catch(() => {
      success = false;
      AppToaster.show(
        {
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        },
        "report-get-error"
      );
    });

    if (success) {
      AppToaster.show({
        message: (
          <div>
            <h3>Success</h3>Report was Deleted
          </div>
        ),
        intent: Intent.SUCCESS,
      });
    }

    return success;
  };

  const getBlankReport = (): Report => {
    return {
      id: "",
      title: "",
      externalLink: "",
      reportRoles_AsList: [],
    } as Report;
  };

  return {
    getBlankReport,
    getReports,
    getReport,
    createReport,
    updateReport,
    deleteReport,
  };
};
