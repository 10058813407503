import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { userStore } from "../../../../stores/UserStore";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { BaseDialogProps } from "../../types/types";
import { CorporateFacilitiesGrid } from "./CorporateFacilitiesGrid";

export interface CorporateFacilitiesGridDialogProps extends BaseDialogProps {}

export const CorporateFacilitiesGridDialog: React.FC<
  CorporateFacilitiesGridDialogProps
> = ({ isOpen, closeDialog }) => {
  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      isCloseButtonShown={true}
      title={"Corporate Facilities"}
      onClose={closeDialog}
      portalClassName="mdr-dialog corporate-facilities-dialog fullscreen-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        {dialogsViewerStore.corporateFacilitesGridDialogOpenOptions
          ?.corporateId && (
          <CorporateFacilitiesGrid
            canConfigureBoard={!!userStore.user?.canConfigureFacilityBoard}
            canExportGridToExcel={!!userStore.user?.canExportFacilityToExcel}
            canCreateNewRecord={!!userStore.user?.canCreateFacility}
            canInvokeGridRecord={!!userStore.user?.canEditFacility}
            corporateId={
              dialogsViewerStore.corporateFacilitesGridDialogOpenOptions
                .corporateId
            }
            predicate={
              dialogsViewerStore.corporateFacilitesGridDialogOpenOptions
                .predicate
            }
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          minimal
          intent={Intent.DANGER}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
