import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { Facility } from "../../types/Facility";
import {
  facilityFormSchema,
  newFormFacility,
} from "../Forms/FacilityForm/FacilityForm";
import { facilityApiClient } from "../../lib/apiClients/facility/facilityApiClient";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";

export interface FacilityCreationDialogProps extends BaseDialogProps {}

export const FacilityCreationDialog: React.FC<FacilityCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [facility, setFacility] = useState<Facility | undefined>(undefined);

  useEffect(() => {
    if (dialogsViewerStore.facilityCreateDialogOpenOptions?.corporateId) {
      const newFacility: Partial<Facility> = {
        ...newFormFacility,
        corporateId:
          dialogsViewerStore.facilityCreateDialogOpenOptions.corporateId,
      };
      setFacility(newFacility as Facility);
    } else {
      setFacility(newFormFacility as Facility);
    }

    return () => {
      setFacility(undefined);
    };
  }, [dialogsViewerStore.isFacilityCreationDialogOpen]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Facility"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<Facility>
              formId="create-facility-form"
              value={facility}
              formSchemaBuilder={facilityFormSchema}
              onFormSubmit={async (facility) => {
                setIsSaving(true);

                await facilityApiClient
                  .createFacility(facility)
                  .then(() => {
                    gridStore.refresh();
                    dialogsViewerStore.facilityCreateDialogOpenOptions
                      ?.onSubmit &&
                      dialogsViewerStore.facilityCreateDialogOpenOptions.onSubmit();
                    submitDialog();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="facility-save-button"
          isSaving={isSaving}
          formId="create-facility-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
