import { FC, useEffect, useState } from "react";
import { Application } from "../../../../../../types/Application";
import { ExistingApplicationContact } from "../../../../../../types/Application/ApplicationContact";
import { PrimaryInformation } from "../../../../../../types/Application/PrimaryInformation";
import { DeleteButton } from "../../../../../Buttons/DeleteButton";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../../../Forms";
import {
  applicationContactFormSchema,
  newApplicationContact,
} from "../../../../../Forms/ApplicationForm/ApplicationContact";
import { showSuccessfulSaveAppToaster } from "../../../../../Toast/Toast";
import { ApplicationContact } from "../../../../../../types/Application/ApplicationContact";
import { AddButton } from "../../../../../Buttons/AddButton";
import { Elevation, NonIdealState } from "@blueprintjs/core";
import { SaveButton } from "../../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../../Buttons/UndoButton";
import { Observer } from "mobx-react";
import { applicationStore } from "../../../../../../stores/ApplicationStore";
import { Signature } from "../../../../../../types/Application/Signature";
import { dialogsViewerStore } from "../../../../../Dialogs/stores/DialogsViewerStore";
import _, { orderBy } from "lodash";

export interface ApplicationPageContactCardProps {
  application?: Application;
  canCreateContact: boolean;
  canEditContact: boolean;
  canDeleteContact: boolean;
  createContact: (contact: ApplicationContact) => Promise<void>;
  updateContact: (contact: ExistingApplicationContact) => Promise<void>;
  deleteContact: (contact: ExistingApplicationContact) => Promise<void>;
  submitForm: (application: PrimaryInformation) => Promise<void>;
  showSingleContact?: boolean;
  contactId?: string;
}

const newApplicationContactId = "new-application-contact";
const sectionTitle = "Contact Information";
const baseFormId = "application-contact-form";

export const ApplicationPageContactCard: FC<
  ApplicationPageContactCardProps
> = ({
  application,
  canCreateContact,
  canEditContact,
  createContact,
  updateContact,
  canDeleteContact,
  deleteContact,
  showSingleContact,
  contactId,
}) => {
  const [localApplicationContacts, setLocalApplicationContacts] = useState<
    ExistingApplicationContact[]
  >(application?.contacts || []);
  const [busyContact, setBusyContact] = useState<string>();
  const [isContactDeleting, setIsContactDeleting] = useState<boolean>(false);
  const [contactToReset, setContactToReset] = useState<string>();

  const hasNewApplicationContact = localApplicationContacts.some(
    (contact) => contact.id === newApplicationContactId
  );

  //Is In Deleting State
  const isActiveDelete = (contact: ExistingApplicationContact) => {
    return isBusyContact(contact) && !!isContactDeleting;
  };

  //Is in Saving State
  const isActiveSaving = (contact: ExistingApplicationContact) => {
    return isBusyContact(contact) && !isContactDeleting;
  };

  //Busy - Meaning Request, Create, Update, Delete
  const isBusyContact = (contact: ExistingApplicationContact) => {
    return contact.id === busyContact;
  };

  const handleReset = async (contactToReset: ExistingApplicationContact) => {
    const exisitingLocalContacts = [...localApplicationContacts];

    exisitingLocalContacts.forEach((contact, index) => {
      if (contact.id === contactToReset.id) {
        exisitingLocalContacts[index] = { ...contact, ...contactToReset };
      }
    });

    setLocalApplicationContacts(exisitingLocalContacts);
  };

  useEffect(() => {
    if (!!showSingleContact && !!contactId) {
      const contact = application?.contacts.find(
        (contact) => contact.id === contactId
      );
      setLocalApplicationContacts(contact ? [contact] : []);
    } else {
      setLocalApplicationContacts(application?.contacts || []);
    }
  }, [application?.contacts]);
  return (
    <Observer>
      {() => (
        <RelativityCard
          headerContent={
            <div className="owned-entity-header application-contact-header">
              <SectionHeader label={sectionTitle} type="Section" />
              {canCreateContact && (
                <AddButton
                  disabled={hasNewApplicationContact}
                  text="Add Contact"
                  onClick={() => {
                    const contact = {
                      ...newApplicationContact,
                      id: newApplicationContactId,
                    } as ExistingApplicationContact;

                    setLocalApplicationContacts([
                      ...localApplicationContacts,
                      contact,
                    ]);
                  }}
                />
              )}
            </div>
          }
        >
          {localApplicationContacts?.length <= 0 && (
            <NonIdealState title="No Information Available" />
          )}
          {localApplicationContacts?.length > 0 &&
            orderBy(localApplicationContacts, ["createdAt"], ["desc"]).map(
              (applicationContact, index) => {
                const isNewApplicationContact =
                  applicationContact.id === newApplicationContactId;
                return (
                  <RelativityCard
                    elevation={Elevation.TWO}
                    key={applicationContact.id}
                    className="owned-entity-card application-contact-card"
                    headerContent={
                      <div className="owned-entity-header application-contact-card-header">
                        <SectionHeader
                          label={`Contact ${index + 1}`}
                          type="Section"
                        />
                        <div>
                          {canEditContact && (
                            <>
                              <>
                                <SaveButton
                                  formId={`${baseFormId}-${applicationContact.id}`}
                                  isSaving={isActiveSaving(applicationContact)}
                                  hidden={isActiveDelete(applicationContact)}
                                />
                                <UndoButton
                                  isSaving={isActiveSaving(applicationContact)}
                                  hidden={isActiveDelete(applicationContact)}
                                  onClick={async () => {
                                    setContactToReset(applicationContact.id);
                                    //Need to Await Changes in states in order to update correctly
                                    await handleReset(applicationContact);
                                    setContactToReset(undefined);
                                  }}
                                />
                                {canDeleteContact && (
                                  <DeleteButton
                                    isDeleting={isActiveDelete(
                                      applicationContact
                                    )}
                                    disabled={isActiveSaving(
                                      applicationContact
                                    )}
                                    hidden={isActiveSaving(applicationContact)}
                                    onClick={async () => {
                                      setIsContactDeleting(true);
                                      setBusyContact(applicationContact.id);

                                      if (isNewApplicationContact) {
                                        setLocalApplicationContacts(
                                          localApplicationContacts.filter(
                                            (applicationContacts) =>
                                              applicationContacts.id !==
                                              newApplicationContactId
                                          )
                                        );
                                      } else {
                                        const contactHaveSignature =
                                          applicationStore.application?.signatures.some(
                                            (signature: Signature) =>
                                              signature.contactId ===
                                              applicationContact.id
                                          );

                                        if (contactHaveSignature) {
                                          dialogsViewerStore.setIsConfirmDialogOpen(
                                            true,
                                            {
                                              content:
                                                "There is an active signature for this contact, are you sure you want delete?",
                                              onConfirm: async () => {
                                                await deleteContact(
                                                  applicationContact
                                                );
                                                setIsContactDeleting(false);
                                                setBusyContact(undefined);
                                                return {
                                                  isSuccess: true,
                                                  error: null,
                                                };
                                              },
                                              onClose: () => {
                                                setIsContactDeleting(false);
                                                setBusyContact(undefined);
                                              },
                                            }
                                          );
                                        } else {
                                          await deleteContact(
                                            applicationContact
                                          );
                                        }
                                      }

                                      setBusyContact(undefined);
                                      setIsContactDeleting(false);
                                    }}
                                  />
                                )}
                              </>
                            </>
                          )}
                        </div>
                      </div>
                    }
                  >
                    <Form<ExistingApplicationContact>
                      formId={`${baseFormId}-${applicationContact.id}`}
                      value={applicationContact}
                      formSchemaBuilder={applicationContactFormSchema}
                      shouldBlockNavigation={true}
                      shouldResetForm={contactToReset === applicationContact.id}
                      disableAllFields={
                        !canEditContact ||
                        isActiveSaving(applicationContact) ||
                        isActiveDelete(applicationContact)
                      }
                      onFormSubmit={async (applicationContact) => {
                        setBusyContact(applicationContact.id);

                        if (isNewApplicationContact) {
                          await createContact(applicationContact)
                            .then(() => {
                              showSuccessfulSaveAppToaster(sectionTitle);
                            })
                            .finally(() => {
                              setBusyContact(undefined);
                            });
                        } else {
                          await updateContact(applicationContact)
                            .then(() => {
                              showSuccessfulSaveAppToaster(sectionTitle);
                            })
                            .finally(() => {
                              setBusyContact(undefined);
                            });
                        }
                      }}
                    />
                  </RelativityCard>
                );
              }
            )}
        </RelativityCard>
      )}
    </Observer>
  );
};
