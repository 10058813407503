import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC } from "react";

export interface AddButtonProps {
  id?: string;
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const AddButton: FC<AddButtonProps> = ({
  id,
  className,
  text = "",
  disabled = false,
  loading = false,
  onClick,
}) => {
  return (
    <Button
      className={className}
      id={id}
      type="submit"
      disabled={disabled}
      minimal
      intent={Intent.PRIMARY}
      onClick={onClick}
      rightIcon={IconNames.Plus}
      text={text}
      loading={loading}
    />
  );
};
