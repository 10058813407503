import { Corporate, ExistingCorporate } from "../../../types/Corporate";
import baseApiClient from "../baseApiClient";

class CorporateApiClient {
  createCorporate = async (corporate: Corporate) => {
    return baseApiClient.post<ExistingCorporate>("corporate", corporate);
  };

  getCorporateDetail = async (id: string) => {
    return baseApiClient.get<ExistingCorporate>(`corporate/${id}`);
  };

  updateCorporate = async (id: string, corporate: ExistingCorporate) => {
    return baseApiClient.put(`corporate/${id}`, corporate);
  };
}

export const corporateApiClient = new CorporateApiClient();
