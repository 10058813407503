import { HelpCenterTopicCreate } from "./HelpCenterTopicCreate";
import { HelpCenterTopicUpdate } from "./HelpCenterTopicUpdate";
import "./styles.scss";
import { TopicSectionTypes } from "./types";

export interface HelpCenterTopicDetailsProps {
  sectionType: TopicSectionTypes;
  usState?: number;
  id?: string;
  onClose?: (refresh: boolean) => void;
}

export const HelpCenterTopicDetails: React.FC<HelpCenterTopicDetailsProps> = (
  props
) => {
  const closeDialogHandler = (refresh: boolean) => {
    props.onClose && props.onClose(refresh);
  };

  return (
    <section className="helpcenter-topic-details">
      {!props.id && (
        <HelpCenterTopicCreate
          sectionType={props.sectionType}
          usState={props.usState}
          onAction={closeDialogHandler}
        />
      )}
      {props.id && (
        <HelpCenterTopicUpdate
          id={props.id}
          sectionType={props.sectionType}
          onAction={closeDialogHandler}
        />
      )}
    </section>
  );
};
