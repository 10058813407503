import { FC } from "react";
import { DocumentPreview } from "../../Document/DocumentPreview";
import { documentApiClient } from "../../../lib/apiClients/document/documentApiClient";
import { useVerifyComponent } from "./useVerifyComponent";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { VerifyForm } from "./VerifyForm";
import { checklistApiClient } from "../../../lib/apiClients/checklist/checklistApiClient";

export interface VerifyComponentProps {
  checklistItemId?: string;
  documentId?: string;
  onCancel: () => void;
  closeDialog: () => void;
  onSave: () => void;
  submitDialog: () => void;
}
export const VerifyDialogComponent: FC<VerifyComponentProps> = (props) => {
  const {
    verifyFormProperties,
    isDocumentVerified,
    fiaFollowUpReasonNotSelected,
  } = useVerifyComponent(props.checklistItemId, props.documentId);

  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <div className="verify-panels">
          {props.documentId && (
            <div className="verify-panel">
              <DocumentPreview
                getDocument={documentApiClient.downloadDocument(
                  props.documentId
                )}
              />
            </div>
          )}
          <div className="verify-panel">
            <VerifyForm
              {...verifyFormProperties}
              documentId={props.documentId}
              onSave={props.onSave}
            />
          </div>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={props.closeDialog}
        />
        <Button
          intent={isDocumentVerified ? Intent.SUCCESS : Intent.PRIMARY}
          text={isDocumentVerified ? "Verified" : "Verify"}
          disabled={fiaFollowUpReasonNotSelected || isDocumentVerified}
          loading={verifyFormProperties.isSaving}
          onClick={async () => {
            verifyFormProperties.checklistItem &&
              props.documentId &&
              (verifyFormProperties.setIsSaving(true),
              await checklistApiClient
                .verifyChecklistDocument(
                  verifyFormProperties.checklistItem?.id,
                  props.documentId,
                  verifyFormProperties.documentVerifyDTO
                )
                .then(() => {
                  verifyFormProperties.setIsSaving(false);
                  props.submitDialog();
                }));
          }}
        />
      </div>
    </div>
  );
};
