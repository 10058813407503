import { get } from "lodash";
import { schemaStore } from "../../../../stores/SchemaStore";
import { Asset, AssetInformation } from "../../../../types/Application/Asset";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../../types";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { ApplicationStatuses } from "../../../../types/Application";
import { userStore } from "../../../../stores/UserStore";
import { getAssetOwnerOptions } from "../../../../types/Application/AssetHelpers";

export const burialPreneedApplicationFormSchema: FormSchemaBuilder = (
  _onValueChange,
  form,
  _errors,
  _formId,
  onFormSubmit
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as AssetInformation;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasAssetBurialPreneed"),
      value: application.hasAssetBurialPreneed,
      onSubmit: (hasAssetBurialPreneed) => {
        if (onFormSubmit) {
          onFormSubmit({ ...application, hasAssetBurialPreneed });
        }
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "spouseHasAssetBurialPreneed"
      ),
      value: application.spouseHasAssetBurialPreneed,
      onSubmit: (spouseHasAssetBurialPreneed) => {
        if (onFormSubmit) {
          onFormSubmit({ ...application, spouseHasAssetBurialPreneed });
        }
      },
      isRequired: true,
      hidden: !applicationStore.application?.isMarried,
    } as AllFields,
  ];
};

export const burialPreneedFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "asset";
  const asset = form as Asset;

  const canEdit = !!applicationStore.applicationCanBeEdited;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "owner"),
      value: asset.owner,
      onSubmit: (owner: Option) => {
        onValueChange({ owner: owner.value });
      },
      isRequired: true,
      errorMessages: get(errors, "owner"),
      width: "48.5%",
      optionValues: getAssetOwnerOptions(),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "funeralHome"),
      value: asset.funeralHome,
      onSubmit: (funeralHome) => {
        onValueChange({ funeralHome });
      },
      isRequired: true,
      errorMessages: get(errors, "funeralHome"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isBurialPreneed"),
      value: asset.isBurialPreneed,
      onSubmit: (isBurialPreneed) => {
        onValueChange({ isBurialPreneed });
      },
      errorMessages: get(errors, "isBurialPreneed"),
      isRequired: true,
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isFunded"),
      value: asset.isFunded,
      onSubmit: (isFunded) => {
        onValueChange({ isFunded });
      },
      isRequired: true,
      errorMessages: get(errors, "isFunded"),
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fundingSource"),
      value: asset.fundingSource,
      onSubmit: (fundingSource) => {
        onValueChange({ fundingSource });
      },
      isRequired: true,
      errorMessages: get(errors, "fundingSource"),
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "value"),
      label: "Value",
      value: asset.value,
      onSubmit: (value) => {
        onValueChange({ value });
      },
      isRequired: true,
      errorMessages: get(errors, "value"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "asOfDate"),
      value: asset.asOfDate,
      onSubmit: (asOfDate) => {
        onValueChange({ asOfDate });
      },
      isRequired: true,
      errorMessages: get(errors, "asOfDate"),
      width: "22.5%",
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isIrrevocable"),
      value: asset.isIrrevocable,
      onSubmit: (isIrrevocable) => {
        onValueChange({ isIrrevocable });
      },
      isRequired: true,
      errorMessages: get(errors, "isIrrevocable"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isExempt"),
      value: asset.isExempt,
      onSubmit: (isExempt) => {
        onValueChange({ isExempt });
      },
      disabled: !canEdit || !userStore.user?.canEditAssetExempt,
      hidden:
        applicationStore.application?.status ==
        ApplicationStatuses.interviewing,
      isRequired:
        applicationStore.application?.status !=
        ApplicationStatuses.interviewing,
      errorMessages: get(errors, "isExempt"),
      width: "22.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isVerified"),
      value: asset.isVerified,
      onSubmit: (isVerified) => {
        onValueChange({ isVerified });
      },
      disabled: !canEdit || !userStore.user?.canEditAssetVerified,
      hidden:
        applicationStore.application?.status ==
        ApplicationStatuses.interviewing,
      isRequired:
        applicationStore.application?.status !=
        ApplicationStatuses.interviewing,
      errorMessages: get(errors, "isVerified"),
      width: "22.5%",
    } as AllFields,
  ];
};
