import GridClickableDataColumn from "../../../Grid/Grid/types/GridClickableDataColumn";
import GridColumn from "../../../Grid/Grid/types/GridColumn";

export const corporateFacilitiesGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "corporateCode",
    headerText: "Corp Code",
    type: "string",
  }),
  new GridColumn({
    field: "facilityName",
    headerText: "Name",
    type: "string",
    width: 225,
  }),
  new GridColumn({
    field: "facilityArea_AsString",
    headerText: "Region",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddressStreet",
    headerText: "Street",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddressCity",
    headerText: "City",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddressCounty",
    headerText: "County",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddressState_AsString",
    headerText: "State",
    type: "string",
  }),
  new GridColumn({
    field: "facilityAddressZip",
    headerText: "Zip",
    type: "string",
  }),
  new GridClickableDataColumn({
    field: "corporateFacilityUserCount",
    headerText: "Users",
    type: "number",
    dialogType: "facility-users-grid",
  }),
  new GridClickableDataColumn({
    field: "corporateFacilityUserIsPOCCount",
    headerText: "POCs",
    type: "number",
    dialogType: "facility-user-is-poc-grid",
  }),
  new GridClickableDataColumn({
    field: "corporateFacilityUserIsBillingPOCCount",
    headerText: "Billing POCs",
    type: "number",
    dialogType: "facility-user-is-billing-poc-grid",
  }),
  new GridClickableDataColumn({
    field: "corporateFacilityApplicationCount",
    headerText: "Applications",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "corporate-facility-application-count",
    },
    dialogType: "facility-applications-grid",
  }),
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "user-is-active-header",
    },
  }),
];
