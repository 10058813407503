import {
  ChatAssistantConversationMessageMetadata,
  ChatUserConversationMessageMetadata,
} from "../HelpCenter/types";

export interface ChatConversation {
  id: string;
  messages: ChatMessage[];
  usState?: number;
}

export interface ChatMessage {
  id?: string;
  pairId?: string;
  message: string;
  messageMetadata?: string;
  messageMetadataObj?:
    | ChatUserConversationMessageMetadata
    | ChatAssistantConversationMessageMetadata;
  role: ConversationRoleTypes;
  messageType: ChatConversationMessageType;
  timestamp?: Date;
}

export enum ConversationRoleTypes {
  Assistant = 2,
  User = 3,
}

export enum ConversationTopicTypes {
  MDRQuestion = 1,
  StateQuestion = 2,
}

export enum ChatConversationMessageType {
  AssistantAnswer = 1,
  AssistantNotKnownAnswer = 2,
  AssistantInnacurateAnswer = 3,
  AssistantNoDocumentation = 4,
  AssistantFallbackMessage = 5,
  AssistantStatePolicyMessage = 6,
  UserPrompt = 11,
}
