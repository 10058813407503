import {
  Button,
  Classes,
  Dialog,
  Intent,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import { mergeWith } from "lodash";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { ExistingCorporate } from "../../types/Corporate";
import { corporateApiClient } from "../../lib/apiClients/corporate/corporateApiClient";
import {
  corporateFormSchema,
  newFormCorporate,
} from "../Forms/CorporateForm/CorporateForm";
import { BillingRuleGrid } from "../Billing/BillingRuleGrid";
import { DiscountGrid } from "../Billing/DiscountGrid";
import { userStore } from "../../stores/UserStore";

export interface CorporateUpdateDialogProps extends BaseDialogProps {}

export const CorporateUpdateDialog: React.FC<CorporateUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [corporate, setCorporate] = useState<ExistingCorporate | null>(null);
  const [selectedTabId, setSelectedTabId] = useState<TabId>("default");

  useEffect(() => {
    if (!!dialogsViewerStore.corporateUpdateDialogOpenOptions) {
      corporateApiClient
        .getCorporateDetail(
          dialogsViewerStore.corporateUpdateDialogOpenOptions.corporateId
        )
        .then(setCorporate);
    }

    return setCorporate(null);
  }, [dialogsViewerStore.corporateUpdateDialogOpenOptions]);

  const corporateForm = () => (
    <Form
      formId="update-corporate-form"
      value={mergeWith({}, newFormCorporate, corporate, (dest, source) =>
        source === null ? dest : source
      )}
      formSchemaBuilder={corporateFormSchema}
      onFormSubmit={async (corporate) => {
        setIsSaving(true);

        await corporateApiClient
          .updateCorporate(corporate.id, corporate)
          .then(() => {
            gridStore.refresh();
            submitDialog();
          })
          .finally(() => {
            setIsSaving(false);
          });
      }}
    />
  );

  return (
    <Dialog
      portalClassName="mdr-dialog"
      className="bp4-dialog_corporate"
      isOpen={isOpen}
      hasBackdrop={true}
      isCloseButtonShown={true}
      title={"Update Corporate"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            corporate && (
              <Tabs
                selectedTabId={selectedTabId}
                onChange={setSelectedTabId}
                renderActiveTabPanelOnly={true}
              >
                <Tab id="default" title="Details" panel={corporateForm()} />
                <Tab
                  id="billing-rule"
                  title="Billing Rules"
                  panel={
                    <BillingRuleGrid
                      corporateId={corporate.id}
                      canExportGridToExcel={
                        !!userStore.user?.canExportBillingRuleToExcel
                      }
                      canCreateNewRecord={true}
                      canInvokeGridRecord={!!userStore.user?.canEditBillingRule}
                    />
                  }
                />
                <Tab
                  id="discount"
                  title="Discounts"
                  panel={
                    <DiscountGrid
                      corporateId={corporate.id}
                      canExportGridToExcel={
                        !!userStore.user?.canExportDiscountToExcel
                      }
                      canInvokeGridRecord={!!userStore.user?.canEditDiscount}
                      canCreateNewRecord={!!userStore.user?.canCreateDiscount}
                    />
                  }
                />
              </Tabs>
            )
          }
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        {selectedTabId === "default" ? (
          <FormSubmitButton
            buttonId="corporate-save-button"
            isSaving={isSaving}
            formId="update-corporate-form"
            text="Save"
          />
        ) : (
          <Button
            className="bp4-button bp4-intent-success"
            type="submit"
            intent={Intent.PRIMARY}
            text="Save"
            onClick={closeDialog}
          />
        )}
      </div>
    </Dialog>
  );
};
