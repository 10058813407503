import { Elevation } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { Asset } from "../../../../../../types/Application/Asset";
import { DeleteButton } from "../../../../../Buttons/DeleteButton";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../../../Forms";
import { showSuccessfulSaveAppToaster } from "../../../../../Toast/Toast";
import { AssetTypes } from "../../../../../../types/Application/Asset";
import { NewAsset } from "../../../../../../types/Application/Asset";
import { FormSchemaBuilder } from "../../../../../Forms/types";
import { SaveButton } from "../../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../../Buttons/UndoButton";
import { assetOwnedEntityStore } from "../../../../../../stores/OwnedEntityStores/AssetOwnedEntityStore";
import { FIARequest } from "../../../../../FIARequests/configurations/types";
import { fiaRequestApiClient } from "../../../../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { OwnedEntityFIARequestSubCard } from "../../../../../FIARequests/OwnedEntityFIARequestsSubCard";

export interface AssetPageCardProps {
  asset: Asset;
  assets: Asset[];
  assetType: AssetTypes;
  title: string;
  assetFormSchema: FormSchemaBuilder;
  createAsset: (asset: NewAsset) => Promise<void>;
  updateAsset: () => Promise<void>;
  deleteAsset: (asset: Asset) => Promise<void>;
  canCreateAsset: boolean;
  canEditAsset: boolean;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  index: number;
  isAddingAsset: boolean;
  applicationId?: string;
  setLocalAssets: (assets: Asset[]) => void;
}

const newAssetId = "new-asset";
const baseFormId = "asset-form";
export const AssetPageCard: FC<AssetPageCardProps> = (props) => {
  const [isInDeleteAction, setIsInDeleteAction] = useState<boolean>(false);
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );

  const isNewAsset = props.asset.id === newAssetId;

  const handleReset = async (assetToReset: Asset) => {
    const exisitingLocalAssets = [...props.assets];

    exisitingLocalAssets[props.index] = { ...props.asset, ...assetToReset };

    props.setLocalAssets(exisitingLocalAssets);
  };

  useEffect(() => {
    if (!!props.applicationId && !isNewAsset) {
      fiaRequestApiClient
        .getMostRecentRequestByOwnedEntityId(
          props.applicationId,
          props.asset.id
        )
        .then((res) => setFiaRequest(res));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [props.asset.id]);

  return (
    <RelativityCard
      key={`${props.title}-${props.asset.id}`}
      className="owned-entity-card asset-card"
      elevation={Elevation.TWO}
      headerContent={
        <div className="owned-entity-header asset-card-header">
          <SectionHeader
            label={`${props.title} ${props.index + 1}`}
            type="Section"
            inAction={props.formIsSaving}
          />
          <div>
            {props.canEditAsset && (
              <>
                <>
                  <SaveButton
                    formId={`${baseFormId}-${props.asset.id}`}
                    isSaving={props.formIsSaving}
                    disabled={isInDeleteAction || props.shouldDisableForm}
                    hidden={isInDeleteAction}
                  />
                  <UndoButton
                    isSaving={props.formIsSaving || isInDeleteAction}
                    disabled={props.shouldDisableForm}
                    onClick={async () => {
                      //Need to Await Changes in states in order to update correctly
                      await handleReset(props.asset);
                    }}
                  />
                  <DeleteButton
                    isDeleting={isInDeleteAction}
                    disabled={props.formIsSaving || props.shouldDisableForm}
                    hidden={props.formIsSaving}
                    onClick={async () => {
                      props.setShouldDisableForm(true);
                      setIsInDeleteAction(true);

                      if (isNewAsset) {
                        props.setLocalAssets(
                          props.assets.filter(
                            (asset) => asset.id !== newAssetId
                          )
                        );
                        assetOwnedEntityStore.removeLocalAsset(props.asset);
                      } else {
                        await props.deleteAsset(props.asset);
                      }

                      setIsInDeleteAction(false);
                      props.setShouldDisableForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </div>
      }
    >
      <Form<Asset>
        formId={`${baseFormId}-${props.asset.id}`}
        value={props.asset}
        formSchemaBuilder={props.assetFormSchema}
        shouldBlockNavigation={true}
        shouldResetForm={true}
        disableAllFields={
          !props.canEditAsset ||
          props.formIsSaving ||
          isInDeleteAction ||
          props.isAddingAsset ||
          props.shouldDisableForm
        }
        shouldSaveEntiityToLocalStore={true}
        ownedEntityStoreAction={(updatedAsset) => {
          if (JSON.stringify(updatedAsset) !== JSON.stringify(props.asset)) {
            assetOwnedEntityStore.addLocalAsset(updatedAsset);
          }
        }}
        onFormSubmit={async (asset) => {
          props.setShouldDisableForm(true);
          props.setFormIsSaving(true);

          if (isNewAsset) {
            await props
              .createAsset({ ...asset, type: props.assetType })
              .then(() => {
                showSuccessfulSaveAppToaster("Assets");
              });
          } else {
            await props.updateAsset().then(() => {
              showSuccessfulSaveAppToaster("Assets");
            });
          }
          props.setFormIsSaving(false);
          props.setShouldDisableForm(false);
        }}
      />
      {fiaRequest && <OwnedEntityFIARequestSubCard fiaRequest={fiaRequest} />}
    </RelativityCard>
  );
};
