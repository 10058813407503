import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react";
import { autorun } from "mobx";
import { Button, Intent } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../AppInsights";
import "../../../pages/styles.scss";
import { GridFiltersModule } from "../../Grid/Filters/Filters";
import { GridPermissions } from "../../Grid/types/GridPermissions";
import { GridViewPicker } from "../../Grid/Views/GridViewPicker";
import { GridFilterSettings } from "../../Grid/Grid/types/GridFilterSettings";
import { openFlyoutInPlace, useGridView } from "@ucl/library";
import baseApiClient from "../../../lib/apiClients/baseApiClient";
import { gridStore } from "../../Grid/Grid/stores/GridStore";
import { MatchupGridV2CannedViewPickerTypes } from "./types";
import { MatchupGridV2CannedViewPicker } from "./MatchupGridV2CannedViewPicker";
import { ActiveMatchupGridV2 } from "./CannedViews/ActiveMatchupGridV2";
import { ClosedMatchupGridV2 } from "./CannedViews/ClosedMatchupGridV2";
import {
  getGridColumnsConfigurationByRole,
  gridColumnsConfiguration,
} from "../MatchupGrid/configurations/gridColumnsConfiguration";
import { AllMatchupGridV2 } from "./CannedViews/AllMatchupGridV2";
import { MatchupImportFlyout } from "../MatchupImportFlyout";

export interface MatchupGridV2Props extends GridPermissions {
  canImportRecords: boolean;
  onRecordClick: (matchupId: string) => void;
}

const MatchupGridV2Component: React.FC<MatchupGridV2Props> = ({
  canConfigureBoard,
  canExportGridToExcel,
  canInvokeGridRecord,
  canCreateNewRecord,
  canImportRecords,
  onRecordClick,
}) => {
  const gridKey = "matchup-v2-grid";

  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Matchup Board",
    gridColumnsConfiguration,
    getGridColumnsConfigurationByRole,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  const [gridCannedView, setGridCannedView] =
    useState<MatchupGridV2CannedViewPickerTypes>("Active");
  const [isImportFlyoutOpen, setIsImportFlyoutOpen] = useState<boolean>(false);

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>View</li>
          <li>
            <MatchupGridV2CannedViewPicker
              setGridCannedView={setGridCannedView}
            />
          </li>
          <li>
            {/* Grid Views Module - Picker */}
            {canConfigureBoard && (
              <GridViewPicker
                gridView={gridView.currentGridView}
                baseGridColumnsConfiguration={
                  gridView.baseGridColumnsConfiguration
                }
                updateViewColumnSettings={gridView.updateViewColumnSettings}
              />
            )}
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            {/* Grid Filters Module */}
            <GridFiltersModule />
          </li>
          {canImportRecords && (
            <li>
              <Button
                intent={Intent.PRIMARY}
                text="Import Matchups"
                onClick={() => setIsImportFlyoutOpen(!isImportFlyoutOpen)}
              />
            </li>
          )}
          <li>
            {canCreateNewRecord && (
              <Button intent={Intent.PRIMARY} text="Create New Lead" />
            )}
          </li>
        </ul>
      </section>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && gridCannedView === "Active" && (
                <ActiveMatchupGridV2
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                  onRecordClick={onRecordClick}
                />
              )}
              {!!gridView.currentGridView && gridCannedView === "Closed" && (
                <ClosedMatchupGridV2
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                  onRecordClick={onRecordClick}
                />
              )}
              {!!gridView.currentGridView && gridCannedView === "All" && (
                <AllMatchupGridV2
                  canConfigureBoard={canConfigureBoard}
                  canExportGridToExcel={canExportGridToExcel}
                  canCreateNewRecord={canCreateNewRecord}
                  canInvokeGridRecord={canInvokeGridRecord}
                  onRecordClick={onRecordClick}
                />
              )}
            </>
          )}
        </Observer>
      </section>
      {isImportFlyoutOpen &&
        openFlyoutInPlace(
          <MatchupImportFlyout
            flyoutIsOpen={true}
            onClose={() => {
              setIsImportFlyoutOpen(false);
            }}
          />,
          isImportFlyoutOpen,
          () => {
            setIsImportFlyoutOpen(false);
          },
          { canOutsideClickClose: true }
        )}
    </div>
  );
};

export const MatchupGridV2 = withAITracking(
  reactPlugin,
  MatchupGridV2Component,
  "MatchupGridV2",
  "AI-flex-container"
);
