import { useEffect, useRef, useState } from "react";
import SchedulerODataV4Adaptor from "./SchedulerODataV4Adaptor";
import { DataManager, Query, Predicate } from "@syncfusion/ej2-data";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import { SchedulerSetupSettings } from "./types/Scheduler";
import {
  CellClickEventArgs,
  ScheduleComponent,
  ScheduleModel,
  View,
} from "@syncfusion/ej2-react-schedule";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { openCreateAppointmentDialog } from "../Dialogs/AppointmentDialog";
import schedulingApiClient from "../../lib/apiClients/scheduling/schedulingApiClient";
import { AppToaster } from "../Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { SchedulerResourceConfig } from "./types/Resources";
import { AppointmentOutlookEvents } from "../../types/Scheduling";
import { UserRoles } from "../../types/User";
import { ScheduleViews } from "./types/SchedulerViews";

export const defaultScheduleSettings: ScheduleModel & DefaultHtmlAttributes = {
  height: "100%",
  width: "100%",
  workHours: { highlight: true, start: "09:00", end: "18:00" },
  eventSettings: {},
  allowResizing: true,
  timezone: "America/New_York",
};

export const useSchedule = (props: SchedulerSetupSettings) => {
  const scheduleRef = useRef<ScheduleComponent>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [scheduleSettings, setScheduleSettings] = useState<
    ScheduleModel & DefaultHtmlAttributes
  >(defaultScheduleSettings);
  const [outlookEvents, setOutlookEvents] = useState<
    AppointmentOutlookEvents[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [currentView, setCurrentView] = useState<View | undefined>(undefined);

  const fetchAccessToken = async () => {
    const token = await getAccessToken();
    setAccessToken(token);
  };

  const blankQuery = new Query()
    .select("id")
    .where("id", "equal", "00000000-0000-0000-0000-000000000000");

  const generateQuery = () => {
    // Early exit for no data sources
    if (
      props.resourceConfig.length === 0 ||
      props.resourceConfig.every((resource) => resource.dataSource.length === 0)
    ) {
      return blankQuery;
    }

    if (!scheduleRef.current) {
      return blankQuery; // Early exit if scheduleRef is not set
    }

    let query = new Query();
    const fieldsToSelect = props.resourceConfig.map(
      (resource) => resource.field
    );
    query = query.select(fieldsToSelect);

    const allPredicates = collectPredicates(props.resourceConfig);
    // Apply 'OR' across all collected predicates from all resources
    if (allPredicates.length > 0) {
      query = query.where(Predicate.or(...allPredicates));
      return query;
    }
    return blankQuery; // Return blank query if no valid predicates are found
  };

  const collectPredicates = (resourceConfig: SchedulerResourceConfig[]) => {
    const allPredicates: Predicate[] = [];

    resourceConfig.forEach((resource) => {
      if (resource.dataSource.length > 0) {
        const resourceToQuery = resource.dataSource.filter(
          (dataSource) => dataSource.id !== "outlook"
        );

        const predicates = resourceToQuery.map(
          (dataSource) => new Predicate(resource.field, "equal", dataSource.id)
        );

        // Combine predicates with 'OR' for this particular resource
        if (predicates.length > 0) {
          allPredicates.push(Predicate.or(...predicates));
        }
      }
    });

    return allPredicates;
  };

  const setDataManager = (dataManager: DataManager) => {
    setScheduleSettings({
      ...scheduleSettings,
      eventSettings: {
        ...scheduleSettings.eventSettings,
        dataSource: dataManager,
      },
    });
  };

  const setupScheduleSettings = (dataManager: DataManager) => {
    const scheduleSettings = defaultScheduleSettings;

    //scheduleSettings.actionBegin = handleActionBegin;
    scheduleSettings.actionComplete = handleActionComplete;
    scheduleSettings.cellClick = handleCellClick;

    scheduleSettings.eventSettings = {
      ...scheduleSettings.eventSettings,
      dataSource: dataManager,
      fields: props.fieldModel,
    };

    scheduleSettings.resources = props.resourceConfig;
    scheduleSettings.workHours = {
      highlight: true,
      start: props.startHour ?? undefined,
      end: props.endHour ?? undefined,
    };
    scheduleSettings.startHour = props.workHours?.start ?? undefined;
    scheduleSettings.endHour = props.workHours?.end ?? undefined;
    const viewOptions = props.viewOptions.map((option) => ({
      option: option.view,
      readonly: props.readOnly,
    }));
    scheduleSettings.views = viewOptions;

    setScheduleSettings(scheduleSettings);
  };

  const setupODataSource = (
    odataUrl: string,
    accessToken: string,
    includeOutlookEvents: boolean,
    outlookEvents: AppointmentOutlookEvents[],
    isFacilitySchedule: boolean
  ) => {
    const dataManager = new DataManager(
      {
        url: odataUrl,
        adaptor: new SchedulerODataV4Adaptor(
          undefined,
          includeOutlookEvents,
          outlookEvents,
          isFacilitySchedule
        ),
        crossDomain: true,
        headers: [{ Authorization: `Bearer ${accessToken}` }],
      },
      blankQuery
    );

    return dataManager;
  };

  useEffect(() => {
    if (!initialized) {
      fetchAccessToken();
      setInitialized(true);
    }
  }, [scheduleRef]);

  const includeOutlookEvents = props.resourceConfig.some((x) =>
    x.dataSource.some((x) => x.id === "outlook")
  );

  const isFacilityScheduleView =
    props.scheduleViewType === ScheduleViews.FacilitySchedule;

  const isAppointmentsCalendarView =
    props.scheduleViewType === ScheduleViews.AppointmentsCalendar;

  useEffect(() => {
    if (!!accessToken && !ready) {
      const dataManager = setupODataSource(
        props.odataUrl,
        accessToken,
        includeOutlookEvents,
        outlookEvents,
        isFacilityScheduleView
      );
      setupScheduleSettings(dataManager);

      setReady(true);
    }
  }, [accessToken]);

  useEffect(() => {
    if (!!accessToken && !!ready) {
      const dataManager = setupODataSource(
        props.odataUrl,
        accessToken,
        includeOutlookEvents,
        outlookEvents,
        true
      );
      setDataManager(dataManager);
    }
  }, [includeOutlookEvents]);

  useEffect(() => {
    if (
      isAppointmentsCalendarView &&
      props.user.role === UserRoles.FieldRepresentative
    ) {
      getOutlookAppointments();
    }

    return () => {
      setOutlookEvents([]);
    };
  }, []);

  const getOutlookAppointments = () => {
    try {
      schedulingApiClient
        .getOutlookAppointments(props.user.id)
        .then((response) => {
          const outlookEvents = response.map((event) => {
            return {
              ...event,
              startTime: new Date(event.startTime),
              endTime: new Date(event.endTime),
              isReadOnly: true,
            };
          });
          setOutlookEvents(outlookEvents);
        });
    } catch (error: any) {
      AppToaster.show({
        message: "Unexpected error occurred while retrieving outlook calendar",
        intent: Intent.DANGER,
      });
    }
  };

  // Handle Actions

  // Dev Note: Comment back in if needed or for troubleshooting
  // const handleActionBegin = (eventArgs: any) => {
  //   console.log(eventArgs);
  // };

  const handleActionComplete = (eventArgs: any) => {
    if (eventArgs.requestType === "dateNavigate") {
      setSelectedDate(scheduleRef.current?.selectedDate);
      setCurrentView(scheduleRef.current?.currentView);
    }
    if (eventArgs.requestType === "viewNavigate") {
      setSelectedDate(scheduleRef.current?.selectedDate);
      setCurrentView(scheduleRef.current?.currentView);
    }
  };

  const handleCellClick = (args: CellClickEventArgs) => {
    args.cancel = true;
    openCreateAppointmentDialog(
      undefined, // FacilityId
      args.startTime.toDateString(),
      args.startTime.getTime(),
      args.endTime.getTime()
    );
  };

  return {
    scheduleRef,
    ready,
    scheduleSettings,
    generateQuery,
    selectedDate,
    currentView,
  };
};
