import { Button, Intent } from "@blueprintjs/core";
import RelativityCard from "../../../Card/RelativityCard";
import { useState } from "react";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import {
  currency,
  label,
  NameValuePair,
  tooltip,
} from "../../FinancialSummary";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { ApplicationBillAmount } from "../../../../types/Application";

export interface FinancialSummaryCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
}

export const FinancialSummaryCard: React.FC<FinancialSummaryCardProps> = ({
  applicationSummary,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);

  const incomeItems: NameValuePair[] = [
    {
      name: "Social Security",
      value: applicationSummary.combinedSocialSecurityAmount || 0,
    },
    { name: "SSI", value: applicationSummary.combinedSSIAmount || 0 },
    {
      name: "VA Benefits",
      value: applicationSummary.combinedVABenefitsAmount || 0,
    },
    {
      name: "Additional Income",
      value: applicationSummary.combinedAdditionalIncomeAmount || 0,
    },
  ];

  const assetItems: NameValuePair[] = [
    {
      name: "Property",
      value: applicationSummary.combinedAssetPropertyValue || 0,
    },
    {
      name: "Vehicles",
      value: applicationSummary.combinedAssetVehicleValue || 0,
    },
    {
      name: "Bank Accounts",
      value: applicationSummary.combinedAssetBankAccountValue || 0,
    },
    {
      name: "Life Insurance",
      value: applicationSummary.combinedAssetLifeInsuranceValue || 0,
    },
    {
      name: "Burial/Preneed Funeral",
      value: applicationSummary.combinedAssetBurialPreneedValue || 0,
    },
    {
      name: "Other Assets",
      value: applicationSummary.combinedAssetOtherValue || 0,
    },
  ];

  const billItems: NameValuePair[] =
    applicationSummary.billAmounts?.map((bill: ApplicationBillAmount) => {
      return {
        name: bill.type_AsString,
        value: bill.amount || 0,
      };
    }) || [];

  const estPatientLiabilityItems: NameValuePair[] = [
    {
      name: "Total Monthly Income",
      value: applicationSummary.combinedIncomeItemsTotal || 0,
    },
    {
      name: "Medical Coverage Premiums",
      value: -(applicationSummary.medicalCoveragePremiums || 0),
    },
    {
      name: applicationSummary.isFacilityStateFL
        ? [
            "State's Personal Care Allowance",
            "*Effective July 1st 2023, PNA increased from $130 to $160",
          ]
        : "State's Personal Care Allowance",
      value: -(applicationSummary.statesPersonCareAllowance ?? 0),
    },
  ];

  return (
    <div className="summary-dialog-financial-summary">
      <RelativityCard
        className="summary-dialog-financial-summary-card"
        headerContent={
          <div className="summary-dialog-financial-summary-header">
            <Button
              active={false}
              minimal={true}
              intent={Intent.PRIMARY}
              onClick={() => setIsViewing((prev) => !prev)}
            >
              Financial Summary
            </Button>
          </div>
        }
        hideBody={!isViewing}
      >
        <RelativitySubCard>
          <div className="summary-dialog-financial-points">
            <div className="financial-summary-data-point">
              {label(
                "Income",
                tooltip(
                  incomeItems,
                  applicationSummary.combinedIncomeItemsTotal
                )
              )}
              <div>
                {currency(applicationSummary.combinedIncomeItemsTotal || 0)}
              </div>
            </div>
            <div className="financial-summary-data-point">
              {label(
                "Assets",
                tooltip(assetItems, applicationSummary.combinedAssetItemsTotal)
              )}
              <div>
                {currency(applicationSummary.combinedAssetItemsTotal || 0)}
              </div>
            </div>
            <div className="financial-summary-data-point">
              {label(
                "Bills",
                tooltip(billItems, applicationSummary.billItemsTotal)
              )}
              <div>{currency(applicationSummary.billItemsTotal || 0)}</div>
            </div>
            <div className="financial-summary-data-point">
              {label(
                "Est. Patient Liability",
                tooltip(
                  estPatientLiabilityItems,
                  applicationSummary.estPatientLiabilityItemsTotal,
                  "Est. Patient Liability"
                )
              )}
              <div>
                {currency(
                  applicationSummary.estPatientLiabilityItemsTotal || 0
                )}
              </div>
            </div>
          </div>
        </RelativitySubCard>
      </RelativityCard>
    </div>
  );
};
