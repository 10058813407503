const useFilenameFormat = () => {
  const formatFilename = (value: string) => {
    if (!value) {
      return value;
    }

    // List of invalid characters
    const invalidChars = /[^A-Za-z0-9 ().-]/g;

    // Replace invalid characters with an empty string
    const sanitized = value.replace(invalidChars, "");

    return sanitized;
  };

  return { formatFilename };
};

export default useFilenameFormat;
