import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { applicationStore } from "../../stores/ApplicationStore";
import { checklistItemStore } from "../../stores/ChecklistItemStore";
import { NewChecklistItem } from "../../types/Checklist";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { checklistItemFormSchema } from "../Forms/ChecklistForm/ChecklistForm";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";
import { checklistSplitItemCreationFormSchema } from "../Forms/ChecklistForm/ChecklistSplitItemCreationFormSchema";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";

export interface ChecklistItemCreationDialogProps extends BaseDialogProps {
  onSuccess?: () => void;
}

export const ChecklistItemCreationDialog: React.FC<
  ChecklistItemCreationDialogProps
> = ({ isOpen, closeDialog, onSuccess }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localChecklistItem] = useState<Partial<NewChecklistItem>>();

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"New Checklist Item"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Form
          formId="checklist-item-form"
          value={localChecklistItem}
          formSchemaBuilder={
            dialogsViewerStore.checklistItemCreationDialogOpenOptions
              ?.openOnSplit
              ? checklistSplitItemCreationFormSchema
              : checklistItemFormSchema
          }
          onFormSubmit={async (checklistItem: Partial<NewChecklistItem>) => {
            setIsSaving(true);
            try {
              await checklistItemStore.createChecklistItem({
                ...checklistItem,
                applicationId: applicationStore.application?.id,
              });

              if (!!onSuccess) {
                onSuccess();
              }
              closeDialog();
            } finally {
              setIsSaving(false);
            }
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="checklist-item-button"
          isSaving={isSaving}
          formId="checklist-item-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
