import { FilenameFieldProps } from "../types/fieldTypes";
import { TextField } from "../TextField/TextField";
import useFilenameFormat from "./useFilenameFormat";

export const FilenameField: React.FC<FilenameFieldProps> = ({
  label,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  placeholder,
  maxLength,
}) => {
  const { formatFilename } = useFilenameFormat();

  return (
    <TextField
      type="Text"
      label={label}
      value={formatFilename(value)}
      disabled={disabled}
      onSubmit={onSubmit}
      hidden={hidden}
      errorMessages={errorMessages}
      isRequired={isRequired}
      placeholder={placeholder}
      isSensitive={false}
      maxLength={maxLength}
      inputMode="text"
      sanitize={formatFilename}
    />
  );
};
