import {
  DateFieldProps,
  EnumCheckboxFieldProps,
  EnumRadioFieldProps,
  EnumSingleSelectFieldV2Props,
  SchemaFactoryV2,
  SingleCheckboxFieldProps,
} from "@ucl/library";
import { Dispatch, SetStateAction } from "react";
import { BlockScheduleFormModel } from "./BlockScheduleFormModel";
import { RecurringFrequencyTypeOptions } from "./types/RecurringFrequencyTypeOptions";

const modelName = "FacilityBlockSchedule";
const getFieldKey = (key: string) => `${modelName}__${key}`;

export const getBlockScheduleFormSchema = (
  factory: SchemaFactoryV2,
  form: BlockScheduleFormModel,
  setForm: Dispatch<SetStateAction<BlockScheduleFormModel>>
) => {
  return {
    AvailableTimeSlot: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("AvailableTimeSlot")
      ),
      value: form.availableTimeSlot,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, availableTimeSlot: value };
        }),
    } as EnumRadioFieldProps,
    RepeatSchedule: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("RepeatSchedule")),
      value: form.repeatSchedule,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, repeatSchedule: value };
        }),
    } as SingleCheckboxFieldProps,
    RecurringFrequencyType: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("RecurringFrequencyType")
      ),
      value: form.recurringFrequencyType,
      hidden: !form.repeatSchedule,
      isRequired: true,
      showClearButton: false,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, recurringFrequencyType: value };
        }),
    } as EnumSingleSelectFieldV2Props,
    StartDate: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("StartDate")),
      label: form.repeatSchedule ? "Starting on" : "Date",
      value: form.startDate,
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
      isRequired: true,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, startDate: value };
        }),
    } as DateFieldProps,
    EndDate: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("EndDate")),
      value: form.endDate,
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
      hidden: !form.repeatSchedule,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, endDate: value };
        }),
    } as DateFieldProps,
    RecurringOrdinalDays_AsList: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("RecurringOrdinalDays_AsList")
      ),
      value: form.recurringOrdinalDays_AsList,
      hidden:
        !form.repeatSchedule ||
        form.recurringFrequencyType !== RecurringFrequencyTypeOptions.Monthly,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, recurringOrdinalDays_AsList: value };
        }),
    } as EnumCheckboxFieldProps,
    RecurringDays_AsList: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("RecurringDays_AsList")
      ),
      label:
        form.recurringFrequencyType === RecurringFrequencyTypeOptions.Monthly
          ? undefined
          : "On",
      value: form.recurringDays_AsList,
      hidden:
        !form.repeatSchedule ||
        (form.recurringFrequencyType !== RecurringFrequencyTypeOptions.Weekly &&
          form.recurringFrequencyType !==
            RecurringFrequencyTypeOptions.Biweekly &&
          form.recurringFrequencyType !==
            RecurringFrequencyTypeOptions.Monthly),
      isRequired: true,
      onSubmit: (value) =>
        setForm((prev) => {
          return { ...prev, recurringDays_AsList: value };
        }),
    } as EnumCheckboxFieldProps,
  };
};
