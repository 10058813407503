import {
  FIARequestFilingProcessDocumentResult,
  StateNoticeFilingProcessDocumentResult,
} from "../../../components/DocumentFiling/types";
import baseApiClient from "../baseApiClient";

class DocumentFilingAIClient {
  fiaFilingProcessDocument = async (
    documentId: string
  ): Promise<FIARequestFilingProcessDocumentResult> => {
    return baseApiClient.get<FIARequestFilingProcessDocumentResult>(
      `filing-ai/fia/document/${documentId}`
    );
  };

  stateNoticeFilingProcessDocument = async (
    documentId: string
  ): Promise<StateNoticeFilingProcessDocumentResult> => {
    return baseApiClient.get<StateNoticeFilingProcessDocumentResult>(
      `filing-ai/state-notice/document/${documentId}`
    );
  };
}

export const documentFilingAIClient = new DocumentFilingAIClient();
