import { AllFields } from "../../Fields/types/fieldTypes";
import { ApplicationSummaryResponseDTO } from "../../../types/Application/ApplicationSummary";
import { differenceInMonths } from "../../../utils/date";
import { userStore } from "../../../stores/UserStore";

export const applicationSummaryFileInfoFormSchema = {
  residentFullName: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Resident Name",
      value: summary.applicantFullName,
      minimal: true,
    } as AllFields;
  },
  facilityName: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Facility Name",
      value: summary.facilityName,
      minimal: true,
    } as AllFields;
  },
  facilityAddress: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Facility Address",
      value: summary.facilityFullAddress_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  facilityPhone: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Facility Phone",
      value: summary.facilityPhone_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  facilityFax: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Facility Fax",
      value: summary.facilityFax_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  fileNumber: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "File Number",
      value: summary.fileNumber,
      minimal: true,
    } as AllFields;
  },
  productType: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Product Type",
      value: summary.productType_AsString,
      minimal: true,
    } as AllFields;
  },
  applicationStatus: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Status",
      value: summary.status_AsString,
      minimal: true,
    } as AllFields;
  },
  applicationInternalStatus: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Internal Status",
      value: summary.internalStatus_AsString,
      minimal: true,
    } as AllFields;
  },
  reasonCode: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Reason Code",
      value: summary.reasonCode_AsString,
      minimal: true,
    } as AllFields;
  },
  fieldRepName: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: userStore.user?.isExternal ? "Territory Manager" : "Field Rep",
      value: summary.fieldRepFullName,
      minimal: true,
    } as AllFields;
  },
  processorName: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Processor",
      value: summary.processorFullName,
      minimal: true,
    } as AllFields;
  },
  currentAssessment: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Current Assessment",
      value: summary.currentAssessment_AsString,
      minimal: true,
    } as AllFields;
  },
  redFlags: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Red Flags",
      value: summary.redFlags_AsString,
      minimal: true,
    } as unknown as AllFields;
  },
};

export const applicationSummaryDatesFormSchema = {
  applicationDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Application Date",
      value: summary.applicationDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  admissionDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Admission Date",
      value: summary.admissionDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  dueDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Due Date",
      value: summary.dueDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  lastAppliedDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Last Applied Date",
      value: summary.lastAppliedDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },

  repHandoffDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Rep Handoff Date",
      value: summary.repHandoffDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  medicaidNeededDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Medicaid Needed Date",
      description: `${differenceInMonths(
        summary.medicaidNeededDate_FormattedAsString || ""
      )} Months Remaining`,
      value: summary.medicaidNeededDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  firstAppliedDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "First Applied Date",
      value: summary.stateAppDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  deniedDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Denied Date",
      value: summary.deniedDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  createdDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Created Date",
      value: summary.createdDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  approvedDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Approved Date",
      value: summary.approvedDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  medicaidEligibleDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Medicaid Eligible Date",
      value: summary.medicaidEligibleDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
  nextRecertDate: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      label: "Next Recert Date",
      value: summary.nextRecertDate_FormattedAsString,
      minimal: true,
    } as AllFields;
  },
};

export const applicationSummaryOutstandingCount = {
  outstandingDocument: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      value: summary.outstandingDocumentsCount,
      minimal: true,
    } as unknown as AllFields;
  },
  outstandingFIARequests: (summary: ApplicationSummaryResponseDTO) => {
    return {
      type: "ReadOnly",
      value: summary.outstandingFIARequestsCount,
      minimal: true,
    } as unknown as AllFields;
  },
};
