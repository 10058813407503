import { Elevation } from "@blueprintjs/core";
import { FC, useState, useEffect } from "react";
import { assetOwnedEntityStore } from "../../../../stores/OwnedEntityStores/AssetOwnedEntityStore";
import {
  Asset,
  AssetTypes,
  NewAsset,
} from "../../../../types/Application/Asset";
import { DeleteButton } from "../../../Buttons/DeleteButton";
import { FormSubmitButton } from "../../../Buttons/FormSubmitButton";
import RelativityCard from "../../../Card/RelativityCard";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../Forms";
import { FormSchemaBuilder } from "../../../Forms/types";
import { showSuccessfulSaveAppToaster } from "../../../Toast/Toast";
import "../../styles.scss";
import { fiaRequestApiClient } from "../../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { FIARequest } from "../../../FIARequests/configurations/types";
import { OwnedEntityFIARequestSubCard } from "../../../FIARequests/OwnedEntityFIARequestsSubCard";

export interface AssetFormCardProps {
  asset: Asset;
  assetType: AssetTypes;
  applicationId?: string;
  title: string;
  index: number;
  assetFormSchema: FormSchemaBuilder;
  createAsset: (asset: NewAsset) => Promise<void>;
  updateAsset?: () => Promise<void>;
  deleteAsset?: (asset: Asset) => Promise<void>;
  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  showCancelButton?: boolean;
  onCancel?: () => void;
  shouldSaveEntityToLocalStore: boolean;
  setLocalAssets: (assets: Asset[]) => void;
  localAssets: Asset[];
}

export const newAssetId = "new-asset";
export const AssetFormCard: FC<AssetFormCardProps> = ({
  asset,
  applicationId,
  title,
  index,
  formIsSaving,
  shouldDisableForm,
  setShouldDisableForm,
  deleteAsset,
  setLocalAssets,
  localAssets,
  assetFormSchema,
  shouldSaveEntityToLocalStore,
  setFormIsSaving,
  createAsset,
  assetType,
  updateAsset,
}) => {
  const [isInDeleteActionAsset, setIsInDeleteActionAsset] =
    useState<boolean>(false);
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );
  const isNewAsset = asset.id === newAssetId;

  useEffect(() => {
    if (!!applicationId && !isNewAsset) {
      fiaRequestApiClient
        .getMostRecentRequestByOwnedEntityId(applicationId, asset.id)
        .then((res) => setFiaRequest(res));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [asset.id]);

  return (
    <RelativityCard
      key={`${title}-${asset.id}`}
      className="owned-entity-card asset-card"
      elevation={Elevation.TWO}
      headerContent={
        <div className="owned-entity-header asset-card-header">
          <SectionHeader
            label={`${title} ${index + 1}`}
            type="Section"
            inAction={formIsSaving}
          />
          <div>
            {deleteAsset && (
              <DeleteButton
                isDeleting={isInDeleteActionAsset}
                disabled={shouldDisableForm || formIsSaving}
                onClick={async () => {
                  setShouldDisableForm(true);
                  setIsInDeleteActionAsset(true);

                  if (isNewAsset) {
                    setLocalAssets(
                      localAssets.filter((asset) => asset.id !== newAssetId)
                    );
                  } else {
                    deleteAsset && (await deleteAsset(asset));
                  }

                  setIsInDeleteActionAsset(false);
                  setShouldDisableForm(false);
                }}
              />
            )}
            <FormSubmitButton
              buttonId={`asset-form-create-button-${asset.id}`}
              isSaving={formIsSaving}
              disabled={shouldDisableForm}
              formId={`asset-form-${asset.id}`}
              text={isNewAsset ? "Create" : "Update"}
            />
          </div>
        </div>
      }
    >
      <Form<Asset>
        formId={`asset-form-${asset.id}`}
        value={asset}
        formSchemaBuilder={assetFormSchema}
        shouldBlockNavigation={true}
        disableAllFields={shouldDisableForm}
        shouldSaveEntiityToLocalStore={shouldSaveEntityToLocalStore}
        ownedEntityStoreAction={(updatedAsset) => {
          if (JSON.stringify(updatedAsset) !== JSON.stringify(asset)) {
            assetOwnedEntityStore.addLocalAsset(updatedAsset);
          }
        }}
        onFormSubmit={async (asset) => {
          setShouldDisableForm(true);
          setFormIsSaving(true);

          if (isNewAsset) {
            await createAsset({ ...asset, type: assetType }).then(() => {
              showSuccessfulSaveAppToaster("Assets");
            });
          } else {
            updateAsset &&
              (await updateAsset().then(() => {
                showSuccessfulSaveAppToaster("Assets");
              }));
          }
          setFormIsSaving(false);
          setShouldDisableForm(false);
        }}
      />
      {fiaRequest && <OwnedEntityFIARequestSubCard fiaRequest={fiaRequest} />}
    </RelativityCard>
  );
};
