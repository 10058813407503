import { flyoutRoutes, WithFlyoutNavigation, WithParams } from "@ucl/library";
import { utils } from "@ucl/library";
import { HomePage } from "../../pages/Home";
import { createBrowserRouter } from "react-router-dom";
import { PagesLayout } from "../../pages/PagesLayout";
import { AdminPage } from "../../pages/Admin";
import { ReportsPage } from "../../pages/ReportsPage";
import { TemplatesPage } from "../../pages/Templates/Templates";
import { TemplateDetails } from "../../pages/Templates/TemplateDetails";
import { NewApplicationForm } from "../../pages/Application/NewApplicationForm";
import { ApplicationForm } from "../../pages/Application/ApplicationForm";
import { ApplicationPage } from "../../pages/Application/ApplicationPage";
import { ApplicationFormPage, ApplicationPages } from "../../types/Application";
import { FIARequestFlyout } from "../FIARequests/FIARequestFlyout";
import { MatchupPage } from "../../pages/MatchupPage";
import { PagesLayoutChildren } from "../../pages/PagesLayoutChildren";
import ErrorWrapper from "../../pages/ErrorWrapper";
import { HelpCenterPage } from "../../pages/helpcenter/HelpCenterPage";
import { topicSectionTypeConfig, TopicSectionTypes } from "../HelpCenter/types";
import { ReportViewer } from "../Reports/ReportViewer";
import { FIAPage } from "../../pages/FIAPage";
import { SchedulerPage } from "../../pages/SchedulerPage";
import { MatchupFlyout } from "../Matchup/MatchupFlyout";

const applicationRoute = (
  label: string,
  selectedPageTab: ApplicationFormPage | ApplicationPages
): utils.routes.UclRouteObject => {
  return {
    path: selectedPageTab,
    label: label,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    element: (
      <WithParams<{ id: string }>>
        {({ id }) => (
          <ApplicationPage id={id || ""} selectedPageTab={selectedPageTab} />
        )}
      </WithParams>
    ),
  };
};

const errorWrapper = (
  <div className="page">
    <ErrorWrapper />
  </div>
);

export const uclRouteObject = [
  {
    path: "/",
    label: "Home",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" />,
    children: [
      {
        path: "",
        label: "Applications",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        element: <HomePage />,
      },
    ],
  },
  {
    path: "/scheduler",
    label: "Scheduler",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/scheduler/appointments-calendar" />,
    children: [
      {
        path: ":tabId",
        label: "Scheduler",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <SchedulerPage />,
      },
    ],
  },
  {
    path: "/matchup",
    label: "Matchup",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    element: <PagesLayout redirectPath="/" />,
    children: [
      {
        path: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <MatchupPage />,
        children: [
          flyoutRoutes({
            redirectPath: () => `/matchup`,
            routes: [
              {
                path: ":id",
                isAvailable: true,
                showInNavigation: false,
                showInSubNavigation: false,
                element: (
                  <WithParams<{ id: string }>>
                    {({ id }) => (
                      <WithFlyoutNavigation
                        redirectPath={`/matchup`}
                        userSettings={{ canOutsideClickClose: true }}
                      >
                        {(closeDialog) => (
                          <MatchupFlyout
                            matchupId={String(id)}
                            onClose={closeDialog}
                            flyoutIsOpen={true}
                          />
                        )}
                      </WithFlyoutNavigation>
                    )}
                  </WithParams>
                ),
              },
            ],
          }),
        ],
      },
    ],
  },
  {
    path: "/fias",
    label: "FIA Requests",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" />,
    children: [
      {
        path: "",
        label: "FIA Requests",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <FIAPage />,
      },
    ],
  },
  {
    path: "/admin",
    label: "Admin",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/admin/users" />,
    children: [
      {
        path: ":tabId",
        label: "Admin",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <AdminPage />,
      },
    ],
  },
  {
    path: "/reports",
    label: "Reports",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" />,
    children: [
      {
        path: "",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <ReportsPage />,
      },
      {
        path: ":reportId",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{ reportId: string }>>
            {({ reportId }) => <ReportViewer id={reportId || ""} />}
          </WithParams>
        ),
      },
    ],
  },
  {
    path: "/templates",
    label: "Templates",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" />,
    children: [
      {
        path: "",
        label: "Templates",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <TemplatesPage />,
      },
      {
        path: ":templateId",
        label: "Templates",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{ templateId: string }>>
            {({ templateId }) => (
              <TemplateDetails templateId={templateId || ""} />
            )}
          </WithParams>
        ),
      },
    ],
  },
  {
    path: "/application-form",
    label: "Application Form",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" />,
    children: [
      {
        path: "",
        label: "Application Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <NewApplicationForm />,
      },
      {
        path: ":id/:page",
        label: "Application Form",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: <ApplicationForm />,
      },
    ],
  },
  {
    path: "/application-page",
    label: "Application",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath={`/`} />,
    children: [
      {
        path: ":id",
        label: "Application",
        icon: undefined,
        isAvailable: true,
        showInNavigation: true,
        showInSubNavigation: true,
        element: (
          <WithParams<{ id: string }>>
            {({ id }) => (
              <PagesLayoutChildren
                redirectPath={`/application-page/${id}/comments`}
              />
            )}
          </WithParams>
        ),
        children: [
          applicationRoute("Contacts", "contact"),
          applicationRoute("Comments", "comments"),
          applicationRoute("Medical Information", "medical-information"),
          applicationRoute("Medical Coverage", "medical-coverage"),
          applicationRoute("Spouse Information", "spouse-information"),
          applicationRoute("Income Information", "income-information"),
          applicationRoute("Asset", "asset"),
          applicationRoute("Bill Information", "bill-information"),
          applicationRoute("Legal Information", "legal-information"),
          applicationRoute("Signatures", "signatures"),
          applicationRoute("Checklists", "checklist"),
          applicationRoute("Audit Log", "audit-log"),
          {
            ...applicationRoute("FIA Requests", "fia-requests"),
            ...{
              children: [
                flyoutRoutes({
                  redirectPath: ({ id }) =>
                    `/application-page/${id}/fia-requests`,
                  routes: [
                    {
                      path: ":fiaRequestId",
                      isAvailable: true,
                      showInNavigation: false,
                      showInSubNavigation: false,
                      element: (
                        <WithParams<{ id: string; fiaRequestId: string }>>
                          {({ id, fiaRequestId }) => (
                            <WithFlyoutNavigation
                              redirectPath={`/application-page/${id}/fia-requests`}
                              userSettings={{ canOutsideClickClose: true }}
                            >
                              {(closeDialog) => (
                                <FIARequestFlyout
                                  applicationId={String(id)}
                                  flyoutIsOpen={true}
                                  fiaRequestId={fiaRequestId}
                                  onClose={closeDialog}
                                />
                              )}
                            </WithFlyoutNavigation>
                          )}
                        </WithParams>
                      ),
                    },
                    {
                      path: "new",
                      isAvailable: true,
                      showInNavigation: false,
                      showInSubNavigation: false,
                      element: (
                        <WithParams<{ id: string }>>
                          {({ id }) => (
                            <WithFlyoutNavigation
                              redirectPath={`/application-page/${id}/fia-requests`}
                              userSettings={{ canOutsideClickClose: true }}
                            >
                              {(closeDialog) => (
                                <FIARequestFlyout
                                  applicationId={String(id)}
                                  flyoutIsOpen={true}
                                  onClose={closeDialog}
                                />
                              )}
                            </WithFlyoutNavigation>
                          )}
                        </WithParams>
                      ),
                    },
                  ],
                }),
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/helpcenter",
    label: "Help Center",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    element: (
      <PagesLayout
        redirectPath={`/helpcenter/${
          topicSectionTypeConfig[TopicSectionTypes.FAQ].path
        }`}
      />
    ),
    children: [
      {
        path: ":paramsTabId",
        label: "Help Center",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{ paramsTabId: string }>>
            {({ paramsTabId }) => (
              <>
                <HelpCenterPage tabId={paramsTabId} />
              </>
            )}
          </WithParams>
        ),
      },
      {
        path: ":paramsTabId/:stateTabId",
        label: "Help Center",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{ paramsTabId: string; stateTabId: string }>>
            {({ paramsTabId, stateTabId }) => (
              <>
                <HelpCenterPage tabId={paramsTabId} stateTabId={stateTabId} />
              </>
            )}
          </WithParams>
        ),
      },
    ],
  },
] as utils.routes.UclRouteObject[];

export const browserRouter = createBrowserRouter(uclRouteObject, {
  basename: process.env.REACT_APP_PATH_PREFIX,
});
