import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { Lead } from "../../types/Lead";
import { leadEditFormSchema } from "../Forms/MatchupForm/LeadEditForm";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { matchupApiClient } from "../../lib/apiClients/matchup/matchupApiClient";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { AppToaster } from "../Toast/Toast";
import { MatchupDocumentsCard } from "../Matchup/MatchupDocumentsCard";

export interface LeadEditDialogProps extends BaseDialogProps {}

export const LeadEditDialog: React.FC<LeadEditDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [lead, setLead] = useState<Lead | null>(null);

  useEffect(() => {
    if (!!dialogsViewerStore.leadEditDialogOpenOptions) {
      matchupApiClient
        .getLeadById(dialogsViewerStore.leadEditDialogOpenOptions?.leadId)
        .then((lead) => {
          setLead(lead);
        });
    }

    return () => {
      setLead(null);
    };
  }, [dialogsViewerStore.leadEditDialogOpenOptions]);

  const saveLead = async (lead: Lead) => {
    setIsSaving(true);
    await matchupApiClient
      .updateLead(lead.id, lead)
      .then(() => {
        setLead(lead);
        gridStore.refresh();
        submitDialog();
      })
      .finally(() => {
        setIsSaving(false);
        closeDialog();
      });
  };

  const saveAndConvertLead = async (
    lead: Lead,
    shouldValidateSSNOnOpenApplication = true
  ) => {
    setIsConverting(true);
    await matchupApiClient
      .updateLead(lead.id, lead)
      .then(async () => {
        await matchupApiClient
          .convertToApplication(
            lead.id,
            lead,
            shouldValidateSSNOnOpenApplication
          )
          .then((res: any) => {
            let redirectTo: string | undefined = undefined;
            if (res.applicationStatus_AsString === "Interviewing") {
              redirectTo = `/application-form/${res.applicationId}/primary-information`;
            } else {
              redirectTo = `/application-page/${res.applicationId}`;
            }
            window.open(redirectTo, "_blank");
          })
          .then(() => {
            gridStore.refresh();
            submitDialog();
          });

        setLead(lead);
        closeDialog();
      })
      .catch((error: any) => {
        if (error.response?.status === 400) {
          const specificErrorMessage =
            "Social Security Number exists on an open application.";
          const ssnErrors =
            error.response?.data?.additionalInfo?.socialSecurityNumber;

          if (
            Array.isArray(ssnErrors) &&
            ssnErrors.includes(specificErrorMessage)
          ) {
            dialogsViewerStore.setIsConfirmDialogOpen(true, {
              content:
                "There is an open file with a matching SSN, are you sure you want to proceed with conversion?",
              onConfirm: async () => {
                saveAndConvertLead(lead, false);
                return { isSuccess: true, error: null };
              },
            });
          } else {
            AppToaster.show({
              message: (
                <div>
                  Please fill out all required fields before converting to an
                  Application.
                </div>
              ),
              intent: Intent.DANGER,
            });
          }
        }
      })
      .finally(() => {
        setIsConverting(false);
      });
  };

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Match Up"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            lead && (
              <>
                <Form
                  formId="edit-lead-form"
                  value={lead}
                  formSchemaBuilder={leadEditFormSchema}
                  onFormSubmit={async (lead) => {
                    const clickedButtonId = document.activeElement?.id;
                    const isSaveAndConvert =
                      clickedButtonId === "lead-save-and-convert-button";

                    if (isSaveAndConvert) {
                      await saveAndConvertLead(lead);
                    } else {
                      await saveLead(lead);
                    }
                  }}
                />
                <div className="matchup-documents">
                  <MatchupDocumentsCard lead={lead} />
                </div>
              </>
            )
          }
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="lead-save-button"
          isSaving={isSaving}
          formId="edit-lead-form"
          text="Save"
        />
        <FormSubmitButton
          buttonId="lead-save-and-convert-button"
          isSaving={isConverting}
          formId="edit-lead-form"
          text="Convert to Application"
        />
      </div>
    </Dialog>
  );
};
