import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState, useEffect } from "react";
import { billingApiClient } from "../../lib/apiClients/billing/billingApiClient";
import { Discount } from "../../types/Discount";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import {
  discountFormSchema,
  newDiscount,
} from "../Forms/BillingForm/DiscountForm";
import { BaseDialogProps } from "./types/types";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { discountGridStore } from "../Billing/store/DiscountGridStore";

export interface DiscountCreationDialogProps extends BaseDialogProps {
  corporateId?: string;
}

export const DiscountCreationDialog: React.FC<DiscountCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [corporateId, setCorporateId] = useState("");

  useEffect(() => {
    if (!!dialogsViewerStore.discountCreationDialogOpenOptions) {
      setCorporateId(
        dialogsViewerStore.discountCreationDialogOpenOptions.corporateId
      );
    }

    return () => {
      setCorporateId("");
    };
  }, [dialogsViewerStore.discountCreationDialogOpenOptions]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Discount"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<Discount>
              formId="create-discount-form"
              value={newDiscount as Discount}
              formSchemaBuilder={discountFormSchema}
              onFormSubmit={async (discount) => {
                if (corporateId) {
                  setIsSaving(true);
                  await billingApiClient
                    .createDiscount({ ...discount, corporateId })
                    .then(() => {
                      submitDialog();
                      discountGridStore.refresh();
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="save-discount-button"
          isSaving={isSaving}
          formId="create-discount-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
