import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { PageSidebar } from "../../components/PageSidebar/PageSidebar";
import { useReport } from "./useReport";
import { Report } from "./types";
import { Loading } from "../Loading/Loading";

export interface ReportUpdatReportViewerProps {
  id: string;
}

export const ReportViewer: React.FC<ReportUpdatReportViewerProps> = (props) => {
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(true);
  const [report, setReport] = useState<Report>();

  const { getReport } = useReport();

  useEffect(() => {
    loadReport();
  }, []);

  const loadReport = async () => {
    setIsLoadingReport(true);

    const loadedReport = await getReport(props.id);

    setReport(loadedReport);
    setIsLoadingReport(false);
  };

  return (
    <div className="page board-page">
      {isLoadingReport && <Loading />}
      {!isLoadingReport && report && (
        <>
          <Header
            breadCrumbs={[
              { href: "/reports", text: "Reports" },
              { text: report.title },
            ]}
          />
          <div className="content">
            <PageSidebar activePage="reports" />
            <div className="page-main-content">
              <iframe
                title={report.title}
                width="100%"
                height="100%"
                src={report.externalLink}
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
