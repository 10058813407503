import { useState } from "react";
import variables from "../../config/variables";
import { userStore } from "../../stores/UserStore";
import { SchedulerComponent } from "./SchedulerComponent";
import { ScheduleResource, SchedulerResourceConfig } from "./types/Resources";
import { SchedulerResources } from "./SchedulerResources";
import { User, UserRoles } from "../../types/User";
import { ScheduleViews } from "./types/SchedulerViews";
import { getScheduleResourceColor } from "./utils/scheduleUtils";

export const AppointmentsCalendar = () => {
  const [scheduleResourceData, setScheduleResourceData] = useState<
    ScheduleResource[]
  >([]);

  const getResourceConfig = (user: User) => {
    let resourceConfig: SchedulerResourceConfig[] = [];
    if (user?.isExternal) {
      resourceConfig = [
        {
          field: "facilityId",
          title: "Facility",
          name: "Facilities",
          idField: "id",
          textField: "text",
          colorField: "color",
          dataSource: scheduleResourceData,
        },
      ];
      return resourceConfig;
    } else if (user?.role === UserRoles.FieldRepresentative) {
      resourceConfig = [
        {
          field: "fieldRepId",
          title: "User",
          name: "Users",
          idField: "id",
          textField: "text",
          colorField: "color",
          dataSource: scheduleResourceData,
        },
      ];
      return resourceConfig;
    }
    return resourceConfig;
  };

  const getResourceProps = (user: User, scheduleViewType: ScheduleViews) => {
    return {
      resources: mapResourceItems(user),
      setScheduleResourceData,
      header: user?.isExternal ? "My Facilities" : "My Calendars",
      scheduleViewType: scheduleViewType,
      user: user,
    };
  };

  const mapResourceItems = (user: User) => {
    let resourceItems: ScheduleResource[] = [];
    if (user?.isExternal) {
      resourceItems =
        user?.facilities.map(
          (facility, index): ScheduleResource => ({
            id: facility.facilityId,
            text: facility.facilityName,
            color: getScheduleResourceColor(index),
          })
        ) ?? [];
      return resourceItems;
    } else if (user?.role === UserRoles.FieldRepresentative) {
      resourceItems = [
        {
          id: userStore.user?.id ?? "",
          text: "Maverick",
          color: "#ea7a57",
        },
        {
          id: "outlook",
          text: "Outlook",
          color: "#f8a398",
        },
      ];
    }

    return resourceItems;
  };

  return (
    <div className="in-app-scheduler">
      <div className="resources-container">
        <h2 className="resources-title">Appointments Calendar</h2>
        {userStore.user && (
          <SchedulerResources
            {...getResourceProps(
              userStore.user,
              ScheduleViews.AppointmentsCalendar
            )}
          />
        )}
      </div>
      {userStore.user && (
        <SchedulerComponent
          schedulerSetupSettings={{
            viewOptions: [{ view: "Day" }, { view: "Week" }],
            readOnly: false,
            odataUrl: `${variables.apiBaseUrl}odata/appointmentodata`,
            user: userStore.user,
            fieldModel: {
              id: "id",
              subject: { name: "fieldRep_FullName" },
              location: { name: "facility_Name" },
              startTime: { name: "startTime" },
              endTime: { name: "endTime" },
              isReadonly: "isReadOnly",
            },
            resourceConfig: getResourceConfig(userStore.user),
            scheduleViewType: ScheduleViews.AppointmentsCalendar,
          }}
        />
      )}
    </div>
  );
};
