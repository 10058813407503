import {
  ActionCompleteEventArgs,
  AsyncSettingsModel,
  RemovingEventArgs,
  UploaderModel,
  UploadingEventArgs,
} from "@syncfusion/ej2-react-inputs";
import { FC, useState } from "react";
import variables from "../../config/variables";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import { Document, NewDocument } from "../../types/Document";
import { buildFileDocumentCustomFormData } from "../../types/FileDocument";
import { buildField } from "../Fields";
import { AllFields } from "../Fields/types/fieldTypes";

export enum PdfDocumentEntityType {
  Application = 1,
  Template = 2,
  Checklist = 3,
  Invoice = 4,
  InstitutionDocumentsNeeded = 5,
  FIARequestDocuments = 6,
  Notary = 7,
  Signature = 8,
  Matchup = 9,
  Corporate = 10,
  FilingAI = 11,
  AppointmentDocuments = 12,
}

export interface PdfDocumentEntity {
  entityId?: string;
  entityType: PdfDocumentEntityType;
  applicationId?: string;
  useStatementsReceived?: boolean;
}
export interface PdfDocumentUploader {
  pdfDocumentEntity: PdfDocumentEntity;
  showDocumentUploader?: () => void;
  onCompleteUploading?: (doc: Document) => void;
  onCompleteRemoving?: () => void;
  onAllActionComplete?: (args: ActionCompleteEventArgs) => void;
  allowMultipleFiles?: boolean;
  disabled?: boolean;
  onUploading?: () => void;
}

export const PdfDocumentUploader: FC<PdfDocumentUploader> = (props) => {
  const [document, setDocument] = useState<Document>();

  const onUploadingFile = (args: UploadingEventArgs) => {
    // Set Custom Data
    props.onUploading && props.onUploading();
    const doc: Partial<NewDocument> = {
      originalFileName: args.fileData.name,
      entityType: props.pdfDocumentEntity.entityType,
      entityId: props.pdfDocumentEntity.entityId,
      applicationId: props.pdfDocumentEntity.applicationId,
      useStatementsReceived: props.pdfDocumentEntity.useStatementsReceived,
      isChunked: true,
    };

    args.customFormData = buildFileDocumentCustomFormData(doc);

    if (!!props.showDocumentUploader) {
      props.showDocumentUploader();
    }
  };

  const onChunkUploadingFile = (
    args: UploadingEventArgs,
    uploadedDocument?: Document
  ) => {
    props.onUploading && props.onUploading();
    // Set Custom Data
    const doc: Partial<Document> = {
      id: uploadedDocument?.id,
      fileName: uploadedDocument?.fileName,
      originalFileName: args.fileData.name,
      entityType: props.pdfDocumentEntity.entityType,
      entityId: props.pdfDocumentEntity.entityId,
      applicationId: props.pdfDocumentEntity.applicationId,
      isChunked: true,
    };

    args.customFormData = buildFileDocumentCustomFormData(doc);

    if (!!props.showDocumentUploader) {
      props.showDocumentUploader();
    }
  };

  const onRemovingFile = (args: RemovingEventArgs) => {
    const doc: Partial<Document> = {
      id: document?.id,
      isChunked: true,
    };

    // Set Custom Data
    args.customFormData = buildFileDocumentCustomFormData(doc);
  };

  return buildField(
    {
      type: "File",
      disabled: props.disabled || false,
      onSubmit: (doc: Document) => {
        setDocument(doc);
      },
      onCompleteUploading: props.onCompleteUploading,
      onCompleteRemoving: props.onCompleteRemoving,
      getAccessToken: getAccessToken,
      onUploading: onUploadingFile,
      onChunkUploading: onChunkUploadingFile,
      onAllActionComplete: props.onAllActionComplete,
      onRemoving: onRemovingFile,
      uploaderConfig: {
        multiple: props.allowMultipleFiles || false,
        allowedExtensions: ".pdf",
        asyncSettings: {
          chunkSize: 102400,
          removeUrl: `${variables.apiBaseUrl}api/document/remove`,
          saveUrl: `${variables.apiBaseUrl}api/document/upload`,
        } as AsyncSettingsModel,
      } as UploaderModel,
    } as AllFields,
    0
  );
};
