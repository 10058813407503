import baseApiClient from "../../../../lib/apiClients/baseApiClient";
import { GridMetricRequestDTO } from "../../types/dtos/GridMetrics";

class GridMetricsApiClient {
  fetchMetrics = async <T>(url: string, qry: string): Promise<T> => {
    const gridMetricRequestDTO = {
      query: qry,
    } as GridMetricRequestDTO;
    return baseApiClient.post<T>(url, gridMetricRequestDTO);
  };
}

export const gridMetricsApiClient = new GridMetricsApiClient();
