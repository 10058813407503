import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { ScheduleResource } from "./types/Resources";
import { Dispatch, SetStateAction } from "react";
import { Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { openFacilityBlockScheduleDialog } from "../Dialogs/FacilityBlockScheduleDialog";
import { User, UserRoles } from "../../types/User";
import { ScheduleViews } from "./types/SchedulerViews";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

export interface SchedulerResourcesProps {
  resources: ScheduleResource[];
  setScheduleResourceData: Dispatch<SetStateAction<ScheduleResource[]>>;
  header?: string;
  scheduleViewType: ScheduleViews;
  user: User;
}

export const SchedulerResources: React.FC<SchedulerResourcesProps> = (
  props
) => {
  const handleCheckBoxChange = (
    resource: ScheduleResource,
    isChecked: boolean
  ) => {
    if (isChecked) {
      props.setScheduleResourceData((prevData: ScheduleResource[]) => [
        ...prevData,
        resource,
      ]);
    } else {
      props.setScheduleResourceData((prevData: ScheduleResource[]) =>
        prevData.filter((resources) => resources.id !== resource.id)
      );
    }
  };

  return (
    <div className="resources-items">
      <div className="resources-items-header">
        {props.header && <h3>{props.header}</h3>}
        {props.scheduleViewType === ScheduleViews.FacilitySchedule && (
          <div className="resources-filter">
            <TooltipComponent
              id="filterTooltip"
              opensOn="Click"
              target="#filterIcon"
              isSticky={true}
              content={() => (
                <div>
                  {props.resources.map(
                    (resource: ScheduleResource, index: number) => (
                      <CheckBoxComponent
                        key={index}
                        className="resource-checkbox"
                        label={resource.text}
                        onChange={(args: any) => {
                          handleCheckBoxChange(
                            {
                              id: resource.id,
                              text: resource.text,
                              color: resource.color,
                            },
                            args.target.checked
                          );
                        }}
                      />
                    )
                  )}
                </div>
              )}
            >
              <Icon
                id="filterIcon"
                icon={IconNames.FilterList}
                intent={Intent.PRIMARY}
              />
            </TooltipComponent>
          </div>
        )}
      </div>

      <div className="resource-items">
        {props.scheduleViewType === ScheduleViews.AppointmentsCalendar &&
          props.resources.map((resource: ScheduleResource, index: number) => (
            <CheckBoxComponent
              key={index}
              className="resource-checkbox"
              label={resource.text}
              onChange={(args: any) => {
                handleCheckBoxChange(
                  {
                    id: resource.id,
                    text: resource.text,
                    color: resource.color,
                  },
                  args.target.checked
                );
              }}
            />
          ))}
        {props.scheduleViewType === ScheduleViews.FacilitySchedule &&
          props.user.role === UserRoles.FieldRepresentative &&
          props.resources.map((resource: ScheduleResource, index: number) => (
            <div key={index} className="resource-item-filtered">
              <div>{resource.text}</div>
              <Icon
                icon={IconNames.Cog}
                onClick={() => {
                  if (props.user) {
                    openFacilityBlockScheduleDialog(
                      resource.id,
                      props.user.id,
                      props.user.fullName
                    );
                  }
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
