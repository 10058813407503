import {
  AsyncAutoCompleteFieldProps,
  CalendarFieldProps,
  CurrencyFieldProps,
  DateFieldProps,
  EnumSingleSelectFieldV2Props,
  SchemaFactoryV2,
  SocialSecurityNumberFieldProps,
  TextFieldProps,
} from "@ucl/library";
import { AppointmentFormModel } from "./AppointmentFormModel";
import { Dispatch, SetStateAction } from "react";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { userStore } from "../../../stores/UserStore";
import { getDurationDisplayText } from "./util";
import { User, UserRoles } from "../../../types/User";
import variables from "../../../config/variables";
import { getAuthRequestHeader } from "../../../lib/apiClients/baseApiClient";
import { Facility } from "../../../types/Facility";
import { YesNoOptionTypes } from "./types/YesNoOptionTypes";
import { InsuranceTypeOptions } from "./types/InsuranceTypeOptions";
import {
  Option,
  EnumSingleSelectFieldValue,
  SocialSecurityNumberFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { get } from "lodash";

const modelName = "Appointment";
const getFieldKey = (key: string) => `${modelName}__${key}`;

export const getAppointmentFormSchema = (
  factory: SchemaFactoryV2,
  errors: { [key: string]: string[] },
  form: AppointmentFormModel,
  setForm: Dispatch<SetStateAction<AppointmentFormModel>>,
  startTimesLoading: boolean,
  startTimeOptions: Option<EnumSingleSelectFieldValue>[],
  endTimesLoading: boolean,
  endTimeOptions: Option<EnumSingleSelectFieldValue>[],
  handleSSNFieldChanged: (value: SocialSecurityNumberFieldValue) => void
) => {
  const isExternalUser = userStore.user?.isExternal;

  const getFacilityQuery = () => {
    let query = new Query().where("isActive", "equal", true);

    let regions: number[] = [];
    let facilityIds: string[] = [];

    switch (userStore?.user?.role) {
      case UserRoles.FieldRepresentativeManager:
        regions = userStore.user?.regions || [];
        break;
      case UserRoles.CorporateUser:
      case UserRoles.FacilityUser:
        facilityIds = userStore.user?.facilities.map((x) => x.facilityId) || [];
        break;
      case UserRoles.FieldRepresentative:
        facilityIds = userStore.user?.fieldRepFacilityIds || [];
        break;
    }

    if (regions.length > 0) {
      query = query.where(
        regions
          .map((region) => new Predicate("area", "equal", region))
          .reduce((prevPredicate: Predicate, currPredicate: Predicate) =>
            prevPredicate.or(currPredicate)
          )
      );
    }

    if (facilityIds.length > 0) {
      query = query.where(
        facilityIds
          .map((facilityId) => new Predicate("value", "equal", facilityId))
          .reduce((prevPredicate: Predicate, currPredicate: Predicate) =>
            prevPredicate.or(currPredicate)
          )
      );
    }

    // Filter Facilities with no Field Rep for External Users
    if (isExternalUser) {
      query = query.where(
        new Predicate(
          "fieldRepId",
          "notequal",
          "00000000-0000-0000-0000-000000000000"
        ).and(new Predicate("fieldRepFullName", "notequal", null))
      );
    }

    return query.take(25);
  };

  const getFieldRepQuery = () => {
    return new Query()
      .select(["label", "value"])
      .where(
        new Predicate("role", "equal", UserRoles.FieldRepresentative)
          .or(
            new Predicate("role", "equal", UserRoles.FieldRepresentativeManager)
          )
          .or(new Predicate("role", "equal", UserRoles.HybridRepProcessor))
      )
      .take(50);
  };

  return {
    RescheduleReason: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("RescheduleReason")
      ),
      value: form.rescheduleReason,
      isRequired: true,
      onSubmit(value) {
        setForm((prev) => {
          return {
            ...prev,
            rescheduleReason: value,
          };
        });
      },
      errorMessages: get(errors, "rescheduleReason"),
    } as EnumSingleSelectFieldV2Props,
    CancellationReason: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("CancellationReason")
      ),
      value: form.cancellationReason,
      isRequired: true,
      onSubmit(value) {
        setForm((prev) => {
          return {
            ...prev,
            cancellationReason: value,
          };
        });
      },
      errorMessages: get(errors, "cancellationReason"),
    } as EnumSingleSelectFieldV2Props,
    FacilityId: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("FacilityId")),
      value: form.facilityId,
      isRequired: true,
      odataUrl: `${variables.apiBaseUrl}odata/facilityodata/options`,
      getAuthRequestHeader,
      fieldNames: ["label", "value", "fieldRepId", "fieldRepFullName"],
      sortBy: "label",
      autoCompleteSettings: {
        query: getFacilityQuery(),
      },
      onSubmit: (value) => {
        // Workaround because AsyncAutocompleteFieldValue type is not accurate
        // It actually returns the entire OData record as an object
        const selection = value as unknown as Option<string> & Facility;
        setForm((prevState) => {
          return {
            ...prevState,
            facilityId: selection?.value,
            facility_Name: selection?.label || "",
            facilityHasNoFieldRep: !selection?.fieldRepId,
            fieldRepId: selection?.fieldRepId,
            fieldRep_FullName: selection?.fieldRepFullName || "",
            // Clear date when facility changed
            date: undefined,
            startTime: undefined,
            endTime: undefined,
          };
        });
      },
      errorMessages: get(errors, "facilityId"),
    } as AsyncAutoCompleteFieldProps,
    FieldRepId: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("FieldRepId")),
      value: form.fieldRepId,
      isRequired: true,
      odataUrl: `${variables.apiBaseUrl}odata/userodata/options`,
      getAuthRequestHeader,
      fieldNames: ["label", "value"],
      sortBy: "label",
      autoCompleteSettings: {
        query: getFieldRepQuery(),
      },
      onSubmit: (value) => {
        // Workaround because AsyncAutocompleteFieldValue type is not accurate
        // It actually returns the entire OData record as an object
        const selection = value as unknown as Option<string> & User;
        setForm((prevState) => {
          return {
            ...prevState,
            fieldRepId: selection?.value,
            fieldRep_FullName: selection?.label || "",
            // Clear date when field rep changed
            date: undefined,
            startTime: undefined,
            endTime: undefined,
          };
        });
      },
      errorMessages: get(errors, "fieldRepId"),
    } as AsyncAutoCompleteFieldProps,
    FieldRep_FullName: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("FieldRep_FullName")
      ),
      value: form.fieldRep_FullName,
      disabled: true, // Always readonly
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            fieldRep_FullName: value,
          };
        }),
      errorMessages: get(errors, "fieldRepId"),
    } as TextFieldProps,
    Date: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("Date")),
      showTodayButton: false,
      value: form.date,
      isRequired: true,
      minDate: new Date(),
      // 2 months from today for external users, 1 year for internal users
      maxDate: new Date(
        new Date().setMonth(new Date().getMonth() + (isExternalUser ? 12 : 2))
      ),
      disabled: isExternalUser || !form.fieldRepId,
      onSubmit: (value) =>
        // Clear start time, end time, and duration values when date changed
        setForm((prevState) => {
          return {
            ...prevState,
            date: value,
            startTime: undefined,
            endTime: undefined,
          };
        }),
      errorMessages: get(errors, "date"),
    } as CalendarFieldProps,
    StartTime: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("StartTime")),
      value: form.startTime,
      isRequired: true,
      optionValues: startTimeOptions,
      disabled: isExternalUser || startTimesLoading || !form.date,
      showClearButton: false,
      onSubmit: (value) => {
        setForm((prevState) => {
          return {
            ...prevState,
            startTime: value,
            // Clear end time value when start time changed
            endTime: undefined,
          };
        });
      },
      errorMessages: get(errors, "startTime"),
    } as EnumSingleSelectFieldV2Props,
    EndTime: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("EndTime")),
      value: form.endTime,
      isRequired: true,
      optionValues: endTimeOptions,
      disabled:
        isExternalUser || endTimesLoading || !form.date || !form.startTime,
      showClearButton: false,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            endTime: value,
          };
        }),
      errorMessages: get(errors, "endTime"),
    } as EnumSingleSelectFieldV2Props,
    Duration: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("Duration")),
      value: getDurationDisplayText(form.startTime, form.endTime),
      disabled: true, // Always readonly
    } as TextFieldProps,
    FirstName: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("FirstName")),
      value: form.firstName,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            firstName: value,
          };
        }),
      errorMessages: get(errors, "firstName"),
    } as TextFieldProps,
    LastName: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("LastName")),
      value: form.lastName,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            lastName: value,
          };
        }),
      errorMessages: get(errors, "lastName"),
    } as TextFieldProps,
    IsResidentCapableOfSigning: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("IsResidentCapableOfSigning")
      ),
      value: form.isResidentCapableOfSigning,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            isResidentCapableOfSigning: value,
          };
        }),
      errorMessages: get(errors, "isResidentCapableOfSigning"),
    } as EnumSingleSelectFieldV2Props,
    WillAnyoneElseBeAttending: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("WillAnyoneElseBeAttending")
      ),
      value: form.willAnyoneElseBeAttending,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            willAnyoneElseBeAttending: value,
          };
        }),
      errorMessages: get(errors, "willAnyoneElseBeAttending"),
    } as EnumSingleSelectFieldV2Props,
    MeetingType: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("MeetingType")),
      value: form.meetingType,
      isRequired: true,
      hidden: isExternalUser,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            meetingType: value,
          };
        }),
      errorMessages: get(errors, "meetingType"),
    } as EnumSingleSelectFieldV2Props,
    SocialSecurityNumber: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("SocialSecurityNumber")
      ),
      value: form.socialSecurityNumber,
      onSubmit: (value) =>
        setForm((prevState) => {
          if (prevState.socialSecurityNumber !== value) {
            handleSSNFieldChanged(value);
          }
          return {
            ...prevState,
            socialSecurityNumber: value,
          };
        }),
    } as SocialSecurityNumberFieldProps,
    StartingMonthOfEligibilityNeeded: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("StartingMonthOfEligibilityNeeded")
      ),
      value: form.startingMonthOfEligibilityNeeded,
      isRequired: true,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            startingMonthOfEligibilityNeeded: value,
          };
        }),
      errorMessages: get(errors, "startingMonthOfEligibilityNeeded"),
    } as EnumSingleSelectFieldV2Props,
    PlannedCareType: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("PlannedCareType")
      ),
      value: form.plannedCareType,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            plannedCareType: value,
          };
        }),
    } as EnumSingleSelectFieldV2Props,
    IsBalanceOwedToFacility: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("IsBalanceOwedToFacility")
      ),
      value: form.isBalanceOwedToFacility,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            isBalanceOwedToFacility: value,
          };
        }),
    } as EnumSingleSelectFieldV2Props,
    BalanceOwedToFacility: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("BalanceOwedToFacility")
      ),
      value: form.balanceOwedToFacility,
      hidden: form.isBalanceOwedToFacility !== YesNoOptionTypes.Yes,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            balanceOwedToFacility: value,
          };
        }),
    } as CurrencyFieldProps,
    LastInsuranceCoveredDate: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("LastInsuranceCoveredDate")
      ),
      value: form.lastInsuranceCoveredDate,
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            lastInsuranceCoveredDate: value,
          };
        }),
    } as DateFieldProps,
    InsuranceType: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("InsuranceType")),
      value: form.insuranceType,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            insuranceType: value,
          };
        }),
    } as EnumSingleSelectFieldV2Props,
    CopayAmount: {
      ...factory.getFieldSchemaByName(modelName, getFieldKey("CopayAmount")),
      value: form.copayAmount,
      hidden: form.insuranceType !== InsuranceTypeOptions.Copay,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            copayAmount: value,
          };
        }),
    } as CurrencyFieldProps,
    CoinsuranceAmount: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("CoinsuranceAmount")
      ),
      value: form.coinsuranceAmount,
      hidden: form.insuranceType !== InsuranceTypeOptions.Coinsurance,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            coinsuranceAmount: value,
          };
        }),
    } as CurrencyFieldProps,
    AdditionalNotes: {
      ...factory.getFieldSchemaByName(
        modelName,
        getFieldKey("AdditionalNotes")
      ),
      value: form.additionalNotes,
      onSubmit: (value) =>
        setForm((prevState) => {
          return {
            ...prevState,
            additionalNotes: value,
          };
        }),
    } as TextFieldProps,
  };
};

export default getAppointmentFormSchema;
