import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { IconNames } from "@blueprintjs/icons";
import { useState, useEffect } from "react";
import "../../Grid/Views/styles.scss";

export interface BillingCannedViewPickerProps {
  setGridCannedView: Function;
}

export const BillingCannedViewPicker: React.FC<BillingCannedViewPickerProps> =
  observer((props) => {
    const [cannedView, setCannedView] = useState<string>("Not Invoiced");

    useEffect(() => {
      props.setGridCannedView(cannedView);

      return () => {
        props.setGridCannedView("Not Invoiced");
      };
    }, [cannedView, setCannedView]);

    return (
      <div className="canned-view-picker-popover">
        <Popover2
          placement="bottom-end"
          content={
            <Menu>
              <MenuItem
                text="Not Invoiced"
                className="not-invoiced"
                onClick={() => {
                  setCannedView("Not Invoiced");
                }}
              />
              <MenuItem
                text="Invoiced"
                className="invoiced"
                onClick={() => {
                  setCannedView("Invoiced");
                }}
              />
            </Menu>
          }
        >
          <div className="canned-view-picker-text">
            <div>{`${cannedView}`}</div>
            <div>
              <Icon iconSize={15} icon={IconNames.CHEVRON_DOWN} />
            </div>
          </div>
        </Popover2>
      </div>
    );
  });
