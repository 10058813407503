import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";
import { AddOwnedEntityComponent } from "../Application/AddOwnedEntityComponent";
import {
  otherTypeString,
  useOwnedEntityComponent,
} from "../Application/UseOwnedEntityComponent";

export interface ChecklistSplitAddOwnedEntityDialogProps
  extends BaseDialogProps {
  onSave: () => void;
}

export const ChecklistSplitAddOwnedEntityDialog: React.FC<
  ChecklistSplitAddOwnedEntityDialogProps
> = ({ isOpen, closeDialog, onSave }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { ownedEntityTypeString, setOwnedEntityTypeString } =
    useOwnedEntityComponent();

  return (
    <Dialog
      portalClassName="mdr-dialog"
      className="checklist-split-add-owned-entity-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"New Checklist Item"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <AddOwnedEntityComponent
          onSave={onSave}
          onCancel={closeDialog}
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          ownedEntityType={ownedEntityTypeString}
          setOwnedEntityType={setOwnedEntityTypeString}
        />
      </div>
      {(ownedEntityTypeString === otherTypeString ||
        ownedEntityTypeString === "") && (
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={closeDialog}
          />
          {ownedEntityTypeString === otherTypeString && (
            <FormSubmitButton
              buttonId="checklist-item-button"
              isSaving={isSaving}
              formId="checklist-item-form"
              text="Save"
            />
          )}
        </div>
      )}
    </Dialog>
  );
};
