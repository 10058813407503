import {
  PrimaryGrid,
  PrimaryGridModule,
  SortDescriptorModel,
} from "@ucl/library";
import {
  FIARequestODataReadDTO,
  FIARequestMetricReadDTO,
} from "./configurations/types";
import baseApiClient, {
  getAuthRequestHeader,
} from "../../lib/apiClients/baseApiClient";
import variables from "../../config/variables";
import { MenuEventArgs } from "@syncfusion/ej2-react-navigations";
import { AppToaster } from "../Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { getFiaRequestODataRoutes } from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import GridRecordClickEventArgs from "@ucl/library/lib/components/Grids/GridSettings/GridRecordClickEventArgs";
import { fiaRequestsGridColumnsConfiguration } from "./configurations/gridColumnsConfiguration";
import { useEffect, useRef } from "react";
import classNames from "classnames";

export interface FIARequestsGridProps {
  gridKey: string;
  applicationId?: string;
  odataParams?: Record<string, string>;
  defaultGridViewTitle?: string;
  defaultSort?: SortDescriptorModel;
  shouldShowViews?: boolean;
  shouldShowExportToExcel?: boolean;
  shouldShowSearch?: boolean;
  shouldShowOpenInNewTab?: boolean;
  shouldShowMetrics?: boolean;
  getFIARequestURL: (rowData: FIARequestODataReadDTO) => string;
  onRecordClick?: (
    recordClickEventArgs: GridRecordClickEventArgs<FIARequestODataReadDTO>
  ) => void;
  gridNeedsRefresh?: boolean;
  setGridNeedsRefresh?: (needsRefresh: boolean) => void;
}

export const FIARequestsGrid: React.FC<FIARequestsGridProps> = (
  props: FIARequestsGridProps
) => {
  const gridRef = useRef<PrimaryGridModule>(null);

  useEffect(() => {
    if (props.gridNeedsRefresh && gridRef.current) {
      gridRef.current?.getGridModule()?.getGridRef()?.refresh();
      props.setGridNeedsRefresh?.(false);
    }
  }, [props.gridNeedsRefresh]);

  const contextMenuItems = [
    ...(props.shouldShowOpenInNewTab
      ? [
          {
            text: "Open in new tab",
            target: ".e-content",
            id: "open-in-new-tab",
          },
        ]
      : []),
    {
      text: "Copy",
      target: ".e-rowcell",
      id: "copy-cell",
    },
  ];

  type ContextMenuClickArgs = MenuEventArgs & {
    rowInfo: {
      rowData: FIARequestODataReadDTO;
      cell: { innerText: string };
    };
  };

  const handleCopyCell = (args: ContextMenuClickArgs) => {
    const cellText = args.rowInfo.cell.innerText;
    navigator.clipboard.writeText(cellText);
    AppToaster.show({
      message: `Copied "${cellText}" to clipboard`,
      intent: Intent.SUCCESS,
      timeout: 2000,
    });
  };

  const handleOpenInNewTab = (args: ContextMenuClickArgs) => {
    const rowData = args.rowInfo.rowData;
    window.open(props.getFIARequestURL(rowData), "_blank");
  };

  const contextMenuClick = async (args: ContextMenuClickArgs) => {
    switch (args.item.id) {
      case "copy-cell":
        handleCopyCell(args);
        break;
      case "open-in-new-tab":
        handleOpenInNewTab(args);
        break;
    }
  };

  return (
    <div className={classNames("fia-grid", props.gridKey)}>
      <PrimaryGrid<FIARequestODataReadDTO, FIARequestMetricReadDTO>
        gridKey={props.gridKey}
        ref={gridRef}
        apiClient={baseApiClient}
        odataUrl={`${variables.apiBaseUrl}${
          getFiaRequestODataRoutes().fiaRequestRoute
        }`}
        odataParams={props.odataParams}
        getAuthRequestHeader={getAuthRequestHeader}
        columnsConfiguration={fiaRequestsGridColumnsConfiguration}
        viewsSettings={{
          showGridViewPicker: props.shouldShowViews ?? true,
          gridTitle: "FIA Requests",
          odataUrl: `${variables.apiBaseUrl}odata/usergridviewodata`,
          gridAutoName: "Default",
          defaultGridViewTitle: props.defaultGridViewTitle ?? "All",
        }}
        metricsSettings={
          props.shouldShowMetrics
            ? {
                url: `${variables.apiBaseUrl}api/application/fiarequest/grid`,
                columns: 4,
                languageMapping: {
                  fiaRequestCount: "FIA Request Count",
                  attentionFraction: "Attention Count",
                  attentionPercentage: "Attention %",
                  averageAttentionAge: "Average Attention Age",
                },
              }
            : undefined
        }
        gridConfiguration={{
          allowSelection: false,
          allowExcelExport: props.shouldShowExportToExcel,
          defaultSort: props.defaultSort ?? {
            field: "lastSubmittedAt",
            direction: "Descending",
          },
          onRecordClick: props.onRecordClick,
          contextMenuItems,
          contextMenuClick,
        }}
        allowSearch={props.shouldShowSearch}
      />
    </div>
  );
};
