import { FormGroup, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2 } from "@blueprintjs/popover2";
import { MultiSelect2 } from "@blueprintjs/select";
import React, { useEffect } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import {
  MultiSelectCustomTagsProps,
  MultiSelectFieldProps,
  MultiSelectFieldValue,
  Option,
} from "../types/fieldTypes";
import "./styles.scss";

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  value,
  description,
  label,
  optionValues,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  disabled,
  includeSelectAll,
  selectedItemLimit,
  sortItemListAlphabetically,
  customTagSettings,
}) => {
  if (hidden) {
    return null;
  }

  const selectAllOption: Option = {
    label: "Select All",
    value: "select-all",
  };

  const valueWithSelectAll =
    optionValues.length === value?.length
      ? [selectAllOption.value, ...value]
      : value;

  const setInitialFieldValue = (includeSelectAll = false) => {
    if (!!includeSelectAll) {
      return valueWithSelectAll;
    } else {
      return value;
    }
  };
  const [localValue, setLocalValue] = React.useState<MultiSelectFieldValue>(
    setInitialFieldValue(includeSelectAll) || []
  );

  const localOptionValues: Option[] = [selectAllOption, ...optionValues];

  const disableItemSelection = selectedItemLimit
    ? localValue.filter((x) => x !== "").length >= selectedItemLimit
    : false;

  useEffect(() => {
    if (!!disabled) {
      setLocalValue(value);
    }
  }, [value]);

  const renderTag = (
    item: Option,
    customTagSettings?: MultiSelectCustomTagsProps
  ) => {
    if (
      !!customTagSettings?.useCustomTags &&
      customTagSettings.tagValues.includes(item.value)
    ) {
      return (
        <span className={`custom-tag__${customTagSettings.customClassName}`}>
          {item.label}
        </span>
      );
    }
    return item.label;
  };

  return (
    <FormGroup
      className={`${
        isRequired && localValue.filter((x) => x !== "").length === 0
          ? "has-required-background"
          : "select-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <div
        className={`${
          localValue.length === 0 || localValue[0] === ""
            ? "multi-select-container__empty"
            : "multi-select-container"
        }`}
      >
        <MultiSelect2<Option>
          className="multi-select-input"
          disabled={disabled}
          selectedItems={
            localValue
              .map((value) =>
                localOptionValues.find(
                  (optionValue) => String(optionValue.value) === String(value)
                )
              )
              .filter((value) => !!value) as Option[]
          }
          popoverProps={{
            position: "auto",
            hasBackdrop: true,
            minimal: false,
            canEscapeKeyClose: true,
          }}
          items={includeSelectAll ? localOptionValues : optionValues}
          itemRenderer={(item, itemProps) => {
            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem2
                className="select__menu-item"
                key={itemProps.index}
                text={item.label}
                active={localValue.some(
                  (value) => String(value) === String(item.value)
                )}
                disabled={disableItemSelection}
                onClick={itemProps.handleClick}
                {...itemProps}
              />
            );
          }}
          onClear={() => {
            const newValue: any[] = [];
            setLocalValue(newValue);
            onSubmit(newValue);
          }}
          noResults={"No Results"}
          resetOnSelect={true}
          onRemove={(item) => {
            let newValue: any[] = [];
            if (item.value === selectAllOption.value) {
              newValue = [];
            } else {
              newValue = localValue.filter(
                (value) =>
                  String(value) !== String(item.value) &&
                  String(value) !== selectAllOption.value
              );
            }
            setLocalValue(newValue);
            onSubmit(newValue);
          }}
          onItemSelect={(item) => {
            let newValue: any[] = [];

            if (
              localValue.some((value) => String(value) === String(item.value))
            ) {
              if (item.value === selectAllOption.value) {
                newValue = [];
                setLocalValue(newValue);
              } else {
                newValue = localValue.filter(
                  (value) =>
                    String(value) !== String(item.value) &&
                    String(value) !== selectAllOption.value
                );
                setLocalValue(newValue);
              }
            } else {
              if (item.value === selectAllOption.value) {
                newValue = optionValues.map((optionValue) => optionValue.value);
                setLocalValue([selectAllOption.value, ...newValue]);
              } else {
                newValue = [...localValue, item.value];
                setLocalValue(newValue);
              }
            }
            onSubmit(newValue);
          }}
          tagRenderer={(item) => renderTag(item, customTagSettings)}
          itemListPredicate={(query, items) => {
            query = query.toLowerCase();

            let filteredItems = items.filter(
              (optionValue) =>
                optionValue.label.toLowerCase().includes(query) &&
                !optionValue.isExcluded
            );

            if (sortItemListAlphabetically) {
              filteredItems = filteredItems.sort((a, b) => {
                // Pin "Select All" to the top of the list
                if (a.value === selectAllOption.value) {
                  return -1;
                } else if (b.value === selectAllOption.value) {
                  return 1;
                }
                // sort remainder of list alphabetically by label
                else {
                  return a.label.localeCompare(b.label);
                }
              });
            }
            return filteredItems;
          }}
        />
        <Icon className="select-right-icon" icon={IconNames.CaretDown} />
      </div>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
