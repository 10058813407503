import { applicationApiClient } from "../../../lib/apiClients/application/applicationApiClient";
import { applicationStore } from "../../../stores/ApplicationStore";
import { userStore } from "../../../stores/UserStore";
import { Application } from "../../../types/Application";
import { ApplicationFIARequestsView } from "../../FIARequests/ApplicationFIARequestsView";
import { ApplicationContactCard } from "./ApplicationContactCard";
import { DateInformationPageCard } from "./ApplicationPage/DateInformationPageCard";
import { FileInformationPageCard } from "./ApplicationPage/FileInformationPageCard";
import { ApplicationPageContactCard } from "./ApplicationPage/PrimaryTabs/Owned Entities/ApplicationPageContactCard";
import { AssetInformationPageCard } from "./ApplicationPage/PrimaryTabs/AssetInformationPageCard";
import { BillInformationPageCard } from "./ApplicationPage/PrimaryTabs/BillInformationPageCard";
import { IncomeInformationPageCard } from "./ApplicationPage/PrimaryTabs/IncomeInformationPageCard";
import { LegalInformationPageCard } from "./ApplicationPage/PrimaryTabs/LegalInformationPageCard";
import { MedicalCoveragesPageCard } from "./ApplicationPage/PrimaryTabs/MedicalCoveragesPageCard";
import { MedicalInformationPageCard } from "./ApplicationPage/PrimaryTabs/MedicalInformationPageCard";
import { SignaturesPageCard } from "./ApplicationPage/PrimaryTabs/SignaturesPageCard";
import { SpouseInformationPageCard } from "./ApplicationPage/PrimaryTabs/SpouseInformationPageCard";
import { StateInformationCard } from "./ApplicationPage/StateInformationPageCard";
import { AssetInformationCard } from "./Asset/AssetInformationCard";
import { BillInformationCard } from "./BillInformationCard";
import { ChecklistCard } from "./ChecklistCard";
import { CommentsCard } from "./CommentsCard/CommentsCard";
import { DateInformationCard } from "./DateInformationCard";
import { IncomeInformationCard } from "./Income/IncomeInformationCard";
import { LegalInformationCard } from "./LegalInformationCard";
import { MedicalInformationCard } from "./MedicalInformationCard";
import { PrimaryInformationCard } from "./PrimaryInformationCard";
import { SecondaryInformationCard } from "./SecondaryInformationCard";
import { SignaturesCard } from "./SignaturesCard";
import { SpouseInformationCard } from "./SpouseInformationCard";
import { AuditLogCard } from "./AuditLogCard";
import { assetOwnedEntityStore } from "../../../stores/OwnedEntityStores/AssetOwnedEntityStore";
import { billOwnedEntityStore } from "../../../stores/OwnedEntityStores/BillOwnedEntityStore";
import { incomeOwnedEntityStore } from "../../../stores/OwnedEntityStores/IncomeOwnedEntityStore";
import { medicalCoverageOwnedEntityStore } from "../../../stores/OwnedEntityStores/MedicalCoverageOwnedEntityStore";
import {
  Asset,
  AssetInformation,
  NewAsset,
} from "../../../types/Application/Asset";
import { MedicalCoverage } from "../../../types/Application/MedicalCoverage";
import { IncomeInformation } from "../../../types/Application/IncomeInformation";
import { MedicalCoveragesFormCard } from "./MedicalCoverage/MedicalCoveragesFormCard";

export const applicationFormCards = {
  "primary-information": (application?: Application) => (
    <PrimaryInformationCard
      application={application}
      shouldBlockNavigation={true}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "primary-information", "form")
      }
    />
  ),
  "secondary-information": (application?: Application) => (
    <SecondaryInformationCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "secondary-information")
      }
    />
  ),
  "medical-information": (application?: Application) => (
    <MedicalInformationCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "medical-information")
      }
    />
  ),
  "spouse-information": (application?: Application) => (
    <SpouseInformationCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "spouse-information")
      }
    />
  ),
  "date-information": (application?: Application) => (
    <DateInformationCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "date-information", "form")
      }
    />
  ),
  "legal-information": (application?: Application) => (
    <LegalInformationCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "legal-information")
      }
    />
  ),
  "income-information": (application?: Application) => (
    <>
      <IncomeInformationCard
        application={application}
        canEdit={!!applicationStore.applicationCanBeEdited}
        submitForm={async (form: IncomeInformation) =>
          await incomeOwnedEntityStore.saveLocalIncomes().then(async () => {
            await applicationStore.updateApplication(
              form,
              "income-information"
            );
          })
        }
      />
    </>
  ),
  income: () => <></>,
  "bill-information": (application?: Application) => (
    <>
      <BillInformationCard
        application={application}
        canEdit={!!applicationStore.applicationCanBeEdited}
        submitForm={async (form) =>
          await billOwnedEntityStore.saveLocalBills().then(async () => {
            await applicationStore.updateApplication(form, "bill-information");
          })
        }
      />
    </>
  ),
  bill: () => <></>,
  "medical-coverage": (application?: Application) => (
    <MedicalCoveragesFormCard
      medicalCoverages={application?.medicalCoverages}
      canEdit={!!applicationStore.applicationCanBeEdited}
      createAndUpdateMedicalCoverages={async () =>
        await medicalCoverageOwnedEntityStore.saveLocalMedicalCoverages()
      }
      deleteMedicalCoverage={async (medicalCoverage: MedicalCoverage) => {
        await medicalCoverageOwnedEntityStore.saveLocalMedicalCoverages();

        medicalCoverageOwnedEntityStore.removeLocalMedicalCoverage(
          medicalCoverage
        );
        await applicationStore.deleteApplicationOwnedEntity(
          medicalCoverage.id,
          "medical-coverage"
        );
      }}
      getMedicalCoverageComputedState={
        applicationApiClient.getMedicalCoverageComputedState
      }
      applicationId={application?.id}
      showAddButton={true}
      showCancelButton={false}
    />
  ),
  contact: (application?: Application) => (
    <ApplicationContactCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      createContact={(form) =>
        applicationStore.createApplicationOwnedEntity({
          page: "contact",
          ownedEntity: form,
        })
      }
      updateContact={(form) =>
        applicationStore.updateApplicationOwnedEntity({
          page: "contact",
          ownedEntity: form,
          ownedEntityId: form.id,
        })
      }
      deleteContact={(form) =>
        applicationStore.deleteApplicationOwnedEntity(form.id, "contact")
      }
    />
  ),
  asset: (application?: Application) => (
    <AssetInformationCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={async (form: AssetInformation) =>
        await assetOwnedEntityStore.saveLocalAssets().then(async () => {
          await applicationStore.updateApplication(form, "asset-information");
        })
      }
      createAsset={async (asset: NewAsset) => {
        await assetOwnedEntityStore.saveLocalAssets();
        //DEV Note - Becasue we Save Assets Directly On Save
        // We have to explicitly call create because there is no
        // "local" instance of new asset
        await applicationStore.createApplicationOwnedEntity({
          page: "asset",
          ownedEntity: asset,
        });
      }}
      updateAsset={async () => await assetOwnedEntityStore.saveLocalAssets()}
      deleteAsset={async (asset: Asset) => {
        await assetOwnedEntityStore.saveLocalAssets();
        assetOwnedEntityStore.removeLocalAsset(asset);
        await applicationStore.deleteApplicationOwnedEntity(asset.id, "asset");
      }}
    />
  ),
  "asset-information": () => null,
  signatures: (application?: Application) => (
    <SignaturesCard
      application={application}
      canEdit={!!applicationStore.applicationCanBeEdited}
      submitForm={(signatures) =>
        applicationStore.updateApplicationSignatures(signatures)
      }
    />
  ),
  "state-information": () => null,
  checklist: (application?: Application) => (
    <ChecklistCard
      canCreate={
        !!applicationStore.canCreateChecklist ||
        !!applicationStore.applicationCanBeEdited
      }
      canUserGenerateChecklist={
        !!applicationStore.canGenerateChecklist ||
        !!applicationStore.applicationCanBeEdited
      }
      canSplitChecklist={
        !!applicationStore.canSplitChecklist ||
        !!applicationStore.applicationCanBeEdited
      }
      applicationId={application?.id}
      hasGeneratedChecklist={!!application?.hasGeneratedChecklist}
      canGenerateChecklist={!!application?.canGenerateChecklist}
      unresolvedChecklistGenerationDependencies={
        application?.unresolvedChecklistGenerationDependencies
      }
      user={userStore.user}
    />
  ),
  "fia-requests": (application?: Application) =>
    application && (
      <ApplicationFIARequestsView
        canCreate={
          !!userStore.user?.canCreateFIARequest &&
          !!applicationStore.applicationCanBeEdited
        }
        canInvokeRecordClick={
          !!userStore.user?.canViewFIARequest &&
          !!applicationStore.applicationCanBeEdited
        }
        applicationId={application?.id}
        parentClassComponent="application-form"
      />
    ),
  comments: (application?: Application) => (
    <CommentsCard
      applicationId={application?.id}
      canCreate={
        !!userStore.user?.canCreateComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canDeleteAllComment={
        !!userStore.user?.canDeleteComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canEditAllComment={
        !!userStore.user?.canEditAllComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canDeleteSelfComment={
        !!userStore.user?.canDeleteSelfComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canEditSelfComment={
        !!userStore.user?.canEditSelfComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canPinComment={
        !userStore.user?.isExternal &&
        !!applicationStore.applicationCanBeEdited &&
        userStore.user?.role !== undefined &&
        userStore.user?.canPinComment
      }
    />
  ),
  "audit-log": (application?: Application) => (
    <AuditLogCard applicationId={application?.id} />
  ),
};

export const applicationPagePrimaryCards = {
  "primary-information": (application?: Application) => (
    <PrimaryInformationCard
      application={application}
      shouldBlockNavigation={true}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "primary-information")
      }
    />
  ),
  "secondary-information": (application?: Application) => (
    <SecondaryInformationCard
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "secondary-information")
      }
    />
  ),
  "medical-information": (application?: Application) => (
    <MedicalInformationPageCard
      canEdit={
        !!userStore.user?.canEditApplicationPageMedicalInformation &&
        !!applicationStore.applicationCanBeEdited
      }
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "medical-information")
      }
    />
  ),
  "spouse-information": (application?: Application) => (
    <SpouseInformationPageCard
      canEdit={
        !!userStore.user?.canEditApplicationPageSpouse &&
        !!applicationStore.applicationCanBeEdited
      }
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "spouse-information")
      }
    />
  ),
  "date-information": (application?: Application) => (
    <DateInformationCard
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "date-information")
      }
    />
  ),
  "legal-information": (application?: Application) => (
    <LegalInformationPageCard
      canEdit={
        !!userStore.user?.canEditApplicationPageLegalInformation &&
        !!applicationStore.applicationCanBeEdited
      }
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "legal-information")
      }
    />
  ),
  "income-information": (application?: Application) => (
    <IncomeInformationPageCard
      application={application}
      canEdit={
        !!userStore.user?.canEditApplicationPageIncome &&
        !!applicationStore.applicationCanBeEdited
      }
      submitForm={async (form) => {
        await incomeOwnedEntityStore.saveLocalIncomes().then(async () => {
          await applicationStore.updateApplication(form, "income-information");
        });
      }}
    />
  ),
  income: () => <></>,
  "bill-information": (application?: Application) => (
    <>
      <BillInformationPageCard
        application={application}
        canEdit={
          !!userStore.user?.canEditApplicationPageBill &&
          !!applicationStore.applicationCanBeEdited
        }
        submitForm={async (form) =>
          await billOwnedEntityStore.saveLocalBills().then(async () => {
            await applicationStore.updateApplication(form, "bill-information");
          })
        }
      />
    </>
  ),
  bill: () => <></>,
  "medical-coverage": (application?: Application) => (
    <MedicalCoveragesPageCard
      application={application}
      canCreate={
        !!userStore.user?.canCreateApplicationPageMedicalCoverage &&
        !!applicationStore.applicationCanBeEdited
      }
      canEdit={
        !!userStore.user?.canEditApplicationPageMedicalCoverage &&
        !!applicationStore.applicationCanBeEdited
      }
      createAndUpdateMedicalCoverages={async () =>
        await medicalCoverageOwnedEntityStore.saveLocalMedicalCoverages()
      }
      deleteMedicalCoverage={async (medicalCoverage) => {
        await medicalCoverageOwnedEntityStore.saveLocalMedicalCoverages();

        medicalCoverageOwnedEntityStore.removeLocalMedicalCoverage(
          medicalCoverage
        );
        await applicationStore.deleteApplicationOwnedEntity(
          medicalCoverage.id,
          "medical-coverage"
        );
      }}
      getMedicalCoverageComputedState={
        applicationApiClient.getMedicalCoverageComputedState
      }
    />
  ),
  contact: (application?: Application) => (
    <ApplicationPageContactCard
      application={application}
      canCreateContact={
        !!userStore.user?.canCreateApplicationPageContact &&
        !!applicationStore.applicationCanBeEdited
      }
      canEditContact={
        !!userStore.user?.canEditApplicationPageContact &&
        !!applicationStore.applicationCanBeEdited
      }
      canDeleteContact={
        !!userStore.user?.canEditApplicationPageContact &&
        !!applicationStore.applicationCanBeEdited
      }
      submitForm={(form) =>
        applicationStore.updateApplication(form, "primary-information")
      }
      createContact={(form) =>
        applicationStore.createApplicationOwnedEntity({
          page: "contact",
          ownedEntity: form,
        })
      }
      updateContact={(form) =>
        applicationStore.updateApplicationOwnedEntity({
          page: "contact",
          ownedEntity: form,
          ownedEntityId: form.id,
        })
      }
      deleteContact={(form) =>
        applicationStore.deleteApplicationOwnedEntity(form.id, "contact")
      }
    />
  ),
  asset: (application?: Application) => (
    <AssetInformationPageCard
      application={application}
      canEdit={
        !!userStore.user?.canEditApplicationPageAsset &&
        !!applicationStore.applicationCanBeEdited
      }
      canCreateAsset={
        !!userStore.user?.canCreateApplicationPageAsset &&
        !!applicationStore.applicationCanBeEdited
      }
      submitForm={async (form) =>
        await assetOwnedEntityStore.saveLocalAssets().then(async () => {
          await applicationStore.updateApplication(form, "asset-information");
        })
      }
      createAsset={async (asset) => {
        await assetOwnedEntityStore.saveLocalAssets();
        //DEV Note - Becasue we Save Assets Directly On Save
        // We have to explicitly call create because there is no
        // "local" instance of new asset
        await applicationStore.createApplicationOwnedEntity({
          page: "asset",
          ownedEntity: asset,
        });
      }}
      updateAsset={async () => await assetOwnedEntityStore.saveLocalAssets()}
      deleteAsset={async (asset) => {
        await assetOwnedEntityStore.saveLocalAssets();
        assetOwnedEntityStore.removeLocalAsset(asset);
        await applicationStore.deleteApplicationOwnedEntity(asset.id, "asset");
      }}
    />
  ),
  "asset-information": () => null,
  signatures: (application?: Application) =>
    application && (
      <SignaturesPageCard
        canEdit={
          !!userStore.user?.canEditApplicationPageSignature &&
          !!applicationStore.applicationCanBeEdited
        }
        application={application}
        submitForm={(signatures) =>
          applicationStore.updateApplicationSignatures(signatures)
        }
      />
    ),
  "state-information": () => null,
  checklist: (application?: Application) => (
    <ChecklistCard
      canCreate={
        !!applicationStore.canCreateChecklist ||
        !!applicationStore.applicationCanBeEdited
      }
      canUserGenerateChecklist={
        !!applicationStore.canGenerateChecklist &&
        !!applicationStore.applicationCanBeEdited
      }
      canSplitChecklist={
        !!applicationStore.canSplitChecklist &&
        !!applicationStore.applicationCanBeEdited
      }
      applicationId={application?.id}
      hasGeneratedChecklist={!!application?.hasGeneratedChecklist}
      canGenerateChecklist={!!application?.canGenerateChecklist}
      unresolvedChecklistGenerationDependencies={
        application?.unresolvedChecklistGenerationDependencies
      }
      user={userStore.user}
    />
  ),
  "fia-requests": (application?: Application) =>
    application && (
      <ApplicationFIARequestsView
        canCreate={
          !!userStore.user?.canCreateFIARequest &&
          !!applicationStore.applicationCanBeEdited
        }
        canInvokeRecordClick={
          !!userStore.user?.canViewFIARequest &&
          !!applicationStore.applicationCanBeEdited
        }
        applicationId={application?.id}
        parentClassComponent="application-page"
      />
    ),
  comments: (application?: Application) => (
    <CommentsCard
      applicationId={application?.id}
      canCreate={
        !!applicationStore.canCreateComment ||
        !!applicationStore.applicationCanBeEdited
      }
      canDeleteAllComment={
        !!userStore.user?.canDeleteComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canEditAllComment={
        !!userStore.user?.canEditAllComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canDeleteSelfComment={
        !!userStore.user?.canDeleteSelfComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canEditSelfComment={
        !!userStore.user?.canEditSelfComment &&
        !!applicationStore.applicationCanBeEdited
      }
      canPinComment={
        !userStore.user?.isExternal &&
        !!applicationStore.applicationCanBeEdited &&
        userStore.user?.role !== undefined &&
        userStore.user.canPinComment
      }
    />
  ),
  "audit-log": (application?: Application) => (
    <AuditLogCard applicationId={application?.id} />
  ),
};

export const applicationPageCards = {
  "primary-information": (application?: Application) => (
    <FileInformationPageCard
      canEdit={
        !!userStore.user?.canEditApplicationPageFileInformation &&
        !!applicationStore.applicationCanBeEdited
      }
      application={application}
      shouldBlockNavigation={true}
      submitForm={(form) =>
        applicationStore.updateApplication(
          form,
          "primary-information",
          "file-information"
        )
      }
    />
  ),
  "state-information": (application?: Application) => (
    <StateInformationCard
      canEdit={
        !!userStore.user?.canEditApplicationPageStateInformation &&
        !!applicationStore.applicationCanBeEdited
      }
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "state-information")
      }
    />
  ),
  "date-information": (application?: Application) => (
    <DateInformationPageCard
      canEdit={applicationStore.applicationDateInformationCanBeEdited}
      application={application}
      submitForm={(form) =>
        applicationStore.updateApplication(form, "date-information", "page")
      }
    />
  ),
};
