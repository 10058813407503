import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  AllFields,
  Option,
  SelectFieldValue,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import {
  HelpCenterTopic,
  topicSectionTypeConfig,
} from "../../HelpCenter/types";
import { helpCenterTopicContentBuilder } from "./helpCenterTopicContentBuilder";
import { SwitchFieldValue as UclSwitchFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";

const modelName = "helpCenterTopic";

const helpCenterTopicFormSchemaFields = {
  title: (
    onValueChange: (field: {}) => void,
    topic: HelpCenterTopic,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "title"),
      errorMessages: get(errors, "title"),
      value: topic.title,
      onSubmit: (title) => {
        onValueChange({ ...topic, title });
      },
      isRequired: true,
    } as AllFields;
  },
  externalLink: (
    onValueChange: (field: {}) => void,
    topic: HelpCenterTopic,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "externalLink"),
      width: "39%",
      errorMessages: get(errors, "externalLink"),
      isRequired: topicSectionTypeConfig[topic.sectionType].requireExternalLink,
      value: topic.externalLink,
      onSubmit: (externalLink) => {
        onValueChange({ ...topic, externalLink });
      },
    } as AllFields;
  },
  usState: (
    onValueChange: (field: {}) => void,
    topic: HelpCenterTopic,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "usState"),
      width: "20%",
      errorMessages: get(errors, "usState"),
      isRequired: true,
      value: topic.usState as SelectFieldValue,
      shouldShowClearButton: true,
      onSubmit: async (option: Option) => {
        if (!!option) {
          onValueChange({ ...topic, ...{ usState: option.value } });
        } else {
          onValueChange({ ...topic, ...{ usState: undefined } });
        }
      },
    } as AllFields;
  },
  isUsStateQuickGuide: (
    onValueChange: (field: {}) => void,
    topic: HelpCenterTopic,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "isUsStateQuickGuide"),
      type: "UclSwitch",
      width: "20%",
      errorMessages: get(errors, "isUsStateQuickGuide"),
      value: topic.isUsStateQuickGuide,
      onSubmit: (isUsStateQuickGuide: UclSwitchFieldValue) => {
        onValueChange({ ...topic, isUsStateQuickGuide });
      },
      isRequired: true,
    } as AllFields;
  },
  isFeedAllowed: (
    onValueChange: (field: {}) => void,
    topic: HelpCenterTopic,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "isFeedAllowed"),
      type: "UclSwitch",
      width: "20%",
      errorMessages: get(errors, "isFeedAllowed"),
      value: topic.isFeedAllowed,
      onSubmit: (isFeedAllowed: UclSwitchFieldValue) => {
        onValueChange({ ...topic, isFeedAllowed });
      },
      isRequired: true,
    } as AllFields;
  },
  content: (
    onValueChange: (field: {}) => void,
    topic: HelpCenterTopic,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "Custom",
      form: topic,
      errorMessages: get(errors, "content"),
      value: topic.content,
      isRequired: topicSectionTypeConfig[topic.sectionType].requireContent,
      onSubmit: (content: HelpCenterTopic) => {
        onValueChange(content);
      },
      renderer: helpCenterTopicContentBuilder,
    } as AllFields;
  },
};

export const createHelpCenterTopicFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const topic = form as HelpCenterTopic;
  const result: AllFields[] = [
    helpCenterTopicFormSchemaFields.title(onValueChange, topic, errors),
    helpCenterTopicFormSchemaFields.externalLink(onValueChange, topic, errors),
  ];

  if (topicSectionTypeConfig[topic.sectionType].requireUsState) {
    result.push(
      ...[
        helpCenterTopicFormSchemaFields.usState(onValueChange, topic, errors),
        helpCenterTopicFormSchemaFields.isUsStateQuickGuide(
          onValueChange,
          topic,
          errors
        ),
      ]
    );
  }

  result.push(
    ...[
      helpCenterTopicFormSchemaFields.isFeedAllowed(
        onValueChange,
        topic,
        errors
      ),
      helpCenterTopicFormSchemaFields.content(onValueChange, topic, errors),
    ]
  );

  return result;
};

export const updateHelpCenterTopicFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const topic = form as HelpCenterTopic;
  const result: AllFields[] = [
    helpCenterTopicFormSchemaFields.title(onValueChange, topic, errors),
    helpCenterTopicFormSchemaFields.externalLink(onValueChange, topic, errors),
  ];

  if (topicSectionTypeConfig[topic.sectionType].requireUsState) {
    result.push(
      ...[
        helpCenterTopicFormSchemaFields.usState(onValueChange, topic, errors),
        helpCenterTopicFormSchemaFields.isUsStateQuickGuide(
          onValueChange,
          topic,
          errors
        ),
      ]
    );
  }

  result.push(
    ...[
      helpCenterTopicFormSchemaFields.isFeedAllowed(
        onValueChange,
        topic,
        errors
      ),
      helpCenterTopicFormSchemaFields.content(onValueChange, topic, errors),
    ]
  );

  return result;
};
