import { AddressFieldValue } from "../../components/Fields/types/fieldTypes";
import { ValidGovernmentId } from "../../components/Forms/types";
import { ExistingResource } from "../ExistingResource";

export interface SpouseContact extends ValidGovernmentId {
  id?: string;
  scheduleMappingValues: string[];
  firstName: string;
  lastName: string;
  middleName: string;
  maidenName: string;
  contactType: number;
  dateOfBirth?: Date;
  socialSecurityNumber: string;
  email: string;
  phoneNumber: string;
  additionalPhoneNumber: string;
  preferredContactMethod: number;
  address?: AddressFieldValue;
  race: number;
  ethnicity: number;
  countryOfBirth: number;
  isUsCitizen?: boolean;
  preferredLanguages: number;
  gender: number;
  isPersonallyKnownToNotary?: boolean;
  canEditSpousePersonalInformation: boolean;
}

export enum ContactTypes {
  Witness = 1,
  ContactOnly = 2,
}

export type ExistingSpouseContact = ExistingResource<SpouseContact>;
