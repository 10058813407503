import {
  Application,
  ApplicationFormPage,
  ApplicationStatuses,
  NewApplication,
} from "../../../types/Application";
import {
  MedicalCoverage,
  NewMedicalCoverage,
} from "../../../types/Application/MedicalCoverage";
import { PrimaryInformation } from "../../../types/Application/PrimaryInformation";
import { SecondaryInformation } from "../../../types/Application/SecondaryInformation";
import { MedicalInformation } from "../../../types/Application/MedicalInformation";
import { DateInformation } from "../../../types/Application/DateInformation";
import baseApiClient from "../baseApiClient";
import { ApplicationContact } from "../../../types/Application/ApplicationContact";
import { LegalInformation } from "../../../types/Application/LegalInformation";
import { AssetInformation } from "../../../types/Application/Asset";
import { BillInformation } from "../../../types/Application/BillInformation";
import { IncomeInformation } from "../../../types/Application/IncomeInformation";
import { SpouseInformation } from "../../../types/Application/SpouseInformation";
import { Signatures } from "../../../types/Application/Signatures";
import { NewBill } from "../../../types/Application/Bill";
import { StateInformation } from "../../../types/Application/StateInformation";
import {
  ApplicationChild,
  ApplicationChildRead,
} from "../../../types/Application/ApplicationChild";
import { ExistingMonitor, Monitor } from "../../../types/Monitor";
import { ApplicationSummaryResponseDTO } from "../../../types/Application/ApplicationSummary";
import {
  SignatureHolderReadDTO,
  SignaturesValidationDetailsDTO,
} from "../../../types/Application/Signature";
import { EligibilitySummaryResponseDTO } from "../../../types/Application/EligibilitySummary";
import { FinancialEligibilityConversation } from "../../../types/Application/FinancialEligibilityConversation";
import { DocumentFilingCreate } from "../../../components/DocumentFiling/types";

class ApplicationApiClient {
  createApplication = async (application: NewApplication) => {
    return baseApiClient.post<Application>("application", application);
  };

  updateApplication = async (
    id: string,
    application:
      | PrimaryInformation
      | SecondaryInformation
      | MedicalInformation
      | SpouseInformation
      | DateInformation
      | LegalInformation
      | AssetInformation
      | IncomeInformation
      | Signatures
      | BillInformation
      | StateInformation,
    page: ApplicationFormPage,
    pageSource: string | undefined = undefined
  ) => {
    return !pageSource
      ? baseApiClient.put<Application>(`application/${id}/${page}`, application)
      : baseApiClient.put<Application>(
          `application/${id}/${page}/${pageSource}`,
          application
        );
  };

  updateApplicationFacility = async (
    id: string,
    application: PrimaryInformation
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/facility`,
      application
    );
  };

  updateApplicationFinancialEligibilityConversation = async (
    id: string,
    application: FinancialEligibilityConversation
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/financial-eligibility-conversation`,
      application
    );
  };

  createApplicationOwnedEntity = async (
    id: string,
    ownedEntity: NewMedicalCoverage | ApplicationContact | NewBill | Signatures,
    page: ApplicationFormPage
  ) => {
    return baseApiClient.post<Application>(
      `application/${id}/${page}`,
      ownedEntity
    );
  };

  updateApplicationOwnedEntity = async (
    id: string,
    ownedEntityId: string,
    ownedEntity: NewMedicalCoverage | ApplicationContact | NewBill | Signatures,
    page: ApplicationFormPage
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/${page}/${ownedEntityId}`,
      ownedEntity
    );
  };

  getApplicationSignatures = async (
    id: string
  ): Promise<SignatureHolderReadDTO[]> => {
    return baseApiClient.get<SignatureHolderReadDTO[]>(
      `application/${id}/signatures`
    );
  };

  getApplicationSignaturesValidationDetails = async (
    id: string,
    signatures: SignatureHolderReadDTO[]
  ): Promise<SignaturesValidationDetailsDTO> => {
    return baseApiClient.post<SignaturesValidationDetailsDTO>(
      `application/${id}/signatures/validation-details`,
      signatures
    );
  };

  updateApplicationSignatures = async (
    id: string,
    signatures: SignatureHolderReadDTO[]
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/signatures`,
      signatures
    );
  };

  deleteApplicationOwnedEntity = async (
    id: string,
    ownedEntityId: string,
    page: ApplicationFormPage
  ) => {
    return baseApiClient.delete<Application>(
      `application/${id}/${page}/${ownedEntityId}`
    );
  };

  withdrawApplication = async (id: string, withdrawnReason: number) => {
    return baseApiClient.put<Application>(
      `application/${id}/withdrawn`,
      withdrawnReason
    );
  };

  reactivateApplication = async (id: string) => {
    return baseApiClient.put<Application>(`application/${id}/reactivation`);
  };

  updateApplicationStatus = async (id: string, status: ApplicationStatuses) => {
    return baseApiClient.put<Application>(`application/${id}/status`, {
      status,
    });
  };

  convertApplicationToRecert = async (id: string) => {
    return baseApiClient.put<Application>(
      `application/${id}/convert-to-recert`
    );
  };

  getApplicationById = (id: string) => {
    return baseApiClient.get<Application>(`application/${id}`);
  };

  evaluateApplicationAMDDecision = async (
    id: string,
    triggerIdentifier: string
  ) => {
    return baseApiClient.post<Application>(
      `application/${id}/evaluate-amd-decision`,
      {
        params: {
          triggerIdentifier: triggerIdentifier,
        },
      }
    );
  };

  overrideAssetEligibility = async (
    id: string,
    overrideAssetEligibility: boolean
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/override-asset-eligibility`,
      { overrideAssetEligibility }
    );
  };
  getMedicalCoverageComputedState = (value: Partial<MedicalCoverage>) => {
    return baseApiClient.post<Partial<MedicalCoverage>>(
      `application/medical-coverage/state`,
      value
    );
  };

  checkIfMatchupExistsWithSSN = (applicationId: string) => {
    return baseApiClient.get<boolean>(
      `application/${applicationId}/matchup-exists`
    );
  };

  createApplicationChild = (
    applicationParentId: string,
    childApplication: ApplicationChild
  ) => {
    return baseApiClient.post<Application>(
      `application/${applicationParentId}/child-application`,
      childApplication
    );
  };

  getRelatedApplicationChildren = async (id: string) => {
    return baseApiClient.get<ApplicationChildRead[]>(
      `application/${id}/relations`
    );
  };

  createApplicationMonitor = async (id: string, monitorToCreate: Monitor) => {
    return baseApiClient.post<Application>(
      `application/${id}/monitor`,
      monitorToCreate
    );
  };

  updateApplicationMonitor = async (
    id: string,
    monitorId: string,
    monitorToCreate: ExistingMonitor
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/monitor/${monitorId}`,
      monitorToCreate
    );
  };

  deleteApplicationMonitor = async (id: string, monitorId: string) => {
    return baseApiClient.delete<Application>(
      `application/${id}/monitor/${monitorId}`
    );
  };

  deleteApplicationMonitorWithEndDate = async (
    id: string,
    monitorToDelete: ExistingMonitor
  ) => {
    return baseApiClient.put<Application>(
      `application/${id}/monitor/${monitorToDelete.id}/delete`,
      monitorToDelete
    );
  };

  getStateDocumentPackage = async (
    applicationId: string,
    shouldGenerateChecklistItems: boolean
  ) => {
    return baseApiClient.post(
      `application/${applicationId}/state-document-package`,
      { shouldGenerateChecklistItems },
      {
        headers: { "Content-Type": "application/json" },
        responseType: "blob",
      }
    );
  };

  createStateNoticeFiling = async (
    applicationId: string,
    documentFilingCreate: DocumentFilingCreate
  ) => {
    return baseApiClient.post(
      `application/${applicationId}/filing`,
      documentFilingCreate
    );
  };

  checkForRecentDuplicateApplicationDocuments = async (
    applicationId: string,
    documentId: string
  ) => {
    return baseApiClient.get<boolean>(
      `application/${applicationId}/document/${documentId}/duplicate`
    );
  };

  getApplicationSummary = async (
    id: string
  ): Promise<ApplicationSummaryResponseDTO> => {
    return baseApiClient.get<ApplicationSummaryResponseDTO>(
      `application/${id}/summary`
    );
  };

  getExternalUserApplicationSummary = async (
    id: string
  ): Promise<ApplicationSummaryResponseDTO> => {
    return baseApiClient.get<ApplicationSummaryResponseDTO>(
      `application/${id}/external-user-summary`
    );
  };

  submitApplicationToState = async (
    applicationId: string,
    comment?: string
  ): Promise<Application> => {
    return baseApiClient.put<Application>(
      `application/${applicationId}/state-submission`,
      { comment }
    );
  };

  getPageMissingFieldCount = async (
    applicationId: string
  ): Promise<
    {
      pageName: ApplicationFormPage;
      missingFieldCount: number;
    }[]
  > => {
    return baseApiClient.get<
      {
        pageName: ApplicationFormPage;
        missingFieldCount: number;
      }[]
    >(`application/${applicationId}/missing-field-counter`);
  };

  getEligibilitySummary = async (
    applicationId: string
  ): Promise<EligibilitySummaryResponseDTO> => {
    return baseApiClient.get<EligibilitySummaryResponseDTO>(
      `application/${applicationId}/eligibility-summary`
    );
  };
}

export const applicationApiClient = new ApplicationApiClient();
