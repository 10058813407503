import { first, get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { SignatureHolderReadDTO } from "../../../types/Application/Signature";
import { parseDateToDateString } from "../../../utils/date";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const signatureFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  _formId,
  onFormSubmit
): AllFields[] => {
  const modelName = "signature";
  const signature = form as SignatureHolderReadDTO;
  const timestamp = !!signature.timestamp
    ? ` ${parseDateToDateString(signature.timestamp)}`
    : "";

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isMark"),
      className: "signature-is-mark",
      label: `Is ${first(signature.types_AsString)} Signing with a Mark?`,
      description: `Click here if the ${first(
        signature.types_AsString
      )?.toLocaleLowerCase()}  is signing with an 'X'`,
      value: signature.isMark,
      onSubmit: (isMark) => {
        onValueChange({ isMark });
        if (!!onFormSubmit) {
          onFormSubmit({ isMark });
        }
      },
      disabled: signature.isDisabled,
      errorMessages: get(errors, "isMark"),
      hidden: !signature.canSetIsMark,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signatureBase64"),
      label: (
        <span className="signature-label">
          <span className="signature-label-title">{`${signature.signatureHolderName} ${timestamp}`}</span>
          <span className="signature-label-description">
            {`${signature.types_AsString.join(" / ")}`}
          </span>
        </span>
      ),
      disabled: signature.isDisabled,
      value: signature.signatureBase64 || "",
      onSubmit: (signatureBase64) => {
        onValueChange({ signatureBase64 });
        if (!!onFormSubmit) {
          onFormSubmit({ signatureBase64 });
        }
      },
      errorMessages: get(errors, "signatureBase64"),
    } as AllFields,
  ];
};
