import { FC } from "react";
import RelativityCard from "../../Card/RelativityCard";
import {
  FIARequest,
  FIARequestFollowUpActionTypes,
} from "../../FIARequests/configurations/types";
import { VerifyFIARequestSubCard } from "../../FIARequests/VerifyFIARequestSubCard";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { EnumSingleSelectField } from "@ucl/library";
import { useFiaRequestVerify } from "./useFIARequestVerify";
import { DocumentVerifyDTO } from "../../../types/Document";

export interface FIARequestVerifyComponentProps {
  checklistItemId?: string;
  documentId?: string;
  fiaRequests: FIARequest[];
  documentVerifyDTO: DocumentVerifyDTO;
  setDocumentVerifyDTO: (documentVerifyDTO: DocumentVerifyDTO) => void;
}

export const FIARequestVerifyComponent: FC<FIARequestVerifyComponentProps> = (
  props
) => {
  const { getFiaRequestFollowUpReasonOptions, getFIAFollowUpActions } =
    useFiaRequestVerify();

  return (
    <RelativityCard
      className="verify-fia-panel"
      headerContent={
        <WithTooltip
          shouldShowTooltip={true}
          content={
            <>
              <div>&bull; Only submitted FIA requests are shown.</div>
              <div>
                &bull; If document satisfies FIA requirements, mark FIA requests
                as complete.
              </div>
              <div>
                &bull; If document does not satisfy FIA requirements. Resend FIA
                request(s) and mark a follow up reason. Note that FIA requests
                to an institution with send method of Mail must be resent
                manually
              </div>
            </>
          }
        >
          <div className="fia-details-tooltip">
            {"FIA Request Follow Up"}
            <Icon icon={IconNames.InfoSign} />
          </div>
        </WithTooltip>
      }
    >
      {props.fiaRequests?.map((fiaRequest, index) => (
        <VerifyFIARequestSubCard key={index} fiaRequest={fiaRequest} />
      ))}
      <div className="fia-follow-up-actions">
        <EnumSingleSelectField
          type="EnumSingleSelect"
          label="Complete or Resend FIA Requests"
          optionValues={getFIAFollowUpActions(props.fiaRequests)}
          value={props.documentVerifyDTO.fiaRequestAction}
          onSubmit={(fiaRequestAction) => {
            props.setDocumentVerifyDTO({
              ...props.documentVerifyDTO,
              fiaRequestAction:
                fiaRequestAction as FIARequestFollowUpActionTypes,
              followUpReason:
                fiaRequestAction === FIARequestFollowUpActionTypes.MarkComplete
                  ? undefined
                  : props.documentVerifyDTO.followUpReason,
            });
          }}
        />
        {props.documentVerifyDTO.fiaRequestAction ===
          FIARequestFollowUpActionTypes.Resend && (
          <EnumSingleSelectField
            className="follow-up-reason-field"
            type="EnumSingleSelect"
            label="Follow Up Reason"
            optionValues={getFiaRequestFollowUpReasonOptions()}
            value={props.documentVerifyDTO.followUpReason}
            onSubmit={(followUpReason) =>
              props.setDocumentVerifyDTO({
                ...props.documentVerifyDTO,
                followUpReason: Number(followUpReason),
              })
            }
            isRequired={true}
          />
        )}
      </div>
    </RelativityCard>
  );
};
