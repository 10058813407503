import React, { useRef } from "react";
import { Document } from "../../types/Document";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import "./documentPagesViewerStyles.scss";
import { ThumbnailImagesData } from "./PdfDocumentStore";
import { ButtonProps } from "@blueprintjs/core";
import { forEach, has, isEmpty, isUndefined } from "lodash";
import { DocumentPagesViewerItemModule } from "./DocumentPagesViewerItem";

export type DocumentThumbnailsProperties = Pick<
  ThumbnailImagesData,
  "documentId" | "pageId" | "classNames" | "selectionOrder" | "rotation"
>;

export enum SupportedDocumentPageActionTypes {
  Editor = 3,
  EditorDisabled = 4,
}

export enum DocumentActionTypes {
  Preview = 1,
  OpenInNewTab = 2,
  Refresh = 3,
  SelectAll = 4,
  Rotate = 5,
}

export interface DocumentAction<T extends object>
  extends Omit<
    ButtonProps,
    "onClick" | "onMouseEnter" | "onMouseLeave" | "minimal"
  > {
  name?: string;
  onActionClick?: (data: T) => void;
}

export interface DocumentActionRender<T extends object> {
  name?: string;
  render?: (data: T) => React.ReactNode;
}

export const useDocumentPagesViewer = (
  documents: Document[],
  documentThumbnailsProperties?: DocumentThumbnailsProperties[]
) => {
  const [ready, setReady] = React.useState<boolean>(false);
  const [documentThumbnails, setDocumentThumbnails] =
    React.useState<ThumbnailImagesData[]>();
  const [isSelectAll, setIsSelectAll] = React.useState<Record<string, boolean>>(
    {}
  );
  const [loadedDocumentThumbnails, setLoadedDocumentThumbnails] =
    React.useState<{
      [key: string]: {
        thumbnailImagesData: ThumbnailImagesData[];
      };
    }>({});
  const documentPagesViewerItemModule = useRef<DocumentPagesViewerItemModule[]>(
    []
  );

  // Since fetching is asyncronious we store it into its individual objects and as it is fetched it loads into the main list
  React.useEffect(() => {
    if (loadedDocumentThumbnails && !isEmpty(loadedDocumentThumbnails)) {
      const docThumbnails = [
        ...Object.entries(loadedDocumentThumbnails).flatMap(
          (x) => x[1].thumbnailImagesData as ThumbnailImagesData[]
        ),
      ];

      mergeDocumentThumbnailProperties(
        docThumbnails,
        documentThumbnailsProperties
      );
    }
  }, [loadedDocumentThumbnails]);

  const [selectedDocumentThumbnails, setSelectedDocumentThumbnails] =
    React.useState<ThumbnailImagesData[]>([]);

  React.useEffect(() => {
    if (selectedDocumentThumbnails) {
      // Set Is SelectAll when all pages on loaded document are selected
      forEach(loadedDocumentThumbnails, (value, key) => {
        const selected = selectedDocumentThumbnails.filter(
          (x) => x.documentId === key
        );
        if (selected.length === value.thumbnailImagesData.length) {
          isSelectAll[key] = true;
          setIsSelectAll({ ...isSelectAll });
        } else {
          isSelectAll[key] = false;
          setIsSelectAll({ ...isSelectAll });
        }
      });
    }
  }, [selectedDocumentThumbnails]);

  const fetchDocumentThumbnailsByDocumentIdx = (idx: number) => {
    const loadedDocument = [...documents].at(idx);

    // Check if loadedDocument and documentThumbnails with is documentId already exists, else fetch
    if (!loadedDocument) {
      return;
    }

    if (
      documentThumbnails &&
      documentThumbnails.some((x) => x.documentId === loadedDocument.id)
    ) {
      return;
    }

    if (
      loadedDocument &&
      !has(loadedDocumentThumbnails?.thumbnailImagesData, loadedDocument.id)
    ) {
      fetchDocumentThumbnails(loadedDocument);
    }
  };

  const fetchDocumentThumbnailsByDocumentId = (id: string) => {
    const loadedDocument = [...documents].find((x) => x.id === id);
    if (
      loadedDocument &&
      !has(loadedDocumentThumbnails?.thumbnailImagesData, loadedDocument.id)
    ) {
      fetchDocumentThumbnails(loadedDocument);
    }
  };

  const fetchDocumentThumbnails = (loadedDocument: Document) => {
    documentApiClient
      .getDocumentThumbnails(loadedDocument.id)
      .then((thumbnailImagesData) => {
        loadedDocumentThumbnails[loadedDocument.id] = { thumbnailImagesData };

        loadedDocumentThumbnails[loadedDocument.id].thumbnailImagesData.forEach(
          (x) => {
            x.originalRotation = x.rotation;
          }
        );

        setLoadedDocumentThumbnails({ ...loadedDocumentThumbnails });
      });
  };

  /** Only supported for now is classnames property */
  const mergeDocumentThumbnailProperties = (
    docThumbnails?: ThumbnailImagesData[],
    documentThumbnailsProperties?: DocumentThumbnailsProperties[]
  ) => {
    if (!docThumbnails) {
      return;
    }

    if (documentThumbnailsProperties) {
      docThumbnails.forEach((x) => {
        const properties = documentThumbnailsProperties.find(
          (y) => y.documentId === x.documentId && y.pageId === x.pageId
        );
        if (properties) {
          x.classNames = properties.classNames;
          if (!isUndefined(properties.selectionOrder)) {
            x.selectionOrder = properties.selectionOrder;
          }
          if (!isUndefined(properties.rotation)) {
            x.rotation = properties.rotation;
          }
        }
      });
    }

    setDocumentThumbnails([...docThumbnails]);
  };

  React.useEffect(() => {
    if (
      documentThumbnailsProperties &&
      documentThumbnailsProperties?.length >= 0
    ) {
      mergeDocumentThumbnailProperties(
        documentThumbnails,
        documentThumbnailsProperties
      );
    }
  }, [documentThumbnailsProperties]);

  React.useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    setReady(true);
  };

  const reorderSelection = (selection: ThumbnailImagesData[]) => {
    selection.forEach((x, idx) => {
      x.selectionOrder = idx + 1;
    });

    // unselect all
    if (documentThumbnails) {
      documentThumbnails.forEach((x) => {
        const selected = selection.find(
          (y) => y.documentId === x.documentId && y.pageId === x.pageId
        );

        x.selectionOrder = selected ? selected.selectionOrder : undefined;
      });

      mergeDocumentThumbnailProperties(documentThumbnails, documentThumbnails);
    }

    return selection;
  };

  const selectionChangedHandler = (
    docId: string,
    value: ThumbnailImagesData[]
  ) => {
    if (
      value.length <= 0 &&
      !selectedDocumentThumbnails.some((x) => x.documentId === docId)
    ) {
      return;
    }

    let newSelectedDocumentThumbnails = [...selectedDocumentThumbnails];

    // If no more values (remove all)
    if (
      value.length <= 0 &&
      newSelectedDocumentThumbnails.some((x) => x.documentId === docId)
    ) {
      setSelectedDocumentThumbnails((x) => {
        // Remove document from selections
        const newSelectedDocumentThumbnails = x.filter(
          (x) => x.documentId !== docId
        );
        // Reorder
        reorderSelection(newSelectedDocumentThumbnails);

        return newSelectedDocumentThumbnails;
      });

      return;
    }

    // Else

    // Remove if no longer exists
    newSelectedDocumentThumbnails
      .filter((x) => x.documentId === docId)
      .forEach((s) => {
        const isInExistingSelection = value
          .map((x) => x.pageId)
          .includes(s.pageId);

        if (!isInExistingSelection) {
          newSelectedDocumentThumbnails = newSelectedDocumentThumbnails.filter(
            (x) => !(x.documentId === s.documentId && x.pageId === s.pageId)
          );
        }
      });

    // Add if new
    value.forEach((v) => {
      const isInExistingSelection = newSelectedDocumentThumbnails
        .filter((x) => x.documentId === docId)
        .map((x) => x.pageId)
        .includes(v.pageId);

      if (!isInExistingSelection) {
        newSelectedDocumentThumbnails.push(v);
      }
    });

    // Reorder
    newSelectedDocumentThumbnails = reorderSelection(
      newSelectedDocumentThumbnails
    );

    setSelectedDocumentThumbnails([...newSelectedDocumentThumbnails]);
  };

  const unselectAll = () => {
    setSelectedDocumentThumbnails([]);
    documentPagesViewerItemModule.current?.forEach((x) => x.unselectAll());
  };

  return {
    ready,
    documentThumbnails,
    setDocumentThumbnails,
    selectedDocumentThumbnails,
    isSelectAll,
    selectionChangedHandler,
    fetchDocumentThumbnailsByDocumentIdx,
    fetchDocumentThumbnailsByDocumentId,
    documentPagesViewerItemModule,
    unselectAll,
  };
};
