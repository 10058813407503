import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  InfiniteScroll,
  Inject,
  Page,
  Reorder,
  Resize,
  Search,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import React from "react";
import { reactPlugin } from "../../AppInsights";
import { Document } from "../../types/Document";
import { invoiceDocumentHistoryGridColumnsConfiguration } from "./configurations/gridColumnsConfiguration";

export type UIInvoiceDocument = Document & {
  isCurrentInvoiceDocument: boolean;
};
export interface InvoiceDocumentHistoryGridProps {
  documents: UIInvoiceDocument[];
}

const InvoiceDocumentHistoryGridComponent: React.FC<
  InvoiceDocumentHistoryGridProps
> = ({ documents }) => {
  const gridRef = React.useRef<GridComponent | null>(null);

  return (
    <section className="board-grid-container">
      <div className="grid-container">
        <GridComponent
          ref={gridRef}
          className="grid"
          dataSource={documents}
          columns={invoiceDocumentHistoryGridColumnsConfiguration}
          allowReordering={false}
          allowResizing
          resizeSettings={{ mode: "Normal" }}
          pageSettings={{ pageSize: 20 }}
          allowExcelExport={false}
          allowSorting={false}
          allowFiltering={false}
        >
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Group,
              Toolbar,
              Reorder,
              Resize,
              InfiniteScroll,
              Search,
              ExcelExport,
            ]}
          />
        </GridComponent>
      </div>
    </section>
  );
};

export const InvoiceDocumentHistoryGrid = withAITracking(
  reactPlugin,
  InvoiceDocumentHistoryGridComponent,
  "InvoiceDocumentHistoryGrid",
  "AI-flex-container"
);
