import { makeObservable, observable } from "mobx";
import { ExistingInstitution, Institution } from "../types/Institution";
import { institutionApiClient } from "../lib/apiClients/institution/institutionApiClient";
import {
  ExistingInstitutionContact,
  InstitutionContact,
} from "../types/InstitutionContact";

import {
  OrderedDocumentCreateDTO,
  OrderedDocumentReorderDTO,
} from "../components/OrderedDocumentsGrid/configurations/types";

export class InstitutionStore {
  public institution: ExistingInstitution | null = null;

  constructor() {
    makeObservable(this, {
      institution: observable,
    });
  }

  createInstitution = async (institutionToCreate: Institution) => {
    this.institution = await institutionApiClient.createInstitution(
      institutionToCreate
    );
  };

  getInstitutionById = async (id: string) => {
    this.institution = await institutionApiClient.getInstitutionDetail(id);
  };

  unsetInstitution = () => {
    this.institution = null;
  };

  updateInstitution = async (institutionToUpdate: ExistingInstitution) => {
    if (!this.institution) {
      return;
    }

    this.institution = await institutionApiClient.updateInstitution(
      this.institution.id,
      institutionToUpdate
    );
  };

  createInstitutionContact = async (contactToCreate: InstitutionContact) => {
    if (!this.institution) {
      return;
    }

    this.institution = await institutionApiClient.createInstitutionContact(
      this.institution.id,
      contactToCreate
    );
  };

  updateInstitutionContact = async (
    contactToUpdate: ExistingInstitutionContact
  ) => {
    if (!this.institution) {
      return;
    }

    this.institution = await institutionApiClient.updateInstitutionContact(
      this.institution.id,
      contactToUpdate
    );
  };

  deleteInstitutionContact = async (contactToDeleteId: string) => {
    if (!this.institution) {
      return;
    }

    this.institution = await institutionApiClient.deleteInstitutionContact(
      this.institution.id,
      contactToDeleteId
    );
  };

  createInstitutionDocumentNeeded = async (
    orderedDocumentsCreateDTO: OrderedDocumentCreateDTO
  ) => {
    if (!this.institution) {
      return;
    }

    await institutionApiClient.createInstitutionDocumentNeeded(
      this.institution.id,
      orderedDocumentsCreateDTO
    );
  };

  deleteInstitutionDocumentNeeded = async (discriminatorId: string) => {
    if (!this.institution) {
      return;
    }

    await institutionApiClient.deleteInstitutionDocumentNeeded(
      this.institution.id,
      discriminatorId
    );
  };

  reorderInstitutionDocumentNeeded = async (
    orderedDocumentReorderDTO: OrderedDocumentReorderDTO
  ) => {
    if (!this.institution) {
      return;
    }

    await institutionApiClient.reorderInstitutionDocumentNeeded(
      this.institution.id,
      orderedDocumentReorderDTO
    );
  };
}

export const institutionStore = new InstitutionStore();
