import { AddressFieldValue } from "../../../components/Fields/types/fieldTypes";
import baseApiClient from "../baseApiClient";

class AddressApiClient {
  getAddressesByQuery = (queryAddress: Partial<AddressFieldValue>) => {
    return baseApiClient.get<AddressFieldValue[]>(
      `address?${new URLSearchParams({
        ...(queryAddress.street && { street: queryAddress.street }),
        ...(queryAddress.street2 && { street2: queryAddress.street2 }),
        ...(queryAddress.street2 && { city: queryAddress.city }),
        ...(queryAddress.street2 && { state: queryAddress.state }),
        ...(queryAddress.street2 && { zip: queryAddress.zip }),
      })}`
    );
  };

  getAddressByQuery = (queryAddress: AddressFieldValue) => {
    return baseApiClient.get<AddressFieldValue>(
      `address/full?${new URLSearchParams({
        street: queryAddress.street,
        street2: queryAddress.street2 || "",
        city: queryAddress.city,
        state: queryAddress.state,
        zip: queryAddress.zip,
      })}`
    );
  };
}

export const addressApiClient = new AddressApiClient();
