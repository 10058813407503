import { IconNames } from "@blueprintjs/icons";
import { Button, NonIdealState } from "@blueprintjs/core";
import { errorStore, genericErrorMessage } from "../stores/ErrorStore";
import "./styles.scss";
import { logout } from "../lib/msal/msal";

const ErrorWrapper: React.FC = () => {
  return (
    <>
      <NonIdealState
        icon={IconNames.ERROR}
        description={<>{errorStore.errorMessage || genericErrorMessage}</>}
      />
      <div className="error-page-signout">
        <Button
          className="signout-button"
          text="Sign Out"
          intent="primary"
          onClick={logout}
        />
      </div>
    </>
  );
};

export default ErrorWrapper;
