import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  ExistingInstitutionContact,
  InstitutionContact,
} from "../../../types/InstitutionContact";
import { AllFields, NameFieldValue } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newInstitutionContact: InstitutionContact = {
  firstName: "",
  lastName: "",
  title: "",
  address: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
  email: "",
  phoneNumber: "",
  phoneExtension: "",
  faxNumber: "",
  additionalPhoneNumber: "",
  notes: "",
};

export const institutionContactFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId
): AllFields[] => {
  const modelName = "contact";
  const institutionContact = form as ExistingInstitutionContact;

  return [
    {
      type: "Name",
      label: "Name",
      onSubmit: (name) => {
        onValueChange({ ...(name as NameFieldValue) });
      },
      value: {
        firstName: institutionContact.firstName,
        lastName: institutionContact.lastName,
      },
      isRequired: true,
    },
    {
      ...schemaStore.getFieldSchemaByName(modelName, "title"),
      onSubmit: (title) => {
        onValueChange({ title });
      },
      value: institutionContact.title,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "address"),
      onSubmit: (address) => {
        onValueChange({ address });
      },
      value: institutionContact.address,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "email"),
      onSubmit: (email) => {
        onValueChange({ email });
      },
      errorMessages: get(errors, "email"),
      formId: formId,
      value: institutionContact.email,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneNumber"),
      onSubmit: (phoneNumber) => {
        onValueChange({ phoneNumber });
      },
      value: institutionContact.phoneNumber,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneExtension"),
      onSubmit: (phoneExtension) => {
        onValueChange({ phoneExtension });
      },
      value: institutionContact.phoneExtension,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "faxNumber"),
      onSubmit: (faxNumber) => {
        onValueChange({ faxNumber });
      },
      value: institutionContact.faxNumber,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "additionalPhoneNumber"),
      onSubmit: (additionalPhoneNumber) => {
        onValueChange({ additionalPhoneNumber });
      },
      value: institutionContact.additionalPhoneNumber,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "additionalPhoneNumber"),
      onSubmit: (additionalPhoneNumber) => {
        onValueChange({ additionalPhoneNumber });
      },
      value: institutionContact.additionalPhoneNumber,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "notes"),
      onSubmit: (notes) => {
        onValueChange({ notes });
      },
      value: institutionContact.notes,
    } as AllFields,
  ];
};
