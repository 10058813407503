import { NewNotary, Notary } from "../../../types/Notary";
import baseApiClient from "../baseApiClient";

class NotaryApiClient {
  getNotaryDetail = async (id: string) => {
    return baseApiClient.get<Notary>(`notary/${id}`);
  };

  createNotary = async (notary: NewNotary) => {
    return baseApiClient.post<Notary>(`notary`, notary);
  };

  updateNotary = async (notaryId: string, notary: Notary) => {
    return baseApiClient.put<Notary>(`notary/${notaryId}`, notary);
  };
}

export const notaryApiClient = new NotaryApiClient();
