import { Button, Intent, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC, useEffect, useState } from "react";
import { applicationApiClient } from "../../../../lib/apiClients/application/applicationApiClient";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { checklistItemStore } from "../../../../stores/ChecklistItemStore";
import { permissionStore } from "../../../../stores/PermissionStore";
import {
  Application,
  ApplicationStatuses,
} from "../../../../types/Application";
import { User } from "../../../../types/User";
import { UserIcon } from "../../../Avatar/UserIcon";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";
import { downloadFile } from "../../../Document/PdfDocuments";
import { AppToaster } from "../../../Toast/Toast";
import { WithTooltip } from "../../../Tooltip/Tooltip";

export interface ApplicationPageHeaderProps {
  application?: Application;
  activeUsers?: User[];
  canWithdrawApplicationPage: boolean;
  canMonitorApplicationPage: boolean;
  canSubmitApplicationToState: boolean;
  canGenerateApplicationDocPackage: boolean;
  canConvertApplicationToRecert: boolean;
}

export const ApplicationPageHeaderCard: FC<ApplicationPageHeaderProps> = ({
  application,
  activeUsers,
  canWithdrawApplicationPage,
  canMonitorApplicationPage,
  canSubmitApplicationToState,
  canGenerateApplicationDocPackage,
  canConvertApplicationToRecert,
}) => {
  const [isGeneratingDocs, setIsGeneratingDocs] = useState<boolean>(false);
  const [isReactivating, setIsReactivating] = useState<boolean>(false);
  const [isConvertingToRecert, setIsConvertingToRecert] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      // fetch
      if (application?.monitor && application.monitor.isMonitorEndDateOverDue) {
        applicationStore.deleteApplicationMonitor(application.monitor.id);
      }
    })();
  }, [application?.monitor]);

  return (
    <div className="application-page-navbar">
      {!!activeUsers &&
        activeUsers.map((user) => <UserIcon key={user.id} user={user} />)}
      {!!application?.canApplicationSubmitToState &&
        canSubmitApplicationToState && (
          <WithTooltip
            shouldShowTooltip={!permissionStore.canSubmitToState}
            content={
              "Please complete all required fields prior to applying to state"
            }
          >
            <Button
              minimal
              intent={Intent.PRIMARY}
              disabled={!permissionStore.canSubmitToState}
              onClick={async () => {
                dialogsViewerStore.setIsApplyToStateDialogOpen(true);
              }}
            >
              {`${
                application.hasApplicationStateSubmitted ? "Re-Apply" : "Apply"
              } to State`}
            </Button>
          </WithTooltip>
        )}
      {application?.isWithdrawn === true && canWithdrawApplicationPage && (
        <Button
          minimal={false}
          intent={Intent.SUCCESS}
          onClick={async () => {
            setIsReactivating(true);
            await applicationStore.reactivateApplication();
            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Application reactivated.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            setIsReactivating(false);
          }}
        >
          {isReactivating ? <Spinner size={18.391} /> : "Reactivate"}
        </Button>
      )}
      {application?.status === ApplicationStatuses.futureRecert &&
        canConvertApplicationToRecert && (
          <Button
            minimal={true}
            intent={Intent.DANGER}
            onClick={async () => {
              try {
                setIsConvertingToRecert(true);
                await applicationStore.convertApplicationToRecert();
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Success</h3>Successfully converted Application to
                      Recert.
                    </div>
                  ),
                  intent: Intent.SUCCESS,
                });
              } catch {
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Error</h3>Failed to convert Application to Recert.
                    </div>
                  ),
                  intent: Intent.DANGER,
                });
              } finally {
                setIsConvertingToRecert(false);
              }
            }}
          >
            {isConvertingToRecert ? (
              <Spinner size={18.391} />
            ) : (
              "Convert to Recert"
            )}
          </Button>
        )}
      {application?.isWithdrawn === false && canWithdrawApplicationPage && (
        <Button
          minimal={true}
          intent={Intent.DANGER}
          onClick={() => {
            dialogsViewerStore.setIsWithdrawApplicationDialogOpen(true);
          }}
        >
          Withdraw
        </Button>
      )}
      {!!application?.id && canGenerateApplicationDocPackage && (
        <Button
          minimal
          intent={Intent.PRIMARY}
          loading={isGeneratingDocs}
          disabled={isGeneratingDocs}
          onClick={async () => {
            try {
              setIsGeneratingDocs(true);
              const blob = (await applicationApiClient.getStateDocumentPackage(
                application.id,
                true
              )) as BlobPart;
              downloadFile(blob, "state_document_package.pdf");
            } catch (e) {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Error</h3>Doc Generation Failed
                  </div>
                ),
                intent: Intent.DANGER,
              });
            } finally {
              checklistItemStore.setChecklistItems(true);
              setIsGeneratingDocs(false);
            }
          }}
        >
          Generate Docs
        </Button>
      )}
      {!application?.monitor && canMonitorApplicationPage && (
        <Button
          minimal
          intent={Intent.PRIMARY}
          rightIcon={IconNames.ArrowRight}
          onClick={() => {
            dialogsViewerStore.setIsMonitorCreationDialogOpen(true);
          }}
        >
          Start Monitor
        </Button>
      )}
      {!!application?.monitor &&
        !application.monitor.isMonitorEndDateOverDue &&
        canMonitorApplicationPage && (
          <>
            <Button
              minimal
              intent={Intent.PRIMARY}
              rightIcon={IconNames.ArrowUp}
              onClick={() => {
                dialogsViewerStore.setIsMonitorUpdateDialogOpen(true);
              }}
            >
              Update Monitor
            </Button>
            <Button
              minimal
              intent={Intent.DANGER}
              rightIcon={IconNames.Delete}
              onClick={async () => {
                dialogsViewerStore.setIsEndMonitorDialogOpen(true);
              }}
            >
              End Monitor
            </Button>
          </>
        )}
    </div>
  );
};
