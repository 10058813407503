import { flatten, get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { Corporate, ExistingCorporate } from "../../../types/Corporate";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import { CorporateDocuments } from "../../CorporateGrid/CorporateDocuments";

export const newFormCorporate: Corporate = {
  companyCode: "",
  companyName: "",
  companyEmails_AsList: [],
  companyPhone: "",
  companyAddress: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
  corporateDocuments: [],
  note: "",
  isActive: true,
  annualRateIncrease: undefined,
  annualRateIncreaseEffectiveProductTypes: undefined,
  isAnnualRateIncreaseEnabled: undefined,
  contractEffectiveDate: undefined,
  contractEffectiveDate_AsDateOnly: undefined,
  nextRateIncreaseDate: undefined,
  nextRateIncreaseDate_AsDateOnly: undefined,
};

export const corporateFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "corporate";
  const corporate = form as ExistingCorporate;

  const getErrorMessageForCompanyEmails = (fieldName: string) => {
    if (!errors) {
      return [];
    }

    const fieldErrors = Object.keys(errors)
      .filter((key) => key.startsWith(fieldName))
      .map((key) => get(errors, key));
    return flatten(fieldErrors);
  };

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "companyCode"),
      value: corporate.companyCode,
      maxLength: 5,
      disabled: !!corporate.id,
      onSubmit: (companyCode) => {
        onValueChange({ companyCode });
      },
      errorMessages: get(errors, "companyCode"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "companyName"),
      value: corporate.companyName,
      onSubmit: (companyName) => {
        onValueChange({ companyName });
      },
      isRequired: true,
      errorMessages: get(errors, "companyName"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "companyPhone"),
      value: corporate.companyPhone,
      onSubmit: (companyPhone) => {
        onValueChange({ companyPhone });
      },
      errorMessages: get(errors, "companyPhone"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "companyEmails_AsList"),
      value: corporate.companyEmails_AsList,
      onSubmit: (companyEmails_AsList) => {
        onValueChange({ companyEmails_AsList });
      },
      errorMessages: getErrorMessageForCompanyEmails("companyEmails_AsList"),
      placeholder: "johnsmith@gmail.com",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "companyAddress"),
      onSubmit: (companyAddress) => {
        onValueChange({ companyAddress });
      },
      value: corporate.companyAddress,
      isRequired: true,
      errorMessages: [
        ...(get(errors, "companyAddress.Street") || []),
        ...(get(errors, "companyAddress.City") || []),
        ...(get(errors, "companyAddress.State") || []),
        ...(get(errors, "companyAddress.Zip") || []),
        ...(get(errors, "companyAddress.County") || []),
      ],
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "note"),
      value: corporate.note,
      onSubmit: (note) => {
        onValueChange({ note });
      },
      errorMessages: get(errors, "note"),
    } as AllFields,
    {
      type: "Checkbox-Bool",
      label: "Is Active",
      disabled: false,
      onSubmit: (isActive) => {
        onValueChange({ isActive });
      },
      errorMessages: get(errors, "isActive"),
      value: corporate.isActive,
    },
    {
      type: "Section",
      label: "Annual Rate Increase Rules",
    } as any,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "contractEffectiveDate"),
      value: corporate.contractEffectiveDate,
      onSubmit: (contractEffectiveDate) => {
        onValueChange({ contractEffectiveDate });
      },
      width: "15.5%",
      errorMessages: get(errors, "contractEffectiveDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "nextRateIncreaseDate"),
      value: corporate.nextRateIncreaseDate,
      disabled: true,
      width: "15.5%",
      errorMessages: get(errors, "nextRateIncreaseDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "annualRateIncrease"),
      value: corporate.annualRateIncrease,
      onSubmit: (annualRateIncrease) => {
        onValueChange({ annualRateIncrease });
      },
      width: "15.5%",
      errorMessages: get(errors, "annualRateIncrease"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "annualRateIncreaseEffectiveProductTypes"
      ),
      value: corporate.annualRateIncreaseEffectiveProductTypes,
      onSubmit: (annualRateIncreaseEffectiveProductTypes) => {
        onValueChange({ annualRateIncreaseEffectiveProductTypes });
      },
      width: "15.5%",
      errorMessages: get(errors, "annualRateIncreaseEffectiveProductTypes"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isAnnualRateIncreaseEnabled"
      ),
      value: !!corporate.isAnnualRateIncreaseEnabled,
      onSubmit: (isAnnualRateIncreaseEnabled) => {
        onValueChange({ isAnnualRateIncreaseEnabled });
      },
      width: "15.5%",
      errorMessages: get(errors, "isAnnualRateIncreaseEnabled"),
    } as AllFields,
    {
      type: "Section",
      label: "Documents",
    } as any,
    {
      type: "Custom",
      renderer: () => (
        <CorporateDocuments
          corporateId={corporate.id}
          corporateDocuments={corporate.corporateDocuments}
          onSubmit={(docs) => {
            onValueChange({
              corporateDocuments: docs.map((doc) => ({
                corporateId: corporate.id,
                documentId: doc.id,
              })),
            });
          }}
        />
      ),
    },
  ];
};
