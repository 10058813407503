import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import { userStore } from "../../stores/UserStore";
import { PageSidebarItem } from "./PageSidebarItem";
import "./styles.scss";
import { PageSidebarChatBot } from "../ChatBot/PageSidebarChatBot";
import variables from "../../config/variables";
import { TopicSectionTypes, topicSectionTypeConfig } from "../HelpCenter/types";
import React from "react";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";
import { PageSidebarDocumentFiling } from "../DocumentFiling/PageSidebarDocumentFiling";

export interface PageSidebarProps {
  activePage:
    | "home"
    | "scheduler"
    | "matchup"
    | "fias"
    | "reports"
    | "admin"
    | "templates"
    | "helpcenter";
}

export const PageSidebar: React.FC<PageSidebarProps> = ({ activePage }) => {
  const [sidebarPopoverOpenisOpen, setSidebarPopoverOpenIsOpen] =
    React.useState(false);

  const sidebarPopoverOpenHandler = (isOpen: boolean) => {
    setSidebarPopoverOpenIsOpen(isOpen);
  };

  return (
    <Observer>
      {() => {
        let route;
        switch (true) {
          case !!userStore.user?.canViewUserBoard:
            route = "users";
            break;
          case userStore.user?.canViewCorporateBoard:
            route = "corporates";
            break;
          case userStore.user?.canViewInstitutionBoard:
            route = "fias";
            break;
          case userStore.user?.canViewNotaryBoard:
            route = "notary";
            break;
          default:
            break;
        }

        return (
          <div className="page-sidebar">
            {userStore.user?.canViewBoardMenu && (
              <PageSidebarItem
                item={{
                  icon: IconNames.PROPERTIES,
                  route: "/",
                  label: "Board",
                }}
                isSelected={activePage === "home"}
              />
            )}
            {userStore.user?.canViewScheduleMenu &&
              featureToggleStore.featureToggles?.InAppScheduling && (
                <PageSidebarItem
                  item={{
                    icon: IconNames.CALENDAR,
                    route: "/scheduler",
                    label: "Scheduler",
                  }}
                  isSelected={activePage === "scheduler"}
                />
              )}
            {userStore.user?.canViewMatchupMenu &&
              variables.featureToggleConfig.matchups && (
                <PageSidebarItem
                  item={{
                    icon: IconNames.EXCHANGE,
                    route: "/matchup",
                    label: "Matchup",
                  }}
                  isSelected={activePage === "matchup"}
                />
              )}
            {userStore.user?.canViewFIARequestGrid &&
              featureToggleStore.featureToggles?.EnableFiaGrid && (
                <PageSidebarItem
                  item={{
                    icon: IconNames.FOLDER_SHARED_OPEN,
                    route: "/fias",
                    label: "FIAs",
                  }}
                  isSelected={activePage === "fias"}
                />
              )}
            {userStore.user?.canViewReportsMenu && (
              <PageSidebarItem
                item={{
                  icon: IconNames.CHART,
                  route: "/reports",
                  label: "Reports",
                }}
                isSelected={activePage === "reports"}
              />
            )}
            {userStore.user?.canViewTemplatesMenu && (
              <PageSidebarItem
                item={{
                  icon: IconNames.DOCUMENT,
                  route: "/templates",
                  label: "Templates",
                }}
                isSelected={activePage === "templates"}
              />
            )}
            {userStore.user?.canViewAdminMenu && (
              <PageSidebarItem
                item={{
                  icon: IconNames.SETTINGS,
                  route: `/admin/${route}`,
                  label: "Admin",
                }}
                isSelected={activePage === "admin"}
              />
            )}
            {userStore.user?.canViewHelpCenter && (
              <PageSidebarItem
                item={{
                  icon: IconNames.HELP,
                  route: `/helpcenter/${
                    topicSectionTypeConfig[TopicSectionTypes.FAQ].path
                  }`,
                  label: "Help",
                }}
                isSelected={activePage === "helpcenter"}
              />
            )}
            <hr />
            {featureToggleStore.featureToggles?.FiaFiling &&
              userStore.user?.canAccessFilingAI && (
                <PageSidebarDocumentFiling
                  onOpenChanged={sidebarPopoverOpenHandler}
                  disabled={sidebarPopoverOpenisOpen}
                />
              )}
            {userStore.user?.canViewGooseChat && (
              <PageSidebarChatBot
                onOpenChanged={sidebarPopoverOpenHandler}
                disabled={sidebarPopoverOpenisOpen}
              />
            )}
          </div>
        );
      }}
    </Observer>
  );
};
