import {
  OrderedDocumentCreateDTO,
  OrderedDocumentReorderDTO,
} from "../../../components/OrderedDocumentsGrid/configurations/types";
import { ExistingInstitution, Institution } from "../../../types/Institution";
import {
  ExistingInstitutionContact,
  InstitutionContact,
} from "../../../types/InstitutionContact";
import baseApiClient from "../baseApiClient";

class InstitutionApiClient {
  createInstitution = async (institution: Institution) => {
    return baseApiClient.post<ExistingInstitution>("institution", institution);
  };

  createInstitutionContact = async (
    id: string,
    contact: InstitutionContact
  ) => {
    return baseApiClient.post<ExistingInstitution>(
      `institution/${id}/contact`,
      contact
    );
  };

  getInstitutionDetail = async (id: string) => {
    return baseApiClient.get<ExistingInstitution>(`institution/${id}`);
  };

  updateInstitution = async (id: string, institution: ExistingInstitution) => {
    return baseApiClient.put<ExistingInstitution>(
      `institution/${id}`,
      institution
    );
  };

  updateInstitutionContact = async (
    id: string,
    contact: ExistingInstitutionContact
  ) => {
    return baseApiClient.put<ExistingInstitution>(
      `institution/${id}/contact/${contact.id}`,
      contact
    );
  };

  deleteInstitutionContact = async (id: string, contactId: string) => {
    return baseApiClient.delete<ExistingInstitution>(
      `institution/${id}/contact/${contactId}`
    );
  };

  createInstitutionDocumentNeeded = async (
    id: string,
    orderedDocumentsCreateDTO: OrderedDocumentCreateDTO
  ) => {
    return baseApiClient.post(
      `institution/${id}/documentsneeded`,
      orderedDocumentsCreateDTO
    );
  };

  reorderInstitutionDocumentNeeded = async (
    id: string,
    orderedDocumentReorderDTO: OrderedDocumentReorderDTO
  ) => {
    return baseApiClient.put(
      `institution/${id}/documentsneeded/${orderedDocumentReorderDTO.discriminatorId}`,
      orderedDocumentReorderDTO
    );
  };

  deleteInstitutionDocumentNeeded = async (
    id: string,
    discriminatorId: string
  ) => {
    return baseApiClient.delete(
      `institution/${id}/documentsneeded/${discriminatorId}`
    );
  };
}

export const institutionApiClient = new InstitutionApiClient();
