import { Option } from "../components/Fields/types/fieldTypes";
import moment from "moment";

export const getStatementOptions = (): Option[] => {
  const options: Option[] = [];
  let day = moment();

  for (let i = 0; i < 120; i++) {
    options.push({
      label: day.format("MMMM yyyy"),
      value: day.format("yyyy-MM"),
    });
    day = day.subtract(1, "month");
  }

  return options;
};

export const getReadonlyAggregatedStatementsOptions = (
  statementDates_AsList: string[] | undefined
): Option[] => {
  const options: Option[] = [];
  statementDates_AsList?.forEach((date) => {
    if (moment(date, "yyyy-MM").isValid()) {
      options.push({
        label: moment(date).format("MMMM yyyy"),
        value: date,
      });
    }
  });
  return options;
};

export const getValidAggregatedStatements_AsListString = (
  statementDates_AsList: string[] | undefined
): string[] => {
  if (!!statementDates_AsList && statementDates_AsList.length > 0) {
    return statementDates_AsList?.filter((date) =>
      moment(date, "yyyy-MM").isValid()
    );
  }

  return [];
};
