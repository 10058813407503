import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { Application } from "../../../types/Application";
import {
  AllFields,
  SectionHeaderProps,
  Option,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const withdrawApplicationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as Application;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "withdrawnReason"),
      value: application.withdrawnReason,
      onSubmit: (withdrawnReason: Option) => {
        onValueChange({ withdrawnReason: withdrawnReason.value });
      },
      errorMessages: get(errors, "withdrawnReason"),
      isRequired: true,
    } as AllFields,
  ];
};
