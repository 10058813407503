import { action, makeObservable, observable } from "mobx";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { IPoint } from "@syncfusion/ej2-react-pdfviewer";
import { FileDocument } from "../../../types/FileDocument";
import {
  DragAndDropEventArgs,
  FieldsSettingsModel,
  TreeViewComponent,
  TreeViewModel,
} from "@syncfusion/ej2-react-navigations";
import {
  MaskedTextBoxComponent,
  MaskedTextBoxModel,
} from "@syncfusion/ej2-react-inputs";
import {
  TemplateField,
  defaultTemplateFieldsConfig,
} from "../config/templateFieldsConfig";
import { deep } from "../../../utils/clone";
import { DataManager, Query, Predicate } from "@syncfusion/ej2-data";
import { templateDesignerStore } from "./TemplateDesignerStore";
import { first } from "lodash";
import { ExpressionData, expressionsStore } from "./ExpressionsStore";

export interface SearchResults {
  data: TemplateField[];
  expand: boolean;
}

export class ExpressionToolbarStore {
  // computed

  // public
  public maskedTextBoxInstance?: MaskedTextBoxComponent;
  public treeViewInstance?: TreeViewComponent;
  public templateFieldsConfig: TemplateField[] = [];

  private defaultMaskedTextBoxSettings: MaskedTextBoxModel &
    DefaultHtmlAttributes = {
    placeholder: "Search by Field Name...",
  };

  private defaultTreeViewSettings: TreeViewModel & DefaultHtmlAttributes = {
    allowDragAndDrop: true,
    fullRowSelect: false,
    fullRowNavigable: false,
  };

  constructor() {
    makeObservable(this, {
      maskedTextBoxInstance: observable,
      treeViewInstance: observable,
      templateFieldsConfig: observable,
      setTemplateFieldsConfig: action,
    });
  }

  // Datasource
  private changeDataSource = (data: any) => {
    if (!this.treeViewInstance) {
      return;
    }

    this.treeViewInstance.fields = {
      dataSource: [...data],
      id: "id",
      text: "name",
      child: "fields",
    };
  };

  private getItems = (
    property: string,
    value: string,
    list?: TemplateField[],
    additionalProperty?: string
  ): TemplateField[] => {
    if (!(list && list.length > 0)) {
      return [];
    }
    const predicate = additionalProperty
      ? new Predicate(property, "contains", value, true).or(
          new Predicate(additionalProperty, "contains", value, true)
        )
      : new Predicate(property, "contains", value, true);

    const filteredList: any = new DataManager(list).executeLocal(
      new Query().where(predicate)
    );

    return filteredList;
  };

  private getItemById = (id: string): TemplateField | undefined => {
    const results = deep<TemplateField[]>(this.templateFieldsConfig);

    results.forEach((element) => {
      const node = { ...element } as TemplateField;
      const r = this.getItems("id", id, node.fields);
      element.fields = node.fields?.filter((x) =>
        r.map((y) => y.id).includes(x.id)
      );
    });

    // Just get the first
    const category = first(
      results.filter((x) => x.fields && x.fields.length > 0)
    );
    const item = first(category?.fields);
    return item;
  };

  //search
  public search = (): SearchResults | undefined => {
    if (!this.maskedTextBoxInstance) {
      return;
    }

    const _text = this.maskedTextBoxInstance.element.value;

    if (_text == "") {
      // show all data
      this.changeDataSource([...this.templateFieldsConfig]);
      return { data: [...this.templateFieldsConfig], expand: false };
    }

    // Search children
    let results = deep<TemplateField[]>(this.templateFieldsConfig);

    results.forEach((element) => {
      const node = { ...element } as TemplateField;
      const r = this.getItems(
        "name",
        _text,
        node.fields,
        "templateExpression.expressionCategory"
      );
      element.fields = node.fields?.filter((x) =>
        r.map((y) => y.id).includes(x.id)
      );
    });

    results = results.filter((x) => x.fields && x.fields.length > 0);

    this.changeDataSource([...results]);
    return { data: [...results], expand: true };
  };

  // Events
  handleNodeDragStart = (args: DragAndDropEventArgs) => {
    if (!this.treeViewInstance) {
      return;
    }

    if (
      this.treeViewInstance.selectedNodes.length <= 0 ||
      !args.draggedNodeData.parentID ||
      !this.treeViewInstance.selectedNodes.includes(
        args.draggedNodeData.id as string
      )
    ) {
      // Either not selected or its just category or items trying to be dragged is not the one selected
      args.cancel = true;
    }
  };

  private handleNodeDragging = (args: DragAndDropEventArgs) => {
    if (!this.treeViewInstance) {
      return;
    }

    if (
      args.target != null &&
      (args.target as HTMLElement).classList.contains("e-pv-text-layer")
    ) {
      if (!args.draggedNodeData.parentID) {
        // Its just category (revalidation)
        args.dropIndicator = "e-no-drop";
      } else {
        // All Good
        args.dropIndicator = "e-drop-in";
      }
    } else {
      // Not on PDF Page
      args.dropIndicator = "e-no-drop";
    }
  };

  handleNodeDragStop = (args: DragAndDropEventArgs) => {
    if (
      !(
        args.target != null &&
        (args.target as HTMLElement).classList.contains("e-pv-text-layer")
      )
    ) {
      args.cancel = true;
    } else {
      if (!args.draggedNodeData.parentID) {
        // Its just the category (revalidation)
        return;
      }

      // get data
      const data = this.getItemById(args.draggedNodeData.id as string);

      if (!data?.templateExpression) {
        console.warn("Not an expression annotation.");
        return;
      }

      // Set coordinates
      const dimensions =
        data.templateExpression.expressionDimension ??
        expressionsStore.getDefaultExpressionAnnotationDimensions(
          data.templateExpression.expressionType
        );
      const destinyPoint = {
        x: args.event.offsetX,
        y: args.event.offsetY,
      } as IPoint;
      const pointOffset = 50;

      const xPointCalc = destinyPoint.x + dimensions.width + pointOffset;
      if (xPointCalc >= args.target.clientWidth) {
        destinyPoint.x = Math.abs(destinyPoint.x - dimensions.width);
      }

      const yPointCalc = destinyPoint.y + dimensions.height + pointOffset;
      if (yPointCalc >= args.target.clientHeight) {
        destinyPoint.y = Math.abs(destinyPoint.y - dimensions.height);
      }

      // Add Expression
      templateDesignerStore.addExpressionAnnotation(
        data.templateExpression,
        destinyPoint
      );
    }
  };

  setTemplateFieldsConfig = (expressionDataList: ExpressionData[]) => {
    if (!(expressionDataList && expressionDataList.length > 0)) {
      this.templateFieldsConfig = [...defaultTemplateFieldsConfig];
      return;
    }

    // Set Fields
    const fieldsCategory = {
      id: "fields",
      name: "Fields",
      expanded: true,
      fields: [] as TemplateField[],
    } as TemplateField;

    // Map
    fieldsCategory.fields = expressionDataList.map((x) => {
      return {
        id: x.expressionName,
        name: x.expressionPlaceholder,
        templateExpression: x,
      } as TemplateField;
    });

    this.templateFieldsConfig = [
      ...defaultTemplateFieldsConfig,
      { ...fieldsCategory },
    ];
  };

  // Init
  public setup = (templateFields: ExpressionData[]) => {
    // TreeView Settings
    const treeViewSettings = {
      ...this.defaultTreeViewSettings,
    } as TreeViewModel & DefaultHtmlAttributes;

    this.setTemplateFieldsConfig(templateFields);

    treeViewSettings.nodeDragStart = this.handleNodeDragStart;
    treeViewSettings.nodeDragStop = this.handleNodeDragStop;
    treeViewSettings.nodeDragging = this.handleNodeDragging;
    treeViewSettings.fields = {
      dataSource: [...this.templateFieldsConfig],
      id: "id",
      text: "name",
      child: "fields",
    } as unknown as FieldsSettingsModel;

    const maskedTextBoxModel = {
      ...this.defaultMaskedTextBoxSettings,
    } as MaskedTextBoxModel & DefaultHtmlAttributes;

    return [maskedTextBoxModel, treeViewSettings];
  };

  public initialize = (
    newMaskedTextBoxInstance: MaskedTextBoxComponent,
    newTreeViewInstance: TreeViewComponent
  ) => {
    this.setInstance(newMaskedTextBoxInstance, newTreeViewInstance);
  };

  public reset = () => {
    this.setInstance(undefined, undefined);
  };

  public setInstance = (
    scopeMaskedTextBoxInstance?: MaskedTextBoxComponent,
    scopeTreeViewInstance?: TreeViewComponent
  ) => {
    this.maskedTextBoxInstance = scopeMaskedTextBoxInstance;
    this.treeViewInstance = scopeTreeViewInstance;
  };
}

export const expressionToolbarStore = new ExpressionToolbarStore();

export interface TemplateDesignerSetup {
  accessToken: string;
  fileDocument: FileDocument;
}
