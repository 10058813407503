import React from "react";
import {
  Inject,
  ListViewComponent,
  Virtualization,
} from "@syncfusion/ej2-react-lists";
import {
  AutoCompleteComponent,
  SelectEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import GridColumn from "../../Grid/types/GridColumn";
import { AppToaster } from "../../../Toast/Toast";
import { Tooltip2 } from "@blueprintjs/popover2";
import { GridView } from "@ucl/library";
export interface GridViewConfigurationProps {
  gridView: GridView;
  baseGridColumnsConfiguration?: GridColumn[];
  updateViewColumnSettings: (
    viewId: string,
    columnSettings: string[]
  ) => Promise<void>;
  onCancel?: () => void;
  onSubmit?: () => void;
}

type SearchDto = Pick<GridColumn, "headerText" | "field">;
type ListColumn = { id: string; text: string };

// System will use the preload views on store to improve performance as this is the design that is visualized to stay.
export const GridViewConfiguration: React.FC<GridViewConfigurationProps> = ({
  gridView,
  baseGridColumnsConfiguration,
  updateViewColumnSettings,
  onCancel,
  onSubmit,
}) => {
  const searchRef = React.useRef<AutoCompleteComponent | null>(null);
  const [ogColumnConfiguration, setOgColumnConfiguration] = React.useState([
    ...gridView.columnConfiguration,
  ]);
  const [localColumnConfiguration, setLocalColumnConfiguration] =
    React.useState([...gridView.columnConfiguration]);
  const [disableForm, setDisableForm] = React.useState(false);

  const addColumn = (field: string) => {
    // Validate
    if (
      !(
        baseGridColumnsConfiguration &&
        !localColumnConfiguration.map((x) => x.field).includes(field)
      )
    ) {
      throw `Cannot add ${field} since its already on the list.`;
    }

    // Get configuration
    const col = baseGridColumnsConfiguration.find((x) => x.field === field);
    if (!col) {
      throw `Cannot add ${field} sinc eits already on the list.`;
    }

    // Add
    localColumnConfiguration.push(col);
    setLocalColumnConfiguration([...localColumnConfiguration]);
  };
  const removeColumn = (field: string) => {
    setLocalColumnConfiguration(
      localColumnConfiguration.filter((x) => x.field !== field)
    );
  };

  const columnTemplate = (data: ListColumn): JSX.Element => {
    return (
      <div className="view-column">
        <span>{data.text}</span>
        <Button
          title="Remove"
          className="view-column-remove"
          minimal={true}
          icon={IconNames.BAN_CIRCLE}
          disabled={disableForm}
          onClick={() => {
            removeColumn(data.id);
          }}
        />
      </div>
    );
  };

  const onSearchSelect = (args: SelectEventArgs) => {
    if (!!searchRef.current) {
      const data = args.itemData as SearchDto;
      addColumn(data.field);
    }
  };

  const onSearchActionBegin = (args: { data: SearchDto[] }) => {
    args.data = getSearchColumns();
  };

  const showSearchPopup = () => {
    if (!!searchRef.current) {
      searchRef.current.showPopup();
    }
  };

  const clearSearch = () => {
    if (!!searchRef.current) {
      searchRef.current.clear();
    }
  };

  const getSearchColumns = (): SearchDto[] => {
    const colsNotSelected = baseGridColumnsConfiguration!.filter(
      (x) =>
        !localColumnConfiguration.map((y) => y.field).includes(x.field) &&
        x.visible !== false
    );

    return colsNotSelected.map((x) => {
      return { headerText: x.headerText, field: x.field };
    });
  };

  const getListColumns = (): ListColumn[] => {
    return localColumnConfiguration.map((x) => {
      return { text: x.headerText, id: x.field };
    });
  };

  const cancelChanges = () => {
    setLocalColumnConfiguration([...ogColumnConfiguration]);

    // Delegate
    if (onCancel) {
      onCancel();
    }
  };

  const canSave = (): boolean => {
    return localColumnConfiguration.length > 0;
  };

  const saveView = async () => {
    if (!canSave()) {
      return;
    }

    // Disable
    setDisableForm(true);

    // Validate
    if (
      ogColumnConfiguration.map((x) => x.field).join("") ===
      localColumnConfiguration.map((x) => x.field).join("")
    ) {
      if (onCancel) {
        onCancel();
      }
      return;
    }

    // Save
    await updateViewColumnSettings(
      gridView.id,
      localColumnConfiguration.map((x) => x.field)
    )
      .then(() => {
        // Default
        setOgColumnConfiguration([...localColumnConfiguration]);

        AppToaster.show({
          message: "View Configuration Saved",
          intent: Intent.SUCCESS,
        });

        // Delegate
        if (onSubmit) {
          onSubmit();
        }
      })
      .catch(() => {
        setDisableForm(false);

        AppToaster.show({
          message: "An error ocurred executing the current action.",
          intent: Intent.DANGER,
        });
      });
  };

  return (
    <section className="grid-view-configuration">
      {/* Search */}
      <div className="grid-view-configuration-search">
        <label>Search for the fields you would like to add to this view.</label>
        <AutoCompleteComponent
          ref={searchRef}
          id="grid-view-configuration-search-list"
          className={Classes.INPUT}
          fields={{ value: "headerText" }}
          minLength={0}
          autofill={true}
          showClearButton={false}
          highlight={true}
          placeholder="Type to search..."
          actionBegin={onSearchActionBegin}
          select={onSearchSelect}
          focus={showSearchPopup}
          onClick={showSearchPopup}
          blur={clearSearch}
          change={clearSearch}
          dataSource={getSearchColumns()}
          disabled={disableForm}
        />
      </div>
      {/* View Column Settings */}
      <div className="grid-view-configuration-columns">
        <label>Added Fields</label>
        <ListViewComponent
          id="grid-view-configuration-columns-list"
          enableVirtualization={true}
          template={columnTemplate}
          dataSource={getListColumns()}
          enable={!disableForm}
        >
          <Inject services={[Virtualization]} />
        </ListViewComponent>
      </div>
      {/* Save Footer */}
      <div className="grid-view-configuration-footer">
        <Button
          minimal
          intent={Intent.NONE}
          text="Cancel"
          disabled={disableForm}
          onClick={cancelChanges}
        />
        <Tooltip2
          content={
            <>
              Add one or more fields to <b>Save</b>
            </>
          }
          isOpen={!canSave()}
          position="top"
        >
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            disabled={disableForm || !canSave()}
            loading={disableForm}
            onClick={saveView}
          />
        </Tooltip2>
      </div>
    </section>
  );
};
