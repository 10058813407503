import { FormGroup, RadioGroup } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { RadioFieldProps, RadioFieldValue } from "../types/fieldTypes";

export const RadioField: React.FC<RadioFieldProps> = ({
  label,
  description,
  value,
  optionValues,
  onSubmit,
  disabled,
  hidden,
  errorMessages,
  isRequired,
}) => {
  if (hidden) {
    return null;
  }

  const [localValue, setLocalValue] = React.useState<RadioFieldValue>(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <FormGroup
      className={`${
        isRequired && (!value || value === "0")
          ? "has-required-background"
          : "radio-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <RadioGroup
        disabled={disabled}
        onChange={(e) => {
          setLocalValue(e.currentTarget.value);
          onSubmit(e.currentTarget.value);
        }}
        selectedValue={localValue}
        options={optionValues
          .filter((option) => option.value)
          .map((option, idx) => ({
            key: idx,
            label: option.label,
            value: String(option.value),
          }))}
        inline
      />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
