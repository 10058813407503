import { Intent } from "@blueprintjs/core";
import {
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { AppToaster } from "../components/Toast/Toast";
import { checklistApiClient } from "../lib/apiClients/checklist/checklistApiClient";
import {
  ChecklistItem,
  ChecklistItemDocTypes,
  ChecklistItemResponsiblePartyTypes,
  NewChecklistItem,
} from "../types/Checklist";
import { applicationStore } from "./ApplicationStore";
import { commentStore } from "./CommentStore";
import { userStore } from "./UserStore";
import { UserRoles } from "../types/User";
import { orderBy } from "lodash";

export type UIChecklistItem = ChecklistItem & { commentCount: number };

export class ChecklistItemStore {
  public checklistItems: ChecklistItem[] = [];
  public gridLastRefreshedAt: Date | undefined;

  constructor() {
    makeObservable(this, {
      checklistItems: observable,
      checklistItemsWithCommentCount: computed,
      gridLastRefreshedAt: observable,
    });

    reaction(
      () => applicationStore.application,
      () => {
        this.setChecklistItems(true);
      }
    );
  }

  get checklistItemsWithCommentCount() {
    const userRole = userStore.user?.role;
    const isFieldRep =
      userRole === UserRoles.FieldRepresentative ||
      userRole === UserRoles.FieldRepresentativeManager;
    const isDVT =
      userRole === UserRoles.DVTProcessor || userRole === UserRoles.DVTManager;

    const checklistItems = this.checklistItems.map((checklistItem) => ({
      ...checklistItem,
      commentCount: commentStore.comments.filter(
        (c) => c.sourceId === checklistItem.id
      ).length,
      updatedAt: new Date(`${checklistItem.updatedAt}Z`),
    }));

    /** If field rep, Pin RepUploads then POA Docs
     * Legend: docType, respType, mod
     * Rep Uploads 4 + n/a + 1 = 5
     * POA Document 3 + 1 + 0.5 = 4.5
     * POA ID 0.5 + 1 + 0.5 = 2
     * Guardian/Conservator Document 1 + 0 + 0.5= 1.5
     * Guardian/Conservator ID 0.5 + 0 + 0.5 = 1
     * -1 if not present
     */
    if (isFieldRep) {
      return this.sortChecklistItems(
        checklistItems,
        [
          ChecklistItemDocTypes.RepUploads,
          ChecklistItemDocTypes.POADocument,
          ChecklistItemDocTypes.SpousePOADocument,
          ChecklistItemDocTypes.GuardianshipConservatorDocument,
          ChecklistItemDocTypes.DriversLicensePhotoID,
        ],
        [
          ChecklistItemResponsiblePartyTypes.POA,
          ChecklistItemResponsiblePartyTypes.Guardian,
        ]
      );
    }

    // If DVT, Pin DVTUploads
    if (isDVT) {
      return this.sortChecklistItems(
        checklistItems,
        [ChecklistItemDocTypes.DVTUploads],
        undefined
      );
    }

    return checklistItems;
  }

  sortChecklistItems(
    checklistItems: UIChecklistItem[],
    documentTypeToPin: ChecklistItemDocTypes[] | undefined,
    responsiblePartyTypeToPin: ChecklistItemResponsiblePartyTypes[] | undefined
  ) {
    documentTypeToPin = documentTypeToPin?.reverse();
    responsiblePartyTypeToPin = responsiblePartyTypeToPin?.reverse();
    return orderBy(
      checklistItems,
      [
        (item) => {
          let ordered = -1;
          if (
            item.documentType &&
            documentTypeToPin &&
            documentTypeToPin.length > 0 &&
            documentTypeToPin.includes(item.documentType)
          ) {
            ordered = 0;
            const idxMod =
              documentTypeToPin.indexOf(item.documentType) === 0
                ? 0.5
                : documentTypeToPin.indexOf(item.documentType);

            ordered = ordered + idxMod + 0.5;

            // Since items like RepUploads do not have a unique specific responsible party type
            // If is here, but not in the other one then add 0.5
            if (
              !(
                item.responsiblePartyType &&
                responsiblePartyTypeToPin &&
                responsiblePartyTypeToPin.length > 0 &&
                responsiblePartyTypeToPin.includes(item.responsiblePartyType)
              )
            ) {
              ordered = ordered + 0.5;
            }
          }

          if (
            item.responsiblePartyType &&
            responsiblePartyTypeToPin &&
            responsiblePartyTypeToPin.length > 0 &&
            responsiblePartyTypeToPin.includes(item.responsiblePartyType)
          ) {
            ordered = Math.max(0, ordered);
            ordered =
              ordered +
              responsiblePartyTypeToPin.indexOf(item.responsiblePartyType);
          }

          return ordered;
        },
      ],
      ["desc", "desc"]
    );
  }

  setChecklistItems = async (shouldRefreshedGrid = false) => {
    if (applicationStore.application?.id) {
      await checklistApiClient
        .getApplicationChecklist(applicationStore.application?.id)
        .then((items) => {
          runInAction(() => {
            this.checklistItems = items;
          });
          if (shouldRefreshedGrid) {
            this.setGridLastRefreshedAt(new Date());
          }
        });
    }
  };

  updateChecklistItem = async (id: string, checklistItem: ChecklistItem) => {
    await checklistApiClient
      .updateChecklistItem(id, checklistItem)
      .then(() => this.setChecklistItems());

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Checklist Item Updated
        </div>
      ),
      intent: Intent.SUCCESS,
    });
  };

  updateChecklistItem_NoGridRefresh = async (
    id: string,
    checklistItem: ChecklistItem
  ) => {
    await checklistApiClient
      .updateChecklistItem(id, checklistItem)
      .then(() => this.setChecklistItems());

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Checklist Item Updated
        </div>
      ),
      intent: Intent.SUCCESS,
    });
  };

  createChecklistItem = async (checklistItem: Partial<NewChecklistItem>) => {
    const createdChecklistItem = await checklistApiClient.createChecklistItem({
      ...checklistItem,
      applicationId: applicationStore.application?.id,
    });

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Checklist Item Created.
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    runInAction(() => {
      this.checklistItems = [createdChecklistItem, ...this.checklistItems];
      this.setGridLastRefreshedAt(new Date());
    });
  };

  generateApplicationChecklist = async (applicationId: string) => {
    await checklistApiClient.generateApplicationChecklist(applicationId);

    AppToaster.show({
      message: (
        <div>
          <h3>Success</h3>Checklist Generated.
        </div>
      ),
      intent: Intent.SUCCESS,
    });

    this.setChecklistItems(true);
  };

  setGridLastRefreshedAt = (refreshedAt: Date) => {
    runInAction(() => {
      this.gridLastRefreshedAt = refreshedAt;
    });
  };
}

export const checklistItemStore = new ChecklistItemStore();
