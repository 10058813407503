import { FC } from "react";
import RelativityCard from "../../Card/RelativityCard";
import { ChecklistItem } from "../../../types/Checklist";
import { MultiSelectFieldValue } from "../../Fields/types/fieldTypes";
import { getReadonlyAggregatedStatementsOptions } from "../../../utils/getStatementOptions";
import { BaseField } from "../../Forms/BaseField/BaseField";

import { checklistApiClient } from "../../../lib/apiClients/checklist/checklistApiClient";
import { useDocumentStatementsReceived } from "./useDocumentStatementsReceived";

export interface DocumentStatementsReceivedProps {
  checklistItem: ChecklistItem;
  statementsNeeded?: string[];
  documentId?: string;
  refreshChecklistItem: () => void;
  isSaving?: boolean;
  setIsSaving?: (isSaving: boolean) => void;
  onSave?: () => void;
}

export const DocumentStatementsReceived: FC<DocumentStatementsReceivedProps> = (
  props
) => {
  const { document } = useDocumentStatementsReceived(props.documentId);
  return (
    <RelativityCard headerContent="Document Statements Received">
      {props.checklistItem.useStatementsReceived &&
        document &&
        props.statementsNeeded && (
          <BaseField
            type="Multiselect"
            label="Statements Received"
            value={document.statementsReceived_AsList as MultiSelectFieldValue}
            optionValues={getReadonlyAggregatedStatementsOptions(
              props.statementsNeeded
            )}
            onSubmit={(statements) => {
              if (document) {
                checklistApiClient
                  .updateChecklistItemStatementsReceived(
                    props.checklistItem.id,
                    document.id,
                    statements as string[]
                  )
                  .then(() => {
                    props.refreshChecklistItem();
                    props.onSave && props.onSave();
                  });
              }
            }}
            disabled={props.isSaving}
          />
        )}
    </RelativityCard>
  );
};
