import { Classes, Dialog, Button, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { useEffect, useState } from "react";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { Loading } from "../Loading/Loading";

export interface ImageViewerDialogProps extends BaseDialogProps {}

export const ImageViewerDialog: React.FC<ImageViewerDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [base64String, setBase64String] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    if (!!dialogsViewerStore.imageViewerDialogOptions?.documentId) {
      setIsLoading(true);
      documentApiClient
        .previewImageDocumentById(
          dialogsViewerStore.imageViewerDialogOptions?.documentId
        )
        .then(setBase64String)
        .then(() => setIsLoading(false));
    }
  }, [dialogsViewerStore.imageViewerDialogOptions?.documentId]);
  return (
    <Dialog
      portalClassName="mdr-dialog"
      className="image-viewer"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Preview Image"}
      onClose={closeDialog}
      canEscapeKeyClose={true}
    >
      <div className={`${Classes.DIALOG_BODY}__image-viewer`}>
        <Observer>
          {() => (
            <>
              <section className="container">
                {base64String && !isLoading && (
                  <img src={base64String} alt="Preview Image" />
                )}
                {isLoading && <Loading />}
              </section>
            </>
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
