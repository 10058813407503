import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCheck,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface DocumentFilingComponentReviewItemProps {
  title: string;
  content: string;
  icon?: IconDefinition;
  iconClick?: () => void;
}
export const DocumentFilingComponentReviewItem: React.FC<
  DocumentFilingComponentReviewItemProps
> = (props) => {
  return (
    <li
      className={classNames({
        "fiafiling-content-form-review-disabled": !props.content,
      })}
    >
      <FontAwesomeIcon
        className={classNames({ clickable: props.iconClick })}
        size="lg"
        icon={props.icon ?? faSquareCheck}
        onClick={props.iconClick && props.iconClick}
      />
      <span className="fiafiling-content-form-review-category">
        <span className="fiafiling-content-form-review-category-title">
          {props.title}
        </span>
        <span className="fiafiling-content-form-review-category-content">
          {props.content}
        </span>
      </span>
    </li>
  );
};
