import { ExistingResource } from "./ExistingResource";

export const templatesFolderName = "mdrtemplates";

export interface TemplateData {
  templateName: string;
  templateType: number;
  templateStates_AsList?: number[];
  documentType?: number;
  sendOrder: number;
  documentId?: string;
}
export interface Template extends TemplateData {
  originalFileName: string;
  fileName: string;
  fileFormData?: string;
}

export type UpdateTemplate = ExistingTemplate & {
  validatePagesCount: boolean;
};

export type TemplateFileFormDataUpdate = {
  id: string;
  fileFormData?: string;
};

export type ExistingTemplate = ExistingResource<Template>;
