import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { reactPlugin } from "../../AppInsights";
import variables from "../../config/variables";
import "../../pages/styles.scss";
import { userStore } from "../../stores/UserStore";
import { Invoice } from "../../types/Invoice";
import { GridFiltersModule } from "../Grid/Filters/Filters";
import { Grid } from "../Grid/Grid/Grid";
import { GridPermissions } from "../Grid/types/GridPermissions";
import { GridViewPicker } from "../Grid/Views/GridViewPicker";
import {
  invoiceGridColumnsConfiguration,
  setupActions,
} from "./configurations/gridColumnsConfiguration";
import { GridFilterSettings } from "../Grid/Grid/types/GridFilterSettings";
import baseApiClient from "../../lib/apiClients/baseApiClient";
import { autorun } from "mobx";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { useGridView } from "@ucl/library";
import { Button, Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { invoiceApiClient } from "../../lib/apiClients/invoice/invoiceApiClient";
import { AppToaster } from "../Toast/Toast";

export interface InvoiceGridProps extends Partial<GridPermissions> {}

const InvoiceGridComponent: React.FC<InvoiceGridProps> = ({
  canConfigureBoard,
  canExportGridToExcel,
}) => {
  const gridKey = "invoice-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);
  const [selectedInvoices, setSelectedInvoices] = useState<number>(0);

  const gridView = useGridView(
    gridKey,
    "Invoice Board",
    invoiceGridColumnsConfiguration,
    () => invoiceGridColumnsConfiguration,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
      setSelectedInvoices(gridStore.selectedRowsCount);
    });
  }, []);

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>
            {canConfigureBoard && (
              <GridViewPicker
                gridView={gridView.currentGridView}
                baseGridColumnsConfiguration={
                  gridView.baseGridColumnsConfiguration
                }
                updateViewColumnSettings={gridView.updateViewColumnSettings}
              />
            )}
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            <GridFiltersModule />
          </li>
          <li>
            <Button
              intent={Intent.PRIMARY}
              text="Send Emails"
              disabled={selectedInvoices === 0}
              onClick={() => {
                dialogsViewerStore.setIsConfirmDialogOpen(true, {
                  content: `Are you sure you want to send emails for the selected invoices?`,
                  onConfirm: async () => {
                    try {
                      const invoiceIds = gridStore.getSelectedRecords?.map(
                        (invoice) => (invoice as Invoice).id
                      );

                      await invoiceApiClient.sendBulkEmails(invoiceIds || []);
                      AppToaster.show({
                        message: (
                          <div>
                            <h3>Success</h3>Emails Sent
                          </div>
                        ),
                        intent: Intent.SUCCESS,
                      });
                      gridStore.refresh();
                    } catch {
                      AppToaster.show({
                        message: (
                          <div>
                            <h3>Error</h3>Emails Could Not Be Sent
                          </div>
                        ),
                        intent: Intent.DANGER,
                      });
                    }

                    return { isSuccess: true, error: null };
                  },
                });
              }}
            />
          </li>
        </ul>
      </section>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && (
                <Grid<Invoice>
                  key={gridView.currentGridViewKey}
                  gridKey={gridKey}
                  setupActions={(columns) =>
                    setupActions(
                      columns,
                      !!userStore.user?.canViewInvoiceDocument,
                      !!userStore.user?.canSendInvoice,
                      !!userStore.user?.canRegenerateInvoiceDocument
                    )
                  }
                  dataSource={`${variables.apiBaseUrl}odata/invoiceodata`}
                  // Rather than saving the view for invoices, we use the
                  //static config. We Do not allow custom column configs for this grid
                  columnsConfiguration={[...invoiceGridColumnsConfiguration]}
                  filterConfiguration={[
                    ...(gridView.currentGridView.filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(gridView.currentGridView.sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    allowExcelExport: canExportGridToExcel,
                    exportFilename: "Invoicing.xlsx",
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};

export const InvoiceGrid = withAITracking(
  reactPlugin,
  InvoiceGridComponent,
  "InvoiceGrid",
  "AI-flex-container"
);
