import { Button, ButtonProps, Intent } from "@blueprintjs/core";
import { ColumnModel, GridColumnModel } from "@syncfusion/ej2-react-grids";
import _ from "lodash";
import React from "react";
import { GridInstanceContext } from "../Grid";
import GridColumn from "./GridColumn";

export const GridActionType = "action";
export interface GridAction<T extends object>
  extends Omit<ButtonProps, "onClick" | "minimal" | "disabled" | "intent"> {
  disabled?: (data: T) => boolean;
  intent?: (data: T) => Intent;
  onActionClick?: (data: T) => void;
  alwaysEnabled?: boolean;
}

interface GridActionsColumnOptions<T extends object>
  extends Omit<
    ColumnModel,
    "field" | "type" | "showInColumnChooser" | "allowFiltering" | "allowSorting"
  > {
  actions: GridAction<T>[];
}

interface GridActionsColumnDirectiveOptions<T extends object>
  extends Omit<
    GridColumnModel,
    "field" | "type" | "showInColumnChooser" | "allowFiltering" | "allowSorting"
  > {
  actions: GridAction<T>[];
}

export const GridActionsColumnDirective = <T extends object>(
  props: GridActionsColumnDirectiveOptions<T>
) => {
  return {
    ...props,
    field: "",
    showInColumnChooser: false,
    allowFiltering: false,
    allowSorting: false,
    type: GridActionType,
    template: ((data: T) => {
      const gridInstance = React.useContext(GridInstanceContext);

      return (
        <div className="grid-actions">
          {props.actions.map((action, idx) => (
            <Button
              key={idx}
              {...action}
              minimal={true}
              disabled={action.disabled ? action.disabled(data) : false}
              intent={action.intent ? action.intent(data) : Intent.NONE}
              onClick={() => {
                if (!action.alwaysEnabled) {
                  const isDisabled = (
                    _.get(gridInstance, "htmlattributes") as unknown as {
                      readOnly: boolean;
                    }
                  )?.readOnly;

                  if (isDisabled) {
                    return;
                  }
                }

                action.onActionClick && action.onActionClick(data);
              }}
            />
          ))}
        </div>
      );
    }) as any,
  };
};

export default class GridActionsColumn<T extends object> extends GridColumn {
  constructor(opts: GridActionsColumnOptions<T>) {
    super(GridActionsColumnDirective(opts));
  }
}
