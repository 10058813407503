import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { useState } from "react";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { FIARequestCancelDTO } from "../FIARequests/configurations/types";
import { BaseField } from "../Forms/BaseField/BaseField";
import { fiaRequestFormSchema } from "../Forms/FIARequestForm/FIARequestForm";
import { applicationStore } from "../../stores/ApplicationStore";

export interface CancelAllFIARequestsDialogProps extends BaseDialogProps {
  applicationId?: string;
  setIsCancelLoading?: (isCanceling: boolean) => void;
  onSuccessfulCancel?: () => void;
}

export const CancelAllFIARequestsDialog: React.FC<
  CancelAllFIARequestsDialogProps
> = ({
  isOpen,
  closeDialog,
  submitDialog,
  applicationId,
  setIsCancelLoading,
  onSuccessfulCancel,
}) => {
  const newFIACancelRequest: FIARequestCancelDTO = {
    cancelReasons_AsList: [],
  };

  const formId = "cancel-all-fia-requests-dialog";

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localCancelReasons, setLocalCancelReasons] =
    useState<FIARequestCancelDTO>(newFIACancelRequest);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  return (
    <>
      <Dialog
        portalClassName="mdr-dialog"
        isOpen={isOpen}
        hasBackdrop={false}
        isCloseButtonShown={true}
        title={"Cancel All Active FIA Requests on Application"}
        onClose={() => {
          if (setIsCancelLoading) {
            setIsCancelLoading(false);
          }
          closeDialog();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <BaseForm<FIARequestCancelDTO>
            formId={formId}
            value={localCancelReasons}
            formValueState={{
              formValue: localCancelReasons,
              setFormValue: setLocalCancelReasons,
            }}
            onErrors={(errors) => setErrors(errors)}
            onFormSubmit={async (fiaCancelRequest) => {
              setIsSaving(true);

              await applicationStore
                .cancelAllFIARequests(applicationId || "", fiaCancelRequest)
                .then(() => {
                  if (setIsCancelLoading) {
                    setIsCancelLoading(false);
                  }
                  closeDialog();
                  submitDialog();
                  onSuccessfulCancel?.();
                })
                .finally(() => {
                  setIsSaving(false);
                });
            }}
          >
            <div className="cancel-fia-field-container">
              <div>
                Are you sure you want to cancel any <b>Open</b> and{" "}
                <b>Submitted</b> FIA Requests?
              </div>

              <div className="cancel-fia-fields">
                <BaseField
                  {...fiaRequestFormSchema.cancelReason(
                    localCancelReasons,
                    setLocalCancelReasons,
                    errors
                  )}
                />
              </div>
            </div>
          </BaseForm>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="No"
            onClick={() => {
              if (setIsCancelLoading) {
                setIsCancelLoading(false);
              }
              closeDialog();
            }}
          />

          <FormSubmitButton
            buttonId="cancel-all-fia-requests-dialog-submit"
            isSaving={isSaving}
            formId={formId}
            text="Yes"
          />
        </div>
      </Dialog>
    </>
  );
};
