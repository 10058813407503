import { NumericField } from "../NumericField/NumericField";
import { PercentageFieldProps } from "../types/fieldTypes";

export const PercentageField: React.FC<PercentageFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
}) => {
  return (
    <NumericField
      type="Text"
      description={description}
      label={label}
      numericFieldProps={{
        format: "p2",
        placeholder: "",
      }}
      value={value}
      formatValue={(value) => (value ? value / 100 : value)}
      disabled={disabled}
      onSubmit={onSubmit}
      hidden={hidden}
      errorMessages={errorMessages}
      isRequired={isRequired}
    />
  );
};
