import {
  Comment,
  CommentFilters,
  getCommentLevel,
  getCommentSource,
} from "../../../types/Comment";
import { AllFields, Option } from "../../Fields/types/fieldTypes";

export const getDistinctApplicationIdValues = (comments: Comment[]) => {
  const distinctApplicationIds: Option[] = [];
  comments.forEach((comment: Comment) => {
    if (
      !distinctApplicationIds.some((x) => x.value === comment.applicationId)
    ) {
      distinctApplicationIds.push({
        label: comment.application_FileNumber,
        value: comment.applicationId,
      });
    }
  });

  return distinctApplicationIds;
};

export const getDistinctLevelValues = (comments: Comment[]) => {
  const distinctLevels: Option[] = [];
  comments.forEach((comment: Comment) => {
    if (!distinctLevels.some((x) => x.value === String(comment.level))) {
      distinctLevels.push({
        label: getCommentLevel(comment.level),
        value: String(comment.level),
      });
    }
  });

  return distinctLevels;
};

export const getDistinctSourceValues = (comments: Comment[]) => {
  const distinctSources: Option[] = [];
  comments.forEach((comment: Comment) => {
    if (!distinctSources.some((x) => x.value === String(comment.source))) {
      distinctSources.push({
        label: getCommentSource(comment.source),
        value: String(comment.source),
      });
    }
  });

  return distinctSources;
};

const getDistinctCreatedByValues = (comments: Comment[]) => {
  const distinctCreatedByIds: Option[] = [];
  comments.forEach((comment: Comment) => {
    if (!distinctCreatedByIds.some((x) => x.value === comment.createdBy)) {
      distinctCreatedByIds.push({
        label: comment.createdBy_AsName,
        value: comment.createdBy,
      });
    }
  });

  return distinctCreatedByIds;
};

const getCommentSortByValues = () => {
  const sortByValues: Option[] = [
    { label: "Created At", value: "1" },
    { label: "Created By", value: "2" },
    { label: "File #", value: "3" },
    { label: "Public/Internal", value: "4" },
    { label: "Source", value: "5" },
  ];

  return sortByValues;
};

export const commentFilteringFormSchema = {
  applicationIds: (
    commentFilters: CommentFilters,
    setCommentFilters: (commentFilters: CommentFilters) => void,
    comments: Comment[],
    fieldKey: boolean,
    shouldFetchRelatedComments: boolean,
    loadComments: (
      shouldFetchRelatedComments: boolean,
      filters: CommentFilters | undefined
    ) => void
  ) => {
    const config = {
      type: "Multiselect",
      label: "File #",
      fieldKey: fieldKey.toString(),
      value: commentFilters?.applicationIds,
      optionValues: getDistinctApplicationIdValues(comments),
      sortItemListAlphabetically: true,
      onSubmit: (applicationIds: string[]) => {
        const commentFiltersToSet = { ...commentFilters, applicationIds };
        setCommentFilters(commentFiltersToSet);
        loadComments(shouldFetchRelatedComments, commentFiltersToSet);
      },
    } as AllFields;

    return config;
  },
  levels: (
    commentFilters: CommentFilters,
    setCommentFilters: (commentFilters: CommentFilters) => void,
    comments: Comment[],
    fieldKey: boolean,
    shouldFetchRelatedComments: boolean,
    loadComments: (
      shouldFetchRelatedComments: boolean,
      filters: CommentFilters | undefined
    ) => void
  ) => {
    const config = {
      type: "Multiselect",
      label: "Public/Internal",
      fieldKey: fieldKey.toString(),
      value: commentFilters?.levels,
      optionValues: getDistinctLevelValues(comments),
      sortItemListAlphabetically: true,
      onSubmit: (levels: string[]) => {
        const commentFiltersToSet = { ...commentFilters, levels };
        setCommentFilters(commentFiltersToSet);
        loadComments(shouldFetchRelatedComments, commentFiltersToSet);
      },
    } as AllFields;

    return config;
  },
  sources: (
    commentFilters: CommentFilters,
    setCommentFilters: (commentFilters: CommentFilters) => void,
    comments: Comment[],
    fieldKey: boolean,
    shouldFetchRelatedComments: boolean,
    loadComments: (
      shouldFetchRelatedComments: boolean,
      filters: CommentFilters | undefined
    ) => void
  ) => {
    const config = {
      type: "Multiselect",
      label: "Source",
      fieldKey: fieldKey.toString(),
      value: commentFilters?.sources,
      optionValues: getDistinctSourceValues(comments),
      sortItemListAlphabetically: true,
      onSubmit: (sources: string[]) => {
        const commentFiltersToSet = { ...commentFilters, sources };
        setCommentFilters(commentFiltersToSet);
        loadComments(shouldFetchRelatedComments, commentFiltersToSet);
      },
    } as AllFields;

    return config;
  },
  createdBy: (
    commentFilters: CommentFilters,
    setCommentFilters: (commentFilters: CommentFilters) => void,
    comments: Comment[],
    fieldKey: boolean,
    shouldFetchRelatedComments: boolean,
    loadComments: (
      shouldFetchRelatedComments: boolean,
      filters: CommentFilters | undefined
    ) => void
  ) => {
    const config = {
      type: "Multiselect",
      label: "Created By",
      fieldKey: fieldKey.toString(),
      value: commentFilters?.createdByIds,
      optionValues: getDistinctCreatedByValues(comments),
      sortItemListAlphabetically: true,
      onSubmit: (createdByIds: string[]) => {
        const commentFiltersToSet = { ...commentFilters, createdByIds };
        setCommentFilters(commentFiltersToSet);
        loadComments(shouldFetchRelatedComments, commentFiltersToSet);
      },
    } as AllFields;

    return config;
  },
  createdAt: (
    commentFilters: CommentFilters,
    setCommentFilters: (commentFilters: CommentFilters) => void,
    shouldFetchRelatedComments: boolean,
    loadComments: (
      shouldFetchRelatedComments: boolean,
      filters: CommentFilters | undefined
    ) => void
  ) => {
    const config = {
      type: "DateRange",
      label: "Created At",
      value: commentFilters?.createdAtRange,
      onSubmit: (createdAtRange: [Date | null, Date | null]) => {
        const commentFiltersToSet = { ...commentFilters, createdAtRange };
        setCommentFilters(commentFiltersToSet);
        loadComments(shouldFetchRelatedComments, commentFiltersToSet);
      },
    } as AllFields;

    return config;
  },
  sortBy: (
    commentFilters: CommentFilters,
    setCommentFilters: (commentFilters: CommentFilters) => void,
    fieldKey: boolean,
    shouldFetchRelatedComments: boolean,
    loadComments: (
      shouldFetchRelatedComments: boolean,
      filters: CommentFilters | undefined
    ) => void
  ) => {
    const config = {
      type: "Select",
      label: "Sort By",
      placeholder: "Sort by...",
      labelPosition: "top",
      fieldKey: fieldKey.toString(),
      value: commentFilters?.sortBy,
      shouldShowClearButton: true,
      optionValues: getCommentSortByValues(),
      onSubmit: (sortBy: Option) => {
        const commentFiltersToSet = {
          ...commentFilters,
          sortBy: sortBy?.value,
        };
        setCommentFilters(commentFiltersToSet);
        loadComments(shouldFetchRelatedComments, commentFiltersToSet);
      },
    } as AllFields;

    return config;
  },
};

export const defaultCommentFilters: CommentFilters = {
  applicationIds: [],
  levels: [],
  sources: [],
  createdAtRange: [null, null],
  createdByIds: [],
  sortBy: undefined,
  sortAscending: false,
};
