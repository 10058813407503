import { Comment } from "../../types/Comment";
import { Bill } from "./Bill";
import { Income } from "./Income";
import { Asset } from "./Asset";
import { MedicalCoverage } from "./MedicalCoverage";
import { FIARequestODataReadDTO } from "../../components/FIARequests/configurations/types";
import { ChecklistItem } from "../Checklist";
import { ApplicationBillAmount } from ".";
import { ExistingMedicaidEligibilityDecision } from "../MedicaidEligibilityDecision";
import { Facility } from "../Facility";
export interface ApplicationSummaryResponseDTO {
  id?: string;

  applicantFullName?: string;

  fieldRepId?: string;
  fieldRepFullName?: string;
  processorId?: string;
  processorFullName?: string;
  region?: string;

  //Facility
  facilityId?: string;
  facilityName?: string;
  facilityFullAddress_FormattedAsString?: string;
  facilityPhone_FormattedAsString?: string;
  facilityFax_FormattedAsString?: string;
  isFacilityStateFL?: boolean;
  facility?: Facility;

  //Application Info
  fileNumber?: string;
  productType?: number;
  productType_AsString?: string;
  status_AsString?: string;
  internalStatus_AsString?: string;
  reasonCode_AsString?: string;
  currentAssessment_AsString?: string;
  redFlags_AsString?: string[];

  //Dates
  applicationDate_FormattedAsString?: string;
  repHandoffDate_FormattedAsString?: string;
  admissionDate_FormattedAsString?: string;
  medicaidNeededDate_FormattedAsString?: string;
  monthsUntilMedicaidNeeded?: number;
  dueDate_FormattedAsString?: string;
  stateAppDate_FormattedAsString?: string;
  lastAppliedDate_FormattedAsString?: string;
  deniedDate_FormattedAsString?: string;
  createdDate_FormattedAsString?: string;
  approvedDate_FormattedAsString?: string;
  medicaidEligibleDate_FormattedAsString?: string;
  nextRecertDate_FormattedAsString?: string;

  //Comments
  comments: Comment[];

  //Financial Summary Calcs
  combinedSocialSecurityAmount?: number;
  combinedSSIAmount?: number;
  combinedVABenefitsAmount?: number;
  statesPersonCareAllowance?: number;

  billAmounts?: ApplicationBillAmount[];
  billItemsTotal?: number;
  medicalCoveragePremiums?: number;
  estPatientLiabilityItemsTotal?: number;

  //Owned Entities - Income
  fiaRequests?: FIARequestODataReadDTO[];
  checklistItems?: ChecklistItem[];
  outstandingDocumentsCount?: number;
  outstandingFIARequestsCount?: number;
  assets: Asset[];
  bills: Bill[];
  incomes: Income[];
  medicalCoverages: MedicalCoverage[];

  isRecertProductType?: boolean;
  medicaidEligibilityDecision?: ExistingMedicaidEligibilityDecision;
  medicaidEligibilityStatus_AsString?: string;
  isMarried?: boolean;
  isSpouseLTCFacility?: boolean;
  useResidentPlusHalfJointAssetsCalculation?: boolean;
  recertResidentPlusHalfJointAssetsValue?: number;
  overrideAssetEligibility?: boolean;

  // Income flag
  includeAllIncomesInSummary?: boolean;
  includeAllAssetsInSummary?: boolean;
  //Resident Income - AMD
  socialSecurityAmount?: number;
  ssiAmount?: number;
  vaBenefitsAmount?: number;
  residentMonthlyIncomeValue?: number;
  residentIncomeItemsTotal?: number;

  //Spouse Income - AMD
  spouseSocialSecurityAmount?: number;
  spouseSSIAmount?: number;
  spouseVABenefitsAmount?: number;
  spouseMonthlyIncomeValue?: number;
  spouseIncomeItemsTotal?: number;

  // Joint Income - AMD
  jointMonthlyIncomeValue?: number;

  //Resident Assets - AMD
  residentAssetPropertyValue?: number;
  residentAssetVehicleValue?: number;
  residentAssetBankAccountValue?: number;
  residentAssetLifeInsuranceValue?: number;
  residentAssetBurialPreneedValue?: number;
  residentAssetOtherValue?: number;
  residentAssetItemsTotalValue?: number;
  // Spouse Assets - AMD
  spouseAssetPropertyValue?: number;
  spouseAssetVehicleValue?: number;
  spouseAssetBankAccountValue?: number;
  spouseAssetLifeInsuranceValue?: number;
  spouseAssetBurialPreneedValue?: number;
  spouseAssetOtherValue?: number;
  spouseAssetItemsTotalValue?: number;
  //Joint Assets - AMD
  jointTypeAssetPropertyValue?: number;
  jointTypeAssetVehicleValue?: number;
  jointTypeAssetBankAccountValue?: number;
  jointTypeAssetLifeInsuranceValue?: number;
  jointTypeAssetBurialPreneedValue?: number;
  jointTypeAssetOtherValue?: number;
  jointTypeAssetItemsTotalValue?: number;
  //Resident Income - AMD
  combinedAdditionalIncomeAmount?: number;
  combinedIncomeItemsTotal?: number;

  //Resident Assets - AMD
  combinedAssetPropertyValue?: number;
  combinedAssetVehicleValue?: number;
  combinedAssetBankAccountValue?: number;
  combinedAssetLifeInsuranceValue?: number;
  combinedAssetBurialPreneedValue?: number;
  combinedAssetOtherValue?: number;
  combinedAssetItemsTotal?: number;

  //Application Date Timeline Dates
  lastAppliedDate?: string;
  dueDate?: string;
  hearingDate?: string;
  interviewDate?: string;
  extensionRequestDate?: string;
  qcAuditDate?: string;
  postApprovalDate?: string;
  applyDeadlineDate?: string;
  reapplyDeadlineDate?: string;
  interviewDeadlineDate?: string;
  hardDeadlineDate?: string;
  extensionRequestDeadlineDate?: string;
  extendedDeadlineDate?: string;
  appealDeadlineDate?: string;
  nextDeadlineDate?: string;

  dateTimelineShouldIncludeApplyDeadlineDate?: boolean;
  dateTimelineShouldIncludeReapplyDeadlineDate?: boolean;
  dateTimelineShouldIncludeInterviewDate?: boolean;
  dateTimelineShouldIncludeInterviewDeadlineDate?: boolean;
}

export const defaultApplicationSummaryResponseDTO: ApplicationSummaryResponseDTO =
  {
    id: undefined,

    applicantFullName: undefined,

    fieldRepId: undefined,
    fieldRepFullName: undefined,
    processorId: undefined,
    processorFullName: undefined,
    region: undefined,

    //Facility
    facilityId: undefined,
    facilityName: undefined,
    facilityFullAddress_FormattedAsString: undefined,
    facilityPhone_FormattedAsString: undefined,
    facilityFax_FormattedAsString: undefined,
    facility: undefined,

    //Application Info
    fileNumber: undefined,
    productType: undefined,
    productType_AsString: undefined,
    status_AsString: undefined,
    internalStatus_AsString: undefined,
    reasonCode_AsString: undefined,
    currentAssessment_AsString: undefined,

    //Dates
    applicationDate_FormattedAsString: undefined,
    repHandoffDate_FormattedAsString: undefined,
    admissionDate_FormattedAsString: undefined,
    medicaidNeededDate_FormattedAsString: undefined,
    monthsUntilMedicaidNeeded: undefined,
    dueDate_FormattedAsString: undefined,
    stateAppDate_FormattedAsString: undefined,
    lastAppliedDate_FormattedAsString: undefined,
    deniedDate_FormattedAsString: undefined,
    createdDate_FormattedAsString: undefined,
    approvedDate_FormattedAsString: undefined,
    medicaidEligibleDate_FormattedAsString: undefined,
    nextRecertDate_FormattedAsString: undefined,

    comments: [],
    assets: [],
    bills: [],
    incomes: [],
    medicalCoverages: [],

    isRecertProductType: undefined,
    medicaidEligibilityDecision: undefined,
    medicaidEligibilityStatus_AsString: undefined,
    isMarried: undefined,
    isSpouseLTCFacility: undefined,
    useResidentPlusHalfJointAssetsCalculation: undefined,
    recertResidentPlusHalfJointAssetsValue: undefined,
    overrideAssetEligibility: undefined,

    // Timeline Dates
    lastAppliedDate: undefined,
    dueDate: undefined,
    hearingDate: undefined,
    interviewDate: undefined,
    extensionRequestDate: undefined,
    qcAuditDate: undefined,
    postApprovalDate: undefined,
    applyDeadlineDate: undefined,
    reapplyDeadlineDate: undefined,
    interviewDeadlineDate: undefined,
    hardDeadlineDate: undefined,
    extensionRequestDeadlineDate: undefined,
    extendedDeadlineDate: undefined,
    appealDeadlineDate: undefined,
    nextDeadlineDate: undefined,
    dateTimelineShouldIncludeApplyDeadlineDate: undefined,
    dateTimelineShouldIncludeReapplyDeadlineDate: undefined,
    dateTimelineShouldIncludeInterviewDate: undefined,
    dateTimelineShouldIncludeInterviewDeadlineDate: undefined,
  };
