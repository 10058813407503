import { useEffect, useState } from "react";
import { newApplicationChild } from "../Forms/ApplicationPage/ApplicationChildForm";
import { ApplicationChild } from "../../types/Application/ApplicationChild";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { applicationStore } from "../../stores/ApplicationStore";
import { facilityApiClient } from "../../lib/apiClients/facility/facilityApiClient";
import { useNavigate } from "react-router";

export const useApplicationChildCreationDialog = (props: {
  submitDialog: () => void;
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localApplicationChild, setLocalApplicationChild] =
    useState(newApplicationChild);

  const navigate = useNavigate();

  useEffect(() => {
    setLocalApplicationChild({
      ...localApplicationChild,
      facilityId:
        dialogsViewerStore.applicationChildCreationDialogOptions?.facilityId,
    });
    getApplicationFacility(
      dialogsViewerStore.applicationChildCreationDialogOptions?.facilityId
    );
    return () => {
      setLocalApplicationChild(newApplicationChild);
    };
  }, [dialogsViewerStore.applicationChildCreationDialogOptions?.facilityId]);

  const submitChildApplicationForm = async (
    applicationChild: ApplicationChild
  ) => {
    setIsSaving(true);

    const facility = await getApplicationFacility(applicationChild.facilityId);

    //Facility is blank / empty or inactive
    if (!facility || (!!facility && facility?.isActive === false)) {
      await displayEmptyOrInactiveFacilityWarningDialog();
    } else {
      await createApplicationChildAndNavigate(applicationChild);
    }
  };

  const getApplicationFacility = async (facilityId?: string) => {
    if (facilityId) {
      const facility = await facilityApiClient.getFacilityDetail(facilityId);
      return facility;
    }
  };

  const createApplicationChildAndNavigate = async (
    applicationChild: ApplicationChild
  ) => {
    if (applicationStore.application?.id) {
      try {
        const application = await applicationApiClient.createApplicationChild(
          applicationStore.application.id,
          applicationChild
        );

        props.submitDialog();
        gridStore.refresh();

        if (application.isApplicationInNewProductType) {
          navigate(`/application-form/${application?.id}/primary-information`);
        } else {
          navigate(`/application-page/${application?.id}`);
        }
      } finally {
        setIsSaving(false);
      }
    }
  };

  const displayEmptyOrInactiveFacilityWarningDialog = async () => {
    dialogsViewerStore.confirm({
      content: (
        <>
          The Facility associated with this Application is Inactive or Blank.
          Choose a Facility which is Active for the Creation of Child
          Application
        </>
      ),
      onClose: () => {
        setIsSaving(false);
      },
      onConfirm: async () => {
        const result = {
          isSuccess: true,
          error: undefined,
        };
        setIsSaving(false);
        return result;
      },
      shouldHideCancelButton: true,
      confirmButtonText: "Close",
      confirmButtonIntent: "danger",
      confirmHeaderText: "Confirm - Facility is Inactive or Blank",
    });
  };

  return {
    isSaving,
    localApplicationChild,
    submitChildApplicationForm,
  };
};
