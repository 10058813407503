import "./styles.scss";

export interface RelativitySubCardHeaderProps {
  label: string;
  children?: React.ReactNode;
}
export const RelativitySubCardHeader: React.FC<RelativitySubCardHeaderProps> = (
  props
) => {
  return (
    <div className="relativity-subcard-header">
      <h4 className="bp4-heading">{props.label}</h4>
      {props.children}
    </div>
  );
};
