import { action, makeObservable, observable } from "mobx";
import { OwnedEntityViewerStore } from "./OwnedEntityViewerStore";
import { MedicalCoverage } from "../../types/Application/MedicalCoverage";

export interface MedicalCoverageStore {
  addLocalMedicalCoverage: (medicalCoverageToAdd: MedicalCoverage) => void;
  removeLocalMedicalCoverage: (
    medicalCoverageToRemove: MedicalCoverage
  ) => void;
  saveLocalMedicalCoverages: () => void;
  localMedicalCoverages?: MedicalCoverage[];
}

export class MedicalCoverageOwnedEntityStore
  extends OwnedEntityViewerStore
  implements MedicalCoverageStore
{
  public localMedicalCoverages?: MedicalCoverage[] = [];

  constructor() {
    super();
    makeObservable(this, {
      localMedicalCoverages: observable,
      addLocalMedicalCoverage: action,
      removeLocalMedicalCoverage: action,
      saveLocalMedicalCoverages: action,
    });
  }

  //Medical Coverages
  public addLocalMedicalCoverage(medicalCoverageToAdd: MedicalCoverage) {
    if (!this.localMedicalCoverages) {
      this.localMedicalCoverages = [];
    }

    const index = this.localMedicalCoverages.findIndex(
      (bill) => bill.id === medicalCoverageToAdd.id
    );
    if (index === -1) {
      // Medical Coverages not found, add the new Medical Coverages
      this.localMedicalCoverages = [
        ...this.localMedicalCoverages,
        medicalCoverageToAdd,
      ];
    } else {
      // Medical Coverages found, update the existing Medical Coverages
      this.localMedicalCoverages = this.localMedicalCoverages.map((bill, i) =>
        i === index ? medicalCoverageToAdd : bill
      );
    }
  }

  public removeLocalMedicalCoverage(medicalCoverageToRemove: MedicalCoverage) {
    if (!this.localMedicalCoverages) {
      return;
    }
    this.localMedicalCoverages = this.localMedicalCoverages.filter(
      (x) => x.id !== medicalCoverageToRemove.id
    );
  }

  public saveLocalMedicalCoverages = async () => {
    const promisesToAwait = [];
    if (!!this.localMedicalCoverages && this.localMedicalCoverages.length > 0) {
      for (let i = 0; i < this.localMedicalCoverages.length; i++) {
        const asset = this.localMedicalCoverages[i];
        if (asset.id === "new-medical-coverage") {
          promisesToAwait.push(
            this.createAndAwaitApplicationOwnedEntity({
              page: "medical-coverage",
              ownedEntity: asset,
            })
          );
        } else {
          promisesToAwait.push(
            this.updateAndAwaitApplicationOwnedEntity({
              page: "medical-coverage",
              ownedEntity: asset,
              ownedEntityId: asset.id,
            })
          );
        }
      }
      await this.awaitAndValidateOwnedEntityPromises(
        promisesToAwait,
        "medical-coverage"
      );
      this.localMedicalCoverages = [];
    }
  };
}

export const medicalCoverageOwnedEntityStore =
  new MedicalCoverageOwnedEntityStore();
