import {
  Report,
  ReportCreateDTO,
  ReportUpdateDTO,
} from "../../../components/Reports/types";
import baseApiClient from "../baseApiClient";

class ReportApiClient {
  getReports = async () => {
    return baseApiClient.get<Report[]>(`reports`);
  };

  getReport = async (id: string) => {
    return baseApiClient.get<Report>(`reports/${id}`);
  };

  createReport = async (ReportCreateDTO: ReportCreateDTO) => {
    return baseApiClient.post<Report>(`reports`, ReportCreateDTO);
  };

  updateReport = async (id: string, ReportUpdateDTO: ReportUpdateDTO) => {
    return baseApiClient.put<Report>(`reports/${id}`, ReportUpdateDTO);
  };

  deleteReport = async (id: string) => {
    return baseApiClient.delete<void>(`reports/${id}`);
  };
}

export const reportApiClient = new ReportApiClient();
