import { GridColumn } from "@ucl/library";
import { ColumnModel } from "@syncfusion/ej2-react-grids";
import classNames from "classnames";

interface GridStyleColumnOptions extends ColumnModel {}

export default class GridStyleFIARequestDueInColumn extends GridColumn {
  constructor(opts: GridStyleColumnOptions) {
    const { ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, any>) => {
        // check for null or undefined values
        const dueInValue =
          model[this.field] === undefined || model[this.field] === null
            ? undefined
            : model[this.field];

        // return blank if undefined
        if (dueInValue === undefined || dueInValue === null) {
          return ""; // Return an empty string or appropriate default value
        }

        const dueIn = Number(dueInValue);
        let label = "";
        const baseClass = "grid-style-column__duein";
        const dueLaterClass = `${baseClass}__due_later`;
        const dueSoonClass = `${baseClass}__due_today_or_soon`;
        const overdueClass = `${baseClass}__due_very_soon_or_overdue`;

        // rules for due in
        if (dueIn > 10) {
          label = `${dueIn} Days`;
        } else if (dueIn >= 5 && dueIn <= 10) {
          label = `${dueIn} Days`;
        } else if (dueIn === 1) {
          label = "1 Day";
        } else {
          label =
            dueIn === 0 ? "Today" : dueIn < 0 ? "Past Due" : `${dueIn} Days`;
        }

        const className = classNames(baseClass, {
          [dueLaterClass]: dueIn > 10,
          [dueSoonClass]: dueIn >= 5 && dueIn <= 10,
          [overdueClass]: dueIn < 5,
        });

        return <div className={className}>{label}</div>;
      }) as any,
    });
  }
}
