import { FC, useCallback, useEffect, useState } from "react";
import { Button, Callout, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { featureToggleStore } from "../../../lib/featureToggles/FeatureToggleStore";
import ErrorWrapper from "../../../pages/ErrorWrapper";
import { useFieldSchemaFactory } from "../../../lib/fieldSchemaV2/useFieldSchemaV2Factory";
import { FieldFormV2, FieldV2 } from "@ucl/library";
import { AppointmentFormModel } from "./AppointmentFormModel";
import { Loading } from "../../Loading/Loading";
import { MeetingTypes } from "./types/MeetingTypes";
import schedulingApiClient from "../../../lib/apiClients/scheduling/schedulingApiClient";
import { AppToaster } from "../../Toast/Toast";
import {
  AppointmentContactForm,
  defaultAppointmentContactFormModel,
} from "./AppointmentContactForm/AppointmentContactForm";
import { AppointmentContactFormModel } from "./AppointmentContactForm/AppointmentContactFormModel";
import { SimplePdfDocuments } from "../../Document/SimplePdfDocuments";
import { PdfDocumentEntityType } from "../../Document/PdfDocumentUploader";
import { Document } from "../../../types/Document";
import { AppointmentDocument } from "../../../types/Scheduling";
import "./styles.scss";
import getAppointmentFormSchema from "./AppointmentFormSchema";
import {
  getDateDisplayText,
  getDurationDisplayText,
  getMeetingTypeDisplayText,
  getResidentNameDisplayText,
  getTimespanDisplayText,
  toTimeOptions,
} from "./util";
import { closeAppointmentDialog } from "../../Dialogs/AppointmentDialog";
import { debounce } from "lodash";
import {
  SocialSecurityNumberFieldValue,
  Option,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { userStore } from "../../../stores/UserStore";
import { uniqueId } from "lodash";
import { YesNoOptionTypes } from "./types/YesNoOptionTypes";
import { openConvertDuplicateApplicationDialog } from "../../Dialogs/ConvertDuplicateApplicationDialog";
import { useNavigate } from "react-router";

// TODO: This is a workaround for not being able to make API calls inside the Blueprint dialog
// When issue is resolved:
// Restore appointmentId prop
// Uncomment fetchAppointmentForm function and useEffect inside the component
// Remove props.formModel, use appointmentFormModel state instead
// Restore function of isLoading state
export const fetchAppointmentForm = async (appointmentId: string) => {
  return await schedulingApiClient
    .getAppointment(appointmentId)
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      AppToaster.show({
        message: "Unexpected error occurred while loading the form",
        intent: Intent.DANGER,
      });
      return undefined;
    });
};

export interface AppointmentFormProps {
  formModel?: AppointmentFormModel;
  // appointmentId?: string;
  facilityId?: string;
  date?: string;
  startTime?: number;
  endTime?: number;
  shouldDisableForm?: boolean;
  onFormSubmit?: () => void;
}

export const AppointmentForm: FC<AppointmentFormProps> = (props) => {
  if (!featureToggleStore.featureToggles?.InAppScheduling) {
    return <ErrorWrapper />;
  }
  const navigate = useNavigate();
  const { schemaFactory } = useFieldSchemaFactory();
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  // const [isLoading, setIsLoading] = useState(false);
  const [startTimesLoading, setStartTimesLoading] = useState(false);
  const [endTimesLoading, setEndTimesLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConverting, setIsConverting] = useState(false);

  const [appointmentFormModel, setAppointmentFormModel] =
    useState<AppointmentFormModel>({
      id: undefined,
      status: undefined,
      convertedApplicationId: undefined,
      convertedApplication_Status_AsString: undefined,
      appointmentContacts: [],
      appointmentDocuments: [],
      rescheduleReason: undefined,
      cancellationReason: undefined,
      facilityId: props.facilityId ?? undefined,
      facility_Name: undefined,
      facilityHasNoFieldRep: undefined,
      fieldRepId: undefined,
      fieldRep_FullName: undefined,
      meetingType: MeetingTypes.ResidentInterview,
      date: props.date ?? undefined,
      startTime: props.startTime ?? undefined,
      endTime: props.endTime ?? undefined,
      duration_AsString: undefined,
      firstName: undefined,
      lastName: undefined,
      socialSecurityNumber: undefined,
      isResidentCapableOfSigning: undefined,
      willAnyoneElseBeAttending: undefined,
      startingMonthOfEligibilityNeeded: undefined,
      plannedCareType: undefined,
      isBalanceOwedToFacility: undefined,
      balanceOwedToFacility: undefined,
      lastInsuranceCoveredDate: undefined,
      insuranceType: undefined,
      copayAmount: undefined,
      coinsuranceAmount: undefined,
      additionalNotes: undefined,
      createdBy_AsName: undefined,
      ...props.formModel,
    });

  const [isRescheduling, setIsRescheduling] = useState(false);
  const [isCancelling, setIsCancelling] = useState(
    !!appointmentFormModel.cancellationReason
  );

  const [startTimeOptions, setStartTimeOptions] = useState(
    [] as Option<EnumSingleSelectFieldValue>[]
  );
  const [endTimeOptions, setEndTimeOptions] = useState(
    [] as Option<EnumSingleSelectFieldValue>[]
  );

  useEffect(() => {
    const fetchAvailableStartTimes = async () => {
      if (appointmentFormModel.fieldRepId && appointmentFormModel.date) {
        const localDate = new Date(appointmentFormModel.date);

        setStartTimesLoading(true);
        await schedulingApiClient
          .getAvailableStartTimes(
            appointmentFormModel.fieldRepId,
            localDate.getUTCFullYear(),
            localDate.getUTCMonth() + 1,
            localDate.getUTCDate()
          )
          .then((response) => {
            setStartTimeOptions(toTimeOptions(response));
          })
          .catch((error) => {
            console.error(error);
            AppToaster.show({
              message: "Unexpected error occurred while loading start times",
              intent: Intent.DANGER,
            });
          })
          .finally(() => {
            setStartTimesLoading(false);
          });
      }
    };
    fetchAvailableStartTimes();
  }, [appointmentFormModel.fieldRepId, appointmentFormModel.date]);

  useEffect(() => {
    const fetchAvailableEndTimes = async () => {
      if (appointmentFormModel.fieldRepId && appointmentFormModel.startTime) {
        setEndTimesLoading(true);
        await schedulingApiClient
          .getAvailableEndTimes(
            appointmentFormModel.fieldRepId,
            appointmentFormModel.startTime as number
          )
          .then((response) => {
            setEndTimeOptions(toTimeOptions(response));
          })
          .catch((error) => {
            console.error(error);
            AppToaster.show({
              message: "Unexpected error occurred while loading end times",
              intent: Intent.DANGER,
            });
          })
          .finally(() => {
            setEndTimesLoading(false);
          });
      }
    };
    fetchAvailableEndTimes();
  }, [appointmentFormModel.fieldRepId, appointmentFormModel.startTime]);

  useEffect(() => {
    // When Resident is not capable of signing or someone will be attending and there are no contacts, add one
    if (
      (appointmentFormModel.isResidentCapableOfSigning ===
        YesNoOptionTypes.No ||
        appointmentFormModel.willAnyoneElseBeAttending ===
          YesNoOptionTypes.Yes) &&
      appointmentFormModel.appointmentContacts.length === 0
    ) {
      addContact();
    }
  }, [
    appointmentFormModel.isResidentCapableOfSigning,
    appointmentFormModel.willAnyoneElseBeAttending,
  ]);

  const [originalDate] = useState(appointmentFormModel.date);
  const [originalStartTime] = useState(appointmentFormModel.startTime);
  const [originalEndTime] = useState(appointmentFormModel.endTime);

  const [staticDateDisplay] = useState(getDateDisplayText(originalDate));
  const [staticTimespanDisplay] = useState(
    getTimespanDisplayText(originalStartTime, originalEndTime)
  );
  const [staticDurationDisplay] = useState(
    getDurationDisplayText(originalStartTime, originalEndTime)
  );

  const [hasDuplicateSSN, setHasDuplicateSSN] = useState(false);

  // Set up debounce
  const debouncedHandleSSNFieldChange = useCallback(
    debounce(async (ssn: string) => {
      await schedulingApiClient.duplicateSSNCheck(ssn).then((isDuplicate) => {
        setHasDuplicateSSN(isDuplicate);
      });
    }, 2000),
    []
  );

  // Clean up debounce
  useEffect(() => {
    return () => debouncedHandleSSNFieldChange.cancel();
  }, [debouncedHandleSSNFieldChange]);

  const handleSSNFieldChanged = (value: SocialSecurityNumberFieldValue) => {
    // Call debounced function if SSN is valid
    if (value && value.length === 9) {
      debouncedHandleSSNFieldChange(value);
    } else {
      setHasDuplicateSSN(false);
    }
  };

  useEffect(() => {
    // Reset date/time fields and clear reschedule reason when rescheduling is toggled off
    if (!isRescheduling) {
      setAppointmentFormModel((prev) => ({
        ...prev,
        date: originalDate,
        startTime: originalStartTime,
        endTime: originalEndTime,
        rescheduleReason: undefined,
      }));
    }
  }, [isRescheduling]);

  useEffect(() => {
    // Clear cancellation reason when cancellation is toggled off
    if (!isCancelling) {
      setAppointmentFormModel((prev) => ({
        ...prev,
        cancellationReason: undefined,
      }));
    }
  }, [isCancelling]);

  const id = props.formModel?.id;
  const isEditing = !!id;
  const isConverted =
    !!appointmentFormModel.convertedApplicationId &&
    !!appointmentFormModel.convertedApplication_Status_AsString;

  const formDisabled = props.shouldDisableForm || isSubmitting || isConverting;

  // Field Rep becomes an autocomplete field when an Internal User selects a Facility with no Field Rep
  const hasFacilityWithNoFieldRep =
    !userStore.user?.isExternal &&
    appointmentFormModel.facilityId &&
    appointmentFormModel.facilityHasNoFieldRep;

  // TODO: Restore when the API call is moved inside the component
  // useEffect(() => {
  //   if (props.appointmentId) {
  //     fetchAppointmentForm();
  //   }
  // }, [props.appointmentId]);

  // const fetchAppointmentForm = async () => {
  //   if (!props.appointmentId) {
  //     return;
  //   }

  //   setIsLoading(true);

  //   await schedulingApiClient
  //     .getAppointment(props.appointmentId)
  //     .then((response) => {
  //       setAppointmentFormModel(response);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       AppToaster.show({
  //         message: "Unexpected error occurred while loading the form",
  //         intent: Intent.DANGER,
  //       });
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  const addContact = () => {
    setAppointmentFormModel((prev) => {
      return {
        ...prev,
        appointmentContacts: [
          ...prev.appointmentContacts,
          {
            ...defaultAppointmentContactFormModel,
            key: uniqueId("contact-"),
            appointmentId: id,
          },
        ],
      };
    });
  };

  const removeContact = (index: number) => {
    setAppointmentFormModel((prev) => {
      const updatedContacts = prev.appointmentContacts.filter(
        (_, i) => i !== index
      );
      return {
        ...prev,
        appointmentContacts: updatedContacts,
      };
    });
  };

  const handleContactFieldChanged = (
    contactFormModel: AppointmentContactFormModel,
    contactIndex: number
  ) => {
    setAppointmentFormModel((prev) => {
      const updatedContacts = [...prev.appointmentContacts];
      updatedContacts[contactIndex] = contactFormModel;
      return {
        ...prev,
        appointmentContacts: updatedContacts,
      };
    });
  };

  const handleDocChanged = (docs: Document[]) => {
    setAppointmentFormModel((prev) => {
      return {
        ...prev,
        appointmentDocuments: docs.map(
          (doc) =>
            ({
              appointmentId: id,
              documentId: doc.id,
            } as AppointmentDocument)
        ),
      };
    });
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);

    await (isEditing
      ? schedulingApiClient.updateAppointment({
          ...appointmentFormModel,
          isReschedule: isRescheduling,
          isCancellation: isCancelling,
        })
      : schedulingApiClient.createAppointment(appointmentFormModel)
    )
      .then((response) => {
        setAppointmentFormModel(response);
        setErrors({});
        props.onFormSubmit?.();
        AppToaster.show({
          message: `Appointment ${
            isEditing ? "updated" : "scheduled"
          } successfully`,
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: `Unexpected error occurred while ${
              isEditing ? "updating" : "scheduling"
            } the appointment`,
            intent: Intent.DANGER,
          });
        }
        const errorResponse = error?.response?.data?.additionalInfo;
        if (errorResponse) {
          setErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const navigateToApplication = (
    applicationId: string,
    applicationStatus_AsString: string
  ) =>
    navigate(
      applicationStatus_AsString === "Interviewing"
        ? `/application-form/${applicationId}/primary-information`
        : `/application-page/${applicationId}`
    );

  const handleConvertToApplication = async (confirm = false) => {
    setIsConverting(true);

    await schedulingApiClient
      .convertToApplication(id as string, confirm)
      .then((response) => {
        if (response.duplicateLeadId) {
          // If Lead with same SSN exists, show confirmation dialog
          openConvertDuplicateApplicationDialog({
            hasDuplicateLead: true,
            onConfirm: async () => await handleConvertToApplication(true),
          });
        } else if (response.duplicateApplicationId) {
          // If Application with same SSN exists, show confirmation dialog
          openConvertDuplicateApplicationDialog({
            hasDuplicateLead: false,
            duplicateApplicationFileNumber:
              response.duplicateApplicationFileNumber,
            onConfirm: async () => await handleConvertToApplication(true),
          });
        } else if (
          response.applicationId &&
          response.applicationStatus_AsString
        ) {
          closeAppointmentDialog();
          AppToaster.show({
            message: "Appointment successfully converted to Application",
            intent: Intent.SUCCESS,
          });
          navigateToApplication(
            response.applicationId,
            response.applicationStatus_AsString
          );
        }
      })
      .catch((error) => {
        console.error(error);
        AppToaster.show({
          message: "Unexpected error occurred while converting the appointment",
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsConverting(false);
      });
  };

  if (!schemaFactory) {
    return <Loading />;
  }

  const schema = getAppointmentFormSchema(
    schemaFactory,
    errors,
    appointmentFormModel,
    setAppointmentFormModel,
    startTimesLoading,
    startTimeOptions,
    endTimesLoading,
    endTimeOptions,
    handleSSNFieldChanged
  );

  return (
    <div className="appointment-form">
      {hasFacilityWithNoFieldRep && (
        <Callout
          className="appointment-form-warning-callout"
          icon={IconNames.WARNING_SIGN}
          intent={Intent.DANGER}
          title="Missing Territory Manager"
        >
          The selected Facility does not have an assigned Territory Manager.
          Please assign a Territory Manager (TM) to the selected Facility or
          choose an available TM from the dropdown list.
        </Callout>
      )}
      {hasDuplicateSSN && (
        <Callout
          className="appointment-form-warning-callout"
          icon={IconNames.WARNING_SIGN}
          intent={Intent.WARNING}
          title="Duplicate SSN"
        >
          The SSN you provided is already associated with an active file.
        </Callout>
      )}
      <FieldFormV2<AppointmentFormModel> isDisabled={formDisabled}>
        {isEditing && (
          <div className="appointment-form_header">
            <div className="appointment-form_header_main">
              <div className="appointment-form_header_main_info">
                <div className="appointment-form_header_main_info_primary">
                  {appointmentFormModel.facility_Name || "N/A"} -{" "}
                  {getMeetingTypeDisplayText(
                    appointmentFormModel.meetingType as MeetingTypes
                  )}{" "}
                  -{" "}
                  {getResidentNameDisplayText(
                    appointmentFormModel.firstName,
                    appointmentFormModel.lastName
                  )}
                </div>
                <div className="appointment-form_header_main_info_secondary">
                  <span className="appointment-form_header_main_info_secondary_scheduler">
                    Scheduler: {appointmentFormModel.createdBy_AsName || "N/A"}
                  </span>
                  <span className="appointment-form_header_main_info_secondary_source">
                    Source: Maverick
                  </span>
                </div>
              </div>
              <div className="appointment-form_header_main_actions">
                <Button
                  intent={Intent.PRIMARY}
                  className="appointment-form_header_main_actions_reschedule"
                  minimal={!isRescheduling}
                  icon={IconNames.TIME}
                  text={"Reschedule"}
                  disabled={formDisabled}
                  onClick={() => {
                    setIsRescheduling(!isRescheduling);
                    setIsCancelling(false);
                  }}
                />
                <Button
                  intent={Intent.DANGER}
                  className="appointment-form_header_main_actions_cancel"
                  minimal={!isCancelling}
                  icon={IconNames.DELETE}
                  text={"Cancel Appointment"}
                  disabled={formDisabled}
                  onClick={() => {
                    setIsCancelling(!isCancelling);
                    setIsRescheduling(false);
                  }}
                />
              </div>
            </div>
            <div className="appointment-form_header_fields">
              {isCancelling && (
                <div className="appointment-form_header_fields_cancellation">
                  <FieldV2 fieldProps={schema.CancellationReason} />
                </div>
              )}
              {isRescheduling && (
                <div className="appointment-form_header_fields_reschedule">
                  <div className="appointment-form_header_fields_reschedule_time">
                    <FieldV2 fieldProps={schema.Date} />
                    <FieldV2 fieldProps={schema.StartTime} />
                    <FieldV2 fieldProps={schema.EndTime} />
                    <FieldV2 fieldProps={schema.Duration} />
                  </div>
                  <div className="appointment-form_header_fields_reschedule_reason">
                    <FieldV2 fieldProps={schema.RescheduleReason} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="appointment-form_section time-section">
          <div className="appointment-form_section_header">
            <Icon icon={IconNames.TIME} size={25} />
          </div>
          <div className="appointment-form_section_content time-section-content">
            {isEditing ? (
              <div className="time-section-content_display">
                <div className="time-section-content_display_time">
                  <span className="time-section-content_display_time_date">
                    {staticDateDisplay}
                  </span>
                  <span className="time-section-content_display_time_start-end">
                    {staticTimespanDisplay}
                  </span>
                  <span className="time-section-content_display_time_duration">
                    ({staticDurationDisplay})
                  </span>
                </div>
                <div className="time-section-content_display_location">
                  Facility: {appointmentFormModel.facility_Name || "N/A"}
                </div>
              </div>
            ) : (
              <div className="time-section-content_create">
                <div className="time-section-content_create_facility">
                  <FieldV2 fieldProps={schema.FacilityId} />
                  {hasFacilityWithNoFieldRep ? (
                    <FieldV2 fieldProps={schema.FieldRepId} />
                  ) : (
                    <FieldV2 fieldProps={schema.FieldRep_FullName} />
                  )}
                </div>
                <div className="time-section-content_create_time">
                  <FieldV2 fieldProps={schema.Date} />
                  <FieldV2 fieldProps={schema.StartTime} />
                  <FieldV2 fieldProps={schema.EndTime} />
                  <FieldV2 fieldProps={schema.Duration} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="appointment-form_section resident-section">
          <div className="appointment-form_section_header">
            <Icon icon={IconNames.PERSON} size={25} />
          </div>
          <div className="appointment-form_section_content resident-section-content">
            <FieldV2 fieldProps={schema.FirstName} />
            <FieldV2 fieldProps={schema.LastName} />
            <FieldV2 fieldProps={schema.IsResidentCapableOfSigning} />
            <FieldV2 fieldProps={schema.WillAnyoneElseBeAttending} />
            <FieldV2 fieldProps={schema.MeetingType} />
          </div>
        </div>
        <div className="appointment-form_section insurance-section">
          <div className="appointment-form_section_header">
            <Icon icon={IconNames.ID_NUMBER} size={25} />
          </div>
          <div className="appointment-form_section_content insurance-section-content">
            <FieldV2 fieldProps={schema.SocialSecurityNumber} />
            <FieldV2 fieldProps={schema.StartingMonthOfEligibilityNeeded} />
            <FieldV2 fieldProps={schema.PlannedCareType} />
            <FieldV2 fieldProps={schema.IsBalanceOwedToFacility} />
            <FieldV2 fieldProps={schema.BalanceOwedToFacility} />
            <FieldV2 fieldProps={schema.LastInsuranceCoveredDate} />
            <FieldV2 fieldProps={schema.InsuranceType} />
            <FieldV2 fieldProps={schema.CopayAmount} />
            <FieldV2 fieldProps={schema.CoinsuranceAmount} />
          </div>
        </div>
        <div className="appointment-form_section contacts-section">
          <div className="appointment-form_section_header">
            <Icon icon={IconNames.PEOPLE} size={25} />
          </div>
          <div className="appointment-form_section_content contacts-section-content">
            {appointmentFormModel.appointmentContacts.map((model, index) => {
              return (
                <AppointmentContactForm
                  key={model.id ?? model.key}
                  contactIndex={index}
                  formModel={model}
                  // First contact is required when Resident is not capable of signing
                  isRequired={
                    index === 0 &&
                    appointmentFormModel.isResidentCapableOfSigning ===
                      YesNoOptionTypes.No
                  }
                  errors={
                    // Only pass errors down to Contacts when creating a new Appointment
                    isEditing
                      ? undefined
                      : Object.fromEntries(
                          Object.entries(errors)
                            // Get the validation error messages for the current contact only
                            .filter(([fieldName, _]) =>
                              fieldName.startsWith(
                                `appointmentContacts[${index}]`
                              )
                            )
                            // Remove the model name and index from error key, and lowercase the first letter of the remainder
                            // e.g. appointmentContacts[0].FirstName -> firstName
                            .map(([fieldName, message]) => {
                              fieldName = fieldName.split(".")[1];
                              fieldName =
                                fieldName.charAt(0).toLowerCase() +
                                fieldName.slice(1);
                              return [fieldName, message];
                            })
                        )
                  }
                  shouldShowSaveButton={isEditing}
                  shouldDisableForm={formDisabled}
                  onFieldChanged={(form) =>
                    handleContactFieldChanged(form, index)
                  }
                  onDelete={() => removeContact(index)}
                />
              );
            })}
            <Button
              minimal
              className="add-contact-button"
              text={"Add Contact"}
              intent={Intent.PRIMARY}
              rightIcon={IconNames.ADD}
              disabled={formDisabled}
              onClick={addContact}
            />
          </div>
        </div>
        <div className="appointment-form_section documents-section">
          <div className="appointment-form_section_header">
            <Icon icon={IconNames.DOCUMENT} size={25} />
          </div>
          <div className="appointment-form_section_content documents-section-content">
            <SimplePdfDocuments
              id={`appointment-documents${id ? `-${id}` : ""}`}
              documentIds={appointmentFormModel.appointmentDocuments.map(
                (doc) => doc.documentId
              )}
              entityId={id} // undefined if appointment is not created yet
              entityType={PdfDocumentEntityType.AppointmentDocuments}
              disableUploadDoc={false}
              disablePreviewDoc={false}
              disableOpenNewTabDoc={false}
              disableDownloadDoc={false}
              disableDeleteDoc={false}
              onDocChanged={handleDocChanged}
            />
          </div>
        </div>
        <div className="appointment-form_section notes-section">
          <div className="appointment-form_section_header">
            <Icon icon={IconNames.ANNOTATION} size={25} />
          </div>
          <div className="appointment-form_section_content notes-section-content">
            <FieldV2 fieldProps={schema.AdditionalNotes} />
          </div>
        </div>
      </FieldFormV2>
      <div className="appointment-form_footer">
        <Button
          className="appointment-form_footer_close"
          text={"Close"}
          onClick={closeAppointmentDialog}
        />
        <Button
          className="appointment-form_footer_submit"
          text={isEditing ? "Update" : "Schedule"}
          intent={Intent.PRIMARY}
          icon={isEditing && IconNames.RESET}
          rightIcon={!isEditing && IconNames.ARROW_RIGHT}
          disabled={formDisabled}
          loading={isSubmitting}
          onClick={handleFormSubmit}
        />
        {isEditing && (
          <Button
            className="appointment-form_footer_convert"
            text={isConverted ? "Go to Application" : "Convert to Application"}
            intent={Intent.PRIMARY}
            rightIcon={IconNames.ARROW_RIGHT}
            disabled={formDisabled}
            loading={isConverting}
            onClick={() => {
              if (isConverted) {
                closeAppointmentDialog();
                navigateToApplication(
                  appointmentFormModel.convertedApplicationId as string,
                  appointmentFormModel.convertedApplication_Status_AsString as string
                );
              } else {
                handleConvertToApplication();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
