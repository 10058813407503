import { FC, useEffect, useState } from "react";
import "./styles.scss";
import RelativityCard from "../Card/RelativityCard";
import { SectionHeader } from "../Fields/SectionHeader/SectionHeader";
import { FIARequestODataReadDTO } from "./configurations/types";
import { AddButton } from "../Buttons/AddButton";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { Button, Intent } from "@blueprintjs/core";
import { openFlyoutInPlace } from "@ucl/library";
import { FIARequestFlyout } from "./FIARequestFlyout";
import { useOutlet } from "react-router";
import { IconNames } from "@blueprintjs/icons";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { applicationStore } from "../../stores/ApplicationStore";
import { userStore } from "../../stores/UserStore";
import { FIARequestsGrid } from "./FIARequestsGrid";
import GridRecordClickEventArgs from "@ucl/library/lib/components/Grids/GridSettings/GridRecordClickEventArgs";
import { fiaRequestConnectivityHub } from "../../lib/hub/fiaRequestConnectivityHub";

export interface FIARequestsGridProps {
  applicationId: string;
  canCreate: boolean;
  canInvokeRecordClick: boolean;
  parentClassComponent: string;
}

const ApplicationFIARequestsViewComponent: FC<FIARequestsGridProps> = (
  props
) => {
  const [showRelatedRequests, setShowRelatedRequests] =
    useState<boolean>(false);

  const outlet = useOutlet();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  const [gridNeedsRefresh, setGridNeedsRefresh] = useState<boolean>(false);
  const [updateFiaRequestId, setUpdateFiaRequestId] = useState<string>();

  useEffect(() => {
    if (!isUpdateOpen) {
      setUpdateFiaRequestId(undefined);
    }
  }, [isUpdateOpen]);

  useEffect(() => {
    if (updateFiaRequestId) {
      setIsUpdateOpen(true);
    }
  }, [updateFiaRequestId]);

  const getFIARequestURL = (rowData: FIARequestODataReadDTO): string => {
    return `/application-page/${props.applicationId}/fia-requests/open/${rowData.id}`;
  };

  const onRecordClick = (
    args: GridRecordClickEventArgs<FIARequestODataReadDTO>
  ) => {
    if (props.canInvokeRecordClick) {
      const rowData = args.rowData as FIARequestODataReadDTO;
      setUpdateFiaRequestId(rowData?.id);
    }
  };

  return (
    <>
      <RelativityCard
        className={`fia-requests-card ${props.parentClassComponent}`}
        headerContent={
          <div className="owned-entity-header fia-requests-header">
            <SectionHeader label="FIA Requests" type="Section" />
            <div>
              {userStore.user?.canViewCancelAllFIARequestsButton &&
                applicationStore.application?.hasPendingFIARequests && (
                  //TODO - Permissions
                  <Button
                    text="Cancel All FIA Requests"
                    id="cancel-all-requests"
                    minimal
                    icon={IconNames.Disable}
                    intent={Intent.DANGER}
                    onClick={() => {
                      dialogsViewerStore.setIsCancelAllFIARequestsDialogOpen(
                        true,
                        {
                          applicationId: props.applicationId,
                          onSuccessfulCancel: () => {
                            setGridNeedsRefresh(true);
                          },
                        }
                      );
                    }}
                  />
                )}
              {showRelatedRequests ? (
                <Button
                  minimal
                  text="Show Current Requests"
                  id="show-current-requests"
                  onClick={() => {
                    setShowRelatedRequests(false);
                  }}
                />
              ) : (
                <Button
                  minimal
                  text="Show Related Requests"
                  id="show-all-requests"
                  onClick={() => {
                    setShowRelatedRequests(true);
                  }}
                />
              )}
              {props.canCreate && (
                <AddButton
                  text="New Request"
                  id="add-request"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        }
      >
        <section className="fia-requests-grid">
          <section className="fia-requests-grid-container">
            <FIARequestsGrid
              gridKey="application-fia-requests-grid"
              key={`application-fia-requests-grid-${
                showRelatedRequests ? "related" : "current"
              }`}
              applicationId={props.applicationId}
              odataParams={
                showRelatedRequests
                  ? { relatedApplicationId: props.applicationId }
                  : { applicationId: props.applicationId }
              }
              shouldShowViews={true}
              shouldShowExportToExcel={false}
              shouldShowSearch={true}
              shouldShowOpenInNewTab={true}
              shouldShowMetrics={false}
              getFIARequestURL={getFIARequestURL}
              onRecordClick={onRecordClick}
              gridNeedsRefresh={gridNeedsRefresh}
              setGridNeedsRefresh={setGridNeedsRefresh}
            />
          </section>
        </section>
      </RelativityCard>

      {isOpen &&
        openFlyoutInPlace(
          <FIARequestFlyout
            applicationId={String(props.applicationId)}
            flyoutIsOpen={true}
            onClose={() => {
              setIsOpen(false);
              setGridNeedsRefresh(true);
            }}
          />,
          isOpen,
          () => {
            setIsOpen(false);
            setGridNeedsRefresh(true);
          },
          { canOutsideClickClose: true }
        )}
      {isUpdateOpen &&
        updateFiaRequestId &&
        openFlyoutInPlace(
          <FIARequestFlyout
            applicationId={String(props.applicationId)}
            fiaRequestId={updateFiaRequestId}
            flyoutIsOpen={true}
            onClose={() => {
              setIsUpdateOpen(false);
              setGridNeedsRefresh(true);
            }}
          />,
          isUpdateOpen,
          () => {
            setIsUpdateOpen(false);
            setGridNeedsRefresh(true);
            fiaRequestConnectivityHub.unsubscribe(updateFiaRequestId);
          },
          { canOutsideClickClose: true }
        )}
      {outlet}
    </>
  );
};

export const ApplicationFIARequestsView = withAITracking(
  reactPlugin,
  ApplicationFIARequestsViewComponent,
  "FIARequestsGrid",
  "AI-flex-container"
);
