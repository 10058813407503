import { Dispatch, SetStateAction, useState } from "react";
import { NewChecklistItem } from "../../types/Checklist";
import Form from "../Forms";
import "./styles.scss";
import { checklistSplitItemCreationFormSchema } from "../Forms/ChecklistForm/ChecklistSplitItemCreationFormSchema";
import { checklistItemStore } from "../../stores/ChecklistItemStore";
import { applicationStore } from "../../stores/ApplicationStore";
import { BaseField } from "../Forms/BaseField/BaseField";
import { addOwnedEntityFormSchema } from "../Forms/AddOwnedEntityComponent/AddOwnedEntityFormSchema";
import { OwnedEntityComponent } from "./OwnedEntityComponent";
import {
  otherTypeString,
  useOwnedEntityComponent,
} from "./UseOwnedEntityComponent";

export interface AddOwnedEntityComponentProps {
  onSave: () => void;
  onCancel: () => void;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  ownedEntityType: string;
  setOwnedEntityType: (ownedEntityType: string) => void;
}

export const AddOwnedEntityComponent: React.FC<AddOwnedEntityComponentProps> = (
  props
) => {
  const { assetTypeSchema, setAssetTypeSchema } = useOwnedEntityComponent();
  const [localChecklistItem] = useState<Partial<NewChecklistItem>>();

  return (
    <div>
      <BaseField
        {...addOwnedEntityFormSchema.ownedEntityType(props.setOwnedEntityType)}
      />
      <BaseField
        {...addOwnedEntityFormSchema.ownedEntityAssetType(
          props.ownedEntityType,
          setAssetTypeSchema
        )}
      />
      <OwnedEntityComponent
        onSave={props.onSave}
        onCancel={props.onCancel}
        isSaving={props.isSaving}
        setIsSaving={props.setIsSaving}
        ownedEntityTypeString={props.ownedEntityType}
        setOwnedEntityTypeString={props.setOwnedEntityType}
        localAssetTypeSchema={assetTypeSchema}
        showCancelButton={true}
      />
      {props.ownedEntityType === otherTypeString && (
        <Form
          formId="checklist-item-form"
          value={localChecklistItem}
          formSchemaBuilder={checklistSplitItemCreationFormSchema}
          onFormSubmit={async (checklistItem: Partial<NewChecklistItem>) => {
            props.setIsSaving(true);
            try {
              await checklistItemStore.createChecklistItem({
                ...checklistItem,
                applicationId: applicationStore.application?.id,
              });
              props.onSave();
            } finally {
              props.setIsSaving(false);
            }
          }}
        />
      )}
    </div>
  );
};
