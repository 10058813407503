import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React from "react";
import { reactPlugin } from "../../AppInsights";
import { Header } from "../../components/Header/Header";
import { PageSidebar } from "../../components/PageSidebar/PageSidebar";
import { TemplateGrid } from "../../components/TemplateGrid/TemplateGrid";
import { userStore } from "../../stores/UserStore";
import "../styles.scss";

const TemplatesPageComponent: React.FC = () => {
  return (
    <div className="page board-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="templates" />
        <div className="page-main-content">
          <TemplateGrid
            canConfigureBoard={!!userStore.user?.canConfigureTemplateBoard}
            canExportGridToExcel={
              !!userStore.user?.canExportTemplateGridToExcel
            }
            canInvokeGridRecord={!!userStore.user?.canEditTemplate}
            canCreateNewRecord={!!userStore.user?.canCreateTemplate}
          />
        </div>
      </div>
    </div>
  );
};

export const TemplatesPage = withAITracking(
  reactPlugin,
  TemplatesPageComponent,
  "TemplatesPage",
  "AI-flex-container"
);
