import { Button, Icon, Intent, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";
import { ApplicationFormPage } from "../../types/Application";
import "./styles.scss";

export const medicalCoveragePage: {
  id: ApplicationFormPage;
  value: string;
  isPageFilledOut?: boolean;
} = {
  id: "medical-coverage",
  value: "Medical Coverage",
};

export const spouseInformationPage: {
  id: ApplicationFormPage;
  value: string;
  isPageFilledOut?: boolean;
} = {
  id: "spouse-information",
  value: "Spouse",
};

export const firstPages: {
  id: ApplicationFormPage;
  value: string;
  isPageFilledOut?: boolean;
}[] = [
  {
    id: "primary-information",
    value: "Primary Information",
  },
  {
    id: "secondary-information",
    value: "Secondary Information",
  },
  {
    id: "medical-information",
    value: "Medical Information",
  },
];

export const lastPages: {
  id: ApplicationFormPage;
  value: string;
  isPageFilledOut?: boolean;
}[] = [
  {
    id: "contact",
    value: "Contact Information",
  },
  {
    id: "date-information",
    value: "Date Information",
  },
  {
    id: "income-information",
    value: "Income Information",
  },
  {
    id: "asset",
    value: "Asset Information",
  },
  {
    id: "bill-information",
    value: "Bill Information",
  },
  {
    id: "legal-information",
    value: "Legal Information",
  },
  {
    id: "signatures",
    value: "Signatures",
  },
  {
    id: "checklist",
    value: "Checklist",
  },
  {
    id: "fia-requests",
    value: "FIA Requests",
    isPageFilledOut: false,
  },
  {
    id: "comments",
    value: "Comments",
    isPageFilledOut: false,
  },
  {
    id: "audit-log",
    value: "Audit Log",
    isPageFilledOut: false,
  },
];

export interface ApplicationFormProgressProps {
  hasMedicalCoverage: boolean;
  hasSpouse: boolean;
  currentPage: ApplicationFormPage;
  progress?: number;
  setCurrentPage?: (currentPage: ApplicationFormPage) => void;
  pageMissingFieldsCounters?: {
    pageName: ApplicationFormPage;
    missingFieldCount: number;
  }[];
}

export const ApplicationFormProgress: React.FC<
  ApplicationFormProgressProps
> = ({
  hasMedicalCoverage,
  hasSpouse,
  progress = 0,
  currentPage,
  setCurrentPage,
  pageMissingFieldsCounters,
}) => {
  const pages = [
    ...firstPages,
    ...(hasMedicalCoverage ? [medicalCoveragePage] : []),
    ...(hasSpouse ? [spouseInformationPage] : []),
    ...lastPages,
  ];

  return (
    <div className="circle-progress">
      <div className="circle-progress__indicator">
        <ProgressBarComponent
          progressColor="#4DD45A"
          type="Circular"
          value={progress}
          height="60px"
          width="60px"
        />
        <h1 className="circle-progress__indicator__number">{progress}%</h1>
      </div>
      <div className="vertical-line" />
      <div className="circle-progress__steps">
        {pages.map((page, idx) => {
          const missingFields = pageMissingFieldsCounters?.filter(
            (pageMissingFieldsCounter) =>
              pageMissingFieldsCounter.pageName === page.id
          );
          // Aggregate validators that have same page name
          const missingFieldCount =
            missingFields?.reduce((a, c) => a + c.missingFieldCount, 0) || 0;

          const isPageFilledOut = missingFieldCount === 0 && progress !== 0;

          const shouldShowCompleted =
            isPageFilledOut && page.isPageFilledOut !== false;

          return (
            <div key={page.id}>
              <div className={"circle-progress__steps__item"}>
                <div className={"circle-progress__steps__item__page"}>
                  <Icon
                    icon={
                      shouldShowCompleted
                        ? IconNames.TICK_CIRCLE
                        : IconNames.FULL_CIRCLE
                    }
                  />
                  <Button
                    active={currentPage === page.id}
                    disabled={!setCurrentPage}
                    minimal={true}
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      if (!!setCurrentPage) {
                        setCurrentPage(page.id);
                      }
                    }}
                  >
                    {page.value}
                  </Button>
                </div>
                {/* {!!missingFieldCount && (
                  <Tag
                    intent={Intent.WARNING}
                    minimal={true}
                    className={"circle-progress__steps__item__counter"}
                  >
                    {missingFieldCount}
                  </Tag>
                )} */}
                {!!missingFieldCount && (
                  <div className="application-form-tab">
                    {(missingFieldCount || 0) > 0 && (
                      <Tag large={true} round={true}>
                        {missingFieldCount}
                      </Tag>
                    )}
                  </div>
                )}
              </div>
              {idx !== pages.length - 1 && <div className="vertical-line" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
