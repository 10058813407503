import {
  PdfDocumentEntityType,
  PdfDocumentUploader,
} from "../Document/PdfDocumentUploader";
import useDocumentFiling, { FilingStrategy } from "./useDocumentFiling";
import { CancelButton, PrimaryButton } from "@ucl/library";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { Button, Classes, Tag } from "@blueprintjs/core";
import { getObjectValueActiveClassName } from "../../utils/miscellaneous";
import { DocumentPreview } from "../Document/DocumentPreview";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { FIAFilingFormCard } from "./FIAFilingFormCard";
import { StateNoticeFilingFormCard } from "./StateNoticeFilingFormCard";
import { IconNames } from "@blueprintjs/icons";

interface DocumentFilingComponentProps {
  closeDialog: () => void;
}
export const DocumentFilingComponent: React.FC<DocumentFilingComponentProps> = (
  props
) => {
  const {
    filingAIEntityId,
    document,
    setDocument,
    stages,
    fiaRequest,
    associatedChecklistItem,
    fiaSearchAsyncAutoCompleteFieldProps,
    stateNoticeSearchAsyncAutoCompleteFieldProps,
    asyncAutoCompleteProps,
    setAsyncAutoCompleteProps,
    canBeFiled,
    fiaProcessDocumentResult,
    searchValue,
    autoFillData,
    filingStrategy,
    setFilingStrategy,
    stateNoticeFilingProcessDocumentResult,
    application,
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    documentVerifyDTO,
    setDocumentVerifyDTO,
    resetDocumentFIling,
    newDocumentName,
    setNewDocumentName,
    isSaving,
    handleSubmitDocument,
  } = useDocumentFiling();

  return (
    <>
      <div className={`${Classes.DIALOG_BODY} fiafiling-container`}>
        <section className="fiafiling-content">
          <div className="document-filing-stages">
            <div
              className={`filing-stage ${getObjectValueActiveClassName(true)}`}
            >
              <Tag className="filing-stage-tag" round={true}>
                1
              </Tag>
              <span className="filing-stage-text">Upload Document</span>
              <div className="filing-stage-border"></div>
            </div>
            <div
              className={`filing-stage ${getObjectValueActiveClassName(
                stages.isFormStage
              )}`}
            >
              <Tag className="filing-stage-tag" round={true}>
                2
              </Tag>
              <span className="filing-stage-text">Verify & File</span>
              <div className="filing-stage-border"></div>
            </div>
          </div>
          {stages.isFormStage && (
            <Button
              className="document-filing-back-button"
              icon={IconNames.ArrowLeft}
              text="Back to File Uploader"
              onClick={resetDocumentFIling}
            />
          )}
          {stages.isUploadDocumentStage && (
            <div className="fiafiling-content-upload">
              <div className="welcome">
                Please upload FIA request and/or State Notice documents by
                dragging and dropping into the respective area below or clicking
                on the upload button.
              </div>
              <div className="document-filing-uploaders-container">
                <div
                  id="fia-uploader"
                  className="fiafiling-content-upload-type"
                >
                  <div className="fiafiling-content-upload-type-text fia">
                    FIA
                  </div>
                  <PdfDocumentUploader
                    allowMultipleFiles={false}
                    pdfDocumentEntity={{
                      entityId: filingAIEntityId,
                      entityType: PdfDocumentEntityType.FilingAI,
                    }}
                    onUploading={() => setIsUploadingFiaDoc(true)}
                    onCompleteUploading={(doc) => {
                      setDocument(doc);
                      setFilingStrategy(FilingStrategy.FIADocument);
                      setAsyncAutoCompleteProps(
                        fiaSearchAsyncAutoCompleteFieldProps
                      );
                      setIsUploadingFiaDoc(false);
                    }}
                    onCompleteRemoving={() => {
                      setDocument(undefined);
                      setFilingStrategy(undefined);
                      setIsUploadingFiaDoc(false);
                    }}
                    disabled={isUploadingStateNoticeDoc}
                  />
                </div>
                <div
                  id="state-notice-uploader"
                  className="fiafiling-content-upload-type"
                >
                  <div className="fiafiling-content-upload-type-text state-notice">
                    State Notices
                  </div>
                  <PdfDocumentUploader
                    allowMultipleFiles={false}
                    pdfDocumentEntity={{
                      entityId: filingAIEntityId,
                      entityType: PdfDocumentEntityType.FilingAI,
                    }}
                    onUploading={() => setIsUploadingStateNoticeDoc(true)}
                    onCompleteUploading={(doc) => {
                      setDocument(doc);
                      setFilingStrategy(FilingStrategy.StateNoticeDocument);
                      setAsyncAutoCompleteProps(
                        stateNoticeSearchAsyncAutoCompleteFieldProps
                      );
                      setIsUploadingStateNoticeDoc(false);
                    }}
                    onCompleteRemoving={() => {
                      setDocument(undefined);
                      setFilingStrategy(undefined);
                      setIsUploadingStateNoticeDoc(false);
                    }}
                    disabled={isUploadingFiaDoc}
                  />
                </div>
              </div>
            </div>
          )}
          {stages.isProcessDocumentStage && (
            <div className="fiafiling-process-document">
              <SkeletonComponent
                className="loading-skeleton"
                type="rectangle"
                width={500}
                height={25}
              >
                Processing document...
              </SkeletonComponent>
            </div>
          )}
          <div
            className="fiafiling-content-form"
            // Issue: AsyncAutoCompleteField is not rendering correctly if not loaded in the first render
            // Rendering form, but not showing until is form stage
            style={{ display: stages.isFormStage ? "flex" : "none" }}
            key={
              (autoFillData && autoFillData.length > 0) || searchValue
                ? "search"
                : "id"
            }
          >
            <div className="filing-document-panel">
              {document && (
                <DocumentPreview
                  getDocument={documentApiClient.downloadDocument(document.id)}
                />
              )}
            </div>

            <div className="filing-form-panel">
              {filingStrategy === FilingStrategy.FIADocument && (
                <FIAFilingFormCard
                  asyncAutoCompleteProps={asyncAutoCompleteProps}
                  document={document}
                  newDocumentName={newDocumentName}
                  setNewDocumentName={setNewDocumentName}
                  fiaProcessDocumentResult={fiaProcessDocumentResult}
                  fiaRequestResult={fiaRequest}
                  associatedChecklistItem={associatedChecklistItem}
                  autoFillData={autoFillData}
                  searchValue={searchValue}
                  isSaving={isSaving}
                  fiaRequestVerifyDTO={documentVerifyDTO}
                  setFiaRequestVerifyDTO={setDocumentVerifyDTO}
                />
              )}
              {filingStrategy === FilingStrategy.StateNoticeDocument && (
                <StateNoticeFilingFormCard
                  asyncAutoCompleteProps={asyncAutoCompleteProps}
                  document={document}
                  newDocumentName={newDocumentName}
                  setNewDocumentName={setNewDocumentName}
                  stateNoticeFilingProcessDocumentResult={
                    stateNoticeFilingProcessDocumentResult
                  }
                  application={application}
                  associatedChecklistItem={associatedChecklistItem}
                  autoFillData={autoFillData}
                  searchValue={searchValue}
                  isSaving={isSaving}
                />
              )}
            </div>
          </div>
        </section>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="document-filing-footer-actions">
          <CancelButton onClick={props.closeDialog} />
          {stages.isFormStage && (
            <PrimaryButton
              content="File Document"
              disabled={!canBeFiled}
              isLoading={isSaving}
              loadingProgressContent="Submitting..."
              loadingSuccessContent="Submitted"
              onClick={async () => await handleSubmitDocument()}
            />
          )}
        </div>
      </div>
    </>
  );
};
