import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../../stores/ApplicationStore";
import { Application } from "../../../../../types/Application";
import {
  Asset,
  AssetInformation,
  NewAsset,
} from "../../../../../types/Application/Asset";
import { SaveButton } from "../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../Buttons/UndoButton";
import RelativityCard from "../../../../Card/RelativityCard";
import { RelativityCardHeader } from "../../../../Card/RelativityCardHeader";
import Form from "../../../../Forms";
import assetSchemas from "../../../../Forms/ApplicationForm/Assets";
import { AssetsPageCard } from "./Owned Entities/AssetsPageCard";
import { BaseField } from "../../../../Forms/BaseField/BaseField";
import { overrideAssetEligibilityFormSchema } from "../../../../Forms/ApplicationForm/Assets/OverrideAssetEligibilityForm";
import "../../../styles.scss";
import { userStore } from "../../../../../stores/UserStore";
import { featureToggleStore } from "../../../../../lib/featureToggles/FeatureToggleStore";

export interface AssetInformationPageCardProps {
  application?: Application;
  canEdit: boolean;
  canCreateAsset: boolean;
  createAsset: (newAsset: NewAsset) => Promise<void>;
  updateAsset: () => Promise<void>;
  deleteAsset: (assetToDelete: Asset) => Promise<void>;
  submitForm: (assetInformation: AssetInformation) => Promise<void>;
}

export const AssetInformationPageCard: FC<AssetInformationPageCardProps> = ({
  application,
  canEdit,
  canCreateAsset,
  createAsset,
  updateAsset,
  deleteAsset,
  submitForm,
}) => {
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<Application>(
    application as Application
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  return (
    <RelativityCard
      className="application-page income-information-card"
      headerContent={
        <RelativityCardHeader label="Assets Information">
          <div>
            {canEdit && (
              <>
                <div className="assets-page-header-actions">
                  {userStore.user?.canOverrideAssetEligibility &&
                    (!application?.isRecertProductType ||
                      (application.isRecertProductType &&
                        featureToggleStore.featureToggles?.EnableRecertAMD)) &&
                    application?.isMarried &&
                    !application?.isSpouseLTCFacility && (
                      <BaseField
                        {...overrideAssetEligibilityFormSchema.overrideAssetEligibility(
                          activeFormValue,
                          setFormIsSaving
                        )}
                        type="Switch"
                      />
                    )}
                  <SaveButton
                    formId="income-information-form"
                    isSaving={formIsSaving}
                    hidden={!formIsSaving}
                  />
                  <UndoButton
                    isSaving={formIsSaving}
                    onClick={async () => {
                      setShouldResetForm(true);
                      await handleReset();
                      setShouldResetForm(false);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </RelativityCardHeader>
      }
    >
      {assetSchemas.map((schema) => {
        const formId = `asset-${schema.formId}-form`;

        return (
          <div className="asset-card" key={schema.formId}>
            <Form<AssetInformation>
              formId={formId}
              formSchemaBuilder={schema.application}
              value={activeFormValue}
              shouldResetForm={shouldResetForm}
              disableAllFields={!canEdit || shouldDisableForm}
              shouldBlockNavigation={true}
              hideSectionHeader={true}
              onFormSubmit={async (application) => {
                setShouldDisableForm(true);
                setFormIsSaving(true);
                await submitForm(application);
                setShouldDisableForm(false);
                setFormIsSaving(false);
              }}
            />
            {application && schema.shouldShowAssetCard(application) && (
              <AssetsPageCard
                assets={application?.assets.filter(
                  (asset) => asset.type === schema.type
                )}
                assetType={schema.type}
                title={schema.title}
                assetFormSchema={schema.asset}
                createAsset={createAsset}
                updateAsset={updateAsset}
                deleteAsset={deleteAsset}
                canCreateAsset={canCreateAsset}
                canEditAsset={canEdit}
                shouldDisableForm={shouldDisableForm || !canEdit}
                setShouldDisableForm={setShouldDisableForm}
                formIsSaving={formIsSaving}
                setFormIsSaving={setFormIsSaving}
                applicationId={application?.id}
              />
            )}
          </div>
        );
      })}
    </RelativityCard>
  );
};
