import { Button, ButtonProps } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ListBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import classNames from "classnames";
import React, { useEffect } from "react";
import { ThumbnailImagesData } from "./PdfDocumentStore";

export type DocumentThumbnailItem = {
  id: string;
  text: string;
  thumbnailImagesData: ThumbnailImagesData;
};

export enum DocumentPageActionTypes {
  Preview = 1,
  OpenInNewTab = 2,
  Editor = 3,
  EditorDisabled = 4,
}

export interface DocumentPageAction<T extends object>
  extends Omit<
    ButtonProps,
    "onClick" | "onMouseEnter" | "onMouseLeave" | "minimal"
  > {
  name?: string;
  onActionClick?: (data: T) => void;
}

export interface DocumentPageActionRender<T extends object> {
  name?: string;
  render?: (data: T) => React.ReactNode;
}

export const useDocumentPagesViewerItem = (
  isDisabled: boolean,
  listBoxInstance: React.MutableRefObject<ListBoxComponent | null>,
  thumbnailImagesData?: ThumbnailImagesData[],
  onPagePreviewClicked?: (data: ThumbnailImagesData) => void,
  onPagePreviewNewTabClicked?: (data: ThumbnailImagesData) => void,
  onPageEditorClicked?: (data: ThumbnailImagesData) => void,
  documentPageItems?: Array<
    | DocumentPageActionTypes
    | DocumentPageAction<ThumbnailImagesData>
    | DocumentPageActionRender<ThumbnailImagesData>
  >
) => {
  const [localThumbnailImagesData, setLocalThumbnailImagesData] =
    React.useState<ThumbnailImagesData[] | undefined>();
  const [selectedThumbnails, setSelectedThumbnails] =
    React.useState<DocumentThumbnailItem[]>();
  const selectedThumbnailsRef = React.useRef<DocumentThumbnailItem[]>();
  const [
    localThumbnailImagesDataTimeoutId,
    setLocalThumbnailImagesDataTimeoutId,
  ] = React.useState<number | null>(null);

  useEffect(() => {
    //if (selectedThumbnailsRef?.current) {
    selectedThumbnailsRef.current = selectedThumbnails;
    //}
  }, [selectedThumbnails]);

  const changeHandler = (thumbnailItem: DocumentThumbnailItem) => {
    select(thumbnailItem);
  };

  const select = (thumbnailItem: DocumentThumbnailItem) => {
    if (isDisabled) {
      return;
    }
    const _selected = [...(selectedThumbnailsRef.current || [])];

    if (!thumbnailItem.id) {
      thumbnailItem.id = thumbnailItem.thumbnailImagesData.pageId.toString();
      thumbnailItem.text = thumbnailItem.thumbnailImagesData.pageId.toString();
    }

    const index = _selected.map((x) => x.id).indexOf(thumbnailItem.id);
    if (index > -1) {
      _selected.splice(index, 1);
    } else {
      _selected.push(thumbnailItem);
    }

    // Update selected values
    setSelectedThumbnails([..._selected]);
  };

  const unselectAll = () => {
    //if (selectedThumbnailsRef?.current) {
    selectedThumbnailsRef.current = [];
    //}
    setSelectedThumbnails([]);
  };

  const selectAll = () => {
    if (!thumbnailImagesData || thumbnailImagesData.length <= 0 || isDisabled) {
      return;
    }

    // Select all unselected items
    const _selected = [...(selectedThumbnailsRef.current || [])];

    const _unselected = getListColumns(
      thumbnailImagesData.filter((all) => {
        return _selected.map((x) => x.id).indexOf(all.pageId.toString()) === -1;
      })
    );

    if (_unselected) {
      _selected.push(..._unselected);
    }

    // Update selected values
    setSelectedThumbnails([..._selected]);
  };

  const isThumbnailItemTemplateSelected = (data: DocumentThumbnailItem) => {
    // Check if data is on selection list
    const selected = selectedThumbnails?.find(
      (x) => x.id === data.thumbnailImagesData.pageId.toString()
    );

    return !!selected;
  };

  const thumbnailItemTemplate = (data: DocumentThumbnailItem) => {
    const isSelected = isThumbnailItemTemplateSelected(data);

    return (
      <div
        key={`${data.thumbnailImagesData.documentId}_${data.id}`}
        className={classNames(
          `document-thumbnail document-thumbnail-pageid-${data.id}`,
          `document-thumbnail-rotate-${data.thumbnailImagesData.rotation}`,
          `document-thumbnail-og-rotate-${data.thumbnailImagesData.originalRotation}`,
          data.thumbnailImagesData.classNames
        )}
      >
        <img
          style={{
            backgroundImage: `url(${data.thumbnailImagesData.thumbnailImage})`,
          }}
          className={`document-thumbnail-image`}
        />
        <span className={`document-thumbnail-page-number`}>{+data.id + 1}</span>
        {isSelected && (
          <span className={`document-thumbnail-selection-order`}>
            {data.thumbnailImagesData.selectionOrder}
          </span>
        )}
        {documentPageItems && documentPageItems.length > 0 && (
          <ul className="document-thumbnail-actions">
            {documentPageItems?.map((item, idx) => (
              <>
                {/* Predefined Options */}
                {item &&
                  (item as string) in DocumentPageActionTypes &&
                  (item as DocumentPageActionTypes) ===
                    DocumentPageActionTypes.Preview && (
                    <li
                      key={`${data.id}${idx}`}
                      className={classNames(
                        "document-thumbnail-actions-item",
                        "document-thumbnail-actions-item-document-action-types",
                        "document-thumbnail-actions-item-preview",
                        `document-thumbnail-actions-item-${item as string}`
                      )}
                    >
                      <Button
                        icon={IconNames.ZoomIn}
                        onClick={(e) => {
                          onPagePreviewClicked &&
                            onPagePreviewClicked(data.thumbnailImagesData);
                          e.stopPropagation();
                        }}
                      />
                    </li>
                  )}
                {item &&
                  (item as string) in DocumentPageActionTypes &&
                  (item as DocumentPageActionTypes) ===
                    DocumentPageActionTypes.OpenInNewTab && (
                    <li
                      key={`${data.id}${idx}`}
                      className={classNames(
                        "document-thumbnail-actions-item",
                        "document-thumbnail-actions-item-document-action-types",
                        "document-thumbnail-actions-item-preview-new-tab",
                        `document-thumbnail-actions-item-${item as string}`
                      )}
                    >
                      <Button
                        icon={IconNames.DocumentOpen}
                        onClick={(e) => {
                          onPagePreviewNewTabClicked &&
                            onPagePreviewNewTabClicked(
                              data.thumbnailImagesData
                            );
                          e.stopPropagation();
                        }}
                      />
                    </li>
                  )}
                {item &&
                  (item as string) in DocumentPageActionTypes &&
                  ((item as DocumentPageActionTypes) ===
                    DocumentPageActionTypes.Editor ||
                    (item as DocumentPageActionTypes) ===
                      DocumentPageActionTypes.EditorDisabled) && (
                    <li
                      key={`${data.id}${idx}`}
                      className={classNames(
                        "document-thumbnail-actions-item",
                        "document-thumbnail-actions-item-document-action-types",
                        "document-thumbnail-actions-item-editor",
                        `document-thumbnail-actions-item-${item as string}`
                      )}
                    >
                      <Button
                        icon={IconNames.Widget}
                        onClick={(e) => {
                          onPageEditorClicked &&
                            onPageEditorClicked(data.thumbnailImagesData);
                          e.stopPropagation();
                        }}
                        disabled={
                          (item as DocumentPageActionTypes) ===
                          DocumentPageActionTypes.EditorDisabled
                        }
                      />
                    </li>
                  )}
              </>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const getListColumns = (
    data: ThumbnailImagesData[]
  ): DocumentThumbnailItem[] | undefined => {
    if (data) {
      return data.map((x) => {
        return {
          text: x.pageId.toString(),
          id: x.pageId.toString(),
          thumbnailImagesData: x,
          classNames: x.classNames,
          selectionOrder: x.selectionOrder,
          rotation: x.rotation,
          originalRotation: x.originalRotation,
        };
      });
    }
  };

  React.useEffect(() => {
    if (
      JSON.stringify(thumbnailImagesData) ===
      JSON.stringify(localThumbnailImagesData)
    ) {
      return;
    }

    if (listBoxInstance.current && thumbnailImagesData) {
      setLocalThumbnailImagesData(
        thumbnailImagesData.map((obj) => ({ ...obj }))
      );
    }
  }, [thumbnailImagesData]);

  useEffect(() => {
    if (
      listBoxInstance.current &&
      localThumbnailImagesData &&
      localThumbnailImagesData.length > 0
    ) {
      if (localThumbnailImagesDataTimeoutId !== null) {
        clearTimeout(localThumbnailImagesDataTimeoutId);
      }

      const timeoutId = window.setTimeout(() => {
        if (listBoxInstance.current) {
          listBoxInstance.current.dataSource =
            getListColumns(
              localThumbnailImagesData.map((obj) => ({ ...obj }))
            ) || [];
        }
        setLocalThumbnailImagesDataTimeoutId(null);
      }, 800);
      setLocalThumbnailImagesDataTimeoutId(timeoutId);
    }
  }, [localThumbnailImagesData]);

  return {
    isThumbnailItemTemplateSelected,
    getListColumns,
    thumbnailItemTemplate,
    selectedThumbnails,
    selectAll,
    unselectAll,
    select,
    changeHandler,
  };
};
