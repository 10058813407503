import { useEffect, useRef, useState } from "react";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { ThumbnailImagesData } from "../Document/PdfDocumentStore";
import { Document as MdrDocument } from "../../types/Document";
import { AppToaster } from "../Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { DocumentPagesViewerModule } from "./DocumentPagesViewer";

export enum DocumentThumbnailActionTypes {
  ROTATE = 1,
  CROP = 2,
}
export interface DocumentThumbnailOperation {
  actionType: DocumentThumbnailActionTypes;
  timestamp?: number;
  pageId?: number;
  data?: DocumentThumbnailRotateData | DocumentThumbnailCropData;
}

interface DocumentThumbnailRotateData {}
export class DocumentThumbnailRotateOperation
  implements DocumentThumbnailOperation
{
  actionType: DocumentThumbnailActionTypes =
    DocumentThumbnailActionTypes.ROTATE;
  timestamp?: number;
  pageId?: number;
  data?: DocumentThumbnailRotateData;

  constructor(pageId: number) {
    this.pageId = pageId;
    this.timestamp = Date.now();
  }
}

interface DocumentThumbnailCropData {
  base64Image?: string;
}
export class DocumentThumbnailCropOperation
  implements DocumentThumbnailOperation
{
  actionType: DocumentThumbnailActionTypes = DocumentThumbnailActionTypes.CROP;
  timestamp?: number;
  pageId?: number;
  data?: DocumentThumbnailCropData;

  constructor(pageId: number, cropData: DocumentThumbnailCropData) {
    this.pageId = pageId;
    this.timestamp = Date.now();
    this.data = cropData;
  }
}

export const useDocumentEditor = (props: { documentId: string }) => {
  const documentPagesViewermModule = useRef<DocumentPagesViewerModule>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [mdrDocument, setMDRDocument] = useState<MdrDocument>();
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
  const [selectedDocumentThumbnails, setSelectedDocumentThumbnails] = useState<
    ThumbnailImagesData[]
  >([]);
  const [documentOperations, setDocumentOperations] = useState<
    DocumentThumbnailOperation[]
  >([]);
  const [updatedDocumentName, setUpdatedDocumentName] = useState<string>();
  const [originalFileName, setOriginalFileName] = useState<string>();
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    if (props.documentId) {
      setup();
    }
  }, []);

  useEffect(() => {
    setUpdatedDocumentName(mdrDocument?.originalFileName.replace(".pdf", ""));
  }, [mdrDocument?.originalFileName]);

  const renameDocumentName = (updateValue?: boolean) => {
    if (isDocumentNameChanged && updateValue) {
      if (!updatedDocumentName) {
        return;
      }

      mdrDocument &&
        setMDRDocument({
          ...mdrDocument,
          originalFileName: `${updatedDocumentName}.pdf`,
        });
    } else {
      setUpdatedDocumentName(mdrDocument?.originalFileName.replace(".pdf", ""));
    }
  };

  const isDocumentNameChanged =
    mdrDocument?.originalFileName !== `${updatedDocumentName || ""}.pdf`;

  const isOriginalFileNameModified =
    mdrDocument?.originalFileName !== originalFileName;

  const setup = async () => {
    await fetchDocument(props.documentId);
    setReady(true);
  };

  const fetchDocument = (documentId: string) => {
    setIsLoadingDocument(true);
    documentApiClient
      .getDocument(documentId)
      .then((doc) => {
        setMDRDocument(doc);
        setOriginalFileName(doc.originalFileName);
      })
      .finally(() => {
        setIsLoadingDocument(false);
      });
  };

  const selectionChangedHandler = (value: ThumbnailImagesData[]) => {
    {
      setSelectedDocumentThumbnails(value);
    }
  };

  const unselectAllPages = () => {
    setSelectedDocumentThumbnails([]);
    documentPagesViewermModule.current?.unselectAll();
  };

  // Toolbar Actions
  const crop = (cropData: DocumentThumbnailCropData) => {
    if (selectedDocumentThumbnails?.length <= 0) {
      return;
    }

    documentOperations?.push(
      new DocumentThumbnailCropOperation(
        Number(selectedDocumentThumbnails.at(0)?.pageId),
        cropData
      )
    );
    setDocumentOperations([...documentOperations]);
  };

  const rotate = () => {
    if (selectedDocumentThumbnails?.length <= 0) {
      return;
    }

    selectedDocumentThumbnails.forEach((x) => {
      documentOperations?.push(
        new DocumentThumbnailRotateOperation(Number(x.pageId))
      );
      setDocumentOperations([...documentOperations]);

      let defaultRotation = 0;
      switch (x.rotation) {
        case 1:
          defaultRotation = 90;
          break;
        case 2:
          defaultRotation = 180;
          break;
        case 3:
          defaultRotation = 270;
          break;
        default:
          break;
      }

      const rotationDegrees = defaultRotation + 90;

      const element = document.querySelector(
        `.document-editor .document-pages-viewer-item-pages li:has(.document-thumbnail-pageid-${x.pageId})`
      ) as HTMLLIElement;

      element.classList.add("document-thumbnail-rotating");
      element.style.transform = `rotate(${rotationDegrees}deg)`;
    });

    // Update
    // Timeout helps to the animation completes before rerendering the items
    setTimeout(() => {
      selectedDocumentThumbnails.forEach((x) => {
        x.rotation = x.rotation === 3 ? 0 : x.rotation + 1;
      });
      setSelectedDocumentThumbnails([...selectedDocumentThumbnails]);
    }, 300);
  };

  const updateDocument = async (newDocumentName?: string) => {
    if (!mdrDocument) {
      return;
    }

    let documentPagesHdImages: { [pageId: number]: string } | undefined;

    await documentApiClient
      .updateDocument(
        props.documentId,
        newDocumentName || mdrDocument.originalFileName,
        documentOperations
      )
      .then((res) => {
        AppToaster.show({
          message: (
            <div>
              <h3>Document Updated</h3>
            </div>
          ),
          intent: Intent.SUCCESS,
        });

        documentPagesHdImages = res;
      })
      .catch((error) => {
        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });

        if (error?.response?.status == 400) {
          setErrors({ ...error.response.data.additionalInfo });
        }
      });

    return documentPagesHdImages;
  };

  return {
    ready,
    isLoadingDocument,
    mdrDocument,
    //setMDRDocument,
    selectedDocumentThumbnails,
    selectionChangedHandler,
    unselectAllPages,
    documentOperations,
    updateDocument,
    rotate,
    crop,
    updatedDocumentName,
    setUpdatedDocumentName,
    isDocumentNameChanged,
    isOriginalFileNameModified,
    renameDocumentName,
    documentPagesViewermModule,
    errors,
  };
};
