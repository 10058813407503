import { get } from "lodash";
import { schemaStore } from "../../../../stores/SchemaStore";
import { Application } from "../../../../types/Application";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { Dispatch, SetStateAction } from "react";

export const overrideAssetEligibilityFormSchema = {
  overrideAssetEligibility: (
    application: Application,
    setFormIsSaving?: Dispatch<SetStateAction<boolean>>,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(
        "application",
        "overrideAssetEligibility"
      ),
      onSubmit: () => 0,
      handleClick: async () => {
        setFormIsSaving && setFormIsSaving(true);
        await applicationStore.overrideAssetEligibility(
          application.id,
          !application.overrideAssetEligibility
        );
        setFormIsSaving && setFormIsSaving(false);
      },
      value: application.overrideAssetEligibility,
      errorMessages: get(errors, "overrideAssetEligibility"),
    };
  },
};
