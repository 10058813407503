import _ from "lodash";
import { UserRoles } from "../../../../types/User";
import GridClickableIconColumn from "../../../Grid/Grid/types/GridClickableIconColumn";
import GridColoredColumn from "../../../Grid/Grid/types/GridColoredColumn";
import GridColumn from "../../../Grid/Grid/types/GridColumn";
import GridClickableDataColumn from "../../../Grid/Grid/types/GridClickableDataColumn";
import GridLinkColumn from "../../../Grid/Grid/types/GridLinkColumn";
import GridTooltipColumn from "../../../Grid/Grid/types/GridTooltipColumn";

export const gridColumnsConfiguration = () => {
  const columns: GridColumn[] = [
    new GridColumn({
      field: "id_AsString",
      headerText: "Application ID",
      type: "string",
      visible: true,
      allowSearching: false,
    }),
    new GridColumn({
      field: "status_AsString",
      headerText: "Status",
      type: "string",
      allowSearching: true,
    }),
    new GridClickableDataColumn({
      field: "processorCommentAge",
      headerText: "Processor Comment Age",
      type: "number",
      textAlign: "Center",
      headerTextAlign: "Left",
      allowSearching: false,
      dialogType: "comments",
      width: 155,
    }),
    new GridClickableDataColumn({
      field: "repCommentAge",
      headerText: "Rep Comment Age",
      type: "number",
      textAlign: "Center",
      allowSearching: false,
      headerTextAlign: "Left",
      dialogType: "comments",
      width: 155,
    }),
    new GridClickableIconColumn({
      field: "applicationSummary",
      headerText: "Summary",
      type: "string",
      textAlign: "Center",
      columnName: "applicationSummary",
      icon: "control",
      dialogType: "applicationSummary",
      //NOTE- Columns that do not have an associated value / property cannot be searchable
      allowSearching: false,
      allowFiltering: false,
      allowSorting: false,
    }),
    new GridColumn({
      field: "applicationAge",
      headerText: "App Age",
      type: "number",
      textAlign: "Center",
      allowSearching: false,
      headerTextAlign: "Left",
      width: 90,
    }),
    new GridColoredColumn<number>({
      field: "totalMonitorAge",
      fieldToColor: "totalMonitorAge",
      headerText: "Total Monitor Age",
      type: "number",
      color: "Red",
      textAlign: "Center",
      headerTextAlign: "Left",
      allowSearching: false,
      width: 140,
      shouldUpdateFieldColor: (age) => age >= 10,
      formatField: (age) => age,
    }),
    new GridColumn({
      field: "processingAge",
      headerText: "Processing Age",
      type: "number",
      textAlign: "Center",
      headerTextAlign: "Left",
      allowSearching: false,
      width: 140,
    }),
    new GridColumn({
      field: "fileNumber",
      headerText: "File Number",
      type: "string",
      allowSearching: true,
    }),
    new GridColumn({
      field: "productType_AsString",
      headerText: "Product Type",
      type: "string",
      allowSearching: false,
    }),
    new GridColumn({
      field: "reasonCode_AsString",
      headerText: "Reason Code",
      type: "string",
      allowSearching: false,
    }),
    new GridColumn({
      field: "processor_FullName",
      headerText: "Processor Name",
      type: "string",
      allowSearching: false,
      width: 150,
    }),
    new GridColumn({
      field: "fieldRep_FullName",
      headerText: "Field Rep Name",
      type: "string",
      allowSearching: true,
      width: 150,
    }),
    new GridColumn({
      field: "dueDate_AsDateOnly",
      headerText: "Due Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "firstName",
      headerText: "First Name",
      type: "string",
      allowSearching: true,
      width: 150,
    }),
    new GridColumn({
      field: "lastName",
      headerText: "Last Name",
      type: "string",
      allowSearching: true,
      width: 150,
    }),
    new GridColumn({
      field: "fullName",
      headerText: "Full Name",
      type: "string",
      allowSearching: true,
      width: 150,
    }),
    new GridColumn({
      field: "facility_Name",
      headerText: "Facility Name",
      type: "string",
      allowSearching: false,
    }),
    new GridColumn({
      field: "facility_County",
      headerText: "Facility County",
      type: "string",
      allowSearching: false,
      width: 110,
    }),
    new GridColumn({
      field: "facility_State_AsString",
      headerText: "State",
      type: "string",
      allowSearching: false,
      headerTextAlign: "Left",
      width: 95,
    }),
    new GridColumn({
      field: "facility_Area_AsString",
      headerText: "Facility Region",
      type: "string",
      allowSearching: false,
      headerTextAlign: "Left",
      width: 95,
    }),
    new GridColumn({
      field: "dateOfBirth_AsDateOnly",
      headerText: "Date of Birth",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "isActive",
      headerText: "Active",
      type: "boolean",
      displayAsCheckBox: true,
      textAlign: "Center",
      customAttributes: {
        id: "corporate-is-active-header",
      },
      allowSearching: false,
    }),
    new GridColumn({
      field: "isInMonitor",
      headerText: "In Monitor?",
      type: "boolean",
      width: 120,
    }),
    new GridColumn({
      field: "isWithdrawn",
      headerText: "Withdrawn?",
      type: "boolean",
      allowSearching: false,
    }),
    new GridColumn({
      field: "createdAt_AsDateOnly",
      headerText: "Created Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "createdBy_AsName",
      headerText: "Created By",
      type: "string",
      allowSearching: false,
      width: 150,
    }),
    new GridColumn({
      field: "updatedAt_AsDateOnly",
      headerText: "Updated Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "updatedBy_AsName",
      headerText: "Updated By",
      type: "string",
      allowSearching: false,
      width: 150,
    }),
    new GridLinkColumn({
      headerText: "App Submission",
      field: "embeddedApplicationLinks_ApplicationSubmissionURL",
      linkField: "embeddedApplicationLinks_ApplicationSubmissionURL",
      linkLabelField: "embeddedApplicationLinks_ApplicationSubmissionLabel",
      allowSearching: false,
      webPassword: "webPassword",
      webUsername: "webUsername",
      facilityState: "facility_State_AsString",
      width: 135,
    }),
    new GridLinkColumn({
      headerText: "Status/Eligibility Check",
      field: "embeddedApplicationLinks_StatusEligibilityCheckURL",
      linkField: "embeddedApplicationLinks_StatusEligibilityCheckURL",
      linkLabelField: "embeddedApplicationLinks_StatusEligibilityCheckLabel",
      allowSearching: false,
      width: 160,
    }),
    new GridColumn({
      field: "fiaRequestsCount",
      headerText: "FIA",
      type: "number",
      allowSearching: false,
    }),
    new GridColumn({
      field: "approvedDate_AsDateOnly",
      headerText: "Approved Date",

      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 125,
    }),
    new GridColumn({
      field: "medicaidNeededDate_AsDateOnly",
      headerText: "Medicaid Needed Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 150,
    }),
    new GridColumn({
      field: "nextRecertDate_AsDateOnly",
      headerText: "Next Recert Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 140,
    }),
    new GridColumn({
      field: "recertRequired",
      headerText: "Recert Required?",
      type: "boolean",
      width: 120,
    }),
    new GridColumn({
      field: "repHandoffDate_AsDateOnly",
      headerText: "Rep Handoff Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 140,
    }),
    new GridColumn({
      field: "facility_Corporate_CompanyCode",
      headerText: "Corp Code",
      type: "string",
      width: 120,
    }),
    new GridColumn({
      field: "facility_Corporate_CompanyName",
      headerText: "Corp Name",
      type: "string",
      width: 120,
    }),
    new GridColumn({
      field: "applicationHomesteadCountyOffice_AsString",
      headerText: "Homestead County Office",
      type: "string",
      width: 120,
    }),
    new GridColumn({
      field: "stateAppDate_AsDateOnly",
      headerText: "First Applied Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 125,
    }),
    new GridColumn({
      field: "lastAppliedDate_AsDateOnly",
      headerText: "Last Applied Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 125,
    }),
    new GridColumn({
      field: "deniedDate_AsDateOnly",
      headerText: "Denied Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 125,
    }),
  ];

  // AMD columns
  columns.push(
    new GridTooltipColumn({
      field: "medicaidEligibilityStatus_AsString",
      headerText: "Eligibility Status",
      type: "string",
      width: 120,
      tooltipContentField: "medicaidEligibilityDecisionType_AsString",
    }),
    new GridColumn({
      field: "nextDeadlineDate",
      headerText: "Next Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "hearingDate_AsDateOnly",
      headerText: "Hearing Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "interviewDate_AsDateOnly",
      headerText: "Interview Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "hardDeadlineDate",
      headerText: "Hard Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "extensionRequestDeadlineDate",
      headerText: "Extension Request Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "extendedDeadlineDate",
      headerText: "Extended Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "appealDeadlineDate",
      headerText: "Appeal Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "applyDeadlineDate",
      headerText: "Apply Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "reapplyDeadlineDate",
      headerText: "Reapply Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "interviewDeadlineDate",
      headerText: "Interview Deadline Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "overrideAssetEligibility",
      headerText: "Asset Eligibility Override?",
      type: "boolean",
      width: 120,
    }),
    new GridColumn({
      field: "ineligibleReasons_AsString",
      headerText: "Ineligible Reasons",
      type: "string",
      width: 120,
      displayAsCheckBox: true,
      filter: {
        type: "CheckBox",
        dataSource: [
          { ineligibleReasons_AsString: "Over Assets" },
          { ineligibleReasons_AsString: "Over Income" },
          { ineligibleReasons_AsString: "30-Day Stay" },
          { ineligibleReasons_AsString: "Citizenship" },
          { ineligibleReasons_AsString: "Age/Medicare/Disability" },
          { ineligibleReasons_AsString: "LOC" },
          { ineligibleReasons_AsString: "Medicaid in another state" },
        ],
        operator: "contains",
      },
    }),
    new GridColumn({
      field: "postApprovalDate_AsDateOnly",
      headerText: "Stip Due Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    }),
    new GridColumn({
      field: "qcAuditDate_AsDateOnly",
      headerText: "Appeal Filed Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
      allowSearching: false,
      width: 120,
    })
  );

  return columns;
};

// This is currently unused, but we plan to use it in the future with the UCL auto-views.
export const gridDefaultFields = [
  "fileNumber",
  "productType_AsString",
  "status_AsString",
  "reasonCode_AsString",
  "firstName",
  "lastName",
  "facility_Name",
  "facility_State_AsString",
  "processorCommentAge",
  "applicationAge",
  "totalMonitorAge",
  "processingAge",
  "dueDate_AsDateOnly",
  "approvedDate_AsDateOnly",
  "medicaidNeededDate_AsDateOnly",
  "processor_FullName",
  "fieldRep_FullName",
  "embeddedApplicationLinks_ApplicationSubmissionURL",
  "embeddedApplicationLinks_StatusEligibilityCheckURL",
  "isInMonitor",
  "isWithdrawn",
  "fiaRequestsCount",
  "updatedAt_AsString",
  "updatedBy_AsName",
  "createdAt_AsDateOnly",
  "createdBy_AsName",
  "applicationHomesteadCountyOffice_AsString",
  "postApprovalDate_AsDateOnly",
  "qcAuditDate_AsDateOnly",
];

export const internalUserDefaultFields = [
  "applicationSummary",
  "fileNumber",
  "productType_AsString",
  "status_AsString",
  "reasonCode_AsString",
  "firstName",
  "lastName",
  "facility_Name",
  "facility_State_AsString",
  "createdAt_AsDateOnly",
  "processorCommentAge",
  "applicationAge",
  "processingAge",
  "totalMonitorAge",
  "dueDate_AsDateOnly",
  "embeddedApplicationLinks_ApplicationSubmissionURL",
  "embeddedApplicationLinks_StatusEligibilityCheckURL",
  "processor_FullName",
  "fieldRep_FullName",
  "fiaRequestsCount",
  "updatedAt_AsString",
  "updatedBy_AsName",
  "isInMonitor",
  "isWithdrawn",
  "applicationHomesteadCountyOffice_AsString",
];

export const processorUserDefaultFields = [
  "applicationSummary",
  "fileNumber",
  "productType_AsString",
  "status_AsString",
  "reasonCode_AsString",
  "firstName",
  "lastName",
  "facility_Name",
  "facility_State_AsString",
  "createdAt_AsDateOnly",
  "processorCommentAge",
  "applicationAge",
  "processingAge",
  "totalMonitorAge",
  "dueDate_AsDateOnly",
  "embeddedApplicationLinks_ApplicationSubmissionURL",
  "embeddedApplicationLinks_StatusEligibilityCheckURL",
  "processor_FullName",
  "fieldRep_FullName",
  "fiaRequestsCount",
  "updatedAt_AsString",
  "updatedBy_AsName",
  "isInMonitor",
  "isWithdrawn",
  "applicationHomesteadCountyOffice_AsString",
];

export const fieldRepUserDefaultFields = [
  "applicationSummary",
  "fileNumber",
  "productType_AsString",
  "status_AsString",
  "reasonCode_AsString",
  "firstName",
  "lastName",
  "facility_Name",
  "facility_State_AsString",
  "createdAt_AsDateOnly",
  "repCommentAge",
  "applicationAge",
  "processingAge",
  "totalMonitorAge",
  "dueDate_AsDateOnly",
  "embeddedApplicationLinks_ApplicationSubmissionURL",
  "embeddedApplicationLinks_StatusEligibilityCheckURL",
  "processor_FullName",
  "fieldRep_FullName",
  "fiaRequestsCount",
  "updatedAt_AsString",
  "updatedBy_AsName",
  "isInMonitor",
  "isWithdrawn",
  "applicationHomesteadCountyOffice_AsString",
];

export const dvtUserDefaultFields = [
  "applicationSummary",
  "fileNumber",
  "productType_AsString",
  "status_AsString",
  "reasonCode_AsString",
  "firstName",
  "lastName",
  "facility_Name",
  "facility_State_AsString",
  "createdAt_AsDateOnly",
  "recertRequired",
  "nextRecertDate_AsDateOnly",
  "processorCommentAge",
  "applicationAge",
  "processingAge",
  "totalMonitorAge",
  "dueDate_AsDateOnly",
  "embeddedApplicationLinks_ApplicationSubmissionURL",
  "embeddedApplicationLinks_StatusEligibilityCheckURL",
  "processor_FullName ",
  "fieldRep_FullName ",
  "fiaRequestsCount",
  "updatedAt_AsString",
  "updatedBy_AsName",
  "isInMonitor",
  "isWithdrawn",
  "applicationHomesteadCountyOffice_AsString",
];

export const externalUserDefaultFields = [
  "applicationSummary",
  "firstName",
  "lastName",
  "status_AsString",
  "createdAt_AsDateOnly",
  "processor_FullName",
  "facility_Name",
  "facility_State_AsString",
  "applicationHomesteadCountyOffice_AsString",
];

export const gridRoleColumnsConfiguration: Record<UserRoles, GridColumn[]> = {
  [UserRoles.Administrator]: [
    ...gridColumnsConfiguration()
      .filter((x) => internalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          internalUserDefaultFields.indexOf(a.field) -
          internalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.FieldRepresentative]: [
    ...gridColumnsConfiguration()
      .filter((x) => fieldRepUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          fieldRepUserDefaultFields.indexOf(a.field) -
          fieldRepUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.FieldRepresentativeManager]: [
    ...gridColumnsConfiguration()
      .filter((x) => fieldRepUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          fieldRepUserDefaultFields.indexOf(a.field) -
          fieldRepUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.Processor]: [
    ...gridColumnsConfiguration()
      .filter((x) => processorUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          processorUserDefaultFields.indexOf(a.field) -
          processorUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.ITHelpDesk]: [
    ...gridColumnsConfiguration()
      .filter((x) => internalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          internalUserDefaultFields.indexOf(a.field) -
          internalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.ProcessingManager]: [
    ...gridColumnsConfiguration()
      .filter((x) => processorUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          processorUserDefaultFields.indexOf(a.field) -
          processorUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.DVTProcessor]: [
    ...gridColumnsConfiguration()
      .filter((x) => dvtUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          dvtUserDefaultFields.indexOf(a.field) -
          dvtUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.DVTManager]: [
    ...gridColumnsConfiguration()
      .filter((x) => internalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          internalUserDefaultFields.indexOf(a.field) -
          internalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.Accountant]: [
    ...gridColumnsConfiguration()
      .filter((x) => internalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          internalUserDefaultFields.indexOf(a.field) -
          internalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.Director]: [
    ...gridColumnsConfiguration()
      .filter((x) => internalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          internalUserDefaultFields.indexOf(a.field) -
          internalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.OfficeStaff]: [
    ...gridColumnsConfiguration()
      .filter((x) => internalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          internalUserDefaultFields.indexOf(a.field) -
          internalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.FacilityUser]: [
    ...gridColumnsConfiguration()
      .filter((x) => externalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          externalUserDefaultFields.indexOf(a.field) -
          externalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.CorporateUser]: [
    ...gridColumnsConfiguration()
      .filter((x) => externalUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          externalUserDefaultFields.indexOf(a.field) -
          externalUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.RecertProcessor]: [
    ...gridColumnsConfiguration()
      .filter((x) => dvtUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          dvtUserDefaultFields.indexOf(a.field) -
          dvtUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
  [UserRoles.HybridRepProcessor]: [
    ...gridColumnsConfiguration()
      .filter((x) => dvtUserDefaultFields.includes(x.field))
      .sort(function (a, b) {
        return (
          dvtUserDefaultFields.indexOf(a.field) -
          dvtUserDefaultFields.indexOf(b.field)
        );
      }),
  ],
};

export const getGridColumnsConfigurationByRole = (
  role?: UserRoles
): GridColumn[] => {
  if (!role) {
    return [
      ...gridColumnsConfiguration()
        .filter((x) => externalUserDefaultFields.includes(x.field))
        .sort(function (a, b) {
          return (
            externalUserDefaultFields.indexOf(a.field) -
            externalUserDefaultFields.indexOf(b.field)
          );
        }),
    ];
  }

  return gridRoleColumnsConfiguration[role];
};
