import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  CommentLevel,
  CommentSource,
  NewComment,
} from "../../../types/Comment";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newFormShortComment: Partial<NewComment> = {
  message: undefined,
  source: CommentSource.Document,
  level: CommentLevel.Internal,
  isPinned: false,
};

export const shortCommentFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "comment";
  const comment = form as NewComment;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "message"),
      value: comment.message,
      isRequired: true,
      onSubmit: (message: string) => {
        onValueChange({ message: message });
      },
      errorMessages: get(errors, "message"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isPinned"),
      value: comment.isPinned,
      onSubmit: (isPinned: boolean) => {
        onValueChange({ isPinned: isPinned });
      },
      width: "15%",
      errorMessages: get(errors, "isPinned"),
    } as AllFields,
  ];
};
