import {
  Button,
  Card,
  Divider,
  Intent,
  NonIdealState,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC, useEffect, useState } from "react";
import { ExistingInstitution } from "../../../types/Institution";
import {
  ExistingInstitutionContact,
  InstitutionContact,
} from "../../../types/InstitutionContact";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import Form from "../../Forms";
import "../styles.scss";
import {
  institutionContactFormSchema,
  newInstitutionContact,
} from "./InstitutionContactForm";
import "./styles.scss";

export interface InstitutionContactsCardProps {
  institution?: ExistingInstitution;
  createContact: (contact: InstitutionContact) => Promise<void>;
  updateContact: (contact: ExistingInstitutionContact) => Promise<void>;
  deleteContact: (contact: ExistingInstitutionContact) => Promise<void>;
}

const newInstitutionContactId = "new-institution-contact";

export const InstitutionContactsCard: FC<InstitutionContactsCardProps> = ({
  institution,
  createContact,
  updateContact,
  deleteContact,
}) => {
  const [localContacts, setLocalContacts] = useState<
    ExistingInstitutionContact[]
  >(institution?.contacts || []);

  const [busyContact, setBusyContact] = useState<string>();
  const isSaving = (contact: ExistingInstitutionContact) => {
    return contact.id === busyContact;
  };
  const hasNewInstitutionContact = localContacts.some(
    (contact) => contact.id === newInstitutionContactId
  );

  useEffect(() => {
    setLocalContacts(institution?.contacts || []);
  }, [institution?.contacts]);

  return (
    <section className="institution-contacts-card-container">
      <Button
        className="add-contact-button"
        intent={Intent.PRIMARY}
        disabled={hasNewInstitutionContact}
        text="Add Contact"
        minimal
        icon={IconNames.Plus}
        onClick={() => {
          const contact = {
            ...newInstitutionContact,
            id: newInstitutionContactId,
          } as ExistingInstitutionContact;

          setLocalContacts([...localContacts, contact]);
        }}
      />
      {localContacts?.length <= 0 && (
        <NonIdealState title="No Information Available" />
      )}
      {localContacts?.length > 0 &&
        localContacts?.map((institutionContact, idx) => {
          const isNewInstitutionContact =
            institutionContact.id === newInstitutionContactId;

          return (
            <>
              <Card
                key={institutionContact.id}
                className="institution-contact-card"
              >
                <Form<ExistingInstitutionContact>
                  formId={`institution-contact-form-${institutionContact.id}`}
                  value={institutionContact || newInstitutionContact}
                  formSchemaBuilder={institutionContactFormSchema}
                  onFormSubmit={async (institutionContact) => {
                    setBusyContact(institutionContact.id);
                    if (isNewInstitutionContact) {
                      await createContact(institutionContact);
                    } else {
                      await updateContact(institutionContact);
                    }
                    setBusyContact(undefined);
                  }}
                />
                <div className="institution-card-footer">
                  <Button
                    intent={Intent.DANGER}
                    minimal
                    icon={IconNames.Trash}
                    onClick={async () => {
                      setBusyContact(institutionContact.id);
                      if (isNewInstitutionContact) {
                        setLocalContacts(
                          localContacts.filter(
                            (contacts) =>
                              contacts.id !== newInstitutionContactId
                          )
                        );
                      } else {
                        await deleteContact(institutionContact);
                      }

                      setBusyContact(undefined);
                    }}
                  />
                  <FormSubmitButton
                    buttonId={`institution-contact-form-create-button-${institutionContact.id}`}
                    isSaving={isSaving(institutionContact)}
                    formId={`institution-contact-form-${institutionContact.id}`}
                    text={isNewInstitutionContact ? "Create" : "Update"}
                  />
                </div>
              </Card>
              {idx + 1 !== localContacts.length && <Divider />}
            </>
          );
        })}
    </section>
  );
};
