enum BankAccount {
  CheckingStatementVerification = 1001,
  SavingsStatementVerification = 1002,
  SpouseCheckingStatementVerification = 1003,
  SpouseSavingsStatementVerification = 1004,
  JointCheckingStatementVerification = 1005,
  JointSavingsStatementVerification = 1006,
}
enum LifeInsurance {
  WholeLifePolicyVerification = 2001,
  GroupLifePolicyVerification = 2002,
  TermLifePolicyVerification = 2003,
  UniversalLifePolicyVerification = 2004,
  SpouseWholeLifePolicyVerification = 2005,
  SpouseGroupLifePolicyVerification = 2006,
  SpouseTermLifePolicyVerification = 2007,
  SpouseUniversalLifePolicyVerification = 2008,
  JointWholeLifePolicyVerification = 2009,
  JointGroupLifePolicyVerification = 2010,
  JointTermLifePolicyVerification = 2011,
  JointUniversalLifePolicyVerification = 2012,
  LifeInsurancePolicyVerificationOfValue = 2013,
}
export enum Vehicle {
  AutomobileRegistrationTitleValueVerification = 3001,
  BoatRegistrationTitleValueVerification = 3002,
  MotorcycleRegistrationTitleValueVerification = 3003,
  OtherRegistrationTitleValueVerification = 3004,
  SpouseAutomobileRegistrationTitleValueVerification = 3005,
  SpouseBoatRegistrationTitleValueVerification = 3006,
  SpouseMotorcycleRegistrationTitleValueVerification = 3007,
  SpouseOtherRegistrationTitleValueVerification = 3008,
  JointAutomobileRegistrationTitleValueVerification = 3009,
  JointBoatRegistrationTitleValueVerification = 3010,
  JointMotorcycleRegistrationTitleValueVerification = 3011,
  JointOtherRegistrationTitleValueVerification = 3012,
}
enum Burial {
  BurialArrangementVerification = 4001,
  SpouseBurialArrangementVerification = 4002,
  JointBurialArrangementVerification = 4003,
  BurialTrustVerification = 4004,
}

enum Property {
  HomesteadFMVVerification = 5001,
  PropertyFMVVerification = 5002,
  SpouseHomesteadFMVVerification = 5003,
  SpousePropertyFMVVerification = 5004,
  JointHomesteadFMVVerification = 5005,
  JointPropertyFMVVerification = 5006,
  InheritrancePropertyVerification = 5007,
}

enum Bills {
  ElectricVerification = 6001,
  WaterVerification = 6002,
  TrashVerification = 6003,
  PhoneVerification = 6004,
  PropertyTaxVerification = 6005,
  HomeownersInsuranceVerification = 6006,
  GasVerification = 6007,
  CableVerification = 6008,
  InsuranceVerification = 6009,
  OtherVerification = 6010,
  SpouseElectricVerification = 6011,
  SpouseWaterVerification = 6012,
  SpouseTrashVerification = 6013,
  SpousePhoneVerification = 6014,
  SpousePropertyTaxVerification = 6015,
  SpouseHomeownersInsuranceVerification = 6016,
  SpouseGasVerification = 6017,
  SpouseCableVerification = 6018,
  SpouseInsuranceVerification = 6019,
  SpouseOtherVerification = 6020,
  JointElectricVerification = 6021,
  JointWaterVerification = 6022,
  JointTrashVerification = 6023,
  JointPhoneVerification = 6024,
  JointPropertyTaxVerification = 6025,
  JointHomeownersInsuranceVerification = 6026,
  JointGasVerification = 6027,
  JointCableVerification = 6028,
  JointInsuranceVerification = 6029,
  JointOtherVerification = 6030,
}
enum AdditionalIncome {
  CivilServiceAnnuityVerification = 7001,
  PublicRetirementVerification = 7002,
  UnionFundsOrPensionBenefitsVerification = 7003,
  WidowerIncomeVerification = 7004,
  OtherSourcesVerification = 7005,
  SpouseCivilServiceAnnuityVerification = 7006,
  SpousePublicRetirementVerification = 7007,
  SpouseUnionFundsOrPensionBenefitsVerification = 7008,
  SpouseWidowerIncomeVerification = 7009,
  SpouseOtherSourcesVerification = 7010,
  JointCivilServiceAnnuityVerification = 7011,
  JointPublicRetirementVerification = 7012,
  JointUnionFundsOrPensionBenefitsVerification = 7013,
  JointWidowerIncomeVerification = 7014,
  JointOtherSourcesVerification = 7015,
  EarnedIncomeVerification = 7016,
  SpouseEarnedIncomeVerification = 7017,
  JointEarnedIncomeVerification = 7018,
  RentalIncomeVerification = 7019,
  SpouseRentalIncomeVerification = 7020,
  JointRentalIncomeVerification = 7021,
}
enum MedicalCoverage {
  AccidentOnlyPolicyVerification = 8001,
  AutoPIPPolicyVerification = 8002,
  BasicHospitalPolicyVerification = 8003,
  BasicHospitalMedicalSurgicalPolicyVerification = 8004,
  BasicMedicalPolicyVerification = 8005,
  BasicSurgicalPolicyVerification = 8006,
  HMOPolicyVerification = 8007,
  HospitalIndemnityPolicyVerification = 8008,
  MajorMedicalPolicyVerification = 8009,
  MedicareSupplementPolicyVerification = 8010,
  MedicarePremiumVerficiation = 8011,
  MedicarePartBPolicyVerification = 8012,
  MedicarePartCPolicyVerification = 8013,
  MedicarePartDPolicyVerification = 8014,
  MotorcyclePassengerPolicyVerification = 8015,
  NursingHomeSuppPolicyVerification = 8016,
  SpecDiseaseCancerPolicyVerification = 8017,
  SpecDiseaseHeartPolicyVerification = 8018,
  RXPrescriptionDrugPlanPolicyVerification = 8019,
  SpouseAccidentOnlyPolicyVerification = 8020,
  SpouseAutoPIPPolicyVerification = 8021,
  SpouseBasicHospitalPolicyVerification = 8022,
  SpouseBasicHospitalMedicalSurgicalPolicyVerification = 8023,
  SpouseBasicMedicalPolicyVerification = 8024,
  SpouseBasicSurgicalPolicyVerification = 8025,
  SpouseHMOPolicyVerification = 8026,
  SpouseHospitalIndemnityPolicyVerification = 8027,
  SpouseMajorMedicalPolicyVerification = 8028,
  SpouseMedicareSupplementPolicyVerification = 8029,
  SpouseMedicarePartAPolicyVerification = 8030,
  SpouseMedicarePartBPolicyVerification = 8031,
  SpouseMedicarePartCPolicyVerification = 8032,
  SpouseMedicarePartDPolicyVerification = 8033,
  SpouseMotorcyclePassengerPolicyVerification = 8034,
  SpouseNursingHomeSuppPolicyVerification = 8035,
  SpouseSpecDiseaseCancerPolicyVerification = 8036,
  SpouseSpecDiseaseHeartPolicyVerification = 8037,
  SpouseRXPrescriptionDrugPlanPolicyVerification = 8038,
  JointAccidentOnlyPolicyVerification = 8039,
  JointAutoPIPPolicyVerification = 8040,
  JointBasicHospitalPolicyVerification = 8041,
  JointBasicHospitalMedicalSurgicalPolicyVerification = 8042,
  JointBasicMedicalPolicyVerification = 8043,
  JointBasicSurgicalPolicyVerification = 8044,
  JointHMOPolicyVerification = 8045,
  JointHospitalIndemnityPolicyVerification = 8046,
  JointMajorMedicalPolicyVerification = 8047,
  JointMedicareSupplementPolicyVerification = 8048,
  JointMedicarePartAPolicyVerification = 8049,
  JointMedicarePartBPolicyVerification = 8050,
  JointMedicarePartCPolicyVerification = 8051,
  JointMedicarePartDPolicyVerification = 8052,
  JointMotorcyclePassengerPolicyVerification = 8053,
  JointNursingHomeSuppPolicyVerification = 8054,
  JointSpecDiseaseCancerPolicyVerification = 8055,
  JointSpecDiseaseHeartPolicyVerification = 8056,
  JointRXPrescriptionDrugPlanPolicyVerification = 8057,
}
enum OtherAsset {
  IRAVerification = 9001,
  StocksBondsVerification = 9002,
  RFMSRTAVerification = 9003,
  OtherLiquidAssetsVerification = 9004,
  SpouseIRAVerification = 9005,
  SpouseStocksBondsVerification = 9006,
  SpouseRFMSRTAVerification = 9007,
  SpouseOtherLiquidAssetsVerification = 9008,
  JointIRAVerification = 9009,
  JointStocksBondsVerification = 9010,
  JointRFMSRTAVerification = 9011,
  JointOtherLiquidAssetsVerification = 9012,
}

const bankAccountDocumentTypes = Object.values(BankAccount);
const lifeInsuranceDocumentTypes = Object.values(LifeInsurance);
const vehicleDocumentTypes = Object.values(Vehicle);
const burialDocumentTypes = Object.values(Burial);
const propertyDocumentTypes = Object.values(Property);
const billsDocumentTypes = Object.values(Bills);
const medicalCoverageDocumentTypes = Object.values(MedicalCoverage);
const additionalIncomeDocumentTypes = Object.values(AdditionalIncome);
const otherAssetDocumentTypes = Object.values(OtherAsset);

export const ownedEntityDocumentTypes = [
  ...bankAccountDocumentTypes,
  ...lifeInsuranceDocumentTypes,
  ...vehicleDocumentTypes,
  ...burialDocumentTypes,
  ...propertyDocumentTypes,
  ...billsDocumentTypes,
  ...medicalCoverageDocumentTypes,
  ...additionalIncomeDocumentTypes,
  ...otherAssetDocumentTypes,
];
