import { Card } from "@blueprintjs/core";
import { FC, useState } from "react";
import { Application } from "../../../../types/Application";
import {
  Asset,
  AssetInformation,
  NewAsset,
} from "../../../../types/Application/Asset";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../Forms";
import assetSchemas, {
  newAssetInformation,
} from "../../../Forms/ApplicationForm/Assets";
import "../../styles.scss";
import { AssetsFormCard } from "./AssetsFormCard";
import { userStore } from "../../../../stores/UserStore";
import { BaseField } from "../../../Forms/BaseField/BaseField";
import { overrideAssetEligibilityFormSchema } from "../../../Forms/ApplicationForm/Assets/OverrideAssetEligibilityForm";
import { featureToggleStore } from "../../../../lib/featureToggles/FeatureToggleStore";

export interface AssetsCardProps {
  application?: Application;
  canEdit?: boolean;
  createAsset: (insurance: NewAsset) => Promise<void>;
  updateAsset: () => Promise<void>;
  deleteAsset: (insurance: Asset) => Promise<void>;
  submitForm: (assetInformation: AssetInformation) => Promise<void>;
}

export const AssetInformationCard: FC<AssetsCardProps> = ({
  application,
  canEdit,
  createAsset,
  updateAsset,
  deleteAsset,
  submitForm,
}) => {
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);
  return (
    <>
      <Card>
        <div className="assets-form-header-actions">
          <SectionHeader label="Assets Information" type="Section" />
          {userStore.user?.canOverrideAssetEligibility &&
            (!application?.isRecertProductType ||
              (application.isRecertProductType &&
                featureToggleStore.featureToggles?.EnableRecertAMD)) &&
            application?.isMarried &&
            !application?.isSpouseLTCFacility && (
              <BaseField
                {...overrideAssetEligibilityFormSchema.overrideAssetEligibility(
                  application,
                  setFormIsSaving
                )}
                type="Switch"
              />
            )}
        </div>
        {assetSchemas.map((schema) => {
          const formId = `asset-${schema.formId}-form`;

          return (
            <div className="asset-card" key={schema.formId}>
              <Form<AssetInformation>
                formId={formId}
                value={application || newAssetInformation}
                disableAllFields={shouldDisableForm || !canEdit}
                formSchemaBuilder={schema.application}
                shouldBlockNavigation={true}
                onFormSubmit={async (application) => {
                  setShouldDisableForm(true);
                  setFormIsSaving(true);
                  await submitForm(application);
                  setShouldDisableForm(false);
                  setFormIsSaving(false);
                }}
              />
              {application && schema.shouldShowAssetCard(application) && (
                <AssetsFormCard
                  assets={application?.assets.filter(
                    (asset) => asset.type === schema.type
                  )}
                  applicationId={application.id}
                  assetType={schema.type}
                  title={schema.title}
                  assetFormSchema={schema.asset}
                  shouldSaveEntityToLocalStore={true}
                  createAsset={createAsset}
                  updateAsset={updateAsset}
                  deleteAsset={deleteAsset}
                  shouldDisableForm={shouldDisableForm || !canEdit}
                  setShouldDisableForm={setShouldDisableForm}
                  formIsSaving={formIsSaving}
                  setFormIsSaving={setFormIsSaving}
                  showAddButton={true}
                  showCancelButton={false}
                />
              )}
            </div>
          );
        })}
      </Card>
    </>
  );
};
