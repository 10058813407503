import { ExpressionData, ExpressionTypes } from "../stores/ExpressionsStore";

export interface TemplateField {
  id: string;
  name: string;
  expanded: boolean;
  templateExpression?: ExpressionData;
  fields?: TemplateField[];
}

export const defaultTemplateFieldsConfig = [
  {
    id: "special-items",
    name: "Special Items",
    expanded: true,
    fields: [
      {
        id: "annotation",
        name: "Annotation",
        templateExpression: {
          expressionName: "Annotation",
          expressionPlaceholder: "Type Here",
          expressionType: ExpressionTypes.FreeText,
        } as ExpressionData,
      },
      {
        id: "dot",
        name: "Dot",
        templateExpression: {
          expressionName: "Dot",
          expressionPlaceholder: "Dot",
          expressionType: ExpressionTypes.Circle,
        } as ExpressionData,
      },
      {
        id: "rectangle",
        name: "Rectangle",
        templateExpression: {
          expressionName: "Rectangle",
          expressionType: ExpressionTypes.Rectangle,
        } as ExpressionData,
      },
    ],
  },
] as TemplateField[];
