import { Button, Intent } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { Income, IncomeOwnerTypes } from "../../../../types/Application/Income";
import { AddButton } from "../../../Buttons/AddButton";
import RelativityCard from "../../../Card/RelativityCard";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import { newIncome } from "../../../Forms/ApplicationForm/Income";
import "../../styles.scss";
import { IncomeFormCard } from "./IncomeFormCard";
import _, { orderBy } from "lodash";

export interface IncomesFormCardProps {
  incomes?: Income[];
  createAndUpdateIncomes: () => Promise<void>;
  deleteIncome?: (income: Income) => Promise<void>;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  showCancelButton: boolean;
  onCancel?: () => void;
  applicationId?: string;
  showAddButton: boolean;
  defaultIncomeOwner?: IncomeOwnerTypes;
}

export const newIncomeId = "new-income";

export const IncomesFormCard: FC<IncomesFormCardProps> = ({
  incomes,
  createAndUpdateIncomes,
  deleteIncome,
  shouldDisableForm,
  setShouldDisableForm,
  formIsSaving,
  setFormIsSaving,
  showCancelButton,
  onCancel,
  applicationId,
  showAddButton,
  defaultIncomeOwner,
}) => {
  const [localIncomes, setLocalIncomes] = useState<Income[]>(incomes || []);
  const [isAddingAction, setIsAddingAction] = useState<boolean>(false);

  const hasNewIncome = localIncomes.some((income) => income.id === newIncomeId);

  useEffect(() => {
    setLocalIncomes(incomes || []);
  }, [incomes]);

  return (
    <div>
      <RelativityCard
        headerContent={
          <div className="owned-entity-list-header">
            <SectionHeader
              type="Section"
              label="Additional Income"
              inAction={formIsSaving}
            />
            {!!showCancelButton && (
              <Button
                minimal
                intent={Intent.DANGER}
                text="Cancel"
                onClick={onCancel}
              />
            )}
            {!showCancelButton && showAddButton && (
              <AddButton
                loading={isAddingAction}
                disabled={shouldDisableForm || formIsSaving || hasNewIncome}
                text="Add Additional Income"
                onClick={() => {
                  setShouldDisableForm(true);
                  setIsAddingAction(true);
                  setLocalIncomes([
                    ...localIncomes,
                    {
                      ...newIncome,
                      id: newIncomeId,
                      incomeOwner: defaultIncomeOwner,
                    } as Income,
                  ]);
                  setShouldDisableForm(false);
                  setIsAddingAction(false);
                }}
              />
            )}
          </div>
        }
      >
        {orderBy(localIncomes, ["createdAt"], ["desc"]).map((income, index) => {
          return (
            <IncomeFormCard
              key={index}
              localIncomes={localIncomes}
              setLocalIncomes={setLocalIncomes}
              income={income}
              createAndUpdateIncomes={createAndUpdateIncomes}
              deleteIncome={deleteIncome}
              shouldDisableForm={shouldDisableForm}
              setShouldDisableForm={setShouldDisableForm}
              formIsSaving={formIsSaving}
              setFormIsSaving={setFormIsSaving}
              index={index}
              applicationId={applicationId}
            />
          );
        })}
      </RelativityCard>
    </div>
  );
};
