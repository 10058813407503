import { action, makeObservable, observable } from "mobx";
import { FeatureToggleSet } from "./FeatureToggles";
import { featureToggleApiClient } from "./featureToggleApiClient";

class FeatureToggleStore {
  public featureToggles?: FeatureToggleSet;
  constructor() {
    makeObservable(this, {
      featureToggles: observable,
      init: action,
    });
  }

  public async init() {
    if (!this.featureToggles) {
      await this.setFeatureToggles();
    }
  }

  public async setFeatureToggles() {
    this.featureToggles = await featureToggleApiClient.getFeatureToggleSet();
  }
}

export const featureToggleStore = new FeatureToggleStore();
