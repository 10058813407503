import { FormGroup } from "@blueprintjs/core";
import { DataManager, Predicate, Query } from "@syncfusion/ej2-data";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectChangeEventArgs,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useState } from "react";
import variables from "../../../config/variables";
import { getAccessToken } from "../../../lib/apiClients/baseApiClient";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import {
  AsyncMultiSelectCheckboxFieldProps,
  AsyncMultiselectFieldValue,
  Option,
} from "../types/fieldTypes";
import AsyncMultiSelectODataV4Adaptor from "./AsyncMultiSelectODataV4Adaptor";
import "./styles.scss";

/**
 *
 * SyncFusion async select component has a terrible habit of direct
 * update the value of the input prop and set it to something like [null], ['']
 * in the process
 *
 */
const sanitizeValue = (values: AsyncMultiselectFieldValue) => {
  return values?.filter((value) => value != null && value != "");
};

export const AsyncMultiSelectCheckoxField: React.FC<
  AsyncMultiSelectCheckboxFieldProps
> = ({
  value,
  label,
  description,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  optionValuesODataUrl,
  conditions,
  isRequired,
  isMultiSelect,
  optionFields,
  noRecordsTemplate,
  groupByActiveStatus,
  chainOrPredicates,
}) => {
  if (hidden) {
    return null;
  }
  // TODO: we likely want to manage the access token state in a store
  const [accessToken, setAccessToken] = useState<string>();
  const [dataSource, setDataSource] = useState<DataManager>();
  const localOptions = React.useRef<Option[]>([]);
  const searchRef = React.useRef<MultiSelectComponent | null>(null);
  const cacheConditionsRef = React.useRef<string | null>(null);
  const querysRef = React.useRef<Query | null>(null);

  useEffect(() => {
    getAccessToken().then((accessToken) => {
      setAccessToken(accessToken);
    });
  }, []);

  useEffect(() => {
    const newConditions = JSON.stringify(conditions);
    if (newConditions === cacheConditionsRef.current) {
      return;
    }

    cacheConditionsRef.current = newConditions;
    querysRef.current = getQuery();
  }, [conditions, searchRef]);

  useEffect(() => {
    if (accessToken) {
      setDataSource(
        new DataManager({
          url: `${variables.apiBaseUrl}odata/${optionValuesODataUrl}`,
          adaptor: new AsyncMultiSelectODataV4Adaptor(!!groupByActiveStatus),
          crossDomain: true,
          headers: [{ Authorization: `Bearer ${accessToken}` }],
        })
      );
    }
  }, [accessToken]);

  const getQuery = (queryString?: string) => {
    const predicates = [...(conditions || [])];

    if (queryString) {
      predicates.push(new Predicate("label", "contains", queryString, true));
    }

    let where: Predicate | undefined;

    if (predicates) {
      if (!!chainOrPredicates) {
        predicates.forEach((predicate) => {
          where = where ? where.or(predicate) : predicate;
        });
      } else {
        predicates.forEach((predicate) => {
          where = where ? where.and(predicate) : predicate;
        });
      }
    }

    let query1 = new Query().select([
      "label",
      "value",
      ...(optionFields || []),
    ]);

    if (where) {
      query1 = query1.where(where);
    }
    query1 = query1.sortBy("label");

    return query1;
  };

  const showSearchPopup = () => {
    if (!!searchRef.current) {
      searchRef.current.showPopup();
    }
  };

  const setNoRecordsTemplate = () => {
    if (!!searchRef.current && !!noRecordsTemplate) {
      searchRef.current.noRecordsTemplate = noRecordsTemplate(
        searchRef.current
      ) as any;
    }
  };

  // only render when data source is ready
  if (!dataSource) {
    return null;
  }

  setNoRecordsTemplate();

  const groupBy = groupByActiveStatus ? "category" : undefined;
  const itemTemplate =
    '<span class="async-multiselect-item async-multiselect-item-disabled-${isActive}">' +
    '<span class="e-frame e-icons"></span>' +
    '<span class="e-label">${label}</span>' +
    "</label>" +
    "</span>";

  return (
    <FormGroup
      className={`${
        (isRequired && !value) ||
        (isRequired && value.length === 0) ||
        (isRequired &&
          value.filter((x) => x !== null && x !== undefined).length === 0)
          ? "has-required-background"
          : "async-multiselect-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <div className="async-multiselect-toolbar">
        <div className="async-multiselect-input-container">
          <MultiSelectComponent
            ref={searchRef}
            value={sanitizeValue(value)}
            fields={{
              text: "label",
              value: "value",
              groupBy: groupBy,
            }}
            query={querysRef.current || new Query()}
            dataBound={(event) => {
              localOptions.current = event.items;
            }}
            changeOnBlur={false}
            change={async (e: MultiSelectChangeEventArgs) => {
              // Syncfusion emits a change event on load of the initial data.
              // We only want to update the form state if the change was user-intiated.
              if (e.isInteracted) {
                const defaultOpt: Option = { label: "", value: "" };
                onSubmit(
                  e.value.length === 0
                    ? [defaultOpt]
                    : e.value.map(
                        (value) =>
                          // Syncfusion returns a list of the currently selected option values,
                          // but we want more metadata.
                          localOptions.current.find(
                            (opt) => opt.value === value
                          ) || defaultOpt
                      )
                );
              }
            }}
            className="async-multiselect-input"
            showClearButton={true}
            placeholder="Click to select..."
            focus={showSearchPopup}
            onClick={showSearchPopup}
            disabled={disabled}
            dataSource={dataSource}
            allowFiltering={false}
            ignoreCase
            itemTemplate={itemTemplate}
            sortOrder={"Ascending"}
            maximumSelectionLength={isMultiSelect ? 1000 : 1}
            mode="CheckBox"
          >
            <Inject services={[CheckBoxSelection]} />
          </MultiSelectComponent>
        </div>
      </div>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
