import { FC, useEffect, useState } from "react";
import { useFieldSchemaFactory } from "../../../../lib/fieldSchemaV2/useFieldSchemaV2Factory";
import { AppointmentContactFormModel } from "./AppointmentContactFormModel";
import { FieldFormV2, FieldV2 } from "@ucl/library";
import { Loading } from "../../../Loading/Loading";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import schedulingApiClient from "../../../../lib/apiClients/scheduling/schedulingApiClient";
import { AppToaster } from "../../../Toast/Toast";
import { getAppointmentContactFormSchema } from "./AppointmentContactFormSchema";

export const defaultAppointmentContactFormModel: AppointmentContactFormModel = {
  id: undefined,
  appointmentId: undefined,
  firstName: undefined,
  lastName: undefined,
  phoneNumber: undefined,
  email: undefined,
  preferredContactMethod: undefined,
  relationshipToResident: undefined,
};

export interface AppointmentContactFormProps {
  contactIndex: number;
  formModel: AppointmentContactFormModel;
  isRequired: boolean;
  errors?: {
    [key: string]: string[];
  };
  shouldShowSaveButton?: boolean;
  shouldDisableForm?: boolean;
  onFieldChanged?: (form: AppointmentContactFormModel) => void;
  onDelete?: () => void;
}

export const AppointmentContactForm: FC<AppointmentContactFormProps> = (
  props
) => {
  const { schemaFactory } = useFieldSchemaFactory();
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [appointmentContactFormModel, setAppointmentContactFormModel] =
    useState<AppointmentContactFormModel>({
      ...defaultAppointmentContactFormModel,
      ...props.formModel,
    });

  useEffect(() => {
    props.onFieldChanged?.(appointmentContactFormModel);
  }, [appointmentContactFormModel]);

  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);

  const appointmentId = appointmentContactFormModel.appointmentId;
  const contactId = appointmentContactFormModel.id;

  const isEditing = !!appointmentId && !!contactId;
  const formDisabled = props.shouldDisableForm || isSubmitting || isDeleting;

  const handleDelete = async () => {
    if (isEditing) {
      setIsDeleting(true);
      await schedulingApiClient
        .deleteAppointmentContact(appointmentId, contactId)
        .catch((error) => {
          AppToaster.show({
            message: "Unexpected error occurred while deleting contact",
            intent: Intent.DANGER,
          });
          console.error(error);
        })
        .finally(() => {
          setIsDeleting(false);
        });
    }
    props.onDelete?.();
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);

    await (isEditing
      ? schedulingApiClient.updateAppointmentContact(
          appointmentContactFormModel
        )
      : schedulingApiClient.createAppointmentContact(
          appointmentContactFormModel
        )
    )
      .then((response) => {
        setAppointmentContactFormModel(response);
        setErrors({});
        AppToaster.show({
          message: `Contact ${isEditing ? "updated" : "created"} successfully`,
          intent: Intent.SUCCESS,
        });
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: `Unexpected error occurred while ${
              isEditing ? "updating" : "creating"
            } contact`,
            intent: Intent.DANGER,
          });
        }
        const errorResponse = error?.response?.data?.additionalInfo;
        if (errorResponse) {
          setErrors(errorResponse);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (!schemaFactory) {
    return <Loading />;
  }

  const schema = getAppointmentContactFormSchema(
    schemaFactory,
    errors,
    appointmentContactFormModel,
    setAppointmentContactFormModel,
    props.isRequired
  );

  return (
    <div className="appointment-contact-form">
      <div className="appointment-contact-form_header">
        <div className="appointment-contact-form_header_title">
          Contact {props.contactIndex + 1}
        </div>
        <div className="appointment-contact-form_header_actions">
          {props.shouldShowSaveButton && (
            <Button
              minimal
              className="appointment-contact-form_header_actions_save"
              intent={Intent.PRIMARY}
              rightIcon={IconNames.FLOPPY_DISK}
              disabled={formDisabled}
              loading={isSubmitting}
              onClick={handleFormSubmit}
            />
          )}
          {!props.isRequired && (
            <Button
              minimal
              className="appointment-contact-form_header_actions_delete"
              intent={Intent.DANGER}
              rightIcon={IconNames.TRASH}
              disabled={formDisabled}
              loading={isDeleting}
              onClick={async () => await handleDelete()}
            />
          )}
        </div>
      </div>
      <FieldFormV2<AppointmentContactFormModel> isDisabled={formDisabled}>
        <FieldV2 fieldProps={schema.FirstName} />
        <FieldV2 fieldProps={schema.LastName} />
        <FieldV2 fieldProps={schema.PhoneNumber} />
        <FieldV2 fieldProps={schema.Email} />
        <FieldV2 fieldProps={schema.PreferredContactMethod} />
        <FieldV2 fieldProps={schema.RelationshipToResident} />
      </FieldFormV2>
    </div>
  );
};
