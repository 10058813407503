import { Ajax } from "@syncfusion/ej2-base";
import {
  CrudOptions,
  DataOptions,
  DataResult,
  Query,
  ODataV4Adaptor,
  DataManager,
} from "@syncfusion/ej2-data";
import { ODataBeforeSendArgs } from "../../Grid/Grid/types/ODataBeforeSendArgs";

export default class AsyncMultiSelectODataV4Adaptor extends ODataV4Adaptor {
  private onBeforeSend?: (beforeSendArgs: ODataBeforeSendArgs) => string;

  constructor(
    private groupbyActiveStatus: boolean,
    beforeSendFn?: (beforeSendArgs: ODataBeforeSendArgs) => string
  ) {
    super();

    this.onBeforeSend = beforeSendFn;
  }

  beforeSend = async (
    dataManager: DataManager,
    request: XMLHttpRequest,
    ajax: Ajax
  ) => {
    // Fetch Query
    const url = new URL(ajax.url);
    let queryString = url.search;

    // Cleanups
    //--Replaces any undefined keyword for null to supprto api types
    queryString = queryString.replace(/undefined/g, "null");

    // Send onBeforeSend event
    if (!!this.onBeforeSend) {
      queryString = this.onBeforeSend({
        dataManager,
        request,
        ajax,
        queryString,
      } as ODataBeforeSendArgs);
    }

    // Send request on Body
    // Note that is important that the body still sends the ? on the beginning for full support of queriers, including top.
    //queryString = queryString + "&$orderby=createdAt%20desc";
    ajax.data = queryString;
    request.open("POST", String(`${url.origin}${url.pathname}$query`));
  };

  processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Ajax,
    changes?: CrudOptions
  ): Object {
    const processResult = super.processResponse.apply(this, [
      data,
      ds,
      query,
      xhr,
      request,
      changes,
    ]);

    if (this.groupbyActiveStatus) {
      const processResultActive = (processResult as Array<any>)
        .filter((x) => x.isActive)
        .map((x) => {
          return {
            ...x,
            ...{ category: "Active" },
          };
        });

      const processResultInactive = (processResult as Array<any>)
        .filter((x) => !x.isActive)
        .map((x) => {
          return {
            ...x,
            ...{ category: "Inactive" },
          };
        });

      return [
        ...(processResultActive as any),
        ...(processResultInactive as any),
      ];
    }

    return processResult;
  }
}
