import { ExistingFacility, Facility } from "../../../types/Facility";
import baseApiClient from "../baseApiClient";

class FacilityApiClient {
  createFacility = async (facility: Facility) => {
    return baseApiClient.post("facility", facility);
  };

  getFacilityDetail = async (id: string) => {
    return baseApiClient.get<ExistingFacility>(`facility/${id}`);
  };

  getFacilitiesByCorporateId = async (corporateId: string) => {
    return baseApiClient.get<ExistingFacility[]>(
      `facility/by-corporate/${corporateId}`
    );
  };

  getFaciltiesByFieldRepId = async (fieldRepId: string) => {
    return baseApiClient.get<ExistingFacility[]>(
      `facility/by-field-rep/${fieldRepId}`
    );
  };

  updateFacility = async (id: string, facility: ExistingFacility) => {
    return baseApiClient.put(`facility/${id}`, facility);
  };
}

export const facilityApiClient = new FacilityApiClient();
