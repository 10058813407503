import { ColumnModel } from "@syncfusion/ej2-react-grids";
import { GridColumn } from "@ucl/library";
import { formatDateToMMddYYYY } from "../../../utils/date";
import { currency } from "../../Application/EligibilitySummaryUtilities";
import { billingRuleGridStore } from "../store/BillingRuleGridStore";

interface GridBillingRuleTooltipColumnOptions extends ColumnModel {}

export default class GridBillingRuleTooltipColumn extends GridColumn {
  constructor(opts: GridBillingRuleTooltipColumnOptions) {
    const { ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const productType = Number(model["productType"]);
        const amount = Number(model["amount"]);
        const endDate = Number(model["endDate"]);
        const corporateDetail = billingRuleGridStore.corporateDetail;
        const shouldIncrease =
          corporateDetail?.annualRateIncreaseEffectiveProductTypes?.some(
            (pt) => pt == productType
          ) &&
          !!corporateDetail.isAnnualRateIncreaseEnabled &&
          !endDate;

        return (
          <div className="grid-tooltip-column">
            {shouldIncrease &&
            billingRuleGridStore.corporateDetail?.nextRateIncreaseDate ? (
              <div>
                {currency(
                  amount * (1 + (corporateDetail?.annualRateIncrease as number))
                )}{" "}
                at{" "}
                {formatDateToMMddYYYY(
                  billingRuleGridStore.corporateDetail?.nextRateIncreaseDate
                )}
              </div>
            ) : null}
          </div>
        );
      }) as any,
    });
  }
}
