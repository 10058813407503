import { Button, Classes, Dialog } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { Observer } from "mobx-react";
import React from "react";
import { GridViewConfiguration } from "./configuration/ViewConfiguration";
import "./styles.scss";
import { GridColumn, GridView } from "@ucl/library";

export interface GridViewPickerProps {
  gridView?: GridView;

  baseGridColumnsConfiguration?: GridColumn[];
  updateViewColumnSettings: (
    viewId: string,
    columnSettings: string[]
  ) => Promise<void>;
}
export const GridViewPicker: React.FC<GridViewPickerProps> = (props) => {
  const [isPickerDialogOpen, setIsPickerDialogOpen] =
    React.useState<boolean>(false);

  const closeDialog = () => {
    setIsPickerDialogOpen(false);
  };
  return (
    <Observer>
      {() => {
        return (
          <section
            className={classNames("grid-views-picker", {
              ready: props.gridView,
            })}
          >
            <>
              <div className="grid-views-picker-config">
                <Button
                  title="Board View Configuration"
                  minimal={true}
                  icon={IconNames.COG}
                  disabled={!props.gridView}
                  onClick={() => {
                    !!props.gridView && setIsPickerDialogOpen(true);
                  }}
                >
                  <span
                    className={classNames({
                      "bp4-skeleton": !props.gridView,
                    })}
                  >{`View Configuration`}</span>
                </Button>
              </div>
              {props.gridView && (
                <div className="grid-views-picker-dialog">
                  <Dialog
                    isOpen={isPickerDialogOpen}
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    hasBackdrop={false}
                    portalClassName="mdr-dialog grid-views-picker-dialog"
                    title={`View Configuration`}
                    isCloseButtonShown={true}
                    onClose={() => setIsPickerDialogOpen(false)}
                  >
                    <div className={Classes.DIALOG_BODY}>
                      <GridViewConfiguration
                        gridView={props.gridView}
                        baseGridColumnsConfiguration={
                          props.baseGridColumnsConfiguration
                        }
                        updateViewColumnSettings={
                          props.updateViewColumnSettings!
                        }
                        onCancel={closeDialog}
                        onSubmit={closeDialog}
                      />
                    </div>
                  </Dialog>
                </div>
              )}
            </>
          </section>
        );
      }}
    </Observer>
  );
};
