import { Button, Intent } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { reactPlugin } from "../../AppInsights";
import variables from "../../config/variables";
import "../../pages/styles.scss";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { GridFiltersModule } from "../Grid/Filters/Filters";
import { Grid } from "../Grid/Grid/Grid";
import { GridPermissions } from "../Grid/types/GridPermissions";
import { GridViewPicker } from "../Grid/Views/GridViewPicker";
import {
  getGridColumnsConfigurationByRole,
  gridColumnsConfiguration,
} from "./configurations/gridColumnsConfiguration";
import { TemplateODataReadDTO } from "./configurations/types";
import { GridFilterSettings } from "../Grid/Grid/types/GridFilterSettings";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../lib/apiClients/baseApiClient";
import { autorun } from "mobx";
import { gridStore } from "../Grid/Grid/stores/GridStore";

export interface TemplateGridProps extends GridPermissions {}

const TemplateGridComponent: React.FC<TemplateGridProps> = ({
  canExportGridToExcel,
  canConfigureBoard,
  canInvokeGridRecord,
  canCreateNewRecord,
}) => {
  const navigate = useNavigate();
  const gridKey = "templates-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Templates Board",
    gridColumnsConfiguration,
    getGridColumnsConfigurationByRole,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>
            {canConfigureBoard && (
              <GridViewPicker
                gridView={gridView.currentGridView}
                baseGridColumnsConfiguration={
                  gridView.baseGridColumnsConfiguration
                }
                updateViewColumnSettings={gridView.updateViewColumnSettings}
              />
            )}
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            <GridFiltersModule />
          </li>
          <li>
            {canCreateNewRecord && (
              <Button
                intent={Intent.PRIMARY}
                text="Create New Template"
                onClick={() =>
                  dialogsViewerStore.setIsTemplateCreationDialogOpen(true)
                }
              />
            )}
          </li>
        </ul>
      </section>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && (
                <Grid<TemplateODataReadDTO>
                  key={gridView.currentGridViewKey}
                  gridKey={gridKey}
                  className="template-grid"
                  dataSource={`${variables.apiBaseUrl}odata/templateodata`}
                  columnsConfiguration={[
                    ...gridView.currentGridView.columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(gridView.currentGridView.filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(gridView.currentGridView.sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    allowExcelExport: canExportGridToExcel,
                    exportFilename: "Templates.xlsx",
                    onRecordClick: ({ rowData }) => {
                      if (canInvokeGridRecord) {
                        navigate(`/templates/${rowData.id}`);
                      }
                    },
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};

export const TemplateGrid = withAITracking(
  reactPlugin,
  TemplateGridComponent,
  "TemplateGrid",
  "AI-flex-container"
);
