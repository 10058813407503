import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { MenuEventArgs } from "@syncfusion/ej2-react-navigations";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { reactPlugin } from "../../../../AppInsights";
import variables from "../../../../config/variables";
import "../../../../pages/styles.scss";
import { Grid } from "../../../Grid/Grid/Grid";
import { gridStore } from "../../../Grid/Grid/stores/GridStore";
import { GridPermissions } from "../../../Grid/types/GridPermissions";
import "../../styles.scss";
import {
  getGridColumnsConfigurationByRole,
  gridColumnsConfiguration,
} from "../configurations/gridColumnsConfiguration";
import { GridMetrics } from "../../../Grid/Metrics/GridMetrics";
import { ApplicationMetricReadDTO } from "../types";
import { userStore } from "../../../../stores/UserStore";
import { autorun } from "mobx";
import { GridFilterSettings } from "../../../Grid/Grid/types/GridFilterSettings";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../../../lib/apiClients/baseApiClient";

export interface AllApplicationGridProps extends GridPermissions {}

interface GridApplicationReadDTO {
  id: string;
  status: string;
}
const AllApplicationGridComponent: React.FC<AllApplicationGridProps> = ({
  canExportGridToExcel,
  canInvokeGridRecord,
}) => {
  const navigate = useNavigate();
  const gridKey = "application-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Application Board",
    gridColumnsConfiguration(),
    getGridColumnsConfigurationByRole,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  const onRecordClick = (
    rowData: GridApplicationReadDTO,
    openInNewTab?: boolean
  ) => {
    let redirectTo: string | undefined = undefined;

    if (canInvokeGridRecord && userStore.user?.canViewApplicationDetail) {
      if (rowData.status === "Interviewing") {
        redirectTo = `/application-form/${rowData.id}/primary-information`;
      } else {
        redirectTo = `/application-page/${rowData.id}`;
      }
    }

    if (!redirectTo) {
      return;
    }

    if (openInNewTab) {
      window.open(redirectTo, "_blank");
    } else {
      navigate(redirectTo);
    }
  };

  return (
    <div>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && (
                <Grid<GridApplicationReadDTO>
                  className="application-grid"
                  gridKey={gridKey}
                  key={gridView.currentGridViewKey}
                  dataSource={`${variables.apiBaseUrl}odata/applicationodatagrid`}
                  columnsConfiguration={[
                    ...gridView.currentGridView.columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(gridView.currentGridView.filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(gridView.currentGridView.sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    allowTextWrap: true,
                    textWrapSettings: { wrapMode: "Header" },
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    toolbar: [
                      { text: "Search", tooltipText: "Search Active Files" },
                      {
                        text: "Export to Excel",
                        tooltipText: "Export to Excel",
                        prefixIcon: "e-upload-2",
                        id: "ExcelExport",
                      },
                    ],
                    allowExcelExport: canExportGridToExcel,
                    exportFilename: "Applications.xlsx",
                    contextMenuItems: [
                      {
                        text: "Open in new tab",
                        target: ".e-content",
                        id: "open-in-new-tab",
                      },
                    ],
                    contextMenuClick: (
                      args: MenuEventArgs & {
                        rowInfo: { rowData: GridApplicationReadDTO };
                      }
                    ) => {
                      if (
                        gridStore.gridInstance &&
                        args.item.id === "open-in-new-tab"
                      ) {
                        onRecordClick(args.rowInfo.rowData, true);
                      }
                    },
                    onRecordClick: ({ rowData }) => {
                      onRecordClick(rowData);
                    },
                  }}
                  gridHeightSettings={{
                    autoHeightOffset: userStore.user?.isExternal ? 250 : 330,
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
      {!userStore.user?.isExternal && (
        <section className="board-grid-footer">
          <GridMetrics<ApplicationMetricReadDTO>
            key={gridView.currentGridViewKey}
            gridKey={gridKey}
            url={`${variables.apiBaseUrl}api/application/metrics`}
            columns={4}
            minimal={true}
            languageMapping={{
              countApplications: "Count of Applications",
              averageProcessingAge: "Avg. Processing Age (Days)",
              percentRepCommentAgeOverDue: "% Rep Comment Age >= 5",
              percentProcessorCommentAgeOverDue: "% Processor Comment Age >= 5",
            }}
          />
        </section>
      )}
    </div>
  );
};

export const AllApplicationGrid = withAITracking(
  reactPlugin,
  AllApplicationGridComponent,
  "AllApplicationGrid",
  "AI-flex-container"
);
