import React from "react";
import RelativityCard from "../Card/RelativityCard";
import { BaseField } from "../Forms/BaseField/BaseField";
import { fiaRequestFormSchema } from "../Forms/FIARequestForm/FIARequestForm";
import { FIARequest } from "./configurations/types";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface FIARequestInfoProps {
  fiaRequest: FIARequest;
  numberOfColumns?: number;
}

export const FIARequestInfo: React.FC<FIARequestInfoProps> = ({
  fiaRequest,
  numberOfColumns,
}) => {
  return (
    <section className="fia-request-info-container">
      <RelativityCard
        headerContent="Request Info"
        columns={numberOfColumns ?? 4}
      >
        <BaseField
          {...fiaRequestFormSchema.requestNumber_AsString(fiaRequest)}
        />
        <BaseField {...fiaRequestFormSchema.submittedDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.approvedDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.createdDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.receivedDate(fiaRequest)} />
        <BaseField {...fiaRequestFormSchema.canceledDate(fiaRequest)} />
        {featureToggleStore.featureToggles?.EnableFiaFollowUpReason && (
          <BaseField
            {...fiaRequestFormSchema.followUpReason_AsString(fiaRequest)}
          />
        )}
      </RelativityCard>
    </section>
  );
};
