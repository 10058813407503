import { FC } from "react";
import { ResidentPrimaryInformationCard } from "../../Application/Cards/ResidentPanel/ResidentPrimaryInformationCard";
import { ResidentSecondaryInformationCard } from "../../Application/Cards/ResidentPanel/ResidentSecondaryInformationCard";
import { applicationStore } from "../../../stores/ApplicationStore";
import { userStore } from "../../../stores/UserStore";
import { SpouseInformationPageCard } from "../../Application/Cards/ApplicationPage/PrimaryTabs/SpouseInformationPageCard";
import { ApplicationPageContactCard } from "../../Application/Cards/ApplicationPage/PrimaryTabs/Owned Entities/ApplicationPageContactCard";

export interface IdentificationFormComponentProps {
  isResidentIdentification: boolean;
  isSpouseIdentification: boolean;
  isContactIdentification: boolean;
  contactId?: string;
}
export const IdentificationFormComponent: FC<
  IdentificationFormComponentProps
> = (props) => {
  return (
    <div>
      {props.isResidentIdentification && (
        <>
          <ResidentPrimaryInformationCard
            application={applicationStore.application}
            shouldBlockNavigation={true}
            canEdit={
              !!userStore.user?.canEditApplicationPagePrimaryInformation &&
              !!applicationStore.applicationCanBeEdited
            }
            submitForm={async (form) => {
              await applicationStore.updateApplication(
                form,
                "primary-information",
                "resident-panel"
              );
            }}
            missingFieldCount={
              applicationStore.pageMissingFieldCount.find(
                (page) => page.pageName === "primary-information"
              )?.missingFieldCount
            }
            formKey="verify"
          />
          <ResidentSecondaryInformationCard
            application={applicationStore.application}
            shouldBlockNavigation={true}
            canEdit={
              !!userStore.user?.canEditApplicationPageSecondaryInformation &&
              !!applicationStore.applicationCanBeEdited
            }
            submitForm={async (form) => {
              await applicationStore.updateApplication(
                form,
                "secondary-information"
              );
            }}
            missingFieldCount={
              applicationStore.pageMissingFieldCount.find(
                (page) => page.pageName === "secondary-information"
              )?.missingFieldCount
            }
            formKey="verify"
          />
        </>
      )}
      {props.isSpouseIdentification && (
        <SpouseInformationPageCard
          canEdit={
            !!userStore.user?.canEditApplicationPageSpouse &&
            !!applicationStore.applicationCanBeEdited
          }
          application={applicationStore.application}
          submitForm={(form) =>
            applicationStore.updateApplication(form, "spouse-information")
          }
        />
      )}
      {props.isContactIdentification && (
        <ApplicationPageContactCard
          application={applicationStore.application}
          canCreateContact={false}
          canEditContact={!!applicationStore.applicationCanBeEdited}
          submitForm={(form) =>
            applicationStore.updateApplication(form, "primary-information")
          }
          createContact={(form) =>
            applicationStore.createApplicationOwnedEntity({
              page: "contact",
              ownedEntity: form,
            })
          }
          updateContact={(form) =>
            applicationStore.updateApplicationOwnedEntity({
              page: "contact",
              ownedEntity: form,
              ownedEntityId: form.id,
            })
          }
          canDeleteContact={false}
          deleteContact={(form) =>
            applicationStore.deleteApplicationOwnedEntity(form.id, "contact")
          }
          showSingleContact={true}
          contactId={props.contactId}
        />
      )}
    </div>
  );
};
