import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { userStore } from "../../../stores/UserStore";
import {
  ContactTypes,
  SpouseContact,
} from "../../../types/Application/SpouseContact";
import { SpouseInformation } from "../../../types/Application/SpouseInformation";
import { UserRoles } from "../../../types/User";
import { newAddressField } from "../../Fields/AddressField/AddressField";
import {
  AddressFieldValue,
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder, validGovernmentIdUtils } from "../types";
import { getScheduleMappingClassName } from "../../../utils/miscellaneous";

export const newSpouseContact: SpouseContact = {
  scheduleMappingValues: [],
  firstName: "",
  lastName: "",
  middleName: "",
  maidenName: "",
  contactType: 0,
  dateOfBirth: undefined,
  socialSecurityNumber: "",
  email: "",
  phoneNumber: "",
  additionalPhoneNumber: "",
  preferredContactMethod: 0,
  address: newAddressField,
  isIdAvailable: undefined,
  isCredibleWitnessAvailable: undefined,
  idNumber: "",
  race: 0,
  ethnicity: 0,
  countryOfBirth: 0,
  isUsCitizen: undefined,
  preferredLanguages: 0,
  gender: 0,
  canEditSpousePersonalInformation: true,
};

export const newSpouseInformation: SpouseInformation = {
  spouse: newSpouseContact,
  livingArrangementType: 0,
  isSpouseAlsoApplyingForAssistance: undefined,
  isSpouseDisabledOrBlind: undefined,
  hasSpouseDisabilityBeenDecided: undefined,
  willSpouseDisabilityLastLongerThan30Days: undefined,
  willSpouseDisabilityLastLongerThan12Months: undefined,
};

export const spouseInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  formValue,
  errors,
  formId,
  _onFormSubmit,
  _disableAllFields,
  hideSectionHeader
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const spouseInformation = formValue as SpouseInformation;
  const spouseContact = spouseInformation.spouse || newSpouseContact;
  const validGovernmentId =
    validGovernmentIdUtils.resolveContactValidGovernmentId(
      spouseContact,
      spouseContact.contactType,
      true
    );

  return [
    {
      type: "Section",
      label: "Spouse",
      hidden: !!hideSectionHeader,
    } as SectionHeaderProps,
    {
      type: "Text",
      label: "First Name",
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "name"
      ),
      value: spouseContact.firstName,
      width: "22.5%",
      onSubmit: (firstName: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          firstName,
        };

        onValueChange({ spouse });
      },
      isRequired: true,
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      type: "Text",
      label: "Middle Name",
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "name"
      ),
      value: spouseContact.middleName,
      width: "22.5%",
      onSubmit: (middleName: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          middleName,
        };

        onValueChange({ spouse });
      },
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      type: "Text",
      label: "Last Name",
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "name"
      ),
      value: spouseContact.lastName,
      width: "22.5%",
      onSubmit: (lastName: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          lastName,
        };

        onValueChange({ spouse });
      },
      isRequired: true,
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      type: "Text",
      label: "Maiden Name",
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "name"
      ),
      value: spouseContact.maidenName,
      width: "22.5%",
      onSubmit: (maidenName: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          maidenName,
        };

        onValueChange({ spouse });
      },
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "contactType"),
      value: spouseContact.contactType,
      width: "32%",
      onSubmit: (contactType: Option) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          ...{
            contactType: +contactType.value,
            isIdAvailable: undefined,
            idType: undefined,
            idNumber: "",
          },
        };

        onValueChange({ spouse });
      },
      isRequired: true,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      errorMessages: get(errors, "contactType"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "dateOfBirth"),
      value: spouseContact.dateOfBirth,
      onSubmit: (dateOfBirth: Date) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          dateOfBirth,
        };

        onValueChange({ spouse });
      },
      width: "32%",
      isRequired: true,
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "socialSecurityNumber"),
      value: spouseContact.socialSecurityNumber,
      onSubmit: (socialSecurityNumber: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          socialSecurityNumber,
        };

        onValueChange({ spouse });
      },
      width: "32%",
      isRequired: true,
      isSensitive:
        userStore.user &&
        [
          UserRoles.FieldRepresentative,
          UserRoles.FieldRepresentativeManager,
        ].includes(userStore.user.role),
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "email"),
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "email"
      ),
      value: spouseContact.email,
      onSubmit: (email: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          email,
        };

        onValueChange({ spouse });
      },
      errorMessages: get(errors, "email"),
      formId: formId,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "phoneNumber"),
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "phoneNumber"
      ),
      value: spouseContact.phoneNumber,
      onSubmit: (phoneNumber: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          phoneNumber,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "additionalPhoneNumber"),
      value: spouseContact.additionalPhoneNumber,
      onSubmit: (additionalPhoneNumber: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          additionalPhoneNumber,
        };

        onValueChange({ spouse });
      },
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "preferredContactMethod"),
      className: getScheduleMappingClassName(
        spouseContact.scheduleMappingValues,
        "preferredContactMethod"
      ),
      value: spouseContact.preferredContactMethod,
      onSubmit: ({ value }: { value: number }) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          preferredContactMethod: value,
        };

        onValueChange({ spouse });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "address"),
      value: spouseContact.address || newAddressField,
      onSubmit: (address: AddressFieldValue) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          address,
        };

        onValueChange({ spouse });
      },
      isRequired: true,
      disabled: !spouseContact.canEditSpousePersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "isIdAvailable"),
      hidden: !validGovernmentId.showIsIdAvailable,
      value: validGovernmentId.isIdAvailable,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      width: "33%",
      onSubmit: (isIdAvailable: boolean) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          ...{ isIdAvailable, idType: undefined, idNumber: "" },
        };

        onValueChange({ spouse });
      },
      isRequired: true,
      errorMessages: get(errors, "isIdAvailable"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        "contact",
        "isCredibleWitnessAvailable"
      ),
      hidden: !validGovernmentId.showIsCredibleWitnessAvailable,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      value: validGovernmentId.isCredibleWitnessAvailable,
      width: "33%",
      onSubmit: (isCredibleWitnessAvailable: boolean) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          isCredibleWitnessAvailable,
        };

        onValueChange({ spouse });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "idNumber"),
      hidden: !validGovernmentId.showIdNumber,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      value: validGovernmentId.idNumber,
      width: "33%",
      onSubmit: (idNumber: string) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          idNumber,
        };

        onValueChange({ spouse });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        "contact",
        "isPersonallyKnownToNotary"
      ),
      hidden: spouseContact.contactType !== ContactTypes.Witness,
      isRequired: false,
      disabled: !userStore.user?.canEditIDFieldsAndPersonallyKnownOnContact,
      value: spouseContact.isPersonallyKnownToNotary,
      width: "33%",
      onSubmit: (isPersonallyKnownToNotary?: boolean) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          isPersonallyKnownToNotary,
        };

        onValueChange({ spouse });
      },
      errorMessages: get(errors, "isPersonallyKnownToNotary"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "race"),
      value: spouseContact.race,
      onSubmit: ({ value }: { value: number }) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          race: value,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "ethnicity"),
      value: spouseContact.ethnicity,
      onSubmit: ({ value }: { value: number }) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          ethnicity: value,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "countryOfBirth"),
      value: spouseContact.countryOfBirth,
      onSubmit: ({ value }: { value: number }) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          countryOfBirth: value,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
      //Orders USA to top of collection
      reorderOptions: [{ newIndex: 0, value: 187 }],
    } as unknown as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "isUsCitizen"),
      value: spouseContact.isUsCitizen,
      onSubmit: (isUsCitizen: boolean) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          isUsCitizen,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "preferredLanguages"),
      value: spouseContact.preferredLanguages,
      onSubmit: ({ value }: { value: number }) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          preferredLanguages: value,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName("contact", "gender"),
      value: spouseContact.gender,
      onSubmit: ({ value }: { value: number }) => {
        const spouse: SpouseContact = {
          ...spouseContact,
          gender: value,
        };

        onValueChange({ spouse });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "livingArrangementType"),
      value: spouseInformation.livingArrangementType,
      onSubmit: (livingArrangementType: Option) => {
        onValueChange({ livingArrangementType: livingArrangementType.value });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isSpouseAlsoApplyingForAssistance"
      ),
      value: spouseInformation.isSpouseAlsoApplyingForAssistance,
      onSubmit: (isSpouseAlsoApplyingForAssistance) => {
        onValueChange({ isSpouseAlsoApplyingForAssistance });
      },
      width: "45%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isSpouseDisabledOrBlind"),
      value: spouseInformation.isSpouseDisabledOrBlind,
      onSubmit: (isSpouseDisabledOrBlind) => {
        onValueChange({ isSpouseDisabledOrBlind });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasSpouseDisabilityBeenDecided"
      ),
      value: spouseInformation.hasSpouseDisabilityBeenDecided,
      hidden: !spouseInformation.isSpouseDisabledOrBlind,
      width: "30%",
      onSubmit: (hasSpouseDisabilityBeenDecided) => {
        onValueChange({ hasSpouseDisabilityBeenDecided });
      },
      isRequired: true,
    } as AllFields,

    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "willSpouseDisabilityLastLongerThan30Days"
      ),
      value: spouseInformation.willSpouseDisabilityLastLongerThan30Days,
      hidden: !spouseInformation.isSpouseDisabledOrBlind,
      width: "30%",
      onSubmit: (willSpouseDisabilityLastLongerThan30Days) => {
        onValueChange({ willSpouseDisabilityLastLongerThan30Days });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "willSpouseDisabilityLastLongerThan12Months"
      ),
      value: spouseInformation.willSpouseDisabilityLastLongerThan12Months,
      hidden: !spouseInformation.isSpouseDisabledOrBlind,
      width: "30%",
      onSubmit: (willSpouseDisabilityLastLongerThan12Months) => {
        onValueChange({ willSpouseDisabilityLastLongerThan12Months });
      },
      isRequired: true,
    } as AllFields,
  ];
};
