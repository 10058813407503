import { UserRoles } from "../../../types/User";
import GridClickableDataColumn from "../../Grid/Grid/types/GridClickableDataColumn";
import GridColumn from "../../Grid/Grid/types/GridColumn";

export const gridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "id",
    headerText: "Corporate ID",
    type: "string",
    visible: true,
  }),
  new GridColumn({
    field: "companyCode",
    headerText: "Corporate Code",
    type: "string",
  }),
  new GridColumn({
    field: "companyName",
    headerText: "Name",
    type: "string",
  }),
  new GridColumn({
    field: "companyAddress_Street",
    headerText: "Street Address",
    type: "string",
  }),
  new GridColumn({
    field: "companyAddress_City",
    headerText: "City",
    type: "string",
  }),
  new GridColumn({
    field: "companyAddress_State_AsString",
    headerText: "State",
    type: "string",
  }),
  new GridColumn({
    field: "companyAddress_Zip",
    headerText: "Zip",
    type: "string",
  }),
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "corporate-is-active-header",
    },
  }),
  new GridColumn({
    field: "annualRateIncrease",
    headerText: "Annual Rate Increase Percent",
    type: "number",
    format: "p2",
  }),
  new GridColumn({
    field: "annualRateIncreaseEffectiveProductTypes_AsString",
    headerText: "Product Types",
    type: "string",
  }),
  new GridColumn({
    field: "contractEffectiveDate_AsDateOnly",
    headerText: "Contract Effective Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "nextRateIncreaseDate_AsDateOnly",
    headerText: "Next Rate Increase Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "isAnnualRateIncreaseEnabled",
    headerText: "Enable/Disable Toggle",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "corporate-is-active-header",
    },
  }),
  new GridClickableDataColumn({
    field: "corporateUserCount",
    headerText: "Users",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "corporate-is-user-count",
    },
    dialogType: "corporate-users-grid",
  }),
  new GridClickableDataColumn({
    field: "facilityCount",
    headerText: "Facilities",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "corporate-is-user-count",
    },
    dialogType: "corporate-facilites-grid",
  }),
  new GridClickableDataColumn({
    field: "applicationCount",
    headerText: "Applications",
    type: "number",
    textAlign: "Center",
    customAttributes: {
      id: "corporate-application-count",
    },
    dialogType: "corporate-applications-grid",
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "companyCode",
  "companyName",
  "companyAddress_Street",
  "companyAddress_City",
  "companyAddress_State_AsString",
  "companyAddress_Zip",
  "annualRateIncrease",
  "annualRateIncreaseEffectiveProductTypes_AsString",
  "contractEffectiveDate_AsDateOnly",
  "nextRateIncreaseDate_AsDateOnly",
  "isAnnualRateIncreaseEnabled",
  "isActive",
  "facilityCount",
  "applicationCount",
  "updatedBy_AsName",
  "updatedAt_AsDateOnly",
  "createdBy_AsName",
  "createdAt_AsDateOnly",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
