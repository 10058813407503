import { Button, Intent } from "@blueprintjs/core";
import { closeFacilityBlockScheduleDialog } from "../../Dialogs/FacilityBlockScheduleDialog";
import {
  BlockScheduleForm,
  defaultBlockScheduleFormModel,
} from "./BlockScheduleForm/BlockScheduleForm";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { BlockScheduleFormModel } from "./BlockScheduleForm/BlockScheduleFormModel";
import { useEffect, useState } from "react";
import { uniqueId } from "lodash";
import schedulingApiClient from "../../../lib/apiClients/scheduling/schedulingApiClient";
import { AppToaster } from "../../Toast/Toast";
import { Loading } from "../../Loading/Loading";
import {
  SelectEventArgs,
  TabComponent,
} from "@syncfusion/ej2-react-navigations";
import { facilityApiClient } from "../../../lib/apiClients/facility/facilityApiClient";
import { ExistingFacility } from "../../../types/Facility";

interface FacilityBlockConfigurationFormProps {
  fieldRepId: string;
  facilityId?: string;
  shouldDisableForm?: boolean;
}

const FacilityBlockConfigurationForm: React.FC<
  FacilityBlockConfigurationFormProps
> = (props) => {
  const [areFacilitiesLoading, setAreFacilitiesLoading] = useState(false);
  const [fieldRepFacilities, setFieldRepFacilities] = useState<
    ExistingFacility[]
  >([]);
  const [selectedFacility, setSelectedFacility] = useState<string | undefined>(
    props.facilityId
  );

  const [isScheduleLoading, setIsScheduleLoading] = useState(false);
  const [blockScheduleFormModels, setBlockScheduleFormModels] = useState<
    BlockScheduleFormModel[]
  >([]);

  useEffect(() => {
    setAreFacilitiesLoading(true);
    facilityApiClient
      .getFaciltiesByFieldRepId(props.fieldRepId)
      .then((response) => {
        setFieldRepFacilities(response);
      })
      .catch((error) => {
        AppToaster.show({
          message: "Unexpected error occurred while loading facilities",
          intent: Intent.DANGER,
        });
        console.error(error);
      })
      .finally(() => {
        setAreFacilitiesLoading(false);
      });
  }, [props.fieldRepId]);

  useEffect(() => {
    if (props.fieldRepId && selectedFacility) {
      setIsScheduleLoading(true);
      schedulingApiClient
        .getBlockSchedules(selectedFacility, props.fieldRepId)
        .then((response) => {
          setBlockScheduleFormModels(response);
        })
        .catch((error) => {
          AppToaster.show({
            message:
              "Unexpected error occurred while loading block schedule entries",
            intent: Intent.DANGER,
          });
          console.error(error);
        })
        .finally(() => {
          setIsScheduleLoading(false);
        });
    }
  }, [props.fieldRepId, selectedFacility]);

  const getInitialFacilityTabIndex = () => {
    const index = fieldRepFacilities.findIndex(
      (facility) => facility.id === props.facilityId
    );
    return index >= 0 ? index : 0;
  };

  const handleFacilityTabSelected = (args: SelectEventArgs) => {
    const selectedFacility = fieldRepFacilities[args.selectedIndex];
    setSelectedFacility(selectedFacility.id);
  };

  const addBlock = () => {
    setBlockScheduleFormModels((prev) => {
      return [
        ...prev,
        {
          ...defaultBlockScheduleFormModel(),
          key: uniqueId("block-"),
          facilityId: selectedFacility,
          fieldRepId: props.fieldRepId,
        },
      ];
    });
  };

  const removeBlock = (index: number) => {
    setBlockScheduleFormModels((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  return (
    <div className="facility-block-form">
      <div className="facility-block-form_body">
        {areFacilitiesLoading ? (
          <Loading />
        ) : (
          <TabComponent
            headerPlacement="Right"
            heightAdjustMode="None"
            selectedItem={getInitialFacilityTabIndex()}
            selected={handleFacilityTabSelected}
          >
            <div className="e-tab-header facility-block-form_body_sidebar">
              {fieldRepFacilities.map((facility) => (
                <div key={facility.id}>{facility.facilityName}</div>
              ))}
            </div>
            <div className="e-content"></div>
          </TabComponent>
        )}

        <div className="facility-block-form_body_content">
          {isScheduleLoading ? (
            <Loading />
          ) : (
            <>
              {blockScheduleFormModels.map((model, index) => (
                <BlockScheduleForm
                  key={model.id ?? model.key}
                  blockIndex={index}
                  formModel={model}
                  shouldDisableForm={props.shouldDisableForm}
                  onDelete={() => {
                    removeBlock(index);
                  }}
                />
              ))}
              <Button
                minimal
                className="add-block-button"
                text={"Add Block"}
                intent={Intent.PRIMARY}
                rightIcon={IconNames.ADD}
                disabled={props.shouldDisableForm}
                onClick={addBlock}
              />
            </>
          )}
        </div>
      </div>
      <div className="facility-block-form_footer">
        <Button
          minimal
          text={"Close"}
          onClick={closeFacilityBlockScheduleDialog}
        />
      </div>
    </div>
  );
};

export default FacilityBlockConfigurationForm;
