import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React from "react";
import { reactPlugin } from "../AppInsights";
import { Header } from "../components/Header/Header";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { AdminTabs } from "./AdminTabs";
import "./styles.scss";

const AdminPageComponent: React.FC = () => {
  return (
    <div className="page board-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="admin" />
        <div className="page-main-content">
          <AdminTabs />
        </div>
      </div>
    </div>
  );
};

export const AdminPage = withAITracking(
  reactPlugin,
  AdminPageComponent,
  "AdminPage",
  "AI-flex-container"
);
