import { FormGroup } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import {
  MaskChangeEventArgs,
  MaskedTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { MaskedTextFieldProps } from "../types/fieldTypes";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";

export const MaskedField: React.FC<MaskedTextFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  maskFieldProps,
  formId,
  errorMessages,
  validationModel,
  fieldName,
  isRequired,
}) => {
  if (hidden) {
    return null;
  }
  const [localValue, setLocalValue] = React.useState<string>(value);
  const [formObject, setFormObject] = React.useState<FormValidator>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const maskedInputRef = useRef<MaskedTextBoxComponent | null>(null);

  const isTooltipVisible = useOverflowTooltip(
    maskedInputRef?.current?.element,
    localValue?.toString() || ""
  );

  // Initialize
  useEffect(() => {
    if (!!maskedInputRef.current && !initialized) {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    onSubmit(localValue);
    setFormObject(new FormValidator(`#${formId}`, validationModel));
  }, [localValue, formId]);

  useEffect(() => {
    setLocalValue(value);
    if (!!maskedInputRef.current && !!initialized) {
    }
  }, [value]);

  return (
    <FormGroup
      className={`${
        isRequired && !value ? "has-required-background" : "base-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <WithTooltip
        shouldShowTooltip={true}
        content={isTooltipVisible ? localValue?.toString() : ""}
      >
        <MaskedTextBoxComponent
          ref={maskedInputRef}
          name={fieldName}
          value={localValue}
          readonly={!!disabled}
          enablePersistence={false}
          mask={maskFieldProps?.mask}
          onChange={(e: MaskChangeEventArgs) => {
            e.value ? setLocalValue(e.value) : setLocalValue("");
          }}
          onBlur={() => {
            formObject?.validate(fieldName);
          }}
          placeholder={maskFieldProps?.placeholder}
        />
      </WithTooltip>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
