import { FormGroup, Icon, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2 } from "@blueprintjs/popover2";
import { Select2 } from "@blueprintjs/select";
import React, { useEffect, useRef, useState } from "react";
import { WithTooltip } from "../../Tooltip/Tooltip";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import {
  Option,
  SelectFieldProps,
  SelectFieldValue,
} from "../types/fieldTypes";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";
import { get } from "lodash";
import variables from "../../../config/variables";

const getSelectValue = (selectValue: SelectFieldValue, options: Option[]) => {
  return options.find((option) => String(option.value) === String(selectValue));
};

export const SelectField: React.FC<SelectFieldProps> = ({
  value,
  label,
  description,
  optionValues,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  placeholder,
  reorderOptions,
  permissions,
  shouldShowClearButton,
  usState,
  sortItemListAlphabetically,
}) => {
  if (hidden) {
    return null;
  }

  const [localValue, setLocalValue] =
    React.useState<SelectFieldValue>(undefined);
  const selectedOption = getSelectValue(localValue, optionValues);

  const selectInputRef = useRef<HTMLInputElement | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const isTooltipVisible = useOverflowTooltip(
    selectInputRef.current,
    localValue?.toString() || ""
  );
  // Initialize
  useEffect(() => {
    if (!!selectInputRef.current && !initialized) {
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const orderedOptionValues = () => {
    if (!!reorderOptions) {
      reorderOptions.forEach((element) => {
        const foundOptionIndex = optionValues.findIndex(
          (x) => x.value === element.value
        );

        optionValues.splice(
          element.newIndex,
          0,
          optionValues.splice(foundOptionIndex, 1)[0]
        );
      });
    }
    return optionValues;
  };

  return (
    <FormGroup
      key={localValue}
      className={`${
        isRequired && !localValue ? "has-required-background" : "select-field"
      }`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <Select2<Option>
        items={orderedOptionValues()}
        noResults={"No Results"}
        resetOnSelect={true}
        filterable={optionValues.length > 10}
        popoverProps={{
          position: "bottom-left",
          minimal: true,
          hasBackdrop: true,
          canEscapeKeyClose: true,
        }}
        itemListPredicate={(query, items) => {
          query = query.toLowerCase();

          let filteredItems = items.filter(
            (optionValue) =>
              optionValue.label.toLowerCase().includes(query) &&
              !optionValue.isExcluded &&
              (!optionValue.usState || usState === optionValue.usState)
          );
          if (sortItemListAlphabetically) {
            filteredItems = filteredItems.sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
          }
          return filteredItems;
        }}
        itemRenderer={(item, itemProps) => {
          return (
            <MenuItem2
              className="select__menu-item"
              key={itemProps.index}
              disabled={
                (!!item.needPermission &&
                  !get(permissions, item.needPermission)) ||
                item.isExcluded
              }
              text={item.label}
              active={String(localValue) === String(item.value)}
              onClick={itemProps.handleClick}
            />
          );
        }}
        onItemSelect={(item) => {
          setLocalValue(item.value);
          onSubmit(item);
        }}
        disabled={disabled}
      >
        <WithTooltip
          shouldShowTooltip={isTooltipVisible}
          content={selectedOption ? (selectedOption as Option)?.label : ""}
        >
          <InputGroup
            inputRef={selectInputRef}
            className="select-input"
            rightElement={
              selectedOption && !!shouldShowClearButton ? (
                <Icon
                  className="select-right-icon"
                  icon={IconNames.Cross}
                  onClick={() => {
                    setLocalValue(undefined);
                    onSubmit(undefined);
                  }}
                />
              ) : (
                <Icon
                  className="select-right-icon"
                  icon={IconNames.CaretDown}
                />
              )
            }
            readOnly={true}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              const isValidOption = getSelectValue(
                e.target.value,
                optionValues
              );
              if (!isValidOption) {
                e.target.value = "";
              }
            }}
            value={
              selectedOption ? (selectedOption as Option)?.label : undefined
            }
          />
        </WithTooltip>
      </Select2>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) =>
            errorMessage.startsWith("href") ? (
              <a
                key={idx}
                href={`${variables.redirectUri}/${errorMessage.substring(5)}`}
                target="_blank"
                className="error-link"
                rel="noreferrer"
              >
                Click here
              </a>
            ) : (
              <p key={idx} className="error-message">
                {errorMessage}
              </p>
            )
          )}
        </>
      )}
    </FormGroup>
  );
};
