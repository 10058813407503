import { Popover2 } from "@blueprintjs/popover2";
import { Menu, MenuItem, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { userStore } from "../../stores/UserStore";
import { logout } from "../../lib/msal/msal";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { UserRoles } from "../../types/User";

export interface AvatarProps {}
export const Avatar: React.FC<AvatarProps> = observer(() => {
  const shouldShowSignatureOption =
    userStore.user &&
    ![UserRoles.FacilityUser, UserRoles.CorporateUser].includes(
      userStore.user.role
    );

  return (
    <div>
      <Popover2
        placement="bottom-end"
        content={
          <Menu>
            {/* <MenuItem
              icon={IconNames.USER}
              text="Edit Profile"
              onClick={editProfile}
            /> */}
            <MenuItem
              icon={IconNames.LOG_OUT}
              text="Sign Out"
              onClick={logout}
            />
            {shouldShowSignatureOption && (
              <MenuItem
                icon={IconNames.EDIT}
                text="Signature"
                onClick={() => {
                  if (!!userStore.user) {
                    dialogsViewerStore.setIsUpdateSignatureDialogOpen(true);
                  }
                }}
              />
            )}
          </Menu>
        }
      >
        <div className="user-profile-tab">
          <div>{userStore.fullUserName}</div>
          <div>{userStore.displayRole}</div>
          <div>
            <Icon iconSize={15} icon={IconNames.CHEVRON_DOWN} />
          </div>
        </div>
      </Popover2>
    </div>
  );
});
