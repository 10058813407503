import { makeObservable, observable, runInAction } from "mobx";
import {
  CorporateUpdateDialogOpenOptions,
  FacilityUpdateDialogOpenOptions,
  InstitutionUpdateDialogOpenOptions,
  TemplateUpdateDialogOptions,
  UserUpdateDialogOpenOptions,
  NotaryUpdateDialogOpenOptions,
  CommentUpdateDialogOpenOptions,
  CommentCreationDialogOpenOptions,
  DiscountCreationDialogOpenOptions,
  BillingRuleUpdateDialogOpenOptions,
  OrderedDocumentsPickerDialogOptions,
  CommentsDialogOpenOptions,
  DiscountUpdateDeleteDialogOpenOptions,
  BillingCreateDialogOpenOptions,
  UpdateBillingDialogOpenOptions,
  InvoiceDocumentHistoryDialogOpenOptions,
  ApplicationSummaryDialogOpenOptions,
  ConfirmDialogOptions,
  BillingRuleCreateDialogOpenOptions,
  UpdateSignatureDialogOpenOptions,
  FIARequestCancelDialogOpenOptions,
  FIARequestResendDialogOpenOptions,
  DocumentViewerDialogOptions,
  ChecklistItemUpdateDialogOpenOptions,
  ChecklistItemCreationDialogOpenOptions,
  ImageViewerDialogOpenOptions,
  DocumentEditorDialogOptions,
  ApplicationPageUpdateFacilityDialogOpenOptions,
  FacilityUsersGridDialogOpenOptions,
  UserCreationDialogOpenOptions,
  CorporateFacilitesGridDialogOpenOptions,
  CoverLetterPickerDialogOptions,
  FacilityCreateDialogOpenOptions,
  CorporateFacilityUsersGridDialogOpenOptions,
  CorporateApplicationsGridDialogOpenOptions,
  FacilityApplicationsGridDialogOpenOptions,
  UserApplicationsGridDialogOpenOptions,
  SetInvoiceNumberDialogOpenOptions,
  ChecklistSplitDialogOpenOptions,
  DocumentRenameDialogOptions,
  ApplicationChildCreationDialogOptions,
  HelpCenterTopicDialogOpenOptions,
  FinancialEligibilityConversationDialogOptions,
  LeadEditDialogOpenOptions,
  CancelAllFIARequestDialogOpenOptions,
  ChecklistSplitAddOwnedEntityDialogOpenOptions,
  MatchupDocumentUploadDialogOpenOptions,
  ReportDialogOpenOptions,
  VerifyDialogOpenOptions,
  DocumentFilingDialogOpenOptions,
} from "../types/types";

class DialogsViewerStore {
  // User
  public isUserCreationDialogOpen = false;
  public userCreationDialogOpenOptions?: UserCreationDialogOpenOptions;
  public isUserUpdateDialogOpen = false;
  public userUpdateDialogOpenOptions?: UserUpdateDialogOpenOptions;

  // User Signature
  public isUpdateSignatureDialogOpen = false;
  public updateSignatureDialogOpenOptions?: UpdateSignatureDialogOpenOptions;

  // Corporate
  public isCorporateCreationDialogOpen = false;
  public isCorporateUpdateDialogOpen = false;
  public corporateUpdateDialogOpenOptions?: CorporateUpdateDialogOpenOptions;

  // Facility
  public isFacilityCreationDialogOpen = false;
  public facilityCreateDialogOpenOptions?: FacilityCreateDialogOpenOptions;
  public isFacilityUpdateDialogOpen = false;
  public facilityUpdateDialogOpenOptions?: FacilityUpdateDialogOpenOptions;

  // Institution
  public isInstitutionCreationDialogOpen = false;
  public isInstitutionUpdateDialogOpen = false;
  public institutionUpdateDialogOpenOptions?: InstitutionUpdateDialogOpenOptions;

  // Notary
  public isNotaryCreationDialogOpen = false;
  public isNotaryUpdateDialogOpen = false;
  public notaryUpdateDialogOpenOptions?: NotaryUpdateDialogOpenOptions;

  // Template
  public isTemplateCreationDialogOpen = false;
  public isTemplateUpdateDialogOpen = false;
  public templateUpdateDialogOptions?: TemplateUpdateDialogOptions;

  // OrderedDocumentsPicker
  public isOrderedDocumentsPickerDialogOpen = false;
  public orderedDocumentsPickerDialogOptions?: OrderedDocumentsPickerDialogOptions;

  // CoverLetterPicker
  public isCoverLetterPickerDialogOpen = false;
  public coverLetterPickerDialogOptions?: CoverLetterPickerDialogOptions;

  // DocumentEditor
  public isDocumentEditorDialogOpen = false;
  public documentEditorDialogOptions?: DocumentEditorDialogOptions;

  // DocumentRename
  public isDocumentRenameDialogOpen = false;
  public documentRenameDialogOptions?: DocumentRenameDialogOptions;

  // DocumentViewer
  public isDocumentViewerDialogOpen = false;
  public documentViewerDialogOptions?: DocumentViewerDialogOptions;

  // ImageViewer
  public isImageViewerDialogOpen = false;
  public imageViewerDialogOptions?: ImageViewerDialogOpenOptions;

  // Confirm
  public isConfirmDialogOpen = false;
  public confirmDialogOptions?: ConfirmDialogOptions;

  // Withdraw Application
  public isWithdrawApplicationDialogOpen = false;

  // Application Child
  public isApplicationChildCreationDialogOpen = false;
  public applicationChildCreationDialogOptions?: ApplicationChildCreationDialogOptions;
  public isApplicationChildDialogOpen = false;

  //Monitor
  public isMonitorCreationDialogOpen = false;
  public isMonitorUpdateDialogOpen = false;
  public isEndMonitorDialogOpen = false;

  // Checklist
  public isChecklistItemCreationDialogOpen = false;
  public isChecklistItemUpdateDialogOpen = false;
  public isChecklistCompleteSplitDialogOpen = false;
  public checklistItemUpdateDialogOpenOptions?: ChecklistItemUpdateDialogOpenOptions;
  public checklistItemCreationDialogOpenOptions?: ChecklistItemCreationDialogOpenOptions;

  // Checklist split dialog
  public isChecklistSplitDialogOpen = false;
  public checklistSplitDialogOpenOptions?: ChecklistSplitDialogOpenOptions;

  // Create Owned Entity from Checklist Split
  public isChecklistSplitAddOwnedEntityDialogOpen = false;
  public checklistSplitAddOwnedEntityDialogOpenOptions?: ChecklistSplitAddOwnedEntityDialogOpenOptions;

  // Verify Dialog
  public isVerifyDialogOpen = false;
  public verifyDialogOpenOptions?: VerifyDialogOpenOptions;

  // Document Filing Dialog
  public isDocumentFilingDialogOpen = false;
  public documentFilingDialogOpenOptions?: DocumentFilingDialogOpenOptions;

  // HelpCenter Topic Dialog
  public isHelpCenterTopicDialogOpen = false;
  public helpCenterTopicDialogOpenOptions?: HelpCenterTopicDialogOpenOptions;
  // Report Dialog
  public isReportDialogOpen = false;
  public reportDialogOpenOptions?: ReportDialogOpenOptions;
  //Comment
  public isCommentCreationDialogOpen = false;
  public commentCreationDialogOpenOptions?: CommentCreationDialogOpenOptions;
  public isCommentUpdateDialogOpen = false;
  public commentUpdateDialogOpenOptions?: CommentUpdateDialogOpenOptions;

  //Comments - dialog opens from application grid and checklist comment grid
  public isCommentsDialogOpen = false;
  public commentsDialogOpenOptions?: CommentsDialogOpenOptions;

  // Discount
  public isDiscountCreationDialogOpen = false;
  public isBillingRuleUpdateDialogOpen = false;
  public isBillingRuleCreateDialogOpen = false;
  public isDiscountUpdateDeleteDialogOpen = false;
  public discountCreationDialogOpenOptions?: DiscountCreationDialogOpenOptions;
  public billingRuleUpdateDialogOpenOptions?: BillingRuleUpdateDialogOpenOptions;
  public billingRuleCreateDialogOpenOptions?: BillingRuleCreateDialogOpenOptions;
  public discountUpdateDeleteDialogOpenOptions?: DiscountUpdateDeleteDialogOpenOptions;

  // Billing
  public isBillingCreateDialogOpen = false;
  public isUpdateBillingDialogOpen = false;
  public billingCreateDialogOpenOptions?: BillingCreateDialogOpenOptions;
  public updateBillingDialogOpenOptions?: UpdateBillingDialogOpenOptions;

  // Invoice
  public isInvoiceGenerationDialogOpen = false;
  public isInvoiceDocumentHistoryDialogOpen = false;
  public invoiceDocumentHistoryDialogOpenOptions?: InvoiceDocumentHistoryDialogOpenOptions;

  //Comments
  public isApplicationSummaryDialogOpen = false;
  public applicationSummaryDialogOpenOptions?: ApplicationSummaryDialogOpenOptions;

  //StateSubmission
  public isApplyToStateDialogOpen = false;

  //FIA
  public isFIARequestCancelDialogOpen = false;
  public fiaRequestCancelDialogOpenOptions?: FIARequestCancelDialogOpenOptions;

  public isCancelAllFIARequestsDialogOpen = false;
  public cancelAllFIARequestsDialogOpenOptions?: CancelAllFIARequestDialogOpenOptions;

  public isFIARequestResendDialogOpen = false;
  public isFIARequestResendDialogOpenOptions?: FIARequestResendDialogOpenOptions;

  // Application Page - Update Facility
  public isApplicationPageUpdateFacilityDialogOpen = false;
  public applicationPageUpdateFacilityDialogOpenOptions?: ApplicationPageUpdateFacilityDialogOpenOptions;

  // Facility Users Grid Dialog
  public isFacilityUsersGridDialogOpen = false;
  public facilityUsersGridDialogOpenOptions?: FacilityUsersGridDialogOpenOptions;

  // Corporate Facilites Grid Dialog
  public isCorporateFacilitesGridDialogOpen = false;
  public corporateFacilitesGridDialogOpenOptions?: CorporateFacilitesGridDialogOpenOptions;

  // Corporate Facility Users Grid Dialog
  public isCorporateFacilityUsersGridDialogOpen = false;
  public corporateFacilityUsersGridDialogOpenOptions?: CorporateFacilityUsersGridDialogOpenOptions;

  // Corporate Applications Grid Dialog
  public isCorporateApplicationsGridDialogOpen = false;
  public corporateApplicationsGridDialogOpenOptions?: CorporateApplicationsGridDialogOpenOptions;

  // Corporate Applications Grid Dialog
  public isFacilityApplicationsGridDialogOpen = false;
  public facilityApplicationsGridDialogOpenOptions?: FacilityApplicationsGridDialogOpenOptions;

  // User Applications Grid Dialog
  public isUserApplicationsGridDialogOpen = false;
  public userApplicationsGridDialogOpenOptions?: UserApplicationsGridDialogOpenOptions;

  //Set Invoice Number Dialog
  public isSetInvoiceNumberDialogOpen = false;
  public setInvoiceNumberDialogOpenOptions?: SetInvoiceNumberDialogOpenOptions;

  // Set Financial Eligibility Conversation Dialog
  public isFinancialEligibilityConversationDialogOpen = false;
  public financialEligibilityConversationDialogOptions?: FinancialEligibilityConversationDialogOptions;

  // Set Lead Edit Dialog
  public isLeadEditDialogOpen = false;
  public leadEditDialogOpenOptions?: LeadEditDialogOpenOptions;

  // Matchup (Lead) Upload Document Dialog
  public isMatchupDocumentUploadDialogOpen = false;
  public matchupDocumentUploadDialogOpenOptions?: MatchupDocumentUploadDialogOpenOptions;

  constructor() {
    makeObservable(this, {
      // User
      isUserCreationDialogOpen: observable,
      isUserUpdateDialogOpen: observable,
      userUpdateDialogOpenOptions: observable,

      // User Signature
      isUpdateSignatureDialogOpen: observable,
      updateSignatureDialogOpenOptions: observable,

      // Corporate
      isCorporateCreationDialogOpen: observable,
      isCorporateUpdateDialogOpen: observable,
      corporateUpdateDialogOpenOptions: observable,

      // Facility
      isFacilityCreationDialogOpen: observable,
      facilityCreateDialogOpenOptions: observable,
      isFacilityUpdateDialogOpen: observable,
      facilityUpdateDialogOpenOptions: observable,

      // Institution
      isInstitutionCreationDialogOpen: observable,
      isInstitutionUpdateDialogOpen: observable,
      institutionUpdateDialogOpenOptions: observable,

      // Notary
      isNotaryCreationDialogOpen: observable,
      isNotaryUpdateDialogOpen: observable,
      notaryUpdateDialogOpenOptions: observable,

      // Template
      isTemplateCreationDialogOpen: observable,
      isTemplateUpdateDialogOpen: observable,
      templateUpdateDialogOptions: observable,

      // Ordered DocumentsPicker
      isOrderedDocumentsPickerDialogOpen: observable,
      orderedDocumentsPickerDialogOptions: observable,

      // CoverLetter Picker
      isCoverLetterPickerDialogOpen: observable,
      coverLetterPickerDialogOptions: observable,

      // Document Editor
      isDocumentEditorDialogOpen: observable,
      documentEditorDialogOptions: observable,

      // Document Editor
      isDocumentRenameDialogOpen: observable,
      documentRenameDialogOptions: observable,

      // Document Viewer
      isDocumentViewerDialogOpen: observable,
      documentViewerDialogOptions: observable,

      // Image Viewer
      isImageViewerDialogOpen: observable,
      imageViewerDialogOptions: observable,

      // Confirm
      isConfirmDialogOpen: observable,
      confirmDialogOptions: observable,

      // Withdraw Application
      isWithdrawApplicationDialogOpen: observable,

      //Application Child Dialog
      isApplicationChildCreationDialogOpen: observable,
      applicationChildCreationDialogOptions: observable,

      isMonitorCreationDialogOpen: observable,
      isMonitorUpdateDialogOpen: observable,
      isEndMonitorDialogOpen: observable,

      // Checklist
      isChecklistItemCreationDialogOpen: observable,
      isChecklistItemUpdateDialogOpen: observable,
      checklistItemUpdateDialogOpenOptions: observable,
      checklistItemCreationDialogOpenOptions: observable,

      isChecklistSplitDialogOpen: observable,
      checklistSplitDialogOpenOptions: observable,
      isChecklistSplitAddOwnedEntityDialogOpen: observable,
      checklistSplitAddOwnedEntityDialogOpenOptions: observable,

      // Verify Dialog
      isVerifyDialogOpen: observable,
      verifyDialogOpenOptions: observable,

      // Document Filing Dialog
      isDocumentFilingDialogOpen: observable,
      documentFilingDialogOpenOptions: observable,

      // HelpCenter Topic Dialog
      isHelpCenterTopicDialogOpen: observable,
      helpCenterTopicDialogOpenOptions: observable,

      // Report Dialog
      isReportDialogOpen: observable,
      reportDialogOpenOptions: observable,

      // Comment
      isCommentCreationDialogOpen: observable,
      commentCreationDialogOpenOptions: observable,
      isCommentUpdateDialogOpen: observable,
      commentUpdateDialogOpenOptions: observable,
      isCommentsDialogOpen: observable,
      commentsDialogOpenOptions: observable,

      // billing
      isDiscountCreationDialogOpen: observable,
      isBillingRuleCreateDialogOpen: observable,
      billingRuleCreateDialogOpenOptions: observable,
      isBillingRuleUpdateDialogOpen: observable,
      isDiscountUpdateDeleteDialogOpen: observable,
      isBillingCreateDialogOpen: observable,
      isUpdateBillingDialogOpen: observable,
      discountCreationDialogOpenOptions: observable,
      billingRuleUpdateDialogOpenOptions: observable,
      isInvoiceGenerationDialogOpen: observable,
      discountUpdateDeleteDialogOpenOptions: observable,
      billingCreateDialogOpenOptions: observable,
      updateBillingDialogOpenOptions: observable,
      isInvoiceDocumentHistoryDialogOpen: observable,
      invoiceDocumentHistoryDialogOpenOptions: observable,
      setInvoiceNumberDialogOpenOptions: observable,
      isSetInvoiceNumberDialogOpen: observable,

      // Summary
      isApplicationSummaryDialogOpen: observable,
      applicationSummaryDialogOpenOptions: observable,

      //StateSubmission
      isApplyToStateDialogOpen: observable,

      //FIA Request
      isFIARequestCancelDialogOpen: observable,
      fiaRequestCancelDialogOpenOptions: observable,

      isCancelAllFIARequestsDialogOpen: observable,
      cancelAllFIARequestsDialogOpenOptions: observable,

      isFIARequestResendDialogOpen: observable,
      isFIARequestResendDialogOpenOptions: observable,

      //Application Page - Update Facility
      isApplicationPageUpdateFacilityDialogOpen: observable,
      applicationPageUpdateFacilityDialogOpenOptions: observable,

      // Facility User Grid Dialog
      isFacilityUsersGridDialogOpen: observable,
      facilityUsersGridDialogOpenOptions: observable,

      // Corporate Facilites Grid Dialog
      isCorporateFacilitesGridDialogOpen: observable,
      corporateFacilitesGridDialogOpenOptions: observable,

      // Corporate Facility Users Grid Dialog
      isCorporateFacilityUsersGridDialogOpen: observable,
      corporateFacilityUsersGridDialogOpenOptions: observable,

      // Corporate Applications Grid Dialog
      isCorporateApplicationsGridDialogOpen: observable,
      corporateApplicationsGridDialogOpenOptions: observable,

      // Facility Applications Grid Dialog
      isFacilityApplicationsGridDialogOpen: observable,
      facilityApplicationsGridDialogOpenOptions: observable,

      // User Applications Grid Dialog
      isUserApplicationsGridDialogOpen: observable,
      userApplicationsGridDialogOpenOptions: observable,

      // Financial Eligibility Conversation Dialog
      isFinancialEligibilityConversationDialogOpen: observable,
      financialEligibilityConversationDialogOptions: observable,

      // Lead Dialog
      isLeadEditDialogOpen: observable,

      // Matchup (Lead) Upload Document
      isMatchupDocumentUploadDialogOpen: observable,
      matchupDocumentUploadDialogOpenOptions: observable,
    });
  }

  setIsUserCreationDialogOpen = (
    isOpen: boolean,
    userCreationDialogOpenOptions?: UserCreationDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isUserCreationDialogOpen = isOpen;
      this.userCreationDialogOpenOptions = userCreationDialogOpenOptions;
    });
  };

  setIsNotaryCreationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isNotaryCreationDialogOpen = isOpen;
    });
  };

  setIsApplicationChildCreationDialogOpen = (
    isOpen: boolean,
    options?: ApplicationChildCreationDialogOptions
  ) => {
    runInAction(() => {
      this.applicationChildCreationDialogOptions = options;
      this.isApplicationChildCreationDialogOpen = isOpen;
    });
  };

  setIsUserUpdateDialogOpen = (
    isOpen: boolean,
    userUpdateDialogOpenOptions?: UserUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isUserUpdateDialogOpen = isOpen;
      this.userUpdateDialogOpenOptions = userUpdateDialogOpenOptions;
    });
  };

  setIsUpdateSignatureDialogOpen = (
    isOpen: boolean,
    updateSignatureDialogOpenOptions?: UpdateSignatureDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isUpdateSignatureDialogOpen = isOpen;
      this.updateSignatureDialogOpenOptions = updateSignatureDialogOpenOptions;
    });
  };

  setIsNotaryUpdateDialogOpen = (
    isOpen: boolean,
    notaryUpdateDialogOpenOptions?: NotaryUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isNotaryUpdateDialogOpen = isOpen;
      this.notaryUpdateDialogOpenOptions = notaryUpdateDialogOpenOptions;
    });
  };

  setIsCorporateCreationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isCorporateCreationDialogOpen = isOpen;
    });
  };

  setIsCorporateUpdateDialogOpen = (
    isOpen: boolean,
    corporateUpdateDialogOpenOptions?: CorporateUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCorporateUpdateDialogOpen = isOpen;
      this.corporateUpdateDialogOpenOptions = corporateUpdateDialogOpenOptions;
    });
  };

  setIsFacilityCreationDialogOpen = (
    isOpen: boolean,
    facilityCreateDialogOpenOptions?: FacilityCreateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isFacilityCreationDialogOpen = isOpen;
      this.facilityCreateDialogOpenOptions = facilityCreateDialogOpenOptions;
    });
  };

  setIsFacilityUpdateDialogOpen = (
    isOpen: boolean,
    facilityUpdateDialogOpenOptions?: FacilityUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isFacilityUpdateDialogOpen = isOpen;
      this.facilityUpdateDialogOpenOptions = facilityUpdateDialogOpenOptions;
    });
  };

  setIsInstitutionCreationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isInstitutionCreationDialogOpen = isOpen;
    });
  };

  setIsInstitutionUpdateDialogOpen = (
    isOpen: boolean,
    institutionUpdateDialogOpenOptions?: InstitutionUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isInstitutionUpdateDialogOpen = isOpen;
      this.institutionUpdateDialogOpenOptions =
        institutionUpdateDialogOpenOptions;
    });
  };

  // Template
  setIsTemplateCreationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isTemplateCreationDialogOpen = isOpen;
    });
  };

  setIsOrderedDocumentsPickerDialogOpen = (
    isOpen: boolean,
    orderedDocumentsPickerDialogOptions?: OrderedDocumentsPickerDialogOptions
  ) => {
    runInAction(() => {
      this.isOrderedDocumentsPickerDialogOpen = isOpen;
      this.orderedDocumentsPickerDialogOptions =
        orderedDocumentsPickerDialogOptions;
    });
  };

  setIsCoverLetterPickerDialogOpen = (
    isOpen: boolean,
    coverLetterPickerDialogOptions?: CoverLetterPickerDialogOptions
  ) => {
    runInAction(() => {
      this.isCoverLetterPickerDialogOpen = isOpen;
      this.coverLetterPickerDialogOptions = coverLetterPickerDialogOptions;
    });
  };

  setIsDocumentEditorDialogOpen = (
    isOpen: boolean,
    documentEditorDialogOptions?: DocumentEditorDialogOptions
  ) => {
    runInAction(() => {
      this.isDocumentEditorDialogOpen = isOpen;
      this.documentEditorDialogOptions = documentEditorDialogOptions;
    });
  };

  setIsDocumentRenameDialogOpen = (
    isOpen: boolean,
    documentRenameDialogOptions?: DocumentRenameDialogOptions
  ) => {
    runInAction(() => {
      this.isDocumentRenameDialogOpen = isOpen;
      this.documentRenameDialogOptions = documentRenameDialogOptions;
    });
  };

  setIsDocumentViewerDialogOpen = (
    isOpen: boolean,
    documentViewerDialogOptions?: DocumentViewerDialogOptions
  ) => {
    runInAction(() => {
      this.isDocumentViewerDialogOpen = isOpen;
      this.documentViewerDialogOptions = documentViewerDialogOptions;
    });
  };

  setIsConfirmDialogOpen = (
    isOpen: boolean,
    confirmDialogOptions?: ConfirmDialogOptions
  ) => {
    runInAction(() => {
      this.isConfirmDialogOpen = isOpen;
      this.confirmDialogOptions = confirmDialogOptions;
    });
  };

  setIsTemplateUpdateDialogOpen = (
    isOpen: boolean,
    templateUpdateDialogOptions?: TemplateUpdateDialogOptions
  ) => {
    runInAction(() => {
      this.isTemplateUpdateDialogOpen = isOpen;
      this.templateUpdateDialogOptions = templateUpdateDialogOptions;
    });
  };

  openTemplateUpdateDialog = (
    templateUpdateDialogOptions: TemplateUpdateDialogOptions
  ) => {
    this.setIsTemplateUpdateDialogOpen(true, templateUpdateDialogOptions);
  };

  openOrderedDocumentsPickerDialog = (
    orderedDocumentsPickerDialogOptions: OrderedDocumentsPickerDialogOptions
  ) => {
    this.setIsOrderedDocumentsPickerDialogOpen(
      true,
      orderedDocumentsPickerDialogOptions
    );
  };

  openCoverLetterPickerDialog = (
    coverLetterPickerDialogOptions: CoverLetterPickerDialogOptions
  ) => {
    this.setIsCoverLetterPickerDialogOpen(true, coverLetterPickerDialogOptions);
  };

  openDocumentEditorDialog = (
    documentEditorDialogOptions: DocumentEditorDialogOptions
  ) => {
    this.setIsDocumentEditorDialogOpen(true, documentEditorDialogOptions);
  };

  openDocumentRenameDialog = (
    documentRenameDialogOptions: DocumentRenameDialogOptions
  ) => {
    this.setIsDocumentRenameDialogOpen(true, documentRenameDialogOptions);
  };

  openDocumentViewerDialog = (
    documentViewerDialogOptions: DocumentViewerDialogOptions
  ) => {
    this.setIsDocumentViewerDialogOpen(true, documentViewerDialogOptions);
  };

  confirm = (confirmDialogOptions: ConfirmDialogOptions) => {
    this.setIsConfirmDialogOpen(true, confirmDialogOptions);
  };

  closeTemplateUpdateDialog = () => {
    runInAction(() => {
      this.setIsTemplateUpdateDialogOpen(false);
    });
  };

  setIsWithdrawApplicationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isWithdrawApplicationDialogOpen = isOpen;
    });
  };

  setIsMonitorCreationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isMonitorCreationDialogOpen = isOpen;
    });
  };

  setIsMonitorUpdateDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isMonitorUpdateDialogOpen = isOpen;
    });
  };

  setIsChecklistItemCreationDialogOpen = (
    isOpen: boolean,
    checklistItemCreationOptions?: ChecklistItemCreationDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isChecklistItemCreationDialogOpen = isOpen;
      this.checklistItemCreationDialogOpenOptions =
        checklistItemCreationOptions;
    });
  };

  setIsChecklistItemUpdateDialogOpen = (
    isOpen: boolean,
    checklistItemUpdateDialogOpenOptions?: ChecklistItemUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isChecklistItemUpdateDialogOpen = isOpen;
      this.checklistItemUpdateDialogOpenOptions =
        checklistItemUpdateDialogOpenOptions;
    });
  };

  setIsCommentCreationDialogOpen = (
    isOpen: boolean,
    commentCreationDialogOpenOptions?: CommentCreationDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCommentCreationDialogOpen = isOpen;
      this.commentCreationDialogOpenOptions = commentCreationDialogOpenOptions;
    });
  };

  setIsCommentUpdateDialogOpen = (
    isOpen: boolean,
    commentUpdateDialogOpenOptions?: CommentUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCommentUpdateDialogOpen = isOpen;
      this.commentUpdateDialogOpenOptions = commentUpdateDialogOpenOptions;
    });
  };

  setIsDiscountCreationDialogOpen = (
    isOpen: boolean,
    discountCreationDialogOpenOptions?: DiscountCreationDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isDiscountCreationDialogOpen = isOpen;
      this.discountCreationDialogOpenOptions =
        discountCreationDialogOpenOptions;
    });
  };

  setIsDiscountUpdateDeleteDialogOpen = (
    isOpen: boolean,
    discountUpdateDeleteDialogOpenOptions?: DiscountUpdateDeleteDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isDiscountUpdateDeleteDialogOpen = isOpen;
      this.discountUpdateDeleteDialogOpenOptions =
        discountUpdateDeleteDialogOpenOptions;
    });
  };

  setIsBillingRuleCreateDialogOpen = (
    isOpen: boolean,
    options?: BillingRuleCreateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isBillingRuleCreateDialogOpen = isOpen;
      this.billingRuleCreateDialogOpenOptions = options;
    });
  };

  setIsBillingRuleUpdateDialogOpen = (
    isOpen: boolean,
    billingRuleUpdateDialogOpenOptions?: BillingRuleUpdateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isBillingRuleUpdateDialogOpen = isOpen;
      this.billingRuleUpdateDialogOpenOptions =
        billingRuleUpdateDialogOpenOptions;
    });
  };

  setIsBillingCreateDialogOpen = (
    isOpen: boolean,
    billingCreateDialogOpenOptions?: BillingCreateDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isBillingCreateDialogOpen = isOpen;
      this.billingCreateDialogOpenOptions = billingCreateDialogOpenOptions;
    });
  };

  setIsUpdateBillingDialogOpen = (
    isOpen: boolean,
    updateBillingDialogOpenOptions?: UpdateBillingDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isUpdateBillingDialogOpen = isOpen;
      this.updateBillingDialogOpenOptions = updateBillingDialogOpenOptions;
    });
  };

  setIsCommentsDialogOpen = (
    isOpen: boolean,
    options?: CommentsDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCommentsDialogOpen = isOpen;
      this.commentsDialogOpenOptions = options;
    });
  };

  setIsChecklistSplitDialogOpen = (
    isOpen: boolean,
    options?: ChecklistSplitDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isChecklistSplitDialogOpen = isOpen;
      this.checklistSplitDialogOpenOptions = options;
    });
  };

  setIsVerifyDialogOpen = (
    isOpen: boolean,
    options?: VerifyDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isVerifyDialogOpen = isOpen;
      this.verifyDialogOpenOptions = options;
    });
  };

  setIsDocumentFilingDialogOpen = (
    isOpen: boolean,
    options?: DocumentFilingDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isDocumentFilingDialogOpen = isOpen;
      this.documentFilingDialogOpenOptions = options;
    });
  };

  setIsHelpCenterTopicDialogOpen = (
    isOpen: boolean,
    options?: HelpCenterTopicDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isHelpCenterTopicDialogOpen = isOpen;
      this.helpCenterTopicDialogOpenOptions = options;
    });
  };

  setIsReportDialogOpen = (
    isOpen: boolean,
    options?: ReportDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isReportDialogOpen = isOpen;
      this.reportDialogOpenOptions = options;
    });
  };

  setIsInvoiceGenerationDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isInvoiceGenerationDialogOpen = isOpen;
    });
  };

  setIsInvoiceDocumentHistoryDialogOpen = (
    isOpen: boolean,
    options?: InvoiceDocumentHistoryDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isInvoiceDocumentHistoryDialogOpen = isOpen;
      this.invoiceDocumentHistoryDialogOpenOptions = options;
    });
  };

  setIsApplicationSummaryDialogOpen = (
    isOpen: boolean,
    options?: ApplicationSummaryDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isApplicationSummaryDialogOpen = isOpen;
      this.applicationSummaryDialogOpenOptions = options;
    });
  };

  setIsApplyToStateDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isApplyToStateDialogOpen = isOpen;
    });
  };

  setIsFIARequestCancelDialogOpen = (
    isOpen: boolean,
    options?: FIARequestCancelDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isFIARequestCancelDialogOpen = isOpen;
      this.fiaRequestCancelDialogOpenOptions = options;
    });
  };

  setIsCancelAllFIARequestsDialogOpen = (
    isOpen: boolean,
    options?: CancelAllFIARequestDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCancelAllFIARequestsDialogOpen = isOpen;
      this.cancelAllFIARequestsDialogOpenOptions = options;
    });
  };

  setIsFIARequestResendDialogOpen = (
    isOpen: boolean,
    options?: FIARequestResendDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isFIARequestResendDialogOpen = isOpen;
      this.isFIARequestResendDialogOpenOptions = options;
    });
  };

  setIsImageViewerDialogOpen = (
    isOpen: boolean,
    options?: ImageViewerDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isImageViewerDialogOpen = isOpen;
      this.imageViewerDialogOptions = options;
    });
  };

  setIsEndMonitorDialogOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isEndMonitorDialogOpen = isOpen;
    });
  };

  setIsApplicationPageUpdateFacilityDialogOpen = (
    isOpen: boolean,
    options?: ApplicationPageUpdateFacilityDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isApplicationPageUpdateFacilityDialogOpen = isOpen;
      this.applicationPageUpdateFacilityDialogOpenOptions = options;
    });
  };

  setIsFacilityUsersGridDialogOpen = (
    isOpen: boolean,
    options?: FacilityUsersGridDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isFacilityUsersGridDialogOpen = isOpen;
      this.facilityUsersGridDialogOpenOptions = options;
    });
  };

  setIsCorporateFacilitesGridDialogOpen = (
    isOpen: boolean,
    options?: CorporateFacilitesGridDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCorporateFacilitesGridDialogOpen = isOpen;
      this.corporateFacilitesGridDialogOpenOptions = options;
    });
  };

  setIsCorporateFacilityUsersGridDialogOpen = (
    isOpen: boolean,
    options?: CorporateFacilityUsersGridDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCorporateFacilityUsersGridDialogOpen = isOpen;
      this.corporateFacilityUsersGridDialogOpenOptions = options;
    });
  };

  setIsCorporateApplicationsGridDialogOpen = (
    isOpen: boolean,
    options?: CorporateApplicationsGridDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isCorporateApplicationsGridDialogOpen = isOpen;
      this.corporateApplicationsGridDialogOpenOptions = options;
    });
  };

  setIsFacilityApplicationsGridDialogOpen = (
    isOpen: boolean,
    options?: FacilityApplicationsGridDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isFacilityApplicationsGridDialogOpen = isOpen;
      this.facilityApplicationsGridDialogOpenOptions = options;
    });
  };

  setIsUserApplicationsGridDialogOpen = (
    isOpen: boolean,
    options?: UserApplicationsGridDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isUserApplicationsGridDialogOpen = isOpen;
      this.userApplicationsGridDialogOpenOptions = options;
    });
  };

  setIsSetInvoiceNumberDialogOpen = (
    isOpen: boolean,
    options?: SetInvoiceNumberDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isSetInvoiceNumberDialogOpen = isOpen;
      this.setInvoiceNumberDialogOpenOptions = options;
    });
  };

  setFinancialEligibilityConversationDialogOpen = (
    isOpen: boolean,
    options?: FinancialEligibilityConversationDialogOptions
  ) => {
    runInAction(() => {
      this.isFinancialEligibilityConversationDialogOpen = isOpen;
      this.financialEligibilityConversationDialogOptions = options;
    });
  };

  setIsLeadEditDialogOpen = (
    isOpen: boolean,
    options?: LeadEditDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isLeadEditDialogOpen = isOpen;
      this.leadEditDialogOpenOptions = options;
    });
  };

  setIsMatchupUploadDocumentDialogOpen = (
    isOpen: boolean,
    options?: MatchupDocumentUploadDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isMatchupDocumentUploadDialogOpen = isOpen;
      this.matchupDocumentUploadDialogOpenOptions = options;
    });
  };

  setIsChecklistSplitAddOwnedEntityDialogOpen = (
    isOpen: boolean,
    options?: ChecklistSplitAddOwnedEntityDialogOpenOptions
  ) => {
    runInAction(() => {
      this.isChecklistSplitAddOwnedEntityDialogOpen = isOpen;
      this.checklistSplitAddOwnedEntityDialogOpenOptions = options;
    });
  };
}

export const dialogsViewerStore = new DialogsViewerStore();
