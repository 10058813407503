import {
  Button,
  Classes,
  Dialog,
  H6,
  Icon,
  Intent,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import { mergeWith } from "lodash";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { ExistingFacility } from "../../types/Facility";
import {
  facilityFormSchema,
  newFormFacility,
} from "../Forms/FacilityForm/FacilityForm";
import { facilityApiClient } from "../../lib/apiClients/facility/facilityApiClient";
import { formatPhoneNumber } from "../Fields/PhoneField/utils";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { userStore } from "../../stores/UserStore";

export interface FacilityUpdateDialogProps extends BaseDialogProps {}

export const FacilityUpdateDialog: React.FC<FacilityUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState<TabId>("default");
  const [facility, setFacility] = useState<ExistingFacility | null>(null);

  useEffect(() => {
    if (!!dialogsViewerStore.facilityUpdateDialogOpenOptions) {
      facilityApiClient
        .getFacilityDetail(
          dialogsViewerStore.facilityUpdateDialogOpenOptions.facilityId
        )
        .then(setFacility);
    }

    return () => {
      setFacility(null);
      setSelectedTabId("default");
    };
  }, [dialogsViewerStore.facilityUpdateDialogOpenOptions]);

  return (
    <Dialog
      portalClassName={"mdr-dialog"}
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={
        dialogsViewerStore.facilityUpdateDialogOpenOptions?.isReadonly
          ? "Facility"
          : "Update Facility"
      }
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            facility && (
              <Tabs
                selectedTabId={selectedTabId}
                onChange={(newTabId) => {
                  setSelectedTabId(newTabId);
                }}
                large
              >
                <Tab
                  id="default"
                  title="Facility Details"
                  panel={
                    <Form
                      formId="update-facility-form"
                      disableAllFields={
                        dialogsViewerStore.facilityUpdateDialogOpenOptions
                          ?.isReadonly
                      }
                      value={mergeWith(
                        {},
                        newFormFacility,
                        facility,
                        (dest, source) => (source === null ? dest : source)
                      )}
                      formSchemaBuilder={facilityFormSchema}
                      onFormSubmit={async (facility) => {
                        setIsSaving(true);

                        await facilityApiClient
                          .updateFacility(facility.id, facility)
                          .then(() => {
                            gridStore.refresh();
                            dialogsViewerStore.facilityUpdateDialogOpenOptions
                              ?.onSubmit &&
                              dialogsViewerStore.facilityUpdateDialogOpenOptions.onSubmit();
                            submitDialog();
                          })
                          .finally(() => {
                            setIsSaving(false);
                          });
                      }}
                    />
                  }
                />
                <Tab
                  id="contacts"
                  title="Contacts"
                  disabled={!userStore.user?.canViewFacilityContact}
                  panel={
                    <section>
                      {facility.facilityUsers.map((user) => (
                        <div
                          key={user.id}
                          className="facility-contacts__detail"
                        >
                          <H6>
                            {user.firstName} {user.lastName}{" "}
                            {user.isBillingPOC ? "- Billing POC" : ""}{" "}
                            {user.isFacilityPOC ? "- Facility POC" : ""}
                          </H6>
                          {user.title && (
                            <p className="facility-contacts__detail__item">
                              <Icon icon={IconNames.PERSON} />
                              Title: {user.title}
                            </p>
                          )}
                          {user.email && (
                            <p className="facility-contacts__detail__item">
                              <Icon icon={IconNames.ENVELOPE} />
                              Email: {user.email}
                            </p>
                          )}
                          {user.phoneNumber && (
                            <p className="facility-contacts__detail__item">
                              <Icon icon={IconNames.PHONE} />
                              Phone Number:{" "}
                              {formatPhoneNumber(user.phoneNumber)}
                              {user.phoneExtension
                                ? ` +${user.phoneExtension}`
                                : ""}
                            </p>
                          )}
                          {user.faxNumber && (
                            <p className="facility-contacts__detail__item">
                              <Icon icon={IconNames.PRINT} />
                              Fax: {formatPhoneNumber(user.faxNumber)}
                            </p>
                          )}
                        </div>
                      ))}
                    </section>
                  }
                />
              </Tabs>
            )
          }
        </Observer>
      </div>
      {!dialogsViewerStore.facilityUpdateDialogOpenOptions?.isReadonly && (
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={closeDialog}
          />
          <FormSubmitButton
            buttonId="facility-save-button"
            isSaving={isSaving}
            formId="update-facility-form"
            text="Save"
          />
        </div>
      )}
    </Dialog>
  );
};
