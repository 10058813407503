import { UserRoles } from "../../../types/User";
import GridColumn from "../../Grid/Grid/types/GridColumn";

export const gridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "name",
    headerText: "Name",
    type: "string",
  }),
  new GridColumn({
    field: "type_AsString",
    headerText: "Type",
    type: "string",
  }),
  new GridColumn({
    field: "isSpecialized",
    headerText: "Specialized",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "institution-is-specialized-header",
    },
  }),
  new GridColumn({
    field: "phoneNumber",
    headerText: "Phone Number",
    type: "string",
  }),
  new GridColumn({
    field: "faxNumber",
    headerText: "Fax Number",
    type: "string",
  }),
  new GridColumn({
    field: "mailingAddress_Street",
    headerText: "Street Address",
    type: "string",
  }),
  new GridColumn({
    field: "mailingAddress_City",
    headerText: "City",
    type: "string",
  }),
  new GridColumn({
    field: "mailingAddress_State_AsString",
    headerText: "State",
    type: "string",
  }),
  new GridColumn({
    field: "mailingAddress_Zip",
    headerText: "Zip",
    type: "string",
  }),
  new GridColumn({
    field: "sendingContactMethod_AsString",
    headerText: "Default Send Method",
    type: "string",
  }),
  new GridColumn({
    field: "notes",
    headerText: "FIA Notes",
    type: "string",
    width: 300,
  }),
  new GridColumn({
    field: "isActive",
    headerText: "Active",
    type: "boolean",
    displayAsCheckBox: true,
    textAlign: "Center",
    customAttributes: {
      id: "institution-is-active-header",
    },
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedAt_AsDateOnly",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "name",
  "type_AsString",
  "isSpecialized",
  "phoneNumber",
  "faxNumber",
  "mailingAddress_Street",
  "mailingAddress_City",
  "mailingAddress_State_AsString",
  "mailingAddress_Zip",
  "sendingContactMethod_AsString",
  "notes",
  "isActive",
  "updatedBy_AsName",
  "updatedAt_AsDateOnly",
  "createdBy_AsName",
  "createdAt_AsDateOnly",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
