import { Predicate } from "@syncfusion/ej2-data";
import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { FacilityUser } from "../../../types/FacilityUser";
import { NewUser, User, UserRoles } from "../../../types/User";
import {
  AddressFieldValue,
  AllFields,
  NameFieldValue,
  Option,
} from "../../Fields/types/fieldTypes";
import { getCorporateAsyncAutocompleteFieldProps } from "../types";
import { buildFacilityPOCFields } from "./FacilityPOCFieldsBuilder";
import { validateCorporateUserAndAssignCoporateValues } from "../../Dialogs/User/utils";

const modelName = "user";

export const newFormUser: NewUser = {
  firstName: "",
  lastName: "",
  corporateId: "",
  facilityIds: [],
  facilities: [],
  address: {
    street: "",
    street2: "",
    city: "",
    zip: "",
    state: "",
    county: "",
  },
  phoneNumber: "",
  phoneExtension: "",
  additionalPhoneNumber: "",
  faxNumber: "",
  email: "",
  role: 0,
  regions: [],
  userPermissions: [],
  isActive: true,
  fieldRepFacilityIds: [],
};

export const userFormSchema = {
  role: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "role"),
      disabled: false,
      onSubmit: (option: Option) => {
        setUserForm({
          ...user,
          role: Number(option.value),
          role_AsString: option.label,
        });
      },
      errorMessages: get(errors, "role"),
      value: user.role,
      isRequired: true,
    } as AllFields;
  },
  userName: (user: User, errors?: { [key: string]: string[] }) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "username"),
      disabled: true,
      hidden: !user.id || !user.isExternal,
      value: user.username,
      errorMessages: get(errors, "username"),
    } as AllFields;
  },
  applicantName: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "Name",
      label: "Name",
      disabled: false,
      onSubmit: (name: NameFieldValue) => {
        setUserForm({
          ...user,
          firstName: name.firstName,
          lastName: name.lastName,
        });
      },
      value: { firstName: user.firstName, lastName: user.lastName },
      isRequired: true,
      errorMessages: [
        ...(get(errors, "firstName") || []),
        ...(get(errors, "lastName") || []),
      ],
    } as AllFields;
  },
  corporate: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "AsyncAutocomplete",
      label: "Corporate",
      ...getCorporateAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      errorMessages: get(errors, "corporateId"),
      hidden: !user.isExternal,
      isRequired: true,
      value: user.corporateId,
      onSubmit: (
        corporateOption: Option & {
          companyAddress: AddressFieldValue;
          companyPhone: string;
        }
      ) => {
        const coporateValues = validateCorporateUserAndAssignCoporateValues(
          user,
          corporateOption?.companyAddress,
          corporateOption?.companyPhone
        );

        const newFields: Partial<User> = {
          corporateId: corporateOption?.value,
          address: coporateValues.userAddress,
          phoneNumber: coporateValues.userPhoneNumber,
        };

        // reset facilities dropdown if corporate is de-selected
        if (
          !newFields.corporateId ||
          user.corporateId !== newFields.corporateId
        ) {
          newFields.facilityIds = [];
          newFields.facilities = [];
          if (user.role === UserRoles.FacilityUser) {
            user.phoneNumber = "";
            user.faxNumber = "";
          }
        }
        setUserForm({ ...user, ...newFields });
      },
    } as AllFields;
  },
  facilities: (
    user: User,
    setUserForm: (test: any) => void,
    _errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "AsyncMultiSelectCheckbox",
      label: "Facilities",
      optionValuesODataUrl: "facilityodata/options",
      groupByActiveStatus: true,
      onSubmit: async (
        facilityOptions: (Option & {
          corporateId: string;
          facilityAddress: AddressFieldValue;
          facilityPhone: string;
        })[]
      ) => {
        const facilityIds = facilityOptions
          .filter((opt) => opt.value !== "") // Remove elements with empty value
          .map((opt) => opt.value);

        setUserForm({ ...user, facilityIds });
      },
      value: user.facilityIds,
      conditions: user.corporateId
        ? [new Predicate("corporateId", "equal", user.corporateId)]
        : undefined,
      hidden: !user.isExternal || !user.corporateId,
      isRequired: user.role === UserRoles.FacilityUser,
      isMultiSelect: true,
      fieldKey: user.corporateId,
    } as AllFields;
  },
  facilityPOCFields: (
    user: User,
    setUserForm: (test: any) => void,
    _errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "Custom",
      form: user,
      onSubmit: (facilities: FacilityUser[]) => {
        setUserForm({ ...user, facilities: facilities });
      },
      renderer: buildFacilityPOCFields,
    } as AllFields;
  },
  title: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "title"),
      disabled: false,
      hidden: !user.isExternal,
      errorMessages: get(errors, "title"),
      onSubmit: (title: string) => {
        setUserForm({ ...user, title: title });
      },
      value: user.title,
      isRequired:
        user.role === UserRoles.FacilityUser ||
        user.role === UserRoles.CorporateUser,
    } as AllFields;
  },
  email: (
    user: User,
    formId: string,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "email"),
      disabled: false,
      onSubmit: (email: string) => {
        setUserForm({ ...user, email: email });
      },
      errorMessages: get(errors, "email"),
      formId: formId,
      value: user.email,
      isRequired: true,
    } as AllFields;
  },
  phoneNumber: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneNumber"),
      onSubmit: (phoneNumber: string) => {
        setUserForm({ ...user, phoneNumber: phoneNumber });
      },
      value: user.phoneNumber,
      errorMessages: get(errors, "phoneNumber"),
      isRequired: true,
    } as AllFields;
  },
  phoneExtension: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneExtension"),
      onSubmit: (phoneExtension: string) => {
        setUserForm({ ...user, phoneExtension: phoneExtension });
      },
      value: user.phoneExtension,
      errorMessages: get(errors, "phoneExtension"),
    } as AllFields;
  },
  faxNumber: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "faxNumber"),
      onSubmit: (faxNumber: string) => {
        setUserForm({ ...user, faxNumber: faxNumber });
      },
      value: user.faxNumber,
      errorMessages: get(errors, "faxNumber"),
    } as AllFields;
  },
  additionalPhoneNumber: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "additionalPhoneNumber"),
      onSubmit: (additionalPhoneNumber: string) => {
        setUserForm({ ...user, additionalPhoneNumber: additionalPhoneNumber });
      },
      value: user.additionalPhoneNumber,
      errorMessages: get(errors, "additionalPhoneNumber"),
    } as AllFields;
  },
  address: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "address"),
      disabled: false,
      onSubmit: (address: AddressFieldValue) => {
        setUserForm({ ...user, address: address });
      },
      value: user.address,
      isRequired: true,
      errorMessages: [
        ...(get(errors, "address.Street") || []),
        ...(get(errors, "address.City") || []),
        ...(get(errors, "address.State") || []),
        ...(get(errors, "address.Zip") || []),
        ...(get(errors, "address.County") || []),
      ],
    } as AllFields;
  },
  fieldRepFacilities: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "AsyncMultiSelectCheckbox",
      label: "Facilities",
      optionValuesODataUrl: "facilityodata/options",
      groupByActiveStatus: true,
      errorMessages: get(errors, "fieldRepFacilityIds"),
      onSubmit: async (facilityOptions: Option[]) => {
        const fieldRepFacilityIds = facilityOptions
          .filter((opt) => opt.value !== "") // Remove elements with empty value
          .map((opt) => opt.value);

        setUserForm({ ...user, fieldRepFacilityIds });
      },
      value: user.fieldRepFacilityIds,
      hidden: user.role !== UserRoles.FieldRepresentative,
      isRequired: false,
      isMultiSelect: true,
      conditions:
        user.regions.length > 0
          ? user.regions.map((region) => {
              return new Predicate("area", "equal", region);
            })
          : undefined,
      chainOrPredicates: true,
      fieldKey: user.regions.join(","),
    } as AllFields;
  },
  regions: (
    user: User,
    setUserForm: (test: any) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "regions"),
      disabled: false,
      includeSelectAll: true,
      sortItemListAlphabetically: true,
      hidden: user.isExternal,
      onSubmit: (regions: number[]) => {
        setUserForm({ ...user, regions: regions });
      },
      errorMessages: get(errors, "regions"),
      value: user.regions,
      isRequired: true,
    } as AllFields;
  },
  isActive: (
    user: User,
    setUserForm: (test: any) => void,
    _errors?: { [key: string]: string[] }
  ) => {
    return {
      type: "Checkbox-Bool",
      label: "Is Active",
      disabled: false,
      onSubmit: (isActive: boolean) => {
        setUserForm({ ...user, isActive: isActive });
      },
      value: user.isActive,
    } as AllFields;
  },
};
