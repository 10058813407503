import React from "react";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  PdfDocumentEntity,
  PdfDocumentEntityType,
  PdfDocumentUploader,
} from "../Document/PdfDocumentUploader";
import { OrderedDocumentCreateDTO } from "./configurations/types";
import { TemplatePicker } from "../TemplatePicker/TemplatePicker";
import { ChecklistDocumentPicker } from "../TemplatePicker/ChecklistDocumentPicker";
import { applicationStore } from "../../stores/ApplicationStore";

export interface OrderedDocumentsPickerProps {
  pdfDocumentEntity: PdfDocumentEntity;
  onDocumentPicked: (
    orderedDocumentCreateDTO?: OrderedDocumentCreateDTO
  ) => void;
  templateOdataExcludeIds?: string[];
}

export const OrderedDocumentsPicker: React.FC<OrderedDocumentsPickerProps> = (
  props
) => {
  const templateId = "uploadType-template";
  const uploadId = "uploadType-upload";
  const documentId = "uploadType-document";

  const [activeTab, setActiveTab] = React.useState<string>(templateId);

  enableRipple(true);

  return (
    <section className="ordered-documents-picker">
      <div className="ordered-document-picker-dialog-nav">
        <div className="e-btn-group e-round-corner">
          <input
            type="radio"
            id={templateId}
            name="uploadType"
            value="template"
            checked={activeTab === templateId}
            onClick={() => {
              setActiveTab(templateId);
              props.onDocumentPicked(undefined);
            }}
          />
          <label className="e-btn" htmlFor={templateId}>
            Select Template
          </label>
          <input
            type="radio"
            id={uploadId}
            name="uploadType"
            value="upload"
            checked={activeTab === uploadId}
            onClick={() => {
              setActiveTab(uploadId);
              props.onDocumentPicked(undefined);
            }}
          />
          <label className="e-btn" htmlFor={uploadId}>
            Upload File
          </label>
          {props.pdfDocumentEntity.entityType ===
            PdfDocumentEntityType.FIARequestDocuments && (
            <>
              <input
                type="radio"
                id={documentId}
                name="uploadType"
                value="upload"
                checked={activeTab === documentId}
                onClick={() => {
                  setActiveTab(documentId);
                  props.onDocumentPicked(undefined);
                }}
              />
              <label className="e-btn" htmlFor={documentId}>
                Select From Application
              </label>
            </>
          )}
        </div>
      </div>
      <div className="ordered-document-picker-dialog-content">
        {activeTab === templateId && (
          <div className="ordered-document-picker-dialog-content-template">
            <TemplatePicker
              onTemplatePicked={(template) => {
                let orderedDocumentCreateDTO = undefined;
                if (template) {
                  orderedDocumentCreateDTO = {
                    discriminatorId: template.id,
                    isTemplate: true,
                  };
                }
                props.onDocumentPicked(orderedDocumentCreateDTO);
              }}
              odataExcludeIds={props.templateOdataExcludeIds}
            />
          </div>
        )}
        {activeTab === uploadId && (
          <div className="ordered-document-picker-dialog-content-upload">
            <PdfDocumentUploader
              allowMultipleFiles
              pdfDocumentEntity={props.pdfDocumentEntity}
              onCompleteUploading={(doc) => {
                const orderedDocumentCreateDTO = {
                  discriminatorId: doc.id,
                  isTemplate: false,
                };
                props.onDocumentPicked(orderedDocumentCreateDTO);
              }}
              onCompleteRemoving={() => props.onDocumentPicked(undefined)}
            />
          </div>
        )}
        {activeTab === documentId &&
          props.pdfDocumentEntity.entityType ===
            PdfDocumentEntityType.FIARequestDocuments && (
            <div className="ordered-document-picker-dialog-content-upload">
              <ChecklistDocumentPicker
                onDocumentPicked={(checklistDocument) => {
                  let orderedDocumentCreateDTO = undefined;
                  if (checklistDocument) {
                    orderedDocumentCreateDTO = {
                      discriminatorId: checklistDocument.value,
                      isTemplate: false,
                      isExisitingDocument: true,
                    };
                  }
                  props.onDocumentPicked(orderedDocumentCreateDTO);
                }}
                odataExcludeIds={props.templateOdataExcludeIds}
                applicationId={applicationStore.application?.id || ""}
              />
            </div>
          )}
      </div>
    </section>
  );
};
