import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  FIARequest,
  FIARequestCancelDTO,
  FIARequestInfo,
  FIARequestResendDTO,
  FIARequestValidationsDTO,
  //StatementDateTypes,
} from "../../FIARequests/configurations/types";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import {
  getChecklistItemsAsyncAutocompleteFieldProps,
  getInstitutionAsyncAutocompleteFieldProps,
} from "../types";
import { getStatementOptions } from "../../../utils/getStatementOptions";
import { fiaRequestStore } from "../../FIARequests/stores/FIARequestStore";
import { applicationStore } from "../../../stores/ApplicationStore";
import { userStore } from "../../../stores/UserStore";
import { Institution } from "../../../types/Institution";

const modelName = "fiaRequest";

export const containsSpouseInfo = (fiaRequest: FIARequest) => {
  return (
    fiaRequest.spouseFullName &&
    fiaRequest.spouseDateOfBirth &&
    fiaRequest.spouseSSN
  );
};

export const UpdateFIARequest = async (
  fiaRequest: FIARequest,
  validateFIARequestRequirements?: (
    applicationId: string,
    fiaRequestId: string,
    fiaRequest: FIARequest
  ) => Promise<void>
) => {
  if (!!applicationStore.application?.id && !!fiaRequest.id) {
    await fiaRequestStore
      .update(applicationStore.application?.id, fiaRequest.id, fiaRequest)
      .then(
        async () =>
          validateFIARequestRequirements &&
          (await validateFIARequestRequirements(
            String(applicationStore.application?.id),
            String(fiaRequest.id),
            fiaRequest
          ))
      );
  }
};

export const fiaRequestFormSchema = {
  requestNumber_AsString: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Request Number",
      value: (fiaRequest as FIARequestInfo).requestNumber_AsString,
      minimal: true,
    } as AllFields;
  },
  submittedDate: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Submitted Date",
      value: (fiaRequest as FIARequestInfo).submittedDate,
      minimal: true,
    } as AllFields;
  },
  approvedDate: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Approved Date",
      value: (fiaRequest as FIARequestInfo).approvedDate,
      minimal: true,
    } as AllFields;
  },
  createdDate: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Created Date",
      value: (fiaRequest as FIARequestInfo).createdDate,
      minimal: true,
    } as AllFields;
  },
  receivedDate: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Received Date",
      value: (fiaRequest as FIARequestInfo).receivedDate,
      minimal: true,
    } as AllFields;
  },
  canceledDate: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Canceled Date",
      value: (fiaRequest as FIARequestInfo).canceledDate,
      minimal: true,
    } as AllFields;
  },
  followUpReason: (
    fiaRequest: FIARequestResendDTO,
    setFIAResendForm: (fiaRequest: FIARequestResendDTO) => void,
    errors?: { [key: string]: string[] },
    readOnly?: boolean
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "followUpReason"),
      readOnly: readOnly,
      onSubmit: (followUpReason: Option) => {
        setFIAResendForm({
          ...fiaRequest,
          followUpReason: Number(followUpReason.value),
        });
      },
      errorMessages: get(errors, "followUpReason"),
      value: fiaRequest.followUpReason,
      isRequired: true,
    } as AllFields;
  },
  followUpReason_AsString: (fiaRequest: FIARequest) => {
    const followUpReasonToDisplay = (fiaRequest as FIARequestInfo)
      .followUpSecondaryReason_AsString
      ? (fiaRequest as FIARequestInfo).followUpReason_AsString +
        " - " +
        (fiaRequest as FIARequestInfo).followUpSecondaryReason_AsString
      : (fiaRequest as FIARequestInfo).followUpReason_AsString;

    return {
      type: "ReadOnly",
      label: "Follow Up Reason",
      description: "Follow up reason, if FIA Request was resent",
      value: followUpReasonToDisplay,
      minimal: true,
    } as AllFields;
  },
  followUpSecondaryReason: (
    fiaRequest: FIARequestResendDTO,
    setFIAResendForm: (fiaRequest: FIARequestResendDTO) => void,
    errors?: { [key: string]: string[] },
    readOnly?: boolean
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "followUpSecondaryReason"),
      readOnly: readOnly,
      onSubmit: (followUpSecondaryReason: Option) => {
        setFIAResendForm({
          ...fiaRequest,
          followUpSecondaryReason: Number(followUpSecondaryReason.value),
        });
      },
      errorMessages: get(errors, "followUpSecondaryReason"),
      value: fiaRequest.followUpSecondaryReason,
      isRequired: true,
    } as AllFields;
  },
  residentFullName: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Name",
      value: fiaRequest.residentFullName,
      minimal: true,
    } as AllFields;
  },
  residentDateOfBirth: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Date of Birth",
      value: fiaRequest.residentDateOfBirth,
      minimal: true,
    } as AllFields;
  },
  residentSSN: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "SSN",
      value: fiaRequest.residentSSN,
      minimal: true,
    } as AllFields;
  },
  spouseFullName: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Name",
      value: fiaRequest.spouseFullName,
      hidden: !containsSpouseInfo(fiaRequest),
      minimal: true,
    } as AllFields;
  },
  spouseDateOfBirth: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Date of Birth",
      value: fiaRequest.spouseDateOfBirth,
      hidden: !containsSpouseInfo(fiaRequest),
      minimal: true,
    } as AllFields;
  },
  spouseSSN: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "SSN",
      value: fiaRequest.spouseSSN,
      hidden: !containsSpouseInfo(fiaRequest),
      minimal: true,
    } as AllFields;
  },
  isResidentIncluded: (
    fiaRequest: FIARequest,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "isResidentIncluded"),
      errorMessages: get(errors, "isResidentIncluded"),
      value: !!fiaRequest.id ? fiaRequest.isResidentIncluded : true,
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      labelOnTop: true,
      onSubmit: (isResidentIncluded: boolean) => {
        fiaRequest.isResidentIncluded = isResidentIncluded;
      },
      isRequired: true,
    } as AllFields;
  },
  isSpouseIncluded: (
    fiaRequest: FIARequest,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "isSpouseIncluded"),
      errorMessages: get(errors, "isSpouseIncluded"),
      value: fiaRequest.isSpouseIncluded,
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      labelOnTop: true,
      hidden: !containsSpouseInfo(fiaRequest),
      onSubmit: (isSpouseIncluded: boolean) => {
        fiaRequest.isSpouseIncluded = isSpouseIncluded;
      },
      isRequired: true,
    } as AllFields;
  },
  checklistItemId: (
    fiaRequest: FIARequest,
    applicationId: string,
    setFIARequest: (fiaRequest: FIARequest) => void,
    errors?: { [key: string]: string[] }
  ) => {
    const config = {
      ...schemaStore.getFieldSchemaByName(modelName, "checklistItemId"),
      ...getChecklistItemsAsyncAutocompleteFieldProps(applicationId),
      errorMessages: get(errors, "checklistItemId"),
      isRequired: false,
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      value: fiaRequest.checklistItemId,
      onSubmit: (selected: Option) => {
        setFIARequest({
          ...fiaRequest,
          checklistItemId: selected?.value,
        });

        UpdateFIARequest({
          ...fiaRequest,
          checklistItemId: selected?.value,
        });
      },
    } as AllFields;

    return config;
  },
  institutionId: (
    fiaRequest: FIARequest,
    setFIARequest: (fiaRequest: FIARequest) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "institutionId"),
      ...getInstitutionAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "institutionId"),
      groupByActiveStatus: true,
      isRequired: true,
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      value: fiaRequest.institutionId,
      onSubmit: (options: {
        id_AsString: string;
        sendingContactMethod_AsString: string;
        displayNotes: string;
      }) => {
        const updatedFIARequest = {
          ...fiaRequest,
          institutionId: options?.id_AsString.toLowerCase(),
          institutionContactMethod_AsString:
            options?.sendingContactMethod_AsString,
          institutionDisplayNotes: options?.displayNotes,
        };
        setFIARequest(updatedFIARequest);
        UpdateFIARequest(updatedFIARequest);
      },
    } as AllFields;
  },
  institutionDefaultSendMethod: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Default Send Method",
      value: fiaRequest.institutionContactMethod_AsString,
      minimal: true,
    } as AllFields;
  },
  institutionDisplayNotes: (fiaRequest: FIARequest) => {
    return {
      type: "ReadOnly",
      label: "Display Notes",
      value: fiaRequest.institutionDisplayNotes,
      minimal: true,
    } as AllFields;
  },
  institutionAccountNumbers_AsList: (
    fiaRequest: FIARequest,
    setFIARequest: (fiaRequest: FIARequest) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "institutionAccountNumbers_AsList"
      ),
      value: fiaRequest.institutionAccountNumbers_AsList,
      errorMessages: get(errors, "institutionAccountNumbers_AsList"),
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      onSubmit: (institutionAccountNumbers_AsList: string[]) => {
        setFIARequest({
          ...fiaRequest,
          institutionAccountNumbers_AsList: institutionAccountNumbers_AsList,
        });
        UpdateFIARequest({
          ...fiaRequest,
          institutionAccountNumbers_AsList: institutionAccountNumbers_AsList,
        });
      },
    } as AllFields;
  },
  statementDateRangeStart: (
    fiaRequest: FIARequest,
    setFIARequest: (fiaRequest: FIARequest) => void,
    institution?: Institution,
    validateFIARequestRequirements?: (
      applicationId: string,
      fiaRequestId: string,
      fiaRequest: FIARequest
    ) => Promise<void>,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDateRangeStart"),
      value: fiaRequest.statementDateRangeStart,
      isRequired:
        institution?.requiresStatementsNeeded &&
        !!fiaRequest.id &&
        (!fiaRequest.statementDates_AsList ||
          fiaRequest.statementDates_AsList?.filter((date) => date !== "")
            .length === 0),
      errorMessages: get(errors, "statementDateRangeStart"),
      shouldShowClearButton: true,
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      onSubmit: (statementDateRangeStart: Option) => {
        setFIARequest({
          ...fiaRequest,
          statementDateRangeStart: statementDateRangeStart?.value,
        });

        UpdateFIARequest(
          {
            ...fiaRequest,
            statementDateRangeStart: statementDateRangeStart?.value,
          },
          validateFIARequestRequirements
        );
      },
      optionValues: getStatementOptions(),
    } as AllFields;
  },
  statementDates: (
    fiaRequest: FIARequest,
    setFIARequest: (fiaRequest: FIARequest) => void,
    institution?: Institution,
    validateFIARequestRequirements?: (
      applicationId: string,
      fiaRequestId: string,
      fiaRequest: FIARequest
    ) => Promise<void>,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDates_AsList"),
      value: fiaRequest.statementDates_AsList,
      isRequired:
        institution?.requiresStatementsNeeded &&
        !!fiaRequest.id &&
        !fiaRequest.statementDateRangeStart,
      errorMessages: get(errors, "statementDates_AsList"),
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      onSubmit: (statementDates_AsList: string[]) => {
        setFIARequest({
          ...fiaRequest,
          statementDates_AsList: statementDates_AsList,
        });

        UpdateFIARequest(
          {
            ...fiaRequest,
            statementDates_AsList: statementDates_AsList,
          },
          validateFIARequestRequirements
        );
      },
      optionValues: getStatementOptions(),
    } as AllFields;
  },
  statementCycleEndDay: (
    fiaRequest: FIARequest,
    setFIARequest: (fiaRequest: FIARequest) => void,
    institution?: Institution,
    validateFIARequestRequirements?: (
      applicationId: string,
      fiaRequestId: string,
      fiaRequest: FIARequest
    ) => Promise<void>,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "statementCycleEndDay"),
      value: fiaRequest.statementCycleEndDay,
      isRequired:
        institution?.requiresStatementsNeeded &&
        !!fiaRequest.id &&
        !fiaRequest.statementCycleEndDay,
      errorMessages: get(errors, "statementCycleEndDay"),
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      minValue: 1,
      maxValue: 31,
      onSubmit: (statementCycleEndDay: number) => {
        setFIARequest({
          ...fiaRequest,
          statementCycleEndDay: statementCycleEndDay,
        });

        UpdateFIARequest(
          {
            ...fiaRequest,
            statementCycleEndDay: statementCycleEndDay,
          },
          validateFIARequestRequirements
        );
      },
      optionValues: getStatementOptions(),
    } as AllFields;
  },
  annotation: (
    fiaRequest: FIARequest,
    setFIARequest: (fiaRequest: FIARequest) => void,
    errors?: { [key: string]: string[] }
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "annotation"),
      errorMessages: get(errors, "annotation"),
      disabled:
        !(fiaRequest as FIARequestValidationsDTO).canBeUpdated ||
        !userStore.user?.canEditFIARequest,
      onSubmit: (annotation: string) => {
        setFIARequest({
          ...fiaRequest,
          annotation: annotation,
        });
      },
      value: fiaRequest.annotation,
    } as AllFields;
  },
  cancelReason: (
    fiaRequest: FIARequestCancelDTO,
    setFIACancelForm: (fiaRequest: FIARequestCancelDTO) => void,
    errors?: { [key: string]: string[] },
    readOnly?: boolean
  ) => {
    return {
      ...schemaStore.getFieldSchemaByName(modelName, "cancelReasons_AsList"),
      readOnly: readOnly,
      onSubmit: (cancelReasons: number[]) => {
        setFIACancelForm({
          ...fiaRequest,
          cancelReasons_AsList: cancelReasons,
        });
      },
      errorMessages: get(errors, "cancelReasons_AsList"),
      value: fiaRequest.cancelReasons_AsList,
      isRequired: true,
    } as AllFields;
  },
};

export const blankNewFIARequest = {
  residentFullName: "",
  residentDateOfBirth: "",
  residentSSN: "",
  spouseFullName: "",
  spouseDateOfBirth: "",
  spouseSSN: "",

  isResidentIncluded: undefined,
  isSpouseIncluded: undefined,
  institutionId: undefined,
  institutionAccountNumbers_AsList: undefined,
  statementDates_AsList: undefined,
  annotation: undefined,
  statementDateType: undefined,
  statementCycleEndDay: undefined,
};
export const blankFIARequestDetails = {
  isResidentIncluded: undefined,
  isSpouseIncluded: undefined,
  checklistItemId: undefined,
  institutionId: undefined,
  institutionAccountNumbers_AsList: undefined,
  statementDateRangeStart: undefined,
  statementDates_AsList: undefined,
  annotation: undefined,
  statementDateType: undefined,
  statementCycleEndDay: undefined,
};
