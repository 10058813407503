import { Intent } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../../stores/ApplicationStore";
import { Application } from "../../../../../types/Application";
import { MedicalInformation } from "../../../../../types/Application/MedicalInformation";
import { SaveButton } from "../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../Buttons/UndoButton";
import RelativityCard from "../../../../Card/RelativityCard";
import { RelativityCardHeader } from "../../../../Card/RelativityCardHeader";
import Form from "../../../../Forms";
import { medicalInformationFormSchema } from "../../../../Forms/ApplicationForm/MedicalInformation";
import { AppToaster } from "../../../../Toast/Toast";

export interface MedicalInformationPageCardProps {
  application?: Application;
  canEdit: boolean;
  submitForm: (application: MedicalInformation) => Promise<void>;
}

export const MedicalInformationPageCard: FC<
  MedicalInformationPageCardProps
> = ({ application, submitForm, canEdit }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<Application>(
    application as Application
  );

  useEffect(() => {
    if (!!application) {
      setActiveFormValue(application);
    }
  }, [application]);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  return (
    <RelativityCard
      className="application-page medical-information-card"
      headerContent={
        <RelativityCardHeader label="Medical Information">
          <div>
            {canEdit && (
              <>
                <>
                  <SaveButton
                    formId="medical-information-form"
                    isSaving={isSaving}
                  />
                  <UndoButton
                    isSaving={isSaving}
                    onClick={async () => {
                      setShouldResetForm(true);
                      await handleReset();
                      setShouldResetForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </RelativityCardHeader>
      }
    >
      <Form<MedicalInformation>
        formId="medical-information-form"
        shouldBlockNavigation={true}
        shouldResetForm={shouldResetForm}
        value={activeFormValue}
        formSchemaBuilder={medicalInformationFormSchema}
        disableAllFields={isSaving || !canEdit}
        hideSectionHeader={true}
        onFormSubmit={async (application) => {
          setIsSaving(true);
          await submitForm(application)
            .then(() => {
              AppToaster.show({
                message: (
                  <div>
                    <h3>Success</h3>Application Saved
                  </div>
                ),
                intent: Intent.SUCCESS,
              });
            })
            .finally(() => {
              setIsSaving(false);
            });
        }}
      />
    </RelativityCard>
  );
};
