import { Card, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import React from "react";
import { FC, useState } from "react";
import { userStore } from "../../../stores/UserStore";
import { Application } from "../../../types/Application";
import { PrimaryInformation } from "../../../types/Application/PrimaryInformation";
import { UserRoles } from "../../../types/User";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import Form from "../../Forms";
import {
  newPrimaryInformation,
  primaryInformationFormSchema,
} from "../../Forms/ApplicationForm/PrimaryInformation";
import { AppToaster } from "../../Toast/Toast";
import { differenceInYears } from "../../../utils/date";
import classNames from "classnames";
import { getApplicationStatusClassName } from "../../../utils/miscellaneous";

export interface PrimaryInformationCardProps {
  application?: Application;
  shouldBlockNavigation?: boolean;
  canEdit?: boolean;
  submitForm: (application: PrimaryInformation) => Promise<void>;
}

export const PrimaryInformationCard: FC<PrimaryInformationCardProps> = ({
  application,
  shouldBlockNavigation,
  canEdit,
  submitForm,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localApplication, setLocalApplication] = useState<
    Application | undefined
  >(
    application ||
      ({
        ...newPrimaryInformation,
        fieldRepId:
          userStore.user?.role === UserRoles.FieldRepresentative
            ? userStore.user.id
            : "",
      } as Application)
  );

  React.useEffect(() => {
    setLocalApplication(application);
  }, [application]);

  return (
    <>
      <Card
        className={classNames(
          "primary-information-card",
          getApplicationStatusClassName(localApplication?.status_AsString)
        )}
      >
        <Observer>
          {() => (
            <Form<PrimaryInformation>
              formId="primary-information-form"
              shouldBlockNavigation={shouldBlockNavigation}
              disableAllFields={!canEdit}
              value={localApplication}
              formSchemaBuilder={primaryInformationFormSchema}
              onFormSubmit={async (primaryApplication) => {
                setIsSaving(true);
                await submitForm(primaryApplication)
                  .then(() => {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Application Saved
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
        {localApplication?.dateOfBirth && (
          <p className="resident-age">
            Age: {differenceInYears(localApplication?.dateOfBirth)}
          </p>
        )}
      </Card>
      <div className="application-card-footer">
        <FormSubmitButton
          disabled={!canEdit}
          buttonId="primary-information-save-button"
          isSaving={isSaving}
          formId="primary-information-form"
          text="Save"
        />
      </div>
    </>
  );
};
