import { SignatureComponent } from "@syncfusion/ej2-react-inputs";
import { SignaturePadWindow } from "./signaturePad";
import { action, makeObservable, observable } from "mobx";
import variables from "../../../config/variables";
declare const window: SignaturePadWindow;

export class SignaturePadStore {
  public isLoaded?: boolean;
  public isSigWebInstalled?: boolean;
  public isSigaturePadSupported?: boolean;
  public timer: NodeJS.Timeout | number | null = null;
  public currentSignatureKey?: string;
  public currentSignatureComponent?: SignatureComponent;

  private onDisconnectSignaturePad?: () => void;
  private eventsTimer: NodeJS.Timeout | number | null = null;

  setIsSigWebInstalled = (value: boolean) => {
    this.isSigWebInstalled = value;
    this.isSigaturePadSupported = value;
  };

  setCurrentSignatureComponent = (value?: SignatureComponent) => {
    this.currentSignatureComponent = value;
  };
  setCurrentSignatureKey = (value?: string) => {
    this.currentSignatureKey = value;
  };

  setTimer = (value: NodeJS.Timeout | number | null) => {
    this.timer = value;
  };

  setIsLoaded = (value: boolean) => {
    this.isLoaded = value;
  };

  load = async () => {
    if (!variables.featureToggleConfig.signaturePad) {
      return;
    }

    if (this.isLoaded) {
      return;
    }

    // Validate that SigWeb is installed and the correct version
    if (window.IsSigWebInstalled()) {
      let sigWebVer = "";
      try {
        sigWebVer = window.GetSigWebVersion();
      } catch (err: any) {
        console.error("Unable to get SigWeb Version: " + err.message);
      }
      console.error("SigWeb Version: " + sigWebVer);
      if (sigWebVer != "") {
        // Check if SigWeb 1.7.0 is installed
        let SigWeb_1_7_0_0_IsInstalled = false;
        try {
          SigWeb_1_7_0_0_IsInstalled =
            this.isSigWeb_1_7_0_0_Installed(sigWebVer);
        } catch (err: any) {
          console.error(err.message);
        }

        if (SigWeb_1_7_0_0_IsInstalled) {
          this.setIsSigWebInstalled(true);
        }
      }
    }

    this.setIsLoaded(true);
  };

  disconnectSignaturePad = (signatureKey: string) => {
    // Validate
    if (!this.isSigWebInstalled) {
      return;
    }
    if (this.currentSignatureKey !== signatureKey) {
      return;
    }

    // Disconnect Signature
    if (this.currentSignatureComponent) {
      const ctx = this.currentSignatureComponent.element.getContext("2d");

      if (ctx == null) {
        return;
      }

      if (this.timer != null) {
        window.SetTabletState(0, ctx, this.timer);
        clearInterval(this.timer as NodeJS.Timeout);
        clearInterval(this.eventsTimer as NodeJS.Timeout);
        this.eventsTimer = null;
        this.setTimer(null);
      }
    }

    // Trigger Event
    if (this.onDisconnectSignaturePad) {
      this.preProcessSignature();
      this.onDisconnectSignaturePad && this.onDisconnectSignaturePad();
      this.setCurrentSignatureComponent(undefined);
      this.setCurrentSignatureKey(undefined);
      this.onDisconnectSignaturePad = undefined;
    }
  };

  connectSignaturePad = (config: {
    signatureKey: string;
    newSignatureComponent: SignatureComponent | null;
    onSignaturePenUp: () => void;
    onDisconnectSignaturePad: () => void;
  }) => {
    // Validate
    if (!(config.newSignatureComponent && this.isSigWebInstalled)) {
      return;
    }

    // Disconnect Previous Signature
    if (this.currentSignatureComponent && this.currentSignatureKey) {
      this.disconnectSignaturePad(this.currentSignatureKey);
    }

    // Connect to new Signature
    this.setCurrentSignatureComponent(config.newSignatureComponent);
    this.setCurrentSignatureKey(config.signatureKey);
    this.onDisconnectSignaturePad = config.onDisconnectSignaturePad;

    // Activate to new Signature
    const ctx = config.newSignatureComponent.element.getContext("2d");

    if (ctx == null) {
      return;
    }

    clearInterval(this.timer as NodeJS.Timeout);
    clearInterval(this.eventsTimer as NodeJS.Timeout);
    this.eventsTimer = null;
    window.SetDisplayPenWidth(8);
    window.SetDisplayXSize(1300);
    window.SetDisplayYSize(180);
    window.SetImageXSize(1300);
    window.SetImageYSize(180);
    window.SetTabletState(0, ctx, this.timer);
    window.SetJustifyMode(0);
    window.ClearTablet();

    this.preProcessSignature();

    // The timer is used to poll the tablet to see if a signature has been captured.
    // If the time spent signing is less than this time, the event is not thrown.
    // Setting the timer to 100 ms to capture even pen ups that occur within a short time.
    this.eventsTimer = setInterval(window.SigWebEvent, 100);

    // Set Pen Up Event
    window.onSigPenUp = () => {
      this.preProcessSignature();
      config.onSignaturePenUp();
    };

    this.setTimer(window.SetTabletState(1, ctx, 50));
  };

  preProcessSignature = () => {
    this.removeWhiteBackground(
      this.currentSignatureComponent?.element.getContext(
        "2d"
      ) as CanvasRenderingContext2D
    );
  };

  removeWhiteBackground = (ctx: CanvasRenderingContext2D) => {
    // Get the image data
    const imageData = ctx.getImageData(0, 0, 1300, 180);
    const data = imageData.data;

    // Loop through each pixel and set the alpha channel based on white background
    for (let i = 0; i < data.length; i += 4) {
      const red = data[i];
      const green = data[i + 1];
      const blue = data[i + 2];

      // Check if the pixel is white (you can adjust the threshold if needed)
      if (red === 255 && green === 255 && blue === 255) {
        // Set the alpha channel to 0 for white pixels
        data[i + 3] = 0;
      }
    }

    // Put the modified image data back on the canvas
    ctx.putImageData(imageData, 0, 0);
  };

  isSigWeb_1_7_0_0_Installed = (sigWebVer: string) => {
    const minSigWebVersionGetDaysUntilCertificateExpiresSupport = "1.7.0.0";

    if (
      this.isOlderSigWebVersionInstalled(
        minSigWebVersionGetDaysUntilCertificateExpiresSupport,
        sigWebVer
      )
    ) {
      console.error("SigWeb version 1.7.0.0 or higher not installed.");
      return false;
    }
    return true;
  };

  isOlderSigWebVersionInstalled = (cmprVer: string, sigWebVer: string) => {
    return this.isOlderVersion(cmprVer, sigWebVer);
  };

  isOlderVersion = (oldVer: string, newVer: string) => {
    const oldParts = oldVer.split(".");
    const newParts = newVer.split(".");
    for (let i = 0; i < newParts.length; i++) {
      const a = parseInt(newParts[i]) || 0;
      const b = parseInt(oldParts[i]) || 0;
      if (a < b) {
        return true;
      }
      if (a > b) {
        return false;
      }
    }
    return false;
  };

  reset = () => {
    if (!this.isSigWebInstalled) {
      return;
    }

    this.setCurrentSignatureComponent(undefined);
    this.setCurrentSignatureKey(undefined);
    this.onDisconnectSignaturePad = undefined;

    clearInterval(this.timer as NodeJS.Timeout);
    clearInterval(this.eventsTimer as NodeJS.Timeout);
    this.eventsTimer = null;
    this.setTimer(null);
    this.setIsLoaded(false);
    window.Reset();
  };

  constructor() {
    makeObservable(this, {
      isSigWebInstalled: observable,
      isSigaturePadSupported: observable,
      currentSignatureComponent: observable,
      setIsSigWebInstalled: action,
      setCurrentSignatureComponent: action,
      reset: action,
    });
  }
}

export const signaturePadStore = new SignaturePadStore();
