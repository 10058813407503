import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { userStore } from "../../../../stores/UserStore";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { BaseDialogProps } from "../../types/types";
import { UserApplicationsGrid } from "./UserApplicationsGrid";

export interface UserApplicationsGridDialogProps extends BaseDialogProps {}

export const UserApplicationsGridDialog: React.FC<
  UserApplicationsGridDialogProps
> = ({ isOpen, closeDialog }) => {
  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      isCloseButtonShown={true}
      title={"User Applications"}
      onClose={closeDialog}
      className="user-applications"
      portalClassName="mdr-dialog user-applications-dialog fullscreen-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        {dialogsViewerStore.userApplicationsGridDialogOpenOptions?.userId &&
          dialogsViewerStore.userApplicationsGridDialogOpenOptions.userRole && (
            <UserApplicationsGrid
              canExportGridToExcel={!!userStore.user?.canExportUserBoardToExcel}
              canInvokeGridRecord={!!userStore.user?.canEditUser}
              userId={
                dialogsViewerStore.userApplicationsGridDialogOpenOptions.userId
              }
              userRole={
                dialogsViewerStore.userApplicationsGridDialogOpenOptions
                  .userRole
              }
            />
          )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          minimal
          intent={Intent.DANGER}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
