import { dialogStore } from "@ucl/library";
import { AppointmentForm } from "../Forms/AppointmentForm/AppointmentForm";
import { AppointmentFormModel } from "../Forms/AppointmentForm/AppointmentFormModel";
import "./styles.scss";

const dialogIdBase = "appointment-dialog";

export const openCreateAppointmentDialog = (
  facilityId?: string,
  date?: string,
  startTime?: number,
  endTime?: number
) =>
  dialogStore.openDialog({
    id: dialogIdBase,
    className: dialogIdBase,
    header: "Schedule an Interview",
    allowDragging: false,
    content: () => (
      <AppointmentForm
        facilityId={facilityId}
        date={date}
        startTime={startTime}
        endTime={endTime}
        onFormSubmit={closeAppointmentDialog}
      />
    ),
  });

export const openUpdateAppointmentDialog = (
  formModel?: AppointmentFormModel
  // appointmentId?: string
) =>
  dialogStore.openDialog({
    id: dialogIdBase,
    className: dialogIdBase,
    header: "Appointment Details",
    allowDragging: false,
    content: () => (
      <AppointmentForm
        formModel={formModel}
        // appointmentId={appointmentId}
      />
    ),
  });

export const closeAppointmentDialog = () =>
  dialogStore.closeDialog(dialogIdBase);
