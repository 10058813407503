import { ColumnModel } from "@syncfusion/ej2-react-grids";
import { Predicate } from "@syncfusion/ej2-data";
import { getObjectValueClassName } from "../../../../utils/miscellaneous";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";
import GridColumn from "./GridColumn";
import "../styles.scss";

interface GridClickableDataColumnOptions extends ColumnModel {
  dialogType: DialogTypes;
}

type DialogTypes =
  | "comments"
  | "facility-users-grid"
  | "corporate-facilites-grid"
  | "corporate-applications-grid"
  | "user-applications-grid"
  | "facility-applications-grid"
  | "facility-user-grid"
  | "facility-user-is-poc-grid"
  | "facility-user-is-billing-poc-grid"
  | "corporate-users-grid";

const openDialog = (dialogType: DialogTypes, model: Record<string, string>) => {
  const modelId = model["id"];

  switch (dialogType) {
    case "comments":
      const applicationFileNumber = model["fileNumber"];
      dialogsViewerStore.setIsCommentsDialogOpen(true, {
        applicationId: modelId,
        applicationFileNumber: applicationFileNumber,
        sourceId: modelId,
        isSrcApplication: true,
      });
      break;
    case "corporate-facilites-grid":
      dialogsViewerStore.setIsCorporateFacilitesGridDialogOpen(true, {
        corporateId: model["id"],
        predicate: new Predicate("corporateId", "equal", modelId).and(
          new Predicate("isActive", "equal", true)
        ),
      });
      break;
    case "corporate-applications-grid":
      dialogsViewerStore.setIsCorporateApplicationsGridDialogOpen(true, {
        corporateId: modelId,
      });
      break;
    case "user-applications-grid":
      dialogsViewerStore.setIsUserApplicationsGridDialogOpen(true, {
        userId: modelId,
        userRole: model["role"],
      });
      break;
    case "facility-applications-grid":
      dialogsViewerStore.setIsFacilityApplicationsGridDialogOpen(true, {
        facilityId: modelId,
      });
      break;
    case "facility-users-grid":
      dialogsViewerStore.setIsFacilityUsersGridDialogOpen(true, {
        corporateId: model["corporateId"],
        facilityId: modelId,
        predicate: new Predicate("facilityId", "equal", modelId).and(
          new Predicate("isActive", "equal", true)
        ),
        dialogTitle: "Facility Users",
      });
      break;
    case "corporate-users-grid":
      dialogsViewerStore.setIsCorporateFacilityUsersGridDialogOpen(true, {
        corporateId: modelId,
        predicate: new Predicate("corporateId", "equal", modelId).and(
          new Predicate("isActive", "equal", true)
        ),
      });
      break;
    case "facility-user-is-poc-grid":
      dialogsViewerStore.setIsFacilityUsersGridDialogOpen(true, {
        corporateId: model["corporateId"],
        facilityId: modelId,
        predicate: new Predicate("facilityId", "equal", modelId).and(
          new Predicate("isFacilityPOC", "equal", true)
        ),
        dialogTitle: "Facility Users - POC",
      });
      break;
    case "facility-user-is-billing-poc-grid":
      dialogsViewerStore.setIsFacilityUsersGridDialogOpen(true, {
        corporateId: model["corporateId"],
        facilityId: modelId,
        predicate: new Predicate("facilityId", "equal", modelId).and(
          new Predicate("isBillingPOC", "equal", true)
        ),
        dialogTitle: "Facility Users - Billing POC",
      });
      break;
    default:
      throw new Error(
        `Cannot perform action for unexpected dialogType: ${dialogType}`
      );
  }
};

export default class GridClickableDataColumn extends GridColumn {
  constructor(opts: GridClickableDataColumnOptions) {
    const { dialogType, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const modelValue = opts.field ? model[opts.field] : "";

        const formatTableData = (dialogType: DialogTypes) => {
          switch (dialogType) {
            case "comments":
              const isInMonitor: boolean = model["isInMonitor"] ? true : false;
              if (isInMonitor) {
                return parseInt(modelValue) >= 10 ? "_commentage_red" : "";
              } else {
                return parseInt(modelValue) >= 5 ? "_commentage_red" : "";
              }
            case "facility-users-grid":
              return "";
            case "corporate-facilites-grid":
              return "";
            case "corporate-applications-grid":
              return "";
            case "user-applications-grid":
              return "";
            case "facility-applications-grid":
              return "";
            case "facility-users-grid":
              return "";
            case "facility-user-is-poc-grid":
              return "";
            case "facility-user-is-billing-poc-grid":
              return "";
            case "corporate-users-grid":
              return "";
            default:
              throw new Error(
                `Cannot format data for unexpected dialogType: ${dialogType}`
              );
          }
        };

        return (
          <div
            className="grid-clickable-data-column"
            onClick={(e) => {
              e.stopPropagation();
              openDialog(dialogType, model);
            }}
          >
            <a
              className={`link${getObjectValueClassName(
                formatTableData(dialogType)
              )}`}
            >
              {modelValue}
            </a>
          </div>
        );
      }) as any,
    });
  }
}
