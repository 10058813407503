import { makeObservable, observable } from "mobx";
import {
  AnnotationDataFormat,
  AnnotationSelectEventArgs,
  CircleSettings,
  CustomStampModel,
  CustomStampSettings,
  FreeTextSettings,
  IPoint,
  PdfAnnotationBase,
  PdfAnnotationBaseModel,
  PdfViewerComponent,
  RectangleSettings,
} from "@syncfusion/ej2-react-pdfviewer";
import { ImageElement } from "@syncfusion/ej2-drawings";
import { v4 as uuidv4 } from "uuid";
import { get } from "lodash";
import _ from "lodash";
import { useDocument } from "../../Document/useDocument";

export interface ExpressionData {
  // Id is generated at runttime
  id?: string;
  expressionName: string;
  expressionPlaceholder?: string;
  expressionType: ExpressionTypes;
  expressionCategory?: string;
  expressionDimension?: { width: number; height: number };
}

export enum ExpressionTypes {
  FreeText = 1,
  Rectangle = 2,
  Circle = 3,
  TextExpression = 4,
  ImageExpression = 5,
  CheckboxExpression = 6,
}

export const expressiveTypes: ExpressionTypes[] = [
  ExpressionTypes.FreeText,
  ExpressionTypes.TextExpression,
  ExpressionTypes.ImageExpression,
  ExpressionTypes.CheckboxExpression,
];

export const freeTextAnnotationDimensionsStyle: Pick<
  FreeTextSettings | ExpressionDimensions,
  "width" | "height"
> = {
  width: 150,
  height: 24,
};
export const shapeAnnotationDimensionsStyle: Pick<
  RectangleSettings | CircleSettings | ExpressionDimensions,
  "width" | "height"
> = {
  width: 100,
  height: 100,
};
export const imageAnnotationDimensionsStyle: Pick<
  CustomStampSettings | ExpressionDimensions,
  "width" | "height"
> = {
  width: 250,
  height: 50,
};

export const checkboxAnnotationDimensionsStyle: Pick<
  RectangleSettings | ExpressionDimensions,
  "width" | "height"
> = {
  width: 15,
  height: 15,
};

export interface ExpressionDimensions {
  width: number;
  height: number;
}

export class ExpressionsStore {
  // public
  public pdfViewerInstance?: PdfViewerComponent;
  public originalImportedData?: {
    Rect: any;
    AnnotName: string;
    Apperarance: { [key: string]: string }[];
  }[];
  public pdfViewerId?: string;
  public previewData: any = {};

  private annotationFreeTextExpressionStyle: Pick<
    FreeTextSettings,
    "fillColor" | "borderColor" | "borderWidth"
  > = {
    fillColor: "#fdf8d8",
    borderColor: "#f1a01a",
    borderWidth: 2,
  };

  private annotationImageExpressionStyle: Pick<
    CanvasRenderingContext2D,
    "fillStyle" | "strokeStyle" | "lineWidth"
  > = {
    fillStyle: "#fdf8d8",
    strokeStyle: "#f1a01a",
    lineWidth: 4,
  };

  private annotationFreeTexStyle: Pick<
    FreeTextSettings,
    "fillColor" | "borderColor" | "borderWidth"
  > = {
    fillColor: "#d8f0fd",
    borderColor: "#1a84f1",
    borderWidth: 2,
  };
  private annotationShapeStyle: Pick<
    RectangleSettings | CircleSettings,
    "fillColor" | "strokeColor" | "thickness"
  > = {
    fillColor: "#000",
    strokeColor: "#000",
    thickness: 2,
  };

  constructor() {
    makeObservable(this, {
      pdfViewerInstance: observable,
    });
  }

  // Events
  public handleAnnotationSelect = (args: AnnotationSelectEventArgs) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    const annotation = this.getAnnotationByAnnotationId(args.annotationId);
    if (annotation?.customData) {
      this.updateExpressionSelectedClassName(
        (annotation.customData as ExpressionData).expressionType,
        true
      );

      this.pdfViewerInstance.toolbar.showAnnotationToolbar(false);
    }
  };

  public updateExpressionSelectedClassName = (
    expressionType: ExpressionTypes,
    isAdd?: boolean
  ) => {
    const pdfViewerContainer = document.getElementById(this.pdfViewerId!);

    // Remove all to cleanup
    this.removeAllExpressionSelectedClassName();

    if (isAdd === true) {
      pdfViewerContainer?.classList.add(
        `expression-selected-${ExpressionTypes[expressionType].toLowerCase()}`
      );
    }
  };

  public removeAllExpressionSelectedClassName = () => {
    const pdfViewerContainer = document.getElementById(this.pdfViewerId!);

    // Remove all to cleanup
    pdfViewerContainer?.classList.remove(`expression-selected-freetext`);
    pdfViewerContainer?.classList.remove(`expression-selected-rectangle`);
    pdfViewerContainer?.classList.remove(`expression-selected-circle`);
    pdfViewerContainer?.classList.remove(`expression-selected-textexpression`);
    pdfViewerContainer?.classList.remove(`expression-selected-imageexpression`);
    pdfViewerContainer?.classList.remove(
      `expression-selected-checkboxexpression`
    );
  };

  public getAnnotationByAnnotationId = (annotationId: string) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    return this.pdfViewerInstance.annotationCollection.find(
      (x) => x.annotationId === annotationId
    );
  };

  public getAnnotationObjectByAnnotationId = (annotationId: string) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    return this.pdfViewerInstance.annotations.find(
      (x) => x.annotName === annotationId
    );
  };

  public handleAnnotationUnselect = (args: AnnotationSelectEventArgs) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    this.unselectAnnotation(args.annotationId);
  };
  public unselectAnnotation = (annotationId: string) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    const annotation = this.getAnnotationByAnnotationId(annotationId);

    if (annotation?.customData) {
      this.updateExpressionSelectedClassName(
        (annotation.customData as ExpressionData).expressionType,
        false
      );

      // Hide
      this.pdfViewerInstance.toolbar.showAnnotationToolbar(false);
    }
  };

  public unselectAllAnnotations = () => {
    if (!this.pdfViewerInstance) {
      return;
    }

    this.pdfViewerInstance?.selectedItems?.annotations?.forEach((annot) => {
      if (annot.annotName) {
        this.unselectAnnotation(annot.annotName);
      }
    });

    this.removeAllExpressionSelectedClassName();
  };

  public annotationRemove = () => {
    if (!this.pdfViewerInstance) {
      return;
    }

    this.pdfViewerInstance?.annotationModule.clearSelection();
    this.unselectAllAnnotations();
  };

  public addExpressionAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    switch (templateExpression.expressionType) {
      case ExpressionTypes.FreeText:
        return this.addFreeTextAnnotation(templateExpression, point);
      case ExpressionTypes.Rectangle:
        return this.addRectangleAnnotation(templateExpression, point);
      case ExpressionTypes.Circle:
        return this.addCircleAnnotation(templateExpression, point);
      case ExpressionTypes.TextExpression:
        return this.addTextExpressionAnnotation(templateExpression, point);
      case ExpressionTypes.ImageExpression:
        point = {
          x: point.x,
          y: point.y,
        } as IPoint;
        return this.addImageExpressionAnnotation(templateExpression, point);
      case ExpressionTypes.CheckboxExpression:
        return this.addCheckboxExpressionAnnotation(templateExpression, point);
      default:
        return undefined;
    }
  };

  public getDefaultExpressionAnnotationDimensions = (
    expressionType: ExpressionTypes
  ): ExpressionDimensions => {
    // I am not being a fan of these multiple switches wich may raise a flag that these annotation should be in in their own factories
    // Since its just a few, let keep going this path and adjust as needed

    switch (expressionType) {
      case ExpressionTypes.FreeText:
        return freeTextAnnotationDimensionsStyle as ExpressionDimensions;
      case ExpressionTypes.Rectangle:
        return shapeAnnotationDimensionsStyle as ExpressionDimensions;
      case ExpressionTypes.Circle:
        return shapeAnnotationDimensionsStyle as ExpressionDimensions;
      case ExpressionTypes.TextExpression:
        return freeTextAnnotationDimensionsStyle as ExpressionDimensions;
      case ExpressionTypes.ImageExpression:
        return imageAnnotationDimensionsStyle as ExpressionDimensions;
      case ExpressionTypes.CheckboxExpression:
        return checkboxAnnotationDimensionsStyle as ExpressionDimensions;
      default:
        return { width: 0, height: 0 } as ExpressionDimensions;
    }
  };

  public addFreeTextAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    // Setup
    templateExpression.id = uuidv4();
    const dimensions =
      templateExpression.expressionDimension ??
      this.getDefaultExpressionAnnotationDimensions(
        templateExpression.expressionType
      );
    const freeTextSettings = {
      customData: templateExpression,
      offset: point,
      defaultText: templateExpression.expressionPlaceholder,
      pageNumber: this.pdfViewerInstance.currentPageNumber,
      isReadonly: false,
      ...this.annotationFreeTexStyle,
      ...dimensions,
    } as FreeTextSettings;

    // Add
    this.pdfViewerInstance.annotationModule.addAnnotation(
      "FreeText",
      freeTextSettings
    );

    return this.getExpressionAnnotationByExpressionId(templateExpression.id);
  };

  public addRectangleAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    // Setup
    // Note that customData is not being stored for Rectangle
    templateExpression.id = uuidv4();
    const dimensions =
      templateExpression.expressionDimension ??
      this.getDefaultExpressionAnnotationDimensions(
        templateExpression.expressionType
      );
    const rectangleSettings = {
      customData: templateExpression,
      offset: point,
      pageNumber: this.pdfViewerInstance.currentPageNumber,
      ...this.annotationShapeStyle,
      ...dimensions,
    } as RectangleSettings;

    // Add
    this.pdfViewerInstance.annotationModule.addAnnotation(
      "Rectangle",
      rectangleSettings
    );

    return this.getExpressionAnnotationByExpressionId(templateExpression.id);
  };

  public addCircleAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    // Setup
    // Note that customData is not being stored for Circle
    templateExpression.id = uuidv4();
    const dimensions =
      templateExpression.expressionDimension ??
      this.getDefaultExpressionAnnotationDimensions(
        templateExpression.expressionType
      );
    const rectangleSettings = {
      customData: templateExpression,
      offset: point,
      pageNumber: this.pdfViewerInstance.currentPageNumber,
      ...this.annotationShapeStyle,
      ...dimensions,
    } as CircleSettings;

    // Add
    this.pdfViewerInstance.annotationModule.addAnnotation(
      "Circle",
      rectangleSettings
    );

    return this.getExpressionAnnotationByExpressionId(templateExpression.id);
  };

  public addCheckboxExpressionAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    // Setup
    // Note that customData is not being stored for Rectangle
    templateExpression.id = uuidv4();
    const dimensions =
      templateExpression.expressionDimension ??
      this.getDefaultExpressionAnnotationDimensions(
        templateExpression.expressionType
      );
    const rectangleSettings = {
      customData: templateExpression,
      offset: point,
      pageNumber: this.pdfViewerInstance.currentPageNumber,
      ...this.annotationShapeStyle,
      ...dimensions,
    } as RectangleSettings;

    // Add
    this.pdfViewerInstance.annotationModule.addAnnotation(
      "Rectangle",
      rectangleSettings
    );

    return this.getExpressionAnnotationByExpressionId(templateExpression.id);
  };

  public addTextExpressionAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    // Setup
    templateExpression.id = uuidv4();
    const dimensions =
      templateExpression.expressionDimension ??
      this.getDefaultExpressionAnnotationDimensions(
        templateExpression.expressionType
      );
    const freeTextSettings = {
      customData: templateExpression,
      offset: point,
      defaultText: templateExpression.expressionPlaceholder,
      pageNumber: this.pdfViewerInstance.currentPageNumber,
      isReadonly: true,
      ...this.annotationFreeTextExpressionStyle,
      ...dimensions,
    } as FreeTextSettings;

    // Add
    this.pdfViewerInstance.annotationModule.addAnnotation(
      "FreeText",
      freeTextSettings
    );

    return this.getExpressionAnnotationByExpressionId(templateExpression.id);
  };

  public addImageExpressionAnnotation = (
    templateExpression: ExpressionData,
    point: IPoint
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    // Setup
    // As unknown to overwrite custom data
    templateExpression.id = uuidv4();
    const dimensions =
      templateExpression.expressionDimension ??
      this.getDefaultExpressionAnnotationDimensions(
        templateExpression.expressionType
      );

    const customStampSettings = {
      customData: templateExpression,
      customStamps: [
        {
          customStampName: templateExpression.expressionName,
          customStampImageSource: this.getImageExpressionPlaceholderImage(
            dimensions.width,
            dimensions.height,
            templateExpression.expressionPlaceholder ?? ""
          ),
        } as CustomStampModel,
      ],
      offset: point,
      pageNumber: this.pdfViewerInstance.currentPageNumber,
      isReadonly: true,
      ...dimensions,
    } as unknown as CustomStampSettings;

    // Add
    this.pdfViewerInstance.annotationModule.addAnnotation(
      "Stamp",
      customStampSettings
    );

    return this.getExpressionAnnotationByExpressionId(templateExpression.id);
  };

  public getImageExpressionPlaceholderImage = (
    width: number,
    height: number,
    placeholderText: string
  ): string => {
    const w = Math.round(width);
    const h = Math.round(height);

    // x Resolution
    const ratio = 2;

    const canvas = document.createElement("canvas");
    canvas.width = w * ratio;
    canvas.height = h * ratio;
    canvas.style.width = w + "px";
    canvas.style.height = h + "px";

    // Context
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return "";
    }

    // Scale (Resolution)
    ctx.scale(ratio, ratio);

    // Fill
    ctx.fillStyle = this.annotationImageExpressionStyle.fillStyle;
    ctx.fillRect(0, 0, w, h);

    // Stroke
    ctx.strokeStyle = this.annotationImageExpressionStyle.strokeStyle;
    ctx.lineWidth = this.annotationImageExpressionStyle.lineWidth;
    ctx.strokeRect(0, 0, w, h);

    // Text
    ctx.font = "13px Roboto";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#000";
    ctx.fillText(placeholderText, w / 2, h / 2);

    const dataURL = canvas.toDataURL("image/png");

    return dataURL;
  };

  public designAnnotations = () => {
    if (!this.pdfViewerInstance) {
      return;
    }

    // Unselect
    this.unselectAllAnnotations();

    this.pdfViewerInstance.annotationCollection.forEach((annotation: any) => {
      const annotCustomData = annotation.customData as ExpressionData;
      if (
        annotation.customData &&
        expressiveTypes.includes(annotCustomData?.expressionType)
      ) {
        switch (annotation.shapeAnnotationType) {
          case "FreeText":
            {
              if (
                annotCustomData.expressionType ===
                ExpressionTypes.TextExpression
              ) {
                this.updateAnnotationProperties(annotation.annotationId, {
                  dynamicText: annotCustomData.expressionPlaceholder,
                  fillColor: this.annotationFreeTextExpressionStyle.fillColor,
                  strokeColor:
                    this.annotationFreeTextExpressionStyle.borderColor,
                  thickness: this.annotationFreeTextExpressionStyle.borderWidth,
                });
              } else {
                // FreeText
                this.updateAnnotationProperties(annotation.annotationId, {
                  dynamicText: annotation.dynamicText,
                  fillColor: this.annotationFreeTexStyle.fillColor,
                  strokeColor: this.annotationFreeTexStyle.borderColor,
                  thickness: this.annotationFreeTexStyle.borderWidth,
                });
              }
            }
            break;
          case "Square":
            {
              if (
                annotCustomData.expressionType ===
                ExpressionTypes.CheckboxExpression
              ) {
                this.updateAnnotationProperties(annotation.annotationId, {
                  fillColor: this.annotationShapeStyle.fillColor,
                  strokeColor: this.annotationShapeStyle.strokeColor,
                  thickness: this.annotationShapeStyle.thickness,
                });
              }
            }
            break;
          case "stamp":
            {
              let bounds = annotation.bounds;
              if (!bounds) {
                // It has not being loaded yet. Fetch from initial import.
                const ogImportedAnnotation = this.originalImportedData?.find(
                  (x) => x.AnnotName == annotation.annotationId
                );

                if (ogImportedAnnotation) {
                  bounds = {
                    width: ogImportedAnnotation.Rect.Width,
                    height: ogImportedAnnotation.Rect.Height,
                  };
                }
              }

              const value = this.getImageExpressionPlaceholderImage(
                bounds.width,
                bounds.height,
                annotation.customData.expressionPlaceholder
              );
              this.updateCustomStampAnnotationImage(
                annotation.annotationId,
                value
              );
            }
            break;
          default:
            break;
        }
      }
    });
  };

  public previewAnnotations = async (data?: any) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    // any for now
    if (!this.pdfViewerInstance?.annotationModule) {
      return;
    }

    // Set Preview Data
    if (data) {
      this.previewData = data;
    } else {
      data = this.previewData;
    }

    // Unselect
    this.unselectAllAnnotations();

    this.pdfViewerInstance?.annotationCollection.forEach(
      async (annotation: any) => {
        const annotCustomData = annotation.customData as ExpressionData;

        if (
          annotation.customData &&
          expressiveTypes.includes(annotCustomData?.expressionType)
        ) {
          switch (annotation.shapeAnnotationType) {
            case "FreeText":
              {
                const value =
                  annotCustomData.expressionType ===
                  ExpressionTypes.TextExpression
                    ? get(data, annotCustomData?.expressionName)
                    : annotation.dynamicText;

                this.updateAnnotationProperties(annotation.annotationId, {
                  dynamicText: value || " ",
                  fillColor: "transparent",
                  strokeColor: "transparent",
                  thickness: 0,
                });
              }
              break;
            case "Square":
              {
                if (
                  annotCustomData.expressionType ===
                  ExpressionTypes.CheckboxExpression
                ) {
                  const value = get(data, annotCustomData?.expressionName);

                  // If no value, hide from PDF
                  if (value === "True") {
                    this.updateAnnotationProperties(annotation.annotationId, {
                      fillColor: this.annotationShapeStyle.fillColor,
                      strokeColor: this.annotationShapeStyle.strokeColor,
                      thickness: this.annotationShapeStyle.thickness,
                    });
                  } else {
                    this.updateAnnotationProperties(annotation.annotationId, {
                      fillColor: "transparent",
                      strokeColor: "transparent",
                      thickness: 0,
                    });
                  }
                }
              }
              break;
            case "stamp":
              {
                let value = get(data, annotCustomData?.expressionName);
                value = await this.getBaseImageAnnotationData(value);

                this.updateCustomStampAnnotationImage(
                  annotation.annotationId,
                  value
                );
              }
              break;
            default:
              break;
          }
        }
      }
    );
  };

  private isGuid = (value: string): boolean => {
    // Define a regular expression pattern for a GUID
    const guidPattern =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    // Use the test method of the regular expression to check if the value matches the pattern
    return guidPattern.test(value);
  };

  private getBaseImageAnnotationData = async (
    value: string
  ): Promise<string> => {
    // Verify if value is a Guid
    if (!value) {
      return "";
    }

    if (this.isGuid(value)) {
      const { getDocument, getDocumentBlobUrl } = useDocument();
      const docBlobPart = await getDocument(value);

      if (!docBlobPart) {
        return "";
      }

      return await getDocumentBlobUrl(docBlobPart);
    }

    return value;
  };

  public updateCustomStampAnnotationImage = (
    annotationId: string,
    imageSource: string
  ) => {
    const annoObject = this.getAnnotationObjectByAnnotationId(annotationId);
    if (!annoObject) {
      return;
    }

    const imageElement = annoObject!.wrapper!.children[0] as ImageElement;
    imageElement["imageSource"] = imageSource;

    this.updateAnnotationProperties(annotationId, {
      data: imageSource,
    });
  };

  public updateAnnotationProperties = (
    annotationId: string,
    annotationUpdateSettings: PdfAnnotationBaseModel
  ) => {
    const annoObject = this.getAnnotationObjectByAnnotationId(annotationId);
    if (!annoObject) {
      return;
    }

    this.pdfViewerInstance?.nodePropertyChange(
      annoObject,
      annotationUpdateSettings
    );
  };

  public goToPage = (pageNumber: number) => {
    const pdfViewerContainer = document.getElementById(this.pdfViewerId!);
    // Remove all to cleanup
    pdfViewerContainer?.classList.remove(`go-to-page-transition-enter`);
    pdfViewerContainer?.classList.remove(`go-to-page-transition-exit`);
    pdfViewerContainer?.classList.add(`go-to-page-transition-enter`);
    setTimeout(() => {
      this.pdfViewerInstance?.navigation.goToPage(pageNumber);
      setTimeout(() => {
        pdfViewerContainer?.classList.add(`go-to-page-transition-exit`);
      }, 300);
    }, 200);
  };

  public getExpressionAnnotationByExpressionId = (
    expressionId: string
  ): PdfAnnotationBase | undefined => {
    if (!this.pdfViewerInstance) {
      return undefined;
    }

    const newAnnotation = this.pdfViewerInstance.annotations.find(
      (x) =>
        x.customData && (x.customData as ExpressionData)?.id === expressionId
    );

    return newAnnotation as PdfAnnotationBase;
  };

  public exportAnnotations = async (): Promise<string | undefined> => {
    if (!this.pdfViewerInstance) {
      return;
    }

    if (this.pdfViewerInstance.annotationCollection.length <= 0) {
      // No Annotations
      return;
    }

    //

    // Export
    const exportResultBase64Xfdf =
      await this.pdfViewerInstance?.exportAnnotationsAsBase64String(
        AnnotationDataFormat.Xfdf
      );

    // Clean response
    return exportResultBase64Xfdf.replace("data:application/pdf;base64,", "");
  };

  public importAnnotations = async (exportedAnnotationsXFDFBase64: string) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    this.pdfViewerInstance?.importAnnotation(
      exportedAnnotationsXFDFBase64,
      AnnotationDataFormat.Xfdf
    );
  };

  public selectAnnotation = (annotationId: string) => {
    if (!this.pdfViewerInstance) {
      return;
    }

    // Select
    this.pdfViewerInstance.annotation.selectAnnotation(annotationId);
    this.pdfViewerInstance.toolbar.showAnnotationToolbar(true);
  };

  public setup = (args: { pdfViewerId: string }) => {
    // Setup Properties
    this.pdfViewerId = args.pdfViewerId;
  };

  public initialize = (newPdfViewerInstance: PdfViewerComponent) => {
    this.setInstance(newPdfViewerInstance);
  };

  public reset = () => {
    this.setInstance(undefined);
  };

  public setInstance = (scopePdfViewerInstance?: PdfViewerComponent) => {
    this.pdfViewerInstance = scopePdfViewerInstance;
  };

  public setOriginalImportedData = (originalImportedData?: any) => {
    this.originalImportedData = originalImportedData;
  };
}

export const expressionsStore = new ExpressionsStore();

export interface ExpressionsStoreSetup {
  pdfViewerId: string;
}
