import { Button, Callout, Classes, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { getObjectValueClassName } from "../../utils/miscellaneous";
import {
  FIARequest,
  FIARequestUpdateDTO,
  FIARequestValidationsDTO,
} from "./configurations/types";
import { FIARequestCreate } from "./FIARequestCreate";
import { FIARequestUpdate } from "./FIARequestUpdate";
import { useFIARequestFlyout } from "./UseFIARequestFlyout";
import { applicationStore } from "../../stores/ApplicationStore";
import { UserIcon } from "../Avatar/UserIcon";
import { fiaRequestViewerStore } from "../../stores/FIARequestViewerStore";

export interface FIARequestFlyoutProps {
  applicationId: string;
  flyoutIsOpen: boolean;
  onClose?: () => void;
  fiaRequestId?: string;
  applicationURL?: string;
}

export const setIsUpdatedFIAPending = (fiaRequest: FIARequest) => {
  const fiaValidations = fiaRequest as FIARequestValidationsDTO;

  if (fiaValidations.isPending) {
    applicationStore.setHasPendingFIARequestsOnAplication();
  }
};

export const FIARequestFlyout: React.FC<FIARequestFlyoutProps> = (props) => {
  const {
    setFIARequestFromFIACreateApproveOrSend,
    handleClose,
    reload,
    reloadKey,
    localFIARequestId,
    institution,
    setIsActionExecuting,
    fiaRequest,
    setFIARequest,
  } = useFIARequestFlyout(props.fiaRequestId, props.onClose);
  return (
    <>
      <div className={Classes.DRAWER_HEADER}>
        <h3>Request</h3>
        {fiaRequest &&
          (fiaRequest as FIARequestUpdateDTO).requestStatus_AsString && (
            <Tag
              className={`fia-request-status fia-request-status__${getObjectValueClassName(
                (fiaRequest as FIARequestUpdateDTO).requestStatus_AsString
              )}`}
              round={true}
            >
              {(fiaRequest as FIARequestUpdateDTO).requestStatus_AsString}
            </Tag>
          )}
        {fiaRequestViewerStore.activeUsers?.map((user) => (
          <UserIcon key={user.id} user={user} />
        ))}
        {props.applicationURL && (
          <Button
            className="fia-request-open-file-button"
            icon={IconNames.SHARE}
            text="Open File"
            onClick={() => {
              window.open(props.applicationURL, "_blank");
            }}
          />
        )}
      </div>

      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <div className="fia-request-callouts-container">
            {institution && institution.isSpecialized && (
              <Callout
                className="fia-request-callout"
                icon={IconNames.WARNING_SIGN}
                intent="warning"
              >
                {"This institution is Specialized. Please contact DVT"}
              </Callout>
            )}
            {fiaRequest?.hasInvalidFaxInformation && (
              <Callout
                className="fia-request-callout"
                icon={IconNames.WARNING_SIGN}
                intent="warning"
              >
                This Institution has a Send Method of Fax, and does not have a
                valid Fax Number.
              </Callout>
            )}
            {fiaRequest?.hasInvalidEmailInformation && (
              <Callout
                className="fia-request-callout"
                icon={IconNames.WARNING_SIGN}
                intent="warning"
              >
                This Institution has a Send Method of Email, and does not have a
                valid Email Address.
              </Callout>
            )}
          </div>
        </div>
        {!!localFIARequestId && (
          <FIARequestUpdate
            key={reloadKey}
            applicationId={props.applicationId}
            fiaRequestId={localFIARequestId}
            institution={institution}
            onRequestLoaded={(request?: FIARequest) => {
              setFIARequest(request);
            }}
            onActionExecuting={(isExecuting) => {
              setIsActionExecuting(isExecuting);
            }}
            onActionExecuted={() => {
              reload();
            }}
            setFIARequestOnApproveOrSend={
              setFIARequestFromFIACreateApproveOrSend
            }
          />
        )}
        {!localFIARequestId && (
          <FIARequestCreate
            applicationId={props.applicationId}
            onCancel={handleClose}
            setFIARequestFromFIACreate={setFIARequestFromFIACreateApproveOrSend}
          />
        )}
      </div>

      <div
        className={Classes.DRAWER_FOOTER}
        style={{ height: 5, boxShadow: "none", padding: 0 }}
      ></div>
    </>
  );
};
