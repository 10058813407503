import { Application } from "../../../../types/Application";
import {
  AssetInformation,
  AssetTypes,
  NewAsset,
} from "../../../../types/Application/Asset";
import { FormSchemaBuilder } from "../../types";
import {
  burialPreneedApplicationFormSchema,
  burialPreneedFormSchema,
} from "./BurialPreneed";
import {
  fiveYearLookbackApplicationFormSchema,
  fiveYearLookbackFormSchema,
} from "./FiveYearLookback";
import {
  lifeInsuranceApplicationFormSchema,
  lifeInsuranceFormSchema,
} from "./LifeInsurance";
import {
  institutionApplicationFormSchema,
  institutionFormSchema,
} from "./LiquidAsset";
import {
  liquidAssetApplicationFormSchema,
  liquidAssetFormSchema,
} from "./Other";
import {
  realPropertyApplicationFormSchema,
  realPropertyFormSchema,
} from "./RealProperty";
import { vehicleApplicationFormSchema, vehicleFormSchema } from "./Vehicle";

export const newAssetInformation: AssetInformation = {
  hasAssetRealProperty: undefined,
  hasAssetVehicle: undefined,
  hasAssetLiquidAsset: undefined,
  hasAssetInstitution: undefined,
  hasAssetLifeInsurance: undefined,
  hasAssetBurialPreneed: undefined,
  hasAssetFiveYearLookback: undefined,

  spouseHasAssetRealProperty: undefined,
  spouseHasAssetVehicle: undefined,
  spouseHasAssetLiquidAsset: undefined,
  spouseHasAssetInstitution: undefined,
  spouseHasAssetLifeInsurance: undefined,
  spouseHasAssetBurialPreneed: undefined,
  spouseHasAssetFiveYearLookback: undefined,
};

export const newAsset: NewAsset = {
  type: undefined,
  subType: undefined,
  description: undefined,
  vehicleYear: undefined,
  vehicleMake: undefined,
  vehicleModel: undefined,
  isVehicleRegistered: undefined,
  accountNumber: undefined,
  institutionId: undefined,
  owner: undefined,
  estimatedValue: undefined,
  value: undefined,
  isExempt: undefined,
  asOfDate: undefined,
  isVerified: undefined,
  isBurialPreneed: undefined,
  otherInstitutionName: undefined,
  statementDateRangeStart: undefined,
  statementDates_AsList: undefined,
  statementFrequency: undefined,
  funeralHome: undefined,
  isIrrevocable: undefined,
  isFunded: undefined,
  fundingSource: undefined,
  recipient: undefined,
  transferDate: undefined,
  willPenaltyOccur: undefined,
};

const assetSchemas: {
  shouldShowAssetCard: (application: Application) => boolean;
  formId: string;
  type: AssetTypes;
  title: string;
  application: FormSchemaBuilder;
  asset: FormSchemaBuilder;
}[] = [
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetRealProperty ||
      !!application.spouseHasAssetRealProperty,
    formId: "has-asset-real-property",
    title: "Property",
    type: AssetTypes.RealProperty,
    application: realPropertyApplicationFormSchema,
    asset: realPropertyFormSchema,
  },
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetVehicle || !!application.spouseHasAssetVehicle,
    formId: "has-asset-vehicle",
    title: "Vehicle",
    type: AssetTypes.Vehicle,
    application: vehicleApplicationFormSchema,
    asset: vehicleFormSchema,
  },
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetInstitution ||
      !!application.spouseHasAssetInstitution,
    formId: "has-asset-institution",
    title: "Bank Account",
    type: AssetTypes.Institution,
    application: institutionApplicationFormSchema,
    asset: institutionFormSchema,
  },
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetLiquidAsset ||
      !!application.spouseHasAssetLiquidAsset,
    formId: "has-asset-liquid-asset",
    title: "Other Asset",
    type: AssetTypes.LiquidAsset,
    application: liquidAssetApplicationFormSchema,
    asset: liquidAssetFormSchema,
  },
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetLifeInsurance ||
      !!application.spouseHasAssetLifeInsurance,
    formId: "has-asset-life-insurance",
    title: "Life Insurance",
    type: AssetTypes.LifeInsurance,
    application: lifeInsuranceApplicationFormSchema,
    asset: lifeInsuranceFormSchema,
  },
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetBurialPreneed ||
      !!application.spouseHasAssetBurialPreneed,
    formId: "has-asset-burial-preneed",
    title: "Burial / Preneed Funeral",
    type: AssetTypes.BurialPreNeed,
    application: burialPreneedApplicationFormSchema,
    asset: burialPreneedFormSchema,
  },
  {
    shouldShowAssetCard: (application: Application) =>
      !!application.hasAssetFiveYearLookback ||
      !!application.spouseHasAssetFiveYearLookback,
    formId: "has-asset-five-year-lookback",
    title: "Five Year Lookback",
    type: AssetTypes.FiveYearLookback,
    application: fiveYearLookbackApplicationFormSchema,
    asset: fiveYearLookbackFormSchema,
  },
];

export default assetSchemas;
