import {
  ChecklistItemDocumentPageDTO,
  ChecklistItemDocumentsReadDTO,
} from "../../../components/Checklist/ChecklistSplit";
import {
  DocumentThumbnailActionTypes,
  DocumentThumbnailOperation,
} from "../../../components/Document/useDocumentEditor";
import { ChecklistItem, NewChecklistItem } from "../../../types/Checklist";
import { Document, DocumentVerifyDTO } from "../../../types/Document";
import baseApiClient from "../baseApiClient";

export interface DocumentThumbnailOperationRequest {
  actionType: DocumentThumbnailActionTypes;
  timestamp?: number;
  pageId?: number;
  data?: string;
}

class ChecklistApiClient {
  getChecklistItemById = async (id: string) => {
    return baseApiClient.get<ChecklistItem>(`checklist-item/${id}`);
  };

  getApplicationChecklist = async (applicationId: string) => {
    return baseApiClient.get<ChecklistItem[]>(
      `application/${applicationId}/checklist`
    );
  };

  createChecklistItem = async (checklistItem: Partial<NewChecklistItem>) => {
    return baseApiClient.post<ChecklistItem>(`checklist-item`, checklistItem);
  };

  getChecklistItemDocuments = async (id: string) => {
    return baseApiClient.get<ChecklistItemDocumentsReadDTO>(
      `checklist-item/${id}/documents`
    );
  };
  split = async (
    checklistItemId: string,
    documentName: string,
    documentPages: ChecklistItemDocumentPageDTO[],
    documentOperations: DocumentThumbnailOperation[],
    originChecklistItemId: string
  ) => {
    const documentOperationsRequest: DocumentThumbnailOperationRequest[] =
      documentOperations.map((documentOperation) => ({
        actionType: documentOperation.actionType,
        timestamp: documentOperation.timestamp,
        pageId: documentOperation.pageId,
        data: JSON.stringify(documentOperation.data),
      }));

    return baseApiClient.post<Document>(
      `checklist-item/${checklistItemId}/documents/`,
      {
        documentName: documentName,
        documentPages: documentPages,
        documentOperations: documentOperationsRequest,
      },
      { params: { originChecklistItemId } }
    );
  };

  updateChecklistItem = async (
    checklistItemId: string,
    checklistItem: Partial<ChecklistItem>
  ) => {
    return baseApiClient.put<ChecklistItem[]>(
      `checklist-item/${checklistItemId}`,
      checklistItem
    );
  };

  updateChecklistItemStatementsReceived = async (
    checklistItemId: string,
    documentId: string,
    statementsReceived: string[]
  ) => {
    return baseApiClient.put<ChecklistItem[]>(
      `checklist-item/${checklistItemId}/statements-received`,
      {
        documentId,
        statementsReceived,
      }
    );
  };
  verifyChecklistDocument = async (
    id: string,
    documentId: string,
    documentVerifyDTO: DocumentVerifyDTO
  ): Promise<Document> => {
    return baseApiClient.put(
      `checklist-item/${id}/document/${documentId}/verify`,
      documentVerifyDTO
    );
  };
  generateApplicationChecklist = async (applicationId: string) => {
    return baseApiClient.post(`application/${applicationId}/checklist`);
  };
}

export const checklistApiClient = new ChecklistApiClient();
