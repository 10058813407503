import { FormGroup, InputGroup, Spinner } from "@blueprintjs/core";
import { MenuItem2 } from "@blueprintjs/popover2";
import { Select2 } from "@blueprintjs/select";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usStateOptions } from "../../../constants/usStateOptions";
import { addressApiClient } from "../../../lib/apiClients/address/addressApiClient";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { SelectField } from "../SelectField/SelectField";
import { TextField } from "../TextField/TextField";
import {
  AddressFieldProps,
  AddressFieldValue,
  Option,
} from "../types/fieldTypes";
import "./styles.scss";
import { formatAddress } from "./utils";
import { useOverflowTooltip } from "../../Tooltip/useOverflowTooltip";
import { WithTooltip } from "../../Tooltip/Tooltip";

export const newAddressField: AddressFieldValue = {
  street: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  county: "",
};

const getAddress = async (searchAddress: AddressFieldValue) => {
  if (searchAddress && searchAddress.street.length > 2) {
    return addressApiClient.getAddressByQuery(searchAddress);
  }
};

const areAddressesSame = (
  localAddress: AddressFieldValue,
  listItem: AddressFieldValue
) => {
  if (
    localAddress.street === listItem.street &&
    localAddress.street2 === listItem.street2 &&
    localAddress.city === listItem.city &&
    localAddress.state === listItem.state
  ) {
    return true;
  }
  return false;
};

export const AddressField: React.FC<AddressFieldProps> = observer(
  ({
    label,
    description,
    value,
    onSubmit,
    disabled,
    hidden,
    errorMessages,
    isRequired,
  }) => {
    if (hidden) {
      return null;
    }

    const textInputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [localAddress, setLocalAddress] = useState<AddressFieldValue>(value);
    const [suggestions, setSuggestions] = useState<AddressFieldValue[]>([]);
    const [usStates] = useState<Option[]>(usStateOptions);
    const isTooltipVisible = useOverflowTooltip(
      textInputRef?.current,
      localAddress?.toString() || ""
    );

    useEffect(() => {
      !!value ? setLocalAddress(value) : setLocalAddress(newAddressField);
    }, [value]);

    const debouncedChangeHandler = useCallback(
      debounce((street) => {
        if (street.length > 3) {
          setIsLoading(true);
          addressApiClient
            .getAddressesByQuery({ street })
            .then(setSuggestions)
            .finally(() => setIsLoading(false));
        }
      }, 1000),
      []
    );

    return (
      <FormGroup>
        {label && (
          <SelectableLabel
            name={label}
            description={
              description ||
              "Please input at least 3 characters for address look up"
            }
          />
        )}
        <div className="address-field_input" id="address-street">
          <Select2<AddressFieldValue & { isLoading?: boolean }>
            items={
              isLoading
                ? [{ isLoading: true } as unknown as AddressFieldValue]
                : suggestions
            }
            filterable={false}
            itemDisabled={(item) => !!item.isLoading}
            popoverProps={{
              position: "bottom-left",
              minimal: true,
            }}
            itemRenderer={(item, itemProps) => {
              if (item.isLoading) {
                return <Spinner size={16} />;
              } else {
                return (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <MenuItem2
                    {...itemProps}
                    id="address_street_popover"
                    className="select__menu-item"
                    key={itemProps.index}
                    text={formatAddress(item)}
                    active={areAddressesSame(localAddress, item)}
                    onClick={itemProps.handleClick}
                    icon="map-marker"
                  />
                );
              }
            }}
            onItemSelect={async (item) => {
              const fullAddress = await getAddress(item);

              if (fullAddress) {
                setLocalAddress(fullAddress);
                onSubmit(fullAddress);
              }
            }}
            disabled={false}
            noResults={"Type Address to Search"}
          >
            <WithTooltip
              shouldShowTooltip={isTooltipVisible}
              content={(isTooltipVisible && localAddress?.toString()) || ""}
            >
              <InputGroup
                className={`address_street_field ${
                  isRequired && !localAddress?.street
                    ? "has-required-background"
                    : ""
                }`}
                value={localAddress?.street}
                disabled={disabled}
                placeholder={"Street"}
                onChange={(e) => {
                  const street = e.target.value;
                  setLocalAddress({ ...localAddress, street });
                  onSubmit({ ...localAddress, street });
                  debouncedChangeHandler(street);
                }}
              />
            </WithTooltip>
          </Select2>
        </div>
        <div className="address-field_input" id="additional-street">
          <TextField
            type="Text"
            label=""
            placeholder="Additional Street"
            value={localAddress?.street2 || ""}
            inputMode="text"
            disabled={disabled}
            onSubmit={(value) => {
              setLocalAddress({ ...localAddress, street2: value as string });
              onSubmit({ ...localAddress, street2: value as string });
            }}
          />
        </div>
        <div className="flex-field">
          <div className="address-field_input">
            <TextField
              type="Text"
              label=""
              placeholder="City"
              value={localAddress?.city}
              inputMode="text"
              disabled={disabled}
              width="20%"
              onSubmit={(value) => {
                setLocalAddress({ ...localAddress, city: value as string });
                onSubmit({ ...localAddress, city: value as string });
              }}
              isRequired={isRequired}
            />
          </div>
          <div className="address-field_input">
            <SelectField
              type="Select"
              placeholder="State"
              value={localAddress?.state}
              label=""
              optionValues={usStates}
              disabled={disabled}
              onSubmit={(value) => {
                const option = value as Option;
                setLocalAddress({
                  ...localAddress,
                  state: option.value as string,
                });
                onSubmit({
                  ...localAddress,
                  state: option.value as string,
                });
              }}
              isRequired={isRequired}
            />
          </div>
          <div className="address-field_input">
            <TextField
              type="Text"
              label=""
              value={localAddress?.zip}
              placeholder={"Zip Code"}
              inputMode="text"
              disabled={disabled}
              onSubmit={(value) => {
                setLocalAddress({ ...localAddress, zip: value as string });
                onSubmit({ ...localAddress, zip: value as string });
              }}
              isRequired={isRequired}
            />
          </div>
          <div className="address-field_input">
            <TextField
              type="Text"
              label=""
              value={localAddress?.county}
              placeholder={"County"}
              inputMode="text"
              disabled={disabled}
              onSubmit={(value) => {
                setLocalAddress({ ...localAddress, county: value as string });
                onSubmit({ ...localAddress, county: value as string });
              }}
            />
          </div>
        </div>
        {errorMessages && (
          <>
            {errorMessages.map((errorMessage, idx) => (
              <p key={idx} className="error-message">
                {errorMessage}
              </p>
            ))}
          </>
        )}
      </FormGroup>
    );
  }
);
