import { ColumnModel } from "@syncfusion/ej2-react-grids";
import GridColumn from "./GridColumn";

interface GridColoredColumnOptions<T> extends ColumnModel {
  color: string;
  fieldToColor: string;
  shouldUpdateFieldColor: (fieldValue: T) => boolean;
  formatField: (fieldValue: T) => React.ReactNode;
}

export default class GridColoredColumn<T> extends GridColumn {
  constructor(opts: GridColoredColumnOptions<T>) {
    const {
      color,
      fieldToColor,
      shouldUpdateFieldColor,
      formatField,
      ...superOpts
    } = opts;

    const coloredText = {
      color: color,
    } as const;

    super({
      ...superOpts,
      template: ((model: Record<string, T>) => {
        const fieldValue = model[fieldToColor];
        if (shouldUpdateFieldColor(fieldValue)) {
          return <div style={coloredText}>{formatField(fieldValue)}</div>;
        } else {
          return <div>{formatField(fieldValue)}</div>;
        }
      }) as any,
    });
  }
}
