import { Button, Classes, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import React from "react";
import { useMatchupFlyout } from "./useMatchupFlyout";
import Form from "../Forms";
import { leadEditFormSchema } from "../Forms/MatchupForm/LeadEditForm";
import { MatchupDocumentsCard } from "./MatchupDocumentsCard";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { Footer } from "@ucl/library";

export interface MatchupFlyoutProps {
  matchupId?: string;
  flyoutIsOpen: boolean;
  onClose?: () => void;
}

export const MatchupFlyout: React.FC<MatchupFlyoutProps> = (props) => {
  const { lead, saveLead, saveAndConvertLead, isSaving, isConverting } =
    useMatchupFlyout(props);

  return (
    <>
      <div className={Classes.DRAWER_HEADER}>
        <h3>Matchup</h3>
      </div>

      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <Observer>
            {() =>
              lead && (
                <>
                  <Form
                    formId="edit-lead-form"
                    value={lead}
                    formSchemaBuilder={leadEditFormSchema}
                    onFormSubmit={async (lead) => {
                      const clickedButtonId = document.activeElement?.id;
                      const isSaveAndConvert =
                        clickedButtonId === "lead-save-and-convert-button";

                      if (isSaveAndConvert) {
                        await saveAndConvertLead(lead);
                      } else {
                        await saveLead(lead);
                      }
                    }}
                  />
                  <div className="matchup-documents">
                    <MatchupDocumentsCard lead={lead} />
                  </div>
                </>
              )
            }
          </Observer>
        </div>
      </div>
      <Footer>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={props.onClose}
          />
          <FormSubmitButton
            buttonId="lead-save-button"
            isSaving={isSaving}
            formId="edit-lead-form"
            text="Save"
          />
          <FormSubmitButton
            buttonId="lead-save-and-convert-button"
            isSaving={isConverting}
            formId="edit-lead-form"
            text="Convert to Application"
          />
        </div>
      </Footer>
    </>
  );
};
