import { useEffect, useState } from "react";
import variables from "../../config/variables";
import { userStore } from "../../stores/UserStore";
import { SchedulerComponent } from "./SchedulerComponent";
import { ScheduleResource, SchedulerResourceConfig } from "./types/Resources";
import { SchedulerResources } from "./SchedulerResources";
import { User, UserRoles } from "../../types/User";
import { facilityApiClient } from "../../lib/apiClients/facility/facilityApiClient";
import { Facility } from "../../types/Facility";
import { getScheduleResourceColor } from "./utils/scheduleUtils";
import { ScheduleViews } from "./types/SchedulerViews";

export const FacilityScheduleView = () => {
  const [scheduleResourceData, setScheduleResourceData] = useState<
    ScheduleResource[]
  >([]);
  console.log(scheduleResourceData);
  const [ready, setReady] = useState(false);
  const [fieldRepFacilities, setFieldRepFacilities] = useState<
    Facility[] | undefined
  >(undefined);

  const getResourceConfig = (user: User) => {
    let resourceConfig: SchedulerResourceConfig[] = [];
    if (user.role === UserRoles.FieldRepresentative) {
      resourceConfig = [
        {
          field: "facilityId",
          title: "Facility",
          name: "Facilities",
          idField: "id",
          textField: "text",
          colorField: "color",
          dataSource: mapResourceItems(user),
        },
      ];
      return resourceConfig;
    }
    return resourceConfig;
  };

  const getResourceProps = (user: User, scheduleViewType: ScheduleViews) => {
    return {
      resources: mapResourceItems(user),
      setScheduleResourceData,
      header: "Facility Block Schedules",
      scheduleViewType: scheduleViewType,
      user: user,
    };
  };

  const mapResourceItems = (user: User) => {
    let resourceItems: ScheduleResource[] = [];
    if (user?.role === UserRoles.FieldRepresentative) {
      if (fieldRepFacilities) {
        resourceItems = fieldRepFacilities.map((facility, index) => {
          return {
            id: facility.id,
            text: facility.facilityName,
            color: getScheduleResourceColor(index),
          } as ScheduleResource;
        });
      }
    }

    return resourceItems;
  };

  useEffect(() => {
    if (userStore.user) {
      facilityApiClient
        .getFaciltiesByFieldRepId(userStore.user.id)
        .then((facilities: Facility[]) => {
          setFieldRepFacilities(facilities);
          userStore.user &&
            setScheduleResourceData(mapResourceItems(userStore.user));
        })
        .finally(() => setReady(true));
    }

    return () => {
      setFieldRepFacilities(undefined);
      setReady(false);
    };
  }, []);

  return (
    <div className="in-app-scheduler">
      <div className="resources-container">
        <h2 className="resources-title">Facilities</h2>
        {userStore.user && (
          <SchedulerResources
            {...getResourceProps(
              userStore.user,
              ScheduleViews.FacilitySchedule
            )}
          />
        )}
      </div>
      {userStore.user && fieldRepFacilities && ready && (
        <SchedulerComponent
          schedulerSetupSettings={{
            viewOptions: [{ view: "Day" }, { view: "Week" }],
            workHours: { start: "09:00", end: "17:00" },
            startHour: "09:00",
            endHour: "17:00",
            readOnly: false,
            odataUrl: `${variables.apiBaseUrl}odata/facilityblockscheduleodata`,
            user: userStore.user,
            fieldModel: {
              id: "id",
              subject: { name: "facility_Name" },
              location: { name: "availableTimeSlot_AsString" },
              startTime: { name: "startTime" },
              endTime: { name: "endTime" },
              //isReadonly: "isReadOnly",
            },
            resourceConfig: getResourceConfig(userStore.user),
            scheduleViewType: ScheduleViews.FacilitySchedule,
          }}
        />
      )}
    </div>
  );
};
