import { ExistingResource } from "../ExistingResource";

/**
 * Gets the default income owner based on the given conditions.
 * @param isMarried Whether the resident is married.
 * @param doesResidentHaveAnyIncome Whether the resident has any additional income.
 * @param doesSpouseHaveAnyIncome Whether the spouse has any additional income.
 * @returns {IncomeOwnerTypes | undefined} The default income owner based on the given conditions.
 */
export const getDefaultIncomeOwner = (
  isMarried: boolean,
  doesResidentHaveAnyIncome: boolean,
  doesSpouseHaveAnyIncome: boolean
) => {
  // If the resident is single, then the default should be the resident
  if (!isMarried) {
    return IncomeOwnerTypes.Resident;
  }

  // If only the spouse has additional income, the default should be the spouse
  if (!doesResidentHaveAnyIncome && doesSpouseHaveAnyIncome) {
    return IncomeOwnerTypes.Spouse;
  }

  // The default should be empty if there are no conditions met
  return undefined;
};

export interface NewIncome {
  incomeType?: number;
  description?: string;
  incomeOwner?: number;
  startDate?: string;
  amount?: number;
  payFrequency?: number;
  verified?: boolean;
  institutionId?: string;
  hasOtherInstitutionName?: boolean;
  otherInstitutionName?: string;
  statementDateRangeStart?: string;
  statementDates_AsList?: string[];
  statementFrequency?: number;
  aggregatedStatementsNeeded?: string[];
}

export type Income = ExistingResource<NewIncome>;

export enum IncomeOwnerTypes {
  Resident = 1,
  Joint = 2,
  Spouse = 3,
}
