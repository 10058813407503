import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { documentApiClient } from "../../../lib/apiClients/document/documentApiClient";
import { invoiceApiClient } from "../../../lib/apiClients/invoice/invoiceApiClient";
import { Invoice } from "../../../types/Invoice";
import { deep } from "../../../utils/clone";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import { downloadFile } from "../../Document/PdfDocuments";
import { gridStore } from "../../Grid/Grid/stores/GridStore";
import GridColumn from "../../Grid/Grid/types/GridColumn";
import GridMenuColumn from "../../Grid/Grid/types/GridMenuColumn";
import { AppToaster } from "../../Toast/Toast";
import { UIInvoiceDocument } from "../InvoiceDocumentHistoryGrid";

export const invoiceDocumentHistoryGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created At",
    width: 90,
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    width: 90,
    type: "string",
  }),
  new GridColumn({
    field: "fileName",
    headerText: "File Name",
    width: 90,
    type: "string",
  }),
  new GridColumn({
    field: "isCurrentInvoiceDocument",
    headerText: "Active",
    type: "boolean",
    width: 90,
    displayAsCheckBox: true,
  }),
  new GridMenuColumn<UIInvoiceDocument>({
    headerText: "Actions",
    clipMode: "Clip",
    width: 90,
    menus: [
      {
        text: "View",
        icon: IconNames.DOWNLOAD,
        onMenuClick: async (document) => {
          const response = (await documentApiClient.downloadDocument(
            document.id
          )) as BlobPart;
          downloadFile(response, document.originalFileName);
        },
      },
    ],
  }),
];

export const invoiceGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    type: "checkbox",
    width: 50,
    field: "Select",
    allowSorting: false,
    allowFiltering: false,
  }),
  new GridColumn({
    field: "createdAt_AsDateOnly",
    headerText: "Created At",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
  new GridColumn({
    /**
     * The bulk row selection feature requires a primary key field to be defined.
     * This field is used to uniquely identify each row in the grid so that when you sort/filter
     * the grid, it knows if the rows that were previously selected are still in the current grid view.
     */
    isPrimaryKey: true,
    field: "id",
    headerText: "Invoice Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "documentId",
    headerText: "Document Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "corporateId",
    headerText: "Corporate Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "companyName",
    headerText: "Corporate",
    type: "string",
  }),
  new GridColumn({
    field: "invoiceNumber_AsString",
    headerText: "Invoice Number",
    type: "string",
  }),
  new GridColumn({
    field: "amount",
    headerText: "Invoice Amount",
    type: "number",
    format: "C",
  }),
  new GridColumn({
    field: "dateSent_AsDateOnly",
    headerText: "Invoice Sent Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
    allowSearching: false,
  }),
];

export const setupActions = (
  columnsConfiguration: GridColumn[],
  canViewInvoiceDocument: boolean,
  canSendInvoice: boolean,
  canRegenerateInvoiceDocument: boolean
): GridColumn[] => {
  const config = deep<GridColumn[]>(columnsConfiguration);
  config.push(
    new GridMenuColumn<Invoice>({
      headerText: "Actions",
      clipMode: "Clip",
      width: 90,
      menus: [
        {
          text: "View",
          icon: IconNames.DOWNLOAD,
          disabled: !canViewInvoiceDocument,
          onMenuClick: async (invoice) => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Downloading in Progress...</h3>Invoice is downloading...
                </div>
              ),
              intent: Intent.WARNING,
            });
            await documentApiClient
              .downloadDocument(invoice.documentId)
              .then((response) => {
                const reponseAsBlob = response as BlobPart;
                downloadFile(reponseAsBlob, invoice.documentOriginalFileName);
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Success!</h3>Invoice download is finished
                    </div>
                  ),
                  intent: Intent.SUCCESS,
                });
              })
              .catch(() => {
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Failed</h3>Invoice download has failed
                    </div>
                  ),
                  intent: Intent.DANGER,
                });
              });
          },
        },
        {
          text: "Send",
          icon: IconNames.ENVELOPE,
          disabled: !canSendInvoice,
          onMenuClick: async (invoice) => {
            await invoiceApiClient.sendInvoice(invoice.id);
            AppToaster.show({
              message: (
                <div>
                  <h3>Success!</h3>Invoice has been emailed
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            gridStore.refresh();
          },
        },
        {
          text: "Regenerate",
          icon: IconNames.REPEAT,
          disabled: !canRegenerateInvoiceDocument,
          onMenuClick: async (invoice) => {
            AppToaster.show({
              message: (
                <div>
                  <h3>Regeneration in Progress...</h3>Invoice is regenerating...
                </div>
              ),
              intent: Intent.WARNING,
            });
            await invoiceApiClient
              .recreateInvoice(invoice.id)
              .then(() => {
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Success!</h3>Invoice regeneration is finished
                    </div>
                  ),
                  intent: Intent.SUCCESS,
                });
              })
              .catch(() => {
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Failed</h3>Invoice regeneration has failed
                    </div>
                  ),
                  intent: Intent.DANGER,
                });
              });
            gridStore.refresh();
          },
        },
        {
          text: "History",
          icon: IconNames.HISTORY,
          onMenuClick: (invoice) => {
            dialogsViewerStore.setIsInvoiceDocumentHistoryDialogOpen(true, {
              invoiceId: invoice.id,
              invoiceDocumentId: invoice.documentId,
            });
          },
        },
      ],
    })
  );

  return config;
};
