import { Classes, Icon, Tag } from "@blueprintjs/core";
import { Application, ApplicationBillAmount } from "../../types/Application";
import { WithTooltip } from "../Tooltip/Tooltip";
import { IconNames } from "@blueprintjs/icons";
import {
  differenceInDaysFromToday,
  formatDateToMMddYYYY,
  getTodaysDateOnly_UTC,
} from "../../utils/date";
import { usStateOptions } from "../../constants/usStateOptions";
import { ApplicationSummaryResponseDTO } from "../../types/Application/ApplicationSummary";

export interface FinancialTooltipItem {
  name: string | string[];
  value: number;
  colIndex?: number;
  isTotal?: boolean;
  isAnnotation?: boolean;
}

export interface NameStringValuePair {
  name: string | string[];
  value: string;
}

export interface DateTimelineTooltipItem {
  name: string | string[];
  description: string;
  value: string;
}

export const currency = (value: number) => <>${value.toFixed(2)}</>;

export const spendDownIconColor = "#E42A49";
export const labelWithIcon = (
  name: string,
  content: JSX.Element | string,
  iconName?: JSX.Element
) => (
  <div className="eligibility-summary-label">
    <span className="m-r-sm">{name}</span>
    <WithTooltip
      content={content}
      shouldShowTooltip={true}
      className={Classes.FOCUS_DISABLED}
    >
      <Icon
        size={15}
        icon={iconName ? iconName : IconNames.TimelineLineChart}
        color="#4d85eb"
      />
    </WithTooltip>
  </div>
);

export const tooltip = (
  rows: FinancialTooltipItem[],
  totalRowValue: number | undefined,
  headerLabel = "",
  totalRowLabel = "Total"
) => (
  <>
    <div className="m-r-sm">
      <b>{headerLabel}</b>
    </div>
    {rows.map((r, i) => (
      <>
        <div className="eligibility-summary-tooltip-item" key={i}>
          <div className="m-r-sm">
            {Array.isArray(r.name)
              ? r.name.map((line, j) => <div key={j}>{line}</div>)
              : r.name}
          </div>
          <div>{currency(r.value)}</div>
        </div>
      </>
    ))}
    <div className="eligibility-summary-tooltip-total">
      <div className="m-r-sm">
        <b>{totalRowLabel}</b>
      </div>
      <div>
        <b>{currency(totalRowValue || 0)}</b>
      </div>
    </div>
  </>
);

export const expandedTooltip = (
  rows: FinancialTooltipItem[],
  totalRowLabel = "Total"
) => {
  const distinctColIndexes = new Set();
  // Loop through the array and add colIndex values to the Set
  for (const row of rows) {
    distinctColIndexes.add(row.colIndex);
  }
  // Convert the Set to an array to get distinct values
  const distinctColIndexArray = Array.from(distinctColIndexes);

  return (
    <>
      {distinctColIndexArray.length > 1 &&
        distinctColIndexArray.map((colIndex, i) => (
          <div className="eligibility-summary-tooltip-column" key={i}>
            <div className="border">
              {" "}
              <div className="m-r-sm">
                {colIndex === 0 && <b>Resident</b>}
                {colIndex === 1 && <b>Joint</b>}
                {colIndex === 2 && <b>Spouse</b>}
                {colIndex === 3 && <b>Combined</b>}
              </div>
              {rows
                .filter(
                  (r) =>
                    r.colIndex === colIndex && !r.isTotal && !r.isAnnotation
                )
                .map((r, i) => (
                  <div className="eligibility-summary-tooltip-item" key={i}>
                    <div className="m-r-sm">
                      {Array.isArray(r.name)
                        ? r.name.map((line, j) => <div key={j}>{line}</div>)
                        : r.name}
                    </div>
                    <div>{currency(r.value)}</div>
                  </div>
                ))}
              <div className="eligibility-summary-tooltip-total">
                <div className="m-r-sm">
                  <b>{totalRowLabel}</b>
                </div>
                <div>
                  <b>
                    {currency(
                      rows.find((r) => r.colIndex === colIndex && r.isTotal)
                        ?.value || 0
                    )}
                  </b>
                </div>
              </div>
            </div>
            <div className="eligibility-summary-tooltip-annotation">
              <div className="m-r-sm">
                {
                  rows.find((r) => r.colIndex === colIndex && r.isAnnotation)
                    ?.name
                }
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export const progressTooltip = (outstandingItems: NameStringValuePair[]) => (
  <>
    {outstandingItems.length === 0 && (
      <div className="eligibility-summary-tooltip-item">
        No Outstanding Items
      </div>
    )}
    {outstandingItems.length > 0 &&
      outstandingItems.map((item: NameStringValuePair, index: number) => (
        <div className="eligibility-summary-tooltip-item" key={index}>
          <div className="m-r-sm">{item.name}</div>
          <div>{item.value}</div>
        </div>
      ))}
  </>
);

export const dateTooltip = (outstandingItems: DateTimelineTooltipItem[]) => (
  <>
    {outstandingItems.length <= 0 ||
      (outstandingItems.filter(
        (item) => new Date(item.value) >= getTodaysDateOnly_UTC()
      ).length <= 0 && (
        <div className="eligibility-summary-tooltip-item">
          No Upcoming Dates
        </div>
      ))}
    {outstandingItems.length > 0 &&
      outstandingItems
        .filter(
          (item) =>
            item.value !== "" && new Date(item.value) >= getTodaysDateOnly_UTC()
        )
        .sort((a, b) => +new Date(a.value) - +new Date(b.value))
        .map((item: DateTimelineTooltipItem, index: number) => (
          <div className="eligibility-summary-tooltip-item" key={index}>
            <div className="date-content">
              <div className="m-r-sm">
                {item.name}
                {item.description !== "" && ` - ${item.description}`}
              </div>
              <div className="date-value">
                <span>{item.value}</span>
                <span className="date-tag">
                  {differenceInDaysFromToday(new Date(item.value)) <= 0 && (
                    <Tag intent="danger">Today</Tag>
                  )}
                  {differenceInDaysFromToday(new Date(item.value)) > 0 &&
                    differenceInDaysFromToday(new Date(item.value)) <= 5 && (
                      <Tag intent="warning">
                        {`In ${differenceInDaysFromToday(
                          new Date(item.value)
                        )} Days`}
                      </Tag>
                    )}
                  {differenceInDaysFromToday(new Date(item.value)) > 5 && (
                    <Tag intent="primary">
                      {`In ${differenceInDaysFromToday(
                        new Date(item.value)
                      )} Days`}
                    </Tag>
                  )}
                </span>
              </div>
            </div>
          </div>
        ))}
  </>
);

export const assetItems = (
  application?: Application
): FinancialTooltipItem[] => [
  {
    name: "Property",
    value: application?.combinedAssetPropertyValue || 0,
  },
  {
    name: "Vehicles",
    value: application?.combinedAssetVehicleValue || 0,
  },
  {
    name: "Bank Accounts",
    value: application?.combinedAssetBankAccountValue || 0,
  },
  {
    name: "Life Insurance",
    value: application?.combinedAssetLifeInsuranceValue || 0,
  },
  {
    name: "Burial/Preneed Funeral",
    value: application?.combinedAssetBurialPreneedValue || 0,
  },
  {
    name: "Other Assets",
    value: application?.combinedAssetOtherValue || 0,
  },
];

export const expandedAssetItems = (
  application?: Application | ApplicationSummaryResponseDTO
): FinancialTooltipItem[] => {
  const expandedAssetItems = [
    {
      name: "Property",
      value: application?.residentAssetPropertyValue || 0,
      colIndex: 0,
    },
    {
      name: "Vehicles",
      value: application?.residentAssetVehicleValue || 0,
      colIndex: 0,
    },
    {
      name: "Bank Accounts",
      value: application?.residentAssetBankAccountValue || 0,
      colIndex: 0,
    },
    {
      name: "Life Insurance",
      value: application?.residentAssetLifeInsuranceValue || 0,
      colIndex: 0,
    },
    {
      name: "Burial/Preneed Funeral",
      value: application?.residentAssetBurialPreneedValue || 0,
      colIndex: 0,
    },
    {
      name: "Other Assets",
      value: application?.residentAssetOtherValue || 0,
      colIndex: 0,
    },
    {
      name: "Total",
      value: application?.residentAssetItemsTotalValue || 0,
      colIndex: 0,
      isTotal: true,
    },
    {
      name: "Property",
      value: application?.jointTypeAssetPropertyValue || 0,
      colIndex: 1,
    },
    {
      name: "Vehicles",
      value: application?.jointTypeAssetVehicleValue || 0,
      colIndex: 1,
    },
    {
      name: "Bank Accounts",
      value: application?.jointTypeAssetBankAccountValue || 0,
      colIndex: 1,
    },
    {
      name: "Life Insurance",
      value: application?.jointTypeAssetLifeInsuranceValue || 0,
      colIndex: 1,
    },
    {
      name: "Burial/Preneed Funeral",
      value: application?.jointTypeAssetBurialPreneedValue || 0,
      colIndex: 1,
    },
    {
      name: "Other Assets",
      value: application?.jointTypeAssetOtherValue || 0,
      colIndex: 1,
    },
    {
      name: "Total",
      value: application?.jointTypeAssetItemsTotalValue || 0,
      colIndex: 1,
      isTotal: true,
    },
    {
      name: "Property",
      value: application?.spouseAssetPropertyValue || 0,
      colIndex: 2,
    },
    {
      name: "Vehicles",
      value: application?.spouseAssetVehicleValue || 0,
      colIndex: 2,
    },
    {
      name: "Bank Accounts",
      value: application?.spouseAssetBankAccountValue || 0,
      colIndex: 2,
    },
    {
      name: "Life Insurance",
      value: application?.spouseAssetLifeInsuranceValue || 0,
      colIndex: 2,
    },
    {
      name: "Burial/Preneed Funeral",
      value: application?.spouseAssetBurialPreneedValue || 0,
      colIndex: 2,
    },
    {
      name: "Other Assets",
      value: application?.spouseAssetOtherValue || 0,
      colIndex: 2,
    },
    {
      name: "Total",
      value: application?.spouseAssetItemsTotalValue || 0,
      colIndex: 2,
      isTotal: true,
    },
    {
      name: "Property",
      value: application?.combinedAssetPropertyValue || 0,
      colIndex: 3,
    },
    {
      name: "Vehicles",
      value: application?.combinedAssetVehicleValue || 0,
      colIndex: 3,
    },
    {
      name: "Bank Accounts",
      value: application?.combinedAssetBankAccountValue || 0,
      colIndex: 3,
    },
    {
      name: "Life Insurance",
      value: application?.combinedAssetLifeInsuranceValue || 0,
      colIndex: 3,
    },
    {
      name: "Burial/Preneed Funeral",
      value: application?.combinedAssetBurialPreneedValue || 0,
      colIndex: 3,
    },
    {
      name: "Other Assets",
      value: application?.combinedAssetOtherValue || 0,
      colIndex: 3,
    },
    {
      name: "Total",
      value: application?.combinedAssetItemsTotal || 0,
      colIndex: 3,
      isTotal: true,
      isAnnotation: false,
    },
  ];

  if (application?.useResidentPlusHalfJointAssetsCalculation) {
    expandedAssetItems.push({
      name: "Please note: 50% of all joint resources are countable assets.",
      value: 0,
      colIndex: 1,
      isTotal: false,
      isAnnotation: true,
    });
  }
  return expandedAssetItems;
};

export const incomeItems = (
  application?: Application
): FinancialTooltipItem[] => [
  {
    name: "Social Security",
    value: application?.combinedSocialSecurityAmount || 0,
  },
  { name: "SSI", value: application?.combinedSSIAmount || 0 },
  { name: "VA Benefits", value: application?.combinedVABenefitsAmount || 0 },
  {
    name: "Additional Income",
    value: application?.combinedAdditionalIncomeAmount || 0,
  },
];

export const expandedIncomeItems = (
  application?: Application | ApplicationSummaryResponseDTO
): FinancialTooltipItem[] => [
  //Resident
  {
    name: "Social Security",
    value: application?.socialSecurityAmount || 0,
    colIndex: 0,
    isTotal: false,
  },
  { name: "SSI", value: application?.ssiAmount || 0, colIndex: 0 },
  {
    name: "VA Benefits",
    value: application?.vaBenefitsAmount || 0,
    colIndex: 0,
    isTotal: false,
  },
  {
    name: "Additional Income",
    value: application?.residentMonthlyIncomeValue || 0,
    colIndex: 0,
    isTotal: false,
  },
  {
    name: "Resident Total",
    value: application?.residentIncomeItemsTotal || 0,
    colIndex: 0,
    isTotal: true,
  },
  //Joint
  {
    name: "N/A",
    value: 0,
    colIndex: 1,
    isTotal: false,
  },
  { name: "N/A", value: 0, colIndex: 1 },
  {
    name: "N/A",
    value: 0,
    colIndex: 1,
    isTotal: false,
  },
  {
    name: "Additional Income",
    value: application?.jointMonthlyIncomeValue || 0,
    colIndex: 1,
    isTotal: false,
  },
  {
    name: "Joint Total",
    value: application?.jointMonthlyIncomeValue || 0,
    colIndex: 1,
    isTotal: true,
  },
  //Spouse
  {
    name: "Social Security",
    value: application?.spouseSocialSecurityAmount || 0,
    colIndex: 2,
    isTotal: false,
  },
  { name: "SSI", value: application?.spouseSSIAmount || 0, colIndex: 2 },
  {
    name: "VA Benefits",
    value: application?.spouseVABenefitsAmount || 0,
    colIndex: 2,
    isTotal: false,
  },
  {
    name: "Additional Income",
    value: application?.spouseMonthlyIncomeValue || 0,
    colIndex: 2,
    isTotal: false,
  },
  {
    name: "Spouse Total",
    value: application?.spouseIncomeItemsTotal || 0,
    colIndex: 2,
    isTotal: true,
  },
  // Total
  {
    name: "Social Security",
    value: application?.combinedSocialSecurityAmount || 0,
    colIndex: 3,
    isTotal: false,
  },
  { name: "SSI", value: application?.combinedSSIAmount || 0, colIndex: 3 },
  {
    name: "VA Benefits",
    value: application?.combinedVABenefitsAmount || 0,
    colIndex: 3,
    isTotal: false,
  },
  {
    name: "Additional Income",
    value: application?.combinedAdditionalIncomeAmount || 0,
    colIndex: 3,
    isTotal: false,
  },
  {
    name: "Combined Total",
    value: application?.combinedIncomeItemsTotal || 0,
    colIndex: 3,
    isTotal: true,
  },
];

export const billItems = (application?: Application): FinancialTooltipItem[] =>
  application?.billAmounts.map((bill: ApplicationBillAmount) => {
    return {
      name: bill.type_AsString,
      value: bill.amount || 0,
    };
  }) || [];

export const estPatientLiabilityItems = (
  application?: Application
): FinancialTooltipItem[] => [
  {
    name: "Total Monthly Income",
    value: application?.combinedIncomeItemsTotal || 0,
  },
  {
    name: "Medical Coverage Premiums",
    value: -(application?.medicalCoveragePremiums || 0),
  },
  {
    name: application?.isFacilityStateFL
      ? [
          "State's Personal Care Allowance",
          "*Effective July 1st 2023, PNA increased from $130 to $160",
        ]
      : "State's Personal Care Allowance",
    value: -(application?.statesPersonCareAllowance ?? 0),
  },
];

export const applyByDateTimelineItem = (
  application?: Application | ApplicationSummaryResponseDTO
): DateTimelineTooltipItem[] => [
  {
    name: "Deadline to Apply",
    description: "",
    value: formatDateToMMddYYYY(application?.applyDeadlineDate || ""),
  },
];

export const dateTimelineItems = (
  application?: Application | ApplicationSummaryResponseDTO
): DateTimelineTooltipItem[] => {
  const timelineItems = [
    {
      name: "Last Applied Date",
      description: "",
      value: formatDateToMMddYYYY(application?.lastAppliedDate || ""),
    },
    {
      name: "Due Date",
      description:
        (application?.facility &&
          dueDateDescriptionHelper(
            application.facility.facilityAddress.state.toString()
          )) ||
        "",
      value: formatDateToMMddYYYY(application?.dueDate || ""),
    },
    {
      name: "Hard Deadline",
      description: "Final date to send all required verifications to State",
      value: formatDateToMMddYYYY(application?.hardDeadlineDate || ""),
    },
    {
      name: "Extension Request Deadline",
      description: "",
      value: formatDateToMMddYYYY(
        application?.extensionRequestDeadlineDate || ""
      ),
    },
    {
      name: "Extended Deadline",
      description: "",
      value: formatDateToMMddYYYY(application?.extendedDeadlineDate || ""),
    },
    {
      name: "Appeal Deadline",
      description: "",
      value: formatDateToMMddYYYY(application?.appealDeadlineDate || ""),
    },
    {
      name: "Hearing Date",
      description: "",
      value: formatDateToMMddYYYY(application?.hearingDate || ""),
    },

    // Remove these dates from timeline for now
    // {
    //   name: "QC Audit Date",
    //   description: "",
    //   value: formatDateToMMddYYYY(application?.qcAuditDate || ""),
    // },
    // {
    //   name: "Post Approval Date",
    //   description: "",
    //   value: formatDateToMMddYYYY(application?.postApprovalDate || ""),
    // },
  ];

  if (application?.dateTimelineShouldIncludeApplyDeadlineDate) {
    timelineItems.push({
      name: "Deadline to Apply",
      description: "",
      value: formatDateToMMddYYYY(application?.applyDeadlineDate || ""),
    });
  }
  if (application?.dateTimelineShouldIncludeReapplyDeadlineDate) {
    timelineItems.push({
      name: "Deadline to Re-Apply",
      description: "",
      value: formatDateToMMddYYYY(application?.reapplyDeadlineDate || ""),
    });
  }
  if (application?.dateTimelineShouldIncludeInterviewDate) {
    timelineItems.push({
      name: "Interview Date",
      description: "",
      value: formatDateToMMddYYYY(application?.interviewDate || ""),
    });
  }
  if (application?.dateTimelineShouldIncludeInterviewDeadlineDate) {
    timelineItems.push({
      name: "Interview Deadline",
      description: "Date the interview must be scheduled and held by",
      value: formatDateToMMddYYYY(application?.interviewDeadlineDate || ""),
    });
  }

  return timelineItems;
};

export const dueDateDescriptionHelper = (facilityState: string) => {
  switch (facilityState) {
    case usStateOptions.find((state) => state.label === "TX")?.value:
      return "Submit information requested on H1020 by this date";
      break;
    case usStateOptions.find((state) => state.label === "NC")?.value:
      return "Submit information requested on 5097 by this date";
      break;
    case usStateOptions.find((state) => state.label === "MS")?.value:
      return "Submit information requested on DOM 307/309 by this date";
      break;
    case usStateOptions.find((state) => state.label === "CT")?.value:
      return "Submit information requested on VCL by this date";
      break;
    case usStateOptions.find((state) => state.label === "MA")?.value:
      return "Submit information requested on VCL by this date";
      break;
    case usStateOptions.find((state) => state.label === "IL")?.value:
      return "Submit information requested on VCL by this date";
      break;
    case usStateOptions.find((state) => state.label === "FL")?.value:
      return "Submit information requested on NOCA by this date";
      break;
    case usStateOptions.find((state) => state.label === "GA")?.value:
      return "Submit information requested on State Checklist by this date";
      break;
    case usStateOptions.find((state) => state.label === "VA")?.value:
      return "Submit information requested on State Checklist by this date";
      break;
    case usStateOptions.find((state) => state.label === "IN")?.value:
      return "Submit information requested on DFR-2032 by this date";
      break;
    case usStateOptions.find((state) => state.label === "KY")?.value:
      return "Submit information requested on RFI by this date";
      break;
    case usStateOptions.find((state) => state.label === "MA")?.value:
      return "Submit information requested on RFI by this date";
      break;
    case usStateOptions.find((state) => state.label === "MI")?.value:
      return "Submit information requested on RFI by this date";
      break;
    case usStateOptions.find((state) => state.label === "OH")?.value:
      return "Submit information requested on RFI by this date";
      break;
    case usStateOptions.find((state) => state.label === "TN")?.value:
      return "Submit information requested on RFI by this date";
      break;
    case usStateOptions.find((state) => state.label === "PA")?.value:
      return "Submit information requested on PA 253 by this date";
      break;
    default:
      return "";
  }
};
