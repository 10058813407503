import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { Institution } from "../../../types/Institution";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newFormInstitution: Institution = {
  name: "",
  type: 0,
  fee: undefined,
  institutionFeeFrequencyType: 0,
  isSpecialized: false,
  specializationCategory: [],
  larryLetterType: 0,
  batchSendDays: [],
  websiteAddress: "",
  emailAddress: "",
  phoneNumber: "",
  faxNumber: "",
  mailingAddress: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
  receivingContactMethods: [],
  sendingContactMethod: 0,
  emailAttentionTo: "",
  faxAttentionTo: "",
  mailAttentionTo: "",
  emailSLAInDays: "",
  faxSLAInDays: "",
  mailSLAInDays: "",
  automaticResendDelayInDays: "",
  displayNotes: "",
  notes: "",
  requireGovernmentId: false,
  isActive: true,
  contacts: [],
  requiresStatementsNeeded: false,
};

export const institutionFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId
) => {
  const modelName = "institution";
  const institution = form as Institution;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "type"),
      value: institution.type,
      errorMessages: get(errors, "type"),
      onSubmit: ({ value }: Option) => {
        onValueChange({ type: value });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "name"),
      value: institution.name,
      errorMessages: get(errors, "name"),
      width: "70%",
      onSubmit: (name) => {
        onValueChange({ name });
      },
      isRequired: true,
    } as AllFields,
    {
      type: "Checkbox-Bool",
      label: "Specialized",
      value: institution.isSpecialized,
      width: "25%",
      onSubmit: (isSpecialized) => {
        onValueChange({ isSpecialized });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "specializationCategory"),
      value: institution.specializationCategory,
      errorMessages: get(errors, "specializationCategory"),
      onSubmit: (specializationCategory) => {
        onValueChange({ specializationCategory });
      },
      isRequired: true,
      hidden: !institution.isSpecialized,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "larryLetterType"),
      value: institution.larryLetterType,
      errorMessages: get(errors, "larryLetterType"),
      onSubmit: ({ value }: Option) => {
        onValueChange({ larryLetterType: value });
      },
      isRequired: true,
      hidden: !(
        institution.specializationCategory.includes(3) &&
        institution.isSpecialized
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "batchSendDays"),
      value: institution.batchSendDays,
      errorMessages: get(errors, "batchSendDays"),
      onSubmit: (batchSendDays) => {
        onValueChange({ batchSendDays });
      },
      isRequired: true,
      hidden: !(
        institution.specializationCategory.includes(7) &&
        institution.isSpecialized
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneNumber"),
      value: institution.phoneNumber,
      errorMessages: get(errors, "phoneNumber"),
      width: "45%",
      onSubmit: (phoneNumber) => {
        onValueChange({ phoneNumber });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "faxNumber"),
      value: institution.faxNumber,
      errorMessages: get(errors, "faxNumber"),
      width: "45%",
      isRequired: institution.sendingContactMethod === 2,
      onSubmit: (faxNumber) => {
        onValueChange({ faxNumber });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "websiteAddress"),
      value: institution.websiteAddress,
      errorMessages: get(errors, "websiteAddress"),
      onSubmit: (websiteAddress) => {
        onValueChange({ websiteAddress });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "emailAddress"),
      value: institution.emailAddress,
      errorMessages: get(errors, "emailAddress"),
      onSubmit: (emailAddress) => {
        onValueChange({ emailAddress });
      },
      formId: formId,
      isRequired: institution.sendingContactMethod === 1,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "mailingAddress"),
      value: institution.mailingAddress,
      onSubmit: (mailingAddress) => {
        onValueChange({ mailingAddress });
      },
      isRequired: institution.sendingContactMethod === 3,
      errorMessages: [
        ...(get(errors, "mailingAddress.Street") || []),
        ...(get(errors, "mailingAddress.City") || []),
        ...(get(errors, "mailingAddress.State") || []),
        ...(get(errors, "mailingAddress.Zip") || []),
        ...(get(errors, "mailingAddress.County") || []),
      ],
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fee"),
      value: institution.fee,
      errorMessages: get(errors, "fee"),
      onSubmit: (fee) => {
        onValueChange({ fee });
      },
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "institutionFeeFrequencyType"
      ),
      value: institution.institutionFeeFrequencyType,
      errorMessages: get(errors, "institutionFeeFrequencyType"),
      onSubmit: ({ value }: Option) => {
        onValueChange({ institutionFeeFrequencyType: value });
      },
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "receivingContactMethods"),
      onSubmit: (receivingContactMethods) => {
        onValueChange({ receivingContactMethods });
      },
      value: institution.receivingContactMethods,
      isRequired: true,
      errorMessages: get(errors, "receivingContactMethods"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isPolicyOrAccountNumberRequiredForFIARequest"
      ),
      onSubmit: (isPolicyOrAccountNumberRequiredForFIARequest) => {
        onValueChange({ isPolicyOrAccountNumberRequiredForFIARequest });
      },
      value: institution.isPolicyOrAccountNumberRequiredForFIARequest,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "requireGovernmentId"),
      value: institution.requireGovernmentId,
      errorMessages: get(errors, "requireGovernmentId"),
      onSubmit: (requireGovernmentId) => {
        onValueChange({ requireGovernmentId });
      },
      isRequired: false,
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "displayNotes"),
      onSubmit: (displayNotes) => {
        onValueChange({ displayNotes });
      },
      value: institution.displayNotes,
      errorMessages: get(errors, "displayNotes"),
      maxLength: 255,
    } as AllFields,

    {
      ...schemaStore.getFieldSchemaByName(modelName, "notes"),
      onSubmit: (notes) => {
        onValueChange({ notes });
      },
      value: institution.notes,
      errorMessages: get(errors, "notes"),
    } as AllFields,
    {
      type: "Checkbox-Bool",
      label: "Is Active",
      value: institution.isActive,
      errorMessages: get(errors, "isActive"),
      onSubmit: (isActive) => {
        onValueChange({ isActive });
      },
    } as AllFields,
    { type: "Section", label: "Sending Methods" },
    {
      ...schemaStore.getFieldSchemaByName(modelName, "sendingContactMethod"),
      value: institution.sendingContactMethod,
      errorMessages: get(errors, "sendingContactMethod"),
      onSubmit: ({ value }: Option) => {
        onValueChange({ sendingContactMethod: value });
      },
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "emailAttentionTo"),
      value: institution.emailAttentionTo,
      errorMessages: get(errors, "emailAttentionTo"),
      width: "45%",
      onSubmit: (emailAttentionTo) => {
        onValueChange({ emailAttentionTo });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "emailSLAInDays"),
      value: institution.emailSLAInDays,
      errorMessages: get(errors, "emailSLAInDays"),
      width: "45%",
      onSubmit: (emailSLAInDays) => {
        onValueChange({ emailSLAInDays });
      },
      isRequired: institution.sendingContactMethod === 1,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "faxAttentionTo"),
      value: institution.faxAttentionTo,
      errorMessages: get(errors, "faxAttentionTo"),
      width: "45%",
      onSubmit: (faxAttentionTo) => {
        onValueChange({ faxAttentionTo });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "faxSLAInDays"),
      value: institution.faxSLAInDays,
      errorMessages: get(errors, "faxSLAInDays"),
      width: "45%",
      onSubmit: (faxSLAInDays) => {
        onValueChange({ faxSLAInDays });
      },
      isRequired: institution.sendingContactMethod === 2,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "mailAttentionTo"),
      value: institution.mailAttentionTo,
      errorMessages: get(errors, "mailAttentionTo"),
      width: "45%",
      onSubmit: (mailAttentionTo) => {
        onValueChange({ mailAttentionTo });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "mailSLAInDays"),
      value: institution.mailSLAInDays,
      errorMessages: get(errors, "mailSLAInDays"),
      width: "45%",
      onSubmit: (mailSLAInDays) => {
        onValueChange({ mailSLAInDays });
      },
      isRequired: institution.sendingContactMethod === 3,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "automaticResendDelayInDays"
      ),
      value: institution.automaticResendDelayInDays,
      minValue: 0,
      errorMessages: get(errors, "automaticResendDelayInDays"),
      width: "45%",
      onSubmit: (automaticResendDelayInDays) => {
        onValueChange({ automaticResendDelayInDays });
      },
    } as AllFields,
  ];
};
