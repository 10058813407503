import { ThumbnailImagesData } from "../../../components/Document/PdfDocumentStore";
import { PdfDocumentEntityType } from "../../../components/Document/PdfDocumentUploader";
import { DocumentThumbnailOperation } from "../../../components/Document/useDocumentEditor";
import { ChecklistItem } from "../../../types/Checklist";
import { ChecklistDocumentDTO } from "../../../types/ChecklistDocumentDTO";
import { Document } from "../../../types/Document";
import baseApiClient from "../baseApiClient";

class DocumentApiClient {
  fetchDocumentsByIds = async (documentIds: string[]) => {
    return baseApiClient.post<Document[]>(`document/fetch`, {
      documentIds,
    });
  };

  getDocumentThumbnails = async (documentId: string) => {
    return baseApiClient.get<ThumbnailImagesData[]>(
      `document/${documentId}/thumbnails`
    );
  };

  getDocument = async (documentId: string) =>
    baseApiClient.get<Document>(`document/${documentId}`);

  getDocumentsByEntity = async (
    entityType: PdfDocumentEntityType,
    entityId: string
  ) => baseApiClient.get<Document[]>(`document/${entityType}/${entityId}`);

  getApplicationChecklistDocuments = async (
    applicationId: string,
    getChecklistDocsFromRelatedApps: boolean
  ) => {
    return await baseApiClient
      .get<ChecklistDocumentDTO[]>(
        `application/${applicationId}/checklist-documents`,
        { params: { getChecklistDocsFromRelatedApps } }
      )
      .then((docs) =>
        docs.map((doc) => ({
          ...doc,
          uploadedDate: new Date(doc.uploadedDate),
        }))
      );
  };

  previewImageDocumentById = async (documentId: string) => {
    return baseApiClient.get<string>(`document/${documentId}/preview/`);
  };

  downloadDocument = async (documentId: string): Promise<BlobPart> => {
    return baseApiClient.download(`document/download/${documentId}`);
  };

  reuploadChecklistItemDocument = async (
    documentId: string,
    checklistItem: Partial<ChecklistItem>
  ) => {
    return baseApiClient.post<ChecklistItem[]>(
      `document/${documentId}/reupload`,
      checklistItem
    );
  };

  getStampedDocument = async (
    documentId: string,
    applicationId: string
  ): Promise<BlobPart> => {
    const bodyFormData = new FormData();
    bodyFormData.append("documentId", documentId);
    bodyFormData.append("applicationId", applicationId || "");
    return baseApiClient.download(`document/stamp`, bodyFormData);
  };

  downloadDocumentPage = async (
    documentId: string,
    pageId: number
  ): Promise<BlobPart> => {
    return baseApiClient.download(
      `document/download/${documentId}/page/${pageId}`
    );
  };

  deleteDocument = async (document: Document) => {
    const bodyFormData = new FormData();
    bodyFormData.append("id", document.id);

    return baseApiClient.post(`document/remove`, bodyFormData, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "blob",
    });
  };

  updateDocument = async (
    documentId: string,
    fileName: string,
    documentOperations?: DocumentThumbnailOperation[]
  ): Promise<{ [pageId: number]: string }> => {
    return baseApiClient.put(`document/${documentId}`, {
      fileName,
      documentOperations,
    });
  };
}

export const documentApiClient = new DocumentApiClient();
