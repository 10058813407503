import { FC } from "react";
import { SignaturesValidationDetailsDTO } from "../../types/Application/Signature";
import { PrimaryCard } from "@ucl/library";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { Loading } from "../Loading/Loading";
import "./styles.scss";

export interface SignaturesValidationPanelProps {
  signaturesValidationDetails?: SignaturesValidationDetailsDTO;
}
export const SignaturesValidationPanel: FC<SignaturesValidationPanelProps> = (
  props
) => {
  return (
    <section className="signatures-validation-details">
      {!props.signaturesValidationDetails && <Loading />}
      {props.signaturesValidationDetails && (
        <>
          <PrimaryCard
            className={classNames("signatures-conditions", {
              "no-conditions":
                !props.signaturesValidationDetails.conditions ||
                props.signaturesValidationDetails.conditions?.length <= 0,
            })}
            headerContent={
              <>
                <Icon icon={IconNames.Settings} /> Conditions
              </>
            }
          >
            {props.signaturesValidationDetails.conditions &&
              props.signaturesValidationDetails.conditions?.length > 0 && (
                <ul>
                  {props.signaturesValidationDetails.conditions.map(
                    (condition) => (
                      <li key={condition}>{condition}</li>
                    )
                  )}
                </ul>
              )}
            {!props.signaturesValidationDetails.conditions ||
              (props.signaturesValidationDetails.conditions?.length <= 0 && (
                <p>No conditions found.</p>
              ))}
          </PrimaryCard>
          <PrimaryCard
            className={classNames("signatures-required-signatures", {
              "no-required-signatures":
                !props.signaturesValidationDetails.requiredSignaturesData ||
                props.signaturesValidationDetails.requiredSignaturesData
                  ?.length <= 0,
            })}
            headerContent={
              <>
                <Icon icon={IconNames.WarningSign} />
                Required Signatures
              </>
            }
          >
            {props.signaturesValidationDetails.requiredSignaturesData &&
              props.signaturesValidationDetails.requiredSignaturesData?.length >
                0 && (
                <ul>
                  {props.signaturesValidationDetails.requiredSignaturesData.map(
                    (requiredSignature, idx) => (
                      <li key={idx}>
                        {requiredSignature.signatureHolderName}
                        {requiredSignature.types_AsString &&
                          ` - ${requiredSignature.types_AsString.join(" / ")}`}
                      </li>
                    )
                  )}
                </ul>
              )}
            {!props.signaturesValidationDetails.requiredSignaturesData ||
              (props.signaturesValidationDetails.requiredSignaturesData
                ?.length <= 0 && (
                <p>All required signatures have been collected.</p>
              ))}
          </PrimaryCard>
        </>
      )}
    </section>
  );
};
