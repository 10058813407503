import { Card } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC, useState } from "react";
import { applicationStore } from "../../../../stores/ApplicationStore";
import { incomeOwnedEntityStore } from "../../../../stores/OwnedEntityStores/IncomeOwnedEntityStore";
import { Application } from "../../../../types/Application";
import { IncomeInformation } from "../../../../types/Application/IncomeInformation";
import { FormSubmitButton } from "../../../Buttons/FormSubmitButton";
import Form from "../../../Forms";
import {
  incomeInformationFormSchema,
  newIncomeInformation,
} from "../../../Forms/ApplicationForm/IncomeInformation";
import { showSuccessfulSaveAppToaster } from "../../../Toast/Toast";
import { IncomesFormCard } from "./IncomesFormCard";
import { getDefaultIncomeOwner } from "../../../../types/Application/Income";

export interface IncomeInformationCardProps {
  application?: Application;
  canEdit?: boolean;
  submitForm: (application: IncomeInformation) => Promise<void>;
}

export const IncomeInformationCard: FC<IncomeInformationCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const shouldShowChildren =
    application?.doesResidentHaveAdditionalIncome ||
    newIncomeInformation.doesResidentHaveAdditionalIncome ||
    application?.doesSpouseHaveAdditionalIncome ||
    newIncomeInformation.doesSpouseHaveAdditionalIncome;

  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  return (
    <>
      <Card className="income-information-card">
        <Observer>
          {() => (
            <Form<IncomeInformation>
              formId="income-information-form"
              shouldBlockNavigation={true}
              value={application || newIncomeInformation}
              disableAllFields={shouldDisableForm || !canEdit}
              formSchemaBuilder={incomeInformationFormSchema}
              onFormSubmit={async (application) => {
                setShouldDisableForm(true);
                setFormIsSaving(true);
                await submitForm(application)
                  .then(() => {
                    showSuccessfulSaveAppToaster("Income Information");
                  })
                  .finally(() => {
                    setShouldDisableForm(false);
                    setFormIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>

        {shouldShowChildren && (
          <IncomesFormCard
            incomes={application?.incomes}
            createAndUpdateIncomes={async () =>
              await incomeOwnedEntityStore.saveLocalIncomes()
            }
            deleteIncome={async (income) => {
              await incomeOwnedEntityStore.saveLocalIncomes();
              incomeOwnedEntityStore.removeLocalIncome(income);
              await applicationStore.deleteApplicationOwnedEntity(
                income.id,
                "income"
              );
            }}
            formIsSaving={formIsSaving}
            setFormIsSaving={setFormIsSaving}
            shouldDisableForm={shouldDisableForm || !canEdit}
            setShouldDisableForm={setShouldDisableForm}
            applicationId={application?.id}
            showAddButton={true}
            showCancelButton={false}
            defaultIncomeOwner={getDefaultIncomeOwner(
              application?.isMarried ?? false,
              application?.doesResidentHaveAdditionalIncome ?? false,
              application?.doesSpouseHaveAdditionalIncome ?? false
            )}
          />
        )}
      </Card>
      <div className="application-card-footer">
        <FormSubmitButton
          disabled={!canEdit}
          buttonId="income-information-save-button"
          isSaving={formIsSaving}
          formId="income-information-form"
          text="Save"
        />
      </div>
    </>
  );
};
