export enum ScheduleViews {
  AppointmentsCalendar = 1,
  FacilitySchedule = 2,
}

export interface ScheduleTabViewConfiguration {
  key: number;
  tabIndex: number;
  title: string;
  isInternalOnly: boolean;
  navigateTo: string;
}

export const ScheduleViewTabConfig: Record<
  ScheduleViews,
  ScheduleTabViewConfiguration
> = {
  [ScheduleViews.AppointmentsCalendar]: {
    key: ScheduleViews.AppointmentsCalendar,
    tabIndex: 0,
    title: "Appointments Calendar",
    isInternalOnly: false,
    navigateTo: "/scheduler/appointments-calendar",
  },
  [ScheduleViews.FacilitySchedule]: {
    key: ScheduleViews.FacilitySchedule,
    tabIndex: 1,
    title: "Facility Schedule",
    isInternalOnly: true,
    navigateTo: "/scheduler/facility-schedule",
  },
};
