import { ChatConversation } from "../../../components/ChatBot/types";
import baseApiClient from "../baseApiClient";

class ChatBotApiClient {
  getOpenConversation = async () => {
    return baseApiClient.get<ChatConversation>(`chat/conversation/open`);
  };

  promptAssistant = async (
    userMessage: string,
    usState?: number,
    searchStatePolicyTopics?: boolean
  ) => {
    return baseApiClient.post<ChatConversation>(`chat/conversation/prompt`, {
      userMessage: userMessage,
      usState: usState,
      searchStatePolicyTopics: searchStatePolicyTopics,
    });
  };

  complete = async (wasResolved: boolean) => {
    return baseApiClient.put<ChatConversation>(`chat/conversation/complete`, {
      wasResolved: wasResolved,
    });
  };
}

export const chatBotApiClient = new ChatBotApiClient();
