import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { Monitor } from "../../types/Monitor";
import { applicationStore } from "../../stores/ApplicationStore";
import {
  monitorFormSchema,
  newMonitorForm,
} from "../Forms/MonitorForm/MonitorForm";

export interface MonitorCreationDialogProps extends BaseDialogProps {}

export const MonitorCreationDialog: React.FC<MonitorCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Monitor"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<Monitor>
              formId="create-monitor-form"
              value={newMonitorForm}
              formSchemaBuilder={monitorFormSchema}
              onFormSubmit={async (monitor) => {
                setIsSaving(true);
                await applicationStore
                  .createApplicationMonitor(monitor)
                  .then(() => {
                    gridStore.refresh();
                    submitDialog();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="monitor-save-button"
          isSaving={isSaving}
          formId="create-monitor-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
