import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { userStore } from "../../../stores/UserStore";
import { Application, ApplicationStatuses } from "../../../types/Application";
import { PrimaryInformation } from "../../../types/Application/PrimaryInformation";
import { UserRoles } from "../../../types/User";
import {
  AllFields,
  FullNameFieldValue,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getFacilityAsyncAutocompleteFieldProps,
  getFieldRepAsyncAutocompleteFieldProps,
} from "../types";
import { buildFacilityAddress } from "./FacilityReadFieldBuilder";
import { getScheduleMappingClassName } from "../../../utils/miscellaneous";

export const newPrimaryInformation: PrimaryInformation = {
  productType: 1,
  status: 1,
  reasonCode: 1,
  internalStatus: 1,
  fieldRepId: undefined,
  processorId: undefined,
  facilityId: undefined,
  canEditResidentPersonalInformation: true,
  firstName: "",
  middleName: "",
  lastName: "",
  maidenName: "",
  dateOfBirth: undefined,
  socialSecurityNumber: "",
  previousAddress: {
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    county: "",
  },
};

export const primaryInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const primaryInformation = form as PrimaryInformation;
  const application = form as Application;

  const shouldDisableStatusFields =
    !!application?.monitor || !!application.isWithdrawn;

  const statusOptionValues = (productTypeValue?: string) => {
    const localProductTypeValue = productTypeValue
      ? productTypeValue
      : primaryInformation.productType?.toString();

    let optionValues = schemaStore.getMappingsByName(
      `productType${localProductTypeValue}`
    );

    if (optionValues.length <= 0) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "status").optionValues ||
        [];
    }
    return optionValues;
  };

  const reasonCodeOptionValues = (
    productTypeValue?: string,
    statusValue?: string
  ) => {
    const localProductTypeValue = productTypeValue
      ? productTypeValue
      : primaryInformation.productType?.toString();

    const localStatusValue = statusValue
      ? statusValue
      : primaryInformation.status?.toString();

    let optionValues = schemaStore.getMappingsByName(
      `productType${localProductTypeValue}-status${localStatusValue}`
    );

    if (optionValues.length <= 0) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "reasonCode")
          .optionValues || [];
    }

    return optionValues;
  };

  const internalStatusOptionValues = (
    productTypeValue?: string,
    statusValue?: string,
    reasonCodeValue?: string
  ) => {
    const localProductTypeValue = productTypeValue
      ? productTypeValue
      : primaryInformation.productType?.toString();

    const localStatusValue = statusValue
      ? statusValue
      : primaryInformation.status?.toString();

    const localReasonCode = reasonCodeValue
      ? reasonCodeValue
      : primaryInformation.reasonCode?.toString();

    let optionValues = schemaStore.getMappingsByName(
      `productType${localProductTypeValue}-status${localStatusValue}-reasonCode${localReasonCode}`
    );

    if (optionValues.length <= 0) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "internalStatus")
          .optionValues || [];
    }
    return optionValues;
  };

  return [
    {
      type: "Section",
      label: "Primary Information",
    } as SectionHeaderProps,
    {
      type: "Section",
      label: "File Information",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "productType"),
      onSubmit: (productType: Option) => {
        const productTypeValue = productType.value;
        let statusValue = undefined;
        let reasonCodeValue = undefined;
        let internalStatusValue = undefined;

        const statusOptions = statusOptionValues(productType.value);
        if (statusOptions.length === 1) {
          statusValue = statusOptions[0].value;

          const reasonCodeOptions = reasonCodeOptionValues(
            productTypeValue,
            statusValue
          );
          if (reasonCodeOptions.length === 1) {
            reasonCodeValue = reasonCodeOptions[0].value;

            const internalStatusOptions = internalStatusOptionValues(
              productTypeValue,
              statusValue,
              reasonCodeValue
            );
            if (internalStatusOptions.length === 1) {
              internalStatusValue = internalStatusOptions[0].value;
            }
          }
        }

        onValueChange({
          productType: productTypeValue,
          status: statusValue,
          reasonCode: reasonCodeValue,
          internalStatus: internalStatusValue,
        });
      },
      value: primaryInformation.productType,
      isRequired: true,
      errorMessages: get(errors, "productType"),
      disabled: !!shouldDisableStatusFields,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "status"),
      optionValues: statusOptionValues(
        primaryInformation.productType?.toString()
      ),
      onSubmit: (status: Option) => {
        const statusValue = status.value;
        let reasonCodeValue = undefined;
        let internalStatusValue = undefined;

        const reasonCodeOptions = reasonCodeOptionValues(
          primaryInformation.productType?.toString(),
          statusValue
        );
        if (reasonCodeOptions.length === 1) {
          reasonCodeValue = reasonCodeOptions[0].value;

          const internalStatusOptions = internalStatusOptionValues(
            primaryInformation.productType?.toString(),
            statusValue,
            reasonCodeValue
          );
          if (internalStatusOptions.length === 1) {
            internalStatusValue = internalStatusOptions[0].value;
          }
        }

        onValueChange({
          status: statusValue,
          reasonCode: reasonCodeValue,
          internalStatus: internalStatusValue,
        });
      },
      value: primaryInformation.status,
      width: "33%",
      isRequired: true,
      disabled: !primaryInformation.productType || !!shouldDisableStatusFields,
      errorMessages: get(errors, "status"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "reasonCode"),
      optionValues: reasonCodeOptionValues(
        primaryInformation.productType?.toString(),
        primaryInformation.status?.toString()
      ),
      onSubmit: (reasonCode: Option) => {
        const reasonCodeValue = reasonCode.value;
        let internalStatusValue = undefined;

        const internalStatusOptions = internalStatusOptionValues(
          primaryInformation.productType?.toString(),
          primaryInformation.status?.toString(),
          reasonCodeValue
        );
        if (internalStatusOptions.length === 1) {
          internalStatusValue = internalStatusOptions[0].value;
        }

        onValueChange({
          reasonCode: reasonCodeValue,
          internalStatus: internalStatusValue,
        });
      },
      value: primaryInformation.reasonCode,
      width: "33%",
      isRequired: true,
      disabled: !primaryInformation.status || !!shouldDisableStatusFields,
      errorMessages: get(errors, "reasonCode"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "internalStatus"),
      optionValues: internalStatusOptionValues(
        primaryInformation.productType?.toString(),
        primaryInformation.status?.toString(),
        primaryInformation.reasonCode?.toString()
      ),
      onSubmit: (internalStatus: Option) => {
        onValueChange({ internalStatus: internalStatus.value });
      },
      value: primaryInformation.internalStatus,
      width: "33%",
      isRequired: true,
      disabled: !primaryInformation.reasonCode || !!shouldDisableStatusFields,
      errorMessages: get(errors, "internalStatus"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fieldRepId"),
      ...getFieldRepAsyncAutocompleteFieldProps(),
      className: getScheduleMappingClassName(
        application.scheduleMappingValues,
        "fieldRepId"
      ),
      isRequired: true,
      groupByActiveStatus: true,
      value: primaryInformation.fieldRepId,
      errorMessages: get(errors, "fieldRepId"),
      onSubmit: (fieldRepOptions: Option) => {
        onValueChange({
          fieldRepId: fieldRepOptions?.value,
        });
      },
    } as AllFields,
    {
      type: "Section",
      label: "Resident Information",
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityId"),
      ...getFacilityAsyncAutocompleteFieldProps(),
      className: getScheduleMappingClassName(
        application.scheduleMappingValues,
        "facilityId"
      ),
      groupByActiveStatus: true,
      errorMessages: get(errors, "facilityId"),
      width: "45.9%",
      isRequired: true,
      disabled:
        application.status === ApplicationStatuses.interviewing &&
        !userStore.user?.canEditFacilityOnInterviewingApplication,
      value: primaryInformation.facilityId,
      onSubmit: (facilityOptions: Option & { area_AsString: string }) => {
        onValueChange({
          facilityId: facilityOptions?.value,
          region: facilityOptions?.area_AsString,
        });
      },
    } as AllFields,
    {
      label: "Facility Region",
      value: primaryInformation.region || "",
      width: "45.9%",
      type: "Text",
      disabled: true,
    } as AllFields,
    {
      type: "Custom",
      label: "Facility Address",
      disabled: true,
      onSubmit: () => {
        onValueChange({});
      },
      form: form,
      value: primaryInformation.facilityId || "",
      renderer: buildFacilityAddress,
    },
    {
      type: "FullName",
      label: "Name",
      className: getScheduleMappingClassName(
        application.scheduleMappingValues,
        "name"
      ),
      disabled: !application.canEditResidentPersonalInformation,
      onSubmit: (name) => {
        onValueChange({ ...(name as FullNameFieldValue) });
      },
      value: {
        firstName: primaryInformation.firstName,
        lastName: primaryInformation.lastName,
        middleName: primaryInformation.middleName,
        maidenName: primaryInformation.maidenName,
      },
      errorMessages: [
        ...(get(errors, "firstName") || []),
        ...(get(errors, "lastName") || []),
      ],
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "dateOfBirth"),
      value: primaryInformation.dateOfBirth || "",
      onSubmit: (dateOfBirth) => {
        onValueChange({ dateOfBirth });
      },
      errorMessages: get(errors, "dateOfBirth"),
      isRequired: true,
      maxDate: new Date(Date.now()),
      minDate: new Date("1900-01-01"),
      disabled: !application.canEditResidentPersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneNumber"),
      value: primaryInformation.phoneNumber,
      onSubmit: (phoneNumber) => {
        onValueChange({ phoneNumber });
      },
      errorMessages: get(errors, "phoneNumber"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "additionalPhoneNumber"),
      value: primaryInformation.additionalPhoneNumber,
      onSubmit: (additionalPhoneNumber) => {
        onValueChange({ additionalPhoneNumber });
      },
      width: "45%",
      errorMessages: get(errors, "additionalPhoneNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "email"),
      value: primaryInformation.email,
      onSubmit: (email) => {
        onValueChange({ email });
      },
      formId: formId,
      errorMessages: get(errors, "email"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "socialSecurityNumber"),
      className: getScheduleMappingClassName(
        application.scheduleMappingValues,
        "socialSecurityNumber"
      ),
      onSubmit: (socialSecurityNumber) => {
        onValueChange({ socialSecurityNumber });
      },
      value: primaryInformation.socialSecurityNumber,
      isRequired: true,
      errorMessages: get(errors, "socialSecurityNumber"),
      isSensitive:
        userStore.user &&
        [
          UserRoles.FieldRepresentative,
          UserRoles.FieldRepresentativeManager,
        ].includes(userStore.user.role),
      disabled: !application.canEditResidentPersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "previousAddress"),
      disabled: !application.canEditResidentPersonalInformation,
      onSubmit: (previousAddress) => {
        onValueChange({ previousAddress });
      },
      value: primaryInformation.previousAddress,
      isRequired: true,
      errorMessages: [
        ...(get(errors, "previousAddress.Street") || []),
        ...(get(errors, "previousAddress.City") || []),
        ...(get(errors, "previousAddress.State") || []),
        ...(get(errors, "previousAddress.Zip") || []),
        ...(get(errors, "previousAddress.County") || []),
      ],
    } as AllFields,
  ];
};
