import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import variables from "../../../../config/variables";
import "../../../../pages/styles.scss";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";
import { Grid } from "../../../Grid/Grid/Grid";
import { GridPermissions } from "../../../Grid/types/GridPermissions";
import { billingGridColumnsConfiguration } from "../../configurations/gridColumnsConfiguration";
import "../../styles.scss";
import { GridMetrics } from "../../../Grid/Metrics/GridMetrics";
import { BillingMetricReadDTO } from "../../types";
import { GridFilterSettings } from "../../../Grid/Grid/types/GridFilterSettings";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../../../lib/apiClients/baseApiClient";
import { autorun } from "mobx";
import { gridStore } from "../../../Grid/Grid/stores/GridStore";

export interface InvoicedBillingGridProps extends GridPermissions {
  canGenerateInvoice: boolean;
}

interface GridBillingReadDTO {
  id: string;
}

const InvoicedBillingGridComponent: React.FC<InvoicedBillingGridProps> = ({
  canExportGridToExcel,
  canInvokeGridRecord,
}) => {
  const gridKey = "billing-board-grid";
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Billing Board",
    billingGridColumnsConfiguration,
    () => billingGridColumnsConfiguration,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  return (
    <div>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && (
                <Grid<GridBillingReadDTO>
                  key={gridView.currentGridViewKey}
                  gridKey={gridKey}
                  dataSource={`${variables.apiBaseUrl}odata/billingodatainvoiced`}
                  columnsConfiguration={[
                    ...gridView.currentGridView.columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(gridView.currentGridView.filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(gridView.currentGridView.sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    allowExcelExport: canExportGridToExcel,
                    exportFilename: "Billings.xlsx",
                    onRecordClick: ({ rowData }) => {
                      if (canInvokeGridRecord) {
                        dialogsViewerStore.setIsUpdateBillingDialogOpen(true, {
                          billingId: rowData.id,
                        });
                      }
                    },
                  }}
                  gridHeightSettings={{ autoHeightOffset: 380 }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
      <section className="board-grid-footer">
        <GridMetrics<BillingMetricReadDTO>
          key={gridView.currentGridViewKey}
          gridKey={gridKey}
          url={`${variables.apiBaseUrl}api/billing/metrics/invoiced`}
          columns={4}
          minimal={true}
          languageMapping={{
            countBillingEvents: "Count of Billing Events",
            sumBillingAmount: "Sum of Billing Amount",
            sumBillingDiscounts: "Sum of Discounts",
            sumBillingFinalAmount: "Sum of Final Amount",
          }}
        />
      </section>
    </div>
  );
};

export const InvoicedBillingGrid = withAITracking(
  reactPlugin,
  InvoicedBillingGridComponent,
  "BillingGrid",
  "AI-flex-container"
);
