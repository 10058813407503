import classNames from "classnames";
import RelativityCard from "../Card/RelativityCard";
import { DocumentFilingComponentReviewItem } from "./DocumentFilingComponentReviewItem";
import {
  AllFIARequestODataReadDTO,
  ApplicationFilingODataReadDTO,
  DocumentFilingAssociatedChecklistItem,
  StateNoticeFilingProcessDocumentResult,
} from "./types";
import {
  AsyncAutoCompleteField,
  AsyncAutoCompleteFieldProps,
} from "@ucl/library";
import { FC, useState } from "react";
import { Document } from "../../types/Document";
import { ReadOnlyField } from "../Fields/ReadOnlyField/ReadOnlyField";
import { FilenameField } from "../Fields/FilenameField/FilenameField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faPencil } from "@fortawesome/free-solid-svg-icons";

export interface StateNoticeFilingFormCardProps {
  document?: Document;
  newDocumentName?: string;
  setNewDocumentName: (newDocumentName: string) => void;
  stateNoticeFilingProcessDocumentResult?: StateNoticeFilingProcessDocumentResult;
  application?: ApplicationFilingODataReadDTO;
  asyncAutoCompleteProps: AsyncAutoCompleteFieldProps;

  associatedChecklistItem?: DocumentFilingAssociatedChecklistItem;

  searchValue?: string;
  autoFillData?: AllFIARequestODataReadDTO[] | ApplicationFilingODataReadDTO[];
  isSaving?: boolean;
}

export const StateNoticeFilingFormCard: FC<StateNoticeFilingFormCardProps> = (
  props
) => {
  const [isDocumentRenameActive, setIsDocumentRenameActive] = useState(false);
  return (
    <>
      <RelativityCard headerContent={"State Notice Filing"}>
        {!props.stateNoticeFilingProcessDocumentResult?.applicationData?.id && (
          <div className="information">
            To file your document, please search by Resident Name, Case Number,
            or File Number in the Search Bar below.
          </div>
        )}
        <div className="fiafiling-content-form-search">
          <AsyncAutoCompleteField
            {...props.asyncAutoCompleteProps}
            value={props.application?.id || ""}
            disabled={props.isSaving}
            {...((props.autoFillData || [])?.length > 0
              ? { autoFillData: props.autoFillData }
              : { searchValue: props.searchValue })}
          />
        </div>
        <div className="fiafiling-content-form-review">
          <ul>
            {isDocumentRenameActive && (
              <li>
                <FontAwesomeIcon
                  className="clickable"
                  size="lg"
                  icon={faFloppyDisk}
                  onClick={() => setIsDocumentRenameActive(false)}
                />
                <FilenameField
                  isRequired={true}
                  readOnly={false}
                  disabled={false}
                  maxLength={250}
                  type={"Filename"}
                  value={props.newDocumentName || ""}
                  onSubmit={function (value: unknown): void {
                    props.setNewDocumentName(value as string);
                  }}
                  width="90%"
                />
              </li>
            )}

            {!isDocumentRenameActive && (
              <li
                className={classNames({
                  "fiafiling-content-form-review-disabled": !props.document?.id,
                })}
              >
                <DocumentFilingComponentReviewItem
                  title="Document"
                  content={
                    props.newDocumentName ||
                    props.document?.originalFileName ||
                    "-"
                  }
                  icon={faPencil}
                  iconClick={() => {
                    setIsDocumentRenameActive(true);
                  }}
                />
              </li>
            )}

            <li
              className={classNames({
                "fiafiling-content-form-review-disabled":
                  !props.application?.id,
              })}
            >
              <DocumentFilingComponentReviewItem
                title="Application"
                content={`${props.application?.fileNumber || ""} - ${
                  props.application?.residentFullName || ""
                }`}
              />
            </li>

            <li
              className={classNames({
                "fiafiling-content-form-review-disabled":
                  !props.application?.id,
              })}
            >
              <DocumentFilingComponentReviewItem
                title="Case Number"
                content={props.application?.caseNumber || ""}
              />
            </li>

            <li
              className={classNames({
                "fiafiling-content-form-review-disabled":
                  !props.associatedChecklistItem?.id,
              })}
            >
              <DocumentFilingComponentReviewItem
                title="Checklist Item"
                content={props.associatedChecklistItem?.displayName || "-"}
              />
            </li>
          </ul>
        </div>
      </RelativityCard>

      <RelativityCard
        className="state-notice-app-information"
        headerContent="File"
        columns={2}
      >
        <>
          <ReadOnlyField
            type="ReadOnly"
            onSubmit={() => 0}
            label="Resident Name"
            value={props.application?.residentFullName || ""}
          />
          <ReadOnlyField
            type="ReadOnly"
            onSubmit={() => 0}
            label="File Number"
            value={props.application?.fileNumber || ""}
          />
          <ReadOnlyField
            type="ReadOnly"
            onSubmit={() => 0}
            label="Case Number"
            value={props.application?.caseNumber || ""}
          />
          <ReadOnlyField
            type="ReadOnly"
            onSubmit={() => 0}
            label="State App Number"
            value={props.application?.stateAppNumber || ""}
          />
          <ReadOnlyField
            type="ReadOnly"
            onSubmit={() => 0}
            label="Facility Name"
            value={props.application?.facilityName || ""}
          />
          <ReadOnlyField
            type="ReadOnly"
            onSubmit={() => 0}
            label="Facility Address"
            value={props.application?.facilityAddress || ""}
          />
        </>
      </RelativityCard>
    </>
  );
};
