import {
  ExcelExport,
  Filter,
  GridModel,
  GridComponent,
  Group,
  InfiniteScroll,
  Inject,
  Page,
  Reorder,
  Resize,
  Search,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { Predicate } from "@syncfusion/ej2-data";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Observer } from "mobx-react";
import { autorun } from "mobx";
import React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Button, Intent } from "@blueprintjs/core";
import { GridPermissions } from "../../../Grid/types/GridPermissions";
import { BaseGridModel } from "../../../Grid/Grid/types/BaseGridModel";
import { getAccessToken } from "../../../../lib/apiClients/baseApiClient";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { reactPlugin } from "../../../../AppInsights";
import { corporateUsersGridColumnsConfiguration } from "./corporateUsersGridColumnsConfiguration";
import { corporateUsersGridStore } from "./CorporateUsersGridStore";
import { UserRoles } from "../../../../types/User";
import {
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";

export interface CorporateUsersGridProps<T extends object>
  extends Partial<GridPermissions> {
  gridConfiguration?: Readonly<BaseGridModel<T>>;
  corporateId: string;
  predicate: Predicate;
}

const CorporateUsersGridComponent = <T extends object>(
  props: CorporateUsersGridProps<T>
) => {
  const gridRef = React.useRef<GridComponent | null>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [gridSettings, setGridSettings] = React.useState<
    GridModel & DefaultHtmlAttributes
  >();
  const [ready, setReady] = React.useState<boolean>(false);
  const [tooltipText, setTooltipText] = React.useState<string>();

  React.useEffect(() => {
    autorun(async () => {
      corporateUsersGridStore.refresh();
    });
  }, []);

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      corporateUsersGridStore.reset();
    };
  }, []);

  // Initialize
  React.useEffect(() => {
    if (!!gridRef.current && !initialized) {
      corporateUsersGridStore.initialize(gridRef.current);
      setInitialized(true);
    }
  });

  // Setup Method
  const setup = async () => {
    const gridModel = await corporateUsersGridStore.setup({
      getAccessToken: getAccessToken,
      canExportGridToExcel: !!props.canExportGridToExcel,
      canInvokeGridRecord: !!props.canInvokeGridRecord,
      gridConfiguration: props.gridConfiguration,
      dataSource: "corporateusersodata",
      gridColumnsConfiguration: corporateUsersGridColumnsConfiguration,
      predicate: props.predicate,
    });
    setGridSettings(gridModel);
    setReady(true);
  };

  // Set Tooltip on Column hover
  const beforeRender = (args: TooltipEventArgs) => {
    // Only show a tooltip for long character-length column values.
    if (!args.target.innerText || args.target.innerText.length < 25) {
      args.cancel = true;
    }

    setTooltipText(args.target.innerText);
  };

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-right">
          {props.canCreateNewRecord && (
            <Button
              className="create-user-button"
              intent={Intent.PRIMARY}
              text="Create New User"
              onClick={() => {
                dialogsViewerStore.setIsUserCreationDialogOpen(true, {
                  onSubmit: () => corporateUsersGridStore.refresh(),
                  role: UserRoles.CorporateUser,
                  isExternal: true,
                  corporateId: props.corporateId,
                });
              }}
            />
          )}
        </ul>
      </section>
      <section className="board-grid-container">
        <div className="grid-container">
          <Observer>
            {() => (
              <>
                {ready && (
                  <TooltipComponent
                    id="Tooltip"
                    target=".e-rowcell"
                    beforeRender={beforeRender}
                    content={tooltipText}
                  >
                    <GridComponent
                      ref={gridRef}
                      {...gridSettings}
                      className="grid"
                      recordClick={({ rowData }) => {
                        if (props.canInvokeGridRecord) {
                          dialogsViewerStore.setIsUserUpdateDialogOpen(true, {
                            userId: rowData.id,
                            onSubmit: () => corporateUsersGridStore.refresh(),
                          });
                        }
                      }}
                    >
                      <Inject
                        services={[
                          Page,
                          Sort,
                          Filter,
                          Group,
                          Toolbar,
                          Reorder,
                          Resize,
                          InfiniteScroll,
                          Search,
                          ExcelExport,
                        ]}
                      />
                    </GridComponent>
                  </TooltipComponent>
                )}
                {!ready && <></>}
              </>
            )}
          </Observer>
        </div>
      </section>
    </div>
  );
};

export const CorporateUsersGrid = withAITracking(
  reactPlugin,
  CorporateUsersGridComponent,
  "CorporateUsersGrid",
  "AI-flex-container"
);
