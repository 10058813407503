import {
  GridComponent,
  GridModel,
  RowDD,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import React from "react";
import { getAccessToken } from "../../lib/apiClients/baseApiClient";
import RelativityCard from "../Card/RelativityCard";
import "./styles.scss";
import _ from "lodash";
import {
  PdfDocumentEntity,
  PdfDocumentEntityType,
} from "../Document/PdfDocumentUploader";
import {
  OrderedDocumentsGridSetup,
  orderedDocumentsGridStore,
} from "./stores/OrderedDocumentsGridStore";
import {
  OrderedDocumentCoverLetterCreateDTO,
  OrderedDocumentCreateDTO,
  OrderedDocumentReadDTO,
  OrderedDocumentReorderDTO,
} from "./configurations/types";
import { Button, Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { RelativityCardHeader } from "../Card/RelativityCardHeader";
import { GridInstanceContext } from "../Grid/Grid/Grid";
import { IconNames } from "@blueprintjs/icons";
import { WithTooltip } from "../Tooltip/Tooltip";

export interface OrderedDocumentsGridProps {
  sectionTitle: string;
  odataUrl: string;
  pdfDocumentEntity: PdfDocumentEntity;
  canEditFIARequestDocumentList: boolean;
  odataQueryParams?: { key: string; value: Function | string | null }[];
  addAction?: (rowData: OrderedDocumentCreateDTO) => void;
  addCoverLetter?: (rowData: OrderedDocumentCoverLetterCreateDTO) => void;
  previewAction?: (rowData: OrderedDocumentReadDTO) => void;
  downloadAction?: (rowData: OrderedDocumentReadDTO) => void;
  deleteAction?: (rowData: OrderedDocumentReadDTO) => void;
  reorderAction?: (rowData: OrderedDocumentReorderDTO) => void;
  sortAction?: () => Promise<void>;
  isDisabled?: boolean;
  hideAddAction?: boolean;
  onRefresh?: () => void;
}

export const OrderedDocumentsGrid: React.FC<OrderedDocumentsGridProps> = (
  props
) => {
  const gridRef = React.useRef<GridComponent | null>(null);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [gridSettings, setGridSettings] = React.useState<
    GridModel & DefaultHtmlAttributes
  >();
  const [ready, setReady] = React.useState<boolean>(false);
  const [discriminatorIds, setDiscriminatorIds] = React.useState<
    string[] | undefined
  >(undefined);
  const [doesCoverLetterExist, setDoesCoverLetterExist] = React.useState<
    boolean | undefined
  >(false);

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      orderedDocumentsGridStore.reset();
    };
  }, []);

  // Initialize
  React.useEffect(() => {
    if (!!gridRef.current && !initialized) {
      orderedDocumentsGridStore.initialize(gridRef.current);
      setInitialized(true);
    }
  });

  // Setup Method
  const setup = async () => {
    const gridModel = await orderedDocumentsGridStore.setup({
      odataUrl: props.odataUrl,
      getAccessToken: getAccessToken,
      odataQueryParams: props.odataQueryParams,
      previewAction: props.previewAction,
      downloadAction: props.downloadAction,
      deleteAction:
        !props.isDisabled && props.canEditFIARequestDocumentList
          ? async (rowData: OrderedDocumentReadDTO) => {
              props.deleteAction && (await props.deleteAction(rowData));
              refresh();
            }
          : undefined,
      reorderAction:
        !props.isDisabled && props.canEditFIARequestDocumentList
          ? props.reorderAction
          : undefined,
    } as OrderedDocumentsGridSetup);

    setGridSettings(gridModel);
    setReady(true);
  };

  const onDataBound = () => {
    const data = gridRef.current?.currentViewData;
    const ids = data?.map((x) => (x as OrderedDocumentReadDTO).discriminatorId);
    setDiscriminatorIds(ids || []);
    setDoesCoverLetterExist(
      data
        ?.map((x) => (x as OrderedDocumentReadDTO).documentName)
        .includes("Cover Letter")
    );
  };

  const refresh = () => {
    gridRef.current?.refresh();
    props.onRefresh && props.onRefresh();
  };

  const sort = async () => {
    if (
      props.sortAction &&
      !props.isDisabled &&
      props.canEditFIARequestDocumentList
    ) {
      await props.sortAction();
      refresh();
    }
  };

  return (
    <section className="ordered-documents-grid">
      <RelativityCard
        headerContent={
          <RelativityCardHeader label={props.sectionTitle}>
            <section>
              {ready && discriminatorIds !== undefined && (
                <>
                  <WithTooltip
                    shouldShowTooltip={true}
                    content="Refresh"
                    placement="top"
                  >
                    <Button
                      intent={Intent.NONE}
                      icon={IconNames.Refresh}
                      disabled={props.isDisabled}
                      onClick={() => {
                        refresh();
                      }}
                    />
                  </WithTooltip>
                  <WithTooltip
                    shouldShowTooltip={true}
                    content="Sort Documents Alphabetically by Type"
                    placement="top"
                  >
                    <Button
                      intent={Intent.NONE}
                      icon={IconNames.Sort}
                      disabled={props.isDisabled}
                      aria-description="Sort Documents Alphabetically by Type"
                      onClick={() => {
                        sort();
                      }}
                    />
                  </WithTooltip>
                </>
              )}
              {props.canEditFIARequestDocumentList &&
                props.pdfDocumentEntity.entityType ===
                  PdfDocumentEntityType.FIARequestDocuments &&
                props.addCoverLetter &&
                !props.hideAddAction &&
                !doesCoverLetterExist && (
                  <Button
                    intent={Intent.PRIMARY}
                    text="Add Cover Letter"
                    disabled={props.isDisabled}
                    onClick={() => {
                      dialogsViewerStore.openCoverLetterPickerDialog({
                        pdfDocumentEntity: props.pdfDocumentEntity,
                        async onSubmit(
                          orderedDocumentCoverLetterCreateDTO?: OrderedDocumentCoverLetterCreateDTO
                        ) {
                          if (!!orderedDocumentCoverLetterCreateDTO) {
                            props.addCoverLetter &&
                              (await props.addCoverLetter(
                                orderedDocumentCoverLetterCreateDTO
                              ));
                            refresh();
                          }
                        },
                      });
                    }}
                  />
                )}
              {props.canEditFIARequestDocumentList &&
                props.addAction &&
                !props.hideAddAction &&
                ready &&
                discriminatorIds !== undefined && (
                  <Button
                    intent={Intent.PRIMARY}
                    text="Add Document"
                    disabled={props.isDisabled}
                    onClick={() => {
                      dialogsViewerStore.openOrderedDocumentsPickerDialog({
                        pdfDocumentEntity: props.pdfDocumentEntity,
                        templateOdataExcludeIds: discriminatorIds,
                        async onSubmit(
                          orderedDocumentCreateDTO?: OrderedDocumentCreateDTO
                        ) {
                          if (!!orderedDocumentCreateDTO) {
                            props.addAction &&
                              (await props.addAction(orderedDocumentCreateDTO));
                            refresh();
                          }
                        },
                      });
                    }}
                  />
                )}
            </section>
          </RelativityCardHeader>
        }
      >
        <section className="board-grid-container">
          <div className="grid-container">
            {ready && (
              <>
                <GridInstanceContext.Provider value={gridRef.current}>
                  <GridComponent
                    ref={gridRef}
                    {...gridSettings}
                    dataBound={onDataBound}
                    readOnly={props.isDisabled}
                    className="grid"
                  >
                    <Inject services={[RowDD]} />
                  </GridComponent>
                </GridInstanceContext.Provider>
              </>
            )}
            {!ready && <></>}
          </div>
        </section>
      </RelativityCard>
    </section>
  );
};
