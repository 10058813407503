import { Menu, MenuItem } from "@blueprintjs/core";
import { ViewSelectionMenuProps } from "../interfaces/ViewSelectionMenuProps";

export const ViewSelectionMenu: React.FC<ViewSelectionMenuProps> = ({
  menuItems,
}) => {
  return (
    <Menu>
      {menuItems.map((item, index) => (
        <MenuItem key={index} text={item.text} onClick={item.onClick} />
      ))}
    </Menu>
  );
};
