import { Card, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { Application } from "../../../types/Application";
import { LegalInformation } from "../../../types/Application/LegalInformation";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import Form from "../../Forms";
import {
  legalInformationFormSchema,
  newLegalInformation,
} from "../../Forms/ApplicationForm/LegalInformation";
import { AppToaster } from "../../Toast/Toast";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import { applicationStore } from "../../../stores/ApplicationStore";

export interface LegalInformationCardProps {
  application?: Application;
  canEdit?: boolean;
  submitForm: (application: LegalInformation) => Promise<void>;
}

export const LegalInformationCard: FC<LegalInformationCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!!application?.medicaidEligibilityDecision) {
      if (!!applicationStore.financialEligibilityConversationRequired) {
        dialogsViewerStore.setFinancialEligibilityConversationDialogOpen(true, {
          financialEligibilityConversation: {
            financialEligibilityConversationConfirmation:
              application?.financialEligibilityConversationConfirmation ||
              false,
          },
          medicaidEligibilityDecisionType_AsString:
            application.medicaidEligibilityDecision.decisionType_AsString,
        });
      }
    }
  }, []);

  return (
    <>
      <Card className="legal-information-card">
        <Observer>
          {() => (
            <Form<LegalInformation>
              formId="legal-information-form"
              shouldBlockNavigation={true}
              value={application || newLegalInformation}
              disableAllFields={!canEdit}
              formSchemaBuilder={legalInformationFormSchema}
              onFormSubmit={async (application) => {
                setIsSaving(true);
                await submitForm(application)
                  .then(() => {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Application Saved
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </Card>
      <div className="application-card-footer">
        <FormSubmitButton
          disabled={!canEdit}
          buttonId="legal-information-save-button"
          isSaving={isSaving}
          formId="legal-information-form"
          text="Save"
        />
      </div>
    </>
  );
};
