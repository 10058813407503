import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { PrimaryInformation } from "../../../types/Application/PrimaryInformation";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getFacilityAsyncAutocompleteFieldProps,
} from "../types";

export const applicationPageFacilityUpdateFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const primaryInformation = form as PrimaryInformation;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "facilityId"),
      ...getFacilityAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      errorMessages: get(errors, "facilityId"),
      isRequired: true,
      value: primaryInformation.facilityId,
      onSubmit: (facilityOptions: Option) => {
        onValueChange({
          facilityId: facilityOptions?.value,
        });
      },
    } as AllFields,
  ];
};
