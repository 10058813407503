import React from "react";
import { Observer } from "mobx-react";
import { Loading } from "../Loading/Loading";
import "./documentPreviewStyles.scss";
import { useDocument } from "./useDocument";
import { NonIdealState } from "@blueprintjs/core";

export interface DocumentPreviewProps {
  getDocument?: Promise<BlobPart>;
  onDocumentDownloaded?: {
    key: string;
    handler: (key: string, blobPart: BlobPart) => void;
  };
}

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  getDocument,
  onDocumentDownloaded,
}) => {
  const [ready, setReady] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<boolean>(false);
  const [documentData, setDocumentData] = React.useState<string>();

  const { getDocumentBlobUrl } = useDocument();

  // Setup
  React.useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    if (!getDocument) {
      setReady(true);
      return;
    }

    try {
      const documentBlobPart = await getDocument;

      onDocumentDownloaded?.handler &&
        onDocumentDownloaded.handler(
          onDocumentDownloaded.key,
          documentBlobPart
        );
      setDocumentData(getDocumentBlobUrl(documentBlobPart));
      setReady(true);
    } catch {
      setErrors(true);
      setReady(true);
    }
  };
  return (
    <section className="document-preview-container">
      <Observer>
        {() => (
          <section className="document-preview">
            {ready && documentData && (
              <object data={documentData} type="application/pdf"></object>
            )}
            {ready && !documentData && <>Document Not Loaded</>}
            {!ready && <Loading />}
            {ready && errors && (
              <div className="document-pages-viewer-item-error">
                <NonIdealState
                  title={
                    <>
                      Something went wrong while processing the file. <br />
                      Please upload again or contact support.
                    </>
                  }
                />
              </div>
            )}
          </section>
        )}
      </Observer>
    </section>
  );
};
