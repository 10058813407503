import { RichTextEditorField, RichTextEditorValueType } from "@ucl/library";
import { CustomRendererBuilder } from "../../Fields/types/fieldTypes";
import { HelpCenterTopic } from "../../HelpCenter/types";
import { useEffect, useRef } from "react";
import { RichTextEditorFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";

export const helpCenterTopicContentBuilder: CustomRendererBuilder<
  HelpCenterTopic
> = ({ form, rendererProps }) => {
  const helpCenterRef = useRef<HelpCenterTopic>();

  const submitContentHandler = (content: RichTextEditorFieldValue) => {
    if (!helpCenterRef.current) {
      return;
    }
    helpCenterRef.current.content = content as string;
    rendererProps.onSubmit(helpCenterRef.current);
  };

  useEffect(() => {
    helpCenterRef.current = form;
  }, [form]);

  return (
    <RichTextEditorField
      label="Content"
      isRequired={rendererProps.isRequired}
      valueType={RichTextEditorValueType.base64}
      value={rendererProps.value as string}
      richTextEditorSettings={{
        height: 650,
      }}
      onSubmit={(content) => {
        submitContentHandler(content);
      }}
    />
  );
};
