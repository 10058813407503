import { NonIdealState } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { Asset } from "../../../../../../types/Application/Asset";
import { AddButton } from "../../../../../Buttons/AddButton";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import { newAsset } from "../../../../../Forms/ApplicationForm/Assets";
import { AssetTypes } from "../../../../../../types/Application/Asset";
import { NewAsset } from "../../../../../../types/Application/Asset";
import { FormSchemaBuilder } from "../../../../../Forms/types";
import { AssetPageCard } from "./AssetPageCard";
import _, { orderBy } from "lodash";

export interface AssetsPageCardProps {
  assets?: Asset[];
  assetType: AssetTypes;
  title: string;
  assetFormSchema: FormSchemaBuilder;
  createAsset: (asset: NewAsset) => Promise<void>;
  updateAsset: () => Promise<void>;
  deleteAsset: (asset: Asset) => Promise<void>;
  canCreateAsset: boolean;
  canEditAsset: boolean;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
  applicationId: string;
}

const sectionTitle = "Asset";
export const AssetsPageCard: FC<AssetsPageCardProps> = (props) => {
  const [localAssets, setLocalAssets] = useState<Asset[]>(props.assets || []);
  const [isAddingAsset, setIsAddingAsset] = useState<boolean>(false);

  useEffect(() => {
    setLocalAssets(props.assets || []);
  }, [props.assets]);

  return (
    <RelativityCard
      headerContent={
        <div className="owned-entity-header asset-header">
          <SectionHeader type="Section" label={props.title} />
          {props.canCreateAsset && (
            <AddButton
              disabled={props.shouldDisableForm || props.formIsSaving}
              text={`Add ${sectionTitle}`}
              loading={isAddingAsset}
              onClick={async () => {
                props.setShouldDisableForm(true);
                setIsAddingAsset(true);
                /**
                 * Unfortunately, when mounting Syncfusion component
                 * specifically the Instuition Component
                 * The component hijack the reference in Application Store
                 * and is forcing Application Form level refersh
                 *
                 * This then will clear out the child state, to combat this at this moment
                 * We will create database record on Add Asset
                 */

                await props.createAsset({ ...newAsset, type: props.assetType });
                props.setShouldDisableForm(false);
                setIsAddingAsset(false);
              }}
            />
          )}
        </div>
      }
    >
      {localAssets?.length <= 0 && (
        <NonIdealState title="No Information Available" />
      )}
      {localAssets?.length > 0 &&
        orderBy(localAssets, ["createdAt"], ["desc"]).map((asset, index) => {
          return (
            <AssetPageCard
              key={index}
              asset={asset}
              assets={localAssets}
              title={sectionTitle}
              assetType={props.assetType}
              index={index}
              assetFormSchema={props.assetFormSchema}
              createAsset={props.createAsset}
              updateAsset={props.updateAsset}
              deleteAsset={props.deleteAsset}
              canCreateAsset={props.canCreateAsset}
              canEditAsset={props.canEditAsset}
              shouldDisableForm={props.shouldDisableForm}
              setShouldDisableForm={props.setShouldDisableForm}
              formIsSaving={props.formIsSaving}
              setFormIsSaving={props.setFormIsSaving}
              isAddingAsset={isAddingAsset}
              setLocalAssets={setLocalAssets}
              applicationId={props.applicationId}
            />
          );
        })}
    </RelativityCard>
  );
};
