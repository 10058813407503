import { Intent } from "@blueprintjs/core";
import classNames from "classnames";
import { get } from "lodash";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { SaveChangeDialog } from "../../Dialogs/SaveChangeDialog";
import { AppToaster } from "../../Toast/Toast";
import "./styles.scss";

export const FormDisabledContext = React.createContext<boolean | undefined>(
  false
);

export interface BaseFormProps<T> {
  formId: string;
  onFormSubmit: (value: T) => Promise<void>;
  shouldBlockNavigation?: boolean;
  value?: T;
  formValueState: {
    formValue: T;
    setFormValue: React.Dispatch<React.SetStateAction<T>>;
  };
  computedStateOption?: {
    fields: string[];
    getComputedState?: (value: Partial<T>) => Promise<Partial<T>>;
  };
  shouldResetForm?: boolean;
  isFormRequired?: boolean;
  onErrors?: (errors: { [key: string]: string[] }) => void;
  setErrors?: Dispatch<SetStateAction<{ [key: string]: string[] }>>;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export function BaseForm<T>(props: BaseFormProps<T>) {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [isFormDisabled, setIsFormDisabled] = React.useState<
    boolean | undefined
  >(false);

  useEffect(() => {
    if (!!props.value) {
      props.formValueState.setFormValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    setIsFormDisabled(props.isDisabled);
  }, [props.isDisabled]);

  useEffect(() => {
    if (props.value) {
      props.formValueState.setFormValue(props.value);
    }
  }, [props.shouldResetForm]);

  useEffect(
    () => {
      if (!!props.computedStateOption?.getComputedState) {
        props.computedStateOption
          .getComputedState(props.formValueState.formValue)
          .then((computedState) => {
            return props.formValueState.setFormValue((prevState) => ({
              ...prevState,
              ...computedState,
            }));
          });
      }
    },
    props.computedStateOption?.fields.map((field) =>
      get(props.formValueState.formValue, field)
    )
  );

  return (
    <>
      {props.shouldBlockNavigation === true && (
        <SaveChangeDialog
          isDirty={
            JSON.stringify(props.value) !==
            JSON.stringify(props.formValueState.formValue)
          }
        />
      )}
      <form
        ref={formRef}
        id={props.formId}
        autoComplete="off"
        className={classNames("base-form", {
          "base-form-disabled": isFormDisabled,
        })}
        onSubmit={(e) => {
          e.preventDefault();
          props.setErrors?.({});
          props.onFormSubmit(props.formValueState.formValue).catch((error) => {
            if (error.response?.data?.errors) {
              console.error("Runtime Crash Error", error.response.data.errors);

              AppToaster.show({
                message: "An error ocurred executing the current action.",
                intent: Intent.DANGER,
              });
            } else {
              props.onErrors &&
                props.onErrors(error.response.data.additionalInfo);
            }
          });
        }}
      >
        {/* <fieldset disabled={isFormDisabled}> */}
        <fieldset>
          <FormDisabledContext.Provider value={isFormDisabled}>
            {props.children}
          </FormDisabledContext.Provider>
        </fieldset>
      </form>
    </>
  );
}
