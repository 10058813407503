import { schemaStore } from "../../../stores/SchemaStore";
import { get } from "lodash";
import {
  AllFields,
  SectionHeaderProps,
  Option,
} from "../../Fields/types/fieldTypes";
import {
  getFieldRepAsyncAutocompleteFieldProps,
  FormSchemaBuilder,
  getProcessorAsyncAutocompleteFieldProps,
  getNotaryAsyncAutocompleteFieldProps,
} from "../types";
import { PrimaryInformation } from "../../../types/Application/PrimaryInformation";
import { Application } from "../../../types/Application";
import { userStore } from "../../../stores/UserStore";
import { featureToggleStore } from "../../../lib/featureToggles/FeatureToggleStore";

export const fileInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  _formId,
  _onChange
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const primaryInformation = form as PrimaryInformation;
  const application = form as Application;

  const shouldDisableStatusFields =
    !!application?.monitor || !!application.isWithdrawn;

  const statusOptionValues = (productTypeValue?: string) => {
    const localProductTypeValue = productTypeValue
      ? productTypeValue
      : primaryInformation.productType?.toString();

    let optionValues = schemaStore.getMappingsByName(
      `productType${localProductTypeValue}`
    );

    if (optionValues.length <= 0) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "status").optionValues ||
        [];
    }
    return optionValues;
  };

  const reasonCodeOptionValues = (
    productTypeValue?: string,
    statusValue?: string
  ) => {
    const localProductTypeValue = productTypeValue
      ? productTypeValue
      : primaryInformation.productType?.toString();

    const localStatusValue = statusValue
      ? statusValue
      : primaryInformation.status?.toString();

    let optionValues = schemaStore.getMappingsByName(
      `productType${localProductTypeValue}-status${localStatusValue}`
    );

    if (optionValues.length <= 0) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "reasonCode")
          .optionValues || [];
    }

    return optionValues;
  };

  const internalStatusOptionValues = (
    productTypeValue?: string,
    statusValue?: string,
    reasonCodeValue?: string,
    isWithrawn?: boolean
  ) => {
    const localProductTypeValue = productTypeValue
      ? productTypeValue
      : primaryInformation.productType?.toString();

    const localStatusValue = statusValue
      ? statusValue
      : primaryInformation.status?.toString();

    const localReasonCode = reasonCodeValue
      ? reasonCodeValue
      : primaryInformation.reasonCode?.toString();

    let optionValues = schemaStore.getMappingsByName(
      `productType${localProductTypeValue}-status${localStatusValue}-reasonCode${localReasonCode}`
    );

    if (optionValues.length <= 0 || !!isWithrawn) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "internalStatus")
          .optionValues || [];
    }
    return optionValues;
  };

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "productType"),
      onSubmit: (productType: Option) => {
        const productTypeValue = productType.value;
        let statusValue = undefined;
        let reasonCodeValue = undefined;
        let internalStatusValue = undefined;

        const statusOptions = statusOptionValues(productType.value);

        if (statusOptions.length === 1) {
          statusValue = statusOptions[0].value;

          const reasonCodeOptions = reasonCodeOptionValues(
            productTypeValue,
            statusValue
          );

          if (reasonCodeOptions.length === 1) {
            reasonCodeValue = reasonCodeOptions[0].value;

            const internalStatusOptions = internalStatusOptionValues(
              productTypeValue,
              statusValue,
              reasonCodeValue
            );

            if (internalStatusOptions.length === 1) {
              internalStatusValue = internalStatusOptions[0].value;
            }
          }
        }

        onValueChange({
          productType: productTypeValue,
          status: statusValue,
          reasonCode: reasonCodeValue,
          internalStatus: internalStatusValue,
        });
      },
      value: primaryInformation.productType,
      isRequired: true,
      width: "24%",
      errorMessages: get(errors, "productType"),
      disabled: !!shouldDisableStatusFields,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "status"),
      optionValues: statusOptionValues(
        primaryInformation.productType?.toString()
      ),
      onSubmit: (status: Option) => {
        const statusValue = status.value;
        let reasonCodeValue = undefined;
        let internalStatusValue = undefined;

        const reasonCodeOptions = reasonCodeOptionValues(
          primaryInformation.productType?.toString(),
          statusValue
        );

        if (reasonCodeOptions.length === 1) {
          reasonCodeValue = reasonCodeOptions[0].value;

          const internalStatusOptions = internalStatusOptionValues(
            primaryInformation.productType?.toString(),
            statusValue,
            reasonCodeValue
          );

          if (internalStatusOptions.length === 1) {
            internalStatusValue = internalStatusOptions[0].value;
          }
        }

        onValueChange({
          status: statusValue,
          reasonCode: reasonCodeValue,
          internalStatus: internalStatusValue,
        });
      },
      value: primaryInformation.status,
      isRequired: true,
      disabled: !primaryInformation.productType || !!shouldDisableStatusFields,
      width: "24%",
      hidden: !!shouldDisableStatusFields,
      errorMessages: get(errors, "status"),
    } as AllFields,
    {
      type: "ReadOnly",
      label: schemaStore.getFieldSchemaByName(modelName, "status").label,
      value: primaryInformation.status_AsString,
      hidden: !shouldDisableStatusFields,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "reasonCode"),
      optionValues: reasonCodeOptionValues(
        primaryInformation.productType?.toString(),
        primaryInformation.status?.toString()
      ),
      onSubmit: (reasonCode: Option) => {
        const reasonCodeValue = reasonCode.value;
        let internalStatusValue = undefined;

        const internalStatusOptions = internalStatusOptionValues(
          primaryInformation.productType?.toString(),
          primaryInformation.status?.toString(),
          reasonCodeValue
        );

        if (internalStatusOptions.length === 1) {
          internalStatusValue = internalStatusOptions[0].value;
        }

        onValueChange({
          reasonCode: reasonCodeValue,
          internalStatus: internalStatusValue,
        });
      },
      value: primaryInformation.reasonCode,
      disabled: !primaryInformation.status || !!shouldDisableStatusFields,
      width: "24%",
      isRequired: true,
      errorMessages: get(errors, "reasonCode"),
      hidden: !!shouldDisableStatusFields,
    } as AllFields,
    {
      type: "ReadOnly",
      label: schemaStore.getFieldSchemaByName(modelName, "reasonCode").label,
      value: primaryInformation.reasonCode_AsString,
      hidden: !shouldDisableStatusFields,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "internalStatus"),
      optionValues: internalStatusOptionValues(
        primaryInformation.productType?.toString(),
        primaryInformation.status?.toString(),
        primaryInformation.reasonCode?.toString(),
        primaryInformation.isWithdrawn
      ),
      onSubmit: (internalStatus: Option) => {
        onValueChange({ internalStatus: internalStatus.value });
      },
      value: primaryInformation.internalStatus,
      width: "24%",
      isRequired: true,
      disabled: !primaryInformation.reasonCode || !!shouldDisableStatusFields,
      hidden: !!shouldDisableStatusFields,
    } as AllFields,
    {
      type: "ReadOnly",
      label: schemaStore.getFieldSchemaByName(modelName, "internalStatus")
        .label,
      value: primaryInformation.internalStatus_AsString,
      hidden: !shouldDisableStatusFields,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fieldRepId"),
      ...getFieldRepAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      width: "30%",
      value: primaryInformation.fieldRepId,
      disabled: !userStore.user?.canEditFieldRepAssignedToApplication,
      onSubmit: (fieldRepOptions: Option & { regions_AsString: string }) => {
        onValueChange({
          fieldRepId: fieldRepOptions?.value,
          fieldRepName: fieldRepOptions?.label,
          region: fieldRepOptions?.regions_AsString,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "processorId"),
      ...getProcessorAsyncAutocompleteFieldProps(),
      groupByActiveStatus: true,
      width: "30%",
      value: primaryInformation.processorId,
      disabled:
        !userStore.user?.canEditProcessorAssignedToApplication ||
        (application.isApplicationClosed &&
          !userStore.user?.canEditProcessorAssignedToClosedApplication),
      onSubmit: (processorOptions: Option) => {
        onValueChange({
          processorId: processorOptions?.value,
          processorName: processorOptions?.label,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "notaryId"),
      ...getNotaryAsyncAutocompleteFieldProps(),
      width: "30%",
      value: primaryInformation.notaryId,
      disabled: !userStore.user?.canEditApplicationNotary,
      onSubmit: (notaryOption: Option) => {
        onValueChange({
          notaryId: notaryOption?.value,
        });
      },
    } as AllFields,
    {
      label: "Region",
      value: primaryInformation.facility?.area_AsString,
      type: "Text",
      disabled: true,
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "originalAssessment"),
      onSubmit: (originalAssessment: Option) => {
        onValueChange({ originalAssessment: originalAssessment.value });
      },
      value: primaryInformation.originalAssessment,
      hidden:
        !application.isRecertProductType ||
        (application.isRecertProductType &&
          featureToggleStore.featureToggles?.EnableRecertAMD),
      width: "24%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "currentAssessment"),
      onSubmit: (currentAssessment: Option) => {
        onValueChange({ currentAssessment: currentAssessment.value });
      },
      value: primaryInformation.currentAssessment,
      hidden:
        !application.isRecertProductType ||
        (application.isRecertProductType &&
          featureToggleStore.featureToggles?.EnableRecertAMD),
      width: "24%",
    } as AllFields,
    {
      label: "Application Age",
      value: primaryInformation.applicationAge,
      disabled: true,
      type: "Text",
      width: "24%",
    } as AllFields,
    {
      label: "Processing Age",
      value: primaryInformation.processingAge,
      disabled: true,
      type: "Text",
      width: "24%",
    } as AllFields,
  ];
};
