import { computed, makeObservable, observable, reaction } from "mobx";
import { applicationApiClient } from "../lib/apiClients/application/applicationApiClient";
import { applicationConnectivityHub } from "../lib/hub/applicationConnectivityHub";
import {
  Application,
  ApplicationFormPage,
  ApplicationStatuses,
  NewApplication,
} from "../types/Application";
import { ApplicationContact } from "../types/Application/ApplicationContact";
import { AssetInformation, NewAsset } from "../types/Application/Asset";
import { Bill, NewBill } from "../types/Application/Bill";
import { BillInformation } from "../types/Application/BillInformation";
import { DateInformation } from "../types/Application/DateInformation";
import { NewIncome } from "../types/Application/Income";
import { IncomeInformation } from "../types/Application/IncomeInformation";
import { LegalInformation } from "../types/Application/LegalInformation";
import { NewMedicalCoverage } from "../types/Application/MedicalCoverage";
import { MedicalInformation } from "../types/Application/MedicalInformation";
import { PrimaryInformation } from "../types/Application/PrimaryInformation";
import { SecondaryInformation } from "../types/Application/SecondaryInformation";
import { SignatureHolderReadDTO } from "../types/Application/Signature";
import { Signatures } from "../types/Application/Signatures";
import { SpouseInformation } from "../types/Application/SpouseInformation";
import { StateInformation } from "../types/Application/StateInformation";
import { ExistingMonitor, Monitor } from "../types/Monitor";
import { userStore } from "./UserStore";
import { EligibilitySummaryResponseDTO } from "../types/Application/EligibilitySummary";
import { FinancialEligibilityConversation } from "../types/Application/FinancialEligibilityConversation";
import { FIARequestCancelDTO } from "../components/FIARequests/configurations/types";
import { fiaRequestApiClient } from "../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { featureToggleStore } from "../lib/featureToggles/FeatureToggleStore";

export type OwnedEntityPageItem = (
  | {
      page: "income";
      ownedEntity: NewIncome;
    }
  | {
      page: "bill";
      ownedEntity: NewBill | Bill;
    }
  | {
      page: "asset";
      ownedEntity: NewAsset;
    }
  | {
      page: "medical-coverage";
      ownedEntity: NewMedicalCoverage;
    }
  | {
      page: "contact";
      ownedEntity: ApplicationContact;
    }
  | {
      page: "signatures";
      ownedEntity: Signatures;
    }
) & {
  ownedEntityId?: string;
};

export class ApplicationStore {
  public application?: Application;
  public refreshActiveUser?: NodeJS.Timeout;
  public applicationCanBeEdited?: boolean;
  public canCreateChildApplication?: boolean;
  public canCreateComment?: boolean;
  public canCreateChecklist?: boolean;
  public canGenerateChecklist?: boolean;
  public canSplitChecklist?: boolean;
  public pageMissingFieldCount: {
    pageName: ApplicationFormPage;
    missingFieldCount: number;
  }[] = [];
  public eligibilitySummary?: EligibilitySummaryResponseDTO;
  public financialEligibilityConversationRequired?: boolean;

  constructor() {
    makeObservable(this, {
      application: observable,
      applicationCanBeEdited: observable,
      canCreateChildApplication: observable,
      canCreateComment: observable,
      canCreateChecklist: observable,
      canGenerateChecklist: observable,
      canSplitChecklist: observable,
      applicationDateInformationCanBeEdited: computed,
      pageMissingFieldCount: observable,
      eligibilitySummary: observable,
      financialEligibilityConversationRequired: observable,
    });

    reaction(
      () => this.application,
      () => {
        this.setApplicationCanBeEdited();
        this.setCanCreateChildApplication();
        this.setCanCreateComment();
        this.setCanCreateChecklist();
        this.setCanGenerateChecklist();
        this.setCanSplitChecklist();
        this.getPageMissingFieldCount();
        this.getEligibilitySummary();
        this.setUTCOffsetOnApplicationDateTimes();
        this.setFinancialEligibilityConversationRequired();
      }
    );
  }

  createApplication = async (primaryInformation: PrimaryInformation) => {
    this.application = await applicationApiClient.createApplication(
      primaryInformation as NewApplication
    );
  };

  getApplicationById = async (id: string) => {
    this.application = await applicationApiClient.getApplicationById(id);
    await applicationConnectivityHub.subscribe(id);
    this.refreshActiveUser = setInterval(() => {
      applicationConnectivityHub.subscribe(id);
    }, 30000);
  };

  evaluateApplicationAMDDecision = async (
    id: string,
    triggerIdentifier: string
  ) => {
    this.application =
      await applicationApiClient.evaluateApplicationAMDDecision(
        id,
        triggerIdentifier
      );
  };

  overrideAssetEligibility = async (
    id: string,
    overrideAssetEligibility: boolean
  ) => {
    this.application = await applicationApiClient.overrideAssetEligibility(
      id,
      overrideAssetEligibility
    );
  };

  get applicationDateInformationCanBeEdited() {
    return !!(
      userStore.user?.canEditNextRecertDateWhileClosed ||
      userStore.user?.canEditRecertNeededWhileClosed ||
      userStore.user?.canEditApplicationPageDateInformation
    );
  }

  setApplicationCanBeEdited = async () => {
    if (this.application) {
      this.applicationCanBeEdited = this.application?.isApplicationClosed
        ? userStore.user?.canEditApplicationWhenClosed
        : true;
    } else {
      this.applicationCanBeEdited = undefined;
    }
  };

  public setCanCreateChildApplication = async (): Promise<void> => {
    if (!this.application) {
      this.canCreateChildApplication = undefined;
      return;
    }

    this.canCreateChildApplication = this.application.isApplicationClosed
      ? this.canCreateChildInClosedApplication()
      : this.canCreateChildInOpenApplication();
  };

  private canCreateChildInClosedApplication(): boolean {
    return !!userStore.user?.canCreateNewChildWhileClosed;
  }

  private canCreateChildInOpenApplication(): boolean {
    return !!userStore.user?.canCreateChildApplication;
  }

  public setCanCreateComment = async (): Promise<void> => {
    if (!this.application) {
      this.canCreateComment = undefined;
      return;
    }

    this.canCreateComment = this.application.isApplicationClosed
      ? this.canCreateCommentInClosedApplication()
      : this.canCreateCommentInOpenApplication();
  };

  private canCreateCommentInClosedApplication(): boolean {
    return !!userStore.user?.canAddCommentWhileClosed;
  }

  private canCreateCommentInOpenApplication(): boolean {
    return !!userStore.user?.canCreateComment;
  }

  public setCanCreateChecklist = async (): Promise<void> => {
    if (!this.application) {
      this.canCreateChecklist = undefined;
      return;
    }

    this.canCreateChecklist = this.application.isApplicationClosed
      ? this.canCreateChecklistInClosedApplication
      : this.canCreateChecklistInOpenApplication;
  };

  public setCanGenerateChecklist = async (): Promise<void> => {
    if (!this.application) {
      this.canGenerateChecklist = undefined;
      return;
    }

    this.canGenerateChecklist = this.application.isApplicationClosed
      ? this.canGenerateChecklistInClosedApplication
      : this.canGenerateChecklistInOpenApplication;
  };

  public setCanSplitChecklist = async (): Promise<void> => {
    if (!this.application) {
      this.canSplitChecklist = undefined;
      return;
    }

    this.canSplitChecklist = this.application.isApplicationClosed
      ? this.canSplitChecklistInClosedApplication
      : this.canSplitChecklistInOpenApplication;
  };

  private get canCreateChecklistInClosedApplication(): boolean {
    return !!userStore.user?.canEditChecklistWhileClosed;
  }

  private get canCreateChecklistInOpenApplication(): boolean {
    return !!userStore.user?.canCreateChecklist;
  }

  private get canGenerateChecklistInClosedApplication(): boolean {
    return !!userStore.user?.canEditChecklistWhileClosed;
  }

  private get canGenerateChecklistInOpenApplication(): boolean {
    return !!userStore.user?.canGenerateChecklist;
  }

  private get canSplitChecklistInClosedApplication(): boolean {
    return !!userStore.user?.canEditChecklistWhileClosed;
  }

  private get canSplitChecklistInOpenApplication(): boolean {
    return !!userStore.user?.canSplitChecklist;
  }

  setFinancialEligibilityConversationRequired() {
    if (this.application && this.application.medicaidEligibilityDecision) {
      const decisionNeedsConversation =
        this.application.medicaidEligibilityDecision
          .residentIncomeExceedsStateThreshold ||
        this.application.medicaidEligibilityDecision
          .residentAssetsExceedsStateThreshold;

      const conversationHad =
        !!this.application.financialEligibilityConversationConfirmation;

      this.financialEligibilityConversationRequired =
        !conversationHad &&
        decisionNeedsConversation &&
        (!this.application.isRecertProductType ||
          (this.application.isRecertProductType &&
            featureToggleStore.featureToggles?.EnableRecertAMD));
    } else {
      this.financialEligibilityConversationRequired = undefined;
    }
  }

  unsetApplication = async () => {
    if (this.application?.id) {
      await applicationConnectivityHub.unsubscribe(this.application.id);
      clearInterval(this.refreshActiveUser);
      this.application = undefined;
    }
  };

  updateApplication = async (
    application:
      | PrimaryInformation
      | SecondaryInformation
      | MedicalInformation
      | SpouseInformation
      | AssetInformation
      | IncomeInformation
      | BillInformation
      | Signatures
      | DateInformation
      | LegalInformation
      | StateInformation,
    page: ApplicationFormPage,
    pageSource: string | undefined = undefined
  ) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.updateApplication(
      this.application.id,
      application,
      page,
      pageSource
    );
  };

  updateApplicationFacility = async (application: PrimaryInformation) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.updateApplicationFacility(
      this.application.id,
      application
    );
  };

  updateApplicationFinancialEligibilityConversation = async (
    application: FinancialEligibilityConversation
  ) => {
    if (!this.application) {
      return;
    }

    this.application =
      await applicationApiClient.updateApplicationFinancialEligibilityConversation(
        this.application.id,
        application
      );
  };

  createApplicationOwnedEntity = async (item: OwnedEntityPageItem) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.createApplicationOwnedEntity(
      this.application.id,
      item.ownedEntity,
      item.page
    );
  };

  updateApplicationOwnedEntity = async (item: OwnedEntityPageItem) => {
    if (!this.application || !item.ownedEntityId) {
      return;
    }

    this.application = await applicationApiClient.updateApplicationOwnedEntity(
      this.application.id,
      item.ownedEntityId,
      item.ownedEntity,
      item.page
    );
  };

  updateApplicationSignatures = async (
    signatures: SignatureHolderReadDTO[]
  ) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.updateApplicationSignatures(
      this.application.id,
      signatures
    );
  };

  deleteApplicationOwnedEntity = async (
    ownedEntityId: string,
    page: ApplicationFormPage
  ) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.deleteApplicationOwnedEntity(
      this.application.id,
      ownedEntityId,
      page
    );
  };

  withdrawApplication = async (withdrawnReason: number) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.withdrawApplication(
      this.application.id,
      withdrawnReason
    );
  };

  reactivateApplication = async () => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.reactivateApplication(
      this.application.id
    );
  };

  updateApplicationStatus = async (status: ApplicationStatuses) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.updateApplicationStatus(
      this.application.id,
      status
    );
  };

  convertApplicationToRecert = async () => {
    if (!this.application) {
      return;
    }
    this.application = await applicationApiClient.convertApplicationToRecert(
      this.application.id
    );
  };

  markChecklistAsGenerated = () => {
    if (!this.application) {
      return;
    }

    this.application.hasGeneratedChecklist = true;
  };

  createApplicationMonitor = async (monitorToCreate: Monitor) => {
    if (!this.application) {
      return;
    }

    this.application = await applicationApiClient.createApplicationMonitor(
      this.application.id,
      monitorToCreate
    );
  };

  updateApplicationMonitor = async (monitorToCreate: ExistingMonitor) => {
    if (!this.application?.monitor) {
      return;
    }

    this.application = await applicationApiClient.updateApplicationMonitor(
      this.application.id,
      this.application.monitor.id,
      monitorToCreate
    );
  };

  deleteApplicationMonitor = async (monitorId: string) => {
    if (!this.application?.monitor) {
      return;
    }

    this.application = await applicationApiClient.deleteApplicationMonitor(
      this.application.id,
      monitorId
    );
  };

  deleteApplicationMonitorWithEndDate = async (
    monitorToDelete: ExistingMonitor
  ) => {
    if (!this.application?.monitor) {
      return;
    }

    this.application =
      await applicationApiClient.deleteApplicationMonitorWithEndDate(
        this.application.id,
        monitorToDelete
      );
  };

  submitApplicationToState = async (comment?: string) => {
    if (!this.application?.id) {
      return;
    }

    this.application = await applicationApiClient.submitApplicationToState(
      this.application.id,
      comment
    );
  };

  getPageMissingFieldCount = async (): Promise<void> => {
    if (!this.application) {
      return;
    }

    this.pageMissingFieldCount =
      await applicationApiClient.getPageMissingFieldCount(this.application.id);
  };

  getEligibilitySummary = async (): Promise<void> => {
    if (!this.application) {
      return;
    }

    this.eligibilitySummary = await applicationApiClient.getEligibilitySummary(
      this.application.id
    );
  };

  // Add Z Offset to these date times so they can be accurately converted to EST in DateField component
  setUTCOffsetOnApplicationDateTimes = async (): Promise<void> => {
    if (!this.application) {
      return;
    }

    if (!!this.application.hearingDate) {
      this.application.hearingDate = `${this.application.hearingDate}Z`;
    }
    if (!!this.application.interviewDate) {
      this.application.interviewDate = `${this.application.interviewDate}Z`;
    }
  };

  cancelAllFIARequests = async (
    applicationId: string,
    fiaRequestCancelDTO: FIARequestCancelDTO
  ) => {
    if (!this.application?.id) {
      return;
    }

    await fiaRequestApiClient
      .cancelAllRequests(applicationId, fiaRequestCancelDTO)
      .then(() => this.getApplicationById(applicationId));
  };

  setHasPendingFIARequestsOnAplication = async () => {
    if (!this.application?.id) {
      return;
    }
    this.application.hasPendingFIARequests = true;
  };
}

export const applicationStore = new ApplicationStore();
