import { Button, Intent } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { Loading } from "../Loading/Loading";
import { AppToaster } from "../Toast/Toast";
import { FIARequest, FIARequestNewDTO } from "./configurations/types";
import { FIARequestDetails } from "./FIARequestDetails";
import { fiaRequestStore } from "./stores/FIARequestStore";
import { useFIARequestCreate } from "./UseFIARequestFlyout";
import { Footer } from "@ucl/library";
import { setIsUpdatedFIAPending } from "./FIARequestFlyout";

export interface FIARequestCreateProps {
  applicationId: string;
  onCancel: () => void;
  setFIARequestFromFIACreate: (fiaRequest: FIARequest) => void;
}

export const FIARequestCreate: React.FC<FIARequestCreateProps> = (props) => {
  const {
    isSaving,
    setIsSaving,
    ready,
    errors,
    setErrors,
    fiaRequest,
    setFIARequest,
  } = useFIARequestCreate(props.applicationId);

  useEffect(() => {
    if (fiaRequest.institutionId) {
      props.setFIARequestFromFIACreate(fiaRequest);
    }
  }, [fiaRequest?.institutionId]);

  return (
    <div className="fia-request-create">
      {ready && (
        <>
          <BaseForm<FIARequestNewDTO>
            formId="fia-request-create-form"
            onFormSubmit={async (newFIARequest: FIARequestNewDTO) => {
              setIsSaving(true);
              try {
                await fiaRequestStore
                  .create(props.applicationId, newFIARequest)
                  .then(() => {
                    if (fiaRequestStore.fiaRequest) {
                      props.setFIARequestFromFIACreate(
                        fiaRequestStore.fiaRequest
                      );

                      setIsUpdatedFIAPending(fiaRequestStore.fiaRequest);
                    }
                  })
                  .catch((error) => {
                    if (error.response?.status !== 400) {
                      AppToaster.show({
                        message: <div>{genericErrorMessage}</div>,
                        intent: Intent.DANGER,
                      });
                    }

                    throw error;
                  });
              } finally {
                setIsSaving(false);
              }
            }}
            isDisabled={isSaving}
            value={fiaRequest}
            formValueState={{
              formValue: fiaRequest,
              setFormValue: setFIARequest,
            }}
            onErrors={(errors) => setErrors(errors)}
          >
            <FIARequestDetails
              fiaRequest={fiaRequest}
              applicationId={props.applicationId}
              errors={errors}
              setFIARequest={setFIARequest}
            />
          </BaseForm>

          {/* <div className="fia-request-footer">
            <div className="fia-request-footer-panel"> */}
          <Footer>
            <Button
              minimal
              intent={Intent.DANGER}
              text="Cancel"
              onClick={props.onCancel}
            />
            <FormSubmitButton
              buttonId="fia-request-create-button"
              isSaving={isSaving}
              formId="fia-request-create-form"
              text="Create"
            />
          </Footer>
          {/* </div>
          </div> */}
        </>
      )}
      {!ready && <Loading />}
    </div>
  );
};
