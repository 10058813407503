import React from "react";
import {
  Filter,
  GridComponent,
  GridModel,
  InfiniteScroll,
  Inject,
  Page,
  Reorder,
  Sort,
  Toolbar,
  Resize,
  Group,
  Search,
  ExcelExport,
  PredicateModel,
  SortDescriptorModel,
  ContextMenu,
  Selection,
} from "@syncfusion/ej2-react-grids";
import "../Grid/styles.scss";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import {
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";
import GridColumn from "./types/GridColumn";
import { getAccessToken } from "../../../lib/apiClients/baseApiClient";
import { GridSetup, gridStore } from "./stores/GridStore";
import { BaseGridModel } from "./types/BaseGridModel";
import GridHeightSettings from "../Grid/types/GridHeightSettings";
import "./styles.scss";

export const GridInstanceContext = React.createContext<GridComponent | null>(
  null
);

export interface GridProps<T extends object> {
  gridKey: string;
  gridViewTitle?: string;
  dataSource: string;
  columnsConfiguration: Readonly<GridColumn[]>;
  filterConfiguration?: Readonly<PredicateModel[]>;
  sortConfiguration?: Readonly<SortDescriptorModel[]>;
  gridConfiguration?: Readonly<BaseGridModel<T>>;
  gridHeightSettings?: GridHeightSettings;
  className?: string;
  setupActions?: (columns: GridColumn[]) => GridColumn[];
}

export const Grid = <T extends object>(props: GridProps<T>) => {
  const [ready, setReady] = React.useState<boolean>(false);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [gridSettings, setGridSettings] = React.useState<
    GridModel & DefaultHtmlAttributes
  >();
  const [tooltipText, setTooltipText] = React.useState<string>();

  const gridRef = React.useRef<GridComponent | null>(null);

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      gridStore.reset();
    };
  }, []);

  // Initialize
  React.useEffect(() => {
    if (!!gridRef.current && !initialized) {
      gridStore.initialize(gridRef.current);
      setInitialized(true);
    }
  });

  // Setup Method
  const setup = async () => {
    const gridModel = await gridStore.setup({
      gridKey: props.gridKey,
      gridViewTitle: props.gridViewTitle,
      dataSource: props.dataSource,
      getAccessToken: getAccessToken,
      columnsConfiguration: props.columnsConfiguration,
      filterConfiguration: props.filterConfiguration,
      sortConfiguration: props.sortConfiguration,
      gridConfiguration: props.gridConfiguration,
      gridHeightSettings: props.gridHeightSettings,
      setupActions: props.setupActions,
    } as GridSetup<T>);
    setGridSettings(gridModel);
    setReady(true);
  };

  // Set Tooltip on Column hover
  const beforeRender = (args: TooltipEventArgs) => {
    // Only show a tooltip for long character-length column values.
    if (!args.target.innerText || args.target.innerText.length < 25) {
      args.cancel = true;
    }

    setTooltipText(args.target.innerText);
  };

  return (
    <div className="grid-container">
      {ready && (
        <TooltipComponent
          id="Tooltip"
          target=".e-rowcell"
          beforeRender={beforeRender}
          content={tooltipText}
          className={props.className}
        >
          <GridInstanceContext.Provider value={gridRef.current}>
            <GridComponent ref={gridRef} className="grid" {...gridSettings}>
              <Inject
                services={[
                  Page,
                  Sort,
                  Filter,
                  Group,
                  Toolbar,
                  Reorder,
                  Resize,
                  InfiniteScroll,
                  Search,
                  Selection,
                  ExcelExport,
                  ContextMenu,
                ]}
              />
            </GridComponent>
          </GridInstanceContext.Provider>
        </TooltipComponent>
      )}
      {!ready && <></>}
    </div>
  );
};
