import { MaskedField } from "../MaskedField/MaskedField";
import { PhoneFieldProps } from "../types/fieldTypes";

export const PhoneField: React.FC<PhoneFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
}) => {
  return (
    <MaskedField
      type="Text"
      description={description}
      label={label}
      maskFieldProps={{
        mask: "([0-9][0-9][0-9]) [0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]",
        placeholder: "(999) 999-9999",
      }}
      value={value}
      disabled={disabled}
      onSubmit={onSubmit}
      hidden={hidden}
      errorMessages={errorMessages}
      isRequired={isRequired}
    />
  );
};
