import { FC } from "react";
import { SimplePdfDocuments } from "../Document/SimplePdfDocuments";
import { PdfDocumentEntityType } from "../Document/PdfDocumentUploader";
import { Document } from "../../types/Document";
import "./corporateDocumentsStyles.scss";

interface CorporateDocumentsProps {
  corporateId: string;
  corporateDocuments: { corporateId: string; documentId: string }[];
  onSubmit: (docs: Document[]) => void;
}

export const CorporateDocuments: FC<CorporateDocumentsProps> = (props) => {
  return (
    <div className="corporate-documents">
      <SimplePdfDocuments
        id={`corporate-${props.corporateId}`}
        documentIds={props.corporateDocuments.map((doc) => doc.documentId)}
        entityId={props.corporateId}
        entityType={PdfDocumentEntityType.Corporate}
        disableUploadDoc={false}
        disablePreviewDoc={false}
        disableOpenNewTabDoc={false}
        disableDownloadDoc={false}
        disableDeleteDoc={false}
        confirmDeletion={true}
        onDocChanged={props.onSubmit}
      />
    </div>
  );
};
