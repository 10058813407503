import { usStateOptions } from "../../../constants/usStateOptions";
import { AddressFieldValue, Option } from "../types/fieldTypes";

export type AddressFormatTypes = "simple";

export const formatAddress = (
  address: AddressFieldValue,
  formatType: AddressFormatTypes = "simple"
) => {
  const addressState_AsString = convertAddressStateValueToDisplayString(
    usStateOptions,
    address.state
  );

  switch (formatType) {
    case "simple":
      let simpleAddress = `${address.street}, `;

      if (address.street2) {
        simpleAddress += `${address.street2}, `;
      }

      simpleAddress += `${address.city}, ${addressState_AsString} ${address.zip} ${address.county}`;

      return simpleAddress;
    default:
      throw new Error(`unhandled address format type: ${formatType}`);
  }
};

const convertAddressStateValueToDisplayString = (
  usStates: Option[],
  addressState: string
) => {
  const match = usStates.find(
    (value) => value.value === addressState.toString()
  );

  if (!match || !match.label) {
    return "";
  }

  return match.label;
};
