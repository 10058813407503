import { Spinner } from "@blueprintjs/core";
import { FC } from "react";

export interface FormSubmitButtonProps {
  isSaving: boolean;
  formId: string;
  buttonId: string;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
}

export const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  isSaving,
  formId,
  buttonId,
  text = "Submit",
  disabled,
}) => {
  return (
    <button
      id={buttonId}
      className="bp4-button bp4-intent-success"
      type="submit"
      form={formId}
      disabled={isSaving || disabled}
    >
      {isSaving ? <Spinner size={18.391} /> : text}
    </button>
  );
};
