import {
  Icon,
  Menu,
  MenuItem,
  MenuItemProps,
  Position,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 } from "@blueprintjs/popover2";
import { ColumnModel } from "@syncfusion/ej2-react-grids";
import { GridActionType } from "./GridActionsColumn";
import GridColumn from "./GridColumn";

interface GridMenuColumnOptions<T>
  extends Omit<
    ColumnModel,
    "field" | "type" | "showInColumnChooser" | "allowFiltering" | "allowSorting"
  > {
  menus: (MenuItemProps & {
    onMenuClick: (invoice: T) => void;
  })[];
}
export default class GridMenuColumn<T> extends GridColumn {
  constructor(opts: GridMenuColumnOptions<T>) {
    const { menus, ...superOpts } = opts;
    super({
      ...superOpts,
      field: "id",
      showInColumnChooser: false,
      allowFiltering: false,
      allowSorting: false,
      type: GridActionType,
      template: ((model: T) => {
        return (
          <Popover2
            content={
              <Menu>
                {menus.map((menu, i) => (
                  <MenuItem
                    key={i}
                    {...menu}
                    onClick={() => {
                      menu.onMenuClick(model);
                    }}
                  />
                ))}
              </Menu>
            }
            position={Position.RIGHT_TOP}
          >
            <Icon icon={IconNames.More} />
          </Popover2>
        );
      }) as any,
    });
  }
}
