import { SimpleGridStore } from "../../../stores/SimpleGridStore";
import { Corporate } from "../../../types/Corporate";

export class BillingRuleGridStore extends SimpleGridStore {
  public corporateDetail?: Pick<
    Corporate,
    | "annualRateIncrease"
    | "contractEffectiveDate"
    | "nextRateIncreaseDate"
    | "annualRateIncreaseEffectiveProductTypes"
    | "isAnnualRateIncreaseEnabled"
  >;

  public setCorporateDetail = (
    corporateDetail: Pick<
      Corporate,
      | "annualRateIncrease"
      | "contractEffectiveDate"
      | "nextRateIncreaseDate"
      | "annualRateIncreaseEffectiveProductTypes"
      | "isAnnualRateIncreaseEnabled"
    >
  ) => {
    this.corporateDetail = corporateDetail;
  };
}

export const billingRuleGridStore = new BillingRuleGridStore();
