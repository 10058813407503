import { Card, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import { Application } from "../../../types/Application";
import { MedicalInformation } from "../../../types/Application/MedicalInformation";
import { FormSubmitButton } from "../../Buttons/FormSubmitButton";
import Form from "../../Forms";
import {
  medicalInformationFormSchema,
  newMedicalInformation,
} from "../../Forms/ApplicationForm/MedicalInformation";
import { AppToaster } from "../../Toast/Toast";

export interface MedicalInformationCardProps {
  application?: Application;
  canEdit?: boolean;
  submitForm: (application: MedicalInformation) => Promise<void>;
}

export const MedicalInformationCard: FC<MedicalInformationCardProps> = ({
  application,
  canEdit,
  submitForm,
}) => {
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <>
      <Card className="medical-information-card">
        <Form<MedicalInformation>
          formId="medical-information-form"
          shouldBlockNavigation={true}
          value={application || newMedicalInformation}
          disableAllFields={shouldDisableForm || !canEdit}
          formSchemaBuilder={medicalInformationFormSchema}
          onFormSubmit={async (application) => {
            setShouldDisableForm(true);
            setIsSaving(true);
            await submitForm(application)
              .then(() => {
                AppToaster.show({
                  message: (
                    <div>
                      <h3>Success</h3>Application Saved
                    </div>
                  ),
                  intent: Intent.SUCCESS,
                });
              })
              .finally(() => {
                setIsSaving(false);
                setShouldDisableForm(false);
              });
          }}
        />
      </Card>
      <div className="application-card-footer">
        <FormSubmitButton
          disabled={!canEdit}
          buttonId="medical-information-save-button"
          isSaving={isSaving}
          formId="medical-information-form"
          text="Save"
        />
      </div>
    </>
  );
};
