import { get } from "lodash";
import { useEffect, useState } from "react";
import { applicationApiClient } from "../../../lib/apiClients/application/applicationApiClient";
import { corporateApiClient } from "../../../lib/apiClients/corporate/corporateApiClient";
import { facilityApiClient } from "../../../lib/apiClients/facility/facilityApiClient";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  Application,
  getApplicationProductType,
} from "../../../types/Application";
import { Billing, NewBilling } from "../../../types/Billing";
import { Corporate } from "../../../types/Corporate";
import { Facility } from "../../../types/Facility";
import {
  AllFields,
  SectionHeaderProps,
  Option,
} from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getApplicationAsyncAutocompleteFieldProps,
  getInvoiceNumberAsyncAutocompleteFieldProps,
} from "../types";

export const billingCreateForm: Partial<Billing> = {
  applicationId: undefined,
  corporateId: undefined,
  name: "",
  billingDate: "",
  invoiceId: undefined,
  amount: 0,
  isShown: false,
  isDiscounted: false,
};

export const billingEventFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "billing";
  const billing = form as NewBilling;
  const [application, setApplication] = useState<Application>();
  const [facility, setFacility] = useState<Facility>();
  const [corporate, setCorporate] = useState<Corporate>();

  useEffect(() => {
    if (billing.applicationId) {
      applicationApiClient
        .getApplicationById(billing.applicationId)
        .then(setApplication);
    }
  }, [billing.applicationId]);

  useEffect(() => {
    if (application?.facilityId) {
      facilityApiClient
        .getFacilityDetail(application.facilityId)
        .then(setFacility);
    } else {
      setFacility(undefined);
    }
  }, [application]);

  useEffect(() => {
    if (facility?.corporateId) {
      billing.corporateId = facility.corporateId;

      corporateApiClient
        .getCorporateDetail(facility.corporateId)
        .then(setCorporate);
    } else {
      setCorporate(undefined);
    }
  }, [facility]);

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "applicationId"),
      ...getApplicationAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "applicationId"),
      value: billing.applicationId,
      onSubmit: (options: Option) => {
        onValueChange({ applicationId: options?.value });
      },
      isRequired: true,
    } as unknown as AllFields,
    {
      type: "Text",
      label: "Resident First Name",
      value: application?.firstName || "",
      errorMessages: get(errors, "firstName"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      type: "Text",
      label: "Resident Last Name",
      value: application?.lastName || "",
      errorMessages: get(errors, "lastName"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      type: "Text",
      label: "Facility",
      value: facility?.facilityName || "",
      errorMessages: get(errors, "facility"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "corporateId"),
      value: billing.corporateId,
      onSubmit: (corporateId) => {
        onValueChange({ corporateId });
      },
      errorMessages: get(errors, "corporateId"),
      disabled: true,
      isRequired: true,
      hidden: true,
    } as AllFields,
    {
      type: "Text",
      label: "Corporate",
      value: corporate?.companyName || "",
      errorMessages: get(errors, "corporate"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      type: "Text",
      label: "Product Type",
      value: getApplicationProductType(application?.productType) || "",
      errorMessages: get(errors, "productType"),
      isRequired: false,
      disabled: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "name"),
      value: billing.name,
      onSubmit: (name) => {
        onValueChange({ name });
      },
      errorMessages: get(errors, "name"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "invoiceNumber_AsString"),
      ...getInvoiceNumberAsyncAutocompleteFieldProps(),
      value: billing.invoiceNumber_AsString,
      onSubmit: (option: Option & { invoiceId: string }) => {
        onValueChange({
          invoiceNumber_AsString: option?.value,
          invoiceId: option?.invoiceId,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "billingDate"),
      value: billing.billingDate,
      onSubmit: (billingDate) => {
        onValueChange({ billingDate });
      },
      errorMessages: get(errors, "billingDate"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "amount"),
      value: billing.amount,
      onSubmit: (amount: number) => {
        onValueChange({
          amount,
          billingRuleAmount: amount,
          isCredit: amount < 0,
        });
      },
      errorMessages: get(errors, "amount"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isCredit"),
      value: billing.isCredit,
      onSubmit: (isCredit) => {
        onValueChange({ isCredit });
      },
      errorMessages: get(errors, "isCredit"),
      hidden: true,
      disabled: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isShown"),
      value: billing.isShown,
      onSubmit: (isShown) => {
        onValueChange({ isShown });
      },
      errorMessages: get(errors, "isShown"),
      isRequired: false,
    } as AllFields,
  ];
};
