import { makeObservable, observable } from "mobx";
import {
  AutoCompleteComponent,
  AutoCompleteModel,
  ChangeEventArgs,
  SelectEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import { Query } from "@syncfusion/ej2-data";
import { TemplateODataReadDTO } from "../../TemplateGrid/configurations/types";
import { ODataStore } from "../../../stores/ODataStore";

export class TemplatePickerStore extends ODataStore {
  // public
  public autoCompleteInstance?: AutoCompleteComponent;
  public onTemplatePicked?: (
    templateODataReadDTO?: TemplateODataReadDTO
  ) => void;

  // Events
  showSearchPopup = () => {
    if (!!this.autoCompleteInstance) {
      this.autoCompleteInstance.showPopup();
    }
  };

  handleSearchSelect = async (args: SelectEventArgs) => {
    if (!!this.autoCompleteInstance) {
      const data = args?.itemData as TemplateODataReadDTO;

      if (data) {
        this.onTemplatePicked && this.onTemplatePicked(data);
      }
    }
  };

  handleChange = async (args: ChangeEventArgs) => {
    if (!!this.autoCompleteInstance) {
      if (!args.value) {
        this.onTemplatePicked && this.onTemplatePicked(undefined);
      }
    }
  };

  private defaultAutoCompleteSettings: AutoCompleteModel &
    DefaultHtmlAttributes = {
    fields: { value: "templateName" },
    itemTemplate:
      "<table><tr><td>${templateName}</td><td>${templateType_AsString}</td><td>${templateState_AsString}</td></tr><table>",
    autofill: true,
    showClearButton: false,
    highlight: true,
    placeholder: "Enter Template Name",
    select: this.handleSearchSelect,
    focus: this.showSearchPopup,
    onClick: this.showSearchPopup,
    change: this.handleChange,
  };

  constructor() {
    super();

    makeObservable(this, {
      autoCompleteInstance: observable,
    });
  }

  public setup = async (args: {
    odataUrl: string;
    getAccessToken: () => Promise<string | undefined>;
    onTemplatePicked: (templateODataReadDTO?: TemplateODataReadDTO) => void;
    odataExcludeIds?: string[];
  }) => {
    await this.init(args.getAccessToken);

    this.onTemplatePicked = args.onTemplatePicked;

    // Setup Properties
    const autoCompleteSettings = {
      ...this.defaultAutoCompleteSettings,
    } as AutoCompleteModel & DefaultHtmlAttributes;

    const dm = this.setupODataSource(args.odataUrl);
    autoCompleteSettings.dataSource = dm;

    // Set query
    let query = new Query()
      .select(Object.getOwnPropertyNames({} as TemplateODataReadDTO))
      .take(10);

    args.odataExcludeIds?.forEach((idToExclude) => {
      query = query.where("id", "notEqual", idToExclude, false);
    });

    query = query.sortBy("templateName", "asc");

    autoCompleteSettings.query = query;

    autoCompleteSettings.actionFailure = this.baseActionFailureEvent(() => {
      this.autoCompleteInstance?.hidePopup();
      setTimeout(() => {
        this.autoCompleteInstance?.showPopup();
      }, 100);
    });
    autoCompleteSettings.actionComplete = this.baseActionCompleteEvent();

    return autoCompleteSettings;
  };

  public initialize = (newAutoCompleteInstance: AutoCompleteComponent) => {
    this.setInstance(newAutoCompleteInstance);
  };

  public reset = () => {
    this.setInstance(undefined);
  };

  public setInstance = (scopeAutoCompleteInstance?: AutoCompleteComponent) => {
    this.autoCompleteInstance = scopeAutoCompleteInstance;

    // Additional Settings
    if (scopeAutoCompleteInstance) {
      const element = scopeAutoCompleteInstance.element as HTMLInputElement;
      if (element) {
        element.maxLength = 38;
      }
    }
  };
}

export const templatePickerStore = new TemplatePickerStore();
