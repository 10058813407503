import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import { PdfDocumentEntity } from "../Document/PdfDocumentUploader";
import React from "react";

import { OrderedDocumentsPicker } from "../OrderedDocumentsGrid/OrderedDocumentsPicker";
import { OrderedDocumentCreateDTO } from "../OrderedDocumentsGrid/configurations/types";

export interface OrderedDocumentsPickerDialogProps extends BaseDialogProps {
  submitDialog: (
    orderedDocumentCreateDTOs?: OrderedDocumentCreateDTO[]
  ) => void;
  pdfDocumentEntity?: PdfDocumentEntity;
  templateOdataExcludeIds?: string[];
}

export const OrderedDocumentsPickerDialog: React.FC<
  OrderedDocumentsPickerDialogProps
> = ({
  pdfDocumentEntity,
  isOpen,
  closeDialog,
  submitDialog,
  templateOdataExcludeIds,
}) => {
  const [documentsPicked, setDocumentsPicked] = React.useState<
    OrderedDocumentCreateDTO[]
  >([]);

  return (
    <Dialog
      portalClassName="mdr-dialog ordered-document-picker-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Add Document"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <section className="ordered-document-picker-dialog-container">
          <OrderedDocumentsPicker
            pdfDocumentEntity={pdfDocumentEntity!}
            onDocumentPicked={(orderedDocumentCreateDTO) => {
              if (orderedDocumentCreateDTO) {
                setDocumentsPicked((prevDocuments) => [
                  ...prevDocuments,
                  orderedDocumentCreateDTO,
                ]);
              }
            }}
            templateOdataExcludeIds={templateOdataExcludeIds}
          />
        </section>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <Button
          className="bp4-button bp4-intent-success"
          text="Save"
          disabled={!documentsPicked.length}
          onClick={() => submitDialog(documentsPicked)}
        />
      </div>
    </Dialog>
  );
};
