import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState, useEffect } from "react";
import { checklistItemStore } from "../../stores/ChecklistItemStore";
import { ChecklistItem } from "../../types/Checklist";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { checklistShortDescriptionFormSchema } from "../Forms/ChecklistForm/ChecklistShortDescriptionForm";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";

export interface ChecklistItemUpdateDialogProps extends BaseDialogProps {
  onSuccess?: (updatedChecklistItem: ChecklistItem) => void;
}

export const ChecklistItemUpdateDialog: React.FC<
  ChecklistItemUpdateDialogProps
> = ({ isOpen, closeDialog, onSuccess }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [localChecklistItem, setLocalChecklistItem] = useState<ChecklistItem>();

  useEffect(() => {
    if (
      !!dialogsViewerStore.checklistItemUpdateDialogOpenOptions?.checklistItem
    ) {
      setLocalChecklistItem(
        dialogsViewerStore.checklistItemUpdateDialogOpenOptions?.checklistItem
      );
    }

    return () => {
      setLocalChecklistItem(undefined);
    };
  }, [dialogsViewerStore.checklistItemUpdateDialogOpenOptions]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Checklist Item Description"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Form
          formId="checklist-item-form-update"
          value={localChecklistItem}
          formSchemaBuilder={checklistShortDescriptionFormSchema}
          onFormSubmit={async (checklistItem: ChecklistItem) => {
            setIsSaving(true);
            try {
              if (!!checklistItem.id) {
                await checklistItemStore.updateChecklistItem_NoGridRefresh(
                  checklistItem.id,
                  checklistItem
                );

                if (!!onSuccess) {
                  onSuccess(checklistItem);
                }
              }
              closeDialog();
            } finally {
              setIsSaving(false);
            }
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="checklist-item-button"
          isSaving={isSaving}
          formId="checklist-item-form-update"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
