import { Icon } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import { ColumnModel, GridColumn } from "@syncfusion/ej2-react-grids";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";

interface GridClickableIconColumnOptions extends ColumnModel {
  columnName: string;
  icon: IconName;
  dialogType: DialogTypes;
}

type DialogTypes = "applicationSummary";

const openDialog = (dialogType: DialogTypes, modelId: string) => {
  switch (dialogType) {
    case "applicationSummary":
      dialogsViewerStore.setIsApplicationSummaryDialogOpen(true, {
        applicationId: modelId,
      });
      break;
    default:
      throw new Error(
        `Cannot create column with unexpected dialogType: ${dialogType}`
      );
  }
};

export default class GridClickableIconColumn extends GridColumn {
  constructor(opts: GridClickableIconColumnOptions) {
    const { columnName, icon, dialogType, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const fieldValue = model[columnName];
        const modelId = model["id"];
        return (
          <div
            className="icon-grid-column"
            onClick={(e) => {
              e.stopPropagation();
              openDialog(dialogType, modelId);
            }}
          >
            <Icon className="base-grid-icon" icon={icon}></Icon>
            {fieldValue}
          </div>
        );
      }) as any,
    });
  }
}
