import { ColumnModel } from "@syncfusion/ej2-react-grids";
import GridColumn from "./GridColumn";

interface GridLinkColumnOptions extends ColumnModel {
  linkField: string;
  linkLabelField: string;

  facilityState?: string;
  webUsername?: string;
  webPassword?: string;
}

export default class GridLinkColumn extends GridColumn {
  constructor(opts: GridLinkColumnOptions) {
    const {
      linkField,
      linkLabelField,
      webUsername,
      webPassword,
      facilityState,
      ...superOpts
    } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const userName = webUsername ? model[webUsername] : undefined;
        const userPassword = webPassword ? model[webPassword] : undefined;
        const userFacilityState = facilityState
          ? model[facilityState]
          : undefined;

        const fieldLabelLink = model[linkLabelField];

        const shouldRenderLinkWithCredentials =
          !!userName &&
          !!userPassword &&
          !!userFacilityState &&
          userFacilityState === "FL";

        return shouldRenderLinkWithCredentials ? (
          autoLoginlinkToRender(
            userFacilityState,
            userName,
            userPassword,
            fieldLabelLink
          )
        ) : (
          <a
            target="_blank"
            href={model[linkField]}
            onClick={(e) => {
              e.stopPropagation();
            }}
            rel="noreferrer"
          >
            {model[linkLabelField]}
          </a>
        );
      }) as any,
    });
  }
}

const autoLoginlinkToRender = (
  userFacilityState: string,
  userName: string,
  userPassword: string,
  linkLabelField: string
) => {
  switch (userFacilityState) {
    case "FL":
      return (
        <form
          target="_blank"
          action="https://dcf-access.dcf.state.fl.us/access/accountLogin.do"
          method="post"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            type="hidden"
            name="accountDetail.userInfo.userName"
            value={userName}
          />
          <input
            type="hidden"
            name="accountDetail.userInfo.password"
            value={userPassword}
          />
          <input type="hidden" name="imgNext.x" value="38" />
          <input type="hidden" name="imgNext.y" value="10" />
          <input
            type="submit"
            value={linkLabelField}
            style={{
              background: "none",
              border: "none",
              color: "blue",
              cursor: "pointer",
              display: "inline",
              margin: 0,
              padding: 0,
            }}
          />
        </form>
      );
    // add more cases as necessary
    default:
      return null;
  }
};
