import { Elevation } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { MedicalCoverage } from "../../../../../../types/Application/MedicalCoverage";

import { DeleteButton } from "../../../../../Buttons/DeleteButton";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../../../Forms";
import { medicalCoverageFormSchema } from "../../../../../Forms/ApplicationForm/MedicalCoverage";
import { showSuccessfulSaveAppToaster } from "../../../../../Toast/Toast";
import { SaveButton } from "../../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../../Buttons/UndoButton";
import { medicalCoverageOwnedEntityStore } from "../../../../../../stores/OwnedEntityStores/MedicalCoverageOwnedEntityStore";
import { FIARequest } from "../../../../../FIARequests/configurations/types";
import { fiaRequestApiClient } from "../../../../../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { OwnedEntityFIARequestSubCard } from "../../../../../FIARequests/OwnedEntityFIARequestsSubCard";

export interface MedicalCoveragePageCardProps {
  medicalCoverage: MedicalCoverage;
  localMedicalCoverages: MedicalCoverage[];
  setLocalMedicalCoverages: (medicalCoverages: MedicalCoverage[]) => void;
  applicationId?: string;
  index: number;
  canEdit: boolean;
  createAndUpdateMedicalCoverages: () => Promise<void>;
  deleteMedicalCoverage: (medicalCoverage: MedicalCoverage) => Promise<void>;
  getMedicalCoverageComputedState: (
    value: Partial<MedicalCoverage>
  ) => Promise<Partial<MedicalCoverage>>;
  isAdding: boolean;
  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
}

const newMedicalCoverageId = "new-medical-coverage";
const sectionTitle = "Medical Coverage";
const baseFormId = "medical-coverage-form";
export const MedicalCoveragePageCard: FC<MedicalCoveragePageCardProps> = (
  props
) => {
  const [fiaRequest, setFiaRequest] = useState<FIARequest | undefined>(
    undefined
  );
  const [isInDeleteAction, setIsInDeleteAction] = useState<boolean>(false);
  const isNewMedicalCoverage =
    props.medicalCoverage.id === newMedicalCoverageId;

  const handleReset = async (medicalCoverageToReset: MedicalCoverage) => {
    const existingLocalMedicalCoverages = [...props.localMedicalCoverages];

    existingLocalMedicalCoverages[props.index] = {
      ...props.medicalCoverage,
      ...medicalCoverageToReset,
    };

    props.setLocalMedicalCoverages(existingLocalMedicalCoverages);
  };

  useEffect(() => {
    if (!!props.applicationId && !isNewMedicalCoverage) {
      fiaRequestApiClient
        .getMostRecentRequestByOwnedEntityId(
          props.applicationId,
          props.medicalCoverage.id
        )
        .then((res) => setFiaRequest(res));
    }

    return () => {
      setFiaRequest(undefined);
    };
  }, [props.medicalCoverage.id]);

  return (
    <RelativityCard
      elevation={Elevation.TWO}
      key={props.medicalCoverage.id}
      className="owned-entity-card medical-coverage-card"
      headerContent={
        <div className="owned-entity-header medical-coverage-card-header">
          <SectionHeader
            label={`${sectionTitle} ${props.index + 1}`}
            type="Section"
            inAction={props.formIsSaving}
          />
          <div>
            {props.canEdit && (
              <>
                <>
                  <SaveButton
                    formId={`${baseFormId}-${props.medicalCoverage.id}`}
                    isSaving={props.formIsSaving}
                    disabled={props.shouldDisableForm || isInDeleteAction}
                    hidden={isInDeleteAction}
                  />
                  <UndoButton
                    isSaving={props.formIsSaving || isInDeleteAction}
                    disabled={props.shouldDisableForm}
                    onClick={async () => {
                      //Need to Await Changes in states in order to update correctly
                      await handleReset(props.medicalCoverage);
                    }}
                  />
                  <DeleteButton
                    isDeleting={isInDeleteAction}
                    disabled={
                      props.shouldDisableForm ||
                      props.formIsSaving ||
                      isInDeleteAction
                    }
                    hidden={props.formIsSaving}
                    onClick={async () => {
                      props.setShouldDisableForm(true);
                      setIsInDeleteAction(true);

                      if (isNewMedicalCoverage) {
                        props.setLocalMedicalCoverages(
                          props.localMedicalCoverages.filter(
                            (medicalCoverage) =>
                              medicalCoverage.id !== newMedicalCoverageId
                          )
                        );
                        medicalCoverageOwnedEntityStore.removeLocalMedicalCoverage(
                          props.medicalCoverage
                        );
                      } else {
                        await props.deleteMedicalCoverage(
                          props.medicalCoverage
                        );
                      }

                      setIsInDeleteAction(false);
                      props.setShouldDisableForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </div>
      }
    >
      <Form<MedicalCoverage>
        formId={`${baseFormId}-${props.medicalCoverage.id}`}
        shouldBlockNavigation={true}
        value={props.medicalCoverage}
        formSchemaBuilder={medicalCoverageFormSchema}
        shouldResetForm={true}
        disableAllFields={
          !props.canEdit ||
          props.formIsSaving ||
          props.isAdding ||
          isInDeleteAction ||
          props.shouldDisableForm
        }
        shouldSaveEntiityToLocalStore={true}
        ownedEntityStoreAction={(updatedMedicalCoverage) => {
          if (
            JSON.stringify(updatedMedicalCoverage) !==
            JSON.stringify(props.medicalCoverage)
          ) {
            medicalCoverageOwnedEntityStore.addLocalMedicalCoverage(
              updatedMedicalCoverage
            );
          }
        }}
        onFormSubmit={async () => {
          props.setShouldDisableForm(true);
          props.setFormIsSaving(true);

          await props.createAndUpdateMedicalCoverages().then(() => {
            showSuccessfulSaveAppToaster(sectionTitle);
          });

          props.setFormIsSaving(false);
          props.setShouldDisableForm(false);
        }}
        computedStateOption={{
          fields: ["coverageType"],
          getComputedState: props.getMedicalCoverageComputedState,
        }}
      />
      {fiaRequest && <OwnedEntityFIARequestSubCard fiaRequest={fiaRequest} />}
    </RelativityCard>
  );
};
