import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState, useEffect } from "react";
import { invoiceApiClient } from "../../lib/apiClients/invoice/invoiceApiClient";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { buildField } from "../Fields";
import { AllFields } from "../Fields/types/fieldTypes";
import { AppToaster } from "../Toast/Toast";
import { BaseDialogProps } from "./types/types";
import { get } from "lodash";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { gridStore } from "../Grid/Grid/stores/GridStore";

export interface SetInvoiceNumberDialogProps extends BaseDialogProps {}

export const SetInvoiceNumberDialog: React.FC<SetInvoiceNumberDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [lastInvoiceNumber, setLastInvoiceNumber] = useState<number>(10000);
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState<number>(
    lastInvoiceNumber + 1
  );
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    invoiceApiClient.getLastInvoiceNumber().then((res) => {
      setLastInvoiceNumber(res);
      setLocalInvoiceNumber(res + 1);
    });

    return () => {
      setErrors({});
    };
  }, [dialogsViewerStore.setInvoiceNumberDialogOpenOptions]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Set Invoice Number"}
      onClose={closeDialog}
      className="set-invoice-number"
    >
      <div className={Classes.DIALOG_BODY}>
        <form
          id="generate-single-invoice-form"
          onSubmit={async (e) => {
            e.preventDefault();
            if (
              dialogsViewerStore.setInvoiceNumberDialogOpenOptions
                ?.billingIds &&
              localInvoiceNumber
            ) {
              setIsSaving(true);
              try {
                await invoiceApiClient
                  .createInvoiceForSelectedBillings(
                    dialogsViewerStore.setInvoiceNumberDialogOpenOptions
                      ?.billingIds,
                    localInvoiceNumber
                  )
                  .then(() => {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>Invoice Generated
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                    gridStore.refresh();
                  })
                  .then(closeDialog)
                  .then(() =>
                    dialogsViewerStore.setIsUpdateBillingDialogOpen(false)
                  );

                setIsSaving(false);
              } catch (error: any) {
                if (error?.response?.status == 400) {
                  setErrors(error.response.data.additionalInfo);
                }

                setIsSaving(false);
              }
            }
          }}
        >
          {buildField(
            {
              fieldKey: lastInvoiceNumber.toString(),
              type: "Number",
              label: `What Invoice Number do you want to start with? Your last invoice number was #${lastInvoiceNumber}.`,
              value: localInvoiceNumber,
              onSubmit: (invoiceNumber: number) => {
                setLocalInvoiceNumber(invoiceNumber);
              },
            } as AllFields,
            0
          )}
        </form>
        {errors && (
          <>
            {get(errors, "invoiceNumber")?.map(
              (errorMessage: string, idx: number) => (
                <p key={idx} className="error-message">
                  {errorMessage}
                </p>
              )
            )}
          </>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="generate-single-invoice-button"
          isSaving={isSaving}
          formId="generate-single-invoice-form"
          text="Submit"
        />
      </div>
    </Dialog>
  );
};
