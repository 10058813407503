import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import {
  MedicalCoverage,
  NewMedicalCoverage,
} from "../../../types/Application/MedicalCoverage";
import { notInListValue } from "../../Fields/AsyncAutocompleteField/AsyncAutocompleteODataV4Adaptor";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getInstitutionAsyncAutocompleteFieldProps,
} from "../types";
import { getStatementOptions } from "../../../utils/getStatementOptions";
import { featureToggleStore } from "../../../lib/featureToggles/FeatureToggleStore";

export const newMedicalCoverage: NewMedicalCoverage = {
  coverageType: undefined,
  institutionId: undefined,
  coverage: undefined,
  premium: undefined,
  policyNumber: undefined,
  groupName: undefined,
  groupNumber: undefined,
  startDate: undefined,
  paidBy: undefined,
  coveredFor: undefined,
  statementDateRangeStart: undefined,
  statementDates_AsList: undefined,
  statementFrequency: undefined,
};

export const medicalCoverageFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "medicalCoverage";
  const medicalCoverage = form as MedicalCoverage;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "institutionId"),
      ...getInstitutionAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "institutionId"),
      hasNotInListOption: true,
      groupByActiveStatus: true,
      width: "49.5%",
      isRequired: true,
      value: medicalCoverage.hasOtherInstitutionName
        ? notInListValue
        : medicalCoverage.institutionId,
      onSubmit: (institution: { id: string }) => {
        const hasOtherInstitutionName = institution?.id === notInListValue;
        if (hasOtherInstitutionName) {
          onValueChange({
            institutionId: undefined,
            hasOtherInstitutionName: true,
          });
        } else {
          onValueChange({
            institutionId: institution?.id,
            hasOtherInstitutionName: false,
          });
        }
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "otherInstitutionName"),
      value: medicalCoverage.otherInstitutionName,
      hidden: !medicalCoverage.hasOtherInstitutionName,
      onSubmit: (otherInstitutionName) => {
        onValueChange({ otherInstitutionName });
      },
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "otherInstitutionName"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "coverageType"),
      value: medicalCoverage.coverageType,
      onSubmit: (coverageType: Option) => {
        onValueChange({ coverageType: coverageType.value });
      },
      isRequired: true,
      errorMessages: get(errors, "coverageType"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "coverage"),
      onSubmit: (coverage: Option) => {
        onValueChange({ coverage: coverage.value });
      },
      value: medicalCoverage.coverage,
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "coverage"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "premium"),
      onSubmit: (premium) => {
        onValueChange({ premium });
      },
      value: medicalCoverage.premium,
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "premium"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "policyNumber"),
      onSubmit: (policyNumber) => {
        onValueChange({ policyNumber });
      },
      isRequired: true,
      value: medicalCoverage.policyNumber,
      errorMessages: get(errors, "policyNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "groupName"),
      onSubmit: (groupName) => {
        onValueChange({ groupName });
      },
      hidden: medicalCoverage.isGroupInsurance === false,
      value: medicalCoverage.groupName,
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "groupName"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "groupNumber"),
      onSubmit: (groupNumber) => {
        onValueChange({ groupNumber });
      },
      hidden: medicalCoverage.isGroupInsurance === false,
      value: medicalCoverage.groupNumber,
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "groupNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "startDate"),
      onSubmit: (startDate) => {
        onValueChange({ startDate });
      },
      value: medicalCoverage.startDate,
      isRequired: true,
      errorMessages: get(errors, "startDate"),
      maxDate: new Date(Date.now()),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "paidBy"),
      onSubmit: (paidBy: Option) => {
        onValueChange({ paidBy: paidBy.value });
      },
      value: medicalCoverage.paidBy,
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "paidBy"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "coveredFor"),
      onSubmit: (coveredFor: Option) => {
        onValueChange({ coveredFor: coveredFor.value });
      },
      value: medicalCoverage.coveredFor,
      width: "49.5%",
      isRequired: true,
      errorMessages: get(errors, "coveredFor"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDateRangeStart"),
      optionValues: getStatementOptions(),
      value: medicalCoverage.statementDateRangeStart,
      onSubmit: (statementDateRangeStart: Option) => {
        onValueChange({
          statementDateRangeStart: statementDateRangeStart?.value,
        });
      },
      shouldShowClearButton: true,
      errorMessages: get(errors, "statementDateRangeStart"),
      width: "48.5%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "statementDates_AsList"),
      optionValues: getStatementOptions(),
      value: medicalCoverage.statementDates_AsList,
      onSubmit: (statementDates_AsList) => {
        onValueChange({ statementDates_AsList });
      },
      errorMessages: get(errors, "statementDates_AsList"),
      width: "48.5%",
    } as AllFields,
    ...(featureToggleStore.featureToggles?.EnableStatementFrequency
      ? [
          {
            ...schemaStore.getFieldSchemaByName(
              modelName,
              "statementFrequency"
            ),
            value: medicalCoverage.statementFrequency,
            onSubmit: (statementFrequency: Option) => {
              onValueChange({ statementFrequency: statementFrequency.value });
            },
            isRequired: false,
            errorMessages: get(errors, "statementFrequency"),
            width: "48.5%",
          } as AllFields,
        ]
      : []),
  ];
};
