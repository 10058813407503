import { Classes, Icon, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Application, ApplicationBillAmount } from "../../types/Application";
import RelativityCard from "../Card/RelativityCard";
import { WithTooltip } from "../Tooltip/Tooltip";

export interface FinancialSummaryProps {
  application?: Application;
}

export interface NameValuePair {
  name: string | string[];
  value: number;
}

export const currency = (value: number) => <>${value.toFixed(2)}</>;

export const label = (name: string, content: JSX.Element | string) => (
  <>
    <div className="financial-summary-label">
      <span className="m-r-sm">{name}</span>
      <WithTooltip
        content={content}
        shouldShowTooltip={true}
        className={Classes.FOCUS_DISABLED}
      >
        <Icon
          iconSize={15}
          icon={IconNames.TimelineLineChart}
          color="#4d85eb"
        />
      </WithTooltip>
    </div>
  </>
);

export const tooltip = (
  rows: NameValuePair[],
  totalRowValue: number | undefined,
  totalRowLabel = "Total"
) => (
  <>
    {rows.map((r, i) => (
      <>
        <div className="financial-summary-tooltip-item" key={i}>
          <div className="m-r-sm">
            {Array.isArray(r.name)
              ? r.name.map((line, j) => <div key={j}>{line}</div>)
              : r.name}
          </div>
          <div>{currency(r.value)}</div>
        </div>
      </>
    ))}
    <div className="financial-summary-tooltip-total">
      <div className="m-r-sm">
        <b>{totalRowLabel}</b>
      </div>
      <div>
        <b>{currency(totalRowValue || 0)}</b>
      </div>
    </div>
  </>
);

export const assetItems = (application?: Application): NameValuePair[] => [
  {
    name: "Property",
    value: application?.combinedAssetPropertyValue || 0,
  },
  {
    name: "Vehicles",
    value: application?.combinedAssetVehicleValue || 0,
  },
  {
    name: "Bank Accounts",
    value: application?.combinedAssetBankAccountValue || 0,
  },
  {
    name: "Life Insurance",
    value: application?.combinedAssetLifeInsuranceValue || 0,
  },
  {
    name: "Burial/Preneed Funeral",
    value: application?.combinedAssetBurialPreneedValue || 0,
  },
  {
    name: "Other Assets",
    value: application?.combinedAssetOtherValue || 0,
  },
];

export const incomeItems = (application?: Application): NameValuePair[] => [
  { name: "Social Security", value: application?.socialSecurityAmount || 0 },
  { name: "SSI", value: application?.ssiAmount || 0 },
  { name: "VA Benefits", value: application?.vaBenefitsAmount || 0 },
  {
    name: "Additional Income",
    value: application?.combinedAdditionalIncomeAmount || 0,
  },
];

export const billItems = (application?: Application): NameValuePair[] =>
  application?.billAmounts.map((bill: ApplicationBillAmount) => {
    return {
      name: bill.type_AsString,
      value: bill.amount || 0,
    };
  }) || [];

export const estPatientLiabilityItems = (
  application?: Application
): NameValuePair[] => [
  {
    name: "Total Monthly Income",
    value: application?.combinedIncomeItemsTotal || 0,
  },
  {
    name: "Medical Coverage Premiums",
    value: -(application?.medicalCoveragePremiums || 0),
  },
  {
    name: application?.isFacilityStateFL
      ? [
          "State's Personal Care Allowance",
          "*Effective July 1st 2023, PNA increased from $130 to $160",
        ]
      : "State's Personal Care Allowance",
    value: -(application?.statesPersonCareAllowance ?? 0),
  },
];

export const FinancialSummary: React.FC<FinancialSummaryProps> = ({
  application,
}) => {
  return (
    <div className="financial-summary">
      <RelativityCard
        className="financial-summary-card"
        headerContent={
          <div className="financial-summary-header">
            <Tabs
              large={true}
              className="financial-summary-header-tabs"
              selectedTabId={"default"}
              renderActiveTabPanelOnly={true}
            >
              <Tab
                className="financial-summary-header-tab"
                id="default"
                title="Financial Summary"
              />
            </Tabs>
          </div>
        }
      >
        <div className="financial-points">
          <div className="financial-summary-data-point">
            {label(
              "Income",
              tooltip(
                incomeItems(application),
                application?.combinedIncomeItemsTotal
              )
            )}
            <div>{currency(application?.combinedIncomeItemsTotal || 0)}</div>
          </div>
          <div className="financial-summary-data-point">
            {label(
              "Assets",
              tooltip(
                assetItems(application),
                application?.combinedAssetItemsTotal
              )
            )}
            <div>{currency(application?.combinedAssetItemsTotal || 0)}</div>
          </div>
          <div className="financial-summary-data-point">
            {label(
              "Bills",
              tooltip(billItems(application), application?.billItemsTotal)
            )}
            <div>{currency(application?.billItemsTotal || 0)}</div>
          </div>
          <div className="financial-summary-data-point">
            {label(
              "Est. Patient Liability",
              tooltip(
                estPatientLiabilityItems(application),
                application?.estPatientLiabilityItemsTotal,
                "Est. Patient Liability"
              )
            )}
            <div>
              {currency(application?.estPatientLiabilityItemsTotal || 0)}
            </div>
          </div>
        </div>
      </RelativityCard>
    </div>
  );
};
