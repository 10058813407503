import { ColumnModel } from "@syncfusion/ej2-react-grids";
import { getObjectValueClassName } from "../../../../utils/miscellaneous";
import GridColumn from "./GridColumn";

interface GridStyleColumnOptions extends ColumnModel {}

export default class GridStyleColumn extends GridColumn {
  constructor(opts: GridStyleColumnOptions) {
    const { ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        return (
          <div
            className={`grid-style-column grid-style-column__${this.field.toLowerCase()} grid-style-column__${this.field.toLowerCase()}__${getObjectValueClassName(
              model[this.field]
            )}`}
          >
            {model[this.field]}
          </div>
        );
      }) as any,
    });
  }
}
