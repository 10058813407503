import { useEffect, useState } from "react";
import { isTextOverflowingField } from "../../utils/miscellaneous";

export const useOverflowTooltip = (
  textInputElement: any | undefined,
  text: string
) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const handleInputResize = () => {
    if (!!textInputElement) {
      setIsTooltipVisible(isTextOverflowingField(textInputElement));
    }
  };

  useEffect(() => {
    // check overflow when component mounts
    handleInputResize();

    // check overflow whenever window is resized
    window.addEventListener("resize", handleInputResize);

    return () => {
      window.removeEventListener("resize", handleInputResize);
    };
  }, [textInputElement]);

  // check overflow whenever the text changes
  useEffect(() => {
    handleInputResize();
  }, [text]);

  return isTooltipVisible;
};
