import { Predicate, Query } from "@syncfusion/ej2-data";
import { userStore } from "../../stores/UserStore";
import { UserRoles } from "../../types/User";
import {
  AllFields,
  AsyncAutocompleteFieldProps,
  //AsyncAutoCompleteQuerySettings,
  SectionHeaderProps,
} from "../Fields/types/fieldTypes";

import { ContactTypes } from "../../types/Application/SpouseContact";
import { isNil } from "lodash";

export interface ValidGovernmentId {
  isIdAvailable?: boolean;
  isCredibleWitnessAvailable?: boolean;
  idNumber?: string;
}

export type FormSchemaBuilder = (
  onValueChange: (field: {}) => void,
  form: unknown,
  errors?: { [key: string]: string[] },
  formId?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFormSubmit?: (value: any) => Promise<void>,
  disableAllFields?: boolean,
  hideSectionHeader?: boolean,
  isFormRequired?: boolean
) => (AllFields | SectionHeaderProps)[];

export const getInstitutionAsyncAutocompleteFieldProps = () => {
  return {
    fieldNames: [
      "id",
      "name",
      "emailAddress",
      "emailAttentionTo",
      "displayNotes",
      "nameAndDisplayNotes",
    ],
    searchBy: (searchText: string) =>
      new Predicate("name", "contains", searchText),
    sortBy: "name",
    odataUrl: "odata/institutionodata",
    autoCompleteSettings: {
      fields: { text: "nameAndDisplayNotes", value: "id" },
      query: new Query()
        .select([
          "id",
          "name",
          "emailAddress",
          "emailAttentionTo",
          "displayNotes",
          "nameAndDisplayNotes",
        ])
        .sortBy("name")
        .take(50),
      itemTemplate: "<table><tr><td>${nameAndDisplayNotes}</td></tr><table>",
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getApplicationAsyncAutocompleteFieldProps = () => {
  return {
    fieldNames: ["label", "value", "fileNumber", "firstName", "lastName"],
    searchBy: (searchText: string) =>
      new Predicate("fileNumber", "contains", searchText, true)
        .or(new Predicate("firstName", "contains", searchText, true))
        .or(new Predicate("lastName", "contains", searchText, true)),
    sortBy: "firstName",
    odataUrl: "odata/applicationodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "fileNumber", "firstName", "lastName"])
        .sortBy("firstName")
        .take(50),
      itemTemplate:
        "<table><tr><td>${fileNumber} - ${firstName} ${lastName}</td></tr><table>",
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getFacilityAsyncAutocompleteFieldProps = () => {
  let regions: number[] = [];
  let fieldRepFacilityIds: string[] = [];

  if (userStore?.user?.role === UserRoles.FieldRepresentativeManager) {
    regions = userStore.user?.regions || [];
  }

  if (userStore.user?.role === UserRoles.FieldRepresentative) {
    fieldRepFacilityIds = userStore.user?.fieldRepFacilityIds || [];
  }

  const regionPredicates = regions.map((x) => {
    return new Predicate("area", "equal", x);
  });

  const fieldRepFacilityIdPredicates = fieldRepFacilityIds.map((x) => {
    return new Predicate("value", "equal", x);
  });

  let query = new Query().select([
    "label",
    "value",
    "corporateId",
    "area",
    "area_AsString",
    "facilityAddress",
  ]);

  if (regions.length > 0) {
    query = query.where(
      regionPredicates.reduce(
        (prevPredicate: Predicate, currPredicate: Predicate) =>
          prevPredicate.or(currPredicate)
      )
    );
  }

  if (fieldRepFacilityIds.length > 0) {
    query = query.where(
      fieldRepFacilityIdPredicates.reduce(
        (prevPredicate: Predicate, currPredicate: Predicate) =>
          prevPredicate.or(currPredicate)
      )
    );
  }

  return {
    fieldNames: [
      "label",
      "value",
      "corporateId",
      "area_AsString",
      "facilityAddress",
    ],
    odataUrl: "odata/facilityodata/options",
    autoCompleteSettings: {
      query: query.sortBy("label").take(25),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getAttorneyAsyncAutocompleteFieldProps = () => {
  return {
    fieldNames: ["label", "value", "email", "phoneNumber"],
    odataUrl: "odata/attorneyodata/options",
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getProcessorAsyncAutocompleteFieldProps = () => {
  return {
    odataUrl: "odata/userodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value"])
        .where(
          new Predicate("role", "equal", UserRoles.Processor)
            .or(new Predicate("role", "equal", UserRoles.ProcessingManager))
            .or(new Predicate("role", "equal", UserRoles.Director))
            .or(new Predicate("role", "equal", UserRoles.RecertProcessor))
            .or(new Predicate("role", "equal", UserRoles.HybridRepProcessor))
        )
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getFieldRepAsyncAutocompleteFieldProps = () => {
  return {
    odataUrl: "odata/userodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "regions_AsString"])
        .where(
          new Predicate("role", "equal", UserRoles.FieldRepresentative)
            .or(
              new Predicate(
                "role",
                "equal",
                UserRoles.FieldRepresentativeManager
              )
            )
            .or(new Predicate("role", "equal", UserRoles.HybridRepProcessor))
        )
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getNotaryAsyncAutocompleteFieldProps = () => {
  return {
    odataUrl: "odata/notaryodata/options",
    autoCompleteSettings: {
      query: new Query().select(["label", "value", "isActive"]).take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getStateCountyAsyncAutocompleteFieldProps = (
  state: number | string | undefined
) => {
  return {
    odataUrl: "odata/statecountyodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "state"])
        .where(new Predicate("state", "equal", state || ""))
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getSpousePowerOfAttorneyContactAsyncAutocompleteFieldProps = (
  applicationId: string,
  spouseId?: string
) => {
  const equalsToapplicationId = new Predicate(
    "applicationId",
    "equal",
    applicationId
  );
  const notEqualToSpouseId = new Predicate(
    "contactId",
    "notequal",
    spouseId || ""
  );

  return {
    odataUrl: "odata/contactodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "applicationId", "contactId", "idNumber"])
        .where(
          equalsToapplicationId
            .and(notEqualToSpouseId)
            .and(new Predicate("idNumber", "notequal", null))
        )
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getResidentPowerOfAttorneyContactAsyncAutocompleteFieldProps = (
  applicationId: string
) => {
  return {
    odataUrl: "odata/contactodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "applicationId", "contactId", "idNumber"])
        .where(
          new Predicate("applicationId", "equal", applicationId).and(
            new Predicate("idNumber", "notequal", null)
          )
        )
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getResidentGuardianContactAsyncAutocompleteFieldProps = (
  applicationId: string
) => {
  return {
    odataUrl: "odata/contactodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "applicationId", "contactId", "idNumber"])
        .where(
          new Predicate("applicationId", "equal", applicationId).and(
            new Predicate("idNumber", "notequal", null)
          )
        )
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getInvoiceNumberAsyncAutocompleteFieldProps = () => {
  return {
    fieldNames: ["label", "value", "invoiceId"],
    odataUrl: "odata/invoiceodata/options",
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getChecklistItemsAsyncAutocompleteFieldProps = (
  applicationId: string
) => {
  return {
    odataUrl: "odata/checklistitemodata/options",
    autoCompleteSettings: {
      query: new Query()
        .select(["label", "value", "applicationId"])
        .where(new Predicate("applicationId", "equal", applicationId))
        .take(500),
    },
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const getCorporateAsyncAutocompleteFieldProps = () => {
  return {
    fieldNames: ["label", "value", "companyAddress", "companyPhone"],
    odataUrl: "odata/corporateodata/options",
  } as Pick<
    AsyncAutocompleteFieldProps,
    "fieldNames" | "odataUrl" | "autoCompleteSettings"
  >;
};

export const validGovernmentIdUtils = {
  resolveResidentValidGovernmentId: (validGovernmentId: ValidGovernmentId) => {
    const showProps = {
      showIsIdAvailable: true,
      showIsCredibleWitnessAvailable:
        !isNil(validGovernmentId.isIdAvailable) &&
        validGovernmentId.isIdAvailable === false,
      showIdNumber:
        !isNil(validGovernmentId.isIdAvailable) &&
        validGovernmentId.isIdAvailable,
    };

    validGovernmentId.isIdAvailable = showProps.showIsIdAvailable
      ? validGovernmentId.isIdAvailable
      : undefined;
    validGovernmentId.isCredibleWitnessAvailable =
      showProps.showIsCredibleWitnessAvailable
        ? validGovernmentId.isCredibleWitnessAvailable
        : undefined;
    validGovernmentId.idNumber = showProps.showIdNumber
      ? validGovernmentId.idNumber
      : undefined;

    return {
      ...showProps,
      isIdAvailable: validGovernmentId.isIdAvailable,
      isCredibleWitnessAvailable: validGovernmentId.isCredibleWitnessAvailable,
      idNumber: validGovernmentId.idNumber,
    };
  },
  resolveContactValidGovernmentId: (
    validGovernmentId: ValidGovernmentId,
    contactType: ContactTypes,
    isSpouseContact: boolean
  ) => {
    const showProps = {
      showIsIdAvailable: contactType === ContactTypes.Witness,
      showIsCredibleWitnessAvailable:
        isSpouseContact &&
        !isNil(validGovernmentId.isIdAvailable) &&
        validGovernmentId.isIdAvailable === false,
      showIdNumber:
        !isNil(validGovernmentId.isIdAvailable) &&
        validGovernmentId.isIdAvailable,
    };

    validGovernmentId.isIdAvailable = showProps.showIsIdAvailable
      ? validGovernmentId.isIdAvailable
      : undefined;
    validGovernmentId.isCredibleWitnessAvailable =
      showProps.showIsCredibleWitnessAvailable
        ? validGovernmentId.isCredibleWitnessAvailable
        : undefined;
    validGovernmentId.idNumber = showProps.showIdNumber
      ? validGovernmentId.idNumber
      : undefined;

    return {
      ...showProps,
      isIdAvailable: validGovernmentId.isIdAvailable,
      isCredibleWitnessAvailable: validGovernmentId.isCredibleWitnessAvailable,
      idNumber: validGovernmentId.idNumber,
    };
  },
};
