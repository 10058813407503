import React from "react";
import { Observer } from "mobx-react";
import classNames from "classnames";
import { ListBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  DocumentPageAction,
  DocumentPageActionRender,
  DocumentPageActionTypes,
  DocumentThumbnailItem,
  useDocumentPagesViewerItem,
} from "./useDocumentPagesViewerItem";
import { isUndefined } from "lodash";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import { ThumbnailImagesData } from "./PdfDocumentStore";
import { NonIdealState } from "@blueprintjs/core";
import { ListBox } from "../ListBox/ListBox";

export interface DocumentPagesViewerItemProps {
  documentId: string;
  documentStatus: number;
  thumbnailImagesData?: ThumbnailImagesData[];
  disabled?: boolean;
  onSelectionChanged?: (value: ThumbnailImagesData[]) => void;
  isAnimated?: boolean;
  onPagePreviewClicked?: (data: ThumbnailImagesData) => void;
  onPagePreviewNewTabClicked?: (data: ThumbnailImagesData) => void;
  onPageEditorClicked?: (data: ThumbnailImagesData) => void;
  documentPageItems?: Array<
    | DocumentPageActionTypes
    | DocumentPageAction<Document>
    | DocumentPageActionRender<Document>
  >;
}

export interface DocumentPagesViewerItemModule {
  selectAll: () => void;
  unselectAll: () => void;
  select: (data: ThumbnailImagesData) => void;
}

const DocumentPagesViewerItemComponent = (
  props: DocumentPagesViewerItemProps,
  ref: React.ForwardedRef<DocumentPagesViewerItemModule>
) => {
  React.useImperativeHandle(
    ref,
    () =>
      ({
        selectAll: () => {
          selectAll();
        },
        unselectAll: () => {
          unselectAll();
        },
        select: (data: ThumbnailImagesData) => {
          select({
            thumbnailImagesData: data,
          } as DocumentThumbnailItem);
        },
      } as DocumentPagesViewerItemModule),
    []
  );

  const listBoxRef = React.useRef<ListBoxComponent | null>(null);

  const {
    changeHandler,
    isThumbnailItemTemplateSelected,
    getListColumns,
    selectedThumbnails,
    selectAll,
    unselectAll,
    select,
    thumbnailItemTemplate,
  } = useDocumentPagesViewerItem(
    props.disabled || false,
    listBoxRef,
    props.thumbnailImagesData,
    props.onPagePreviewClicked,
    props.onPagePreviewNewTabClicked,
    props.onPageEditorClicked,
    props.documentPageItems
  );

  React.useEffect(() => {
    props.onSelectionChanged &&
      props.onSelectionChanged([
        ...(selectedThumbnails?.map((x) => x.thumbnailImagesData) || []),
      ]);
  }, [selectedThumbnails]);

  return (
    <section className="document-pages-viewer-item-container">
      <Observer>
        {() => (
          <section
            className={classNames("document-pages-viewer-item", {
              "document-pages-viewer-item-selected":
                selectedThumbnails?.length || 0 > 0,
            })}
          >
            {isUndefined(props.thumbnailImagesData) && (
              <SkeletonComponent
                shape="Text"
                width="50%"
                height="15px"
              ></SkeletonComponent>
            )}
            {!isUndefined(props.thumbnailImagesData) &&
              props.documentStatus !== 4 &&
              props.thumbnailImagesData &&
              (props.thumbnailImagesData?.length <= 0 ||
                props.documentStatus === 2) && (
                <div className="document-pages-viewer-item-processing">
                  <NonIdealState
                    title={
                      <>
                        Document is still being processed. <br />
                        Please wait a few minutes, then Refresh.
                      </>
                    }
                  />
                </div>
              )}
            {!isUndefined(props.thumbnailImagesData) &&
              props.thumbnailImagesData?.length > 0 && (
                <>
                  <div
                    className={classNames("document-pages-viewer-item-pages", {
                      "document-pages-viewer-item-pages-animated":
                        props.isAnimated,
                    })}
                  >
                    <ListBox<DocumentThumbnailItem>
                      value={
                        getListColumns(props.thumbnailImagesData) ||
                        ([] as DocumentThumbnailItem[])
                      }
                      change={changeHandler}
                      isItemSelected={isThumbnailItemTemplateSelected}
                      itemTemplate={thumbnailItemTemplate}
                    />
                  </div>
                </>
              )}
            {!isUndefined(props.thumbnailImagesData) &&
              props.documentStatus === 4 && (
                <div className="document-pages-viewer-item-error">
                  <NonIdealState
                    title={
                      <>
                        Something went wrong while processing the file. <br />
                        Please upload again or contact support.
                      </>
                    }
                  />
                </div>
              )}
          </section>
        )}
      </Observer>
    </section>
  );
};

export const DocumentPagesViewerItem = React.forwardRef<
  DocumentPagesViewerItemModule,
  DocumentPagesViewerItemProps
>(DocumentPagesViewerItemComponent);
