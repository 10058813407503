import React from "react";
import { schemaStore } from "../../../stores/SchemaStore";
import { ChecklistItem } from "../../../types/Checklist";
import { AllFields, ChecklistOptions } from "../../Fields/types/fieldTypes";
import { getChecklistItemsAsyncAutocompleteFieldProps } from "../types";

export const blankChecklistSplitForm = {
  id: "",
  documentCategory: 0,
  documentCategory_AsString: "",
  shortDescription: "",
};

export const checklistSplitFormSchema = {
  checklistItemPicker: (
    applicationId: string,
    formValueState: {
      formValue: Partial<ChecklistItem>; //;
      setFormValue: React.Dispatch<
        React.SetStateAction<Partial<ChecklistItem>>
      >;
    }
  ) => {
    return {
      type: "AsyncAutocomplete",
      label: "Checklist Item Name",
      ...getChecklistItemsAsyncAutocompleteFieldProps(applicationId),
      value: formValueState.formValue.id,
      onSubmit: (checklist: ChecklistOptions) => {
        formValueState.setFormValue({
          ...formValueState.formValue,
          id: checklist?.value,
          documentCategory: checklist?.documentCategory,
          documentCategory_AsString: checklist?.documentCategory_AsString,
          shortDescription: checklist?.shortDescription
            ? checklist.shortDescription
            : "",
        });
      },
      isRequired: true,
    } as AllFields;
  },
  documentCategory_AsString: (formValueState: {
    formValue: Partial<ChecklistItem>;
    setFormValue: React.Dispatch<React.SetStateAction<Partial<ChecklistItem>>>;
  }) => {
    return {
      type: "Text",
      label: "Category",
      value: formValueState.formValue?.documentCategory_AsString,
      disabled: true,
    } as AllFields;
  },
  shortDescription: (formValueState: {
    formValue: Partial<ChecklistItem>;
    setFormValue: React.Dispatch<React.SetStateAction<Partial<ChecklistItem>>>;
  }) => {
    return {
      ...schemaStore.getFieldSchemaByName("checklistItem", "shortDescription"),
      onSubmit: (shortDescription: string) => {
        formValueState.formValue.shortDescription = shortDescription;
        formValueState.setFormValue({ ...formValueState.formValue });
      },
      value: formValueState.formValue?.shortDescription,
      maxLength: 256,
      disabled: !formValueState.formValue?.id,
    } as AllFields;
  },
};
