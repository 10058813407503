import { Button, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import RelativityCard from "../../../Card/RelativityCard";
import { applicationSummaryFileInfoFormSchema } from "../../../Forms/ApplicationSummary/FileInformationFormSchema";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { BaseField } from "../../../Forms/BaseField/BaseField";
import { userStore } from "../../../../stores/UserStore";

export interface FileInfoCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
}

export const FileInfoCard: FC<FileInfoCardProps> = ({ applicationSummary }) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);
  const [localApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(applicationSummary);

  return (
    <RelativityCard
      headerContent={
        <>
          <Button
            className="resident-panel__section__view-toggle-button"
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            File Info
          </Button>
        </>
      }
      hideBody={!isViewing}
    >
      <RelativitySubCard hideBody={!isViewing}>
        <div className="summary-info">
          <div className="summary-info__header">
            <BaseField
              {...applicationSummaryFileInfoFormSchema.residentFullName(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryFileInfoFormSchema.facilityName(
                localApplicationSummary
              )}
            />
          </div>
          {!userStore.user?.isExternal && (
            <div className="summary-info__header">
              <BaseField
                {...applicationSummaryFileInfoFormSchema.facilityPhone(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.facilityFax(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.facilityAddress(
                  localApplicationSummary
                )}
              />
            </div>
          )}
        </div>
      </RelativitySubCard>
      <RelativitySubCard hideBody={!isViewing}>
        {!userStore.user?.isExternal && (
          <div className="summary-info">
            <div className="summary-info__section">
              <BaseField
                {...applicationSummaryFileInfoFormSchema.fileNumber(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.productType(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.applicationStatus(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.applicationInternalStatus(
                  localApplicationSummary
                )}
              />
            </div>
            <div className="summary-info__section">
              <BaseField
                {...applicationSummaryFileInfoFormSchema.reasonCode(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.fieldRepName(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.processorName(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.currentAssessment(
                  localApplicationSummary
                )}
              />
            </div>
          </div>
        )}
        {userStore.user?.isExternal && (
          <div className="summary-info">
            <div className="summary-info__section">
              <BaseField
                {...applicationSummaryFileInfoFormSchema.productType(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.applicationStatus(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.fieldRepName(
                  localApplicationSummary
                )}
              />
              <BaseField
                {...applicationSummaryFileInfoFormSchema.processorName(
                  localApplicationSummary
                )}
              />
            </div>
          </div>
        )}
      </RelativitySubCard>
    </RelativityCard>
  );
};
