import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { applicationStore } from "../../stores/ApplicationStore";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { withdrawApplicationFormSchema } from "../Forms/ApplicationForm/WithdrawApplication";
import { AppToaster } from "../Toast/Toast";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";

export interface WithdrawApplicationDialogProps extends BaseDialogProps {}

export const WithdrawApplicationDialog: React.FC<
  WithdrawApplicationDialogProps
> = ({ isOpen, closeDialog }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [localWithdrawReason, setLocalWithdrawReason] = useState<number>();
  const [initialized, setInitialized] = useState<boolean>(false);

  // Initialize
  useEffect(() => {
    if (!initialized) {
      setLocalWithdrawReason(applicationStore.application?.withdrawnReason);
      setInitialized(true);
    }
  }, []);

  return (
    <>
      {initialized && (
        <Dialog
          portalClassName="mdr-dialog"
          isOpen={isOpen}
          hasBackdrop={false}
          isCloseButtonShown={true}
          title={"Withdraw Application"}
          onClose={closeDialog}
        >
          <div className={Classes.DIALOG_BODY}>
            <Form
              formId="withdraw-application-form"
              value={localWithdrawReason}
              formSchemaBuilder={withdrawApplicationFormSchema}
              onFormSubmit={async (withdrawReason) => {
                setIsSaving(true);
                try {
                  await applicationStore.withdrawApplication(withdrawReason);

                  AppToaster.show({
                    message: (
                      <div>
                        <h3>Success</h3>Application withdrawn.
                      </div>
                    ),
                    intent: Intent.SUCCESS,
                  });
                  closeDialog();
                } finally {
                  setIsSaving(false);
                }
              }}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <Button
              minimal
              intent={Intent.DANGER}
              text="Cancel"
              onClick={closeDialog}
            />
            <FormSubmitButton
              buttonId="withdraw-application-button"
              isSaving={isSaving}
              formId="withdraw-application-form"
              text="Save"
            />
          </div>
        </Dialog>
      )}
    </>
  );
};
