import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Document, DocumentVerifyDTO } from "../../types/Document";
import {
  AllFIARequestODataReadDTO,
  DocumentFilingAssociatedChecklistItem,
  ApplicationFilingODataReadDTO,
} from "./types";
import { AsyncAutoCompleteFieldProps } from "@ucl/library";
import { applicationApiClient } from "../../lib/apiClients/application/applicationApiClient";
import { newDocumentVerifyDTO } from "../Checklist/Verify/useVerifyComponent";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import useFiaDocumentFiling from "./useFiaDocumentFiling";
import useStateNoticeDocumentFiling from "./useStateNoticeDocumentFiling";
import { isUndefined } from "lodash";

export enum FilingStrategy {
  FIADocument = 1,
  StateNoticeDocument = 2,
}

export interface UseDocumentFilingProps {
  document?: Document;
  applicationId?: string;
  setApplicationId: Dispatch<SetStateAction<string | undefined>>;
  associatedChecklistItem?: DocumentFilingAssociatedChecklistItem;
  setAssociatedChecklistItem: Dispatch<
    SetStateAction<DocumentFilingAssociatedChecklistItem | undefined>
  >;
  newDocumentName?: string;
  setNewDocumentName: Dispatch<SetStateAction<string | undefined>>;
  searchValueRef: MutableRefObject<string | undefined>;
  autoFillDataRef: MutableRefObject<
    AllFIARequestODataReadDTO[] | ApplicationFilingODataReadDTO[] | undefined
  >;
  documentVerifyDTO: DocumentVerifyDTO;
  setDocumentVerifyDTO: Dispatch<SetStateAction<DocumentVerifyDTO>>;
}
const useDocumentFiling = () => {
  const filingAIEntityId = useRef<string>(uuidv4());
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>();
  const [applicationId, setApplicationId] = useState<string>();
  const [associatedChecklistItem, setAssociatedChecklistItem] =
    useState<DocumentFilingAssociatedChecklistItem>();
  const [filingStrategy, setFilingStrategy] = useState<FilingStrategy>();
  const [documentVerifyDTO, setDocumentVerifyDTO] = useState<DocumentVerifyDTO>(
    {
      ...newDocumentVerifyDTO,
    }
  );
  const searchValueRef = useRef<string>();
  const autoFillDataRef = useRef<
    AllFIARequestODataReadDTO[] | ApplicationFilingODataReadDTO[]
  >();
  const [newDocumentName, setNewDocumentName] = useState<string>();

  const {
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    fiaRequest,
    setFiaRequest,
    fiaProcessDocumentResult,
    setFiaProcessDocumentResult,
    isFiaFilingValid,
    fiaSearchAsyncAutoCompleteFieldProps,
    submitFiaDocument,
    processFIADocument,
  } = useFiaDocumentFiling({
    document,
    applicationId,
    setApplicationId,
    associatedChecklistItem,
    setAssociatedChecklistItem,
    newDocumentName,
    setNewDocumentName,
    searchValueRef,
    autoFillDataRef,
    documentVerifyDTO,
    setDocumentVerifyDTO,
  });

  const {
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    application,
    setApplication,
    stateNoticeFilingProcessDocumentResult,
    setStateNoticeFilingProcessDocumentResult,
    stateNoticeSearchAsyncAutoCompleteFieldProps,
    submitStateNoticeDocument,
    processStateNoticeDocument,
  } = useStateNoticeDocumentFiling({
    document,
    applicationId,
    setApplicationId,
    associatedChecklistItem,
    setAssociatedChecklistItem,
    newDocumentName,
    setNewDocumentName,
    searchValueRef,
    autoFillDataRef,
    documentVerifyDTO,
    setDocumentVerifyDTO,
  });

  useEffect(() => {
    if (
      document?.id &&
      filingStrategy &&
      filingStrategy === FilingStrategy.FIADocument
    ) {
      processFIADocument(document?.id);
    } else if (
      document?.id &&
      filingStrategy &&
      filingStrategy === FilingStrategy.StateNoticeDocument
    ) {
      processStateNoticeDocument(document?.id);
    } else {
      setFiaProcessDocumentResult(undefined);
      setStateNoticeFilingProcessDocumentResult(undefined);
    }
  }, [document?.id]);

  const isUploadDocumentStage =
    isUndefined(document?.id) && isUndefined(filingStrategy);
  const isProcessDocumentStage =
    !isUndefined(document?.id) &&
    !isUndefined(filingStrategy) &&
    isUndefined(fiaProcessDocumentResult) &&
    isUndefined(stateNoticeFilingProcessDocumentResult);
  const isFormStage =
    !isUndefined(document?.id) &&
    !isUndefined(filingStrategy) &&
    (!isUndefined(fiaProcessDocumentResult) ||
      !isUndefined(stateNoticeFilingProcessDocumentResult));

  const canBeFiled =
    document?.id &&
    (fiaRequest?.id || application?.id) &&
    associatedChecklistItem?.id &&
    (filingStrategy === FilingStrategy.FIADocument
      ? isFiaFilingValid(fiaRequest, documentVerifyDTO)
      : true);

  const handleDuplicateCheck = async () => {
    dialogsViewerStore.setIsConfirmDialogOpen(true, {
      content:
        "A potential duplicate document has been recently uploaded to the Application. Would you like to continue filing?",
      confirmHeaderText: "Potential Duplicate Document Found",
      onConfirm: async () => {
        const result = await handleDocumentFiling();
        return { isSuccess: !!result, error: {} };
      },
    });
  };
  const handleDocumentFiling = async () => {
    setIsSaving(true);
    let result;
    if (filingStrategy === FilingStrategy.FIADocument) {
      result = await submitFiaDocument();
    } else if (filingStrategy === FilingStrategy.StateNoticeDocument) {
      result = await submitStateNoticeDocument();
    }

    if (result) {
      resetDocumentFIling();
    }
    setIsSaving(false);
    return result;
  };

  const handleSubmitDocument = async () => {
    if (!document || !applicationId) {
      return;
    }

    const isDuplicate =
      await applicationApiClient.checkForRecentDuplicateApplicationDocuments(
        applicationId,
        document?.id
      );

    if (isDuplicate) {
      await handleDuplicateCheck();
    } else {
      await handleDocumentFiling();
    }
  };

  const [asyncAutoCompleteProps, setAsyncAutoCompleteProps] =
    useState<AsyncAutoCompleteFieldProps>(fiaSearchAsyncAutoCompleteFieldProps);

  const resetDocumentFIling = () => {
    filingAIEntityId.current = uuidv4();
    setDocument(undefined);
    setFiaRequest(undefined);
    setAssociatedChecklistItem(undefined);
    setFilingStrategy(undefined);
    setApplication(undefined);
    setApplicationId(undefined);
    setFiaProcessDocumentResult(undefined);
    setStateNoticeFilingProcessDocumentResult(undefined);
    setDocumentVerifyDTO({ ...newDocumentVerifyDTO });
    searchValueRef.current = undefined;
    autoFillDataRef.current = undefined;
  };

  return {
    filingAIEntityId: filingAIEntityId.current,
    document,
    setDocument,
    stages: {
      isUploadDocumentStage,
      isProcessDocumentStage,
      isFormStage,
    },
    fiaRequest,
    associatedChecklistItem,
    setAssociatedChecklistItem,
    canBeFiled,
    fiaSearchAsyncAutoCompleteFieldProps,
    submitFiaDocument,
    submitStateNoticeDocument,
    fiaProcessDocumentResult,
    documentVerifyDTO,
    setDocumentVerifyDTO,
    searchValue: searchValueRef.current,
    autoFillData: autoFillDataRef.current,
    filingStrategy,
    setFilingStrategy,
    application,
    stateNoticeFilingProcessDocumentResult,
    stateNoticeSearchAsyncAutoCompleteFieldProps,
    asyncAutoCompleteProps,
    setAsyncAutoCompleteProps,
    isUploadingFiaDoc,
    setIsUploadingFiaDoc,
    isUploadingStateNoticeDoc,
    setIsUploadingStateNoticeDoc,
    resetDocumentFIling,
    newDocumentName,
    setNewDocumentName,
    isSaving,
    setIsSaving,
    handleSubmitDocument,
  };
};

export default useDocumentFiling;
