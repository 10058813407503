import { FormGroup } from "@blueprintjs/core";
import {
  MultiSelectComponent,
  MultiSelectModel,
  SelectEventArgs,
} from "@syncfusion/ej2-react-dropdowns";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import React from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { TagsFieldProps } from "../types/fieldTypes";
import "./styles.scss";
import _ from "lodash";

export const TagsField: React.FC<TagsFieldProps> = (props) => {
  if (props.hidden) {
    return null;
  }

  const tagsRef = React.useRef<MultiSelectComponent | null>(null);
  const [localValue] = React.useState(props.value);
  const [ready, setReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    setReady(true);
  }, []);

  // Events
  const handleSelect = async (args: SelectEventArgs) => {
    if (!!tagsRef.current) {
      const allValues = (tagsRef.current.value as string[]) || [];
      allValues.push(args.itemData.text as string);

      //filter out empty strings
      const filteredValues = allValues.filter((value) => value !== "");

      props.onSubmit(filteredValues);
    }
  };
  const handleRemoved = async () => {
    if (!!tagsRef.current) {
      const allValues =
        tagsRef.current.value.length > 0 ? tagsRef.current.value : undefined;
      props.onSubmit(allValues);
    }
  };

  const defaultTagsSettings: MultiSelectModel & DefaultHtmlAttributes = {
    allowCustomValue: true,
    // Immutable
    select: handleSelect,
    removed: handleRemoved,
    addTagOnBlur: true,
    openOnClick: false,
  };

  return (
    <FormGroup
      className={`${
        (props.isRequired && !props.value) ||
        (props.isRequired && !props.value) ||
        (props.isRequired && props.value !== null && props.value !== undefined)
          ? "has-required-background"
          : "tags-field"
      }`}
    >
      {props.label && (
        <SelectableLabel name={props.label} description={props.description} />
      )}
      {ready && (
        <div className="tags-toolbar">
          {ready && (
            <div
              className={`${
                props.disabled
                  ? "tags-input-container__readonly"
                  : "tags-input-container"
              }`}
            >
              {/* No datasource since its for tags purpose */}
              <MultiSelectComponent
                ref={tagsRef}
                id="tags-search-list"
                className="tags-input"
                readonly={props.disabled}
                placeholder={props.placeholder}
                {...({
                  ...defaultTagsSettings,
                  ...(props.tagsSettings || {}),
                  value: localValue,
                } as MultiSelectModel & DefaultHtmlAttributes)}
              />
            </div>
          )}
        </div>
      )}
      {props.errorMessages && (
        <>
          {props.errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
