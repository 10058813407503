import { Observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { applicationStore } from "../../../../../stores/ApplicationStore";
import { billOwnedEntityStore } from "../../../../../stores/OwnedEntityStores/BillOwnedEntityStore";
import { userStore } from "../../../../../stores/UserStore";
import { Application } from "../../../../../types/Application";
import { BillInformation } from "../../../../../types/Application/BillInformation";
import { SaveButton } from "../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../Buttons/UndoButton";
import RelativityCard from "../../../../Card/RelativityCard";
import { RelativityCardHeader } from "../../../../Card/RelativityCardHeader";
import Form from "../../../../Forms";
import {
  billInformationFormSchema,
  newBillInformation,
} from "../../../../Forms/ApplicationForm/BillInformation";
import { showSuccessfulSaveAppToaster } from "../../../../Toast/Toast";
import { BillPageCard } from "./Owned Entities/BillCard";

export interface BillInformationPageCardProps {
  application?: Application;
  canEdit: boolean;
  submitForm: (application: BillInformation) => Promise<void>;
}

export const BillInformationPageCard: FC<BillInformationPageCardProps> = (
  props
) => {
  const shouldShowChildren =
    props.application?.doesResidentHaveBills ||
    newBillInformation.doesResidentHaveBills ||
    props.application?.doesSpouseHaveBills ||
    newBillInformation.doesSpouseHaveBills;

  const [shouldResetForm, setShouldResetForm] = useState<boolean>(false);
  const [activeFormValue, setActiveFormValue] = useState<Application>(
    props.application as Application
  );

  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);
  const [formIsSaving, setFormIsSaving] = useState<boolean>(false);

  const handleReset = async () => {
    setActiveFormValue(applicationStore.application as Application);
  };

  useEffect(() => {
    if (!!props.application) {
      setActiveFormValue(props.application);
    }
  }, [props.application]);

  return (
    <RelativityCard
      className="application-page bill-information-card"
      headerContent={
        <RelativityCardHeader label="Bill Information">
          <div>
            {props.canEdit && (
              <>
                <>
                  <SaveButton
                    formId="bill-information-form"
                    isSaving={formIsSaving}
                  />
                  <UndoButton
                    isSaving={formIsSaving}
                    onClick={async () => {
                      setShouldResetForm(true);
                      await handleReset();
                      setShouldResetForm(false);
                    }}
                  />
                </>
              </>
            )}
          </div>
        </RelativityCardHeader>
      }
    >
      <Observer>
        {() => (
          <Form<BillInformation>
            formId="bill-information-form"
            shouldBlockNavigation={true}
            value={activeFormValue}
            shouldResetForm={shouldResetForm}
            disableAllFields={
              !!formIsSaving || !props.canEdit || shouldDisableForm
            }
            hideSectionHeader={true}
            formSchemaBuilder={billInformationFormSchema}
            onFormSubmit={async (billInfo) => {
              setShouldDisableForm(true);
              setFormIsSaving(true);
              await props
                .submitForm(billInfo)
                .then(() => {
                  showSuccessfulSaveAppToaster("Bill Information");
                })
                .finally(() => {
                  setShouldDisableForm(false);
                  setFormIsSaving(false);
                });
            }}
          />
        )}
      </Observer>

      {shouldShowChildren && (
        <BillPageCard
          application={props.application}
          canCreate={!!userStore.user?.canCreateApplicationPageBill}
          canEdit={!!userStore.user?.canEditApplicationPageBill}
          createAndUpdateBills={async () =>
            await billOwnedEntityStore.saveLocalBills()
          }
          deleteBill={async (billForm) => {
            await billOwnedEntityStore.saveLocalBills();
            billOwnedEntityStore.removeLocalBill(billForm);
            await applicationStore.deleteApplicationOwnedEntity(
              billForm.id,
              "bill"
            );
          }}
          formIsSaving={formIsSaving}
          setFormIsSaving={setFormIsSaving}
          shouldDisableForm={shouldDisableForm || !props.canEdit}
          setShouldDisableForm={setShouldDisableForm}
        />
      )}
    </RelativityCard>
  );
};
