import { NumericField } from "../NumericField/NumericField";
import { CurrencyFieldProps } from "../types/fieldTypes";

export const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
}) => {
  return (
    <NumericField
      type="Text"
      description={description}
      label={label}
      numericFieldProps={{
        format: "c2",
        placeholder: "$0.00",
        currency: "USD",
      }}
      value={value}
      disabled={disabled}
      onSubmit={onSubmit}
      hidden={hidden}
      errorMessages={errorMessages}
      isRequired={isRequired}
    />
  );
};
