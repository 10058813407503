import { FIARequestResendDTO } from "../components/FIARequests/configurations/types";
import { ExistingResource } from "./ExistingResource";

export const NotaryDocumentEntityType = "Notary";

export const DocumentEntityTypes: Record<string, number> = {
  Application: 1,
  Template: 2,
  Checklist: 3,
  Invoice: 4,
};

DocumentEntityTypes[NotaryDocumentEntityType] = 7;

export interface NewDocument {
  id?: string;
  entityType: number;
  fileName?: string;
  originalFileName: string;
  folderName: string;
  isChunked: boolean;
  entityId?: string;
  applicationId?: string;
  uploadedAt?: Date;
  chunkIndex?: number;
  totalChunk?: number;
  useStatementsReceived?: boolean;
  statementsReceived_AsList?: string[];
  isVerified?: boolean;
}

export interface DocumentVerifyDTO extends FIARequestResendDTO {
  applicationId?: string;
  fiaRequestIds?: string[];
  fiaRequestAction?: number;
}

export type Document = ExistingResource<NewDocument> & {
  fileName: string;
  documentStatus: number;
  fileSize: number;
};
