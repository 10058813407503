import React, { useEffect, useState } from "react";
import RelativityCard from "../Card/RelativityCard";
import { Button, Intent, Spinner } from "@blueprintjs/core";
import RelativitySubCard from "../Card/RelativitySubCard";
import { Lead } from "../../types/Lead";
import { DocumentPagesViewer } from "../Document/DocumentPagesViewer";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { PdfDocumentEntityType } from "../Document/PdfDocumentUploader";
import { Document } from "../../types/Document";
import { useDocument } from "../Document/useDocument";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { IconNames } from "@blueprintjs/icons";
import {
  DocumentAction,
  DocumentActionRender,
} from "../Document/useDocumentPagesViewer";
import { AppToaster } from "../Toast/Toast";
import { AddButton } from "../Buttons/AddButton";
import { matchupApiClient } from "../../lib/apiClients/matchup/matchupApiClient";

export interface MatchupDocumentsCardProps {
  lead: Lead;
}

export const MatchupDocumentsCard: React.FC<MatchupDocumentsCardProps> = (
  props
) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(true);
  const [isViewing, setIsViewing] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);

  const { documentActionTypes } = useDocument();
  const matchupDocumentActionTypes = () => {
    const documentActions = documentActionTypes({
      disableOpenNewTabStampedDoc: true,
    });
    documentActions.push({
      name: "edit",
      minimal: true,
      icon: IconNames.EDIT,
      onActionClick: (doc: Document) => {
        dialogsViewerStore.openDocumentEditorDialog({
          documentId: doc.id,
          onUpdate: () => {
            refreshMatchupDocuments();
          },
        });
      },
    } as DocumentAction<Document>);
    documentActions.push({
      name: "rename",
      minimal: true,
      icon: IconNames.TEXT_HIGHLIGHT,
      onActionClick: (doc: Document) => {
        dialogsViewerStore.openDocumentRenameDialog({
          documentId: doc.id,
          onUpdate: () => {
            refreshMatchupDocuments();
          },
        });
      },
    } as DocumentAction<Document>);
    documentActions.push({
      name: "delete",
      minimal: true,
      icon: IconNames.TRASH,
      intent: Intent.DANGER,
      onActionClick: (doc: Document) => {
        dialogsViewerStore.confirm({
          content: <>Are you sure you would like to delete this document?</>,
          onClose: () => {
            null;
          },
          onConfirm: async () => {
            const result = {
              isSuccess: true,
              error: undefined,
            };
            await documentApiClient.deleteDocument(doc);
            await matchupApiClient.removeLeadDocument(props.lead.id, doc.id);

            AppToaster.show({
              message: (
                <div>
                  <h3>Success</h3>Document Deleted.
                </div>
              ),
              intent: Intent.SUCCESS,
            });
            refreshMatchupDocuments();
            return result;
          },
        });
      },
    } as DocumentAction<Document>);
    documentActions.push({
      name: "document-info",
      render: (doc: Document) => {
        return (
          <div className="document-info-container">
            <ul className="document-info">
              <li className="document-info-item">
                <span className="document-info-item-label">Uploaded By:</span>
                <span className="document-info-item-value">
                  {doc.createdBy_AsName}
                </span>
              </li>
            </ul>
          </div>
        );
      },
    } as DocumentActionRender<Document>);
    return documentActions;
  };
  const refreshMatchupDocuments = () => {
    setIsLoadingDocuments(true);
    documentApiClient
      .getDocumentsByEntity(PdfDocumentEntityType.Matchup, props.lead.id)
      .then((documents) => {
        setDocuments(documents);
      })
      .finally(() => {
        setIsLoadingDocuments(false);
      });
  };

  useEffect(() => {
    if (props.lead.id) {
      refreshMatchupDocuments();
    }
  }, []);

  return (
    <RelativityCard
      headerContent={
        <div className="matchup-documents__header">
          <Button
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            Upload Documents
          </Button>
          {isViewing && (
            <AddButton
              className="add-document"
              text="Add Document"
              onClick={() => {
                dialogsViewerStore.setIsMatchupUploadDocumentDialogOpen(true, {
                  leadId: props.lead.id,
                  onSave: refreshMatchupDocuments,
                });
              }}
            />
          )}
        </div>
      }
      hideBody={!isViewing}
    >
      <RelativitySubCard>
        {isLoadingDocuments && <Spinner size={36} />}
        {!isLoadingDocuments && (
          <DocumentPagesViewer
            documents={documents}
            isAnimated={false}
            documentItems={matchupDocumentActionTypes()}
            readOnly={true}
            disabledSelectionOrder={true}
            disabledPagePreview={true}
          />
        )}
      </RelativitySubCard>
    </RelativityCard>
  );
};
