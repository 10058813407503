import { Button, Icon, Intent } from "@blueprintjs/core";
import RelativityCard from "../../../Card/RelativityCard";
import { useState } from "react";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import {
  currency,
  labelWithIcon,
  dateTimelineItems,
  dateTooltip,
  FinancialTooltipItem,
  progressTooltip,
  tooltip,
  expandedTooltip,
  expandedIncomeItems,
  expandedAssetItems,
  spendDownIconColor,
} from "../../EligibilitySummaryUtilities";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { ApplicationBillAmount } from "../../../../types/Application";
import { EligibilitySummaryResponseDTO } from "../../../../types/Application/EligibilitySummary";
import { EligibilitySummaryProgressLabel } from "../../EligibilitySummaryProgressLabel";
import classnames from "classnames";
import { IconNames } from "@blueprintjs/icons";
import { formatDateToMMddYYYY } from "../../../../utils/date";
import { WithTooltip } from "../../../Tooltip/Tooltip";
import { AMDAssetEligibilityDisclaimerIcon } from "../../Icons/AMDAssetEligibilityDisclaimerIcon";
import { getAssetValueToDisplay } from "../../EligibilitySummary";

export interface EligibilitySummaryCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
  eligibilitySummary?: EligibilitySummaryResponseDTO;
}

export const EligibilitySummaryCard: React.FC<EligibilitySummaryCardProps> = ({
  applicationSummary,
  eligibilitySummary,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);

  const incomeItems: FinancialTooltipItem[] = [
    {
      name: "Social Security",
      value: applicationSummary.combinedSocialSecurityAmount || 0,
    },
    { name: "SSI", value: applicationSummary.combinedSSIAmount || 0 },
    {
      name: "VA Benefits",
      value: applicationSummary.combinedVABenefitsAmount || 0,
    },
    {
      name: "Additional Income",
      value: applicationSummary.combinedAdditionalIncomeAmount || 0,
    },
  ];

  const assetItems: FinancialTooltipItem[] = [
    {
      name: "Property",
      value: applicationSummary.combinedAssetPropertyValue || 0,
    },
    {
      name: "Vehicles",
      value: applicationSummary.combinedAssetVehicleValue || 0,
    },
    {
      name: "Bank Accounts",
      value: applicationSummary.combinedAssetBankAccountValue || 0,
    },
    {
      name: "Life Insurance",
      value: applicationSummary.combinedAssetLifeInsuranceValue || 0,
    },
    {
      name: "Burial/Preneed Funeral",
      value: applicationSummary.combinedAssetBurialPreneedValue || 0,
    },
    {
      name: "Other Assets",
      value: applicationSummary.combinedAssetOtherValue || 0,
    },
  ];

  const billItems: FinancialTooltipItem[] =
    applicationSummary.billAmounts?.map((bill: ApplicationBillAmount) => {
      return {
        name: bill.type_AsString,
        value: bill.amount || 0,
      };
    }) || [];

  const estPatientLiabilityItems: FinancialTooltipItem[] = [
    {
      name: "Total Monthly Income",
      value: applicationSummary.combinedIncomeItemsTotal || 0,
    },
    {
      name: "Medical Coverage Premiums",
      value: -(applicationSummary.medicalCoveragePremiums || 0),
    },
    {
      name: applicationSummary.isFacilityStateFL
        ? [
            "State's Personal Care Allowance",
            "*Effective July 1st 2023, PNA increased from $130 to $160",
          ]
        : "State's Personal Care Allowance",
      value: -(applicationSummary.statesPersonCareAllowance ?? 0),
    },
  ];

  return (
    <div className="summary-dialog-eligibility-summary">
      <RelativityCard
        className="summary-dialog-eligibility-summary-card"
        headerContent={
          <div className="summary-dialog-eligibility-summary-header">
            {applicationSummary?.isMarried &&
              !applicationSummary.isSpouseLTCFacility &&
              applicationSummary?.overrideAssetEligibility && (
                <WithTooltip
                  className="asset-eligibility-override-disclaimer"
                  shouldShowTooltip={true}
                  content="Manager confirmed Resident/community spouse spenddown complete. Disregard Asset Eligibility display text."
                >
                  <Icon
                    icon={IconNames.InfoSign}
                    intent={Intent.DANGER}
                    size={25}
                    color={spendDownIconColor}
                  />
                </WithTooltip>
              )}
            <Button
              active={false}
              minimal={true}
              intent={Intent.PRIMARY}
              onClick={() => setIsViewing((prev) => !prev)}
            >
              Eligibility Summary
            </Button>
          </div>
        }
        hideBody={!isViewing}
      >
        <RelativitySubCard>
          {applicationSummary && eligibilitySummary && (
            <>
              <div className="summary-dialog-eligibility-points">
                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Income",
                    applicationSummary.includeAllIncomesInSummary
                      ? expandedTooltip(expandedIncomeItems(applicationSummary))
                      : tooltip(
                          incomeItems,
                          applicationSummary.combinedIncomeItemsTotal,
                          "Resident"
                        )
                  )}
                  <div
                    className={
                      eligibilitySummary.medicaidEligibilityDecision
                        ? classnames({
                            "not-eligible":
                              !!eligibilitySummary.medicaidEligibilityDecision
                                ?.residentIncomeExceedsStateThreshold,
                            eligible:
                              !eligibilitySummary.medicaidEligibilityDecision
                                ?.residentIncomeExceedsStateThreshold,
                          })
                        : ""
                    }
                  >
                    {currency(applicationSummary.combinedIncomeItemsTotal || 0)}
                  </div>
                </div>
                <div className="eligibility-summary-data-point">
                  <div className="multiple-tooltip-summary">
                    {labelWithIcon(
                      "Assets",
                      applicationSummary.includeAllAssetsInSummary
                        ? expandedTooltip(
                            expandedAssetItems(applicationSummary)
                          )
                        : tooltip(
                            assetItems,
                            getAssetValueToDisplay(applicationSummary),
                            "Resident"
                          )
                    )}
                    <AMDAssetEligibilityDisclaimerIcon
                      medicaidEligibilityDecision={
                        eligibilitySummary.medicaidEligibilityDecision
                      }
                    />
                  </div>
                  <div
                    className={
                      eligibilitySummary.medicaidEligibilityDecision
                        ? classnames({
                            "not-eligible":
                              !!eligibilitySummary.medicaidEligibilityDecision
                                ?.residentAssetsExceedsStateThreshold,
                            eligible:
                              !eligibilitySummary.medicaidEligibilityDecision
                                ?.residentAssetsExceedsStateThreshold,
                          })
                        : ""
                    }
                  >
                    {currency(getAssetValueToDisplay(applicationSummary) || 0)}
                  </div>
                </div>
                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Bills",
                    tooltip(billItems, applicationSummary.billItemsTotal)
                  )}
                  <div>{currency(applicationSummary.billItemsTotal || 0)}</div>
                </div>
                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Est. Patient Liability",
                    tooltip(
                      estPatientLiabilityItems,
                      applicationSummary.estPatientLiabilityItemsTotal,
                      "",
                      "Est. Patient Liability"
                    )
                  )}
                  <div>
                    {currency(
                      applicationSummary.estPatientLiabilityItemsTotal || 0
                    )}
                  </div>
                </div>
              </div>
              <div className="summary-dialog-eligibility-points">
                {eligibilitySummary.medicaidEligibilityDecision && (
                  <div className="eligibility-summary-data-point">
                    {labelWithIcon(
                      "Eligibility",
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            eligibilitySummary.medicaidEligibilityDecision
                              ?.decisionType_AsString || "",
                        }}
                      />,
                      <Icon
                        icon={IconNames.Issue}
                        intent={
                          eligibilitySummary.medicaidEligibilityDecision
                            ?.isEligible
                            ? Intent.SUCCESS
                            : Intent.DANGER
                        }
                      />
                    )}
                    <div
                      className={classnames({
                        "not-eligible":
                          !eligibilitySummary.medicaidEligibilityDecision
                            ?.isEligible,
                        eligible:
                          eligibilitySummary.medicaidEligibilityDecision
                            ?.isEligible,
                      })}
                    >
                      {eligibilitySummary.medicaidEligibilityStatus_AsString}
                    </div>
                  </div>
                )}

                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Next Deadline",
                    dateTooltip(
                      dateTimelineItems(applicationSummary).map((item) => ({
                        name: item.name,
                        description: item.description,
                        value: item.value,
                      }))
                    )
                  )}
                  <div>
                    {formatDateToMMddYYYY(
                      applicationSummary.nextDeadlineDate || ""
                    )}
                  </div>
                </div>
                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Non-Financial",
                    progressTooltip(
                      Object.keys(
                        eligibilitySummary.nonFinancialCalculations
                          .nonFinancialCalculations
                      ).map((key) => ({
                        name: key,
                        value: !!eligibilitySummary.nonFinancialCalculations
                          .nonFinancialCalculations[key]
                          ? "Complete"
                          : "Not Complete",
                      }))
                    )
                  )}
                  <EligibilitySummaryProgressLabel
                    componentId="app-summary-nonfinancial-progress"
                    progress={
                      eligibilitySummary.nonFinancialCalculations
                        .percentComplete
                    }
                    completedItems={Object.values(
                      eligibilitySummary.nonFinancialCalculations
                        .nonFinancialCalculations
                    ).reduce(
                      (count: number, value) => count + (value ? 1 : 0),
                      0
                    )}
                    totalItems={
                      Object.keys(
                        eligibilitySummary.nonFinancialCalculations
                          .nonFinancialCalculations
                      ).length
                    }
                  />
                </div>
                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Verifications",
                    progressTooltip(
                      eligibilitySummary.checklistVerificationCalculations.outstandingChecklistItems.map(
                        (item) => ({
                          name: item.displayName,
                          value: item.status_AsString || "",
                        })
                      )
                    )
                  )}
                  <EligibilitySummaryProgressLabel
                    componentId="app-summary-verifications-progress"
                    progress={
                      eligibilitySummary.checklistVerificationCalculations
                        .percentComplete
                    }
                    completedItems={
                      eligibilitySummary.checklistVerificationCalculations
                        .completedChecklistItemCount
                    }
                    totalItems={
                      eligibilitySummary.checklistVerificationCalculations
                        .totalChecklistItemCount
                    }
                  />
                </div>
              </div>
            </>
          )}
        </RelativitySubCard>
      </RelativityCard>
    </div>
  );
};
