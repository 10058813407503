import { Dispatch, FC, SetStateAction } from "react";
import { ChecklistItemVerifyComponent } from "./ChecklistItemVerifyComponent";
import { DocumentStatementsReceived } from "./DocumentStatementsReceived";
import { OwnedEntityComponent } from "../../Application/OwnedEntityComponent";
import { IdentificationFormComponent } from "./IdentificationFormComponent";
import { ChecklistItem } from "../../../types/Checklist";
import {
  OwnedEntityType,
  assetTypeString,
  incomeTypeString,
} from "../../Application/UseOwnedEntityComponent";
import { Asset } from "../../../types/Application/Asset";
import { FIARequestVerifyComponent } from "./FIARequestVerifyComponent";
import { assetOwnedEntityStore } from "../../../stores/OwnedEntityStores/AssetOwnedEntityStore";
import { FIARequest } from "../../FIARequests/configurations/types";
import { DocumentVerifyDTO } from "../../../types/Document";
import { Income } from "../../../types/Application/Income";

export interface VerifyFormProps {
  documentId?: string;
  checklistItem?: ChecklistItem;
  refreshChecklistItem: () => void;
  existingOwnedEntity?: OwnedEntityType;
  ownedEntityTypeString: string;
  setOwnedEntityTypeString: (ownedEntityTypeString: string) => void;
  assetTypeSchema: any;
  refreshOwnedEntity: () => void;
  showDocStatementsReceived: boolean;
  showIdentification: boolean;
  showResidentIdentification: boolean;
  showContactIdentification: boolean;
  showSpouseIdentification: boolean;
  showFiaRequests: boolean;
  outstandingFiaRequests: FIARequest[];
  documentVerifyDTO: DocumentVerifyDTO;
  setDocumentVerifyDTO: Dispatch<SetStateAction<DocumentVerifyDTO>>;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  onSave?: () => void;
}
export const VerifyForm: FC<VerifyFormProps> = (props) => {
  const {
    documentId,
    checklistItem,
    refreshChecklistItem,
    existingOwnedEntity,
    ownedEntityTypeString,
    setOwnedEntityTypeString,
    assetTypeSchema,
    refreshOwnedEntity,
    showDocStatementsReceived,
    showIdentification,
    showResidentIdentification,
    showContactIdentification,
    showSpouseIdentification,
    showFiaRequests,
    outstandingFiaRequests,
    documentVerifyDTO,
    setDocumentVerifyDTO,
    isSaving,
    setIsSaving,
  } = props;

  return (
    <div>
      {checklistItem && (
        <ChecklistItemVerifyComponent
          checklistItem={checklistItem}
          ownedEntity={
            checklistItem.entityType === assetTypeString
              ? (existingOwnedEntity as Asset)
              : checklistItem.entityType === incomeTypeString
              ? (existingOwnedEntity as Income)
              : undefined
          }
        />
      )}
      {checklistItem && showDocStatementsReceived && (
        <DocumentStatementsReceived
          checklistItem={checklistItem}
          statementsNeeded={
            (existingOwnedEntity as Asset | Income).aggregatedStatementsNeeded
          }
          documentId={documentId}
          refreshChecklistItem={refreshChecklistItem}
          isSaving={isSaving}
          onSave={props.onSave}
        />
      )}
      {existingOwnedEntity && (
        <OwnedEntityComponent
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          ownedEntityTypeString={ownedEntityTypeString}
          setOwnedEntityTypeString={setOwnedEntityTypeString}
          localAssetTypeSchema={assetTypeSchema}
          existingOwnedEntity={existingOwnedEntity}
          showCancelButton={false}
          updateAsset={async () => {
            await assetOwnedEntityStore.saveLocalAssets();
            refreshOwnedEntity();
          }}
          refreshOwnedEntity={refreshOwnedEntity}
        />
      )}
      {showIdentification && (
        <IdentificationFormComponent
          isResidentIdentification={!!showResidentIdentification}
          isContactIdentification={!!showContactIdentification}
          isSpouseIdentification={!!showSpouseIdentification}
          contactId={checklistItem?.entityId}
        />
      )}
      {showFiaRequests && (
        <FIARequestVerifyComponent
          checklistItemId={checklistItem?.id}
          documentId={documentId}
          fiaRequests={outstandingFiaRequests}
          documentVerifyDTO={documentVerifyDTO}
          setDocumentVerifyDTO={setDocumentVerifyDTO}
        />
      )}
    </div>
  );
};
