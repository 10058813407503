import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { documentApiClient } from "../../lib/apiClients/document/documentApiClient";
import { Document } from "../../types/Document";
import { PdfDocumentEntityType } from "../Document/PdfDocumentUploader";
import { InvoiceDocumentHistoryGrid } from "../Invoice/InvoiceDocumentHistoryGrid";
import { BaseDialogProps } from "./types/types";

export interface InvoiceDocumentHistoryDialogProps extends BaseDialogProps {
  invoiceId?: string;
  invoiceDocumentId?: string;
}

export const InvoiceDocumentHistoryDialog: React.FC<
  InvoiceDocumentHistoryDialogProps
> = ({ isOpen, closeDialog, invoiceId, invoiceDocumentId }) => {
  const [documents, setDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (!!invoiceId) {
      documentApiClient
        .getDocumentsByEntity(PdfDocumentEntityType.Invoice, invoiceId)
        .then(setDocuments);
    }
  }, [invoiceId]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"History"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <InvoiceDocumentHistoryGrid
          documents={documents.map((document) => ({
            ...document,
            isCurrentInvoiceDocument: document.id === invoiceDocumentId,
          }))}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
