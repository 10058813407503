import { ExpressionData } from "../../../components/TemplateDesigner/stores/ExpressionsStore";
import {
  ExistingTemplate,
  Template,
  TemplateFileFormDataUpdate,
} from "../../../types/Template";
import baseApiClient from "../baseApiClient";

class TemplateApiClient {
  getById = (id: string) => {
    return baseApiClient.get<ExistingTemplate>(`template/${id}`);
  };

  createTemplate = async (template: Template) => {
    return baseApiClient.post<ExistingTemplate>("template", template);
  };

  updateTemplate = async (
    templateId: string,
    template: ExistingTemplate,
    validatePagesCount: boolean
  ) => {
    return baseApiClient.put<ExistingTemplate>(`template/${templateId}`, {
      ...template,
      ...{ validatePagesCount: validatePagesCount },
    });
  };

  updateFileFormData = async (
    templateId: string,
    templateFileFormDataUpdate: TemplateFileFormDataUpdate
  ) => {
    return baseApiClient.put(
      `template/${templateId}/fileformdata`,
      templateFileFormDataUpdate
    );
  };

  getTemplateFields = async () => {
    return baseApiClient.get<ExpressionData[]>(`template/fields`);
  };

  getTemplateFieldsData = async (applicationId: string) => {
    return baseApiClient.get<any>(
      `template/fields/application-data/${applicationId}`
    );
  };

  softDeleteTemplate = async (templateId: string) => {
    return baseApiClient.delete(`template/${templateId}`);
  };

  // This is a test endpoint . Please ignore any clause.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  populate = async (templateId: string, data: any) => {
    return baseApiClient.download(`template/${templateId}/populate`, data);
  };
}

export const templateApiClient = new TemplateApiClient();
