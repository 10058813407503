import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useAccount,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { googleTagManagerArgs } from "../config/googleTagManager";
import { msalInstance } from "../lib/msal/msal";
import { schemaStore } from "../stores/SchemaStore";
import { userStore } from "../stores/UserStore";
import { RouterProvider } from "react-router-dom";
import { browserRouter } from "../components/Routes/routeConfig";
import { useTitle } from "../components/UseTitle/useTitle";
import { featureToggleStore } from "../lib/featureToggles/FeatureToggleStore";
import { FieldSchemaV2FactoryProvider } from "../lib/fieldSchemaV2/useFieldSchemaV2Factory";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function AuthenticatedPages() {
  return (
    <MsalProvider instance={msalInstance}>
      <Pages />
    </MsalProvider>
  );
}

function Pages() {
  const account = useAccount();
  const [isLoaded, setIsLoaded] = useState(false);

  useTitle("Medicaid Done Right");

  useEffect(() => {
    if (account != null) {
      if (googleTagManagerArgs.gtmId) {
        TagManager.initialize(googleTagManagerArgs);
      }

      Promise.all([
        schemaStore.initSchema(),
        userStore.signIn(),
        featureToggleStore.init(),
      ]).finally(() => {
        if (!schemaStore.schema) {
          throw new Error(
            "Field Schema could not be loaded. Verify the API is running, and configured."
          );
        }
        if (!userStore.user) {
          throw new Error("User could not be loaded");
        }
        setIsLoaded(true);
      });
    } else {
      msalInstance.handleRedirectPromise().then(async (loggedInAccount) => {
        const isLoggedIn = !!loggedInAccount;

        if (!isLoggedIn) {
          await msalInstance.loginRedirect();
        }
      });
    }
  }, [account]);

  return isLoaded ? (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <FieldSchemaV2FactoryProvider>
        <RouterProvider router={browserRouter} />
      </FieldSchemaV2FactoryProvider>
    </MsalAuthenticationTemplate>
  ) : null;
}
