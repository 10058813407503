import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { Discount, NewDiscount } from "../../../types/Discount";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newDiscount: Partial<Discount> = {
  quantityStartRange: undefined,
  discountAmount: undefined,
};

export const discountFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "discount";
  const discount = form as NewDiscount;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "quantityStartRange"),
      value: discount.quantityStartRange,
      onSubmit: (quantityStartRange) => {
        onValueChange({ quantityStartRange });
      },
      errorMessages: get(errors, "quantityStartRange"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "discountAmount"),
      value: discount.discountAmount,
      onSubmit: (discountAmount) => {
        onValueChange({ discountAmount });
      },
      errorMessages: get(errors, "discountAmount"),
      isRequired: true,
    } as AllFields,
  ];
};
