import React from "react";
import { Observer } from "mobx-react";
import classNames from "classnames";
import { DocumentPagesViewer } from "../Document/DocumentPagesViewer";
import { Button, Classes, Intent, NonIdealState } from "@blueprintjs/core";
import { Loading } from "../Loading/Loading";
import {
  DocumentThumbnailOperation,
  useDocumentEditor,
} from "./useDocumentEditor";
import { IconNames } from "@blueprintjs/icons";
import { DocumentAction, DocumentActionTypes } from "./useDocumentPagesViewer";
import { Document as MdrDocument } from "../../types/Document";

export interface DocumentEditorProps {
  documentId: string;
  onCancel: () => void;
  onUpdate: (
    documentOperations: DocumentThumbnailOperation[],
    documentPagesHdImages: {
      [pageId: number]: string;
    }
  ) => void;
}

export const DocumentEditor: React.FC<DocumentEditorProps> = (props) => {
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  React.useState<boolean>(false);
  const [isActionExecuting, setIsActionExecuting] =
    React.useState<boolean>(false);

  const {
    ready,
    isLoadingDocument,
    mdrDocument,
    selectedDocumentThumbnails,
    selectionChangedHandler,
    unselectAllPages,
    documentOperations,
    updateDocument,
    rotate,
    documentPagesViewermModule,
    isOriginalFileNameModified,
  } = useDocumentEditor({ ...props });

  React.useEffect(() => {
    const actionExecuting = isLoadingDocument || isSaving;
    setIsActionExecuting(actionExecuting);
  }, [isLoadingDocument, isSaving]);

  const footer = (
    <div
      className={classNames(Classes.DIALOG_FOOTER, {
        ready: ready,
      })}
      onClick={() => {
        unselectAllPages();
      }}
    >
      <Button
        minimal
        intent={Intent.DANGER}
        text="Cancel"
        disabled={isActionExecuting}
        onClick={() => {
          props.onCancel();
        }}
      />
      <Button
        minimal
        intent={Intent.PRIMARY}
        text="Save"
        disabled={
          isActionExecuting ||
          ((documentOperations?.length || 0) <= 0 &&
            !isOriginalFileNameModified)
        }
        loading={isSaving}
        onClick={async () => {
          unselectAllPages();

          setIsSaving(true);

          const result = await updateDocument();

          setIsSaving(false);

          if (result) {
            props.onUpdate(documentOperations, result);
          }
        }}
      />
    </div>
  );

  return (
    <section className="document-editor-container">
      <Observer>
        {() => (
          <section
            className={classNames("document-editor", {
              "document-editor-original-filename-modified":
                isOriginalFileNameModified,
            })}
          >
            {!ready && <Loading />}
            {ready && (
              <>
                <div className="document-editor-content">
                  <div className="document-editor-documents">
                    {isLoadingDocument && <Loading />}
                    {!isLoadingDocument && !mdrDocument && (
                      <NonIdealState title="No Documents Available" />
                    )}
                    {!isLoadingDocument && mdrDocument && (
                      <>
                        <DocumentPagesViewer
                          ref={documentPagesViewermModule}
                          documents={[mdrDocument as MdrDocument]}
                          documentItems={[
                            DocumentActionTypes.SelectAll,
                            {
                              name: "rotate",
                              text: "Rotate",
                              disabled:
                                !selectedDocumentThumbnails ||
                                selectedDocumentThumbnails.length <= 0,
                              minimal: true,
                              icon: IconNames.RotatePage,
                              intent: Intent.NONE,
                              onActionClick: () => {
                                rotate();
                              },
                            } as DocumentAction<Document>,
                            DocumentActionTypes.Refresh,
                          ]}
                          disabled={isActionExecuting}
                          onSelectionChanged={selectionChangedHandler}
                          documentThumbnailsProperties={
                            selectedDocumentThumbnails
                          }
                          isAnimated={true}
                          expandAll={true}
                          disabledCollapse={true}
                          disabledSelectionOrder={false}
                          disabledPagePreview={true}
                        />
                      </>
                    )}
                  </div>
                </div>
                {footer}
              </>
            )}
          </section>
        )}
      </Observer>
    </section>
  );
};
