import { PrimaryInformation } from "./PrimaryInformation";
import { ExistingResource } from "../ExistingResource";
import { SecondaryInformation } from "./SecondaryInformation";
import { MedicalInformation } from "./MedicalInformation";
import { DateInformation } from "./DateInformation";
import { MedicalCoverage } from "./MedicalCoverage";
import { ExistingApplicationContact } from "./ApplicationContact";
import { LegalInformation } from "./LegalInformation";
import { SpouseInformation } from "./SpouseInformation";
import { Signatures } from "./Signatures";
import { AssetInformation, Asset } from "./Asset";
import { Bill } from "./Bill";
import { BillInformation } from "./BillInformation";
import { Income } from "./Income";
import { IncomeInformation } from "./IncomeInformation";
import { Signature } from "./Signature";
import { StateInformation } from "./StateInformation";
import { ExistingMonitor } from "../Monitor";
import { ExistingMedicaidEligibilityDecision } from "../MedicaidEligibilityDecision";
import { NonFinancialCalculationsDTO } from "./EligibilitySummary";

export enum ApplicationStatuses {
  interviewing = 1,
  inScheduling = 2,
  planning = 3,
  applied = 4,
  submitted = 5,
  hearing = 6,
  futureRecert = 7,
  monitor = 8,
  denied = 9,
  approved = 10,
}

export enum InternalStatuses {
  Open = 1,
  Closed = 2,
}

export enum ApplicationProductTypes {
  NewApplication = 1,
  ConversionToLTC = 2,
  DocsOnly = 3,
  Guardianship = 4,
  LiabilityAdjustment = 5,
  NewRecertification = 6,
  PatientLiabilityVerification = 7,
  PostApprovalAction = 8,
  PreAdmit = 9,
  Recertification = 10,
  Retro = 11,
  UMED = 12,
}

export const getApplicationProductType = (productType: number | undefined) => {
  switch (productType) {
    case ApplicationProductTypes.NewApplication:
      return "New Application";
    case ApplicationProductTypes.ConversionToLTC:
      return "Conversion to LTC";
    case ApplicationProductTypes.DocsOnly:
      return "Docs Only";
    case ApplicationProductTypes.Guardianship:
      return "Guardianship";
    case ApplicationProductTypes.LiabilityAdjustment:
      return "Liability Adjustment";
    case ApplicationProductTypes.NewRecertification:
      return "New Recertification";
    case ApplicationProductTypes.PatientLiabilityVerification:
      return "Patient Liability Verification";
    case ApplicationProductTypes.PostApprovalAction:
      return "Post Approval Action";
    case ApplicationProductTypes.PreAdmit:
      return "Pre-Admit";
    case ApplicationProductTypes.Recertification:
      return "Recertification";
    case ApplicationProductTypes.Retro:
      return "Retro";
    case ApplicationProductTypes.UMED:
      return "UMED";
    default:
      return "";
  }
};

export type ApplicationFormPage =
  | "primary-information"
  | "secondary-information"
  | "medical-information"
  | "spouse-information"
  | "date-information"
  | "income-information"
  | "income"
  | "medical-coverage"
  | "asset"
  | "asset-information"
  | "bill"
  | "bill-information"
  | "contact"
  | "legal-information"
  | "signatures"
  | "state-information"
  | "checklist"
  | "fia-requests"
  | "comments"
  | "audit-log";

export type ApplicationPages =
  | "primary-information"
  | "date-information"
  | "state-information";

export type NewApplication = PrimaryInformation &
  SecondaryInformation &
  MedicalInformation &
  SpouseInformation &
  DateInformation &
  LegalInformation &
  StateInformation &
  Signatures &
  IncomeInformation & { incomes: Income[] } & BillInformation & {
    bills: Bill[];
  } & { medicalCoverages: MedicalCoverage[] } & {
    contacts: ExistingApplicationContact[];
  } & AssetInformation & { assets: Asset[] } & { signatures: Signature[] } & {
    canApplicationSubmitToState: boolean;
    hasApplicationStateSubmitted: boolean;
  };

export type ApplicationBillAmount = {
  type: number;
  type_AsString: string;
  amount: number;
};
export type Application = ExistingResource<NewApplication> & {
  status: number;
  isWithdrawn: boolean;
  withdrawnReason: number;
  originApplicationId: string;
  monitor: ExistingMonitor;
  progress: number;
  progressSubmitToState: number;
  currentPage: ApplicationFormPage;
  fileNumber: string;
  outstandingFIARequestsCount: number;
  statesPersonCareAllowance: number | null;
  isApplicationInNewProductType: boolean;
  isApplicationClosed: boolean;
  isFacilityStateFL: boolean;
  isRecertProductType: boolean;
  hasGeneratedChecklist: boolean;
  canGenerateChecklist: boolean;
  unresolvedChecklistGenerationDependencies: string[];

  scheduleMappingValues: string[];

  billAmounts: ApplicationBillAmount[];
  billItemsTotal: number;
  medicalCoveragePremiums: number;
  estPatientLiabilityItemsTotal: number;

  //Override Asset Eligibility
  overrideAssetEligibility: boolean;
  // Income flag
  includeAllIncomesInSummary: boolean;
  includeAllAssetsInSummary: boolean;
  //Resident Income - AMD
  residentMonthlyIncomeValue: number;
  residentIncomeItemsTotal: number;
  combinedSocialSecurityAmount: number;
  combinedSSIAmount: number;
  combinedVABenefitsAmount: number;
  combinedAdditionalIncomeAmount: number;
  combinedIncomeItemsTotal: number;

  //Spouse Income - AMD
  spouseMonthlyIncomeValue: number;
  spouseIncomeItemsTotal: number;

  // Joint Income - AMD
  jointMonthlyIncomeValue: number;

  //Resident Assets - AMD
  residentAssetPropertyValue: number;
  residentAssetVehicleValue: number;
  residentAssetBankAccountValue: number;
  residentAssetLifeInsuranceValue: number;
  residentAssetBurialPreneedValue: number;
  residentAssetOtherValue: number;
  residentAssetItemsTotalValue: number;
  // Spouse Assets - AMD
  spouseAssetPropertyValue: number;
  spouseAssetVehicleValue: number;
  spouseAssetBankAccountValue: number;
  spouseAssetLifeInsuranceValue: number;
  spouseAssetBurialPreneedValue: number;
  spouseAssetOtherValue: number;
  spouseAssetItemsTotalValue: number;
  //Joint Assets - AMD
  jointTypeAssetPropertyValue: number;
  jointTypeAssetVehicleValue: number;
  jointTypeAssetBankAccountValue: number;
  jointTypeAssetLifeInsuranceValue: number;
  jointTypeAssetBurialPreneedValue: number;
  jointTypeAssetOtherValue: number;
  jointTypeAssetItemsTotalValue: number;
  // Combined Assets - AMD
  combinedAssetPropertyValue: number;
  combinedAssetVehicleValue: number;
  combinedAssetBankAccountValue: number;
  combinedAssetLifeInsuranceValue: number;
  combinedAssetBurialPreneedValue: number;
  combinedAssetOtherValue: number;
  combinedAssetItemsTotal: number;

  medicaidEligibilityDecision?: ExistingMedicaidEligibilityDecision;
  nonFinancialCalculations: NonFinancialCalculationsDTO;
  medicaidEligibilityStatus_AsString: string;
  isMarried: boolean;
  isSpouseLTCFacility: boolean;
  useResidentPlusHalfJointAssetsCalculation: boolean;
  recertResidentPlusHalfJointAssetsValue: number;

  applyDeadlineDate: string;
  reapplyDeadlineDate: string;
  interviewDeadlineDate: string;
  hardDeadlineDate: string;
  extensionRequestDeadlineDate: string;
  extendedDeadlineDate: string;
  appealDeadlineDate: string;
  nextDeadlineDate: string;
  financialEligibilityConversationConfirmation?: boolean;

  dateTimelineShouldIncludeApplyDeadlineDate: boolean;
  dateTimelineShouldIncludeReapplyDeadlineDate: boolean;
  dateTimelineShouldIncludeInterviewDate: boolean;
  dateTimelineShouldIncludeInterviewDeadlineDate: boolean;

  // FIA Info
  hasPendingFIARequests?: boolean;
};

export type Resident = PrimaryInformation &
  SecondaryInformation & {
    contacts: ExistingApplicationContact[];
  };
