import { IconNames } from "@blueprintjs/icons";
import { deep } from "../../../utils/clone";
import GridActionsColumn, {
  GridAction,
} from "../../Grid/Grid/types/GridActionsColumn";
import GridColumn from "../../Grid/Grid/types/GridColumn";
import { OrderedDocumentReadDTO } from "./types";

export const orderedDocumentGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "discriminatorId",
    headerText: "Id",
    type: "string",
    visible: false,
  }),
  new GridColumn({
    field: "order",
    headerText: "Order",
    type: "number",
    width: 75,
    textAlign: "Center",
  }),
  new GridColumn({
    field: "documentName",
    headerText: "Document Name",
    type: "string",
  }),
  new GridColumn({
    field: "documentType",
    headerText: "Type",
    type: "string",
    width: 150,
  }),
];

export const setupActions = (
  previewAction?: (rowData: OrderedDocumentReadDTO) => void,
  downloadAction?: (rowData: OrderedDocumentReadDTO) => void,
  deleteAction?: (rowData: OrderedDocumentReadDTO) => void
): GridColumn[] => {
  const config = deep<GridColumn[]>(orderedDocumentGridColumnsConfiguration);

  if (!previewAction && !downloadAction && !deleteAction) {
    return config;
  }

  // Actions
  const actions: GridAction<OrderedDocumentReadDTO>[] = [];
  if (previewAction) {
    actions.push({
      icon: IconNames.EyeOpen,
      className: "ordered-document-grid-actions-preview",
      onActionClick: previewAction,
      alwaysEnabled: true,
    });
  }
  if (downloadAction) {
    actions.push({
      icon: IconNames.Download,
      className: "ordered-document-grid-actions-download",
      onActionClick: downloadAction,
      alwaysEnabled: true,
    });
  }
  if (deleteAction) {
    actions.push({
      icon: IconNames.BanCircle,
      className: "ordered-document-grid-actions-delete",
      onActionClick: deleteAction,
    });
  }

  config.push(
    new GridActionsColumn<OrderedDocumentReadDTO>({
      headerText: "Actions",
      width: 120,
      clipMode: "Clip",
      customAttributes: { class: "ordered-document-grid-actions" },
      actions: actions,
    })
  );

  return config;
};
