import { Ajax } from "@syncfusion/ej2-base";
import {
  CrudOptions,
  DataOptions,
  DataResult,
  Query,
  ODataV4Adaptor,
  DataManager,
} from "@syncfusion/ej2-data";
import { ODataBeforeSendArgs } from "../../Grid/Grid/types/ODataBeforeSendArgs";
import { FieldSettingsModel } from "@syncfusion/ej2-react-dropdowns";
import _ from "lodash";

export const notInListValue = "not-in-list";
export const inactiveCategory = "Inactive";

export default class AsyncAutocompleteODataV4Adaptor extends ODataV4Adaptor {
  private onBeforeSend?: (
    beforeSendArgs: ODataBeforeSendArgs
  ) => [string, string | undefined];

  constructor(
    private hasNotInListOption: boolean,
    private groupbyActiveStatus: boolean,
    private fieldSettings?: FieldSettingsModel,
    beforeSendFn?: (
      beforeSendArgs: ODataBeforeSendArgs
    ) => [string, string | undefined]
  ) {
    super();
    this.onBeforeSend = beforeSendFn;
  }

  beforeSend = async (
    dataManager: DataManager,
    request: XMLHttpRequest,
    ajax: Ajax
  ) => {
    // Fetch Query
    const url = new URL(ajax.url);
    let queryString = url.search;

    // Cleanups
    //--Replaces any undefined keyword for null to supprto api types
    queryString = queryString.replace(/undefined/g, "null");
    let accessToken = undefined;

    // Send onBeforeSend event
    if (!!this.onBeforeSend) {
      const [qry, token] = this.onBeforeSend({
        dataManager,
        request,
        ajax,
        queryString,
      } as ODataBeforeSendArgs);

      queryString = qry;
      accessToken = token;
    }

    // Send request on Body
    // Note that is important that the body still sends the ? on the beginning for full support of queriers, including top.
    ajax.data = queryString;
    request.open("POST", String(`${url.origin}${url.pathname}$query`));

    // Set Access Token
    if (!!accessToken) {
      request.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    }
  };

  processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Ajax,
    changes?: CrudOptions
  ): Object {
    const processResult = super.processResponse.apply(this, [
      data,
      ds,
      query,
      xhr,
      request,
      changes,
    ]);

    const addNotInListOption = () => {
      const processResultAvailable = (processResult as Array<any>).map((x) => {
        return {
          ...x,
          ...{ category: "Available" },
        };
      });

      const notInListOption = {
        category: "Unavailable",
        [this.fieldSettings?.text || "label"]: "Not In List",
        [this.fieldSettings?.value || "value"]: notInListValue,
      };

      return [...(processResultAvailable as any), notInListOption];
    };

    const categorizeListByActiveStatus = () => {
      const processResultActive = (processResult as Array<any>)
        .filter((x) => x.isActive)
        .map((x) => {
          return {
            ...x,
            ...{ category: "Active" },
          };
        });

      const processResultInactive = (processResult as Array<any>)
        .filter((x) => !x.isActive)
        .map((x) => {
          return {
            ...x,
            ...{ category: inactiveCategory },
          };
        });

      if (this.hasNotInListOption) {
        const notInListOption = {
          category: "Unavailable",
          [this.fieldSettings?.text || "label"]: "Not In List",
          [this.fieldSettings?.value || "value"]: notInListValue,
        };
        return [
          ...(processResultActive as any),
          ...(processResultInactive as any),
          notInListOption,
        ];
      }
      return [
        ...(processResultActive as any),
        ...(processResultInactive as any),
      ];
    };

    // Process
    let results = processResult as Array<any>;

    if (this.groupbyActiveStatus) {
      results = [...categorizeListByActiveStatus()];
    }

    if (this.hasNotInListOption && !this.groupbyActiveStatus) {
      results = [...addNotInListOption()];
    }
    return results;
  }
}
