import { Billing, NewBilling } from "../../../types/Billing";
import { BillingRule } from "../../../types/BillingRule";
import { Discount, NewDiscount } from "../../../types/Discount";
import baseApiClient from "../baseApiClient";

class BillingApiClient {
  getBillingById = async (id: string) => {
    return baseApiClient.get<Billing>(`billing/billing-event/${id}`);
  };

  createBilling = async (billing: NewBilling) => {
    return baseApiClient.post<Billing>(`billing/billing-event`, billing);
  };

  createImmediateCredit = async (
    id: string,
    verifyIfUninvoicedCreditExists: boolean
  ) => {
    return baseApiClient.post<Billing>(
      `billing/billing-event/${id}/immediate-credit`,
      { verifyIfUninvoicedCreditExists }
    );
  };

  createFutureCredit = async (
    id: string,
    verifyIfUninvoicedCreditExists: boolean
  ) => {
    return baseApiClient.post<Billing>(
      `billing/billing-event/${id}/future-credit`,
      { verifyIfUninvoicedCreditExists }
    );
  };

  updateBilling = async (id: string, billing: Billing) => {
    return baseApiClient.put<Billing>(`billing/billing-event/${id}`, billing);
  };

  createBillingRule = async (billingRule: BillingRule) => {
    return baseApiClient.post<BillingRule>(`billing/billing-rule`, billingRule);
  };

  updateBillingRule = async (id: string, billingRule: BillingRule) => {
    return baseApiClient.put<BillingRule>(
      `billing/billing-rule/${id}`,
      billingRule
    );
  };

  createDiscount = async (discount: NewDiscount) => {
    return baseApiClient.post<Discount>(`billing/discount`, discount);
  };

  updateDiscount = async (id: string, discount: Discount) => {
    return baseApiClient.put<Discount>(`billing/discount/${id}`, discount);
  };

  deleteDiscount = async (id: string) => {
    return baseApiClient.delete<Discount>(`billing/discount/${id}`);
  };
}

export const billingApiClient = new BillingApiClient();
