import { Checkbox, FormGroup } from "@blueprintjs/core";
import { isArray } from "lodash";
import React, { useEffect } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { CheckboxFieldValue, CheckboxFieldProps } from "../types/fieldTypes";

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  description,
  value,
  optionValues,
  disabled,
  hidden,
  errorMessages,
  onSubmit,
}) => {
  if (hidden) {
    return null;
  }

  const [localValues, setLocalValues] =
    React.useState<CheckboxFieldValue>(value);

  useEffect(() => {
    setLocalValues(value);
  }, [value]);

  return (
    <FormGroup className="checkbox-field">
      {label && <SelectableLabel name={label} description={description} />}
      {(isArray(localValues) || typeof localValues === "string") &&
        optionValues
          .filter((option) => !!option.value)
          .map((option, idx) => {
            const checked = localValues.some((arg) => arg === option.value);
            return (
              <Checkbox
                key={idx}
                name={option.label}
                label={option.label}
                disabled={disabled}
                checked={checked}
                onChange={() => {
                  let newLocalValues: CheckboxFieldValue;
                  if (checked) {
                    const filteredValues = localValues.filter(
                      (localValue) => localValue != option.value
                    );
                    newLocalValues =
                      filteredValues.length > 0 ? filteredValues : [];
                  } else {
                    newLocalValues = localValues
                      ? [...localValues, option.value]
                      : [option.value];
                  }
                  setLocalValues(newLocalValues);
                  onSubmit(newLocalValues);
                }}
              />
            );
          })}
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
