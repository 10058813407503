import { NonIdealState } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { ChecklistItem } from "../../types/Checklist";
import { PdfDocuments } from "../Document/PdfDocuments";
import { reactPlugin } from "../../AppInsights";
import { checklistItemStore } from "../../stores/ChecklistItemStore";

export interface FiaRequestDVTChecklistDocumentsProps {
  checklistItem: ChecklistItem;
  documentCount?: number;
}

const FiaRequestDVTChecklistDocuments: FC<
  FiaRequestDVTChecklistDocumentsProps
> = (props) => {
  return (
    <div>
      {!props.checklistItem && (
        <NonIdealState title="No Information Available" />
      )}
      {!!props.checklistItem && (
        <div className="fia-request-dvt-checklist-documents">
          {props.documentCount
            ? `Number of Documents: ${props.documentCount}`
            : null}
          <PdfDocuments
            checklistItem={props.checklistItem}
            disableEditDoc={true}
            disablePreviewDoc={true}
            disableDeleteDoc={true}
            disableVerifyDoc={true}
            setUIChecklistItemFileCount={async (_) => {
              await checklistItemStore.setChecklistItems(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export const FiaRequestDVTChecklistDocumentsComponent = withAITracking(
  reactPlugin,
  FiaRequestDVTChecklistDocuments,
  "FiaRequestDVTChecklistDocuments",
  "AI-flex-container"
);
