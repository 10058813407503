import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { userStore } from "../../../stores/UserStore";
import { Application } from "../../../types/Application";
import { UserRoles } from "../../../types/User";
import {
  AllFields,
  FullNameFieldValue,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const residentPrimaryInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  formId
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const application = form as Application;

  return [
    {
      type: "FullName",
      label: "Resident Name",
      onSubmit: (name) => {
        onValueChange({ ...(name as FullNameFieldValue) });
      },
      value: {
        firstName: application.firstName,
        middleName: application.middleName,
        maidenName: application.maidenName,
        lastName: application.lastName,
      },
      errorMessages: [
        ...(get(errors, "firstName") || []),
        ...(get(errors, "lastName") || []),
      ],
      isRequired: true,
      disabled: !application.canEditResidentPersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "socialSecurityNumber"),
      onSubmit: (socialSecurityNumber) => {
        onValueChange({ socialSecurityNumber });
      },
      value: application.socialSecurityNumber,
      isRequired: true,
      errorMessages: get(errors, "socialSecurityNumber"),
      isSensitive:
        userStore.user &&
        [
          UserRoles.FieldRepresentative,
          UserRoles.FieldRepresentativeManager,
        ].includes(userStore.user.role),
      disabled: !application.canEditResidentPersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "dateOfBirth"),
      value: application.dateOfBirth || "",
      onSubmit: (dateOfBirth) => {
        onValueChange({ dateOfBirth });
      },
      errorMessages: get(errors, "dateOfBirth"),
      isRequired: true,
      maxDate: new Date(Date.now()),
      minDate: new Date("1900-01-01"),
      disabled: !application.canEditResidentPersonalInformation,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "email"),
      value: application.email,
      onSubmit: (email) => {
        onValueChange({ email });
      },
      formId: formId,
      errorMessages: get(errors, "email"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "phoneNumber"),
      value: application.phoneNumber,
      onSubmit: (phoneNumber) => {
        onValueChange({ phoneNumber });
      },
      errorMessages: get(errors, "phoneNumber"),
      width: "45%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "additionalPhoneNumber"),
      value: application.additionalPhoneNumber,
      onSubmit: (additionalPhoneNumber) => {
        onValueChange({ additionalPhoneNumber });
      },
      width: "45%",
      errorMessages: get(errors, "additionalPhoneNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "previousAddress"),
      disabled: !application.canEditResidentPersonalInformation,
      onSubmit: (previousAddress) => {
        onValueChange({ previousAddress });
      },
      value: application.previousAddress,
      isRequired: true,
      errorMessages: [
        ...(get(errors, "previousAddress.Street") || []),
        ...(get(errors, "previousAddress.City") || []),
        ...(get(errors, "previousAddress.State") || []),
        ...(get(errors, "previousAddress.Zip") || []),
        ...(get(errors, "previousAddress.County") || []),
      ],
    } as AllFields,
  ];
};
