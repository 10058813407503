import { useEffect, useState } from "react";

export const useActionExecuting = (actionLoadingStates: Array<boolean>) => {
  const [isActionExecuting, setIsActionExecuting] = useState<boolean>(false);

  useEffect(() => {
    const actionExecuting = actionLoadingStates.filter((x) => x).length > 0;

    setIsActionExecuting(actionExecuting);
  }, actionLoadingStates);

  return {
    isActionExecuting,
  };
};
