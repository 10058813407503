import { Elevation, NonIdealState } from "@blueprintjs/core";
import { FC, useEffect, useState } from "react";
import { billOwnedEntityStore } from "../../../../../../stores/OwnedEntityStores/BillOwnedEntityStore";
import { Application } from "../../../../../../types/Application";
import { Bill } from "../../../../../../types/Application/Bill";
import { AddButton } from "../../../../../Buttons/AddButton";
import { DeleteButton } from "../../../../../Buttons/DeleteButton";
import { SaveButton } from "../../../../../Buttons/SaveButton";
import { UndoButton } from "../../../../../Buttons/UndoButton";
import RelativityCard from "../../../../../Card/RelativityCard";
import { SectionHeader } from "../../../../../Fields/SectionHeader/SectionHeader";
import Form from "../../../../../Forms";
import {
  billFormSchema,
  newBill,
} from "../../../../../Forms/ApplicationForm/Bill";
import { showSuccessfulSaveAppToaster } from "../../../../../Toast/Toast";
import _, { orderBy } from "lodash";

export interface BillPageCardProps {
  application?: Application;
  canCreate: boolean;
  canEdit: boolean;
  createAndUpdateBills: () => Promise<void>;
  deleteBill: (bill: Bill) => Promise<void>;

  shouldDisableForm: boolean;
  setShouldDisableForm: (shouldDisable: boolean) => void;
  formIsSaving: boolean;
  setFormIsSaving: (shouldDisable: boolean) => void;
}

const newBillId = "new-bill";
const sectionTitle = "Bill";
const baseFormId = "bill-form";
export const BillPageCard: FC<BillPageCardProps> = (props) => {
  const [localBills, setLocalBills] = useState<Bill[]>(
    props.application?.bills || []
  );

  const [billToReset, setBillToReset] = useState<string>();
  const [isInDeleteAction, setIsInDeleteAction] = useState<string>();
  const [isAddingAction, setIsAddingAction] = useState<boolean>(false);

  const isActiveDelete = (asset: Bill) => {
    return asset.id === isInDeleteAction;
  };

  const hasNewBill = localBills.some((bill) => bill.id === newBillId);

  const handleReset = async (billToReset: Bill) => {
    const exisitingLocalBills = [...localBills];

    exisitingLocalBills.forEach((bill, index) => {
      if (bill.id === billToReset.id) {
        exisitingLocalBills[index] = { ...bill, ...billToReset };
      }
    });

    setLocalBills(exisitingLocalBills);
  };

  useEffect(() => {
    setLocalBills(props.application?.bills || []);
  }, [props.application?.bills]);

  return (
    <RelativityCard
      headerContent={
        <div className="owned-entity-list-header">
          <SectionHeader type="Section" label="Bills" />
          {props.canCreate && (
            <AddButton
              loading={isAddingAction}
              disabled={
                props.shouldDisableForm || props.formIsSaving || hasNewBill
              }
              text={`Add ${sectionTitle}`}
              onClick={() => {
                props.setShouldDisableForm(true);
                setIsAddingAction(true);
                const bill = { ...newBill, id: newBillId } as Bill;

                setLocalBills([...localBills, bill]);
                props.setShouldDisableForm(false);
                setIsAddingAction(false);
              }}
            />
          )}
        </div>
      }
    >
      {localBills?.length <= 0 && (
        <NonIdealState title="No Information Available" />
      )}
      {localBills?.length > 0 &&
        orderBy(localBills, ["createdAt"], ["desc"]).map((bill, index) => {
          const isNewBill = bill.id === newBillId;

          return (
            <RelativityCard
              key={bill.id}
              className="owned-entity-card bill-card"
              elevation={Elevation.TWO}
              headerContent={
                <div className="owned-entity-header asset-card-header">
                  <SectionHeader
                    label={`${sectionTitle} ${index + 1}`}
                    type="Section"
                    inAction={props.formIsSaving}
                  />
                  <div>
                    {props.canEdit && (
                      <>
                        <>
                          <SaveButton
                            formId={`${baseFormId}-${bill.id}`}
                            isSaving={props.formIsSaving}
                            disabled={
                              isActiveDelete(bill) || props.shouldDisableForm
                            }
                            hidden={isActiveDelete(bill)}
                          />
                          <UndoButton
                            isSaving={
                              props.formIsSaving || isActiveDelete(bill)
                            }
                            disabled={props.shouldDisableForm}
                            onClick={async () => {
                              setBillToReset(bill.id);
                              //Need to Await Changes in states in order to update correctly
                              await handleReset(bill);
                              setBillToReset(undefined);
                            }}
                          />
                          <DeleteButton
                            isDeleting={isActiveDelete(bill)}
                            disabled={
                              props.formIsSaving || props.shouldDisableForm
                            }
                            hidden={props.formIsSaving}
                            onClick={async () => {
                              props.setShouldDisableForm(true);
                              setIsInDeleteAction(bill.id);

                              if (isNewBill) {
                                setLocalBills(
                                  localBills.filter(
                                    (bill) => bill.id !== newBillId
                                  )
                                );
                                billOwnedEntityStore.removeLocalBill(bill);
                              } else {
                                await props.deleteBill(bill);
                              }

                              setIsInDeleteAction(undefined);

                              props.setShouldDisableForm(false);
                            }}
                          />
                        </>
                      </>
                    )}
                  </div>
                </div>
              }
            >
              <Form<Bill>
                formId={`${baseFormId}-${bill.id}`}
                value={bill}
                formSchemaBuilder={billFormSchema}
                shouldBlockNavigation={true}
                shouldResetForm={billToReset === bill.id}
                disableAllFields={
                  !props.canEdit ||
                  props.formIsSaving ||
                  isActiveDelete(bill) ||
                  isAddingAction ||
                  props.shouldDisableForm
                }
                shouldSaveEntiityToLocalStore={true}
                ownedEntityStoreAction={(updatedBill) => {
                  if (JSON.stringify(updatedBill) !== JSON.stringify(bill)) {
                    billOwnedEntityStore.addLocalBill(updatedBill);
                  }
                }}
                onFormSubmit={async () => {
                  props.setShouldDisableForm(true);
                  props.setFormIsSaving(true);

                  await props.createAndUpdateBills().then(() => {
                    showSuccessfulSaveAppToaster(sectionTitle);
                  });

                  props.setFormIsSaving(false);
                  props.setShouldDisableForm(false);
                }}
              />
            </RelativityCard>
          );
        })}
    </RelativityCard>
  );
};
