import { useState } from "react";
import { applicationStore } from "../../stores/ApplicationStore";
import assetSchemas from "../Forms/ApplicationForm/Assets";
import {
  MedicalCoverage,
  NewMedicalCoverage,
} from "../../types/Application/MedicalCoverage";
import { Income, NewIncome } from "../../types/Application/Income";
import { Asset, NewAsset } from "../../types/Application/Asset";
import { Bill, NewBill } from "../../types/Application/Bill";
import { ChecklistItem } from "../../types/Checklist";

export const medicalCoverageTypeString =
  "MDR.Domain.Models.Application.MedicalCoverage";
export const incomeTypeString = "MDR.Domain.Models.Application.Income";
export const assetTypeString = "MDR.Domain.Models.Application.Asset";
export const billTypeString = "MDR.Domain.Models.Application.Bill";
export const otherTypeString = "Other";

export type OwnedEntityType = MedicalCoverage | Income | Asset | Bill;
export type NewOwnedEntityType =
  | NewAsset
  | NewBill
  | NewIncome
  | NewMedicalCoverage;
export const getAssetTypeSchema = (assetSubType: string) => {
  return assetSchemas.find((schema) => schema.type.toString() === assetSubType);
};
export const getExistingOwnedEntity = (
  entityType: string,
  entityId: string
) => {
  const entityMap: Record<string, OwnedEntityType[]> = {
    [medicalCoverageTypeString]:
      applicationStore.application?.medicalCoverages || [],
    [incomeTypeString]: applicationStore.application?.incomes || [],
    [assetTypeString]: applicationStore.application?.assets || [],
    [billTypeString]: applicationStore.application?.bills || [],
  };

  return entityMap[entityType]?.find((entity) => entity.id === entityId);
};
export const useOwnedEntityComponent = () => {
  const [assetTypeSchema, setAssetTypeSchema] = useState<any | undefined>(
    undefined
  );
  const [ownedEntityTypeString, setOwnedEntityTypeString] =
    useState<string>("");
  const [existingOwnedEntity, setExistingOwnedEntity] = useState<
    OwnedEntityType | undefined
  >(undefined);

  const getOwnedEntityByChecklistItemEntityType = (
    checklistItem: ChecklistItem
  ) => {
    if (
      checklistItem?.entityType === assetTypeString ||
      checklistItem?.entityType === incomeTypeString ||
      checklistItem?.entityType === billTypeString ||
      checklistItem?.entityType === medicalCoverageTypeString
    ) {
      const ownedEntity = getExistingOwnedEntity(
        checklistItem.entityType,
        checklistItem.entityId
      );
      setOwnedEntityTypeString(checklistItem.entityType);
      setExistingOwnedEntity(ownedEntity);

      if (checklistItem.entityType === assetTypeString) {
        const schema = getAssetTypeSchema(String((ownedEntity as Asset)?.type));
        setAssetTypeSchema(schema);
      }
    }
  };

  const resetOwnedEntityStates = () => {
    setOwnedEntityTypeString("");
    setExistingOwnedEntity(undefined);
    setAssetTypeSchema(undefined);
  };

  const setOwnedEntityToDisplay = <
    T extends OwnedEntityType,
    U extends NewOwnedEntityType
  >(
    existingOwnedEntity: T | undefined,
    newOwnedEntity: U,
    newOwnedEntityId: string
  ): T[] => {
    return existingOwnedEntity
      ? [existingOwnedEntity]
      : [{ ...newOwnedEntity, id: newOwnedEntityId } as unknown as T];
  };

  return {
    assetTypeSchema,
    setAssetTypeSchema,
    ownedEntityTypeString,
    setOwnedEntityTypeString,
    getExistingOwnedEntity,
    setOwnedEntityToDisplay,
    existingOwnedEntity,
    setExistingOwnedEntity,
    getOwnedEntityByChecklistItemEntityType,
    resetOwnedEntityStates,
  };
};
