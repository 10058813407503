import { LeadDocumentCreateDTO } from "../../../components/Matchup/types";
import { ConvertLeadToApplicationDTO } from "../../../types/ConvertLeadToApplicationDTO";
import { Lead } from "../../../types/Lead";
import baseApiClient from "../baseApiClient";

class MatchupApiClient {
  getLeadById = async (id: string) => {
    return baseApiClient.get<Lead>(`lead/${id}`);
  };

  updateLead = async (leadId: string, lead: Lead) => {
    return baseApiClient.put<Lead>(`lead/${leadId}`, lead);
  };

  updateRecertRequired = async (leadId: string, recertRequired?: boolean) => {
    return baseApiClient.put<Lead>(`lead/${leadId}/recert-required`, {
      recertRequired,
    });
  };

  updateHasFaceSheet = async (leadId: string, hasFaceSheet?: boolean) => {
    return baseApiClient.put<Lead>(`lead/${leadId}/has-facesheet`, {
      hasFaceSheet,
    });
  };

  updateHasARForm = async (leadId: string, hasARForm?: boolean) => {
    return baseApiClient.put<Lead>(`lead/${leadId}/has-arform`, {
      hasARForm,
    });
  };

  convertToApplication = async (
    leadId: string,
    dto: ConvertLeadToApplicationDTO,
    shouldValidateSSNOnOpenApplication = true
  ) => {
    const url = `lead/${leadId}/convert-to-application?shouldValidateSSNOnOpenApplication=${shouldValidateSSNOnOpenApplication}`;
    return baseApiClient.post(url, dto);
  };

  addLeadDocument = async (
    leadId: string,
    leadDocumentCreateDTO: LeadDocumentCreateDTO
  ) => {
    return baseApiClient.post(
      `lead/${leadId}/documents`,
      leadDocumentCreateDTO
    );
  };

  removeLeadDocument = async (leadId: string, documentId: string) => {
    return baseApiClient.delete(`lead/${leadId}/documents/${documentId}`);
  };

  importLeads = async (file: File) => {
    const formData = new FormData();
    formData.append("leadsImportFile", file);
    return baseApiClient.post("lead/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  downloadImportLeadsTemplate = async (): Promise<BlobPart> => {
    return baseApiClient.get("lead/import-template", {
      responseType: "arraybuffer",
    });
  };
}

export const matchupApiClient = new MatchupApiClient();
