import { GridColumn } from "@syncfusion/ej2-react-grids";

export const applicationChangeAuditGridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "updatedBy_AsFullName",
    headerText: "Updated By",
    type: "string",
  }),
  {
    field: "createdAt",
    headerText: "Modified At",
    type: "datetime",
    format: { format: "MM/dd/yyyy hh:mm a", type: "datetime" },
    allowSearching: false,
  } as GridColumn,
  new GridColumn({
    field: "changesMade_Formatted",
    headerText: "Changes Made",
    type: "string",
    width: 350,
  }),
  new GridColumn({
    field: "fieldName_Formatted",
    headerText: "Field Name",
    type: "string",
  }),
  new GridColumn({
    field: "oldFieldValue_Formatted",
    headerText: "Old Value",
    type: "string",
  }),
  new GridColumn({
    field: "newFieldValue_Formatted",
    headerText: "New Value",
    type: "string",
  }),
];
