import { Button, Intent } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { reactPlugin } from "../../../AppInsights";
import "../../../pages/styles.scss";
import { dialogsViewerStore } from "../../Dialogs/stores/DialogsViewerStore";
import { GridFiltersModule } from "../../Grid/Filters/Filters";
import { InvoicedBillingGrid } from "./BillingGridCannedViews/InvoicedBillingGrid";
import { GridPermissions } from "../../Grid/types/GridPermissions";
import { GridViewPicker } from "../../Grid/Views/GridViewPicker";
import { billingGridColumnsConfiguration } from "../configurations/gridColumnsConfiguration";
import "../styles.scss";
import { BillingCannedViewPicker } from "./BillingCannedViewPicker";
import { useState } from "react";
import { NotInvoicedBillingGrid } from "./BillingGridCannedViews/NotInvoicedBillingGrid";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../../lib/apiClients/baseApiClient";
import { gridStore } from "../../Grid/Grid/stores/GridStore";
import { GridFilterSettings } from "../../Grid/Grid/types/GridFilterSettings";
import { autorun } from "mobx";

export interface BillingGridProps extends GridPermissions {
  canGenerateInvoice: boolean;
}

const BillingGridComponent: React.FC<BillingGridProps> = ({
  canConfigureBoard,
  canCreateNewRecord,
  canGenerateInvoice,
}) => {
  const gridKey = "billing-board-grid";
  const [gridCannedView, setGridCannedView] = useState<string>("Not Invoiced");
  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Billing Board",
    billingGridColumnsConfiguration,
    () => billingGridColumnsConfiguration,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>Views</li>
          <li>
            <BillingCannedViewPicker setGridCannedView={setGridCannedView} />
          </li>
          <li>
            {canConfigureBoard && (
              <GridViewPicker
                gridView={gridView.currentGridView}
                baseGridColumnsConfiguration={
                  gridView.baseGridColumnsConfiguration
                }
                updateViewColumnSettings={gridView.updateViewColumnSettings}
              />
            )}
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            <GridFiltersModule />
          </li>
          <li>
            {canCreateNewRecord && (
              <Button
                intent={Intent.PRIMARY}
                text="Create Bill or Credit"
                onClick={() => {
                  dialogsViewerStore.setIsBillingCreateDialogOpen(true);
                }}
              />
            )}
          </li>
          <li>
            {canGenerateInvoice && (
              <Button
                intent={Intent.PRIMARY}
                text="Run Billing"
                onClick={() => {
                  dialogsViewerStore.setIsInvoiceGenerationDialogOpen(true);
                }}
              />
            )}
          </li>
        </ul>
      </section>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && gridCannedView === "Invoiced" && (
                <InvoicedBillingGrid
                  canConfigureBoard
                  canExportGridToExcel
                  canCreateNewRecord
                  canInvokeGridRecord
                  canGenerateInvoice
                />
              )}
              {!!gridView.currentGridView &&
                gridCannedView === "Not Invoiced" && (
                  <NotInvoicedBillingGrid
                    canConfigureBoard
                    canExportGridToExcel
                    canCreateNewRecord
                    canInvokeGridRecord
                    canGenerateInvoice
                  />
                )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};

export const BillingGrid = withAITracking(
  reactPlugin,
  BillingGridComponent,
  "BillingGrid",
  "AI-flex-container"
);
