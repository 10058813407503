import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { userApiClient } from "../../lib/apiClients/user/userApiClient";
import { User } from "../../types/User";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { newFormUser } from "../Forms/UserForm/UserForm";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { BaseForm } from "../Forms/BaseForm/BaseForm";
import { UserDetails } from "./UserDetails";
import { handleFacilitiesMultiselectChange } from "./User/utils";
import { parseDateToDateString } from "../../utils/date";
export interface UserUpdateDialogProps extends BaseDialogProps {}

export const UserUpdateDialog: React.FC<UserUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveAndSending, setIsSaveAndSending] = useState<boolean>(false);
  const [user, setUser] = useState<User>(newFormUser as User);
  const [ready, setReady] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});
  const [prevFacilityIds, setPrevFacilityIds] = useState<string[]>([]);

  useEffect(() => {
    if (!!dialogsViewerStore.userUpdateDialogOpenOptions) {
      userApiClient
        .getUserDetail(dialogsViewerStore.userUpdateDialogOpenOptions.userId)
        .then((user) => {
          setUser(user);
          setPrevFacilityIds(user.facilityIds);
        })
        .finally(() => setReady(true));
    }

    return () => {
      setUser(newFormUser as User);
      setReady(false);
      setErrors({});
      setPrevFacilityIds([]);
    };
  }, [dialogsViewerStore.userUpdateDialogOpenOptions]);

  useEffect(() => {
    if (JSON.stringify(prevFacilityIds) !== JSON.stringify(user.facilityIds)) {
      handleFacilitiesMultiselectChange(user, setUser);
      setPrevFacilityIds(user.facilityIds);
    }
  }, [user.facilityIds]);

  const setSavingState = (
    shouldSendInviteEmail: boolean,
    newState: boolean
  ) => {
    if (shouldSendInviteEmail) {
      setIsSaveAndSending(newState);
    } else {
      setIsSaving(newState);
    }
  };

  //Is External = FacilityUser || CorporateUser
  const shouldShowInviteButton = user.isExternal;

  const formId = "update-user-form";

  const saveandSendInviteButtonText = !!user?.userInviteEmailSentDate
    ? "Save & Resend Invite"
    : "Save & Send Invite";

  return (
    <>
      {ready && (
        <Dialog
          portalClassName="mdr-dialog"
          isOpen={isOpen}
          hasBackdrop={false}
          isCloseButtonShown={true}
          title={"Update User"}
          onClose={closeDialog}
        >
          <div className={Classes.DIALOG_BODY}>
            <BaseForm<User>
              formId={formId}
              value={user}
              formValueState={{
                formValue: user,
                setFormValue: setUser,
              }}
              onErrors={(errors) => setErrors(errors)}
              onFormSubmit={async (user) => {
                const clickedButtonId = document.activeElement?.id;
                const shouldSendInviteEmail =
                  clickedButtonId === "user-save-and-send-invite-button";

                setSavingState(shouldSendInviteEmail, true);

                await userApiClient
                  .updateUser(user.id, user, shouldSendInviteEmail)
                  .then(() => {
                    setUser(user);
                    gridStore.refresh();
                    dialogsViewerStore.userUpdateDialogOpenOptions?.onSubmit &&
                      dialogsViewerStore.userUpdateDialogOpenOptions.onSubmit();
                    submitDialog();
                  })
                  .finally(() => {
                    setSavingState(shouldSendInviteEmail, false);
                  });
              }}
              computedStateOption={{
                fields: ["role"],
                getComputedState: userApiClient.getComputedState,
              }}
            >
              <UserDetails
                user={user}
                errors={errors}
                setUserForm={setUser}
                formId={formId}
              />
            </BaseForm>
          </div>
          <div className={`${Classes.DIALOG_FOOTER}__user-update-footer`}>
            {!!user.userInviteEmailSentDate && (
              <div className="user-footer-date">
                {"Invited Date: "}
                {parseDateToDateString(user.userInviteEmailSentDate)}
              </div>
            )}
            {!!user.lastLoginDate && (
              <div className="user-footer-date">
                {"Last Login Date: "}
                {parseDateToDateString(user.lastLoginDate)}
              </div>
            )}
            <Button
              minimal
              intent={Intent.DANGER}
              text="Cancel"
              onClick={closeDialog}
            />
            <FormSubmitButton
              buttonId="user-save-button"
              isSaving={isSaving}
              formId={formId}
              text="Save"
            />
            {!!shouldShowInviteButton && (
              <FormSubmitButton
                buttonId="user-save-and-send-invite-button"
                isSaving={isSaveAndSending}
                formId={formId}
                text={saveandSendInviteButtonText}
              />
            )}
          </div>
        </Dialog>
      )}
    </>
  );
};
