import { action, makeObservable, observable } from "mobx";
import { FailureEventArgs } from "@syncfusion/ej2-react-grids";
import { DataManager } from "@syncfusion/ej2-data";
import GridColumn from "../components/Grid/Grid/types/GridColumn";
import GridODataV4Adaptor from "../components/Grid/Grid/GridODataV4Adaptor";
import { AppToaster } from "../components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { GridActionFailureError } from "../components/Grid/Grid/types/GridActionFailureError";
import { ODataBeforeSendArgs } from "../components/Grid/Grid/types/ODataBeforeSendArgs";

export class ODataStore {
  public accessToken?: string;
  public failureTimeoutCount = 0;
  public getAccessToken?: () => Promise<string | undefined>;

  constructor() {
    makeObservable(this, {
      accessToken: observable,
      getAccessToken: observable,
      failureTimeoutCount: observable,
      setFailureTimeoutCount: action,
      setAccessToken: action,
    });
  }

  protected init = async (
    getAccessToken: () => Promise<string | undefined>
  ) => {
    this.getAccessToken = getAccessToken;
    const token = await this.getAccessToken();
    this.setAccessToken(token);
  };

  public setAccessToken = (accessToken: string | undefined) => {
    this.accessToken = accessToken;
  };

  protected setupODataSource = (
    odataUrl: string,
    columnsConfiguration?: GridColumn[],
    beforeSendFn?: (
      beforeSendArgs: ODataBeforeSendArgs
    ) => [string, string | undefined]
  ): DataManager => {
    const dataManager = new DataManager({
      url: odataUrl,
      adaptor: new GridODataV4Adaptor(
        columnsConfiguration,
        beforeSendFn ? beforeSendFn : this.baseOnBeforeSend
      ),
      crossDomain: true,
    });
    dataManager.dateParse = false;
    return dataManager;
  };

  public setFailureTimeoutCount = (value: number) => {
    this.failureTimeoutCount = value;
  };

  protected baseActionCompleteEventHandler = () => {
    this.setFailureTimeoutCount(0);
  };

  protected baseActionFailureEventHandler = async (
    eventArgs: FailureEventArgs,
    refreshFn: () => void
  ) => {
    // Process Error
    const errorStatus =
      (eventArgs.error as unknown as XMLHttpRequest)?.status ||
      (eventArgs.error as unknown as GridActionFailureError)?.error.status ||
      0;
    if (!!eventArgs.error && [401, 403].includes(errorStatus)) {
      this.setFailureTimeoutCount(this.failureTimeoutCount + 1);

      this.failureTimeoutCount;

      if (this.failureTimeoutCount < 3) {
        if (this.getAccessToken) {
          const token = await this.getAccessToken();
          this.setAccessToken(token);
        }

        refreshFn();
      } else {
        AppToaster.show({
          message: "Authorization Error. Please refresh and try again.",
          intent: Intent.WARNING,
        });
      }
    } else {
      AppToaster.show({
        message: "An error ocurred executing the current action.",
        intent: Intent.DANGER,
      });
    }
  };

  // Base Methods
  protected baseOnBeforeSend = ({
    queryString,
  }: ODataBeforeSendArgs): [string, string | undefined] => {
    return [queryString, this.accessToken];
  };

  protected baseActionCompleteEvent = () => {
    return () => {
      this.baseActionCompleteEventHandler();
    };
  };

  protected baseActionFailureEvent = (refreshFn: () => void) => {
    return async (eventArgs: FailureEventArgs) => {
      // Process Error
      await this.baseActionFailureEventHandler(eventArgs, refreshFn);
    };
  };
}

export const odataStore = new ODataStore();
