import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { dialogStore } from "@ucl/library";
import { FC, useState } from "react";

const dialogIdBase = "convert-duplicate-application-dialog";

interface ConvertDuplicateApplicationProps {
  hasDuplicateLead?: boolean;
  duplicateApplicationFileNumber?: string;
  onConfirm: () => void;
}

const ConvertDuplicateApplicationComponent: FC<
  ConvertDuplicateApplicationProps
> = (props) => {
  const [isSubmitting] = useState(false);

  return (
    <div className="convert-duplicate-application">
      <div className="convert-duplicate-application_content">
        {props.hasDuplicateLead ? (
          <p>
            We&apos;ve found that this Social Security Number is already
            associated with a lead in Maverick.
          </p>
        ) : (
          <p>
            We&apos;ve found that this Social Security Number is already
            associated with an application in Maverick:{" "}
            <strong>File #{props.duplicateApplicationFileNumber}</strong>
          </p>
        )}
        <p>
          Would you like to create{" "}
          {props.hasDuplicateLead ? "an application" : "a child application"}{" "}
          for this existing contact?
        </p>
      </div>
      <div className="convert-duplicate-application_footer">
        <Button
          className="convert-duplicate-application_footer_close"
          text={"Cancel"}
          onClick={closeConvertDuplicateApplicationDialog}
        />
        <Button
          className="convert-duplicate-application_footer_create"
          text={
            props.hasDuplicateLead
              ? "Convert To Application"
              : "Create Child Application"
          }
          intent={Intent.PRIMARY}
          rightIcon={IconNames.ARROW_RIGHT}
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={() => {
            closeConvertDuplicateApplicationDialog();
            props.onConfirm();
          }}
        />
      </div>
    </div>
  );
};

export const openConvertDuplicateApplicationDialog = (
  props: ConvertDuplicateApplicationProps
) =>
  dialogStore.openDialog({
    id: dialogIdBase,
    className: dialogIdBase,
    header: `Duplicate Application Found`,
    allowDragging: false,
    content: () => <ConvertDuplicateApplicationComponent {...props} />,
  });

export const closeConvertDuplicateApplicationDialog = () =>
  dialogStore.closeDialog(dialogIdBase);
