import { Callout, Intent } from "@blueprintjs/core";
import React from "react";
import variables from "../../config/variables";
import {
  fiaRequestApiClient,
  getFiaRequestODataRoutes,
} from "../../lib/apiClients/fiaRequest/fiaRequestApiClient";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { downloadFile } from "../Document/PdfDocuments";
import { PdfDocumentEntityType } from "../Document/PdfDocumentUploader";
import { OrderedDocumentsGrid } from "../OrderedDocumentsGrid/OrderedDocumentsGrid";
import { AppToaster } from "../Toast/Toast";
import { FIARequest, FIARequestUpdateDTO } from "./configurations/types";
import { useState } from "react";
import { get, uniq } from "lodash";

export interface FIARequestDocumentsProps {
  applicationId: string;
  fiaRequest: FIARequestUpdateDTO;
  isDisabled?: boolean;
  canEditFIARequestDocumentList: boolean;
  validateFIARequestRequirements: (
    applicationId: string,
    fiaRequestId: string,
    fiaRequest: FIARequest
  ) => Promise<void>;
  errors?: {
    [key: string]: string[];
  };
  documentErrors?: string[];
  catchPreviewFIARequestErrors: (
    promise: Promise<BlobPart> | undefined,
    setErrors: (data: any) => void
  ) => void;
}

export const FIARequestDocuments: React.FC<FIARequestDocumentsProps> = (
  props
) => {
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});
  return (
    <>
      <OrderedDocumentsGrid
        sectionTitle="Documents"
        onRefresh={async () =>
          await props.validateFIARequestRequirements(
            props.applicationId,
            String(props.fiaRequest.id),
            props.fiaRequest
          )
        }
        canEditFIARequestDocumentList={props.canEditFIARequestDocumentList}
        odataUrl={`${variables.apiBaseUrl}${
          getFiaRequestODataRoutes().fiaRequestDocumentsRoute
        }`}
        isDisabled={props.isDisabled || !props.fiaRequest.canUpdateDocuments}
        hideAddAction={!props.fiaRequest.canUpdateDocuments}
        odataQueryParams={[
          { key: "applicationId", value: props.applicationId },
          { key: "fiaRequestId", value: String(props.fiaRequest.id) },
        ]}
        pdfDocumentEntity={{
          entityId: String(props.fiaRequest.id),
          entityType: PdfDocumentEntityType.FIARequestDocuments,
        }}
        addAction={async (orderedDocumentsCreateDTO) =>
          await fiaRequestApiClient.createFIARequestDocument(
            props.applicationId,
            String(props.fiaRequest.id),
            orderedDocumentsCreateDTO
          )
        }
        addCoverLetter={async (orderedDocumentsCoverLetterCreateDTO) =>
          await fiaRequestApiClient.createFIARequestCoverLetter(
            props.applicationId,
            String(props.fiaRequest.id),
            orderedDocumentsCoverLetterCreateDTO
          )
        }
        deleteAction={async (data) =>
          await fiaRequestApiClient.deleteFIARequestDocument(
            props.applicationId,
            String(props.fiaRequest.id),
            data.discriminatorId
          )
        }
        reorderAction={async (reorderData) =>
          await fiaRequestApiClient.reorderFIARequestDocument(
            props.applicationId,
            String(props.fiaRequest.id),
            reorderData
          )
        }
        sortAction={() =>
          fiaRequestApiClient.sortFIARequestDocuments(
            props.applicationId,
            String(props.fiaRequest.id)
          )
        }
        previewAction={(data) => {
          dialogsViewerStore.openDocumentViewerDialog({
            key: data.discriminatorId,
            getDocument: fiaRequestApiClient.previewFIARequestDocument(
              props.applicationId,
              String(props.fiaRequest.id),
              data.discriminatorId
            ),
          });
          props.catchPreviewFIARequestErrors(
            dialogsViewerStore.documentViewerDialogOptions?.getDocument,
            setErrors
          );
        }}
        downloadAction={async (data) => {
          try {
            const blob = await fiaRequestApiClient.previewFIARequestDocument(
              props.applicationId,
              String(props.fiaRequest.id),
              data.discriminatorId
            );
            downloadFile(blob, `${data.documentName.replace(".pdf", "")}.pdf`);
          } catch (error: any) {
            if (error?.response?.status == 400) {
              setErrors(error.response.data.additionalInfo);
            }
            AppToaster.show({
              message: (
                <div>
                  Something went wrong while processing the file. <br />
                  Please upload again or contact support.
                </div>
              ),
              intent: Intent.DANGER,
            });
          }
        }}
      />
      {props.errors && (
        <>
          {get(props.errors, "coverLetter")?.map(
            (errorMessage: string, idx: number) => (
              <Callout key={idx} className="error-message">
                {errorMessage}
              </Callout>
            )
          )}
          {uniq([
            ...((props.errors && get(props.errors, "hasRequestDocuments")) ||
              []),
            ...(props.documentErrors || []),
          ])?.map((errorMessage: string, idx: number) => (
            <Callout key={idx} className="error-message">
              {errorMessage}
            </Callout>
          ))}
        </>
      )}
      {errors && (
        <>
          {get(errors, "coverLetter")?.map(
            (errorMessage: string, idx: number) => (
              <Callout key={idx} className="error-message">
                {errorMessage}
              </Callout>
            )
          )}
        </>
      )}
    </>
  );
};
