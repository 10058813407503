import * as msal from "@azure/msal-browser";
import { Configuration } from "@azure/msal-browser";
import _envVar from "../../config/variables";
import { userStore } from "../../stores/UserStore";

export const b2cPolicies = {
  authorities: {
    signIn: {
      authority: `https://${_envVar.domain}/${_envVar.tenantId}/${_envVar.logInUserFlow}`,
    },
    editProfile: {
      authority: `https://${_envVar.domain}/${_envVar.tenantId}/${_envVar.editProfileUserFlow}`,
    },
  },
  authorityDomain: [_envVar.domain],
};

export const msalConfig: Configuration = {
  auth: {
    clientId: _envVar.clientId,
    authority: b2cPolicies.authorities.signIn.authority,
    redirectUri: _envVar.redirectUri,
    knownAuthorities: b2cPolicies.authorityDomain,
    postLogoutRedirectUri: _envVar.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
    allowRedirectInIframe: true,
  },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);

msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    if (tokenResponse !== null && tokenResponse?.account !== null) {
      msalInstance.setActiveAccount(tokenResponse.account);
      userStore.signIn();
    }
  })
  .catch((error) => {
    console.error(error);
  });

export const loginRequest = {
  scopes: [_envVar.scope, "openid", "offline_access", "profile"],
};

export const logout = () => {
  msalInstance.logoutRedirect({
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
  });
};

export const editProfile = () => {
  msalInstance.loginRedirect({
    scopes: loginRequest.scopes,
    authority: b2cPolicies.authorities.editProfile.authority,
  });
};
