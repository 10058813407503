import { Icon } from "@blueprintjs/core";
import { FC } from "react";
import "./styles.scss";
import RelativitySubCard from "../Card/RelativitySubCard";
import { FIARequest, FIARequestInfo } from "./configurations/types";
import { WithTooltip } from "../Tooltip/Tooltip";
import { IconNames } from "@blueprintjs/icons";
import { ReadOnlyField } from "@ucl/library";

export interface OwnedEntityFIARequestSubCardProps {
  fiaRequest: FIARequest;
}

/**FIA Request Details to be viewed on OwnedEntity Cards (Asset/Income/MedicalCoverage).
 * This is a Relativity SubCard component so developers should be sure it is a child of a RelavityCard component.
 */
export const OwnedEntityFIARequestSubCard: FC<
  OwnedEntityFIARequestSubCardProps
> = ({ fiaRequest }) => {
  return (
    <RelativitySubCard
      className="owned-entity-fia-request-sub-card"
      headerContent={
        <WithTooltip
          shouldShowTooltip={true}
          content="Details shown for the most recent created FIA Request."
        >
          <div className="fia-details-tooltip">
            {"FIA Request Details"}
            <Icon icon={IconNames.InfoSign} />
          </div>
        </WithTooltip>
      }
      columns={6}
    >
      <ReadOnlyField
        label="Request Number"
        value={(fiaRequest as FIARequestInfo).requestNumber_AsString}
      />
      <ReadOnlyField
        label="Submitted Date"
        value={(fiaRequest as FIARequestInfo).submittedDate}
      />
      <ReadOnlyField
        label="Est. Return Date"
        value={(fiaRequest as FIARequestInfo).estimatedReturnDate}
      />
      <ReadOnlyField
        label="Received Date"
        value={(fiaRequest as FIARequestInfo).receivedDate}
      />
      <ReadOnlyField
        label="Canceled Date"
        value={(fiaRequest as FIARequestInfo).canceledDate}
      />
      <ReadOnlyField
        label="Request Status"
        value={(fiaRequest as FIARequestInfo).requestStatus_AsString}
      />
    </RelativitySubCard>
  );
};
