import React, { useEffect } from "react";
import { ImageEditorComponent } from "@syncfusion/ej2-react-image-editor";
import "./documentPageEditorStyles.scss";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { Loading } from "../Loading/Loading";

export interface CroppedChangedEventArgs {
  isCropped: boolean;
  base64CropImage?: string;
}

export interface DocumentPageEditorProps {
  src: string;
  onCroppedChanged?: (args: CroppedChangedEventArgs) => void;
}

interface ImageSettings {
  width: number;
  height: number;
}

export const DocumentPageEditor: React.FC<DocumentPageEditorProps> = (
  props
) => {
  const [isCropping, setIsCropping] = React.useState<boolean>(false);
  const [isCropped, setIsCropped] = React.useState<boolean>(false);
  const [imageSettings, setImageSettings] = React.useState<ImageSettings>();
  const imageEditorRef = React.useRef<ImageEditorComponent | null>(null);

  // TODO Test

  useEffect(() => {
    if (props.src) {
      getImageDimensions(props.src, (width: any, height: any) => {
        setImageSettings({ width, height });
      });
    }
  }, []);

  const getImageDimensions = (
    imageUrl: string,
    callback: (width: any, height: any) => void
  ) => {
    const img = new Image();

    img.onload = function () {
      // Image is loaded, now you can access its width and height
      const width = img.width;
      const height = img.height;

      // Call the callback function with the dimensions
      callback(width, height);
    };

    // Set the image source
    img.src = imageUrl;
  };

  const createdHandler = () => {
    if (imageEditorRef.current && props.src) {
      imageEditorRef.current.open(props.src);
    }
  };

  useEffect(() => {
    if (isCropping) {
      cropMode();
    }
  }, [isCropping]);

  useEffect(() => {
    let base64CropImage: string | undefined;
    if (isCropped) {
      try {
        const imgData = imageEditorRef.current?.getImageData();

        if (imgData) {
          base64CropImage = getCropData(imgData);
        }
      } catch (err) {
        console.warn(err);
        imageEditorRef.current?.reset();
        return;
      }
    }

    props.onCroppedChanged?.({ isCropped, base64CropImage });
  }, [isCropped]);

  const resetStates = () => {
    setIsCropping(false);
  };

  const getCropData = (imgData: ImageData): string => {
    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = imgData.width;
    tempCanvas.height = imgData.height;

    const tempCtx = tempCanvas.getContext("2d")!;
    tempCtx.putImageData(imgData, 0, 0);

    // Convert to base64
    const base64Data = tempCanvas.toDataURL("image/png");
    return base64Data;
  };

  const cropMode = () => {
    if (imageSettings) {
      setIsCropped(false);
      setIsCropping(true);

      const boxWidth = Math.max(100, imageSettings.width * 0.5);
      const boxHeight = Math.max(100, imageSettings.height * 0.5);
      imageEditorRef.current?.select(
        "Custom",
        imageSettings.width - boxWidth * 0.5,
        imageSettings.height * 0.5 - boxHeight * 0.5,
        boxWidth,
        boxHeight
      );
      imageEditorRef.current?.rotate(0);
    }
  };

  const defaultSettings = {
    toolbar: ["ZoomIn", "ZoomOut", "Reset"],
    zoomSettings: {
      minZoomFactor: 1,
      maxZoomFactor: 10,
      zoomFactor: 1,
    },
    showQuickAccessToolbar: false,
    toolbarItemClicked: (args: ClickEventArgs) => {
      if (args.item.tooltipText === "Reset") {
        resetStates();
      } else if (args.item.tooltipText === "Cancel") {
        imageEditorRef.current?.reset();
      }
    },
    cropping: () => {
      resetStates();
      setIsCropped(true);
    },
    fileOpened: () => {
      cropMode();
    },
  };

  return (
    <section className="document-page-editor-container">
      <div className="document-page-editor">
        {!imageSettings && <Loading />}
        {imageSettings && (
          <ImageEditorComponent
            ref={imageEditorRef}
            created={createdHandler}
            width={`${imageSettings?.width}px`}
            height={`${imageSettings?.height}px`}
            {...defaultSettings}
          />
        )}
      </div>
    </section>
  );
};
