import { FC } from "react";
import "./styles.scss";
import RelativitySubCard from "../Card/RelativitySubCard";
import {
  FIARequest,
  FIARequestDetails,
  FIARequestInfo,
} from "./configurations/types";
import { ReadOnlyField } from "@ucl/library";

export interface VerifyFIARequestSubCardProps {
  fiaRequest: FIARequest;
}

/**FIA Request Details to be viewed on OwnedEntity Cards (Asset/Income/MedicalCoverage).
 * This is a Relativity SubCard component so developers should be sure it is a child of a RelavityCard component.
 */
export const VerifyFIARequestSubCard: FC<VerifyFIARequestSubCardProps> = (
  props
) => {
  return (
    <RelativitySubCard
      className="owned-entity-fia-request-sub-card"
      columns={5}
    >
      <ReadOnlyField
        label="Request Number"
        value={(props.fiaRequest as FIARequestInfo).requestNumber_AsString}
      />
      <ReadOnlyField
        label="Submitted Date"
        value={(props.fiaRequest as FIARequestInfo).submittedDate}
      />
      <ReadOnlyField
        label="Est. Return Date"
        value={(props.fiaRequest as FIARequestInfo).estimatedReturnDate}
      />
      <ReadOnlyField
        label="Request Status"
        value={(props.fiaRequest as FIARequestInfo).requestStatus_AsString}
      />
      <ReadOnlyField
        label="Send Method"
        value={
          (props.fiaRequest as FIARequestDetails)
            .institutionContactMethod_AsString
        }
      />
    </RelativitySubCard>
  );
};
