import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ProgressBarComponent } from "@syncfusion/ej2-react-progressbar";

export interface EligibilitySummaryProgressLabelProps {
  progress: number;
  completedItems: number;
  totalItems: number;
  //Unique componentId is necessary for multiple progress bars rendered on the same page/with dialogs
  componentId: string;
}
export const EligibilitySummaryProgressLabel: React.FC<
  EligibilitySummaryProgressLabelProps
> = ({ progress, completedItems, totalItems, componentId }) => {
  return (
    <div className="eligibility-summary-label__progress">
      {progress === Number(100) && (
        <Icon
          className="progress-circle__complete"
          size={16}
          icon={IconNames.TickCircle}
          color="#4DD45A"
        />
      )}
      {progress < Number(100) && (
        <ProgressBarComponent
          id={componentId}
          className="progress-circle"
          progressColor="#4DD45A"
          type="Circular"
          value={progress}
          height="36px"
          width="36px"
        />
      )}
      <span
        className={
          progress < Number(100)
            ? "progress-content incomplete"
            : "progress-content complete"
        }
      >
        {completedItems}/{totalItems}
      </span>
    </div>
  );
};
