import {
  Button,
  Classes,
  Dialog,
  Intent,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { institutionFormSchema } from "../Forms/InstitutionForm/InstitutionForm";
import { InstitutionContactsCard } from "../Forms/InstitutionForm/InstitutionContactsCard";
import { institutionStore } from "../../stores/InstitutionStore";
import { OrderedDocumentsGrid } from "../OrderedDocumentsGrid/OrderedDocumentsGrid";
import variables from "../../config/variables";
import { PdfDocumentEntityType } from "../Document/PdfDocumentUploader";
import { userStore } from "../../stores/UserStore";
import { ExistingInstitution } from "../../types/Institution";

export interface InstitutionUpdateDialogProps extends BaseDialogProps {}

export const InstitutionUpdateDialog: React.FC<
  InstitutionUpdateDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState<TabId>("default");
  const [localInstitution, setLocalInstitution] =
    useState<ExistingInstitution>();
  const shouldShowFooterActions = selectedTabId !== "institution-contacts";

  useEffect(() => {
    (async () => {
      // fetch
      if (!!dialogsViewerStore.institutionUpdateDialogOpenOptions) {
        await institutionStore.getInstitutionById(
          dialogsViewerStore.institutionUpdateDialogOpenOptions.institutionId
        );

        if (institutionStore.institution) {
          setLocalInstitution(institutionStore.institution);
          setIsReady(true);
        }
      }
    })();
  }, [dialogsViewerStore.institutionUpdateDialogOpenOptions]);

  useEffect(() => {
    return () => {
      institutionStore.unsetInstitution();
      setSelectedTabId("default");
      setIsReady(false);
    };
  }, [dialogsViewerStore.institutionUpdateDialogOpenOptions]);

  return (
    <>
      {isReady && (
        <Dialog
          portalClassName="mdr-dialog"
          isOpen={isOpen}
          hasBackdrop={false}
          isCloseButtonShown={true}
          title={"Update Institution"}
          onClose={closeDialog}
        >
          <div className={Classes.DIALOG_BODY}>
            <Observer>
              {() =>
                institutionStore.institution && (
                  <Tabs
                    selectedTabId={selectedTabId}
                    onChange={(newTabId) => setSelectedTabId(newTabId)}
                    large
                  >
                    <Tab
                      id="default"
                      title="Details"
                      panel={
                        <Form
                          formId="update-institution-form"
                          value={localInstitution}
                          formSchemaBuilder={institutionFormSchema}
                          onFormSubmit={async (institution) => {
                            setIsSaving(true);

                            await institutionStore
                              .updateInstitution(institution)
                              .then(() => {
                                gridStore.refresh();
                                submitDialog();
                              })
                              .finally(() => {
                                setIsSaving(false);
                              });
                          }}
                        />
                      }
                    />
                    <Tab
                      id="institution-documents-needed"
                      title="Documents Needed"
                      panel={
                        <OrderedDocumentsGrid
                          canEditFIARequestDocumentList={
                            !!userStore.user?.canEditFIARequestDocumentList
                          }
                          sectionTitle="Documents"
                          odataUrl={`${variables.apiBaseUrl}odata/institutiondocumentsneededodata`}
                          odataQueryParams={[
                            {
                              key: "institutionId",
                              value: institutionStore.institution.id,
                            },
                          ]}
                          pdfDocumentEntity={{
                            entityId: institutionStore.institution.id,
                            entityType:
                              PdfDocumentEntityType.InstitutionDocumentsNeeded,
                          }}
                          addAction={async (orderedDocumentsCreateDTO) =>
                            await institutionStore.createInstitutionDocumentNeeded(
                              orderedDocumentsCreateDTO
                            )
                          }
                          deleteAction={async (data) => {
                            await institutionStore.deleteInstitutionDocumentNeeded(
                              data.discriminatorId
                            );
                          }}
                          reorderAction={async (reorderData) =>
                            await institutionStore.reorderInstitutionDocumentNeeded(
                              reorderData
                            )
                          }
                        />
                      }
                    ></Tab>
                    <Tab
                      id="institution-contacts"
                      title="Contacts"
                      panel={
                        <InstitutionContactsCard
                          institution={institutionStore.institution}
                          createContact={async (contact) => {
                            await institutionStore.createInstitutionContact(
                              contact
                            );
                          }}
                          updateContact={async (contact) => {
                            await institutionStore.updateInstitutionContact(
                              contact
                            );
                          }}
                          deleteContact={async (contact) => {
                            await institutionStore.deleteInstitutionContact(
                              contact.id
                            );
                          }}
                        />
                      }
                    />
                  </Tabs>
                )
              }
            </Observer>
          </div>
          {shouldShowFooterActions && (
            <div className={Classes.DIALOG_FOOTER}>
              <Button
                minimal
                intent={Intent.DANGER}
                text="Cancel"
                onClick={closeDialog}
              />
              <FormSubmitButton
                buttonId="institution-save-button"
                isSaving={isSaving}
                formId="update-institution-form"
                text="Save"
              />
            </div>
          )}
        </Dialog>
      )}
    </>
  );
};
