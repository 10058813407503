import React from "react";
import { RadioField } from "../Fields/RadioField/RadioField";
import { useState } from "react";
import "./styles.scss";

export interface CoverLetterPickerProps {
  onDocumentPicked: (coverLetterType?: string) => void;
}
export const CoverLetterPicker: React.FC<CoverLetterPickerProps> = (props) => {
  const [coverLetterType] = useState<string>("");
  return (
    <div className="cover-letter-picker">
      <div className="cover-letter-picker-input-container">
        <RadioField
          width="50%"
          type="Radio"
          value={coverLetterType}
          onSubmit={(value) => {
            props.onDocumentPicked(String(value));
          }}
          optionValues={[
            { label: "Email", value: "4" },
            { label: "Fax", value: "6" },
            { label: "Mail", value: "5" },
          ]}
        />
      </div>
    </div>
  );
};
