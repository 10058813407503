import React, { useRef } from "react";
import { Observer } from "mobx-react";
import classNames from "classnames";
import { DocumentPagesViewer } from "../Document/DocumentPagesViewer";
import { Button, Dialog, Intent, NonIdealState } from "@blueprintjs/core";
import { Loading } from "../Loading/Loading";
import "./checklistSplitStyles.scss";
import { useChecklistSplit } from "./useChecklistSplit";
import {
  DocumentActionTypes,
  SupportedDocumentPageActionTypes,
} from "../Document/useDocumentPagesViewer";
import {
  Inject,
  ListViewComponent,
  SelectEventArgs,
  Virtualization,
} from "@syncfusion/ej2-react-lists";
import {
  DateRangePickerComponent,
  RangeEventArgs,
} from "@syncfusion/ej2-react-calendars";
import { debounce, get } from "lodash";
import { DIALOG_FOOTER } from "@blueprintjs/core/lib/esm/common/classes";
import { FilenameField } from "../Fields/FilenameField/FilenameField";
import { AddButton } from "../Buttons/AddButton";
import { dialogsViewerStore } from "../Dialogs/stores/DialogsViewerStore";
import { Document } from "../../types/Document";
import { ChecklistItem } from "../../types/Checklist";
import { shouldVerifyChecklistDocument } from "./Verify/useVerifyComponent";
import { SelectableLabel } from "../Fields/SelectableLabel/SelectableLabel";
import moment from "moment";
import { featureToggleStore } from "../../lib/featureToggles/FeatureToggleStore";

export interface ChecklistSplitProps {
  applicationId: string;
  checklistItemId: string;
  setIsSplitted: React.Dispatch<React.SetStateAction<boolean>>;

  onClose: () => void;
}

export interface ChecklistItemDocumentsReadDTO {
  id: string;
  splittedDocumentPages: ChecklistItemDocumentPageDTO[];
  documents: Document[];
}

export interface ChecklistItemDocumentPageDTO extends DocumentPageCore {
  order: number;
}

export interface DocumentPageCore {
  documentId: string;
  pageIdx: number;
}

export const ChecklistSplit: React.FC<ChecklistSplitProps> = (props) => {
  const dateRangePickerComponentRef = useRef<DateRangePickerComponent>(null);
  const checklistItemsListViewRef = useRef<ListViewComponent>(null);

  React.useState<boolean>(false);
  const [isSplitting, setisSplitting] = React.useState<boolean>(false);
  const [isPreviewMode, setIsPreviewMode] = React.useState<boolean>(false);
  const [isPageEditorMode, setIsPageEditorMode] =
    React.useState<boolean>(false);
  const [isRotateMode, setIsRotateMode] = React.useState<boolean>(false);
  const [isActionExecuting, setIsActionExecuting] =
    React.useState<boolean>(false);

  const [isDocumentNameDialogOpen, setIsDocumentNameDialogOpen] =
    React.useState<boolean>(false);

  const [destinyUserInputDocumentName, setDestinyUserInputDocumentName] =
    React.useState<string>("");
  const [
    destinyUserInputStatementsReceived,
    setDestinyUserInputStatementsReceived,
  ] = React.useState<string>("");

  const destinyComputedDocumentName = destinyUserInputDocumentName
    ? `${destinyUserInputDocumentName} ${
        destinyUserInputStatementsReceived
          ? `- ${destinyUserInputStatementsReceived}`
          : ""
      }`
    : "";
  const {
    ready,
    isLoadingDocuments,
    documents,
    loadedDocumentThumbnailsData,
    destinyChecklistItem,
    setDestinyChecklistItem,
    selectedDocumentThumbnails,
    setSelectedDocumentThumbnails,
    selectionChangedHandler,
    split,
    checklistItems,
    isLoadingChecklistItems,
    checklistItemsTemplate,
    documentPagesViewermModule,
    formatFilename,
    reset,
    fetchChecklistItems,
    documentOperations,
    setDocumentOperations,
  } = useChecklistSplit({
    applicationId: props.applicationId,
    checklistItemId: props.checklistItemId,
  });

  React.useEffect(() => {
    const actionExecuting =
      isLoadingDocuments || isLoadingChecklistItems || isSplitting;

    setIsActionExecuting(actionExecuting);
  }, [isLoadingDocuments, isLoadingChecklistItems, isSplitting]);

  React.useEffect(() => {
    if (destinyChecklistItem?.displayName) {
      openDestinyDocumentNameDialog();
    } else {
      closeDestinyDocumentNameDialog();
    }
  }, [destinyChecklistItem]);

  const openDestinyDocumentNameDialog = () => {
    assignDestinyUserInputDocumentName(destinyChecklistItem.displayName);
    setDestinyUserInputStatementsReceived("");

    if (dateRangePickerComponentRef.current) {
      dateRangePickerComponentRef.current.value = [];
    }

    setIsDocumentNameDialogOpen(true);
  };

  const closeDestinyDocumentNameDialog = () => {
    // Remove selection. I know...
    if (checklistItemsListViewRef.current) {
      const element = document.querySelector(
        ".checklist-split-destinty-items-content .e-list-item.e-active"
      );
      if (element) {
        element.classList.remove("e-active");
      }
    }

    assignDestinyUserInputDocumentName("");
    setIsDocumentNameDialogOpen(false);
  };

  const assignDestinyUserInputDocumentName = debounce((value) => {
    setDestinyUserInputDocumentName(formatFilename(value));
  }, 100);

  const splitAndContinue = async () => {
    setisSplitting(true);

    const result = await split(destinyComputedDocumentName);

    if (result) {
      props.setIsSplitted(true);

      // Update Classname
      selectedDocumentThumbnails.forEach((y) => {
        y.classNames = ["checklist-split-splitted"];
        y.selectionOrder = undefined;
      });

      // Pass them to viewer
      setSelectedDocumentThumbnails([...selectedDocumentThumbnails]);
      reset();
    }

    setisSplitting(false);
    return result;
  };

  const cancelHandler = () => {
    reset();
    props.onClose();
  };

  const refreshChecklistItemsList = () => {
    fetchChecklistItems(props.applicationId);
    checklistItemsListViewRef.current?.refresh();
  };

  const openVerifyDialog = (
    destinyChecklistItem: ChecklistItem,
    newDocumentId: string
  ) => {
    newDocumentId &&
      destinyChecklistItem.id &&
      dialogsViewerStore.setIsVerifyDialogOpen(true, {
        checklistItemId: destinyChecklistItem.id,
        documentId: newDocumentId,
        onSave: refreshChecklistItemsList,
      });
  };
  return (
    <section className="checklist-split-container">
      <Observer>
        {() => (
          <section className={classNames("checklist-split")}>
            {!ready && <Loading />}
            {ready && (
              <>
                <div>
                  <Dialog
                    isOpen={isDocumentNameDialogOpen}
                    hasBackdrop={true}
                    canOutsideClickClose={false}
                    canEscapeKeyClose={false}
                    isCloseButtonShown={true}
                    title={"Document Name"}
                    portalClassName="mdr-dialog checklist-split-document-name-dialog"
                    onClose={closeDestinyDocumentNameDialog}
                  >
                    <div className="checklist-split-document-name-dialog-container">
                      <div className="checklist-split-document-name-dialog-form">
                        <div className="checklist-split-document-name-dialog-form-name">
                          <FilenameField
                            label={"Document Name"}
                            isRequired={true}
                            readOnly={false}
                            disabled={false}
                            maxLength={250}
                            type={"Filename"}
                            value={destinyUserInputDocumentName}
                            onSubmit={function (value: unknown): void {
                              assignDestinyUserInputDocumentName(
                                value as string
                              );
                            }}
                          />
                        </div>
                        <div className="checklist-split-document-name-dialog-form-destiny-statements-received">
                          <SelectableLabel name={"Date"} />
                          <DateRangePickerComponent
                            ref={dateRangePickerComponentRef}
                            id="daterangepicker"
                            openOnFocus={true}
                            change={(args: RangeEventArgs) => {
                              if (!args.value) {
                                setDestinyUserInputStatementsReceived("");
                                return;
                              }

                              const startDate = moment(args.startDate).format(
                                "MM.DD.YYYY"
                              );
                              const endDate = moment(args.endDate).format(
                                "MM.DD.YYYY"
                              );
                              if (startDate === endDate) {
                                setDestinyUserInputStatementsReceived(
                                  startDate
                                );
                              } else {
                                setDestinyUserInputStatementsReceived(
                                  `${startDate} - ${endDate}`
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="checklist-split-document-name-dialog-form-destiny-name">
                          {destinyComputedDocumentName}
                        </div>
                        <div
                          className={classNames(
                            DIALOG_FOOTER,
                            "checklist-split-document-name-dialog-form-footer"
                          )}
                        >
                          <Button
                            minimal
                            intent={Intent.DANGER}
                            text="Cancel"
                            disabled={isActionExecuting}
                            onClick={() => closeDestinyDocumentNameDialog()}
                          />
                          <Button
                            intent={Intent.SUCCESS}
                            disabled={
                              isActionExecuting || !destinyUserInputDocumentName
                            }
                            loading={isSplitting}
                            text="Save"
                            onClick={async () => {
                              const result = await splitAndContinue();
                              if (result?.isSuccess) {
                                const checklistItem =
                                  destinyChecklistItem as ChecklistItem;

                                featureToggleStore.featureToggles
                                  ?.EnableVerify &&
                                  shouldVerifyChecklistDocument(
                                    checklistItem
                                  ) &&
                                  openVerifyDialog(
                                    checklistItem,
                                    result.newDocumentId
                                  );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Dialog>
                </div>
                <div
                  className={classNames("checklist-split-content", {
                    "checklist-split-content-is-preview-mode": isPreviewMode,
                    "checklist-split-content-is-page-editor-mode":
                      isPageEditorMode,
                    "checklist-split-content-is-rotate-mode": isRotateMode,
                  })}
                >
                  <div className="checklist-split-documents">
                    {isLoadingDocuments && <Loading />}
                    {!isLoadingDocuments &&
                      documents &&
                      documents.length <= 0 && (
                        <NonIdealState title="No Documents Available" />
                      )}
                    {!isLoadingDocuments &&
                      documents &&
                      loadedDocumentThumbnailsData &&
                      documents.length > 0 && (
                        <DocumentPagesViewer
                          ref={documentPagesViewermModule}
                          documents={documents}
                          documentItems={[
                            DocumentActionTypes.SelectAll,
                            DocumentActionTypes.Preview,
                            DocumentActionTypes.OpenInNewTab,
                            DocumentActionTypes.Refresh,
                            DocumentActionTypes.Rotate,
                          ]}
                          documentPageItems={
                            selectedDocumentThumbnails.length <= 0
                              ? [SupportedDocumentPageActionTypes.Editor]
                              : [
                                  SupportedDocumentPageActionTypes.EditorDisabled,
                                ]
                          }
                          disabled={
                            isActionExecuting ||
                            isPreviewMode ||
                            isPageEditorMode ||
                            isRotateMode
                          }
                          onSelectionChanged={selectionChangedHandler}
                          documentThumbnailsProperties={loadedDocumentThumbnailsData.concat(
                            selectedDocumentThumbnails
                          )}
                          // Prevent animation on each re-render. No needed for split.
                          isAnimated={false}
                          expandAll={true}
                          onPreviewModeChanged={(isPreview: boolean) => {
                            setIsPreviewMode(isPreview);
                          }}
                          onPageEditorMode={(isEditorMode: boolean) => {
                            setIsPageEditorMode(isEditorMode);
                          }}
                          onRotateMode={(isRotate: boolean) => {
                            setIsRotateMode(isRotate);
                          }}
                          onDocumentOperationsChanged={(documentOperations) =>
                            setDocumentOperations([...documentOperations])
                          }
                        />
                      )}
                  </div>
                  <div className="checklist-split-destinty-items">
                    <div className="checklist-split-destinty-items-header">
                      Add to...
                      <AddButton
                        text="New Checklist Item"
                        disabled={isLoadingChecklistItems || isRotateMode}
                        onClick={() => {
                          dialogsViewerStore.setIsChecklistSplitAddOwnedEntityDialogOpen(
                            true,
                            {
                              onSave: refreshChecklistItemsList,
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="checklist-split-destinty-items-content">
                      {isLoadingChecklistItems && <Loading />}
                      {!isLoadingChecklistItems &&
                        checklistItems.length <= 0 && (
                          <NonIdealState title="No Checklist Items Available" />
                        )}

                      {!isLoadingChecklistItems && checklistItems.length > 0 && (
                        <ListViewComponent
                          ref={checklistItemsListViewRef}
                          enableVirtualization={true}
                          template={checklistItemsTemplate}
                          dataSource={checklistItems.map((x) => {
                            return {
                              text: x.displayName,
                              id: x.id,
                              description: x.shortDescription || "",
                            };
                          })}
                          enable={
                            !isActionExecuting &&
                            selectedDocumentThumbnails.length > 0 &&
                            !isPreviewMode &&
                            !isRotateMode &&
                            !(
                              isPageEditorMode && documentOperations.length <= 0
                            )
                          }
                          select={(args: SelectEventArgs) => {
                            setDestinyChecklistItem({
                              ...checklistItems.find(
                                (x) => x.id === get(args.data, "id")
                              ),
                            });
                          }}
                        >
                          <Inject services={[Virtualization]} />
                        </ListViewComponent>
                      )}
                    </div>
                  </div>
                </div>
                <div className="checklist-split-footer">
                  <Button
                    minimal
                    intent={Intent.NONE}
                    text="Close"
                    disabled={isActionExecuting}
                    onClick={() => cancelHandler()}
                  />
                </div>
              </>
            )}
          </section>
        )}
      </Observer>
    </section>
  );
};
