import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { billingApiClient } from "../../lib/apiClients/billing/billingApiClient";
import { Discount } from "../../types/Discount";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { discountFormSchema } from "../Forms/BillingForm/DiscountForm";
import { BaseDialogProps } from "./types/types";
import { AppToaster } from "../Toast/Toast";
import { discountGridStore } from "../Billing/store/DiscountGridStore";
import { DeleteButton } from "../Buttons/DeleteButton";

export interface DiscountUpdateDeleteDialogProps extends BaseDialogProps {
  discount?: Discount;
}

export const DiscountUpdateDeleteDialog: React.FC<
  DiscountUpdateDeleteDialogProps
> = ({ discount, isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update/Delete Discount"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Form<Discount>
              formId="update-discount-form"
              value={discount as Discount}
              formSchemaBuilder={discountFormSchema}
              onFormSubmit={async (discount) => {
                setIsSaving(true);
                await billingApiClient
                  .updateDiscount(discount.id, discount)
                  .then(() => {
                    submitDialog();
                    discountGridStore.refresh();
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
            />
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <DeleteButton
          disabled={!!isSaving}
          onClick={async () => {
            if (!!discount) {
              setIsSaving(true);
              await billingApiClient
                .deleteDiscount(discount.id)
                .then(() => {
                  submitDialog();
                  discountGridStore.refresh();
                })
                .finally(() => {
                  setIsSaving(false);
                });
              AppToaster.show({
                message: <div>Discount deleted.</div>,
                intent: Intent.SUCCESS,
              });
            }
          }}
        />
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="save-discount-button"
          isSaving={isSaving}
          formId="update-discount-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
