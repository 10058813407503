import { applicationStore } from "../../stores/ApplicationStore";
import { schemaStore } from "../../stores/SchemaStore";
import { AssetOwnerTypes } from "./Asset";

export const getAssetOwnerOptions = () => {
  let ownerOptions = schemaStore.getFieldSchemaByName(
    "asset",
    "owner"
  ).optionValues;
  if (!applicationStore.application?.isMarried) {
    ownerOptions = ownerOptions?.filter(
      (option) =>
        option.value.toString() === AssetOwnerTypes.Resident.toString()
    );
  }

  return ownerOptions;
};
