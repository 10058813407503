import moment from "moment";

export const differenceInDaysFromToday = (date: Date) => {
  const timeDiff = +date - +getTodaysDateOnly_UTC();
  return Math.floor(timeDiff / (1000 * 3600 * 24));
};

export const getTodaysDateOnly_UTC = () => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  return today;
};
export const formatDateToMMddYYYY = (date: string | Date) => {
  if (date === "") {
    return date;
  }
  return moment(date).format("MM/DD/YYYY");
};
export const parseDateToDateTimeString = (date: string | Date) => {
  return moment.utc(date).tz("America/New_York").format("MM/DD/YYYY hh:mm A");
};

export const parseDateToDateString = (date: string | Date) => {
  return moment.utc(date).tz("America/New_York").format("MM/DD/YYYY");
};

export const getUTCDate = (date: string | Date) => {
  const utcMonth = moment.utc(date).month();
  const utcDate = moment.utc(date).date();
  const utcYear = moment.utc(date).year();
  return new Date(utcYear, utcMonth, utcDate);
};

export const differenceInMonths = (date: string | Date) => {
  const now = moment();

  return moment(date).diff(now, "M");
};

export const differenceInYears = (date: string | Date) => {
  const now = moment();

  return Math.abs(moment(date).diff(now, "y"));
};

export const dateBuilderAddYearsToTodaysDate = (yearsToCalculate: number) => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return new Date(year + yearsToCalculate, month, day);
};

export const dateBuilderAddMonthsToTodaysDate = (monthsToAdd: number) => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const addedMonths = month + monthsToAdd;
  const day = d.getDate();
  return new Date(year, addedMonths, day);
};

export const isValidDate = (date: string | null) => {
  if (!date) {
    return true;
  }

  const dateValue = new Date(date);

  const year = dateValue.getFullYear();
  const month = dateValue.getMonth() + 1;
  const day = dateValue.getDate();

  const isYearValid = year >= 1899;
  const isMonthValid = month >= 1 && month <= 12;
  const isDayValid = day >= 1 && day <= 31;

  return isYearValid && isMonthValid && isDayValid;
};
