import {
  Column,
  PredicateModel,
  SortDescriptorModel,
} from "@syncfusion/ej2-react-grids";
import moment from "moment";
import { SortDirection } from "@syncfusion/ej2-react-grids";

export interface SortDetails {
  field: string;
  details: string;
  direction: SortDirection | undefined;
}

export interface FilterDetails {
  field: string;
  details: string;
}

export class GridFiltersModuleStore {
  private filterPredicateText = {
    and: "AND",
    or: "OR",
  };

  private filterOperatorText = {
    startswith: "Starts with",
    endswith: "Ends with",
    contains: "Contains",
    equal: "Equal to",
    notequal: "Not Equal to",
    greaterthan: "Greater than",
    greaterthanorequal: "Greater than or Equal To",
    lessthan: "Less than",
    lessthanorequal: "Less than or Equal To",
  };

  private valueOrEmpty = (value: string): string => {
    return ["", "null", "undefined"].includes(value) ? "empty" : value;
  };

  // Sort
  public getSortDetails = (
    columns?: Column[],
    sorts?: SortDescriptorModel[]
  ): SortDetails[] | undefined => {
    const sortDetails = sorts?.map((x) => ({
      field: String(x.field),
      details: this.getSortText(columns, x),
      direction: x.direction,
    })) as SortDetails[] | undefined;

    return sortDetails;
  };

  public getSortText = (
    columns?: Column[],
    sort?: SortDescriptorModel
  ): string => {
    const col = columns?.find((x) => x.field === sort?.field);

    return `Order: ${col?.headerText}`;
  };

  // Filter
  public getFilterDetails = (
    filters?: PredicateModel[],
    columns?: Column[]
  ): FilterDetails[] | undefined => {
    // Get distinct fields
    let distinctFields = filters
      ?.map((item) => item.field)
      .filter((value, index, self) => self.indexOf(value) === index);

    // Filter by just those on the columns  displayed
    if (columns) {
      distinctFields = distinctFields?.filter((field) =>
        columns.some((col) => col.field === field)
      );
    }
    // Get Filter Details
    const filterDetails = distinctFields?.map((field) => ({
      field: String(field),
      details: this.getFilterText(
        filters,
        field,
        columns?.find((x) => x.field === field)?.headerText
      ),
    })) as FilterDetails[] | undefined;

    return filterDetails;
  };

  public formatValue = (
    value: string | number | Date | boolean | undefined,
    type: string | undefined
  ): string => {
    if (type === "date") {
      if (!value) {
        return "";
      }

      const dateValue = moment(String(value));
      if (!dateValue.isValid()) {
        return "";
      }

      return dateValue.format("L");
    }

    return String(value);
  };

  public getFilterText = (
    filters?: PredicateModel[],
    field?: string,
    fieldHeaderText?: string
  ): string => {
    // Process Filter (Ex. [Equal to] [100] [OR] [Equal to] [1000])
    let details = "";
    const filterTextList: string[] = [];
    filters
      ?.filter((x) => x.field === field)
      .forEach((col, idx) => {
        const predicateText =
          idx > 0
            ? (this.filterPredicateText[
                col.predicate as keyof typeof this.filterPredicateText
              ] as string)
            : "";
        const operatorText =
          this.filterOperatorText[
            col.operator as keyof typeof this.filterOperatorText
          ];

        const filterText = `${
          fieldHeaderText || ""
        } ${operatorText} ${this.valueOrEmpty(
          this.formatValue(col.value ?? "", col.type)
        )}`;

        // Remove any duplicate
        if (filterTextList.includes(filterText)) {
          return;
        }

        filterTextList.push(filterText);

        details += ` ${predicateText} ${filterText} `.trimStart();
      });

    return details;
  };
}

export const gridFiltersModuleStore = new GridFiltersModuleStore();
