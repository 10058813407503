import { FormGroup, TextArea } from "@blueprintjs/core";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { TextAreaFieldProps } from "../types/fieldTypes";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  description,
  value,
  onSubmit,
  disabled,
  hidden,
  errorMessages,
  isRequired,
  maxLength,
  onInitialized,
  onEnterKeyPress,
  hideRemainingCharacters,
}) => {
  if (hidden) {
    return null;
  }

  const textInputRef = useRef<HTMLTextAreaElement | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  // Initialize
  useEffect(() => {
    if (!!textInputRef.current && !initialized) {
      setInitialized(true);
      onInitialized && onInitialized(textInputRef.current);
    }
  }, []);

  return (
    <FormGroup
      className={`${isRequired && !value ? "has-required-background" : ""}`}
    >
      {label && <SelectableLabel name={label} description={description} />}
      <TextArea
        inputRef={textInputRef}
        className={label.includes("Comments") ? "comments-textarea" : ""}
        name={label}
        defaultValue={value}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        onChange={(e) => {
          onSubmit(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnterKeyPress && onEnterKeyPress();
          }
        }}
      />
      {!!maxLength && value && !hideRemainingCharacters && (
        <div className="base-field__textarea__remaining-characters">
          <SelectableLabel
            name={`${maxLength - value.length} of ${maxLength} characters left`}
          />
        </div>
      )}

      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
