import { Card } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FC } from "react";
import { userStore } from "../../../stores/UserStore";
import { ApplicationChangeAuditGrid } from "../ChangeAuditGrid/CorporateFacilities/ApplicationChangeAuditGrid";

export interface AuditLogCardProps {
  applicationId?: string;
}

export const AuditLogCard: FC<AuditLogCardProps> = ({ applicationId }) => {
  return (
    <Observer>
      {() => (
        <Card className="audit-log-card">
          <ApplicationChangeAuditGrid
            canConfigureBoard={!userStore.user?.isExternal}
            canExportGridToExcel={!userStore.user?.isExternal}
            canCreateNewRecord={!userStore.user?.isExternal}
            canInvokeGridRecord={!userStore.user?.isExternal}
            applicationId={applicationId || ""}
          />
        </Card>
      )}
    </Observer>
  );
};
