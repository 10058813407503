import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import React from "react";
import {
  PdfDocumentEntityType,
  PdfDocumentUploader,
} from "../Document/PdfDocumentUploader";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { LeadDocumentCreateDTO } from "../Matchup/types";

export interface MatchupDocumentUploadDialogProps extends BaseDialogProps {
  submitDialog: (leadDocumentCreateDTOs?: LeadDocumentCreateDTO[]) => void;
}

export const MatchupDocumentUploadDialog: React.FC<
  MatchupDocumentUploadDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [documentsPicked, setDocumentsPicked] = React.useState<
    LeadDocumentCreateDTO[]
  >([]);

  const onDocumentPicked = (leadDocumentCreateDTO?: LeadDocumentCreateDTO) => {
    if (leadDocumentCreateDTO) {
      setDocumentsPicked((prevDocuments) => [
        ...prevDocuments,
        leadDocumentCreateDTO,
      ]);
    }
  };

  return (
    <Dialog
      portalClassName="mdr-dialog matchup-document-upload-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Add Document"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        {dialogsViewerStore.matchupDocumentUploadDialogOpenOptions?.leadId && (
          <PdfDocumentUploader
            pdfDocumentEntity={{
              entityId:
                dialogsViewerStore.matchupDocumentUploadDialogOpenOptions
                  .leadId,
              entityType: PdfDocumentEntityType.Matchup,
            }}
            onCompleteUploading={(doc) => {
              const leadDocumentCreateDTO: LeadDocumentCreateDTO = {
                leadId:
                  dialogsViewerStore.matchupDocumentUploadDialogOpenOptions
                    ?.leadId || "",
                documentId: doc.id,
              };
              onDocumentPicked(leadDocumentCreateDTO);
            }}
            allowMultipleFiles={true}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <Button
          className="bp4-button bp4-intent-success"
          text="Save"
          disabled={false}
          onClick={() => submitDialog(documentsPicked)}
        />
      </div>
    </Dialog>
  );
};
