import { GridColumn } from "@syncfusion/ej2-react-grids";
import GridActionsColumn, {
  GridAction,
} from "../../Grid/Grid/types/GridActionsColumn";
import { ChecklistDocumentDTO } from "../../../types/ChecklistDocumentDTO";
import { useDocument } from "../../Document/useDocument";

export const checklistDocumemtGridColumnsConfiguration = (): GridColumn[] => {
  const { documentActionTypes } = useDocument();
  const checklistActions = documentActionTypes({
    disableEditDoc: false,
    disableRenameDoc: true,
    disablePreviewDoc: false,
    disableOpenNewTabDoc: false,
    disableDownloadDoc: false,
  }) as GridAction<ChecklistDocumentDTO>[];

  return [
    new GridActionsColumn<ChecklistDocumentDTO>({
      headerText: "Actions",
      width: 230,
      clipMode: "Clip",
      customAttributes: { class: "ordered-document-grid-actions" },
      actions: checklistActions,
    }),
    new GridColumn({
      field: "application_FileNumber",
      headerText: "File Number",
      type: "string",
    }),
    new GridColumn({
      field: "id",
      headerText: "id",
      type: "string",
      visible: false,
    }),
    new GridColumn({
      field: "originalFileName",
      headerText: "Document Name",
      type: "string",
    }),
    new GridColumn({
      field: "checklistItemName",
      headerText: "Checklist Item Name",
      type: "string",
    }),
    new GridColumn({
      field: "checklistSortDescription",
      headerText: "Checklist Short Description",
      type: "string",
    }),
    new GridColumn({
      field: "checklistCategory",
      headerText: "Checklist Category",
      type: "string",
    }),
    new GridColumn({
      field: "checklistStatus",
      headerText: "Checklist Status",
      type: "string",
    }),
    new GridColumn({
      field: "uploadedBy",
      headerText: "Uploaded By",
      type: "string",
    }),
    new GridColumn({
      field: "uploadedDate",
      headerText: "Upload Date",
      type: "date",
      format: { format: "MM/dd/yyyy", type: "date" },
    }),
  ];
};
