import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { ChecklistItem } from "../../../types/Checklist";
import { AllFields, SectionHeaderProps } from "../../Fields/types/fieldTypes";

import { FormSchemaBuilder } from "../types";

export const checklistShortDescriptionFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "checklistItem";
  const checklistItem = form as ChecklistItem;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "shortDescription"),
      onSubmit: (shortDescription) => {
        onValueChange({ shortDescription });
      },
      errorMessages: get(errors, "shortDescription"),
      value: checklistItem.shortDescription,
      maxLength: 256,
    } as AllFields,
  ];
};
