import {
  AsyncSettingsModel,
  FilesPropModel,
  RemovingEventArgs,
  UploaderModel,
  UploadingEventArgs,
} from "@syncfusion/ej2-react-inputs";
import { get } from "lodash";
import { NewNotary, notariesFolderName, Notary } from "../../../types/Notary";
import {
  AllFields,
  Option,
  SelectFieldValue,
} from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getFieldRepAsyncAutocompleteFieldProps,
  getStateCountyAsyncAutocompleteFieldProps,
} from "../types";
import { schemaStore } from "../../../stores/SchemaStore";
import { getAccessToken } from "../../../lib/apiClients/baseApiClient";
import {
  Document,
  DocumentEntityTypes,
  NewDocument,
  NotaryDocumentEntityType,
} from "../../../types/Document";
import variables from "../../../config/variables";
import { buildFileDocumentCustomFormData } from "../../../types/FileDocument";
import { dateBuilderAddYearsToTodaysDate } from "../../../utils/date";
import moment from "moment";

export const newFormNotary: NewNotary = {
  fieldRepId: "",
  documentId: "",
  firstName: "",
  lastName: "",
  state: 0,
  countyId: undefined,
  number: "",
  expirationDate: "",
  bondCompany: "",
  sealId: "",
  signature: "",
  seal: null,
};

export const notaryFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
  //formId
): AllFields[] => {
  const modelName = "notary";
  const notary = form as Notary;
  const isNotaryExpired = moment(notary.expirationDate).isBefore(new Date());

  const onUploadingFile = (args: UploadingEventArgs) => {
    // Set Custom Data
    const doc: NewDocument = {
      originalFileName: args.fileData.name,
      folderName: notariesFolderName,
      entityType: DocumentEntityTypes[NotaryDocumentEntityType],
      isChunked: true,
    };

    args.customFormData = buildFileDocumentCustomFormData(doc);
  };

  const onChunkUploadingFile = (
    args: UploadingEventArgs,
    document: Document
  ) => {
    // Set Custom Data
    const doc: Partial<Document> = {
      id: document.id,
      originalFileName: document.fileName,
      folderName: notariesFolderName,
      entityType: DocumentEntityTypes[NotaryDocumentEntityType],
      isChunked: true,
    };

    args.customFormData = buildFileDocumentCustomFormData(doc);
  };

  const onRemovingFile = (args: RemovingEventArgs) => {
    const doc: Partial<Document> = {
      id: notary.sealId,
      folderName: notariesFolderName,
      isChunked: true,
    };

    // Set Custom Data
    args.customFormData = buildFileDocumentCustomFormData(doc);
  };

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "fieldRepId"),
      ...getFieldRepAsyncAutocompleteFieldProps(),
      errorMessages: get(errors, "fieldRepId"),
      isRequired: true,
      groupByActiveStatus: true,
      value: notary.fieldRepId,
      onSubmit: (fieldRepOptions: Option) => {
        onValueChange({
          fieldRepId: fieldRepOptions?.value,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "state"),
      value: notary.state as SelectFieldValue,
      onSubmit: async (option: Option) => {
        onValueChange({
          state: option.value,
          countyId: undefined,
        });
      },
      errorMessages: get(errors, "state"),
      width: "45.9%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "countyId"),
      ...getStateCountyAsyncAutocompleteFieldProps(notary.state),
      disabled: !notary.state,
      fieldKey: notary.state.toString(),
      errorMessages: get(errors, "countyId"),
      width: "45.9%",
      isRequired: true,
      value: notary.countyId,
      onSubmit: (options: Option) => {
        onValueChange({
          countyId: options?.value,
        });
      },
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "number"),
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ number: value });
      },
      errorMessages: get(errors, "number"),
      value: notary.number,
      width: "45.9%",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "expirationDate"),
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ expirationDate: value });
      },
      errorMessages: isNotaryExpired
        ? get(errors, "expirationDate") !== undefined
          ? [
              "Notary has expired",
              ...(get(errors, "expirationDate") as string[]),
            ]
          : ["Notary has expired"]
        : get(errors, "expirationDate"),
      value: notary.expirationDate,
      width: "45.9%",
      isRequired: true,
      maxDate: new Date(dateBuilderAddYearsToTodaysDate(10)),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "bondCompany"),
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ bondCompany: value });
      },
      errorMessages: get(errors, "bondCompany"),
      value: notary.bondCompany,
    } as AllFields,
    {
      errorMessages: get(errors, "sealId"),
      type: "File",
      label: "Notary Seal",
      disabled: false,
      onSubmit: (doc: Document) => {
        onValueChange({ sealId: doc.id });
      },
      value: notary.sealId,
      showPreview: true,
      getAccessToken: getAccessToken,
      onUploading: onUploadingFile,
      onChunkUploading: onChunkUploadingFile,
      onRemoving: onRemovingFile,
      uploaderConfig: {
        multiple: false,
        allowedExtensions: ".png",
        files: notary.seal
          ? [
              {
                name: notary.seal.originalFileName,
                size: notary.seal.totalChunk || 1,
                type: ".png",
              } as FilesPropModel,
            ]
          : undefined,
        asyncSettings: {
          chunkSize: 102400,
          removeUrl: `${variables.apiBaseUrl}api/document/remove`,
          saveUrl: `${variables.apiBaseUrl}api/document/upload`,
        } as AsyncSettingsModel,
      } as UploaderModel,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature"),
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature: value });
      },
      errorMessages: get(errors, "signature"),
      value: notary.signature || "",
      isRequired: true,
    } as AllFields,
  ];
};
