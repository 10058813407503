import { MaskedField } from "../MaskedField/MaskedField";
import { EmailFieldProps } from "../types/fieldTypes";

export const EmailField: React.FC<EmailFieldProps> = ({
  label,
  description,
  value,
  disabled,
  onSubmit,
  hidden,
  type,
  errorMessages,
  formId,
  isRequired,
}) => {
  return (
    <MaskedField
      formId={formId}
      type={type}
      description={description}
      label={label}
      maskFieldProps={{
        placeholder: "johnsmith@gmail.com",
      }}
      fieldName="email"
      value={value}
      disabled={disabled}
      onSubmit={onSubmit}
      hidden={hidden}
      errorMessages={errorMessages}
      isRequired={isRequired}
    />
  );
};
