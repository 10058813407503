import { Checkbox, FormGroup } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { CheckboxBooleanFieldProps } from "../types/fieldTypes";
import "./styles.scss";
import classNames from "classnames";

export const CheckboxBooleanField: React.FC<CheckboxBooleanFieldProps> = ({
  label,
  value,
  disabled,
  hidden,
  errorMessages,
  onSubmit,
  labelOnTop,
  description,
  className,
}) => {
  if (hidden) {
    return null;
  }

  const [localValue, setLocalValue] = React.useState<boolean | undefined>(
    value
  );

  useEffect(() => {
    onSubmit(localValue);
  }, [localValue]);

  return (
    <FormGroup
      className={classNames(
        "checkbox-field checkbox-boolean-field",
        {
          "label-on-top": labelOnTop,
        },
        { "label-regular": !labelOnTop },
        { "label-disabled": disabled },
        className
      )}
    >
      {label && (
        <SelectableLabel
          name={label}
          description={description}
          disabled={disabled}
          onClick={() => setLocalValue((x) => !x)}
        />
      )}
      <Checkbox
        disabled={disabled}
        checked={localValue}
        onChange={() => {
          setLocalValue(!localValue);
        }}
      />
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
