import { makeObservable, observable, runInAction } from "mobx";
import { userApiClient } from "../lib/apiClients/user/userApiClient";
import { User, UserRoles } from "../types/User";
import { errorStore, genericErrorMessage } from "./ErrorStore";

export interface UserFromProvider {
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  companyName: string;
  role?: UserRoles;
}

class UserStore {
  public user?: User;

  constructor() {
    makeObservable(this, {
      user: observable,
    });
  }

  async signIn() {
    try {
      const user = await userApiClient.signIn();
      runInAction(() => {
        this.user = user;
      });
    } catch (e) {
      errorStore.setErrorMessage(genericErrorMessage);
    }
  }

  get displayRole() {
    return !!this.user?.role_AsString ? `(${this.user.role_AsString})` : "";
  }

  get initials() {
    if (!this.user) {
      return "";
    }
    return `${this.user.firstName[0].toUpperCase()}${this.user.lastName[0].toUpperCase()}`;
  }

  get fullUserName() {
    if (!this.user) {
      return "";
    }

    return `${this.user.firstName} ${this.user.lastName}`;
  }
}

export const userStore = new UserStore();
