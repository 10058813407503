import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC } from "react";

export interface UndoButtonProps {
  isSaving?: boolean;
  text?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  hidden?: boolean;
}

export const UndoButton: FC<UndoButtonProps> = ({
  isSaving,
  text = "",
  disabled = false,
  onClick,
  hidden,
}) => {
  if (isSaving) {
    return null;
  }
  return (
    <Button
      type="button"
      disabled={disabled}
      minimal
      intent={Intent.DANGER}
      onClick={onClick}
      rightIcon={IconNames.Undo}
      text={text}
      hidden={hidden}
    />
  );
};
