import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { StateInformation } from "../../../types/Application/StateInformation";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import {
  FormSchemaBuilder,
  getStateCountyAsyncAutocompleteFieldProps,
} from "../types";
import { Application } from "../../../types/Application";

export const newStateInformation: StateInformation = {
  stateProductType: undefined,
  caseNumber: undefined,
  medicaidIdNumber: undefined,
  stateAppNumber: undefined,
  stateAppDate: undefined,
  lastAppliedDate: undefined,
  webUsername: undefined,
  webPassword: undefined,
};

export const stateInformationPageFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const stateInformation = form as StateInformation;
  const application = form as Application;

  const stateProductTypeOptionValues = (facilityState?: string) => {
    let optionValues = schemaStore.getMappingsByName(
      `stateProductType${facilityState}` || ""
    );

    if (optionValues.length <= 0) {
      optionValues =
        schemaStore.getFieldSchemaByName(modelName, "stateProductType")
          .optionValues || [];
    }

    if (optionValues.length === 1) {
      stateInformation.stateProductType = parseInt(optionValues[0].value);
    }
    return optionValues;
  };
  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "stateProductType"),
      value: stateInformation.stateProductType,
      optionValues: stateProductTypeOptionValues(
        application.facility?.facilityAddress.state
      ),
      onSubmit: (stateProductType: Option) => {
        onValueChange({ stateProductType: stateProductType.value });
      },
      width: "30%",
      errorMessages: get(errors, "stateProductType"),
      isRequired:
        application.facility &&
        schemaStore.getMappingsByName(
          `stateProductType${application.facility.facilityAddress.state}` || ""
        ).length > 0
          ? true
          : false,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "caseNumber"),
      value: stateInformation.caseNumber,
      onSubmit: (caseNumber) => {
        onValueChange({ caseNumber });
      },
      width: "30%",
      errorMessages: get(errors, "caseNumber"),
    } as AllFields,

    {
      ...schemaStore.getFieldSchemaByName(modelName, "stateAppNumber"),
      value: stateInformation.stateAppNumber,
      onSubmit: (stateAppNumber) => {
        onValueChange({ stateAppNumber });
      },
      width: "30%",
      errorMessages: get(errors, "stateAppNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "stateAppDate"),
      value: stateInformation.stateAppDate,
      onSubmit: (stateAppDate) => {
        onValueChange({ stateAppDate });
      },
      errorMessages: get(errors, "stateAppDate"),
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "medicaidIdNumber"),
      value: stateInformation.medicaidIdNumber,
      onSubmit: (medicaidIdNumber) => {
        onValueChange({ medicaidIdNumber });
      },
      width: "30%",
      errorMessages: get(errors, "medicaidIdNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "lastAppliedDate"),
      value: stateInformation.lastAppliedDate,
      onSubmit: (lastAppliedDate) => {
        onValueChange({ lastAppliedDate });
      },
      errorMessages: get(errors, "lastAppliedDate"),
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "webUsername"),
      value: stateInformation.webUsername,
      onSubmit: (webUsername) => {
        onValueChange({ webUsername });
      },
      errorMessages: get(errors, "webUsername"),
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "webPassword"),
      value: stateInformation.webPassword,
      onSubmit: (webPassword) => {
        onValueChange({ webPassword });
      },
      isSensitive: true,
      errorMessages: get(errors, "webPassword"),
      width: "30%",
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "applicationHomesteadCountyOfficeId"
      ),
      ...getStateCountyAsyncAutocompleteFieldProps(
        application.facility?.facilityAddress.state
      ),
      disabled: !application.facility?.facilityAddress.state,
      fieldKey: application.facility?.facilityAddress.state.toString(),
      errorMessages: get(errors, "applicationHomesteadCountyOfficeId"),
      width: "30%",
      isRequired: true,
      value: stateInformation.applicationHomesteadCountyOfficeId,
      onSubmit: (options: Option) => {
        onValueChange({
          applicationHomesteadCountyOfficeId: options?.value,
        });
      },
    } as AllFields,
  ];
};
