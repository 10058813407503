import { Observer } from "mobx-react";
import { FC, useState, useEffect } from "react";
import { commentsApiClient } from "../../../../lib/apiClients/comments/commentsApiClient";
import { userStore } from "../../../../stores/UserStore";
import { Comment, CommentFilters } from "../../../../types/Comment";
import { AddButton } from "../../../Buttons/AddButton";
import RelativityCard from "../../../Card/RelativityCard";
import { dialogsViewerStore } from "../../../Dialogs/stores/DialogsViewerStore";
import { SectionHeader } from "../../../Fields/SectionHeader/SectionHeader";
import "../../styles.scss";
import { Loading } from "../../../Loading/Loading";
import { CommentCard } from "../../../Dialogs/CommentsDialog/CommentCard";
import { Intent, Button, NonIdealState } from "@blueprintjs/core";
import { defaultCommentFilters } from "../../../Forms/CommentForm/CommentFilteringForm";
import { SwitchField } from "../../../Fields/SwitchField/SwitchField";
import { IconNames } from "@blueprintjs/icons";
import { CommentsFilters } from "./CommentsFilters";
import { utils } from "@ucl/library";

export interface CommentsCardProps {
  applicationId?: string;
  canCreate: boolean;
  canEditAllComment: boolean;
  canDeleteAllComment: boolean;
  canEditSelfComment: boolean;
  canDeleteSelfComment: boolean;
  canPinComment: boolean;
}

export const CommentsCard: FC<CommentsCardProps> = ({
  applicationId,
  canCreate,
  canEditAllComment,
  canDeleteAllComment,
  canEditSelfComment,
  canDeleteSelfComment,
  canPinComment,
}) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [shouldFetchRelatedComments, setShouldFetchRelatedComments] =
    useState<boolean>(false);
  const [commentFilters, setCommentFilters] = useState<CommentFilters>(
    defaultCommentFilters
  );

  useEffect(() => {
    if (!!applicationId) {
      loadComments(shouldFetchRelatedComments, commentFilters);
    }
    return () => {
      setReady(false);
      setShouldFetchRelatedComments(false);
      setCommentFilters(defaultCommentFilters);
    };
  }, [applicationId]);

  const loadComments = (
    shouldFetchRelatedComments: boolean,
    filters: CommentFilters | undefined
  ) => {
    setReady(false);
    if (!!applicationId) {
      (!shouldFetchRelatedComments
        ? commentsApiClient.getApplicationComments(applicationId, filters)
        : commentsApiClient.getRelatedApplicationComments(
            applicationId,
            filters
          )
      )
        .then((items) => {
          setComments(items);
        })
        .finally(() => setReady(true));
    }
  };

  const resetCommentFilters = () => {
    setShowFilters(false);
    setCommentFilters(defaultCommentFilters);
  };

  const doesComponentHaveFilters = (commentFilters: CommentFilters) => {
    return (
      commentFilters.applicationIds.length > 0 ||
      commentFilters.createdAtRange?.some((date) => !!date) ||
      commentFilters.createdByIds.length > 0 ||
      commentFilters.levels.length > 0 ||
      !!commentFilters.sortBy ||
      commentFilters.sources.length > 0
    );
  };
  return (
    <RelativityCard
      className="comments-card"
      headerContent={
        <div className="owned-entity-header comments-header">
          <SectionHeader label="Comments" type="Section" />
          <div className="content-right">
            <SwitchField
              type="Switch"
              label="Show Related Comments"
              value={shouldFetchRelatedComments}
              onSubmit={() => 0}
              handleClick={() => {
                resetCommentFilters();
                const newValue = !shouldFetchRelatedComments;
                setShouldFetchRelatedComments(newValue);
                loadComments(newValue, defaultCommentFilters);
              }}
            />
            <Button
              className={`filter-button__${utils.miscellaneous.getObjectValueClassName(
                doesComponentHaveFilters(commentFilters).toString()
              )}`}
              intent={Intent.NONE}
              text="Filter & Sort"
              onClick={() => setShowFilters(!showFilters)}
              icon={IconNames.FILTER}
            />
            {canCreate && (
              <AddButton
                text="Add Comment"
                id="add-comment"
                onClick={() => {
                  dialogsViewerStore.setIsCommentCreationDialogOpen(true, {
                    onSubmit: () => {
                      resetCommentFilters();
                      loadComments(
                        shouldFetchRelatedComments,
                        defaultCommentFilters
                      );
                    },
                  });
                }}
              />
            )}
          </div>
        </div>
      }
    >
      <Observer>
        {() => (
          <>
            {showFilters && (
              <CommentsFilters
                comments={comments}
                shouldFetchRelatedComments={shouldFetchRelatedComments}
                commentFilters={commentFilters}
                setCommentFilters={setCommentFilters}
                loadComments={loadComments}
              />
            )}
            {ready &&
              comments?.length > 0 &&
              comments.map((comment, index) => {
                return (
                  <CommentCard
                    key={index}
                    comment={comment}
                    isComponentEnabled={true && !userStore.user?.isExternal}
                    loadComments={() =>
                      loadComments(shouldFetchRelatedComments, commentFilters)
                    }
                    canEditAllComment={canEditAllComment}
                    canDeleteAllComment={canDeleteAllComment}
                    canDeleteSelfComment={canDeleteSelfComment}
                    canEditSelfComment={canEditSelfComment}
                    canPinComment={canPinComment}
                  />
                );
              })}
            {ready && comments?.length <= 0 && (
              <NonIdealState title="No Information Available" />
            )}
            {!ready && <Loading />}
          </>
        )}
      </Observer>
    </RelativityCard>
  );
};
