import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { notaryApiClient } from "../../lib/apiClients/notary/notaryApiClient";
import { Notary } from "../../types/Notary";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { notaryFormSchema } from "../Forms/NotaryForm/NotaryForm";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
export interface NotaryUpdateDialogProps extends BaseDialogProps {}

export const NotaryUpdateDialog: React.FC<NotaryUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [notary, setNotary] = useState<Notary | null>(null);

  useEffect(() => {
    if (!!dialogsViewerStore.notaryUpdateDialogOpenOptions) {
      notaryApiClient
        .getNotaryDetail(
          dialogsViewerStore.notaryUpdateDialogOpenOptions.notaryId
        )
        .then(setNotary);
    }
  }, [dialogsViewerStore.notaryUpdateDialogOpenOptions]);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Notary"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() =>
            notary && (
              <Form
                formId="update-notary-form"
                value={notary}
                formSchemaBuilder={notaryFormSchema}
                onFormSubmit={async (notary) => {
                  setIsSaving(true);

                  await notaryApiClient
                    .updateNotary(notary.id, notary)
                    .then(() => {
                      setNotary(notary);
                      gridStore.refresh();
                      submitDialog();
                    })
                    .finally(() => {
                      setIsSaving(false);
                    });
                }}
              />
            )
          }
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="notary-save-button"
          isSaving={isSaving}
          formId="update-notary-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
