import { UserRoles } from "../../../types/User";
import GridColumn from "../../Grid/Grid/types/GridColumn";

export const gridColumnsConfiguration: GridColumn[] = [
  new GridColumn({
    field: "templateName",
    headerText: "Template Name",
    type: "string",
  }),
  new GridColumn({
    field: "templateType_AsString",
    headerText: "Type",
    type: "string",
  }),
  new GridColumn({
    field: "templateStates_AsReadonly",
    headerText: "States",
    type: "string",
  }),
  new GridColumn({
    field: "sendOrder",
    headerText: "Send Order",
    type: "number",
  }),
  new GridColumn({
    field: "updatedAt_AsString",
    headerText: "Updated Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "updatedBy_AsName",
    headerText: "Updated By",
    type: "string",
  }),
  new GridColumn({
    field: "createdAt_AsString",
    headerText: "Created Date",
    type: "date",
    format: { format: "MM/dd/yyyy", type: "date" },
  }),
  new GridColumn({
    field: "createdBy_AsName",
    headerText: "Created By",
    type: "string",
  }),
];

const gridRoleColumnsConfigurationDefaultFields = [
  "templateName",
  "templateType_AsString",
  "TemplateStates_AsReadonly",
  "sendOrder",
  "updatedAt",
  "updatedBy_AsName",
  "createdAt",
  "createdBy_AsName",
];

export const getGridColumnsConfigurationByRole = (_role?: UserRoles) => {
  return [
    ...gridColumnsConfiguration
      .filter((x) =>
        gridRoleColumnsConfigurationDefaultFields.includes(x.field)
      )
      .sort(function (a, b) {
        return (
          gridRoleColumnsConfigurationDefaultFields.indexOf(a.field) -
          gridRoleColumnsConfigurationDefaultFields.indexOf(b.field)
        );
      }),
  ];
};
