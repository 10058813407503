import { get } from "lodash";
import React, { useEffect } from "react";
import { schemaStore } from "../../../stores/SchemaStore";
import { MedicalInformation } from "../../../types/Application/MedicalInformation";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newMedicalInformation: MedicalInformation = {};

export const medicalInformationFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  _formId,
  onFormSubmit,
  _disableAllFields,
  hideSectionHeader
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "application";
  const medicalInformation = form as MedicalInformation;

  const [
    isSocialSecurityFollowUpDateHidden,
    setIsSocialSecurityFollowUpDateHidden,
  ] = React.useState<boolean>(true);

  useEffect(() => {
    if (
      medicalInformation.hasSocialSecurityConfirmationNumberBeenProvided ===
        false ||
      medicalInformation.isDisabilitySubmittedToSocialSecurity === false
    ) {
      setIsSocialSecurityFollowUpDateHidden(false);
    } else {
      setIsSocialSecurityFollowUpDateHidden(true);
    }
  }, [
    medicalInformation.hasSocialSecurityConfirmationNumberBeenProvided,
    medicalInformation.isDisabilitySubmittedToSocialSecurity,
  ]);

  return [
    {
      type: "Section",
      label: "Medical Information",
      hidden: !!hideSectionHeader,
    } as SectionHeaderProps,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isDischarged"),
      value: medicalInformation.isDischarged,
      width: "65%",
      onSubmit: (isDischarged) => {
        onValueChange({
          ...medicalInformation,
          isDischarged,
          dischargedDate: "",
        });
      },
      isRequired: true,
      errorMessages: get(errors, "isDischarged"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "dischargedDate"),
      value: medicalInformation.dischargedDate,
      hidden: !medicalInformation.isDischarged,
      width: "65%",
      onSubmit: (dischargedDate) => {
        onValueChange({ dischargedDate });
      },
      isRequired: true,
      errorMessages: get(errors, "dischargedDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isDeceased"),
      value: medicalInformation.isDeceased,
      width: "65%",
      onSubmit: (isDeceased) => {
        onValueChange({ ...medicalInformation, isDeceased, deceasedDate: "" });
      },
      isRequired: true,
      errorMessages: get(errors, "isDeceased"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "deceasedDate"),
      value: medicalInformation.deceasedDate,
      hidden: !medicalInformation.isDeceased,
      width: "65%",
      onSubmit: (deceasedDate) => {
        onValueChange({ deceasedDate });
      },
      isRequired: true,
      errorMessages: get(errors, "deceasedDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "didResidentHavePriorHospitalStay"
      ),
      value: medicalInformation.didResidentHavePriorHospitalStay,
      width: "65%",
      onSubmit: (didResidentHavePriorHospitalStay) => {
        onValueChange({
          ...medicalInformation,
          didResidentHavePriorHospitalStay,
          priorHospitalStayDate: "",
        });
      },
      hidden: !medicalInformation.isPriorHospitalStayApplicableFor30DayStay,

      isRequired: true,
      errorMessages: get(errors, "didResidentHavePriorHospitalStay"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "priorHospitalStayDate"),
      value: medicalInformation.priorHospitalStayDate,
      hidden:
        !medicalInformation.isPriorHospitalStayApplicableFor30DayStay ||
        !medicalInformation.didResidentHavePriorHospitalStay,
      width: "65%",
      onSubmit: (priorHospitalStayDate) => {
        onValueChange({ priorHospitalStayDate });
      },
      isRequired: true,
      errorMessages: get(errors, "priorHospitalStayDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasMedicalCoverage"),
      value: medicalInformation.hasMedicalCoverage,
      onSubmit: (HasMedicalCoverage) => {
        if (onFormSubmit) {
          onFormSubmit({ ...medicalInformation, HasMedicalCoverage });
        }
      },
      isRequired: true,
      errorMessages: get(errors, "hasMedicalCoverage"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasHospice"),
      value: medicalInformation.hasHospice,
      onSubmit: (hasHospice) => {
        onValueChange({ hasHospice });
      },
      isRequired: true,
      width: "30%",
      errorMessages: get(errors, "hasHospice"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hospiceEffectiveDate"),
      value: medicalInformation.hospiceEffectiveDate,
      hidden: !medicalInformation.hasHospice,
      width: "65%",
      onSubmit: (hospiceEffectiveDate) => {
        onValueChange({ hospiceEffectiveDate });
      },
      errorMessages: get(errors, "hospiceEffectiveDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isDisabledOrBlind"),
      value: medicalInformation.isDisabledOrBlind,
      onSubmit: (isDisabledOrBlind) => {
        onValueChange({ isDisabledOrBlind });
      },
      isRequired: true,
      errorMessages: get(errors, "isDisabledOrBlind"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasDisabilityBeenDecided"
      ),
      value: medicalInformation.hasDisabilityBeenDecided,
      hidden: !medicalInformation.isDisabledOrBlind,
      width: "30%",
      onSubmit: (hasDisabilityBeenDecided) => {
        onValueChange({ hasDisabilityBeenDecided });
      },
      errorMessages: get(errors, "hasDisabilityBeenDecided"),
    } as AllFields,

    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "willDisabilityLastLongerThan30Days"
      ),
      value: medicalInformation.willDisabilityLastLongerThan30Days,
      hidden: !medicalInformation.isDisabledOrBlind,
      width: "30%",
      onSubmit: (willDisabilityLastLongerThan30Days) => {
        onValueChange({ willDisabilityLastLongerThan30Days });
      },
      errorMessages: get(errors, "willDisabilityLastLongerThan30Days"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "willDisabilityLastLongerThan12Months"
      ),
      value: medicalInformation.willDisabilityLastLongerThan12Months,
      hidden: !medicalInformation.isDisabledOrBlind,
      width: "30%",
      onSubmit: (willDisabilityLastLongerThan12Months) => {
        onValueChange({ willDisabilityLastLongerThan12Months });
      },
      errorMessages: get(errors, "willDisabilityLastLongerThan12Months"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "isDisabilitySubmittedToSocialSecurity"
      ),
      value: medicalInformation.isDisabilitySubmittedToSocialSecurity,
      hidden: !medicalInformation.isDisabledOrBlind,
      onSubmit: (isDisabilitySubmittedToSocialSecurity) => {
        onValueChange({ isDisabilitySubmittedToSocialSecurity });
      },
      errorMessages: get(errors, "isDisabilitySubmittedToSocialSecurity"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "partyResponsibleForSubmittingSocialSecurity"
      ),
      value: medicalInformation.partyResponsibleForSubmittingSocialSecurity,
      hidden: !(
        medicalInformation.isDisabilitySubmittedToSocialSecurity === false
      ),
      onSubmit: (partyResponsibleForSubmittingSocialSecurity) => {
        onValueChange({ partyResponsibleForSubmittingSocialSecurity });
      },
      errorMessages: get(errors, "partyResponsibleForSubmittingSocialSecurity"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasSocialSecurityConfirmationNumberBeenProvided"
      ),
      value: medicalInformation.hasSocialSecurityConfirmationNumberBeenProvided,
      hidden: !medicalInformation.isDisabilitySubmittedToSocialSecurity,
      onSubmit: (hasSocialSecurityConfirmationNumberBeenProvided) => {
        onValueChange({ hasSocialSecurityConfirmationNumberBeenProvided });
      },
      errorMessages: get(
        errors,
        "hasSocialSecurityConfirmationNumberBeenProvided"
      ),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "socialSecurityConfirmationNumber"
      ),
      value: medicalInformation.socialSecurityConfirmationNumber,
      hidden:
        !medicalInformation.hasSocialSecurityConfirmationNumberBeenProvided,
      onSubmit: (socialSecurityConfirmationNumber) => {
        onValueChange({ socialSecurityConfirmationNumber });
      },
      errorMessages: get(errors, "socialSecurityConfirmationNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "socialSecurityFollowUpDate"
      ),
      value: medicalInformation.socialSecurityFollowUpDate,
      hidden: isSocialSecurityFollowUpDateHidden,
      onSubmit: (socialSecurityFollowUpDate) => {
        onValueChange({ socialSecurityFollowUpDate });
      },
      errorMessages: get(errors, "socialSecurityFollowUpDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasMedicare"),
      value: medicalInformation.hasMedicare,
      width: "45%",
      onSubmit: (hasMedicare) => {
        if (onFormSubmit) {
          onFormSubmit({ ...medicalInformation, hasMedicare });
        }
      },
      isRequired: true,
      errorMessages: get(errors, "hasMedicalCoverage"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "medicareNumber"),
      value: medicalInformation.medicareNumber,
      width: "45%",
      onSubmit: (medicareNumber) => {
        onValueChange({ ...medicalInformation, medicareNumber });
      },
      isRequired: true,
      hidden: !medicalInformation.hasMedicare,
      errorMessages: get(errors, "medicareNumber"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasMedicarePartA"),
      value: medicalInformation.hasMedicarePartA,
      onSubmit: (hasMedicarePartA) => {
        if (onFormSubmit) {
          onFormSubmit({ ...medicalInformation, hasMedicarePartA });
        }
      },
      isRequired: true,
      hidden: !medicalInformation.hasMedicare,
      errorMessages: get(errors, "hasMedicarePartA"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "hasMedicarePartB"),
      value: medicalInformation.hasMedicarePartB,
      onSubmit: (hasMedicarePartB) => {
        if (onFormSubmit) {
          onFormSubmit({ ...medicalInformation, hasMedicarePartB });
        }
      },
      isRequired: true,
      hidden: !medicalInformation.hasMedicare,
      errorMessages: get(errors, "hasMedicarePartB"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "hasMedicaidInAnotherState"
      ),
      value: medicalInformation.hasMedicaidInAnotherState,
      onSubmit: (hasMedicaidInAnotherState) => {
        if (onFormSubmit) {
          onFormSubmit({ ...medicalInformation, hasMedicaidInAnotherState });
        }
      },
      isRequired: true,
      errorMessages: get(errors, "hasMedicaidInAnotherState"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(
        modelName,
        "medicaidInAnotherState_State"
      ),
      value: medicalInformation.medicaidInAnotherState_State,
      onSubmit: (medicaidInAnotherState_State: Option) => {
        onValueChange({
          medicaidInAnotherState_State: medicaidInAnotherState_State.value,
        });
      },
      isRequired: true,
      width: "22.5%",
      hidden: !medicalInformation.hasMedicaidInAnotherState,
      errorMessages: get(errors, "medicaidInAnotherState_State"),
    } as AllFields,
  ];
};
