import { FormGroup } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { SelectableLabel } from "../SelectableLabel/SelectableLabel";
import { TextField } from "../TextField/TextField";
import { FullNameFieldProps, FullNameFieldValue } from "../types/fieldTypes";
import "./styles.scss";

export const FullNameField: React.FC<FullNameFieldProps> = ({
  label,
  description,
  value,
  onSubmit,
  disabled,
  hidden,
  errorMessages,
  isRequired,
}) => {
  if (hidden) {
    return null;
  }
  const [localValue, setLocalValue] = React.useState<FullNameFieldValue>(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <FormGroup className="base-field">
      {label && <SelectableLabel name={label} description={description} />}
      <div className="full-name-field">
        <div className="full-name-field_input">
          <TextField
            type="Text"
            inputMode="text"
            value={localValue.firstName}
            disabled={disabled}
            placeholder={"First Name"}
            onSubmit={(value) => {
              setLocalValue({ ...localValue, firstName: value as string });
              onSubmit({ ...localValue, firstName: value as string });
            }}
            isRequired={isRequired}
          />
        </div>
        <div className="full-name-field_input">
          <TextField
            type="Text"
            inputMode="text"
            value={localValue.middleName}
            disabled={disabled}
            placeholder={"Middle Name"}
            onSubmit={(value) => {
              setLocalValue({ ...localValue, middleName: value as string });
              onSubmit({ ...localValue, middleName: value as string });
            }}
          />
        </div>
        <div className="full-name-field_input">
          <TextField
            type="Text"
            inputMode="text"
            value={localValue.lastName}
            disabled={disabled}
            placeholder={"Last Name"}
            isRequired={isRequired}
            onSubmit={(value) => {
              setLocalValue({ ...localValue, lastName: value as string });
              onSubmit({ ...localValue, lastName: value as string });
            }}
          />
        </div>
        <div className="full-name-field_input">
          <TextField
            type="Text"
            inputMode="text"
            value={localValue.maidenName}
            disabled={disabled}
            placeholder={"Maiden Name"}
            onSubmit={(value) => {
              setLocalValue({ ...localValue, maidenName: value as string });
              onSubmit({ ...localValue, maidenName: value as string });
            }}
          />
        </div>
      </div>
      {errorMessages && (
        <>
          {errorMessages.map((errorMessage, idx) => (
            <p key={idx} className="error-message">
              {errorMessage}
            </p>
          ))}
        </>
      )}
    </FormGroup>
  );
};
