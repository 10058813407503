import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { updateTemplateFormSchema } from "../Forms/TemplateForm/TemplateForm";
import { ExistingTemplate } from "../../types/Template";
import { templateApiClient } from "../../lib/apiClients/template/templateApiClient";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import { errorStore, genericErrorMessage } from "../../stores/ErrorStore";
import { Loading } from "../Loading/Loading";
import { isUndefined } from "lodash";

export interface TemplateUpdateDialogProps<TResponse> extends BaseDialogProps {
  templateId: string;
  submitDialog: (args?: TResponse) => void;
}

export const TemplateUpdateDialog: React.FC<
  TemplateUpdateDialogProps<ExistingTemplate>
> = ({ templateId, isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [template, setTemplate] = useState<ExistingTemplate>();

  useEffect(() => {
    if (!!dialogsViewerStore.templateUpdateDialogOptions) {
      templateApiClient
        .getById(templateId)
        .then((fechedTemplate: ExistingTemplate) => {
          // set
          fechedTemplate && setTemplate(fechedTemplate);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        });
    }
  }, [dialogsViewerStore.templateUpdateDialogOptions]);

  const updateTemplate = async (
    templateToUpdate: ExistingTemplate,
    validatePagesCount: boolean
  ) => {
    setIsSaving(true);

    await templateApiClient
      .updateTemplate(templateToUpdate.id, templateToUpdate, validatePagesCount)
      .then((updatedTemplate: ExistingTemplate) => {
        submitDialog(updatedTemplate);
      })
      .catch((error) => {
        if (
          error.response.status === 400 &&
          error.response.data?.type === "invalid-page-count-exception"
        ) {
          dialogsViewerStore.confirm({
            content: (
              <>
                The uploaded PDF has fewer pages than the existing template.
                Annotations on any additional pages will be lost. Are you sure
                you want to continue?
              </>
            ),
            onConfirm: async () => {
              const result = {
                isSuccess: true,
                error: undefined,
              };

              await updateTemplate(templateToUpdate, false);

              return result;
            },
          });
        } else {
          throw error;
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Dialog
      portalClassName="mdr-dialog template-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Update Template"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <>
              {!template && <Loading />}
              {template && (
                <Form<ExistingTemplate>
                  formId="update-template-form"
                  value={template}
                  formSchemaBuilder={updateTemplateFormSchema}
                  onFormSubmit={async (templateToUpdate) => {
                    if (isUndefined(templateToUpdate.documentId)) {
                      dialogsViewerStore.confirm({
                        content: (
                          <>
                            PDF Document has not finished uploading. If you
                            continue it will restore the previous value. Are you
                            sure you want to continue?
                          </>
                        ),
                        onConfirm: async () => {
                          const result = {
                            isSuccess: true,
                            error: undefined,
                          };

                          await updateTemplate(templateToUpdate, true);

                          return result;
                        },
                      });

                      return;
                    } else {
                      await updateTemplate(templateToUpdate, true);
                    }
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="template-save-button"
          isSaving={isSaving}
          formId="update-template-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
