import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { BaseDialogProps } from "./types/types";
import React, { useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import { financialEligibilityConversationFormSchema } from "../Forms/ApplicationForm/FinancialEligibilityConversation";
import Form from "../Forms";
import { FinancialEligibilityConversation } from "../../types/Application/FinancialEligibilityConversation";
import { applicationStore } from "../../stores/ApplicationStore";

export interface FinancialEligibilityConversationDialogProps
  extends BaseDialogProps {
  financialEligibilityConversation?: FinancialEligibilityConversation;
  medicaidEligibilityDecisionType_AsString?: string;
}

export const FinancialEligibilityConversationDialog: React.FC<
  FinancialEligibilityConversationDialogProps
> = ({
  isOpen,
  closeDialog,
  financialEligibilityConversation,
  medicaidEligibilityDecisionType_AsString,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Dialog
      portalClassName="mdr-dialog financial-eligibility-conversation-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Financial Eligibility Conversation"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <div className="financial-eligibility-conversation-decision-text">
          <div
            dangerouslySetInnerHTML={{
              __html: medicaidEligibilityDecisionType_AsString || "",
            }}
          />
        </div>
        <Form<FinancialEligibilityConversation>
          formId="financial-eligibility-conversation-form"
          formSchemaBuilder={financialEligibilityConversationFormSchema}
          value={financialEligibilityConversation}
          onFormSubmit={async (
            application: FinancialEligibilityConversation
          ) => {
            setIsSaving(true);
            applicationStore.updateApplicationFinancialEligibilityConversation(
              application
            );
            closeDialog();
            setIsSaving(false);
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="financial-eligibility-conversation-submit"
          isSaving={isSaving}
          formId="financial-eligibility-conversation-form"
          text="Submit"
        />
      </div>
    </Dialog>
  );
};
