import { ExistingResource } from "./ExistingResource";
import { Document } from "./Document";

export const notariesFolderName = "mdrnotaries";

export interface NewNotary {
  firstName: string;
  lastName: string;
  fieldRepId: string;
  documentId: string;
  state: number;
  countyId?: string;
  number: string;
  expirationDate: string;
  bondCompany: string;
  sealId: string;
  seal: Document | null;
  signature: string;
}

export type Notary = ExistingResource<NewNotary>;
