import {
  Button,
  Classes,
  Dialog,
  Intent,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import { Corporate, ExistingCorporate } from "../../types/Corporate";
import {
  corporateFormSchema,
  newFormCorporate,
} from "../Forms/CorporateForm/CorporateForm";
import { corporateApiClient } from "../../lib/apiClients/corporate/corporateApiClient";
import { WithTooltip } from "../Tooltip/Tooltip";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";

export interface CorporateCreationDialogProps extends BaseDialogProps {}

export const CorporateCreationDialog: React.FC<
  CorporateCreationDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState<TabId>("default");

  const openCorporateUpdateDialog = (
    newlyCreatedCorporate: ExistingCorporate
  ) => {
    //Opens Update Dialog after create
    dialogsViewerStore.setIsCorporateUpdateDialogOpen(true, {
      corporateId: newlyCreatedCorporate.id,
    });
  };

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Corporate"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Tabs
              selectedTabId={selectedTabId}
              onChange={(newTabId) => setSelectedTabId(newTabId)}
              large
            >
              <Tab
                id="default"
                title="Details"
                panel={
                  <Form<Corporate>
                    formId="create-corporate-form"
                    value={newFormCorporate}
                    formSchemaBuilder={corporateFormSchema}
                    onFormSubmit={async (corporate) => {
                      setIsSaving(true);

                      await corporateApiClient
                        .createCorporate(corporate)
                        .then((newlyCreatedCorporate) => {
                          gridStore.refresh();
                          submitDialog();

                          openCorporateUpdateDialog(newlyCreatedCorporate);
                        })
                        .finally(() => {
                          setIsSaving(false);
                        });
                    }}
                  />
                }
              />
              <Tab
                id="billing-rule"
                title={
                  <WithTooltip
                    shouldShowTooltip={true}
                    content="Please save the Corporate before creating Billing Rules."
                    placement="auto-start"
                  >
                    Billing Rules
                  </WithTooltip>
                }
                disabled
              />
              <Tab
                id="discount"
                title={
                  <WithTooltip
                    shouldShowTooltip={true}
                    content="Please save the Corporate before creating Discounts."
                    placement="auto-start"
                  >
                    Discounts
                  </WithTooltip>
                }
                disabled
              />
            </Tabs>
          )}
        </Observer>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="corporate-save-button"
          isSaving={isSaving}
          formId="create-corporate-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
