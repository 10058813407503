import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { reactPlugin } from "../../../../AppInsights";
import variables from "../../../../config/variables";
import "../../../../pages/styles.scss";
import { Grid } from "../../../Grid/Grid/Grid";
import { GridPermissions } from "../../../Grid/types/GridPermissions";
import { GridFilterSettings } from "../../../Grid/Grid/types/GridFilterSettings";
import { useGridView } from "@ucl/library";
import baseApiClient from "../../../../lib/apiClients/baseApiClient";
import { autorun } from "mobx";
import { gridStore } from "../../../Grid/Grid/stores/GridStore";
import {
  gridColumnsConfiguration,
  getGridColumnsConfigurationByRole,
} from "../../MatchupGrid/configurations/gridColumnsConfiguration";
import { GridMetrics } from "../../../Grid/Metrics/GridMetrics";
import { MatchupMetricReadDTO } from "../types";

export interface ActiveMatchupGridV2Props extends GridPermissions {
  onRecordClick: (matchupId: string) => void;
}

const ActiveMatchupGridV2Component: React.FC<ActiveMatchupGridV2Props> = ({
  canExportGridToExcel,
  canInvokeGridRecord,
  onRecordClick,
}) => {
  const gridKey = "matchup-v2-grid";

  const [filterSettings, setFilterSetttings] =
    useState<GridFilterSettings | null>(null);

  const gridView = useGridView(
    gridKey,
    "Matchup Board",
    gridColumnsConfiguration,
    getGridColumnsConfigurationByRole,
    baseApiClient,
    filterSettings,
    "Auto",
    "Auto"
  );

  useEffect(() => {
    autorun(() => {
      setFilterSetttings(gridStore.filterSettings as GridFilterSettings);
    });
  }, []);

  return (
    <div>
      <section
        key={gridView.currentGridViewKey}
        className="board-grid-container"
      >
        <Observer>
          {() => (
            <>
              {!!gridView.currentGridView && (
                <Grid<{
                  id: string;
                }>
                  key={gridView.currentGridViewKey}
                  gridKey={gridKey}
                  dataSource={`${variables.apiBaseUrl}odata/activeleadodata`}
                  columnsConfiguration={[
                    ...gridView.currentGridView.columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(gridView.currentGridView.filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(gridView.currentGridView.sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    allowExcelExport: canExportGridToExcel,
                    exportFilename: "Leads.xlsx",
                    onRecordClick: ({ rowData }) => {
                      if (canInvokeGridRecord) {
                        onRecordClick(rowData.id);
                      }
                    },
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
      <section className="board-grid-footer">
        <GridMetrics<MatchupMetricReadDTO>
          key={gridView.currentGridViewKey}
          gridKey={gridKey}
          url={`${variables.apiBaseUrl}api/lead/metrics/active`}
          columns={4}
          minimal={true}
          languageMapping={{
            countMatchups: "Count of Matchups",
          }}
        />
      </section>
    </div>
  );
};

export const ActiveMatchupGridV2 = withAITracking(
  reactPlugin,
  ActiveMatchupGridV2Component,
  "ActiveMatchupGridV2",
  "AI-flex-container"
);
