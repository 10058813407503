import {
  Button,
  Classes,
  Dialog,
  Intent,
  Tab,
  TabId,
  Tabs,
} from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { useState } from "react";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import { BaseDialogProps } from "./types/types";
import { gridStore } from "../Grid/Grid/stores/GridStore";
import {
  institutionFormSchema,
  newFormInstitution,
} from "../Forms/InstitutionForm/InstitutionForm";
import { institutionStore } from "../../stores/InstitutionStore";
import { Institution } from "../../types/Institution";
import { WithTooltip } from "../Tooltip/Tooltip";

export interface InstitutionCreationDialogProps extends BaseDialogProps {}

export const InstitutionCreationDialog: React.FC<
  InstitutionCreationDialogProps
> = ({ isOpen, closeDialog, submitDialog }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState<TabId>("default");

  const shouldShowFooterActions = selectedTabId !== "institution-contacts";

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Create Institution"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Observer>
          {() => (
            <Tabs
              selectedTabId={selectedTabId}
              onChange={(newTabId) => setSelectedTabId(newTabId)}
              large
            >
              <Tab
                id="default"
                title="Details"
                panel={
                  <Form<Institution>
                    formId="create-institution-form"
                    value={newFormInstitution}
                    formSchemaBuilder={institutionFormSchema}
                    onFormSubmit={async (institution) => {
                      setIsSaving(true);

                      await institutionStore
                        .createInstitution(institution)
                        .then(() => {
                          gridStore.refresh();
                          submitDialog();
                        })
                        .finally(() => {
                          setIsSaving(false);
                        });
                    }}
                  />
                }
              />
              <Tab
                id="institution-documents-needed"
                title={
                  <WithTooltip
                    shouldShowTooltip={true}
                    content="Please save the institution before creating documents needed."
                    placement="auto-start"
                  >
                    Documents Needed
                  </WithTooltip>
                }
                disabled
              />
              <Tab
                id="institution-contacts"
                title={
                  <WithTooltip
                    shouldShowTooltip={true}
                    content="Please save the institution before creating contacts."
                    placement="auto-start"
                  >
                    Contacts
                  </WithTooltip>
                }
                disabled
              />
            </Tabs>
          )}
        </Observer>
      </div>
      {shouldShowFooterActions && (
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            minimal
            intent={Intent.DANGER}
            text="Cancel"
            onClick={closeDialog}
          />
          <FormSubmitButton
            buttonId="institution-save-button"
            isSaving={isSaving}
            formId="create-institution-form"
            text="Save"
          />
        </div>
      )}
    </Dialog>
  );
};
