import React from "react";
import { User, UserRoles } from "../../types/User";
import { BaseField } from "../Forms/BaseField/BaseField";
import { userFormSchema } from "../Forms/UserForm/UserForm";
import { useState, useEffect } from "react";
import { userApiClient } from "../../lib/apiClients/user/userApiClient";
import { Callout } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export interface UserDetailsProps {
  user: User;
  errors: {
    [key: string]: string[];
  };
  setUserForm: (test: any) => void;
  formId: string;
}

export const UserDetails: React.FC<UserDetailsProps> = ({
  user,
  errors,
  setUserForm,
  formId,
}) => {
  const [externalUsers, setExternalUsers] = useState<Partial<User>[]>([]);
  const [
    doesPotentialDuplicateExternalUserExist,
    setDoesPotentialDuplicateExternalUserExist,
  ] = useState(false);

  const checkForPotentialDuplicateExternalUser = (user: User) => {
    const shouldValidate =
      user.role === UserRoles.FacilityUser ||
      user.role === UserRoles.CorporateUser;
    if (shouldValidate) {
      const matchingUser = externalUsers.filter(
        (x) =>
          x.firstName?.toLowerCase() === user.firstName?.toLowerCase() &&
          x.lastName?.toLowerCase() === user.lastName?.toLowerCase() &&
          x.corporateId === user.corporateId &&
          x.id !== user.id
      );
      return matchingUser.length > 0;
    } else {
      return false;
    }
  };

  useEffect(() => {
    userApiClient.getExternalUsers().then(setExternalUsers);
    return () => {
      setExternalUsers([]);
    };
  }, []);

  useEffect(() => {
    setDoesPotentialDuplicateExternalUserExist(
      checkForPotentialDuplicateExternalUser(user)
    );

    return () => {
      setDoesPotentialDuplicateExternalUserExist(false);
    };
  }, [checkForPotentialDuplicateExternalUser]);

  return (
    <div className="user-details-form-fields">
      <BaseField
        handleOnBlur={() => checkForPotentialDuplicateExternalUser(user)}
        {...userFormSchema.role(user, setUserForm, errors)}
      />
      <BaseField {...userFormSchema.userName(user, errors)} />
      <BaseField
        handleOnBlur={() => checkForPotentialDuplicateExternalUser(user)}
        {...userFormSchema.applicantName(user, setUserForm, errors)}
      />
      {doesPotentialDuplicateExternalUserExist && (
        <Callout
          className="facility-user-potential-duplicate-callout"
          icon={IconNames.WARNING_SIGN}
          intent="warning"
        >
          {`User with this name already exists for this Corporate, please verify potential duplicate.`}
        </Callout>
      )}
      <BaseField
        handleOnBlur={() => checkForPotentialDuplicateExternalUser(user)}
        {...userFormSchema.corporate(user, setUserForm, errors)}
      />
      <BaseField {...userFormSchema.facilities(user, setUserForm, errors)} />
      <BaseField
        {...userFormSchema.facilityPOCFields(user, setUserForm, errors)}
      />
      <BaseField {...userFormSchema.title(user, setUserForm, errors)} />
      <BaseField {...userFormSchema.email(user, formId, setUserForm, errors)} />
      <div className="user-details-form-fields-section">
        <BaseField {...userFormSchema.phoneNumber(user, setUserForm, errors)} />
        <BaseField
          {...userFormSchema.phoneExtension(user, setUserForm, errors)}
        />
      </div>
      <div className="user-details-form-fields-section">
        <BaseField {...userFormSchema.faxNumber(user, setUserForm, errors)} />
        <BaseField
          {...userFormSchema.additionalPhoneNumber(user, setUserForm, errors)}
        />
      </div>
      <BaseField {...userFormSchema.address(user, setUserForm, errors)} />
      <BaseField
        {...userFormSchema.fieldRepFacilities(user, setUserForm, errors)}
      />
      <BaseField {...userFormSchema.regions(user, setUserForm, errors)} />
      <BaseField {...userFormSchema.isActive(user, setUserForm, errors)} />
    </div>
  );
};
