import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { commentsApiClient } from "../lib/apiClients/comments/commentsApiClient";
import { applicationStore } from "./ApplicationStore";
import {
  Comment,
  CommentLevel,
  CommentSource,
  NewComment,
} from "../types/Comment";
import { userStore } from "./UserStore";

const defaultNewCommentLevel = userStore.user?.isExternal
  ? CommentLevel.Public
  : CommentLevel.Internal;

export const defaultNewComment: Partial<NewComment> = {
  applicationId: "",
  sourceId: "",
  message: "",
  level: defaultNewCommentLevel,
  isPinned: false,
  source: CommentSource.File,
  pinnedDate: undefined,
};

export class CommentStore {
  public comments: Comment[] = [];
  public newComment: Partial<NewComment> = defaultNewComment;

  constructor() {
    makeObservable(this, {
      comments: observable,
      newComment: observable,
      setComments: action,
      resetNewComment: action,
    });

    reaction(
      () => applicationStore.application?.id,
      () => {
        this.setComments();
        this.resetNewComment();
      }
    );
  }

  setComments = async () => {
    if (applicationStore.application?.id) {
      await commentsApiClient
        .getApplicationComments(applicationStore.application?.id)
        .then((items) => {
          runInAction(() => {
            this.comments = items;
          });
        });
    }
  };

  resetNewComment = async () => {
    this.newComment = defaultNewComment;
  };

  setNewComment = async (comment: Partial<NewComment>) => {
    this.newComment = comment;
  };
}

export const commentStore = new CommentStore();
