export const openApplicationFormOrPageFromRowData = (
  rowData: any,
  canInvokeGridRecord: boolean | undefined
) => {
  let redirectTo: string | undefined = undefined;
  if (canInvokeGridRecord) {
    if (rowData.status === "Interviewing") {
      redirectTo = `/application-form/${rowData.id}/primary-information`;
    } else {
      redirectTo = `/application-page/${rowData.id}`;
    }
  } else {
    if (rowData.status !== "Interviewing") {
      redirectTo = `/application-page/${rowData.id}`;
    }
  }

  if (!redirectTo) {
    return;
  }
  window.open(redirectTo, "_blank");
};
