import { Permission } from "../../../types/Permission";
import { NewUser, User, UserSignatures } from "../../../types/User";
import baseApiClient from "../baseApiClient";

class UserApiClient {
  signIn = async () => {
    return baseApiClient.post<User>("user/signin");
  };
  getUserDetail = async (id: string) => {
    return baseApiClient.get<User>(`user/${id}/detail`);
  };

  getUserSignatures = async (id: string) => {
    return baseApiClient.get<UserSignatures>(`user/${id}/signatures`);
  };

  createUser = async (user: NewUser, shouldSendInviteEmail = false) => {
    return baseApiClient.post<User>(
      `user?shouldSendInviteEmail=${shouldSendInviteEmail}`,
      user
    );
  };

  getExternalUsers = async () => {
    return baseApiClient.get<Partial<User>[]>(`user/external-users`);
  };
  updateUser = async (
    userId: string,
    user: User,
    shouldSendInviteEmail = false
  ) => {
    return baseApiClient.put<User>(
      `user/${userId}?shouldSendInviteEmail=${shouldSendInviteEmail}`,
      user
    );
  };

  updateUserSignatures = async (
    userId: string,
    userSignatures: UserSignatures
  ) => {
    return baseApiClient.put<User>(`user/${userId}/signatures`, userSignatures);
  };

  getRolePermissions = (role: number) => {
    return baseApiClient.get<Permission[]>(`user/role-permissions/${role}`);
  };

  getComputedState = (user: Partial<User>) => {
    return baseApiClient.post<Partial<User>>("user/state", user);
  };
}

export const userApiClient = new UserApiClient();
