import { userStore } from "../stores/UserStore";

export const getRandomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

export const startWithVowel = (word?: string) => word?.match("^[aieouAIEOU].*");
export const getRightArticleSyntax_aWord = (nextWord?: string) =>
  startWithVowel(nextWord) ? "an" : "a";

export const getObjectValueClassName = (objectValue?: string) =>
  objectValue?.toLowerCase().replace(/[^A-Z0-9]+/gi, "_");

export const getObjectValueActiveClassName = (objectValue?: boolean) =>
  objectValue ? "active" : undefined;

export const getApplicationStatusClassName = (
  applicationStatus_AsString: string | undefined
) => {
  switch (applicationStatus_AsString) {
    case "Interviewing":
      return "application-status-interviewing";
    default:
      return undefined;
  }
};

export const getScheduleMappingClassName = (
  scheduleMappingValues: string[],
  fieldName: string
) =>
  scheduleMappingValues?.includes(fieldName)
    ? "schedule-mapping-value"
    : undefined;

export const isTextOverflowingField = (element?: HTMLInputElement) => {
  return element ? element.offsetWidth < element.scrollWidth : false;
};

export const formatArrayOfStrings = (array?: string[]) => {
  if (array) {
    const formattedArray = array.map((item) => ` ${item}`);
    return formattedArray;
  } else {
    return [];
  }
};

export const generateKey = () => {
  return `${userStore.user?.id}_${new Date().getTime()}`;
};

export const formatBytes = (size: number | undefined) => {
  const units: string[] = ["bytes", "KB", "MB", "GB", "TB"];
  const factor = 1024;

  if (!size) {
    return "0 bytes";
  }

  // Find the appropriate unit
  for (const unit of units) {
    if (size < factor) {
      return `${size.toFixed(2)} ${unit}`;
    }
    size /= factor;
  }
};
