import { ColumnModel } from "@syncfusion/ej2-react-grids";
import GridColumn from "./GridColumn";
import "../styles.scss";
import { WithTooltip } from "../../../Tooltip/Tooltip";

interface GridClickableDataColumnOptions extends ColumnModel {
  tooltipContentField: string;
}

export default class GridTooltipColumn extends GridColumn {
  constructor(opts: GridClickableDataColumnOptions) {
    const { tooltipContentField, ...superOpts } = opts;
    super({
      ...superOpts,
      template: ((model: Record<string, string>) => {
        const modelValue = opts.field ? model[opts.field] : "";
        const tooltipContent = model[tooltipContentField] || "";

        return (
          <div className="grid-tooltip-column">
            <WithTooltip
              shouldShowTooltip={true}
              content={
                <div dangerouslySetInnerHTML={{ __html: tooltipContent }} />
              }
            >
              {modelValue}
            </WithTooltip>
          </div>
        );
      }) as any,
    });
  }
}
