import { get } from "lodash";
import { schemaStore } from "../../../stores/SchemaStore";
import { BillingRule } from "../../../types/BillingRule";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const newBillingRule: Partial<BillingRule> = {
  corporateId: "",
  productType: undefined,
  billingTrigger: undefined,
  name: undefined,
  amount: 0,
  shouldShow: false,
  shouldDiscounted: false,
  startDate: undefined,
  endDate: undefined,
};

export const billingRuleFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "billingRule";
  const billingRule = form as Partial<BillingRule>;
  const isExistingBillingRule = !!billingRule.id;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "productType"),
      value: billingRule.productType,
      disabled: isExistingBillingRule,
      onSubmit: (option: Option) => {
        onValueChange({ productType: option.value });
      },
      isRequired: true,
      errorMessages: get(errors, "productType"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "billingTrigger"),
      value: billingRule.billingTrigger,
      disabled: isExistingBillingRule,
      onSubmit: (option: Option) => {
        onValueChange({ billingTrigger: option.value });
      },
      isRequired: true,
      errorMessages: get(errors, "billingTrigger"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "name"),
      value: billingRule.name,
      onSubmit: (name) => {
        onValueChange({ name });
      },
      errorMessages: get(errors, "name"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "amount"),
      value: billingRule.amount,
      onSubmit: (amount) => {
        onValueChange({ amount });
      },
      errorMessages: get(errors, "amount"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "shouldShow"),
      value: billingRule.shouldShow,
      onSubmit: (shouldShow) => {
        onValueChange({ shouldShow });
      },
      errorMessages: get(errors, "shouldShow"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "shouldDiscounted"),
      value: billingRule.shouldDiscounted,
      onSubmit: (shouldDiscounted) => {
        onValueChange({ shouldDiscounted });
      },
      errorMessages: get(errors, "shouldDiscounted"),
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "startDate"),
      value: billingRule.startDate,
      onSubmit: (startDate) => {
        onValueChange({ startDate });
      },
      errorMessages: get(errors, "startDate"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "endDate"),
      value: billingRule.endDate,
      onSubmit: (endDate) => {
        onValueChange({ endDate });
      },
      errorMessages: get(errors, "endDate"),
    } as AllFields,
  ];
};
