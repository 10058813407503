import { get } from "lodash";
import { AllFields } from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";
import { schemaStore } from "../../../stores/SchemaStore";
import { User } from "../../../types/User";

export const newFormUserSignature = {
  signature: "",
  signature2: "",
  signature3: "",
  signature4: "",
  signature5: "",
  signature6: "",
};

export const userSignatureFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): AllFields[] => {
  const modelName = "user";
  const user = form as User;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature"),
      isDocumentId: true,
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature: value });
      },
      errorMessages: get(errors, "signature"),
      value: user.signature || "",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature2"),
      isDocumentId: true,
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature2: value });
      },
      errorMessages: get(errors, "signature2"),
      value: user.signature2 || "",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature3"),
      isDocumentId: true,
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature3: value });
      },
      errorMessages: get(errors, "signature3"),
      value: user.signature3 || "",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature4"),
      isDocumentId: true,
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature4: value });
      },
      errorMessages: get(errors, "signature4"),
      value: user.signature4 || "",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature5"),
      isDocumentId: true,
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature5: value });
      },
      errorMessages: get(errors, "signature5"),
      value: user.signature5 || "",
      isRequired: true,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "signature6"),
      isDocumentId: true,
      disabled: false,
      onSubmit: (value: string) => {
        onValueChange({ signature6: value });
      },
      errorMessages: get(errors, "signature6"),
      value: user.signature6 || "",
      isRequired: true,
    } as AllFields,
  ];
};
