import { Button, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import RelativityCard from "../../../Card/RelativityCard";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { ExternalUserSummaryChecklistGrid } from "./ExternalUserSummaryChecklistGrid";

export interface UploadDocumentsCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
}

export const UploadDocumentsCard: FC<UploadDocumentsCardProps> = ({
  applicationSummary,
}) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);
  const [localApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(applicationSummary);
  return (
    <RelativityCard
      className="summary-info"
      headerContent={
        <div className="upload-documents-card__header">
          <Button
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            Upload Documents
          </Button>
        </div>
      }
      hideBody={!isViewing}
    >
      <div className="summary-info__section summary-info__section__upload-documents">
        <RelativitySubCard columns={1}>
          <div className={`upload-documents-card`}>
            {localApplicationSummary.checklistItems && (
              <ExternalUserSummaryChecklistGrid
                checklistItems={localApplicationSummary.checklistItems.filter(
                  (x) => x.shouldDisplayOnExternalUserSummaryChecklist
                )}
              />
            )}
          </div>
        </RelativitySubCard>
      </div>
    </RelativityCard>
  );
};
