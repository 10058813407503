import { get } from "lodash";
import { commentStore } from "../../../stores/CommentStore";
import { schemaStore } from "../../../stores/SchemaStore";
import { userStore } from "../../../stores/UserStore";
import { NewComment } from "../../../types/Comment";
import {
  AllFields,
  Option,
  SectionHeaderProps,
} from "../../Fields/types/fieldTypes";
import { FormSchemaBuilder } from "../types";

export const commentFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "comment";
  const comment = form as NewComment;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "message"),
      value: comment.message,
      isRequired: true,
      onSubmit: (message: string) => {
        onValueChange({ message: message });
        commentStore.setNewComment({ ...comment, message: message });
      },
      errorMessages: get(errors, "message"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "level"),
      value: comment.level,
      width: "48%",
      onSubmit: (level: Option) => {
        onValueChange({ level: level.value });
        commentStore.setNewComment({ ...comment, level: Number(level.value) });
      },
      errorMessages: get(errors, "level"),
      hidden: userStore.user?.canViewInternalComment === false,
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "isPinned"),
      value: comment.isPinned,
      onSubmit: (isPinned: boolean) => {
        onValueChange({ isPinned: isPinned });
        commentStore.setNewComment({ ...comment, isPinned: isPinned });
      },
      errorMessages: get(errors, "isPinned"),
    } as AllFields,
  ];
};

export const externalUserCommentFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors
): (AllFields | SectionHeaderProps)[] => {
  const modelName = "comment";
  const comment = form as NewComment;

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "message"),
      value: comment.message,
      isRequired: true,
      onSubmit: (message: string) => {
        onValueChange({ message: message });
        commentStore.setNewComment({ ...comment, message: message });
      },
      errorMessages: get(errors, "message"),
    } as AllFields,
  ];
};
