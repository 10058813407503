import { Icon, Intent, Tab, Tabs } from "@blueprintjs/core";
import { Application } from "../../types/Application";
import RelativityCard from "../Card/RelativityCard";
import { EligibilitySummaryResponseDTO } from "../../types/Application/EligibilitySummary";
import { EligibilitySummaryProgressLabel } from "./EligibilitySummaryProgressLabel";
import {
  labelWithIcon,
  tooltip,
  progressTooltip,
  incomeItems,
  currency,
  assetItems,
  billItems,
  estPatientLiabilityItems,
  dateTooltip,
  dateTimelineItems,
  expandedTooltip,
  expandedAssetItems,
  expandedIncomeItems,
  spendDownIconColor,
} from "./EligibilitySummaryUtilities";
import classnames from "classnames";
import { IconNames } from "@blueprintjs/icons";
import { formatDateToMMddYYYY } from "../../utils/date";
import { WithTooltip } from "../Tooltip/Tooltip";
import { AMDAssetEligibilityDisclaimerIcon } from "./Icons/AMDAssetEligibilityDisclaimerIcon";
import { ApplicationSummaryResponseDTO } from "../../types/Application/ApplicationSummary";

export interface EligibilitySummaryProps {
  application?: Application;
  eligibilitySummary?: EligibilitySummaryResponseDTO;
}

export const getAssetValueToDisplay = (
  application: Application | ApplicationSummaryResponseDTO
) =>
  application?.useResidentPlusHalfJointAssetsCalculation
    ? application?.recertResidentPlusHalfJointAssetsValue
    : application?.combinedAssetItemsTotal;

export const EligibilitySummary: React.FC<EligibilitySummaryProps> = ({
  application,
  eligibilitySummary,
}) => {
  return (
    <div className="eligibility-summary">
      <RelativityCard
        columns={2}
        className="eligibility-summary-card"
        headerContent={
          <div className="eligibility-summary-header">
            {application?.isMarried &&
              !application.isSpouseLTCFacility &&
              application?.overrideAssetEligibility && (
                <WithTooltip
                  className="asset-eligibility-override-disclaimer"
                  shouldShowTooltip={true}
                  content="Manager confirmed Resident/community spouse spenddown complete. Disregard Asset Eligibility display text."
                >
                  <Icon
                    icon={IconNames.InfoSign}
                    intent={Intent.DANGER}
                    size={25}
                    color={spendDownIconColor}
                  />
                </WithTooltip>
              )}
            <Tabs
              large={true}
              className="eligibility-summary-header-tabs"
              selectedTabId={"default"}
              renderActiveTabPanelOnly={true}
            >
              <Tab
                className="eligibility-summary-header-tab"
                id="default"
                title="Eligibility Summary"
              />
            </Tabs>
          </div>
        }
      >
        {application && eligibilitySummary && (
          <>
            <div className="eligibility-points">
              <div className="eligibility-summary-data-point">
                {labelWithIcon(
                  "Income",
                  application.includeAllIncomesInSummary
                    ? expandedTooltip(expandedIncomeItems(application))
                    : tooltip(
                        incomeItems(application),
                        application.combinedIncomeItemsTotal,
                        "Resident"
                      )
                )}
                <div
                  className={
                    eligibilitySummary.medicaidEligibilityDecision
                      ? classnames({
                          "not-eligible":
                            !!eligibilitySummary.medicaidEligibilityDecision
                              .residentIncomeExceedsStateThreshold,
                          eligible:
                            !eligibilitySummary.medicaidEligibilityDecision
                              .residentIncomeExceedsStateThreshold,
                        })
                      : ""
                  }
                >
                  {currency(application.combinedIncomeItemsTotal || 0)}
                </div>
              </div>
              <div className="eligibility-summary-data-point">
                <div className="multiple-tooltip">
                  {labelWithIcon(
                    "Assets",
                    application.includeAllAssetsInSummary
                      ? expandedTooltip(expandedAssetItems(application))
                      : tooltip(
                          assetItems(application),
                          getAssetValueToDisplay(application),
                          "Resident"
                        )
                  )}
                  <AMDAssetEligibilityDisclaimerIcon
                    medicaidEligibilityDecision={
                      eligibilitySummary.medicaidEligibilityDecision
                    }
                  />
                </div>

                <div
                  className={
                    eligibilitySummary.medicaidEligibilityDecision
                      ? classnames({
                          "not-eligible":
                            !!eligibilitySummary.medicaidEligibilityDecision
                              .residentAssetsExceedsStateThreshold,
                          eligible:
                            !eligibilitySummary.medicaidEligibilityDecision
                              .residentAssetsExceedsStateThreshold,
                        })
                      : ""
                  }
                >
                  {currency(getAssetValueToDisplay(application) || 0)}
                </div>
              </div>
              <div className="eligibility-summary-data-point">
                {labelWithIcon(
                  "Bills",
                  tooltip(billItems(application), application.billItemsTotal)
                )}
                <div>{currency(application.billItemsTotal || 0)}</div>
              </div>
              <div className="eligibility-summary-data-point">
                {labelWithIcon(
                  "Est. Patient Liability",
                  tooltip(
                    estPatientLiabilityItems(application),
                    application.estPatientLiabilityItemsTotal,
                    "",
                    "Est. Patient Liability"
                  )
                )}
                <div>
                  {currency(application.estPatientLiabilityItemsTotal || 0)}
                </div>
              </div>
            </div>
            <div className="eligibility-points">
              {eligibilitySummary.medicaidEligibilityDecision && (
                <div className="eligibility-summary-data-point">
                  {labelWithIcon(
                    "Eligibility",
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          eligibilitySummary.medicaidEligibilityDecision
                            ?.decisionType_AsString || "",
                      }}
                    />,
                    <Icon
                      icon={IconNames.Issue}
                      intent={
                        eligibilitySummary.medicaidEligibilityDecision
                          .isEligible
                          ? Intent.SUCCESS
                          : Intent.DANGER
                      }
                    />
                  )}
                  <div
                    className={classnames({
                      "not-eligible":
                        !eligibilitySummary.medicaidEligibilityDecision
                          .isEligible,
                      eligible:
                        eligibilitySummary.medicaidEligibilityDecision
                          .isEligible,
                    })}
                  >
                    {eligibilitySummary.medicaidEligibilityStatus_AsString}
                  </div>
                </div>
              )}
              <div className="eligibility-summary-data-point">
                {labelWithIcon(
                  "Next Deadline",
                  dateTooltip(
                    dateTimelineItems(application).map((item) => ({
                      name: item.name,
                      description: item.description,
                      value: item.value,
                    }))
                  )
                )}
                <div>
                  {formatDateToMMddYYYY(application.nextDeadlineDate || "")}
                </div>
              </div>
              <div className="eligibility-summary-data-point">
                {labelWithIcon(
                  "Non-Financial",
                  progressTooltip(
                    Object.keys(
                      eligibilitySummary.nonFinancialCalculations
                        .nonFinancialCalculations
                    ).map((key) => ({
                      name: key,
                      value: !!eligibilitySummary.nonFinancialCalculations
                        .nonFinancialCalculations[key]
                        ? "Complete"
                        : "Not Complete",
                    }))
                  )
                )}
                <EligibilitySummaryProgressLabel
                  componentId="eligibility-summary-non-financial"
                  progress={
                    eligibilitySummary.nonFinancialCalculations.percentComplete
                  }
                  completedItems={Object.values(
                    eligibilitySummary.nonFinancialCalculations
                      .nonFinancialCalculations
                  ).reduce(
                    (count: number, value) => count + (value ? 1 : 0),
                    0
                  )}
                  totalItems={
                    Object.keys(
                      eligibilitySummary.nonFinancialCalculations
                        .nonFinancialCalculations
                    ).length
                  }
                />
              </div>
              <div className="eligibility-summary-data-point">
                {labelWithIcon(
                  "Verifications",
                  progressTooltip(
                    eligibilitySummary.checklistVerificationCalculations.outstandingChecklistItems.map(
                      (item) => ({
                        name: item.displayName,
                        value: item.status_AsString || "",
                      })
                    )
                  )
                )}
                <EligibilitySummaryProgressLabel
                  componentId="eligibility-summary-verifications"
                  progress={
                    eligibilitySummary.checklistVerificationCalculations
                      .percentComplete
                  }
                  completedItems={
                    eligibilitySummary.checklistVerificationCalculations
                      .completedChecklistItemCount
                  }
                  totalItems={
                    eligibilitySummary.checklistVerificationCalculations
                      .totalChecklistItemCount
                  }
                />
              </div>
            </div>
          </>
        )}
      </RelativityCard>
    </div>
  );
};
