import { Icon, Intent } from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";
import { checklistApiClient } from "../../lib/apiClients/checklist/checklistApiClient";
import { genericErrorMessage } from "../../stores/ErrorStore";
import { ChecklistItem } from "../../types/Checklist";
import { ThumbnailImagesData } from "../Document/PdfDocumentStore";
import { blankChecklistSplitForm } from "../Forms/ChecklistForm/ChecklistSplitForm";
import { AppToaster } from "../Toast/Toast";
import { Document } from "../../types/Document";
import { ChecklistItemDocumentPageDTO } from "./ChecklistSplit";
import { IconNames } from "@blueprintjs/icons";
import { DocumentPagesViewerModule } from "../Document/DocumentPagesViewer";
import useFilenameFormat from "../Fields/FilenameField/useFilenameFormat";
import { DocumentThumbnailsProperties } from "../Document/useDocumentPagesViewer";
import { DocumentThumbnailOperation } from "../Document/useDocumentEditor";

type ListColumn = { id: string; text: string; description: string };

export const useChecklistSplit = (props: {
  applicationId: string;
  checklistItemId: string;
}) => {
  const documentPagesViewermModule = useRef<DocumentPagesViewerModule>(null);

  const { formatFilename } = useFilenameFormat();
  const [ready, setReady] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loadedDocumentThumbnailsData, setLoadedDocumentThumbnailsData] =
    useState<DocumentThumbnailsProperties[]>([]);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState<boolean>(false);
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>([]);
  const [isLoadingChecklistItems, setIsLoadingChecklistItems] =
    useState<boolean>(false);
  const [selectedDocumentThumbnails, setSelectedDocumentThumbnails] = useState<
    ThumbnailImagesData[]
  >([]);
  const [destinyChecklistItem, setDestinyChecklistItem] = useState<
    Partial<ChecklistItem>
  >(blankChecklistSplitForm);
  const [documentOperations, setDocumentOperations] = useState<
    DocumentThumbnailOperation[]
  >([]);

  useEffect(() => {
    setup();
  }, []);

  const setup = async () => {
    setDestinyChecklistItem({ ...{} });
    fetchChecklistItems(props.applicationId);
    fetchChecklistItemDocuments(props.checklistItemId);
    setReady(true);
  };

  const fetchChecklistItems = (applicationId: string) => {
    setIsLoadingChecklistItems(true);

    checklistApiClient
      .getApplicationChecklist(applicationId)
      .then((items) => {
        items.sort((a, b) => a.displayName.localeCompare(b.displayName));
        setChecklistItems(items);
      })
      .finally(() => {
        setIsLoadingChecklistItems(false);
      });
  };

  const fetchChecklistItemDocuments = (checklistItemId: string) => {
    setIsLoadingDocuments(true);
    checklistApiClient
      .getChecklistItemDocuments(checklistItemId)
      .then((checklistDocuments) => {
        setDocuments(checklistDocuments.documents);
        const docThumbnailsData = checklistDocuments.splittedDocumentPages.map(
          (x) => {
            return {
              documentId: x.documentId,
              pageId: x.pageIdx,
              classNames: ["checklist-split-splitted"],
            } as DocumentThumbnailsProperties;
          }
        );
        setLoadedDocumentThumbnailsData(
          docThumbnailsData as DocumentThumbnailsProperties[]
        );
      })
      .finally(() => {
        setIsLoadingDocuments(false);
      });
  };

  const selectionChangedHandler = (value: ThumbnailImagesData[]) => {
    {
      setSelectedDocumentThumbnails(value);
    }
  };

  const checklistItemsTemplate = (data: ListColumn): JSX.Element => {
    return (
      <div className="checklist-split-destinty-item">
        <div className="checklist-split-destinty-item-label">
          <span>{data.text}</span>
          <div>{data.description || ""}</div>
        </div>
        <Icon icon={IconNames.Plus} />
      </div>
    );
  };

  const split = async (documentName: string) => {
    if (!selectedDocumentThumbnails || !destinyChecklistItem?.id) {
      return;
    }

    const result = {
      isSuccess: true,
      newDocumentId: "",
    };
    const request = selectedDocumentThumbnails.map((x) => {
      return {
        documentId: x.documentId,
        pageIdx: x.pageId,
        order: x.selectionOrder,
      } as ChecklistItemDocumentPageDTO;
    });

    await checklistApiClient
      .split(
        destinyChecklistItem.id,
        formatFilename(documentName),
        request,
        documentOperations,
        props.checklistItemId
      )
      .then((res: Document) => {
        result.newDocumentId = res.id;
      })
      .catch(() => {
        result.isSuccess = false;
        AppToaster.show({
          message: <div>{genericErrorMessage}</div>,
          intent: Intent.DANGER,
        });
      });

    if (result.isSuccess) {
      AppToaster.show({
        message: (
          <div>
            <h3>Success</h3>Split Completed.
          </div>
        ),
        intent: Intent.SUCCESS,
      });
    }

    return result;
  };

  /** Do not reset classnames history */
  const reset = () => {
    setDocumentOperations([]);
    setDestinyChecklistItem({ ...blankChecklistSplitForm });
    documentPagesViewermModule.current?.unselectAll();
    documentPagesViewermModule.current?.resetMode();
  };

  return {
    ready,
    isLoadingDocuments,
    documents,
    loadedDocumentThumbnailsData,
    destinyChecklistItem,
    setDestinyChecklistItem,
    selectedDocumentThumbnails,
    setSelectedDocumentThumbnails,
    selectionChangedHandler,
    split,
    checklistItems,
    isLoadingChecklistItems,
    checklistItemsTemplate,
    documentPagesViewermModule,
    formatFilename,
    reset,
    fetchChecklistItems,
    documentOperations,
    setDocumentOperations,
  };
};
