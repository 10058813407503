import {
  Button,
  Card,
  FormGroup,
  Icon,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useState } from "react";
import {
  Comment,
  CommentLevel,
  CommentSource,
  getCommentLevel,
  getCommentSource,
} from "../../../types/Comment";
import { AppToaster } from "../../Toast/Toast";
import { userStore } from "../../../stores/UserStore";
import { commentsApiClient } from "../../../lib/apiClients/comments/commentsApiClient";
import { DeleteButton } from "../../Buttons/DeleteButton";
import { UserIcon } from "../../Avatar/UserIcon";
import { EditButton } from "../../Buttons/EditButton";
import { SaveButton } from "../../Buttons/SaveButton";
import { convertDateTo_EST_timeZone_AsString } from "../../../utils/momentTimeZone";
import { getObjectValueClassName } from "../../../utils/miscellaneous";

export interface CommentCardProps {
  comment: Comment;
  commenter?: any;
  loadComments?: Function;
  isComponentEnabled: boolean;
  canEditAllComment?: boolean;
  canDeleteAllComment?: boolean;
  canEditSelfComment?: boolean;
  canDeleteSelfComment?: boolean;
  canPinComment?: boolean;
}

export const CommentCard: React.FC<CommentCardProps> = ({
  comment,
  loadComments,
  isComponentEnabled,
  canEditAllComment,
  canDeleteAllComment,
  canEditSelfComment,
  canDeleteSelfComment,
  canPinComment,
}) => {
  const [activeCommentText, setActiveCommentText] = useState<string>();
  const [activeComment, setActiveComment] = useState<string>();
  const isActiveComment = (comment: Comment) => {
    return comment.id === activeComment;
  };

  const getInitials = (comment: Comment) => {
    return comment.createdBy_AsName
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  const isCurrentUsersComment = userStore.user?.id === comment.createdBy;
  const isCommentPublic = comment.level === CommentLevel.Public;
  const isCommentSourceFile =
    comment.applicationId === comment.sourceId ||
    comment.source !== CommentSource.Document;

  return (
    <Card className="comment-card">
      <div className="comment-card-badge">
        <UserIcon initials={getInitials(comment)}></UserIcon>
      </div>
      <div className="comment-card-content">
        <div className="comment-card-header">
          <div>
            <div>{comment.createdBy_AsName}</div>
            <div className="grey small-font">
              {convertDateTo_EST_timeZone_AsString(comment.createdAt)}
            </div>
          </div>
          <div>
            <Button
              icon={IconNames.Pin}
              minimal
              intent={comment.isPinned ? Intent.DANGER : Intent.NONE}
              color={comment.isPinned ? "#FF0000" : "#000000"}
              disabled={!canPinComment}
              className={`comment-card-icon pin-button-${getObjectValueClassName(
                comment.isPinned ? "pinned" : "unpinned"
              )}`}
              onClick={async () => {
                if (!!isComponentEnabled) {
                  const result = { isSuccess: true, error: undefined };
                  comment.isPinned = !comment.isPinned;
                  await commentsApiClient.pinComment(comment).catch((error) => {
                    result.isSuccess = false;

                    if (error?.response?.status == 400) {
                      const pinnedError =
                        error.response.data.additionalInfo["isPinned"][0];

                      AppToaster.show({
                        message: <div>{pinnedError}</div>,
                        intent: Intent.DANGER,
                      });
                    }
                  });
                  if (result.isSuccess) {
                    AppToaster.show({
                      message: (
                        <div>
                          <h3>Success</h3>
                          Comment {comment.isPinned ? `Pinned` : `Unpinned`} .
                        </div>
                      ),
                      intent: Intent.SUCCESS,
                    });
                  }
                  loadComments && loadComments();
                }
              }}
            />
          </div>
        </div>
        <div className="comment-card-body">
          {isActiveComment(comment) && (
            <FormGroup className="base-field">
              <TextArea
                disabled={!isActiveComment(comment)}
                value={activeCommentText}
                onChange={(e) => {
                  setActiveCommentText(e.target.value);
                }}
                fill={true}
                growVertically
              />
            </FormGroup>
          )}
          {!isActiveComment(comment) && (
            <FormGroup className="base-field">
              <TextArea
                disabled={!isActiveComment(comment)}
                value={comment.message}
                growVertically
              />
            </FormGroup>
          )}
        </div>
        <div className="comment-card-footer">
          <div className="display-flex">
            <div className="display-flex">
              <>
                <Icon
                  icon={isCommentPublic ? IconNames.People : IconNames.Person}
                  intent={isCommentPublic ? Intent.PRIMARY : Intent.WARNING}
                  className="m-r-sm"
                />
                <span
                  className={
                    isCommentPublic
                      ? "primary small-font"
                      : "warning small-font"
                  }
                >
                  {getCommentLevel(comment.level)}
                </span>
              </>
            </div>
            <div className="comment-card-footer-source">
              Source:{" "}
              {isCommentSourceFile
                ? getCommentSource(comment.source)
                : `${getCommentSource(comment.source)} - ${comment.sourceName}`}
            </div>
            <div className="comment-card-footer-source">
              File Number: {comment.application_FileNumber}
            </div>
          </div>
          <div className="display-flex">
            <div>
              {(canEditAllComment ||
                (canEditSelfComment && isCurrentUsersComment)) && (
                <>
                  {isActiveComment(comment) && !!isComponentEnabled && (
                    <SaveButton
                      formId=""
                      onClick={async () => {
                        await commentsApiClient.updateComment({
                          ...comment,
                          message: activeCommentText,
                        });
                        comment.message = activeCommentText;
                        loadComments && loadComments();
                        setActiveComment(undefined);
                      }}
                    />
                  )}
                  {!isActiveComment(comment) && !!isComponentEnabled && (
                    <EditButton
                      className="comment-card-icon edit-button"
                      onClick={() => {
                        setActiveComment(comment.id);
                        setActiveCommentText(comment.message);
                      }}
                    />
                  )}
                </>
              )}
            </div>
            <div>
              {(canDeleteAllComment ||
                (canDeleteSelfComment && isCurrentUsersComment)) &&
                !!isComponentEnabled && (
                  <DeleteButton
                    onClick={async () => {
                      await commentsApiClient.deleteComment(comment.id);
                      AppToaster.show({
                        message: (
                          <div>
                            <h3>Success</h3>Comment Deleted.
                          </div>
                        ),
                        intent: Intent.SUCCESS,
                      });
                      loadComments && loadComments();
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
