import { schemaStore } from "../../../stores/SchemaStore";
import { AllFields, Option } from "../../Fields/types/fieldTypes";
import { Bill, BillOwnerTypes, NewBill } from "../../../types/Application/Bill";
import { FormSchemaBuilder } from "../types";
import { get } from "lodash";
import { applicationStore } from "../../../stores/ApplicationStore";

export const newBill: NewBill = {
  owner: undefined,
  type: undefined,
  nameDescription: undefined,
  account: undefined,
  amount: undefined,
  verified: undefined,
};

export const billFormSchema: FormSchemaBuilder = (
  onValueChange,
  form,
  errors,
  _formId
): AllFields[] => {
  const modelName = "bill";
  const bill = form as Bill;

  const getOwnerOptions = () => {
    let ownerOptions = schemaStore.getFieldSchemaByName(
      modelName,
      "owner"
    ).optionValues;

    if (!applicationStore.application?.isMarried) {
      ownerOptions = ownerOptions?.filter(
        (x) => x.value.toString() === BillOwnerTypes.Resident.toString()
      );
    }

    return ownerOptions;
  };

  return [
    {
      ...schemaStore.getFieldSchemaByName(modelName, "owner"),
      value: bill.owner,
      onSubmit: (owner: Option) => {
        onValueChange({ owner: owner.value });
      },
      optionValues: getOwnerOptions(),
      width: "45%",
      isRequired: true,
      errorMessages: get(errors, "owner"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "type"),
      value: bill.type,
      onSubmit: (type: Option) => {
        onValueChange({ type: type.value });
      },
      width: "45%",
      isRequired: true,
      errorMessages: get(errors, "type"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "nameDescription"),
      value: bill.nameDescription,
      onSubmit: (nameDescription: string) => {
        onValueChange({ nameDescription });
      },
      isRequired: true,
      errorMessages: get(errors, "nameDescription"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "account"),
      onSubmit: (account: string) => {
        onValueChange({ account });
      },
      value: bill.account,
      width: "30%",
      isRequired: false,
      errorMessages: get(errors, "account"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "amount"),
      onSubmit: (amount) => {
        onValueChange({ amount });
      },
      value: bill.amount,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "amount"),
    } as AllFields,
    {
      ...schemaStore.getFieldSchemaByName(modelName, "verified"),
      onSubmit: (verified: boolean) => {
        onValueChange({ verified });
      },
      value: bill.verified,
      width: "30%",
      isRequired: true,
      errorMessages: get(errors, "verified"),
    } as AllFields,
  ];
};
