import { SocialSecurityNumberFieldProps } from "../types/fieldTypes";
import { TextField } from "../TextField/TextField";
import { formatSSN } from "./utils";

export const SocialSecurityNumberField: React.FC<
  SocialSecurityNumberFieldProps
> = ({
  label,
  value,
  disabled,
  onSubmit,
  hidden,
  errorMessages,
  isRequired,
  isSensitive,
}) => {
  return (
    <TextField
      type="Text"
      label={label}
      value={formatSSN(value)}
      disabled={disabled}
      onSubmit={onSubmit}
      hidden={hidden}
      errorMessages={errorMessages}
      isRequired={isRequired}
      placeholder="999-99-9999"
      isSensitive={isSensitive}
      maxLength={11}
      inputMode="numeric"
    />
  );
};
