import { FC } from "react";
import RelativityCard from "../Card/RelativityCard";
import RelativitySubCard from "../Card/RelativitySubCard";
import { checklistItemStore } from "../../stores/ChecklistItemStore";
import { FiaRequestDVTChecklistDocumentsComponent } from "./FiaRequestDVTChecklistDocuments";
import { Observer } from "mobx-react";

export interface UploadDVTDocumentsCardProps {}

export const UploadDVTDocumentsCard: FC<UploadDVTDocumentsCardProps> = ({}) => {
  return (
    <Observer>
      {() => {
        const dvtChecklistItem = checklistItemStore.checklistItems.find(
          (x) => x.shouldDisplayOnFiaRequest
        );

        return (
          <div
            className="upload-dvt-documents-card-container"
            key={dvtChecklistItem?.count?.toString()}
          >
            {dvtChecklistItem && (
              <RelativityCard headerContent={"DVT Uploads"}>
                <RelativitySubCard columns={1}>
                  <div>
                    {dvtChecklistItem && (
                      <FiaRequestDVTChecklistDocumentsComponent
                        checklistItem={dvtChecklistItem}
                        documentCount={dvtChecklistItem.count}
                      />
                    )}
                  </div>
                </RelativitySubCard>
              </RelativityCard>
            )}
          </div>
        );
      }}
    </Observer>
  );
};
