import { Button, Intent } from "@blueprintjs/core";
import { FC, useState } from "react";
import RelativityCard from "../../../Card/RelativityCard";
import { ApplicationSummaryResponseDTO } from "../../../../types/Application/ApplicationSummary";
import RelativitySubCard from "../../../Card/RelativitySubCard";
import { BaseField } from "../../../Forms/BaseField/BaseField";
import { applicationSummaryDatesFormSchema } from "../../../Forms/ApplicationSummary/FileInformationFormSchema";
import { userStore } from "../../../../stores/UserStore";

export interface KeyDatesCardProps {
  applicationSummary: ApplicationSummaryResponseDTO;
}

export const KeyDatesCard: FC<KeyDatesCardProps> = ({ applicationSummary }) => {
  const [isViewing, setIsViewing] = useState<boolean>(true);

  const [localApplicationSummary] =
    useState<ApplicationSummaryResponseDTO>(applicationSummary);

  return (
    <RelativityCard
      headerContent={
        <>
          <Button
            className="resident-panel__section__view-toggle-button"
            minimal
            intent={Intent.PRIMARY}
            onClick={() => setIsViewing((prev) => !prev)}
          >
            Date Overview
          </Button>
        </>
      }
      hideBody={!isViewing}
    >
      {!userStore.user?.isExternal && (
        <RelativitySubCard hideBody={!isViewing}>
          <div className="summary-info__section">
            <BaseField
              {...applicationSummaryDatesFormSchema.applicationDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.admissionDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.dueDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.lastAppliedDate(
                localApplicationSummary
              )}
            />
          </div>
          <div className="summary-info__section">
            <BaseField
              {...applicationSummaryDatesFormSchema.repHandoffDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.medicaidNeededDate(
                localApplicationSummary
              )}
            ></BaseField>
            <BaseField
              {...applicationSummaryDatesFormSchema.firstAppliedDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.deniedDate(
                localApplicationSummary
              )}
            />
          </div>
          <div className="summary-info__section">
            <BaseField
              {...applicationSummaryDatesFormSchema.approvedDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.nextRecertDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.medicaidEligibleDate(
                localApplicationSummary
              )}
            />
          </div>
        </RelativitySubCard>
      )}
      {userStore.user?.isExternal && (
        <RelativitySubCard hideBody={!isViewing}>
          <div className="summary-info__section">
            <BaseField
              {...applicationSummaryDatesFormSchema.admissionDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.createdDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.repHandoffDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.medicaidNeededDate(
                localApplicationSummary
              )}
            />
          </div>
          <div className="summary-info__section">
            <BaseField
              {...applicationSummaryDatesFormSchema.firstAppliedDate(
                localApplicationSummary
              )}
            ></BaseField>
            <BaseField
              {...applicationSummaryDatesFormSchema.lastAppliedDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.approvedDate(
                localApplicationSummary
              )}
            />
            <BaseField
              {...applicationSummaryDatesFormSchema.medicaidEligibleDate(
                localApplicationSummary
              )}
            />
          </div>
        </RelativitySubCard>
      )}
    </RelativityCard>
  );
};
