import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { userStore } from "../../../../stores/UserStore";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { BaseDialogProps } from "../../types/types";
import { FacilityUsersGrid } from "./FacilityUsersGrid";

export interface FacilityUsersGridDialogProps extends BaseDialogProps {}

export const FacilityUsersGridDialog: React.FC<
  FacilityUsersGridDialogProps
> = ({
  isOpen,
  closeDialog,
  // submitDialog
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      isCloseButtonShown={true}
      title={dialogsViewerStore.facilityUsersGridDialogOpenOptions?.dialogTitle}
      onClose={closeDialog}
      portalClassName="mdr-dialog facility-users-dialog fullscreen-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        {dialogsViewerStore.facilityUsersGridDialogOpenOptions?.facilityId && (
          <FacilityUsersGrid
            canConfigureBoard={!!userStore.user?.canConfigureUserBoard}
            canExportGridToExcel={!!userStore.user?.canExportUserBoardToExcel}
            canCreateNewRecord={!!userStore.user?.canCreateUser}
            canInvokeGridRecord={!!userStore.user?.canEditUser}
            corporateId={
              dialogsViewerStore.facilityUsersGridDialogOpenOptions.corporateId
            }
            facilityId={
              dialogsViewerStore.facilityUsersGridDialogOpenOptions.facilityId
            }
            predicate={
              dialogsViewerStore.facilityUsersGridDialogOpenOptions.predicate
            }
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          minimal
          intent={Intent.DANGER}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
