import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import { reactPlugin } from "../AppInsights";
import { Header } from "../components/Header/Header";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import "./styles.scss";
import { featureToggleStore } from "../lib/featureToggles/FeatureToggleStore";
import ErrorWrapper from "./ErrorWrapper";
import { Button, Intent } from "@blueprintjs/core";
import { userStore } from "../stores/UserStore";
import {
  openCreateAppointmentDialog,
  openUpdateAppointmentDialog,
} from "../components/Dialogs/AppointmentDialog";
import {
  SelectEventArgs,
  TabComponent,
} from "@syncfusion/ej2-react-navigations";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AppointmentsCalendar } from "../components/Scheduler/AppointmentsCalendar";
import { fetchAppointmentForm } from "../components/Forms/AppointmentForm/AppointmentForm";
import {
  ScheduleViewTabConfig,
  ScheduleViews,
} from "../components/Scheduler/types/SchedulerViews";
import { User } from "../types/User";
import { isUndefined } from "lodash";
import { FacilityScheduleView } from "../components/Scheduler/FacilityScheduleView";

const SchedulerPageComponent: React.FC = () => {
  if (
    !userStore.user?.canViewScheduleMenu ||
    !featureToggleStore.featureToggles?.InAppScheduling
  ) {
    return <ErrorWrapper />;
  }
  const navigate = useNavigate();
  const tabRef = useRef<TabComponent>(null);
  const [activeTabConfig, setActiveTabConfig] = useState(
    ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar]
  );
  const tabSelected = (args: SelectEventArgs) => {
    const defaultTab =
      ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar];
    const selectedTab = Object.values(ScheduleViewTabConfig).find(
      (config) => config.tabIndex === args.selectedIndex
    );
    setActiveTabConfig(selectedTab || defaultTab);
    navigate(
      isUndefined(selectedTab) || isUndefined(selectedTab.navigateTo)
        ? defaultTab.navigateTo
        : selectedTab.navigateTo
    );
  };

  const renderTabTitles = (user: User) => {
    let configToRender = Object.values(ScheduleViewTabConfig);
    if (user.isExternal) {
      configToRender = Object.values(ScheduleViewTabConfig).filter(
        (config) => config.isInternalOnly !== userStore.user?.isExternal
      );
    }
    return configToRender.map((config) => {
      return <div key={config.key}>{config.title}</div>;
    });
  };

  return (
    <div className="page scheduler-page">
      {/* Dont want page level observer */}
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="scheduler" />
        <div className="page-main-content">
          <div className="header-bar">
            <h1>Scheduler</h1>
            {userStore.user?.canScheduleAppointment &&
              activeTabConfig ===
                ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar] && (
                <>
                  <Button
                    className="schedule-appointment-button"
                    intent={Intent.PRIMARY}
                    text="Create Appointment"
                    onClick={() => {
                      openCreateAppointmentDialog();
                    }}
                  />
                  <Button
                    className="schedule-appointment-button"
                    intent={Intent.PRIMARY}
                    text="Edit Appointment"
                    onClick={async () => {
                      openUpdateAppointmentDialog(
                        await fetchAppointmentForm(
                          "1109f8c9-6d9b-4439-84ce-c9ac2390ea97"
                        )
                      );
                    }}
                  />
                </>
              )}
          </div>
          <div className="control_wrapper" id="control_wrapper">
            <TabComponent
              ref={tabRef}
              headerPlacement="Top"
              selected={tabSelected}
              animation={{
                previous: { effect: "FadeIn" },
                next: { effect: "FadeIn" },
              }}
            >
              <div className="e-tab-header">
                {renderTabTitles(userStore.user)}
              </div>
              <div className="e-content"></div>
            </TabComponent>
            {activeTabConfig ===
              ScheduleViewTabConfig[ScheduleViews.AppointmentsCalendar] && (
              <AppointmentsCalendar />
            )}
            {activeTabConfig ===
              ScheduleViewTabConfig[ScheduleViews.FacilitySchedule] && (
              <FacilityScheduleView />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SchedulerPage = withAITracking(
  reactPlugin,
  SchedulerPageComponent,
  "SchedulerPage",
  "AI-flex-container"
);
