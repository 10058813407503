import { Ajax } from "@syncfusion/ej2-base";
import {
  CrudOptions,
  DataOptions,
  DataResult,
  ODataV4Adaptor,
  Query,
  DataManager,
  Predicate,
} from "@syncfusion/ej2-data";
import { GridColumn } from "@syncfusion/ej2-react-grids";
import _ from "lodash";
import { ODataBeforeSendArgs } from "./types/ODataBeforeSendArgs";
import moment from "moment-timezone";
import {
  convertDateTo_EST_timeZone_AsDate,
  removeOffSetFromDate,
} from "../../../utils/momentTimeZone";

export default class GridODataV4Adaptor extends ODataV4Adaptor {
  private onBeforeSend?: (
    beforeSendArgs: ODataBeforeSendArgs
  ) => [string, string | undefined];

  constructor(
    private columnsConfiguration?: GridColumn[],
    beforeSendFn?: (
      beforeSendArgs: ODataBeforeSendArgs
    ) => [string, string | undefined]
  ) {
    super();

    this.onBeforeSend = beforeSendFn;
  }

  beforeSend = async (
    dataManager: DataManager,
    request: XMLHttpRequest,
    ajax: Ajax
  ) => {
    // Dev Notes. Asynchronous calls cannot be handled at this level since beforeSend is synchronous.

    // Fetch Query
    const url = new URL(ajax.url);
    let queryString = url.search;

    // Cleanups
    //--Replaces any undefined keyword for null to supprto api types
    queryString = queryString.replace(/undefined/g, "null");
    let accessToken = undefined;

    // Send onBeforeSend event
    if (!!this.onBeforeSend) {
      const [qry, token] = this.onBeforeSend({
        dataManager,
        request,
        ajax,
        queryString,
      } as ODataBeforeSendArgs);

      queryString = qry;
      accessToken = token;
    }

    // Send request on Body
    // Note that is important that the body still sends the ? on the beginning for full support of queriers, including top.
    // queryString = queryString + "&$orderby=createdAt%20desc";
    // Set Query String
    ajax.data = queryString;

    // Open as POST Request
    request.open("POST", String(`${url.origin}${url.pathname}$query`));

    // Set Access Token
    if (!!accessToken) {
      request.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    }
  };

  onPredicate(
    predicate: Predicate,
    query: Query | boolean,
    requiresCast?: boolean
  ): string {
    // "date" types will display as is without time conversion
    // Type on Column Configuration should be date, ideally with format: { format: "MM/dd/yyyy", type: "date" }
    const dateColumnConfigs = this.columnsConfiguration?.filter(
      (x) => x.type === "date"
    );

    if (dateColumnConfigs?.map((x) => x.field).includes(predicate.field)) {
      if (predicate.value) {
        const momentDate = moment(predicate.value as Date);
        if (momentDate.isValid()) {
          const userTimezoneOffset =
            momentDate.toDate().getTimezoneOffset() * 60000;
          const newDateNoTz = new Date(
            momentDate.toDate().getTime() - userTimezoneOffset
          );
          const convertedEST = convertDateTo_EST_timeZone_AsDate(newDateNoTz);
          predicate.value = convertedEST;
        }
      }
    }

    const dateTimeColumnConfigs = this.columnsConfiguration?.filter(
      (x) => x.type === "datetime"
    );

    if (dateTimeColumnConfigs?.map((x) => x.field).includes(predicate.field)) {
      if (predicate.value) {
        const momentDate = moment(predicate.value as Date);
        if (momentDate.isValid()) {
          const cleanedDate = removeOffSetFromDate(momentDate.toString());
          const convertedEST = convertDateTo_EST_timeZone_AsDate(cleanedDate);
          predicate.value = convertedEST;
        }
      }
    }

    const result = super.onPredicate(predicate, query, requiresCast);
    return result;
  }

  processResponse(
    data: DataResult,
    ds?: DataOptions,
    query?: Query,
    xhr?: XMLHttpRequest,
    request?: Ajax,
    changes?: CrudOptions
  ): Object {
    const dateColumnReults = this.processDateColumns(data);

    if (!!dateColumnReults) {
      data = dateColumnReults;
    }

    const datetimeColumnResults = this.processDateTimeColumns(data);
    if (!!datetimeColumnResults) {
      data = datetimeColumnResults;
    }

    const processResult = super.processResponse.apply(this, [
      data,
      ds,
      query,
      xhr,
      request,
      changes,
    ]);

    return processResult;
  }

  processDateTimeColumns(data: DataResult): DataResult | undefined {
    const dateTimeColumnConfigs = this.columnsConfiguration?.filter(
      (x) => x.type === "datetime"
    );
    if (dateTimeColumnConfigs) {
      const cleaned = (_.get(data, "value") as any as Object[])?.map(
        (resultData) => {
          dateTimeColumnConfigs.forEach((columnConfig) => {
            const rawDate = _.get(resultData, columnConfig.field) as string;
            if (rawDate) {
              const momentDate = moment(rawDate);
              if (momentDate.isValid()) {
                const cleanedDate = removeOffSetFromDate(rawDate);
                const convertedEST =
                  convertDateTo_EST_timeZone_AsDate(cleanedDate);

                _.set(resultData, columnConfig.field, convertedEST);
              }
            }
          });

          return resultData;
        }
      );

      return { ...data, ...{ value: cleaned } };
    }
  }

  processDateColumns(data: DataResult): DataResult | undefined {
    // "date" types will display as is without time conversion
    // Type on Column Configuration should be date, ideally with format: { format: "MM/dd/yyyy", type: "date" }
    const dateColumnConfigs = this.columnsConfiguration?.filter(
      (x) => x.type === "date"
    );
    if (dateColumnConfigs) {
      const cleaned = (_.get(data, "value") as any as Object[])?.map(
        (resultData) => {
          dateColumnConfigs.forEach((columnConfig) => {
            const rawDate = _.get(resultData, columnConfig.field) as string;
            // since we now officially set the odata timestamp to UTC, we can ensure that Z will always be returned
            const rawDateWithoutZone = rawDate?.replace("Z", "");
            if (rawDateWithoutZone) {
              const rawDateWithoutZoneAsDate = new Date(rawDateWithoutZone);
              _.set(resultData, columnConfig.field, rawDateWithoutZoneAsDate);
            }
          });

          return resultData;
        }
      );

      return { ...data, ...{ value: cleaned } };
    }
  }
}
