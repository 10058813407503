import { IconName } from "@blueprintjs/core";

export interface HelpCenterTopic {
  id: string;
  sectionType: TopicSectionTypes;
  title: string;
  content: string;
  externalLink: string;
  usState?: number;
  usState_AsString: string;
  isFeedAllowed: boolean;
  isUsStateQuickGuide: boolean;
  updatedAt: string;
  updatedBy_AsName: string;
}

export interface HelpCenterTopicState {
  id: string;
  usState: number;
  usState_AsString: string;
  tabIndex: number;
  hasStatePolicy: boolean;
}

export interface HelpCenterSearchResponse {
  helpCenterTopic_Id: string;
  helpCenterTopic_SectionType: TopicSectionTypes;
  helpCenterTopic_Title: string;
  helpCenterTopic_Content: string;
  helpCenterTopic_UsState_AsString: string;
  score: number;
}

export interface CommonMessageMetadata {
  isSearchStatePolicyTopics: boolean;
}

export type HelpCenterTopicHeaderDTO = Pick<
  HelpCenterTopic,
  "id" | "title" | "sectionType"
> &
  CommonMessageMetadata;

export interface ChatUserConversationMessageMetadata
  extends CommonMessageMetadata {}
export interface ChatAssistantConversationMessageMetadata
  extends CommonMessageMetadata {
  helpCenterTopicHeaders: HelpCenterTopicHeaderDTO[];
}

export type HelpCenterTopicCreateDTO = Pick<
  HelpCenterTopic,
  | "title"
  | "content"
  | "externalLink"
  | "usState"
  | "isUsStateQuickGuide"
  | "isFeedAllowed"
>;

export type HelpCenterTopicUpdateDTO = Pick<
  HelpCenterTopic,
  | "title"
  | "content"
  | "externalLink"
  | "usState"
  | "isUsStateQuickGuide"
  | "isFeedAllowed"
>;

export enum TopicSectionTypes {
  FAQ = 1,
  CUE = 2,
  SOP = 3,
  TUT = 4,
  STR = 5,
}

export enum TopicSectionLayout {
  Accordion = 1,
  Card = 2,
}

export interface TopicSectionTypeConfiguration {
  key: number;
  icon: IconName;
  title: string;
  singularTitle: string;
  path: string;
  shortTitle: string;
  requireExternalLink: boolean;
  requireContent: boolean;
  sectionLayout: TopicSectionLayout;
  helpCenterTabIndex: number;
  requireUsState?: boolean;
  filterByUsState?: boolean;
}

export const topicSectionTypeConfig: Record<
  TopicSectionTypes,
  TopicSectionTypeConfiguration
> = {
  [TopicSectionTypes.FAQ]: {
    key: TopicSectionTypes.FAQ,
    icon: "help",
    title: "FAQ",
    singularTitle: "FAQ",
    path: "faq",
    shortTitle: TopicSectionTypes[TopicSectionTypes.FAQ],
    requireExternalLink: false,
    requireContent: true,
    requireUsState: false,
    sectionLayout: TopicSectionLayout.Accordion,
    helpCenterTabIndex: 0,
    filterByUsState: false,
  },
  [TopicSectionTypes.TUT]: {
    key: TopicSectionTypes.TUT,
    icon: "video",
    title: "Tutorials",
    singularTitle: "Tutorial",
    path: "tutorials",
    shortTitle: TopicSectionTypes[TopicSectionTypes.TUT],
    requireExternalLink: true,
    requireContent: false,
    requireUsState: false,
    sectionLayout: TopicSectionLayout.Card,
    helpCenterTabIndex: 1,
    filterByUsState: false,
  },
  [TopicSectionTypes.CUE]: {
    key: TopicSectionTypes.CUE,
    icon: "error",
    title: "Common Problems",
    singularTitle: "Common Problem",
    path: "common-problems",
    shortTitle: TopicSectionTypes[TopicSectionTypes.CUE],
    requireExternalLink: false,
    requireContent: true,
    requireUsState: false,
    sectionLayout: TopicSectionLayout.Accordion,
    helpCenterTabIndex: 2,
    filterByUsState: false,
  },
  [TopicSectionTypes.SOP]: {
    key: TopicSectionTypes.SOP,
    icon: "document",
    title: "Procedures",
    singularTitle: "Procedure",
    path: "procedures",
    shortTitle: TopicSectionTypes[TopicSectionTypes.SOP],
    requireExternalLink: false,
    requireContent: true,
    requireUsState: false,
    sectionLayout: TopicSectionLayout.Accordion,
    helpCenterTabIndex: 3,
    filterByUsState: false,
  },
  [TopicSectionTypes.STR]: {
    key: TopicSectionTypes.STR,
    icon: "asterisk",
    title: "State Requirements",
    singularTitle: "State Requirement",
    path: "state-requirements",
    shortTitle: TopicSectionTypes[TopicSectionTypes.STR],
    requireExternalLink: false,
    requireUsState: true,
    requireContent: true,
    sectionLayout: TopicSectionLayout.Accordion,
    helpCenterTabIndex: 4,
    filterByUsState: true,
  },
};
