import { Intent } from "@blueprintjs/core";
import classNames from "classnames";
import { autorun } from "mobx";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import RelativityCard from "../../Card/RelativityCard";
import RelativitySubCard from "../../Card/RelativitySubCard";
import { ReadOnlyField } from "../../Fields/ReadOnlyField/ReadOnlyField";
import { ReadOnlyFieldProps } from "../../Fields/types/fieldTypes";
import { AppToaster } from "../../Toast/Toast";
import { gridStore } from "../Grid/stores/GridStore";
import { GridMetricLayout } from "../types/dtos/GridMetrics";
import { SkeletonComponent } from "@syncfusion/ej2-react-notifications";
import "./styles.scss";
import { gridMetricsApiClient } from "./apiClients/gridMetricsApiClient";
import _ from "lodash";

export interface GridMetricsProps<T> {
  gridKey: string;
  url: string;
  languageMapping: T;
  columns: number;
  layout?: GridMetricLayout;
  minimal?: boolean;
}
export const GridMetrics = <T extends object>(props: GridMetricsProps<T>) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [metrics, setMetrics] = React.useState<T>();
  const [queryKey, setQueryKey] = React.useState<string>();

  // Observe and Fetch Metrics
  React.useEffect(() => {
    autorun(async () => {
      if (
        !!gridStore.filterSettings?.rawQuery &&
        gridStore.filterSettings.gridKey === props.gridKey
      ) {
        const requestKey = gridStore.filterSettings?.rawQuery;
        if (requestKey !== queryKey) {
          setQueryKey(requestKey);
        }
      }
    });
  }, []);

  useEffect(() => {
    fetchMetrics();
  }, [queryKey]);

  const fetchMetrics = async () => {
    if (!queryKey) {
      return;
    }

    setLoading(true);
    const newMetrics = await gridMetricsApiClient
      .fetchMetrics<T>(props.url, queryKey)
      .catch(() => {
        AppToaster.show({
          message: "An error ocurred loading the metrics data.",
          intent: Intent.DANGER,
        });
      });

    setMetrics(newMetrics || undefined);
    setLoading(false);
  };

  return (
    <Observer>
      {() => {
        return (
          <section
            className={classNames("grid-metrics", {
              "grid-metrics-minimal": props.minimal,
              "grid-metrics-loading": loading,
            })}
          >
            <RelativityCard>
              <RelativitySubCard
                columns={
                  props.columns ||
                  (metrics && Array.from(Object.entries(metrics))?.length)
                }
              >
                {loading &&
                  Array.from(Array(props.columns)).map((_x, idx) => {
                    return (
                      <div className="bp4-form-group" key={idx}>
                        <div className="bp4-form-content">
                          <SkeletonComponent
                            id="skeletonText"
                            shape="Rectangle"
                          />
                        </div>
                      </div>
                    );
                  })}

                {!loading &&
                  metrics &&
                  Array.from(Object.entries(metrics)).map((metric, idx) => {
                    return (
                      <ReadOnlyField
                        key={idx}
                        minimal={true}
                        label={_.get(props.languageMapping, metric[0])}
                        {...({ value: metric[1] } as ReadOnlyFieldProps)}
                      />
                    );
                  })}
              </RelativitySubCard>
            </RelativityCard>
          </section>
        );
      }}
    </Observer>
  );
};
