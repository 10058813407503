import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { commentsApiClient } from "../../lib/apiClients/comments/commentsApiClient";
import { applicationStore } from "../../stores/ApplicationStore";
import { commentStore } from "../../stores/CommentStore";
import { NewComment } from "../../types/Comment";
import { FormSubmitButton } from "../Buttons/FormSubmitButton";
import Form from "../Forms";
import {
  commentFormSchema,
  externalUserCommentFormSchema,
} from "../Forms/CommentForm/CommentForm";
import { dialogsViewerStore } from "./stores/DialogsViewerStore";
import "./styles.scss";
import { BaseDialogProps } from "./types/types";

export interface CommentCreationDialogProps extends BaseDialogProps {}

export const CommentCreationDialog: React.FC<CommentCreationDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <Dialog
      portalClassName="mdr-dialog"
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"New Comment"}
      onClose={closeDialog}
    >
      <div className={Classes.DIALOG_BODY}>
        <Form
          formId="comment-form"
          formSchemaBuilder={
            dialogsViewerStore.commentCreationDialogOpenOptions
              ?.fromExternalUserSummary
              ? externalUserCommentFormSchema
              : commentFormSchema
          }
          value={commentStore.newComment}
          onFormSubmit={async (comment: Partial<NewComment>) => {
            setIsSaving(true);
            const dialogOptions =
              dialogsViewerStore.commentCreationDialogOpenOptions;

            comment.applicationId = dialogOptions?.fromExternalUserSummary
              ? dialogOptions?.applicationId
              : applicationStore.application?.id;

            comment.application_FileNumber =
              dialogOptions?.fromExternalUserSummary
                ? dialogOptions?.applicationFileNumber
                : applicationStore.application?.fileNumber;

            comment.sourceId = comment.applicationId;

            try {
              await commentsApiClient.createComment({ ...comment });
              dialogsViewerStore.commentCreationDialogOpenOptions?.onSubmit &&
                dialogsViewerStore.commentCreationDialogOpenOptions?.onSubmit();
              submitDialog();
            } finally {
              commentStore.resetNewComment();
              setIsSaving(false);
            }
          }}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text="Cancel"
          onClick={closeDialog}
        />
        <FormSubmitButton
          buttonId="comment-button"
          isSaving={isSaving}
          formId="comment-form"
          text="Save"
        />
      </div>
    </Dialog>
  );
};
