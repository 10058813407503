import { Schema, SchemaFactoryV2 } from "@ucl/library";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import baseApiClient from "../apiClients/baseApiClient";

export interface FieldSchemaV2Model {}

interface FieldSchemaV2FactoryContextProps {
  schemaFactory?: SchemaFactoryV2;
}

interface FieldSchemaV2FactoryProviderProps {
  children: ReactNode;
}

const FieldSchemaV2FactoryContext =
  createContext<FieldSchemaV2FactoryContextProps>({
    schemaFactory: undefined,
  });

export const FieldSchemaV2FactoryProvider: React.FC<
  FieldSchemaV2FactoryProviderProps
> = (props) => {
  const [schemaFactory, setSchemaFactory] = useState<SchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await baseApiClient.get<Schema>(`/fieldschemav2`);
    const newSchemaFactory = new SchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
  }, []);

  return (
    <FieldSchemaV2FactoryContext.Provider
      value={{
        schemaFactory,
      }}
    >
      {props.children}
    </FieldSchemaV2FactoryContext.Provider>
  );
};

export const useFieldSchemaFactory = () =>
  useContext(FieldSchemaV2FactoryContext);
