import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FC } from "react";

export interface EditButtonProps {
  isLoading?: boolean;
  text?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const EditButton: FC<EditButtonProps> = ({
  isLoading,
  text = "",
  className = "",
  disabled = false,
  onClick,
}) => {
  return (
    <Button
      type="button"
      className={className}
      disabled={disabled}
      minimal
      intent={Intent.PRIMARY}
      onClick={onClick}
      rightIcon={IconNames.EDIT}
      loading={isLoading}
      text={text}
    />
  );
};
