import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { userStore } from "../../../../stores/UserStore";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { BaseDialogProps } from "../../types/types";
import { FacilityApplicationsGrid } from "./FacilityApplicationsGrid";

export interface FacilityApplicationsGridDialogProps extends BaseDialogProps {}

export const FacilityApplicationsGridDialog: React.FC<
  FacilityApplicationsGridDialogProps
> = ({ isOpen, closeDialog }) => {
  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={true}
      isCloseButtonShown={true}
      title={"Facility Applications"}
      onClose={closeDialog}
      className="facility-applications"
      portalClassName="mdr-dialog facility-applications-dialog fullscreen-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        {dialogsViewerStore.facilityApplicationsGridDialogOpenOptions
          ?.facilityId && (
          <FacilityApplicationsGrid
            canExportGridToExcel={!!userStore.user?.canExportFacilityToExcel}
            canInvokeGridRecord={!!userStore.user?.canEditFacility}
            facilityId={
              dialogsViewerStore.facilityApplicationsGridDialogOpenOptions
                .facilityId
            }
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          text="Cancel"
          minimal
          intent={Intent.DANGER}
          onClick={closeDialog}
        />
      </div>
    </Dialog>
  );
};
