import { Tab, Tabs } from "@blueprintjs/core";
import { uniq } from "lodash";
import { useState } from "react";
import { buildField } from "../Fields";
import { AllFields, SectionHeaderProps } from "../Fields/types/fieldTypes";
import "./styles.scss";
import { FormSchemaBuilder } from "./types";
import { BaseForm } from "./BaseForm/BaseForm";

const tabIdToName: { [tabId: string]: string } = {
  default: "Primary Details",
  "sending-methods": "Sending Methods",
  "institution-contacts": "Contacts",
};

export interface FormProps<T> {
  formId: string;
  onFormSubmit: (value: T) => Promise<void>;
  formSchemaBuilder: FormSchemaBuilder;
  shouldBlockNavigation?: boolean;
  value?: T;
  computedStateOption?: {
    fields: string[];
    getComputedState?: (value: Partial<T>) => Promise<Partial<T>>;
  };
  disableAllFields?: boolean;
  shouldResetForm?: boolean;
  hideSectionHeader?: boolean;
  isFormRequired?: boolean;
  ownedEntityStoreAction?: (value: T) => void;
  shouldSaveEntiityToLocalStore?: boolean;
}

export default function Form<T>({
  formId,
  formSchemaBuilder,
  shouldBlockNavigation,
  value,
  onFormSubmit,
  computedStateOption,
  disableAllFields,
  shouldResetForm,
  hideSectionHeader,
  isFormRequired,
  ownedEntityStoreAction,
  shouldSaveEntiityToLocalStore,
}: FormProps<T>) {
  const [formValue, setFormValue] = useState<T>(value || ({} as T));
  const [errors, setErrors] = useState<{
    [key: string]: string[];
  }>({});
  const onChange = (field: {}) => {
    setFormValue({ ...formValue, ...field });

    if (!!shouldSaveEntiityToLocalStore && !!ownedEntityStoreAction) {
      ownedEntityStoreAction({ ...formValue, ...field });
    }
  };

  const wrapFields = (fieldSchemas: (AllFields | SectionHeaderProps)[]) =>
    fieldSchemas.map((fieldSchema, idx) => {
      const flex = `0 1 ${fieldSchema.width || "100%"}`;

      if (fieldSchema.hidden) {
        return null;
      }

      return (
        <div key={idx} style={{ flex }}>
          {buildField(fieldSchema, idx)}
        </div>
      );
    });

  let fields = formSchemaBuilder(
    onChange,
    formValue,
    errors,
    formId,
    onFormSubmit,
    disableAllFields,
    hideSectionHeader,
    isFormRequired
  );

  // disable all fields if disableAllFields = true
  fields = fields.map((field) => ({
    ...field,
    ...(field.type !== "Section" && {
      disabled: field.disabled || disableAllFields,
    }),
  }));

  // Require all fields on isFormRequired = true
  fields = fields.map((field) => ({
    ...field,
    ...(field.type !== "Section" && {
      isRequired: field.isRequired || isFormRequired,
    }),
  }));

  const hasTabs = fields.some((field) => !!field.tabId);
  const tabIds = fields
    .filter((field) => field.tabId)
    // we can cast to string because the above filter checks truthy value
    .map((field) => field.tabId as string);
  const uniqueTabIds = uniq(tabIds);

  return (
    <>
      <BaseForm
        formId={formId}
        onFormSubmit={onFormSubmit}
        shouldBlockNavigation={shouldBlockNavigation}
        value={value}
        formValueState={{ formValue, setFormValue }}
        computedStateOption={computedStateOption}
        shouldResetForm={shouldResetForm}
        onErrors={(errors) => setErrors(errors)}
        setErrors={setErrors}
      >
        {hasTabs ? (
          <Tabs>
            <Tab
              id="default"
              title={tabIdToName["default"]}
              panel={<>{wrapFields(fields.filter((f) => !f.tabId))}</>}
            />
            {uniqueTabIds.map((tabId) => (
              <Tab
                key={tabId}
                id={tabId}
                title={tabIdToName[tabId]}
                panel={
                  <>{wrapFields(fields.filter((f) => f.tabId === tabId))}</>
                }
              />
            ))}
          </Tabs>
        ) : (
          wrapFields(fields)
        )}
      </BaseForm>
    </>
  );
}
