import { IconNames } from "@blueprintjs/icons";
import { useMemo, useState } from "react";
import { usStateOptions } from "../../../constants/usStateOptions";
import { facilityApiClient } from "../../../lib/apiClients/facility/facilityApiClient";
import { PrimaryInformation } from "../../../types/Application/PrimaryInformation";
import { Facility } from "../../../types/Facility";
import { TextField } from "../../Fields/TextField/TextField";
import {
  CustomRendererBuilder,
  Option,
  AddressFieldValue,
} from "../../Fields/types/fieldTypes";

export const buildFacilityAddress: CustomRendererBuilder<
  PrimaryInformation
> = ({ form, rendererProps }) => {
  const [facility, setFacility] = useState<Facility>();

  const getStateDisplayFromValue = (
    usStates: Option[],
    stateNumber: string
  ) => {
    return (
      usStates.find((value) => value.value === stateNumber.toString())?.label ||
      ""
    );
  };

  const getSuggestionDisplay = (address?: AddressFieldValue) => {
    if (!address) {
      return "";
    }

    const { street, street2, city, state, zip, county } = address;
    const usStateDisplayValue = getStateDisplayFromValue(usStateOptions, state);

    let display = `${street}, `;

    if (street2) {
      display += `${street2}, `;
    }

    display += `${city}, ${usStateDisplayValue} ${zip}, ${county}`;

    return display;
  };

  useMemo(() => {
    if (form.facilityId) {
      facilityApiClient.getFacilityDetail(form.facilityId).then(setFacility);
    } else {
      setFacility(undefined);
    }
  }, [form.facilityId]);

  return (
    facility && (
      <TextField
        type="Text"
        value={getSuggestionDisplay(facility.facilityAddress)}
        label={rendererProps.label}
        onSubmit={rendererProps.onSubmit}
        disabled={rendererProps.disabled}
        inputMode="none"
        leftIcon={IconNames.MapMarker}
      />
    )
  );
};
