import baseApiClient from "../baseApiClient";

class InvoiceApiClient {
  createInvoices = async (
    dates: [Date | null, Date | null],
    invoiceNumber: number,
    serviceDate: string
  ) => {
    return baseApiClient.post(`invoice`, {
      startDate: dates[0],
      endDate: dates[1],
      invoiceNumber: invoiceNumber,
      serviceDate: serviceDate,
    });
  };

  createInvoiceForSelectedBillings = async (
    billingIds: string[],
    invoiceNumber: number
  ) => {
    return baseApiClient.post(`invoice/for-billing`, {
      billingIds,
      invoiceNumber,
    });
  };

  createInvoiceForSelectedCredit = async (
    billingId: string,
    parentInvoiceId: string
  ) => {
    return baseApiClient.post(`invoice/for-credit`, {
      billingId,
      parentInvoiceId,
    });
  };

  recreateInvoice = async (id: string) => {
    return baseApiClient.post(`invoice/${id}`);
  };

  sendInvoice = async (id: string) => {
    return baseApiClient.post(`invoice/${id}/email`);
  };

  sendBulkEmails = async (invoiceIds: string[]) => {
    return baseApiClient.post(`invoice/send-bulk-emails`, invoiceIds);
  };

  getLastInvoiceNumber = async () => {
    return baseApiClient.get<number>(`invoice/invoice-number`);
  };
}

export const invoiceApiClient = new InvoiceApiClient();
