import baseApiClient from "../baseApiClient";
import { AppointmentFormModel } from "../../../components/Forms/AppointmentForm/AppointmentFormModel";
import {
  Appointment,
  AppointmentChanges,
  AppointmentContact,
  AppointmentConversionResponse,
  AppointmentOutlookEvents,
  FacilityBlockSchedule,
} from "../../../types/Scheduling";
import { AppointmentContactFormModel } from "../../../components/Forms/AppointmentForm/AppointmentContactForm/AppointmentContactFormModel";
import { BlockScheduleFormModel } from "../../../components/Forms/FacilityBlockConfigurationForm/BlockScheduleForm/BlockScheduleFormModel";

class SchedulingApiClient {
  // Availability
  getAvailableStartTimes = (
    fieldRepId: string,
    year: number,
    month: number,
    day: number
  ): Promise<number[]> => {
    return baseApiClient.get<number[]>(
      `appointment/availability/${fieldRepId}/start-times/${year}/${month}/${day}`
    );
  };

  getAvailableEndTimes = (
    fieldRepId: string,
    unixStartTime: number
  ): Promise<number[]> => {
    return baseApiClient.get<number[]>(
      `appointment/availability/${fieldRepId}/end-times/${unixStartTime}`
    );
  };

  // Appointment
  createAppointment = (
    appointmentForm: AppointmentFormModel
  ): Promise<Appointment> => {
    return baseApiClient.post<Appointment>(`appointment`, appointmentForm);
  };

  getAppointment = (appointmentId: string): Promise<Appointment> => {
    return baseApiClient.get<Appointment>(`appointment/${appointmentId}`);
  };

  updateAppointment = (
    appointmentForm: AppointmentFormModel & AppointmentChanges
  ): Promise<Appointment> => {
    return baseApiClient.put<Appointment>(
      `appointment/${appointmentForm.id}`,
      appointmentForm
    );
  };

  deleteAppointment = (appointmentId: string): Promise<void> => {
    return baseApiClient.delete(`appointment/${appointmentId}`);
  };

  duplicateSSNCheck = (socialSecurityNumber: string): Promise<boolean> => {
    return baseApiClient.post<boolean>(
      `appointment/duplicate-ssn-check`,
      socialSecurityNumber
    );
  };

  convertToApplication = (appointmentId: string, confirm = false) => {
    return baseApiClient.post<AppointmentConversionResponse>(
      `appointment/${appointmentId}/convert-to-application`,
      confirm
    );
  };

  // AppointmentContact
  createAppointmentContact = (
    contactForm: AppointmentContactFormModel
  ): Promise<AppointmentContact> => {
    return baseApiClient.post<AppointmentContact>(
      `appointment/${contactForm.appointmentId}/contacts`,
      contactForm
    );
  };

  getAppointmentContacts = (
    appointmentId: string
  ): Promise<AppointmentContact[]> => {
    return baseApiClient.get<AppointmentContact[]>(
      `appointment/${appointmentId}/contacts`
    );
  };

  getAppointmentContact = (
    appointmentId: string,
    contactId: string
  ): Promise<AppointmentContact> => {
    return baseApiClient.get<AppointmentContact>(
      `appointment/${appointmentId}/contacts/${contactId}`
    );
  };

  updateAppointmentContact = (
    contactForm: AppointmentContactFormModel
  ): Promise<AppointmentContact> => {
    return baseApiClient.put<AppointmentContact>(
      `appointment/${contactForm.appointmentId}/contacts/${contactForm.id}`,
      contactForm
    );
  };

  deleteAppointmentContact = (
    appointmentId: string,
    contactId: string
  ): Promise<void> => {
    return baseApiClient.delete(
      `appointment/${appointmentId}/contacts/${contactId}`
    );
  };

  getOutlookAppointments = (
    id: string
  ): Promise<AppointmentOutlookEvents[]> => {
    return baseApiClient.get(`appointment/outlook/${id}`);
  };

  // FacilityBlockSchedule
  createBlockSchedule = (
    blockSchedule: BlockScheduleFormModel
  ): Promise<FacilityBlockSchedule> => {
    return baseApiClient.post(
      `facility/${blockSchedule.facilityId}/block-schedule/${blockSchedule.fieldRepId}`,
      blockSchedule
    );
  };

  getBlockSchedules = (
    facilityId: string,
    fieldRepId: string
  ): Promise<FacilityBlockSchedule[]> => {
    return baseApiClient.get(
      `facility/${facilityId}/block-schedule/${fieldRepId}`
    );
  };

  getBlockSchedule = (
    facilityId: string,
    fieldRepId: string,
    blockScheduleId: string
  ): Promise<FacilityBlockSchedule> => {
    return baseApiClient.get(
      `facility/${facilityId}/block-schedule/${fieldRepId}/${blockScheduleId}`
    );
  };

  updateBlockSchedule = (
    blockSchedule: BlockScheduleFormModel
  ): Promise<FacilityBlockSchedule> => {
    return baseApiClient.put(
      `facility/${blockSchedule.facilityId}/block-schedule/${blockSchedule.fieldRepId}/${blockSchedule.id}`,
      blockSchedule
    );
  };

  deleteBlockSchedule = (
    facilityId: string,
    fieldRepId: string,
    blockScheduleId: string
  ): Promise<void> => {
    return baseApiClient.delete(
      `facility/${facilityId}/block-schedule/${fieldRepId}/${blockScheduleId}`
    );
  };
}

export default new SchedulingApiClient();
