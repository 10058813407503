import React from "react";
import "./styles.scss";
import {
  TreeViewComponent,
  TreeViewModel,
} from "@syncfusion/ej2-react-navigations";
import {
  MaskedTextBoxComponent,
  MaskedTextBoxModel,
} from "@syncfusion/ej2-react-inputs";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  expressionToolbarStore,
  SearchResults,
} from "./stores/ExpressionToolbarStore";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import classNames from "classnames";
import { templateApiClient } from "../../lib/apiClients/template/templateApiClient";
import { errorStore, genericErrorMessage } from "../../stores/ErrorStore";
import { ExpressionData } from "./stores/ExpressionsStore";

export interface ExpressionsToolbarProps {
  disableFields: boolean;
}

export default interface AutoHeightSettings {
  autoHeightOffset: number;
}

export const ExpressionsToolbar: React.FC<ExpressionsToolbarProps> = (
  props
) => {
  const [templateFields, setTemplateFields] = React.useState<
    ExpressionData[] | undefined
  >(undefined);
  const [searchResults, setSearchResults] = React.useState<SearchResults>();
  const [maskedTextBoxSettings, setMaskedTextBoxSettings] = React.useState<
    MaskedTextBoxModel & DefaultHtmlAttributes
  >();
  const [treeViewSettings, setTreeViewSettings] = React.useState<
    TreeViewModel & DefaultHtmlAttributes
  >();

  const [expressionToolbarHeight, setExpressionToolbarHeight] =
    React.useState<number>();

  const maskedTextBoxRef = React.useRef<MaskedTextBoxComponent>(null);
  const treeViewRef = React.useRef<TreeViewComponent>(null);
  const expressionsToolbar = React.useRef<HTMLDivElement>(null);

  // Setup
  React.useEffect(() => {
    // Fetch Fields
    templateApiClient
      .getTemplateFields()
      .then((data) => {
        setTemplateFields(data);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });

    return () => {
      expressionToolbarStore.reset();
    };
  }, []);

  React.useEffect(() => {
    if (templateFields && templateFields.length > 0) {
      const [maskedTextBoxModel, treeViewModel] =
        expressionToolbarStore.setup(templateFields);

      setMaskedTextBoxSettings(maskedTextBoxModel);
      setTreeViewSettings(treeViewModel);
    }
  }, [templateFields]);

  // Initialize
  React.useEffect(() => {
    if (treeViewRef.current && maskedTextBoxRef.current) {
      expressionToolbarStore.initialize(
        maskedTextBoxRef.current,
        treeViewRef.current
      );
    }
  }, [templateFields, treeViewRef, maskedTextBoxRef]);

  React.useEffect(() => {
    if (!treeViewRef.current) {
      return;
    }

    if (searchResults?.expand) {
      treeViewRef.current.expandAll();
    }
  }, [searchResults]);

  React.useEffect(() => {
    if (!!expressionsToolbar.current) {
      initializeAutoHeight();
    }
  });

  const handleMaskedTextBoxChange = () => {
    const results = expressionToolbarStore.search();
    setSearchResults(results);
  };

  const expressionsToolbarHeightSettings: AutoHeightSettings = {
    autoHeightOffset: 150,
  };

  const initializeAutoHeight = () => {
    // Inner Function
    const autoHeight = () => {
      if (expressionsToolbar.current) {
        setExpressionToolbarHeight(
          window.innerHeight - expressionsToolbarHeightSettings.autoHeightOffset
        );
      }
    };

    // Init
    autoHeight();

    // On Resize
    window.addEventListener("resize", () => {
      autoHeight();
    });
  };

  return !!templateFields ? (
    <div
      className="expressions-toolbar"
      style={{ height: expressionToolbarHeight }}
      ref={expressionsToolbar}
    >
      <div className="search-expressions-input-container">
        <MaskedTextBoxComponent
          className="search-expressions-input"
          ref={maskedTextBoxRef}
          change={handleMaskedTextBoxChange}
          {...maskedTextBoxSettings}
          disabled={props.disableFields}
        />
        <Icon icon={IconNames.Search} size={14} />
      </div>
      <div
        className={classNames("fields-container", {
          "fields-disabled": props.disableFields,
        })}
      >
        <TreeViewComponent
          ref={treeViewRef}
          {...treeViewSettings}
          fullRowSelect={false}
          disabled={props.disableFields}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
